import { call, delay, put, race, select, take } from 'redux-saga/effects';

import { KioskDevicesApi } from '@gss/api/KioskApi';
import { KEEP_DEVICE_ALIVE_INTERVAL } from '@gss/configs/timers';
import { Storage } from '@gss/services';
import { getSelfServiceDeviceId } from '@gss/store/configuration/selectors';
import { setIsOfflineStatus } from '@gss/store/mainProcess/actions';
import { getIsOfflineStatus } from '@gss/store/mainProcess/selectors';
import { SagasGenerator } from '@gss/types/shared';
import { isNetworkConnectionError } from '@gss/utils/errors';

import * as actions from '../actions';

function* sendKeepDeviceLive(deviceId: string): SagasGenerator {
  try {
    const isMarkedAsOffline: boolean = yield select(getIsOfflineStatus);

    yield KioskDevicesApi.keepAlive({
      pathParams: {
        deviceId,
      },
    });

    if (isMarkedAsOffline) {
      yield put(setIsOfflineStatus(false));
    }
  } catch (error) {
    if (isNetworkConnectionError(error)) {
      yield put(setIsOfflineStatus(true));
    } else {
      yield put(actions.keepDeviceLiveTask.failed(error));
      throw error;
    }
  }
}

function* keepDeviceLiveTask(): SagasGenerator {
  while (true) {
    try {
      const selfServiceDeviceId: string | undefined = yield select(
        getSelfServiceDeviceId
      );

      if (selfServiceDeviceId) {
        Storage.saveDeviceCookie(selfServiceDeviceId);

        yield call(sendKeepDeviceLive, selfServiceDeviceId);
      }

      yield delay(KEEP_DEVICE_ALIVE_INTERVAL);
    } catch {
      yield put(actions.keepDeviceLiveTask.stop());
    }
  }
}

export function* keepDeviceLiveTaskListener(): SagasGenerator {
  while (true) {
    yield take(actions.keepDeviceLiveTask.start);
    yield race([
      call(keepDeviceLiveTask),
      take(actions.keepDeviceLiveTask.stop),
    ]);
  }
}
