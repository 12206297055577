import { PurchaseItem } from 'types/Api/Availability';
import {
  BillingInstruction,
  CreditCardAuthorizationDetails,
  Folio,
} from 'types/Api/Cashiering';
import { Money } from 'types/Api/Shared';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';
import { configureCheckInPaymentStep } from 'views/CheckInPayment/visibility';
import {
  configurePreAuthSteps,
  isPreAuthorizationAvailable,
} from 'views/CheckInPreAuthorization/routing';
import {
  configurePrepaymentSteps,
  isPrepaymentAvailable,
} from 'views/CheckInPrepayment/routing';

const { SHOW_ADDONS, SHOW_PRE_AUTHORIZATION, SHOW_PREPAYMENT } =
  Configurator.switchCodes;

export const configureCheckInRouting = (
  addons: PurchaseItem[],
  activeAuthorizations: CreditCardAuthorizationDetails[],
  outstandingDeposit: Money,
  localPropertyDateTime: string,
  preAuthorizationAmount: number,
  billingInstructions: BillingInstruction[],
  folios: Folio[],
  isMultiRoomReservation: boolean
) => {
  configureCheckInPaymentStep(folios, billingInstructions);
  if (!addons.length && Configurator.getSwitch(SHOW_ADDONS)) {
    Router.removeStep(Path.checkIn, 'ADDONS');
  }

  if (!isMultiRoomReservation) {
    Router.removeStep(Path.checkIn, 'MULTIROOM');
    Router.removeStep(Path.checkIn, 'NAME_DETAILS');
  }

  const showPrepayment =
    isPrepaymentAvailable(outstandingDeposit.amount) &&
    Configurator.getSwitch(SHOW_PREPAYMENT);
  if (!Configurator.getSwitch(SHOW_ADDONS))
    configurePrepaymentSteps(showPrepayment);
  const showPreAuth =
    isPreAuthorizationAvailable(localPropertyDateTime, activeAuthorizations) &&
    Configurator.getSwitch(SHOW_PRE_AUTHORIZATION) &&
    Boolean(preAuthorizationAmount);
  configurePreAuthSteps(showPreAuth);
};
