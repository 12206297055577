export default {
  PROPERTY_FORM: 'property-form',
  AUTHORIZATION_FORM: 'authorization-form',
  AUTHORIZATION_FORM_CANCEL_BUTTON: 'authorization-form__cancel-button',
  AUTHORIZATION_FORM_SUBMIT_BUTTON: 'authorization-form__submit-button',
  CHECKOUT_AUTH_FORM: 'checkOut-auth-form',
  CHECKOUT_AUTH_FORM_CANCEL_BUTTON: 'checkOut-auth-form__cancel-button',
  CHECKOUT_AUTH_FORM_SUBMIT_BUTTON: 'checkOut-auth-form__submit-button',
  ADDRESS_FORM: 'address-form',
  MINIBAR_FORM: 'minibar-form',
  DETAILS_FORM: 'details-form',
  GENERATE_KEYS_FORM: 'generate-keys-form',
  ADDONS_FORM: 'addons-form',
  MISSING_EMAIL_FORM: 'missing-email-form',
  EDIT_EMAIL_FORM: 'edit-email-form',
  CHANGE_LANGUAGE_FORM: 'change-language-form',
  CHECKIN_NAME_DETAILS: 'checkin-name-details',
};
