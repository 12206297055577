var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/array.ts")).call(alias1,"none","xxs","xs","s","sm","md","lg","xlg","xxlg",{"name":"array","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":38,"column":12},"end":{"line":38,"column":69}}}),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":38,"column":4},"end":{"line":56,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),blockParams[1][0],{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.program(5, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":39,"column":6},"end":{"line":55,"column":13}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "        .pdf-template-wrapper .spacing-"
    + alias2(alias1(blockParams[2][0], depth0))
    + "-"
    + alias2(alias1(blockParams[1][0], depth0))
    + " {\n          margin-"
    + alias2(alias1(blockParams[2][0], depth0))
    + ": var(--spacing-"
    + alias2(alias1(blockParams[1][0], depth0))
    + ");\n        }\n\n        .pdf-template-wrapper .padding-"
    + alias2(alias1(blockParams[2][0], depth0))
    + "-"
    + alias2(alias1(blockParams[1][0], depth0))
    + " {\n          padding-"
    + alias2(alias1(blockParams[2][0], depth0))
    + ": var(--spacing-"
    + alias2(alias1(blockParams[1][0], depth0))
    + ");\n        }\n";
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "        .pdf-template-wrapper .spacing-"
    + alias2(alias1(blockParams[1][0], depth0))
    + " {\n          margin: var(--spacing-"
    + alias2(alias1(blockParams[1][0], depth0))
    + ");\n        }\n\n        .pdf-template-wrapper .padding-"
    + alias2(alias1(blockParams[1][0], depth0))
    + " {\n          padding: var(--spacing-"
    + alias2(alias1(blockParams[1][0], depth0))
    + ");\n        }\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-template-wrapper {\n    --color-black: #000;\n    --color-gray1: #333;\n    --color-dark-gray2: #525252;\n    --color-gray2: #757575;\n    --color-gray3: #919191;\n    --color-gray4: #BDBDBD;\n    --color-gray5: #DFDFDF;\n    --color-gray6: #E6E6E6;\n    --color-gray7: #F5F5F5;\n    --color-white: #FFF;\n\n    --font-size-xxs: 8px;\n    --font-size-xs: 9px;\n    --font-size-s: 10px;\n    --font-size-sm: 11px;\n    --font-size-md: 12px;\n    --font-size-lg: 14px;\n    --font-size-xlg: 16px;\n    --font-size-xxlg: 20px;\n\n    --font-weight-normal: normal;\n    --font-weight-bold: bold;\n\n    --spacing-none: 0;\n    --spacing-xxs: 2px;\n    --spacing-xs: 4px;\n    --spacing-s: 6px;\n    --spacing-sm: 8px;\n    --spacing-md: 10px;\n    --spacing-lg: 12px;\n    --spacing-xlg: 16px;\n    --spacing-xxlg: 20px;\n  }\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,__default(require("../helpers/array.ts")).call(alias1,"top","right","bottom","left",undefined,{"name":"array","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":37,"column":10},"end":{"line":37,"column":57}}}),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":37,"column":2},"end":{"line":57,"column":11}}})) != null ? stack1 : "")
    + "</style>\n\n<div class=\"pdf-template-wrapper\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"blockParams":blockParams,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useBlockParams":true});