import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const ICON_SIZE = 45;

const TIMER_SIZE = 88;
const wrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export default ({ palette, spacing }: Theme) =>
  createStyles({
    descriptionWrapper: {
      ...wrapper,
      whiteSpace: 'pre-wrap',
      width: '100%',
    },
    descriptionTitle: {
      fontSize: '1rem',
      marginBottom: spacing(3),
      textAlign: 'center',
    },
    title: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      marginBottom: spacing(2),
      textAlign: 'center',
    },
    subtitle: {
      fontSize: '1rem',
      textAlign: 'center',
      marginBottom: spacing(2.25),
    },
    content: {
      ...wrapper,
      minHeight: '100%',
      flex: 1,
    },
    circular: {
      color: 'var(--primary-color)',
    },
    successWrapper: {
      position: 'relative',
      width: TIMER_SIZE,
      height: TIMER_SIZE,
      marginBottom: spacing(3),
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  });
