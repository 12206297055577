import React, { useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import {
  addPaymentMethod,
  getAddPaymentMethodOperationStatus,
} from 'store/cashiering/actions';
import { fetchActiveAuthorizations } from 'store/cashiering/authorization/actions';
import { activeAuthorizations } from 'store/cashiering/authorization/selectors';
import {
  getCashieringErrors,
  getPreAuthorization,
  getPreAuthorizationAmount,
} from 'store/cashiering/selectors';
import { getLocalPropertyDateTime } from 'store/propertyManagement/selectors';
import { getAccountId } from 'store/reservation/selectors';
import {
  AsyncApiCallThroughActionCreator,
  PropsWithDispatch,
} from 'store/utils/actions';
import {
  CreditCardAuthorizationDetails,
  PreAuthorizationData,
} from 'types/Api/Cashiering';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator } from 'utils';
import CheckInCardPayment from 'views/CheckInCardPayment';
import { useActiveAuthorizations } from 'views/CheckInPreAuthorization/activeAuthorizations';
import {
  useAddingAuthorizationHandler,
  useCheckingOperationStatusHandler,
} from 'views/CheckInPreAuthorization/authorization';
import {
  isPreAuthorizationAvailable,
  useCorectStepInPreAuthorization,
} from 'views/CheckInPreAuthorization/routing';

import {
  getPaymentDevice,
  getWorkstationId,
} from '@gss/store/configuration/selectors';

const { PRE_AUTHORIZATION_MESSAGE } = Configurator.getTranslationCodes();

interface CheckInPreAuthorizationProps
  extends WithTranslation,
    RouteComponentProps {
  addPaymentMethod: AsyncApiCallThroughActionCreator<typeof addPaymentMethod>;
  fetchActiveAuthorizations: AsyncApiCallThroughActionCreator<
    typeof fetchActiveAuthorizations
  >;
  getAddPaymentMethodOperationStatus: AsyncApiCallThroughActionCreator<
    typeof getAddPaymentMethodOperationStatus
  >;
  errors: ApiError[];
  activeAuthorizations: CreditCardAuthorizationDetails[];
  currentDateTime: string;
  accountId: string;
  preAuthorization: PreAuthorizationData;
  preAuthorizationAmount: number;
}

const CheckInPreAuthorization = ({
  t,
  history,
  fetchActiveAuthorizations,
  errors,
  currentDateTime,
  activeAuthorizations,
  addPaymentMethod,
  accountId,
  preAuthorization,
  getAddPaymentMethodOperationStatus,
  preAuthorizationAmount,
}: CheckInPreAuthorizationProps) => {
  const workstationId = useSelector(getWorkstationId);
  const paymentDevice = useSelector(getPaymentDevice);
  const isLoading = useActiveAuthorizations(fetchActiveAuthorizations);
  const isStepAvailable =
    isPreAuthorizationAvailable(currentDateTime, activeAuthorizations) &&
    !!preAuthorizationAmount;
  useCorectStepInPreAuthorization(
    isStepAvailable,
    history,
    isLoading,
    errors.length > 0
  );

  const addAuthorizationConfig = useMemo(
    () => ({
      transactionCodeId: getTransactionCodeId(),
      terminalId: paymentDevice?.uniqueId,
      workstationId: workstationId as string,
    }),
    [workstationId, paymentDevice]
  );

  const addAuthorization = useAddingAuthorizationHandler(
    addPaymentMethod,
    accountId,
    addAuthorizationConfig
  );
  const checkOperationStatus = useCheckingOperationStatusHandler(
    preAuthorization,
    getAddPaymentMethodOperationStatus
  );

  return (
    <CheckInCardPayment
      headerTitle={t('PRE_AUTHORIZATION')}
      formMainText={
        `${Configurator.getTranslation(PRE_AUTHORIZATION_MESSAGE)}` ||
        `${t('PRE_AUTHORIZATION_INFORMATION')}. ${t('FOLLOW_THE_INSTRUCTION')}.`
      }
      paymentValue={{
        amount: preAuthorizationAmount,
        currency: Configurator.propertySettings.currencyCode,
      }}
      paymentDescription={t('PRE_AUTHORIZATION_AMOUNT_DESCRIPTION')}
      isInitializing={isLoading || !isStepAvailable}
      isPaymentAdded={preAuthorization.isPreAuthorized}
      successMessage={t('AUTHORIZATION_SUCCESSFUL')}
      addPayment={addAuthorization}
      checkOperationStatus={checkOperationStatus}
    />
  );
};

type PropsToMapToDispatch = Pick<
  CheckInPreAuthorizationProps,
  | 'fetchActiveAuthorizations'
  | 'addPaymentMethod'
  | 'getAddPaymentMethodOperationStatus'
>;

const mapDispatchToProps: PropsWithDispatch<PropsToMapToDispatch> = {
  fetchActiveAuthorizations,
  addPaymentMethod,
  getAddPaymentMethodOperationStatus,
};

type PropsFromState = Pick<
  CheckInPreAuthorizationProps,
  | 'errors'
  | 'activeAuthorizations'
  | 'currentDateTime'
  | 'accountId'
  | 'preAuthorization'
  | 'preAuthorizationAmount'
>;

const mapStateToProps = (state: Store): PropsFromState => ({
  errors: getCashieringErrors(state),
  activeAuthorizations: activeAuthorizations(state),
  currentDateTime: getLocalPropertyDateTime(state),
  accountId: getAccountId(state),
  preAuthorization: getPreAuthorization(state),
  preAuthorizationAmount: getPreAuthorizationAmount(state),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInPreAuthorization) as () => JSX.Element;

const getTransactionCodeId = (): string => {
  const {
    entityCodes: { TRANSACTION_CODE_FOR_CREDIT_CARD },
  } = Configurator;

  return Configurator.getEntityTypeId(TRANSACTION_CODE_FOR_CREDIT_CARD);
};
