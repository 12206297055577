import { createStyles } from '@material-ui/styles';

export default () =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: 'inherit',
    },
  });
