import React, { Component, createRef, RefObject } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { isIPad } from 'store/ui/selectors';
import Store from 'types/Store';

import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './Slider.style';

interface PassedProps {
  onChange?: (value: number) => any;
  value?: number;
}

interface SliderProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  showMarks?: boolean;
  markSize?: number;
  maxValue?: number;
  isIPad: boolean;
}

interface SliderState {
  isDragging: boolean;
  startX: number;
  scrollLeft: number;
  value: number;
}

class Slider extends Component<SliderProps, SliderState> {
  public static defaultProps = {
    showMarks: true,
    markSize: 2.5,
    maxValue: 180,
  };

  public state = {
    isDragging: false,
    startX: 0,
    scrollLeft: 0,
    value: 0,
  };

  private Wrapper: RefObject<HTMLDivElement> = createRef();

  public componentDidUpdate(prevProps: SliderProps) {
    if (prevProps.value !== 0 && this.props.value === 0) {
      this.resetValue();
    }
  }

  public componentDidMount() {
    if (this.Wrapper.current) {
      const { isIPad } = this.props;
      const slider = this.Wrapper.current;
      if (isIPad) {
        slider.addEventListener('touchstart', (e) => this.onMouseDown(e));
        slider.addEventListener('touchmove', (e) => this.onMouseMove(e));
        slider.addEventListener('touchend', () => this.onMouseUp());
        slider.addEventListener('touchcancel', () => this.onMouseLeave());
      }
      if (!isIPad) {
        slider.addEventListener('mousedown', (e) => this.onMouseDown(e));
        slider.addEventListener('mousemove', (e) => this.onMouseMove(e));
        slider.addEventListener('mouseup', () => this.onMouseUp());
        slider.addEventListener('mouseleave', () => this.onMouseLeave());
      }
      const middle = (slider.scrollWidth - slider.offsetWidth) / 2;
      slider.scrollLeft = middle;
      this.setState({ scrollLeft: middle, value: 0 });
    }
  }

  public render() {
    const { classes, markSize } = this.props;
    const marksNumber = markSize ? Math.round(100 / markSize) + 1 : 0;

    return (
      <div ref={this.Wrapper} className={classes.wrapper}>
        <div className={classes.slider}>
          {marksNumber &&
            Array(marksNumber)
              .fill('')
              .map((item, index) =>
                this.renderMark(
                  markSize! * index,
                  index,
                  marksNumber,
                  `slider-mark-${index}`
                )
              )}
        </div>
      </div>
    );
  }

  private renderMark = (
    value: number,
    index: number,
    max: number,
    key: string
  ) => {
    const { classes } = this.props;
    const width = index % 10 ? 1 : 3;
    const isMiddle = index === Math.floor(max / 2);
    const height = isMiddle ? 32 : 22;

    return (
      <div
        key={key}
        className={classes.mark}
        style={{ height, width, left: `${value}%` }}
      />
    );
  };

  private onMouseDown = (e: any) => {
    const slider = this.Wrapper.current!;
    this.setState({
      isDragging: true,
      startX: e.pageX - slider.offsetLeft,
      scrollLeft: slider.scrollLeft,
    });
  };

  private onMouseMove = (e: any) => {
    const { isDragging, startX, scrollLeft } = this.state;
    if (!isDragging) return;
    const slider = this.Wrapper.current!;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = x - startX;
    slider.scrollLeft = scrollLeft - walk;
    this.setValue();
  };

  private setValue = () => {
    const { maxValue } = this.props;
    const { onChange } = this.props;

    const slider = this.Wrapper.current!;
    const middle = (slider.scrollWidth - slider.offsetWidth) / 2;
    const currentSize =
      (Math.abs(slider.scrollLeft - middle) / middle) * maxValue!;
    const value = slider.scrollLeft > middle ? currentSize : -currentSize;
    this.setState({ value });
    if (onChange) onChange(value);
  };

  private resetValue = () => {
    const slider = this.Wrapper.current!;
    const middle = (slider.scrollWidth - slider.offsetWidth) / 2;
    slider.scrollLeft = middle;
    this.setState({ scrollLeft: middle, value: 0 });
  };

  private onMouseLeave = () => {
    this.setState({ isDragging: false });
  };

  private onMouseUp = () => {
    this.setState({ isDragging: false });
  };
}

const mapStateToProps = (state: Store) => ({
  isIPad: isIPad(state),
});

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(Slider) as (props: PassedProps) => JSX.Element;
