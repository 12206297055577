import i18next from 'i18next';

import { LocalizedStringDto } from '@ac/library-api';

import { DEFAULT_APP_LANGUAGE } from '@gss/configs/constants';

export const getLocalizedContent = (
  localization: LocalizedStringDto[],
  language?: string
): string => {
  const translationContent = localization.find(
    ({ languageCode }) =>
      languageCode.toLowerCase() ===
      (language || i18next.language).toLowerCase()
  )?.content;

  return (
    translationContent ||
    localization.find(
      ({ languageCode }) =>
        languageCode.toLowerCase() === DEFAULT_APP_LANGUAGE.toLowerCase()
    )?.content ||
    ''
  );
};
