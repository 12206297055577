import { ReducerMap, ReduxCompatibleReducer } from 'redux-actions';

import { handleActions } from '@ac/library-utils/dist/redux-utils';

import {
  clearAllGlobalErrors,
  resetGlobalSetupData,
} from '@gss/store/globalActions';

export const handleGlobalReducer = <ReducerStateType>(
  reducerMap: ReducerMap<ReducerStateType, unknown>,
  initialState: ReducerStateType
): ReduxCompatibleReducer<ReducerStateType, unknown> => {
  const reducer = {
    [clearAllGlobalErrors]: (state: ReducerStateType): ReducerStateType => {
      return {
        ...state,
        errors: [],
      };
    },

    [resetGlobalSetupData]: (): ReducerStateType => {
      return {
        ...initialState,
      };
    },

    ...reducerMap,
  };

  return handleActions(reducer, initialState);
};
