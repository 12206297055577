import {
  AlignItems,
  Color,
  Flex,
  Icon,
  IconTypes,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import './ValidationInfo.scss';

export enum ValidationInfoType {
  warn = 'warn',
  error = 'error',
}

interface ValidationInfoProps {
  content?: string;
  type: ValidationInfoType;
}

export const ValidationInfo = ({
  type,
  content,
}: ValidationInfoProps): JSX.Element => {
  const iconType =
    type === ValidationInfoType.warn
      ? IconTypes.warning
      : IconTypes.cancelFilled;

  const iconColor =
    type === ValidationInfoType.warn ? Color.warning : Color.error;

  return (
    <Flex
      className="validation-info-element gap-sm"
      alignItems={AlignItems.center}
    >
      <Icon
        className="validation-info-icon"
        type={iconType}
        color={iconColor}
      />
      <Text size={TextSize.sm}>{content}</Text>
    </Flex>
  );
};
