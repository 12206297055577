import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    chipRoot: {
      borderRadius: 2,
      padding: `${spacing(1) / 4}px ${spacing(1) / 2}px`,
      height: 'auto',
      textTransform: 'capitalize',
      boxSizing: 'border-box',
      color: colors.grey[500],
    },
    chipLabel: {
      fontSize: '0.875rem',
      padding: 0,
    },
    chip: {
      marginRight: spacing(1),
    },
  });
