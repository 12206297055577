import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List, ListHeader, ListRow, RowItem } from 'components/List';
import { compose } from 'redux';
import { CompanyListItemDetails } from 'types/Api/Profile';

import { Button } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CompanyList.style';
import { listConfig } from './listConfig';

interface PassedProps {
  data: CompanyListItemDetails[];
  onItemClick: (reservationId?: string) => void;
}

interface CompanyListProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class CompanyList extends PureComponent<CompanyListProps> {
  public static defaultProps = {
    data: [],
  };

  private headers: string[];

  constructor(props: CompanyListProps) {
    super(props);

    const { t } = props;
    this.headers = [t('COMPANY_NAME'), t('TAX_ID'), t('ADDRESS'), ''];
  }

  public render() {
    const { t, onItemClick, data, classes } = this.props;

    return (
      <List listConfig={listConfig}>
        <ListHeader headers={this.headers} rowClassName={classes.listWrapper} />
        {data.map(({ address, taxId, companyName, id }) => (
          <ListRow key={`reservations-${id}`} className={classes.listWrapper}>
            <RowItem>{companyName}</RowItem>
            <RowItem>{taxId}</RowItem>
            <RowItem>{address}</RowItem>
            <RowItem>
              <Button
                onClick={() => onItemClick(id)}
                className={classes.selectButton}
              >
                {t('SELECT')}
              </Button>
            </RowItem>
          </ListRow>
        ))}
        <ListRow className={classes.listWrapper}>
          <RowItem gridProps={{ xs: 6 }}>{t('ADD_ANOTHER_COMPANY')}</RowItem>
          <RowItem
            gridProps={{
              container: true,
              justify: 'flex-end',
              xs: 6,
            }}
          >
            <Button
              onClick={() => onItemClick()}
              className={classes.selectButton}
            >
              {t('ADD_NEW_COMPANY')}
            </Button>
          </RowItem>
        </ListRow>
      </List>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(CompanyList) as (
  props: PassedProps
) => JSX.Element;
