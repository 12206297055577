import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { FormControlLabel, Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FormCheckbox.style';
import FormCheckboxControl from './FormCheckboxControl';

interface FormCheckboxProps extends WithStyles<typeof styles> {
  input?: any;
  className?: string;
  label?: JSX.Element;
  required?: boolean;
  labelProps?: any;
  disabled?: boolean;
  [prop: string]: any;
}

class FormCheckbox extends PureComponent<FormCheckboxProps> {
  public static defaultProps = {
    required: false,
    disabled: false,
    labelProps: {},
  };

  public render() {
    const { classes, className, labelProps, input, disabled, ...props } =
      this.props;

    return (
      <Grid
        container
        alignItems="center"
        direction="row"
        className={classNames(classes.wrapper, className)}
        {...props}
      >
        <FormControlLabel
          control={<FormCheckboxControl disabled={disabled} input={input} />}
          label={this.renderLabel()}
          classes={{
            label: classes.formControlLabel,
          }}
          {...labelProps}
        />
      </Grid>
    );
  }

  private renderLabel() {
    const { classes, label } = this.props;

    return <Typography className={classes.label}>{label}</Typography>;
  }
}

export default withStyles(styles)(FormCheckbox);
