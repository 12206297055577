import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  AlignItems,
  Body,
  Button,
  ButtonPattern,
  Flex,
  FormHeader,
  Header,
  IconTypes,
  LoadingView,
  Section,
  SectionType,
  SelectField,
  SelectOption,
  TextWeight,
} from '@ac/kiosk-components';

import { SelfServiceDeviceDto } from '@gss/api/KioskApi/entries';
import { ShijiWhiteLogo } from '@gss/assets/images';
import { LinkedDevices } from '@gss/components';
import { Footer, View } from '@gss/components/layout';
import { REFRESH_DEVICE_LIST_INTERVAL } from '@gss/configs/timers';
import { useRouter } from '@gss/router';
import { Storage } from '@gss/services';
import {
  fetchDeviceList,
  resetApplication,
  setPreselectedDeviceId,
  setUsedDeviceId,
} from '@gss/store/configuration/actions';
import {
  getFetchingDeviceState,
  getValidatedPreselectedDeviceId,
  getValidDevices,
} from '@gss/store/configuration/selectors';
import { initializeMainProcess } from '@gss/store/mainProcess/actions';
import { mapSelectOptions } from '@gss/utils';

export const Device = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const refreshDeviceListInterval = useRef<number>();

  const isFetchingDevices = useSelector(getFetchingDeviceState);
  const devices = useSelector(getValidDevices);

  const [isSelectionReady, setSelectionReady] = useState(!!devices);
  const preselectedDeviceId = useSelector(getValidatedPreselectedDeviceId);

  const deviceOptions = useMemo((): Array<SelectOption<string>> => {
    const offlineDevices = devices?.filter((device) => !device.isOnline);

    return mapSelectOptions(offlineDevices, 'deviceName', 'deviceId');
  }, [devices]);

  const selectedDeviceDetails = useMemo(():
    | SelfServiceDeviceDto
    | undefined => {
    return devices?.find(({ deviceId }) => deviceId === preselectedDeviceId);
  }, [devices, preselectedDeviceId]);

  const completeInitializationProcess = useCallback(
    (deviceId: string): void => {
      dispatch(setUsedDeviceId(deviceId));
      dispatch(initializeMainProcess.trigger());
      router?.gotToNextStep();
    },
    [dispatch, router]
  );

  const refreshDevices = (): void => {
    if (refreshDeviceListInterval.current) {
      clearInterval(refreshDeviceListInterval.current);
    }

    dispatch(fetchDeviceList.trigger());

    refreshDeviceListInterval.current = window.setInterval(() => {
      dispatch(fetchDeviceList.trigger());
    }, REFRESH_DEVICE_LIST_INTERVAL);
  };

  const handleDeviceSelection = (deviceId: string): void => {
    dispatch(setPreselectedDeviceId(deviceId));
  };

  const handleBackClick = (): void => {
    dispatch(resetApplication());
  };

  const handleContinueClick = (): void => {
    if (!preselectedDeviceId) return;
    completeInitializationProcess(preselectedDeviceId);
  };

  const handleOnMissingDeviceClick = (): void => {
    router?.goTo('MISSING_DEVICE');
  };

  useEffect(() => {
    refreshDevices();

    return (): void => {
      if (refreshDeviceListInterval.current) {
        clearInterval(refreshDeviceListInterval.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (devices && !isSelectionReady) {
      const cachedDeviceId = Storage.getDeviceCookie();

      if (cachedDeviceId) {
        const isCachedDeviceValid = devices.some(
          (device) => device.deviceId === cachedDeviceId
        );

        if (isCachedDeviceValid) {
          completeInitializationProcess(cachedDeviceId);
        } else {
          setSelectionReady(true);
        }
      } else {
        setSelectionReady(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  return !isSelectionReady ? (
    <LoadingView
      className="with-default-kiosk-components-theme"
      description={t('VIEWS.DEVICE.LOADING_MESSAGE')}
    />
  ) : (
    <View className="with-default-kiosk-components-theme">
      <Header logo={ShijiWhiteLogo} title={t('VIEWS.DEVICE.TITLE')} />
      <Body className="padding-vertical-lg">
        <Section type={SectionType.wide}>
          <FormHeader
            titleWeight={TextWeight.regular}
            title={t('VIEWS.DEVICE.FORM_HEADER')}
          />
          <Flex
            className="spacing-top-lg gap-lg"
            alignItems={AlignItems.center}
          >
            <SelectField
              label={t('VIEWS.DEVICE.TITLE')}
              placeholder={t('SHARED.SELECT')}
              optionsLoading={isFetchingDevices}
              value={preselectedDeviceId}
              options={deviceOptions}
              onChange={handleDeviceSelection}
            />
            <Button
              pattern={ButtonPattern.tertiary}
              icon={IconTypes.refresh}
              disabled={isFetchingDevices}
              onClick={refreshDevices}
            />
          </Flex>

          <Button
            pattern={ButtonPattern.secondary}
            className="spacing-top-lg"
            onClick={handleOnMissingDeviceClick}
          >
            {t('VIEWS.DEVICE.MISSING_DEVICE_BUTTON')}
          </Button>

          {selectedDeviceDetails &&
            selectedDeviceDetails.workstationDevices && (
              <LinkedDevices mainDevice={selectedDeviceDetails} />
            )}
        </Section>
      </Body>
      <Footer
        hasPrimaryButton
        isPrimaryDisabled={!preselectedDeviceId}
        onPrimaryClick={handleContinueClick}
      >
        <Button onClick={handleBackClick} pattern={ButtonPattern.secondary}>
          {t('SHARED.BACK')}
        </Button>
      </Footer>
    </View>
  );
};
