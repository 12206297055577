import { ApiError, isApiError } from '@ac/library-api';

export const isProcessingError = (error: Error | ApiError) => {
  return (
    error &&
    typeof error === 'object' &&
    !isApiError(error) &&
    !(error instanceof Error)
  );
};
