import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const COLLAPSE_HEIGHT = 52;
const ICON_SIZE = 32;

const detailsRoot = {
  height: COLLAPSE_HEIGHT,
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default ({ palette, spacing }: Theme) =>
  createStyles({
    title: {
      color: palette.common.white,
      fontSize: '1.5rem',
      marginLeft: spacing(1 / 2),
    },
    iconRoot: {
      color: palette.common.white,
      marginRight: spacing(1 / 2),
      width: ICON_SIZE,
      height: ICON_SIZE,
    },
    summaryRoot: {
      minHeight: 0,
      padding: 0,
    },
    summaryContent: {
      margin: 0,
    },
    panelExpanded: {
      margin: '0px !important',
    },
    summaryExpanded: {
      minHeight: '0px !important',
      padding: 0,
      height: 0,
    },
    detailsRootSuccess: {
      ...detailsRoot,
      backgroundColor: colors.green[500],
    },
    detailsRoot: {
      ...detailsRoot,
      backgroundColor: colors.secondary[500],
    },
  });
