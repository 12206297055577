import { createSelector } from 'reselect';
import { getPaymentMethods } from 'store/cashiering/selectors';
import Store from 'types/Store/CashieringStore';

const getCardAuthorizations = createSelector(
  getPaymentMethods,
  (paymentMethods) =>
    paymentMethods
      .filter((method) => method.creditCardDetails?.creditCardAuthorizations)
      .flatMap(
        (paymentMethod) =>
          paymentMethod.creditCardDetails.creditCardAuthorizations
      )
);

export const activeCardAuthorizations = createSelector(
  getCardAuthorizations,
  (cardAuthorizations) =>
    cardAuthorizations.filter((authorization) => authorization.isActive)
);

export const activeAuthorizations = (state: Store) =>
  state.cashiering.activeAuthorizations;
