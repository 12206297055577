import { AnyAction } from 'redux';
import { CallApiAction } from 'store/middleware/api';

import { Store } from '@gss/store';

export type GetState = () => Store;

export type ActionCreator = (dispatch: Dispatch, getState: GetState) => any;

type ReduxDispatch<T> = (action: T) => void | Promise<void>;

export type Dispatch = ReduxDispatch<ActionCreator | CallApiAction | AnyAction>;

export const getApiActionTypes = (actionType: string) => ({
  REQUEST: `${actionType}_REQUEST`,
  SUCCESS: `${actionType}_SUCCESS`,
  FAILURE: `${actionType}_FAILURE`,
});

export const getApiSagaActionTypes = (actionType: string) => ({
  REQUEST: `${actionType}_REQUEST`,
  FINISHED: `${actionType}_FINISHED`,
});

export const getApiCallActionTypes = (
  apiActionTypes: ReturnType<typeof getApiActionTypes>
) => Object.values(apiActionTypes);

export type AsyncApiCallThroughActionCreator<
  ActionCreatorType extends (...args: any) => any
> = (...args: Parameters<ActionCreatorType>) => Promise<void>;

export type PropsWithDispatch<Type> = {
  [key in keyof Type]: Type[key] extends AsyncApiCallThroughActionCreator<
    infer ActionCreator
  >
    ? ActionCreator
    : Type[key];
};
