const FETCH_PROPERTY_LOCAL_DATE_TIME_REQUEST =
  'FETCH_PROPERTY_LOCAL_DATE_TIME_REQUEST';
const FETCH_PROPERTY_LOCAL_DATE_TIME_SUCCESS =
  'FETCH_PROPERTY_LOCAL_DATE_TIME_SUCCESS';
const FETCH_PROPERTY_LOCAL_DATE_TIME_FAILURE =
  'FETCH_PROPERTY_LOCAL_DATE_TIME_FAILURE';

const FETCH_CURRENT_DATE_REQUEST = 'FETCH_CURRENT_DATE_REQUEST';
const FETCH_CURRENT_DATE_SUCCESS = 'FETCH_CURRENT_DATE_SUCCESS';
const FETCH_CURRENT_DATE_FAILURE = 'FETCH_CURRENT_DATE_FAILURE';

export default {
  FETCH_PROPERTY_LOCAL_DATE_TIME_REQUEST,
  FETCH_PROPERTY_LOCAL_DATE_TIME_SUCCESS,
  FETCH_PROPERTY_LOCAL_DATE_TIME_FAILURE,
  FETCH_CURRENT_DATE_REQUEST,
  FETCH_CURRENT_DATE_SUCCESS,
  FETCH_CURRENT_DATE_FAILURE,
};
