import { api } from 'config';
import { CALL_API, CallApiAction } from 'store/middleware/api';
import { Configurator } from 'utils';

import types from './types';

export const fetchAllTransactions = (
  reservationId: string,
  extend = Configurator.aggregatorParams.TRANSACTIONS
): CallApiAction => ({
  [CALL_API]: {
    endpoint: `${api.AGGREGATOR.RESERVATION(reservationId)}`,
    params: { extend },
    types: [
      types.FETCH_ALL_TRANSACTIONS_REQUEST,
      types.FETCH_ALL_TRANSACTIONS_SUCCESS,
      types.FETCH_ALL_TRANSACTIONS_FAILURE,
    ],
  },
});
