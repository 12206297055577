import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Configurator, DateManager } from 'utils';

import {
  BasicModal,
  BasicModalSize,
  Button,
  ButtonPattern,
  Text,
  TextGroup,
  TextSize,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { DocumentScanningResultDetails } from '@gss/api/KioskApi/entries';

import './ScannedDataVerificationModal.scss';

const labelTranslationMap: Array<
  [keyof DocumentScanningResultDetails, string]
> = [
  ['firstName', 'FIRST_NAME'],
  ['lastName', 'LAST_NAME'],
  [
    'middleName',
    'CHECK_IN_ID_SCANNING.SCANNED_DATA_VERIFICATION_MODAL.DATA_LABELS.MIDDLE_NAME',
  ],
  [
    'gender',
    'CHECK_IN_ID_SCANNING.SCANNED_DATA_VERIFICATION_MODAL.DATA_LABELS.GENDER',
  ],
  ['nationality', 'NATIONALITY'],
  ['dateOfBirth', 'SHARED.DATE_OF_BIRTH'],
  ['placeOfBirth', 'SHARED.PLACE_OF_BIRTH'],
  ['countryOfBirth', 'SHARED.COUNTRY_OF_BIRTH'],
  ['addressLine1', 'ADDRESS_LINE_1'],
  ['addressLine2', 'SHARED.ADDRESS_LINE_2'],
  ['documentIdType', 'DOCUMENT_TYPE'],
  ['documentNumber', 'SHARED.DOCUMENT_NUMBER'],
  ['expiryDate', 'EXPIRY_DATE'],
  ['issueDate', 'SHARED.ISSUE_DATE'],
  ['countryOfIssue', 'SHARED.COUNTRY_OF_ISSUE'],
  ['placeOfIssue', 'SHARED.PLACE_OF_ISSUE'],
  ['postalCode', 'SHARED.POSTAL_CODE'],
  ['city', 'CITY'],
  ['state', 'STATE'],
];

interface ScannedDataVerificationModalProps {
  scannedDocument: DocumentScanningResultDetails;
  onContinueCheckIn: () => void;
  onBackToScan: () => void;
}

const ScannedDataVerificationModal = ({
  scannedDocument,
  onContinueCheckIn,
  onBackToScan,
}: ScannedDataVerificationModalProps) => {
  const { t, i18n } = useTranslation();

  const dictionaryMapper = useCallback(
    (
      key: keyof DocumentScanningResultDetails,
      value: DocumentScanningResultDetails[keyof DocumentScanningResultDetails]
    ) => {
      switch (key) {
        case 'gender': {
          const data = value as DocumentScanningResultDetails[typeof key];
          const genderDictionary = Configurator.genders.find(
            ({ code }) => code === data?.code
          );

          return genderDictionary?.description
            ? Configurator.getDescription(genderDictionary.description)
            : undefined;
        }

        case 'nationality': {
          const data = value as DocumentScanningResultDetails[typeof key];
          const nationalities = Configurator.getNationalities(i18n.language);
          const nationality = nationalities.find(
            ({ code }) => code === data?.code
          );

          return nationality?.name;
        }

        case 'countryOfBirth':
        case 'countryOfIssue': {
          const data = value as DocumentScanningResultDetails[typeof key];
          const countries = Configurator.getCountries(i18n.language);
          const country = countries.find(({ code }) => code === data?.code);

          return country?.name;
        }

        case 'documentIdType': {
          const data = value as DocumentScanningResultDetails[typeof key];
          const documentType = Configurator.documentTypes.find(
            ({ code }) => code === data?.code
          );

          return documentType?.description
            ? Configurator.getDescription(documentType.description)
            : undefined;
        }

        case 'dateOfBirth':
        case 'issueDate':
        case 'expiryDate': {
          const data = value as DocumentScanningResultDetails[typeof key];
          const validDate =
            typeof data === 'object'
              ? { ...data, month: data.month - 1 }
              : data;

          return data ? DateManager.getFormattedDate(validDate) : undefined;
        }

        case 'state': {
          const data = value as DocumentScanningResultDetails[typeof key];

          return data?.description;
        }

        default:
          return value;
      }
    },
    [i18n.language]
  );

  const mappedScannedDocument = useMemo((): Array<[string, string]> => {
    return labelTranslationMap
      .map(([key, label]) => {
        const value = scannedDocument[key];
        const preparedValue =
          typeof value === 'object' ? dictionaryMapper(key, value) : value;

        return [label, preparedValue];
      })
      .filter(
        ([label, value]) => isDefined(label) && isDefined(value)
      ) as Array<[string, string]>;
  }, [scannedDocument, t]);

  const scannedDocumentDataSplicedToColumns = useMemo((): Array<
    Array<[string, string]>
  > => {
    return mappedScannedDocument.reduce((columns, data, index) => {
      const shouldBeNewColumn = !(index % 10);
      if (shouldBeNewColumn) columns.push([] as Array<[string, string]>);
      columns[columns.length - 1]?.push(data);

      return columns;
    }, [] as Array<Array<[string, string]>>);
  }, [mappedScannedDocument, t]);

  return (
    <BasicModal
      size={BasicModalSize.lg}
      className="with-default-kiosk-components-theme scanned-data-verification-modal"
    >
      <Text size={TextSize.lg} className="spacing-sm">
        {t('CHECK_IN_ID_SCANNING.SCANNED_DATA_VERIFICATION_MODAL.TITLE')}
      </Text>
      <Text className="spacing-top-sm spacing-bottom-sm">
        {t('CHECK_IN_ID_SCANNING.SCANNED_DATA_VERIFICATION_MODAL.HINT')}
      </Text>
      <div className="spacing-lg data-wrapper">
        {scannedDocumentDataSplicedToColumns.map((columns, index) => (
          <div className="data-column" key={`data-column-${index}`}>
            {columns.map(([key, value]) => (
              <TextGroup
                size={TextSize.xs}
                label={t(key)}
                value={value}
                key={[key, value].join('-')}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="spacing-top-sm buttons-wrapper">
        <Button pattern={ButtonPattern.secondary} onClick={onBackToScan}>
          {t('CHECK_IN_ID_SCANNING.BACK_TO_SCAN')}
        </Button>
        <Button onClick={onContinueCheckIn}>{t('CONFIRM')}</Button>
      </div>
    </BasicModal>
  );
};

export default ScannedDataVerificationModal;
