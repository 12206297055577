import {
  ProfileAdditionalName,
  ProfileAdditionalNameTypes,
} from 'types/Api/Profile';

export const findAdditionalName = (
  additionalNames?: ProfileAdditionalName[]
): string | undefined => {
  return additionalNames?.find(
    (name) => name.type === ProfileAdditionalNameTypes.SecondSurname
  )?.value;
};
