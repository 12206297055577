var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"RESERVATION_DETAILS",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":16,"column":34}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"period") || (depth0 != null ? lookupProperty(depth0,"period") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"period","hash":{},"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":22,"column":14}}}) : helper)))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../../partials/textGroup.hbs"),depth0,{"name":"textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"individual") : stack1),"label":__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"PDF.CHECK_OUT_FOLIO.RESERVATION_DETAILS.FOLIO_FOR",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":33,"column":74}}})},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../../partials/textGroup.hbs"),depth0,{"name":"textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"company") : stack1),"label":__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"COMPANY",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":32}}})},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../../partials/textGroup.hbs"),depth0,{"name":"textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"travelAgent") : stack1),"label":__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"TRAVEL_AGENT",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":45,"column":37}}})},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div>\n"
    + ((stack1 = container.invokePartial(require("../../../partials/textGroup.hbs"),depth0,{"name":"textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"address") : stack1),"label":__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"ADDRESS",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":54,"column":32}}}),"type":"column"},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"roomNumber") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":66,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"confirmationNumber") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":72,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../../partials/textGroup.hbs"),depth0,{"name":"textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"roomNumber") : stack1),"label":__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"ROOM_NUMBER",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":63,"column":18},"end":{"line":63,"column":38}}})},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../../partials/textGroup.hbs"),depth0,{"name":"textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"confirmationNumber") : stack1),"label":__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"CONFIRMATION_NUMBER",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":69,"column":18},"end":{"line":69,"column":46}}})},"data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-reservation-details-wrapper .pdf-reservation-details-grid {\n    display: grid;\n    grid-auto-columns: 1fr;\n    grid-auto-flow: column;\n    gap: var(--spacing-xlg);\n  }\n</style>\n\n<div class=\""
    + container.escapeExpression(__default(require("../../../helpers/classNames.ts")).call(alias1,"pdf-reservation-details-wrapper",(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"classNames","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":66}}}))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-bottom-sm","weight":"bold","size":"lg"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-bottom-sm","size":"sm"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  <div class=\"pdf-reservation-details-grid\">\n    <div>\n"
    + ((stack1 = container.invokePartial(require("../../../partials/textGroup.hbs"),depth0,{"name":"textGroup","hash":{"value":((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"guestName") : stack1),"label":__default(require("../../../helpers/i18n.ts")).call(alias1,"PDF.CHECK_OUT_FOLIO.RESERVATION_DETAILS.GUEST_FULL_NAME",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":14},"end":{"line":28,"column":78}}})},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"individual") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":6},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"company") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":6},"end":{"line":42,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"travelAgent") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":6},"end":{"line":48,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"address") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":58,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../helpers/operators.ts")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"roomNumber") : stack1),"||",((stack1 = (depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0)) != null ? lookupProperty(stack1,"confirmationNumber") : stack1),{"name":"operators","hash":{},"data":data,"loc":{"start":{"line":59,"column":10},"end":{"line":59,"column":94}}}),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":4},"end":{"line":74,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});