/* eslint-disable no-invalid-this */
import get from 'lodash.get';

import {
  FieldState,
  ValidationResult,
  ValidationStatuses,
  Validator,
} from '@ac/react-infrastructure';

import { BaseObject } from '@gss/types/shared';
import { ValidationStatusesTree } from '@gss/types/validationStatusesTree';

export class FormValidator<
  P extends BaseObject,
  T extends ValidationStatuses = ValidationStatusesTree<P>
> extends Validator<P, T> {
  public validate = (data: P): T => {
    return super.validate(data);
  };

  public validateSingleField = <DataT>(
    _value: DataT,
    allValues: P,
    metaData: Partial<FieldState<DataT>>
  ): undefined | ValidationResult => {
    const path = metaData.name?.includes('.')
      ? metaData.name.split('.')
      : metaData.name;

    if (!path || !metaData.name) return;

    const statusesTree = super.validate(allValues, path);
    this.isValid = !this.checkIsInValid(statusesTree);

    return get(statusesTree, metaData.name) as undefined | ValidationResult;
  };

  private checkIsInValid = (statuses: ValidationStatuses): boolean => {
    return Object.values(statuses).some((status) => {
      if (!status) return false;
      if (Array.isArray(status)) {
        return Boolean(status.length);
      }
      if (status && typeof status === 'object') {
        return this.checkIsInValid(status);
      }

      return false;
    });
  };
}
