import React, { PureComponent } from 'react';
import { RadioButtons } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './GenerateKeysForm.style';

type BaseSubmitFunction = () => void;
type GenerateKeysOnSubmit = (values: { keys: number }) => void;

export interface PassedProps {
  maxValue: number;
  onSubmit: GenerateKeysOnSubmit | BaseSubmitFunction;
}

interface GanerateKeysFormProps
  extends PassedProps,
    InjectedFormProps,
    WithStyles<typeof styles> {}

class GenerateKeysForm extends PureComponent<GanerateKeysFormProps> {
  public render() {
    const { classes, handleSubmit, maxValue, onSubmit } = this.props;

    return (
      <Form
        onSubmit={handleSubmit(onSubmit as BaseSubmitFunction)}
        className={classes.keysForm}
      >
        <Field component={RadioButtons} name="keys" maxValue={maxValue} />
      </Form>
    );
  }
}

export default compose(
  withStyles(styles),
  reduxForm({
    form: ids.GENERATE_KEYS_FORM,
    initialValues: { keys: 1 },
  })
)(GenerateKeysForm) as (props: PassedProps) => JSX.Element;
