import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const SQUARE_WRAPPER_SIZE = 228;
const MODAL_WIDTH_THIN = 224;
const MODAL_MIN_HEIGHT_THIN = 256;
const MODAL_WIDTH_WIDE = 520;
const MODAL_MIN_HEIGHT_WIDE = 190;
const MODAL_RADIUS = 6;
const TIMER_SIZE = 88;

const position: CSSProperties = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
};

const getWrapperStyle = (palette: any, spacing: any): CSSProperties => ({
  borderRadius: MODAL_RADIUS,
  backgroundColor: palette.common.white,
  padding: spacing(2),
  paddingTop: spacing(4.5),
  boxSizing: 'border-box',
  outline: 'none',
  maxHeight: '100dvh',
  overflowY: 'auto',
  display: 'flex',
  ...position,
});

export default ({ palette, spacing }: Theme) =>
  createStyles({
    thinWrapper: {
      width: MODAL_WIDTH_THIN,
      minHeight: MODAL_MIN_HEIGHT_THIN,
      ...getWrapperStyle(palette, spacing),
    },
    wideWrapper: {
      width: MODAL_WIDTH_WIDE,
      minHeight: MODAL_MIN_HEIGHT_WIDE,
      ...getWrapperStyle(palette, spacing),
    },
    squareWrapper: {
      width: SQUARE_WRAPPER_SIZE,
      height: SQUARE_WRAPPER_SIZE,
      ...getWrapperStyle(palette, spacing),
    },
    button: {
      width: '100%',
    },
    descriptionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    descriptionTitle: {
      fontSize: '1rem',
      marginBottom: spacing(2),
      textAlign: 'center',
    },
    timerWrapper: {
      position: 'relative',
      width: TIMER_SIZE,
      height: TIMER_SIZE,
    },
    timerValue: {
      fontSize: '1.5rem',
      color: palette.primary.main,
      ...position,
    },
    timerCircle: {
      color: palette.secondary.main,
    },
    errorCircle: {
      color: palette.error.main,
    },
    alertIcon: {
      color: palette.primary.main,
      fontSize: '3.25rem',
      ...position,
    },
    fetchingContent: {
      margin: 'auto',
    },
  });
