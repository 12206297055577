import { Action } from '@ac/library-utils/dist/declarations';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';
import { ReduxReducerMap } from '@gss/types/shared';

import { handleGlobalReducer } from '../storeConfiguration/utils';

import { updateDictionaryStatusData } from './utils/updateDictionaryStatusData';
import * as actions from './actions';
import { DictionaryStatuses, LazyLoadedDictionaryState } from './interfaces';
import {
  FailureDictionaryFetchStatesPayload,
  FetchDictionaryPayload,
  SuccessDictionaryFetchStatesPayload,
} from './types';

const initialState: LazyLoadedDictionaryState = {
  states: {},
  districts: {},
};

const reducerMap: ReduxReducerMap<LazyLoadedDictionaryState> = {
  [actions.fetchStates.trigger]: (
    state,
    action: Action<FetchDictionaryPayload>
  ): LazyLoadedDictionaryState => {
    return updateDictionaryStatusData(
      state,
      {
        key: action.payload.countryCode,
      },
      'states',
      DictionaryStatuses.fetching
    );
  },

  [actions.fetchStates.success]: (
    state,
    action: Action<SuccessDictionaryFetchStatesPayload<KioskStateDetails[]>>
  ): LazyLoadedDictionaryState =>
    updateDictionaryStatusData(
      state,
      { key: action.payload.countryCode, data: action.payload.data },
      'states',
      DictionaryStatuses.completed
    ),

  [actions.fetchStates.failure]: (
    state,
    action: Action<FailureDictionaryFetchStatesPayload>
  ): LazyLoadedDictionaryState =>
    updateDictionaryStatusData(
      state,
      { key: action.payload.countryCode },
      'states',
      DictionaryStatuses.failed
    ),

  [actions.fetchDistricts.trigger]: (
    state,
    action: Action<FetchDictionaryPayload>
  ): LazyLoadedDictionaryState =>
    updateDictionaryStatusData(
      state,
      { key: action.payload.countryCode },
      'districts',
      DictionaryStatuses.fetching
    ),

  [actions.fetchDistricts.success]: (
    state,
    action: Action<
      SuccessDictionaryFetchStatesPayload<BaseKioskConfigurationEntity[]>
    >
  ): LazyLoadedDictionaryState =>
    updateDictionaryStatusData(
      state,
      { key: action.payload.countryCode, data: action.payload.data },
      'districts',
      DictionaryStatuses.completed
    ),

  [actions.fetchDistricts.failure]: (
    state,
    action: Action<FailureDictionaryFetchStatesPayload>
  ): LazyLoadedDictionaryState =>
    updateDictionaryStatusData(
      state,
      { key: action.payload.countryCode },
      'districts',
      DictionaryStatuses.failed
    ),

  [actions.resetLazyLoadedDictionaryStore]: (): LazyLoadedDictionaryState =>
    initialState,
};

export const lazyLoadedDictionary =
  handleGlobalReducer<LazyLoadedDictionaryState>(reducerMap, initialState);
