var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"pdf-check-in-reservation-wrapper\">\n    <div class=\"pdf-check-in-reservation-details-wrapper spacing-bottom-xxlg\">\n"
    + ((stack1 = container.invokePartial(require("./components/guestDetailsSection.hbs"),depth0,{"name":"components/guestDetailsSection","hash":{"contacts":(depth0 != null ? lookupProperty(depth0,"contacts") : depth0),"class":"pdf-check-in-reservation-details-item"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("./components/reservationDetailsSection.hbs"),depth0,{"name":"components/reservationDetailsSection","hash":{"class":"pdf-check-in-reservation-details-item"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = container.invokePartial(require("./components/consentList.hbs"),depth0,{"name":"components/consentList","hash":{"data":(depth0 != null ? lookupProperty(depth0,"consents") : depth0),"class":"spacing-bottom-xxlg"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"disclaimer") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":4},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("../../partials/signature.hbs"),depth0,{"name":"signature","hash":{"dateTime":(depth0 != null ? lookupProperty(depth0,"dateTime") : depth0),"signature":(depth0 != null ? lookupProperty(depth0,"signature") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../partials/disclaimer.hbs"),depth0,{"name":"disclaimer","hash":{"content":(depth0 != null ? lookupProperty(depth0,"disclaimer") : depth0),"class":"spacing-bottom-xxlg"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<style>\n  .pdf-check-in-reservation-wrapper .pdf-check-in-reservation-details-wrapper {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    gap: var(--spacing-xlg);\n  }\n\n  .pdf-check-in-reservation-wrapper .pdf-check-in-reservation-details-item {\n    flex: 1;\n  }\n</style>\n\n"
    + ((stack1 = container.invokePartial(require("../../partials/pdfTemplateWrapper.hbs"),depth0,{"name":"pdfTemplateWrapper","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});