var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div>\n"
    + ((stack1 = container.invokePartial(require("../../partials/header.hbs"),depth0,{"name":"header","hash":{"logo":(depth0 != null ? lookupProperty(depth0,"logo") : depth0),"title":__default(require("../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"PDF.CHECK_OUT_FOLIO.TITLE",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":46}}})},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./components/reservationDetails.hbs"),depth0,{"name":"components/reservationDetails","hash":{"reservationDetails":(depth0 != null ? lookupProperty(depth0,"reservationDetails") : depth0),"period":(depth0 != null ? lookupProperty(depth0,"period") : depth0),"class":"spacing-top-xlg spacing-bottom-xxlg"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./components/chargeList.hbs"),depth0,{"name":"components/chargeList","hash":{"tableData":(depth0 != null ? lookupProperty(depth0,"tableData") : depth0),"tableConfig":(depth0 != null ? lookupProperty(depth0,"tableConfig") : depth0)},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../partials/signature.hbs"),depth0,{"name":"signature","hash":{"dateTime":(depth0 != null ? lookupProperty(depth0,"dateTime") : depth0),"signature":(depth0 != null ? lookupProperty(depth0,"signature") : depth0),"class":"spacing-top-sm"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <div data-slot=\"footer\">\n"
    + ((stack1 = container.invokePartial(require("../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"xs"},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression(__default(require("../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"PDF.CHECK_OUT_FOLIO.FOOTER",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":43,"column":8},"end":{"line":43,"column":45}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<style>\n  .pdf-checkout-folio-wrapper {\n    height: 100%;\n    display: flex;\n    flex-direction: column;;\n  }\n\n  .pdf-reservation-details-subsection {\n    display: flex;\n    align-items: center;\n    margin: var(--spacing-lg);\n    gap: var(--spacing-xxs);\n  }\n\n  .pdf-checkout-folio-footer {\n    margin-bottom: var(--spacing-none);\n    margin-top: auto;\n  }\n</style>\n\n"
    + ((stack1 = container.invokePartial(require("../../partials/pdfTemplateWrapper.hbs"),depth0,{"name":"pdfTemplateWrapper","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});