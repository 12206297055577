import { ProfileAnniversaryDateDto } from '@ac/library-api';

import {
  BaseKioskConfigurationEntity,
  RawBaseKioskConfigurationEntity,
} from '../../shared';

export interface RawDocumentScanningResultDetails {
  documentNumber?: string;
  documentIdType?: RawBaseKioskConfigurationEntity;
  expiryDate?: string;
  issueDate?: string;
  countryOfIssue?: RawBaseKioskConfigurationEntity;
  placeOfIssue?: string;
  issuingAuthority?: string;
  firstName?: string;
  alternateFirstName?: string;
  lastName?: string;
  alternateLastName?: string;
  middleName?: string;
  gender?: RawBaseKioskConfigurationEntity;
  nationality?: RawBaseKioskConfigurationEntity;
  country?: RawBaseKioskConfigurationEntity;
  state?: RawBaseKioskConfigurationEntity;
  city?: string;
  postalCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  countryOfBirth?: RawBaseKioskConfigurationEntity;
  placeOfBirth?: string;
  dateOfBirth?: ProfileAnniversaryDateDto;
}

export class DocumentScanningResultDetails {
  public documentNumber?: string;
  public documentIdType?: BaseKioskConfigurationEntity;
  public expiryDate?: string;
  public issueDate?: string;
  public countryOfIssue?: BaseKioskConfigurationEntity;
  public placeOfIssue?: string;
  public issuingAuthority?: string;
  public firstName?: string;
  public alternateFirstName?: string;
  public lastName?: string;
  public alternateLastName?: string;
  public middleName?: string;
  public gender?: BaseKioskConfigurationEntity;
  public nationality?: BaseKioskConfigurationEntity;
  public country?: BaseKioskConfigurationEntity;
  public state?: BaseKioskConfigurationEntity;
  public city?: string;
  public postalCode?: string;
  public addressLine1?: string;
  public addressLine2?: string;
  public countryOfBirth?: BaseKioskConfigurationEntity;
  public placeOfBirth?: string;
  public dateOfBirth?: ProfileAnniversaryDateDto;

  constructor(raw: RawDocumentScanningResultDetails) {
    this.documentNumber = raw.documentNumber;
    this.documentIdType =
      raw.documentIdType &&
      new BaseKioskConfigurationEntity(raw.documentIdType);
    this.expiryDate = raw.expiryDate;
    this.issueDate = raw.issueDate;
    this.countryOfIssue =
      raw.countryOfIssue &&
      new BaseKioskConfigurationEntity(raw.countryOfIssue);
    this.placeOfIssue = raw.placeOfIssue;
    this.issuingAuthority = raw.issuingAuthority;
    this.firstName = raw.firstName;
    this.alternateFirstName = raw.alternateFirstName;
    this.lastName = raw.lastName;
    this.alternateLastName = raw.alternateLastName;
    this.middleName = raw.middleName;
    this.gender = raw.gender && new BaseKioskConfigurationEntity(raw.gender);
    this.nationality =
      raw.nationality && new BaseKioskConfigurationEntity(raw.nationality);
    this.country = raw.country && new BaseKioskConfigurationEntity(raw.country);
    this.state = raw.state && new BaseKioskConfigurationEntity(raw.state);
    this.city = raw.city;
    this.postalCode = raw.postalCode;
    this.addressLine1 = raw.addressLine1;
    this.addressLine2 = raw.addressLine2;
    this.countryOfBirth =
      raw.countryOfBirth &&
      new BaseKioskConfigurationEntity(raw.countryOfBirth);
    this.placeOfBirth = raw.placeOfBirth;
    this.dateOfBirth = raw.dateOfBirth;
  }
}
