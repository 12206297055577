import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { getTheme } from '@LEGACY/store/ui/selectors';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const MuiProvider = ({ children }: PropsWithChildren<unknown>) => {
  const legacyTheme = useSelector(getTheme);

  return (
    <MuiThemeProvider theme={createTheme(legacyTheme)}>
      {children}
    </MuiThemeProvider>
  );
};

export default MuiProvider;
