import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      minHeight: 0,
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    bodyWrapper: {
      padding: 0,
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        flexDirection: 'row',
        height: '100%',
      },
    },
  });
