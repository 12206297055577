import i18next from 'i18next';
import { Task } from 'redux-saga';
import {
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects';

import { LogReporter } from '@ac/kiosk-components';
import { isApiError } from '@ac/library-api';

import { LOG_MESSAGES, LOG_TYPES } from '@gss/configs/constants';
import { REFRESH_CONFIGURATION_INTERVAL } from '@gss/configs/timers';
import { changeAppLanguage } from '@gss/store/configuration/actions';
import { setIsAppInactive } from '@gss/store/mainProcess/actions';
import { getIsInactiveStatus } from '@gss/store/mainProcess/selectors';
import { applySettings, prepareSettings } from '@gss/store/settings/actions';
import { Settings } from '@gss/store/settings/interfaces/settings';
import { fetchConfiguration } from '@gss/store/settings/sagas';
import { SagasGenerator } from '@gss/types/shared';
import { isNetworkConnectionError } from '@gss/utils/errors';

import * as actions from '../actions';

const REFRESH_CONFIGURATION_RETRIES = 3;

function* refreshConfigurationTask(): SagasGenerator {
  yield delay(REFRESH_CONFIGURATION_INTERVAL);
  const isAppInactive = yield select(getIsInactiveStatus);

  if (!isAppInactive) {
    yield take(setIsAppInactive);
  }

  let repeatCount = 1;

  yield put(actions.pendingRefreshConfiguration.trigger());

  while (repeatCount <= REFRESH_CONFIGURATION_RETRIES) {
    repeatCount++;

    try {
      const settings: Settings = yield call(fetchConfiguration, {
        withDefaultLanguage: true,
      });

      yield put(applySettings(settings));

      const defaultLanguage =
        settings.externalSettings?.LANGUAGE.languageCode?.toLowerCase();

      if (
        defaultLanguage &&
        defaultLanguage !== i18next.language.toLowerCase()
      ) {
        yield put(
          changeAppLanguage.trigger({
            language: defaultLanguage,
            skipSettingsRefresh: true,
          })
        );
      }

      break;
    } catch (error) {
      const isNetworkError = isNetworkConnectionError(error);

      if (!isNetworkError) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const errorData = error.data;

        const details = isApiError(errorData)
          ? errorData.details.map((errorDetails) => ({
              code: errorDetails.code,
              message: errorDetails.message,
            }))
          : (error as Error).message;

        LogReporter.log.warning(LOG_TYPES.app, {
          message: LOG_MESSAGES.REFRESH_CONFIGURATION_FAILURE,
          details,
        });

        break;
      }
    }
  }

  yield put(actions.pendingRefreshConfiguration.done());
}

export function* refreshConfigurationTaskListener(): SagasGenerator {
  while (true) {
    yield take(actions.refreshConfigurationTask.start);

    const refreshTask: Task = yield fork(function* () {
      while (true) {
        yield race([
          call(refreshConfigurationTask),
          /**
           * Resets interval of refresh configuration in case some additional refresh happen
           */
          take(prepareSettings.success),
        ]);
      }
    });

    yield take(actions.refreshConfigurationTask.stop);
    yield cancel(refreshTask);
  }
}
