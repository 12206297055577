import { FOOTER_HEIGHT } from 'components/Footer/Footer.style';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    body: {
      paddingBottom: FOOTER_HEIGHT * 2,
      position: 'relative',
    },
    backButton: {
      marginRight: spacing(1),
    },
    additionalFooter: {
      bottom: FOOTER_HEIGHT,
      padding: `0 ${spacing(12.6)}px`,
      [breakpoints.down('sm')]: {
        padding: `0 ${spacing(9.6)}px`,
      },
      justifyContent: 'flex-end',
    },
    additionalFooterSegment: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonDescription: {
      whiteSpace: 'pre-wrap',
      fontSize: '0.875rem',
      color: palette.text.secondary,
      marginLeft: spacing(1),
    },
    costDescription: {
      fontSize: '1rem',
      marginRight: spacing(1),
      color: 'inherit',
    },
    cost: {
      fontSize: '1.5rem',
      color: 'inherit',
    },
    chargesSummary: {
      position: 'absolute',
      bottom: 62,
      padding: spacing(0, 12, 0, 10),
      width: '100%',
      boxSizing: 'border-box',
    },
  });
