import { all, call } from 'redux-saga/effects';

import { SagasGenerator } from '@gss/types/shared';

import {
  keepDeviceLiveTaskListener,
  refreshConfigurationTaskListener,
} from './tasks';

export function* backgroundTasksSagas(): SagasGenerator {
  yield all([
    call(keepDeviceLiveTaskListener),
    call(refreshConfigurationTaskListener),
  ]);
}
