import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Big from 'big.js';
import { FormErrorMessage } from 'components';
import { compose } from 'redux';

import { Grid, IconButton, ListItem, Typography } from '@material-ui/core';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons';
import { WithStyles, withStyles } from '@material-ui/styles';

import QuantityFormField from '../QuantityFormField';

import styles from './OrderSummaryListItem.style';

interface PassedProps {
  title?: string;
  input?: any;
  quantity?: number;
  type?: string;
  price?: number;
  id?: string;
  max?: number;
  isListEmpty?: boolean;
  onAdd?: (itemName: string) => void;
  onRemove?: (itemName: string) => void;
  onDelete?: (itemName: string) => void;
  onFieldFocusToggle?: (itemName: string) => void;
}

interface OrderSummaryListItemProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class OrderSummaryListItem extends PureComponent<OrderSummaryListItemProps> {
  public static defaultProps = {
    price: 0,
    isListEmpty: false,
  };

  public render() {
    const {
      classes,
      title,
      quantity = 0,
      max,
      isListEmpty,
      type,
      t,
      input,
    } = this.props;
    const isRemoveDisabled = quantity < 2;
    const removeIconClass = isRemoveDisabled
      ? classes.removeIconDisabled
      : classes.removeIcon;
    const isOverMaximum = !!max && quantity > max;

    return isListEmpty ? (
      <ListItem className={classes.emptyItemWrapper}>
        <Typography className={classes.title}>{t('CART_IS_EMPTY')}</Typography>
      </ListItem>
    ) : (
      <div className={classes.wrapper}>
        <ListItem className={classes.contentWrapper}>
          <Grid item xs={5}>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
          <Grid item xs={7} container className={classes.panel}>
            <Grid item xs={6} className={classes.panelInput}>
              <IconButton
                className={classes.iconButton}
                disabled={isRemoveDisabled}
                onClick={this.handleRemoveClick}
              >
                <RemoveIcon className={removeIconClass} color="primary" />
              </IconButton>
              <QuantityFormField
                type={type}
                input={input}
                onFocus={this.handleFieldFocusToggle}
                onBlur={this.handleFieldFocusToggle}
                className={classes.inputField}
                error={isOverMaximum}
              />
              <IconButton
                className={classes.iconButton}
                onClick={this.handleAddClick}
                disabled={isOverMaximum}
              >
                <AddIcon className={classes.icon} color="primary" />
              </IconButton>
            </Grid>
            <Grid item xs={4} className={classes.panelElement}>
              <Typography className={classes.title}>
                {this.getItemPrice()}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.panelElement}>
              <IconButton
                className={classes.iconButton}
                onClick={this.handleDeleteClick}
              >
                <DeleteIcon className={classes.icon} color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </ListItem>
        {isOverMaximum && (
          <FormErrorMessage error={t('QUANTITY_EXCEEDS_MAXIMUM')} />
        )}
      </div>
    );
  }

  private handleDeleteClick = () => {
    const { onDelete, id } = this.props;
    if (!onDelete || !id) return;
    onDelete(id);
  };

  private handleRemoveClick = () => {
    const { onRemove, id } = this.props;
    if (!onRemove || !id) return;
    onRemove(id);
  };

  private handleAddClick = () => {
    const { onAdd, id } = this.props;
    if (!onAdd || !id) return;
    onAdd(id);
  };

  private handleFieldFocusToggle = (event: any) => {
    const { onFieldFocusToggle } = this.props;
    if (!onFieldFocusToggle) return;
    onFieldFocusToggle(event.target.name);
  };

  private getItemPrice = () => {
    const { quantity, price } = this.props;
    if (!quantity || !price) return;

    return new Big(quantity).times(price).toFixed(2);
  };
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(OrderSummaryListItem) as (props: PassedProps) => JSX.Element;
