import { asKeyedRoutes } from '../utils/asKeyedRoutes';

import { checkInRoutes } from './checkInRoutes';
import { checkOutRoutes } from './checkOutRoutes';
import { initialStepsRoutes } from './initialStepsRoutes';
import { makeKeysRoutes } from './makeKeysRoutes';

export const routes = asKeyedRoutes({
  ...initialStepsRoutes,
  ...checkInRoutes,
  ...checkOutRoutes,
  ...makeKeysRoutes,
  WELCOME: {
    path: '/',
  },
});
