import { BaseCreateCSSProperties, CSSProperties } from '@material-ui/styles';

type Styles = CSSProperties | BaseCreateCSSProperties;

function addHoverStyles(styles: Styles): Styles {
  return {
    '@media (hover: hover)': {
      '&:hover': {
        ...styles,
      },
    },
    '@media (hover: none)': {
      '&:active': {
        ...styles,
      },
    },
  };
}

export default addHoverStyles;
