import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const INPUT_ROOT_HEIGHT = 44;
const ICON_ROOT_HEIGHT = 40;
const ICON_ROOT_WIDTH = 40;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    selectLabel: {
      fontSize: '0.875rem',
    },
    inputPropsRoot: {
      height: INPUT_ROOT_HEIGHT,
      '& fieldset': {
        border: 'none',
      },
      backgroundColor: palette.common.white,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: `inset 0 0 0 1px ${colors.grey[500]}`,
      borderRadius: spacing(0.5),
    },
    inputPropsInput: {
      padding: 0,
      height: '100%',
      width: '100%',
      paddingLeft: spacing(1.5),
      paddingRight: spacing(1),
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    inputPropsFocused: {
      boxShadow: `inset 0 0 0 2px ${colors.primary[400]}`,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    inputPropsError: {
      boxShadow: `inset 0 0 0 1px ${palette.error.main}`,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    inputLabelShrink: {
      transform: 'translate(0px, -14px) scale(0.75) !important',
      color: 'inherit',
    },
    inputLabelRoot: {
      fontSize: '1rem',
      transform: 'translate(14px, 14px) scale(1)',
    },
    inputLabelFocused: {
      color: colors.primary[400],
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    inputLabelError: {
      fontSize: '1rem',
      color: palette.error.main,
    },
    selectRoot: {
      height: '100%',
    },
    selectWrapper: {
      width: '100%',
      marginTop: spacing(2),
    },
    iconRoot: {
      height: ICON_ROOT_HEIGHT,
      width: ICON_ROOT_WIDTH,
      color: palette.primary.main,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 0,
      pointerEvents: 'none',
    },
  });
