import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const WRAPPER_SIZE = 16;
const ICON_SIZE = 16;
const title = {
  fontSize: '1rem',
  fontWeight: 300,
  color: 'inherit',
  lineHeight: '33px',
};

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    iconWrapper: {
      width: WRAPPER_SIZE,
      height: WRAPPER_SIZE,
      padding: '2px',
      borderRadius: 100,
      backgroundColor: colors.secondary[500],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      color: palette.common.white,
    },
    title: {
      ...title,
      [breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
    },
    buttonTitle: {
      ...title,
      fontStyle: 'normal',
      fontWeight: 'normal',
      marginRight: spacing(),
      marginLeft: spacing(0.5),
      [breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
    },
    clickableLabel: {
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      outline: 'none',
      padding: 0,
      background: 'none',
      cursor: 'pointer',
    },
  });
