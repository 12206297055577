import { AxiosResponse } from 'axios';
import { api } from 'config';
import { getFolios } from 'store/cashiering/selectors';
import { CALL_API } from 'store/middleware/api';
import { getParentVersion, getProfile } from 'store/profile/selectors';
import { getProfileId } from 'store/reservation/selectors';
import { Dispatch, GetState } from 'store/utils/actions';
import { Folio } from 'types/Api/Cashiering';
import {
  BasicIndividualCreateData,
  CompanyToCreate,
  LinkCompanyDataModel,
} from 'types/Api/Profile';
import { Configurator, DateManager } from 'utils';

import {
  PatchCompanyDetailsRequest,
  PatchIndividualDetailsRequest,
  PatchProfileAddressDetailsRequest,
  PatchProfileCommunicationChannelRequest,
  PatchProfileSectionRequest,
} from '@ac/library-api';

import { mapProfileDataForMatchRequest, stringifyObjectData } from './helpers';
import types from './types';

export const refetchProfile = async (
  response: AxiosResponse,
  profileId: string,
  dispatch: Dispatch
) => {
  const isVersionCorrect = response.status !== 412;
  if (isVersionCorrect) return true;
  await dispatch(fetchProfileVersion(profileId));

  return false;
};

export const fetchProfileVersion = (profileId: string) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.PROFILE(profileId),
    types: [
      types.FETCH_PROFILE_VERSION_REQUEST,
      types.FETCH_PROFILE_VERSION_SUCCESS,
      types.FETCH_PROFILE_VERSION_FAILURE,
    ],
  },
});

export const fetchProfile = (profileId: string) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.PROFILE(profileId),
    types: [
      types.FETCH_PROFILE_REQUEST,
      types.FETCH_PROFILE_SUCCESS,
      types.FETCH_PROFILE_FAILURE,
    ],
  },
});

export const fetchProfileList =
  (profileIds: string[]) => (dispatch: Dispatch) => {
    return Promise.all(profileIds.map((id) => dispatch(fetchProfile(id))));
  };

export const fetchCompany = (companyId: string) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.COMPANY(companyId),
    types: [
      types.FETCH_COMPANY_REQUEST,
      types.FETCH_COMPANY_SUCCESS,
      types.FETCH_COMPANY_FAILURE,
    ],
  },
});

export const updateProfileDetails = (
  profileId: string,
  version: string | number,
  data: PatchIndividualDetailsRequest
) => ({
  [CALL_API]: {
    data: {
      details: data,
    },
    endpoint: api.PROFILES.PROFILE(profileId),
    payload: {
      profileId,
      details: data,
    },
    headers: {
      'If-Match': version,
    },
    method: 'PATCH',
    types: [
      types.UPDATE_PROFILE_DETAILS_REQUEST,
      types.UPDATE_PROFILE_DETAILS_SUCCESS,
      types.UPDATE_PROFILE_DETAILS_FAILURE,
    ],
  },
});

export const fetchTravelAgents = () => ({
  [CALL_API]: {
    endpoint: api.PROFILES.TRAVEL_AGENT_BASE,
    paginate: true,
    types: [
      types.FETCH_TRAVEL_AGENTS_REQUEST,
      types.FETCH_TRAVEL_AGENTS_SUCCESS,
      types.FETCH_TRAVEL_AGENTS_FAILURE,
    ],
  },
});

export const fetchIndividualCommunicationChannels = (profileId: string) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.PROFILE(profileId),
    types: [
      types.FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST,
      types.FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS,
      types.FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE,
    ],
  },
});

export const fetchCompanyCommunicationChannels = (profileId: string) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.COMPANY(profileId),
    types: [
      types.FETCH_COMPANY_COMMUNICATION_CHANNELS_REQUEST,
      types.FETCH_COMPANY_COMMUNICATION_CHANNELS_SUCCESS,
      types.FETCH_COMPANY_COMMUNICATION_CHANNELS_FAILURE,
    ],
  },
});

export const fetchTravelAgentCommunicationChannels = (profileId: string) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.TRAVEL_AGENT(profileId),
    types: [
      types.FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST,
      types.FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS,
      types.FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE,
    ],
  },
});

export const updateGuestCommunicationChannels = (
  profileId: string,
  version: string,
  data: Array<
    PatchProfileSectionRequest<PatchProfileCommunicationChannelRequest>
  >
) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.PROFILE(profileId),
    data: {
      communicationChannels: data,
    },
    headers: {
      'If-Match': version,
    },
    payload: data,
    method: 'PATCH',
    types: [
      types.UPDATE_GUEST_COMMUNICATION_CHANNELS_REQUEST,
      types.UPDATE_GUEST_COMMUNICATION_CHANNELS_SUCCESS,
      types.UPDATE_GUEST_COMMUNICATION_CHANNELS_FAILURE,
    ],
  },
});

export const updateIndividualCommunicationChannels = (
  profileId: string,
  version: string,
  data: Array<
    PatchProfileSectionRequest<PatchProfileCommunicationChannelRequest>
  >
) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.PROFILE(profileId),
    data: {
      communicationChannels: data,
    },
    headers: {
      'If-Match': version,
    },
    payload: data,
    method: 'PATCH',
    types: [
      types.UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST,
      types.UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS,
      types.UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE,
    ],
  },
});

export const updateCompanyCommunicationChannels = (
  profileId: string,
  version: string,
  data: Array<
    PatchProfileSectionRequest<PatchProfileCommunicationChannelRequest>
  >
) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.COMPANY(profileId),
    data: {
      communicationChannels: data,
    },
    headers: {
      'If-Match': version,
    },
    payload: data,
    method: 'PATCH',
    types: [
      types.UPDATE_COMPANY_COMMUNICATION_CHANNELS_REQUEST,
      types.UPDATE_COMPANY_COMMUNICATION_CHANNELS_SUCCESS,
      types.UPDATE_COMPANY_COMMUNICATION_CHANNELS_FAILURE,
    ],
  },
});

export const updateTravelAgentCommunicationChannels = (
  profileId: string,
  version: string,
  data: Array<
    PatchProfileSectionRequest<PatchProfileCommunicationChannelRequest>
  >
) => ({
  [CALL_API]: {
    endpoint: api.PROFILES.TRAVEL_AGENT(profileId),
    data: {
      communicationChannels: data,
    },
    headers: {
      'If-Match': version,
    },
    payload: data,
    method: 'PATCH',
    types: [
      types.UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST,
      types.UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS,
      types.UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE,
    ],
  },
});

export const addConsents =
  (profileId: string, consentIds: string[] = []) =>
  (dispatch: Dispatch, getState: GetState) => {
    const consents = consentIds.map((consentId) => {
      const consent = Configurator.consents.find(
        (consent) => consent.id === consentId
      );
      const defaultExpiryPeriod = consent?.defaultExpiryPeriod;

      return {
        consentId,
        expiryDate: defaultExpiryPeriod
          ? DateManager.addDays(
              Configurator.propertyBusinessDate,
              defaultExpiryPeriod
            )
          : null,
        source: Configurator.consentConfig.CONSENT_SOURCE,
        description: Configurator.consentConfig.CONSENT_DESCRIPTION,
      };
    });

    return dispatch({
      [CALL_API]: {
        data: {
          consents,
        },
        method: 'POST',
        checkFailureCondition: (response: AxiosResponse) =>
          refetchProfile(response, profileId, dispatch),
        getOptions: () => ({
          headers: {
            'If-Match': getParentVersion(getState()),
          },
        }),
        endpoint: api.PROFILES.ADD_CONSENTS(profileId),
        payload: {
          consents,
          profileId,
        },
        types: [
          types.ADD_CONSENTS_REQUEST,
          types.ADD_CONSENTS_SUCCESS,
          types.ADD_CONSENTS_FAILURE,
        ],
      },
    });
  };

export const revokeConsents =
  (profileId: string, consentIds: string[] = []) =>
  (dispatch: Dispatch, getState: GetState) => {
    const consents = {
      consentIds,
    };

    return dispatch({
      [CALL_API]: {
        data: consents,
        method: 'POST',
        checkFailureCondition: (response: AxiosResponse) => {
          return refetchProfile(response, profileId, dispatch);
        },
        getOptions: () => ({
          headers: {
            'If-Match': getParentVersion(getState()),
          },
        }),
        endpoint: api.PROFILES.REVOKE_CONSENTS(profileId),
        payload: {
          consents: consentIds,
          profileId,
        },
        types: [
          types.REVOKE_CONSENTS_REQUEST,
          types.REVOKE_CONSENTS_SUCCESS,
          types.REVOKE_CONSENTS_FAILURE,
        ],
      },
    });
  };

export const updateCompanyAddress = (
  companyId: string,
  version: string,
  data: Array<PatchProfileSectionRequest<PatchProfileAddressDetailsRequest>>
) => ({
  [CALL_API]: {
    data: {
      addressDetails: data,
    },
    endpoint: api.PROFILES.COMPANY(companyId),
    headers: {
      'If-Match': version,
    },
    method: 'PATCH',
    types: [
      types.UPDATE_COMPANY_ADDRESSES_REQUEST,
      types.UPDATE_COMPANY_ADDRESSES_SUCCESS,
      types.UPDATE_COMPANY_ADDRESSES_FAILURE,
    ],
  },
});

export const updateCompanyDetails = (
  companyId: string,
  version: string,
  data: PatchCompanyDetailsRequest
) => ({
  [CALL_API]: {
    data: {
      details: data,
    },
    endpoint: api.PROFILES.COMPANY(companyId),
    headers: {
      'If-Match': version,
    },
    method: 'PATCH',
    types: [
      types.UPDATE_COMPANY_DETAILS_REQUEST,
      types.UPDATE_COMPANY_DETAILS_SUCCESS,
      types.UPDATE_COMPANY_DETAILS_FAILURE,
    ],
  },
});

export const fetchCompanies = () => ({
  [CALL_API]: {
    endpoint: api.PROFILES.COMPANY_BASE,
    method: 'GET',
    paginate: true,
    types: [
      types.FETCH_COMPANIES_REQUEST,
      types.FETCH_COMPANIES_SUCCESS,
      types.FETCH_COMPANIES_FAILURE,
    ],
  },
});

export const createCompany = (data: CompanyToCreate) => ({
  [CALL_API]: {
    data,
    endpoint: api.PROFILES.COMPANY_BASE,
    method: 'POST',
    types: [
      types.CREATE_COMPANY_PROFILE_REQUEST,
      types.CREATE_COMPANY_PROFILE_SUCCESS,
      types.CREATE_COMPANY_PROFILE_FAILURE,
    ],
  },
});

export const createIndividual = (data: BasicIndividualCreateData) => ({
  [CALL_API]: {
    data,
    endpoint: api.PROFILES.INDIVIDUAL_BASE,
    method: 'POST',
    types: [
      types.CREATE_INDIVIDUAL_PROFILE_REQUEST,
      types.CREATE_INDIVIDUAL_PROFILE_SUCCESS,
      types.CREATE_INDIVIDUAL_PROFILE_FAILURE,
    ],
  },
});

export const fetchCreateCompanyStatus =
  (companyId: string) => (dispatch: Dispatch) => {
    return dispatch({
      [CALL_API]: {
        endpoint: api.PROFILES.COMPANY(companyId),
        repetitions: 30,
        interval: 1000,
        checkFailureCondition: (response: AxiosResponse) => {
          const { status } = response;

          return status !== 422 && status !== 412;
        },
        types: [
          types.FETCH_CREATE_COMPANY_STATUS_REQUEST,
          types.FETCH_CREATE_COMPANY_STATUS_SUCCESS,
          types.FETCH_CREATE_COMPANY_STATUS_FAILURE,
        ],
      },
    });
  };

export const fetchCreateIndividualStatus =
  (individualId: string) => (dispatch: Dispatch) => {
    return dispatch({
      [CALL_API]: {
        endpoint: api.PROFILES.PROFILE(individualId),
        repetitions: 30,
        interval: 1000,
        checkFailureCondition: (response: AxiosResponse) => {
          return response.status !== 422;
        },
        types: [
          types.FETCH_CREATE_INDIVIDUAL_STATUS_REQUEST,
          types.FETCH_CREATE_INDIVIDUAL_STATUS_SUCCESS,
          types.FETCH_CREATE_INDIVIDUAL_STATUS_FAILURE,
        ],
      },
    });
  };

export const linkCompanyToProfile = (
  companyId: string,
  data: LinkCompanyDataModel
) => ({
  [CALL_API]: {
    data,
    endpoint: api.PROFILES.LINK_COMPANY(companyId),
    method: 'POST',
    types: [
      types.LINK_COMPANY_PROFILE_REQUEST,
      types.LINK_COMPANY_PROFILE_SUCCESS,
      types.LINK_COMPANY_PROFILE_FAILURE,
    ],
  },
});

export const clearProfileErrors = () => ({
  type: types.CLEAR_PROFILE_ERRORS,
});

export const chooseProfile = (profileId: string) => ({
  type: types.CHOOSE_PROFILE,
  payload: profileId,
});

export const fetchProfileFromFolio = (currentFolioProfileId: string) => ({
  [CALL_API]: {
    payload: currentFolioProfileId,
    endpoint: api.PROFILES.PROFILE(currentFolioProfileId),
    types: [
      types.FETCH_PROFILE_FROM_FOLIO_REQUEST,
      types.FETCH_PROFILE_FROM_FOLIO_SUCCESS,
      types.FETCH_PROFILE_FROM_FOLIO_FAILURE,
    ],
  },
});

export const fetchCommunicationChannels =
  () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const folios = getFolios(state);
    const profileId = getProfileId(state);
    const {
      folioTypeCodes: { INDIVIDUAL, COMPANY, TRAVEL_AGENT },
    } = Configurator;
    if (folios.length) {
      folios.map(async (folio: Folio) => {
        const {
          profileId: folioProfileId,
          folioTypeCode: { code },
        } = folio;
        const isGuestFolio = profileId === folioProfileId;
        switch (code) {
          // eslint-disable-next-line no-lone-blocks
          case INDIVIDUAL:
            {
              if (!isGuestFolio)
                dispatch(fetchIndividualCommunicationChannels(folioProfileId));
            }
            break;
          case COMPANY:
            dispatch(fetchCompanyCommunicationChannels(folioProfileId));
            break;
          case TRAVEL_AGENT:
            dispatch(fetchTravelAgentCommunicationChannels(folioProfileId));
            break;
          default:
        }
      });
    }
  };

export const fetchProfilesViews = (ids: string[]) => (dispatch: Dispatch) => {
  const filter = ids.map((id) => `id==${id}`).join(',');

  return dispatch({
    [CALL_API]: {
      endpoint: api.PROFILES.PROFILES_VIEWS(filter),
      types: [
        types.FETCH_PROFILES_VIEWS_REQUEST,
        types.FETCH_PROFILES_VIEWS_SUCCESS,
        types.FETCH_PROFILES_VIEWS_FAILURE,
      ],
    },
  });
};

export const checkEditEmailFormInitialization = (
  wasEditEmailFormInitialized: boolean
) => ({
  payload: wasEditEmailFormInitialized,
  type: types.CHECK_EDIT_EMAIL_FORM,
});

export const checkIndividualProfileMatching =
  (currentProfileId: string) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const profile = getProfile(getState());
    const profileDataForMatching = mapProfileDataForMatchRequest(profile);
    const stringifiedDataForMatching = stringifyObjectData(
      profileDataForMatching
    );

    return dispatch({
      [CALL_API]: {
        endpoint: `${api.PROFILES.INDIVIDUAL_MATCHING}?match=${stringifiedDataForMatching}&filter=profile.id!=${currentProfileId}&includeHighlights=false&includedFields=id`,
        types: [
          types.CHECK_PROFILE_DUPLICATES_REQUEST,
          types.CHECK_PROFILE_DUPLICATES_SUCCESS,
          types.CHECK_PROFILE_DUPLICATES_FAILURE,
        ],
      },
    });
  };

export const setIsGuestAcceptedProfileDuplicateRisk = (
  isGuestAcceptedRisk: boolean
) => ({
  type: types.SET_GUEST_ACCEPTED_PROFILE_DUPLICATE_RISK,
  payload: isGuestAcceptedRisk,
});
