import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const INPUT_SIZE = 44;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    textField: {
      width: INPUT_SIZE,
    },
    inputPropsRoot: {
      height: INPUT_SIZE,
      textAlign: 'center',
      backgroundColor: palette.common.white,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: `inset 0 0 0 1px ${colors.grey[500]}`,
      borderRadius: 4,
    },
    inputPropsInput: {
      border: 'none',
      padding: 0,
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
    },
    inputPropsFocused: {
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: `inset 0 0 0 2px ${palette.primary.light}`,
    },
    textFieldInput: {
      textAlign: 'center',
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  });
