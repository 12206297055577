export const TOGGLE_RESERVATION_HEADER = 'TOGGLE_RESERVATION_HEADER';
export const REFRESH_UI = 'REFRESH_UI';
export const SET_IS_IPAD = 'SET_IS_IPAD';
export const REFRESH_THEME = 'REFRESH_THEME';
export const TOGGLE_DAY_USE_MODAL = 'TOGGLE_DAY_USE_MODAL';

export default {
  REFRESH_THEME,
  TOGGLE_RESERVATION_HEADER,
  REFRESH_UI,
  SET_IS_IPAD,
  TOGGLE_DAY_USE_MODAL,
};
