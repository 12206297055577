import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, TextGroup, TextSize } from '@ac/kiosk-components';

export interface ReviewAddressColumn {
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  city: string | undefined;
  postCode: string | undefined;
  state: string | undefined;
  country: string | undefined;
}

interface AddressColumnProps {
  title?: string;
  address: ReviewAddressColumn;
}

const AddressColumn = ({ title, address }: AddressColumnProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Text hint>{title}</Text>
      <TextGroup
        size={TextSize.xs}
        label={t('ADDRESS_LINE_1')}
        value={address.addressLine1}
      />
      <TextGroup
        size={TextSize.xs}
        label={t('SHARED.ADDRESS_LINE_2')}
        value={address.addressLine2}
      />
      <TextGroup
        size={TextSize.xs}
        label={t('SHARED.POSTAL_CODE')}
        value={address.postCode}
      />
      <TextGroup size={TextSize.xs} label={t('CITY')} value={address.city} />
      <TextGroup size={TextSize.xs} label={t('STATE')} value={address.state} />
      <TextGroup
        size={TextSize.xs}
        label={t('COUNTRY')}
        value={address.country}
      />
    </>
  );
};

export default AddressColumn;
