import { useCallback } from 'react';
import {
  addPaymentMethod,
  getAddPaymentMethodOperationStatus,
} from 'store/cashiering/actions';
import { AsyncApiCallThroughActionCreator } from 'store/utils/actions';
import {
  CreatePaymentMethodDataModel,
  Folio,
  PreAuthorizationData,
} from 'types/Api/Cashiering';
import { Money } from 'types/Api/Shared';

interface Config {
  transactionCodeId: string;
  terminalId?: string;
  workstationId: string;
}

export const useAddingAuthorizationHandler = (
  addPaymentMethodInApi: AsyncApiCallThroughActionCreator<
    typeof addPaymentMethod
  >,
  accountId: string,
  config: Config
) =>
  useCallback(
    async (folio: Folio, paymentValue: Required<Money>) => {
      if (!config.transactionCodeId || !config.workstationId) {
        return;
      }

      await addPaymentMethodInApi(
        accountId,
        getAddingPaymentMethodParams(paymentValue.amount, folio.id, config)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addPaymentMethodInApi, accountId, ...Object.values(config)]
  );

export const useCheckingOperationStatusHandler = (
  preAuthorization: PreAuthorizationData,
  checkAddingPaymentMethodStatusInApi: AsyncApiCallThroughActionCreator<
    typeof getAddPaymentMethodOperationStatus
  >
) =>
  useCallback(async () => {
    if (preAuthorization.isLongRunningProcess) {
      await checkAddingPaymentMethodStatusInApi(
        preAuthorization.operationStatusUrl
      );
    }
  }, [preAuthorization, checkAddingPaymentMethodStatusInApi]);

const getAddingPaymentMethodParams = (
  approvalAmount: number,
  folioId: string | undefined,
  config: Config
): CreatePaymentMethodDataModel => ({
  transactionCodeId: config.transactionCodeId,
  creditCardDetails: {
    approvalAmount,
    terminalId: config.terminalId,
    workstationId: config.workstationId,
    authorizationRule: 'LOSRate',
    folioWindowIds: folioId ? [folioId] : [],
  },
});
