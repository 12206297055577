import { Dispatch } from 'redux';

import { LogReporter } from '@ac/kiosk-components';
import { Config, isApiError, LibraryApiResponse } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

import { LOG_MESSAGES, LOG_TYPES } from '@gss/configs/constants';
import { knownErrorCodes } from '@gss/configs/knownErrorCodes';
import { CustomError, isNetworkConnectionError } from '@gss/utils/errors';

interface PayloadWithLegacyConfig {
  config?: {
    isLegacyApiMiddleware?: boolean;
  };
}

const isLegacyAction = (action: Action<unknown>): boolean => {
  if (!action?.type?.startsWith('@')) {
    return true;
  }

  if (!action?.payload) return false;

  return Array.isArray(action.payload)
    ? action.payload.some(
        (payload) =>
          (payload as PayloadWithLegacyConfig)?.config?.isLegacyApiMiddleware
      )
    : !!(action?.payload as PayloadWithLegacyConfig)?.config
        ?.isLegacyApiMiddleware;
};

export const sagaFailureActionMiddleware = () => (next: Dispatch) => {
  return async (
    action: Action<unknown>
  ): Promise<
    Action<unknown> & {
      payload: unknown;
    }
  > => {
    const actionResult = next(action);

    const legacyAction = isLegacyAction(actionResult);
    const isCustomError = Boolean(actionResult?.payload instanceof CustomError);
    if (isCustomError || legacyAction) return actionResult;

    if (actionResult?.error || actionResult.type.includes('FAILURE')) {
      const errorData = (
        actionResult?.payload as Record<'data', unknown> | undefined
      )?.data;

      if (isApiError(errorData)) {
        const errorsToSkip = Object.values(knownErrorCodes).map(
          ({ code }) => code
        );
        const loggingShouldBeSkip = errorData.details.some(({ code }) =>
          errorsToSkip.includes(code)
        );

        if (loggingShouldBeSkip) return actionResult;

        const apiResponse = actionResult.payload as
          | LibraryApiResponse<unknown>
          | undefined;
        const apiConfig = apiResponse?.config as Config | undefined;

        LogReporter.log.error(
          LOG_TYPES.app,
          {
            message: LOG_MESSAGES.API_FAILURE_ACTION,
            details: {
              action: actionResult.type,
              details: errorData.details.map((detail) => ({
                code: detail.code,
                message: detail.message,
              })),
              url: apiConfig?.url,
              method: apiConfig?.method,
              status: apiResponse?.status,
              statusTest: apiResponse?.statusText,
            },
          },
          true
        );
      } else {
        LogReporter.log.error(
          LOG_TYPES.app,
          {
            message: LOG_MESSAGES.SAGA_FAILURE_ACTION,
            details: {
              action: actionResult.type,
              message: isNetworkConnectionError(actionResult?.payload)
                ? 'Network connection Error'
                : (actionResult?.payload as Error | undefined)?.message,
            },
          },
          true
        );
      }
    }

    return actionResult;
  };
};
