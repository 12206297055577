import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  LanguageButtonGroup,
  LanguageSelector,
  SelectOption,
} from '@ac/kiosk-components';

import { changeAppLanguage } from '@gss/store/configuration/actions';
import {
  getExternalSettings,
  getSupportedUiLanguages,
} from '@gss/store/settings/selectors';

export enum SupportedLanguagesSelectorType {
  buttonGroup = 'buttonGroup',
  modalSelector = 'modalSelector',
}

interface SupportedLanguagesSelectorProps {
  className?: string;
  type?: SupportedLanguagesSelectorType;
}

export const SupportedLanguagesSelector = ({
  className,
  type = SupportedLanguagesSelectorType.modalSelector,
}: SupportedLanguagesSelectorProps): JSX.Element => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const externalSettings = useSelector(getExternalSettings);
  const supportedUiLanguages = useSelector(getSupportedUiLanguages);

  const languageOptions = useMemo((): SelectOption[] | string[] => {
    const supportedLanguages = externalSettings?.LANGUAGE.supportedLanguages;

    if (!supportedLanguages || !supportedUiLanguages) {
      return [];
    }

    if (type === SupportedLanguagesSelectorType.buttonGroup) {
      return supportedLanguages.map((language) => language.toLowerCase());
    }

    return supportedUiLanguages
      .filter(({ code }) => code && supportedLanguages.includes(code))
      .map(
        (language): SelectOption => ({
          value: language.code?.toLowerCase() as string,
          title: language.nativeLanguageName || language.name || '',
        })
      );
  }, [
    externalSettings?.LANGUAGE.supportedLanguages,
    supportedUiLanguages,
    type,
  ]);

  const handleLanguageSelectChange = (language: string): void => {
    dispatch(
      changeAppLanguage.trigger({
        language,
      })
    );
  };

  return type === SupportedLanguagesSelectorType.modalSelector ? (
    <LanguageSelector
      className={classNames('with-default-kiosk-components-theme', className)}
      currentLanguageCode={i18n.language}
      languageOptions={languageOptions as SelectOption[]}
      onLanguageSelect={handleLanguageSelectChange}
    />
  ) : (
    <LanguageButtonGroup
      currentLanguageCode={i18n.language}
      className={className}
      languageOptions={languageOptions as string[]}
      onLanguageSelect={handleLanguageSelectChange}
    />
  );
};
