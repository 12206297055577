import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddressForm } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { initialize, InjectedFormProps, submit } from 'redux-form';
import {
  addBillingInstruction,
  applyBillingInstruction,
  getApplyBillingInstructionStatus,
} from 'store/cashiering/actions';
import { getBillingInstructions } from 'store/cashiering/selectors';
import {
  fetchCompany,
  updateCompanyAddress,
  updateCompanyDetails,
} from 'store/profile/actions';
import { getAddress } from 'store/profile/selectors';
import { getReservation } from 'store/reservation/selectors';
import { getCompanyProfileIds } from 'store/selectors';
import { Address } from 'types/Api/Profile';
import Store from 'types/Store';

import {
  PatchProfileAddressDetailsRequest,
  PatchProfileSectionRequest,
} from '@ac/library-api';

import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import CompanyDetailsForm from '../CompanyDetailsForm';
import { getLinkedCompany } from '../store/selectors';

import styles from './InvoiceForm.style';

const GRID_SPACING = 2;

interface PassedProps {
  onSubmit: () => void;
  setSubmitState: (isFormValid: boolean) => void;
  selectedCompanyId: string;
}

interface InvoiceFormProps
  extends PassedProps,
    InjectedFormProps,
    WithTranslation,
    WithStyles<typeof styles> {
  linkedCompanyIds: string[];
  fetchCompany: typeof fetchCompany;
  submit: typeof submit;
  applyBillingInstruction: typeof applyBillingInstruction;
  getApplyBillingInstructionStatus: typeof getApplyBillingInstructionStatus;
  updateCompanyAddress: typeof updateCompanyAddress;
  updateCompanyDetails: typeof updateCompanyDetails;
  addBillingInstruction: typeof addBillingInstruction;
  billingInstructions: {
    targetFolioId: string;
    operationId: string;
  };
  expanded: boolean;
  linkedCompany: {
    id: string;
    version: string;
    addresses: Address[];
    details: any;
  };
  reservation: {
    accountId: string;
  };
  onInitialize: (form: string, data: object) => void;
}

interface InvoiceFormState {
  isCompanyDetailsFormValid: boolean;
  isAddressFormValid: boolean;
  [key: string]: boolean;
}

class InvoiceForm extends PureComponent<InvoiceFormProps, InvoiceFormState> {
  public state = {
    isCompanyDetailsFormValid: false,
    isAddressFormValid: false,
  };

  public componentDidUpdate() {
    const { setSubmitState } = this.props;
    const { isAddressFormValid, isCompanyDetailsFormValid } = this.state;
    setSubmitState(isAddressFormValid && isCompanyDetailsFormValid);
  }

  public render() {
    const { linkedCompany } = this.props;
    const companyAddress = linkedCompany
      ? getAddress(linkedCompany.addresses)
      : undefined;

    return (
      <Grid container spacing={GRID_SPACING}>
        <Grid container item xs={12} sm={12} md={6}>
          <CompanyDetailsForm
            setSubmitState={this.setCompanyDetailsFormValidationState}
            onSubmit={this.onDetailsFormSubmit}
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={6}>
          <AddressForm
            shouldInitialize={false}
            companyAddress={companyAddress}
            isCompany
            setSubmitState={this.setAddressFormValidationState}
            onSubmit={this.onAddressFormSubmit}
            isTitleDisabled
            isSubtitleDisabled
          />
        </Grid>
      </Grid>
    );
  }

  private onDetailsFormSubmit = async (values: any) => {
    const {
      updateCompanyDetails,
      linkedCompany: { id, version: parentVersion },
      submit,
    } = this.props;

    await updateCompanyDetails(id, parentVersion, {
      ...values,
      businessSegmentCodes: [],
      industryCodes: [],
    });

    submit(ids.ADDRESS_FORM);
  };

  private setInvoiceFormValidationState = (
    isFormValid: boolean,
    key: string
  ) => {
    const { setSubmitState } = this.props;
    this.setState({ [key]: isFormValid });
    const { isAddressFormValid, isCompanyDetailsFormValid } = this.state;
    setSubmitState(isAddressFormValid && isCompanyDetailsFormValid);
  };

  private setAddressFormValidationState = (isFormValid: boolean) => {
    this.setInvoiceFormValidationState(isFormValid, 'isAddressFormValid');
  };

  private setCompanyDetailsFormValidationState = (isFormValid: boolean) => {
    this.setInvoiceFormValidationState(
      isFormValid,
      'isCompanyDetailsFormValid'
    );
  };

  private onAddressFormSubmit = async (
    data: PatchProfileSectionRequest<PatchProfileAddressDetailsRequest>
  ) => {
    const {
      updateCompanyAddress,
      linkedCompany: { id, version },
      onSubmit,
    } = this.props;

    await updateCompanyAddress(id, (parseInt(version, 10) + 1).toString(), [
      data,
    ]);
    onSubmit();
  };
}

const mapStateToProps = (state: Store | any, props: InvoiceFormProps) => ({
  billingInstructions: getBillingInstructions(state),
  linkedCompanyIds: getCompanyProfileIds(state),
  reservation: getReservation(state),
  linkedCompany: getLinkedCompany(state, props.selectedCompanyId),
});

const mapDispatchToProps = {
  submit,
  addBillingInstruction,
  applyBillingInstruction,
  getApplyBillingInstructionStatus,
  fetchCompany,
  updateCompanyDetails,
  updateCompanyAddress,
  onInitialize: initialize,
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(InvoiceForm) as (props: PassedProps) => JSX.Element;
