var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":20,"column":13}}}) : helper)))
    + ":\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":23,"column":13}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .text-group-wrapper {\n    vertical-align: bottom;\n    line-height: var(--spacing-xxlg);\n  }\n\n  .text-group-wrapper.inline .text-group-element {\n    display: inline;\n  }\n\n  .text-group-wrapper.column .text-group-element {\n    display: block;\n  }\n</style>\n\n<div\n  class=\""
    + container.escapeExpression(__default(require("../helpers/classNames.ts")).call(alias1,"text-group-wrapper spacing-none",__default(require("../helpers/operators.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"||","inline",{"name":"operators","hash":{},"data":data,"loc":{"start":{"line":17,"column":56},"end":{"line":17,"column":86}}}),(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"classNames","hash":{},"data":data,"loc":{"start":{"line":17,"column":9},"end":{"line":17,"column":94}}}))
    + "\"\n>\n"
    + ((stack1 = container.invokePartial(require("./text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-right-xs text-group-element","weight":"light","size":"s"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-none text-group-element","size":"sm"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});