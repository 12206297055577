import State from 'types/Store/UiStore';

export const isReservationHeaderExtended = (state: State) =>
  state.ui.isReservationHeaderExtended;

export const getTheme = (state: State) => state.ui.theme;

export const getScreenSaverMessages = (state: State) =>
  state.ui.screenSaverMessages;

export const isIPad = (state: State) => state.ui.isIPad;

export const isDayUseModalOpen = (state: State) => state.ui.isDayUseModalOpen;
