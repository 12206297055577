export enum ConfirmationFormProperties {
  purposeOfStay = 'purposeOfStay',
  termsAndCondition = 'termsAndCondition',
}

export interface ConfirmationFormValues {
  [ConfirmationFormProperties.purposeOfStay]?: string;
  [ConfirmationFormProperties.termsAndCondition]: {
    [key: string]: boolean;
  };
}
