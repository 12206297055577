import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Configurator } from 'utils';

import { BasicModalSize, ConfirmModal } from '@ac/kiosk-components';

interface QuickCheckOutModalProps {
  onCancel?: () => void;
  onConfirm?: () => void;
}

const QuickCheckOutModal = ({
  onCancel,
  onConfirm,
}: QuickCheckOutModalProps) => {
  const { t, i18n } = useTranslation();

  const modalDescription = useMemo(() => {
    const { KIOSK_CUSTOM_FAST_TRACK_CHECKOUT_MESSAGE } =
      Configurator.getTranslationCodes();

    return Configurator.getTranslation(
      KIOSK_CUSTOM_FAST_TRACK_CHECKOUT_MESSAGE
    );
  }, [i18n.language]);

  return (
    <ConfirmModal
      size={BasicModalSize.lg}
      header={t('MODALS.QUICK_CHECK_OUT.TITLE')}
      description={modalDescription}
      onPrimaryClick={onConfirm}
      onSecondaryClick={onCancel}
      primaryButtonLabel={t('MODALS.QUICK_CHECK_OUT.CONFIRM_BUTTON')}
      secondaryButtonLabel={t('MODALS.QUICK_CHECK_OUT.CANCEL_BUTTON')}
    />
  );
};

export default QuickCheckOutModal;
