import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import {
  Button,
  ButtonPattern,
  Circular,
  IconTypes,
} from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './Confirm.style';

interface PassedProps {
  onContinue?: () => void;
  onCancel?: () => void;
  currentKey?: number;
  keysNumber: number;
}

interface ConfirmProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class Confirm extends PureComponent<ConfirmProps> {
  public render() {
    const { classes, t, onContinue, currentKey, onCancel, keysNumber } =
      this.props;

    return (
      <Grid className={classes.content}>
        <Grid className={classes.successWrapper}>
          <Circular icon={IconTypes.key} className={classes.circular} />
        </Grid>
        <Grid className={classes.descriptionWrapper}>
          <Typography className={classes.title}>
            {t('MAKE_WITH_COUNT', { current: currentKey, max: keysNumber })}
          </Typography>
          <Typography className={classes.subtitle}>
            {t('PLACE_KEY_ON_CODER')}
          </Typography>
          <Grid className={classes.buttonWrapper}>
            <Button onClick={onCancel} pattern={ButtonPattern.secondary}>
              {t('CANCEL')}
            </Button>
            <Button onClick={onContinue}>{t('ENCODE_KEY')}</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(Confirm) as (
  props: PassedProps
) => JSX.Element;
