import { AnyAction } from 'redux';
import getLocalStorageSize from 'utils/getLocalStorageSize';

import { LogReporter } from '@ac/kiosk-components';
import {
  ApiErrorDetail,
  isApiError,
  LibraryApiResponse,
} from '@ac/library-api';

import { LOG_MESSAGES, LOG_TYPES } from '@gss/configs/constants';

export const reportReduxActionError = async (
  error: unknown,
  action?: AnyAction
) => {
  let errorData = (error as LibraryApiResponse<any>)?.data;

  if (errorData instanceof Blob && errorData.type === 'application/json') {
    errorData = JSON.parse(await errorData.text());
  }

  if (
    isApiError(errorData) ||
    (error as LibraryApiResponse<unknown>)?.request
  ) {
    const libraryApiResponse = error as LibraryApiResponse<unknown>;

    LogReporter.log.error(
      LOG_TYPES.app,
      {
        message: LOG_MESSAGES.API_FAILURE,
        details: {
          action: action?.type,
          details: errorData?.details?.map((detail: ApiErrorDetail) => ({
            code: detail?.code,
            message: detail?.message,
          })),
          url: (libraryApiResponse?.config as Record<string, unknown>)?.url,
          method: (libraryApiResponse?.config as Record<string, unknown>)
            ?.method,
          status: libraryApiResponse?.status,
          statusTest: libraryApiResponse?.statusText,
        },
      },
      true
    );
  } else {
    LogReporter.log.error(
      LOG_TYPES.app,
      {
        message: LOG_MESSAGES.REDUX_EXCEPTION,
        details: {
          errorMessage: action?.payload?.message || (error as Error)?.message,
          action: action?.type,
          storageSize: getLocalStorageSize(),
        },
      },
      true
    );
  }
};
