export default {
  ROOM_ATTRIBUTE: 'Attribute',
  ATTRIBUTE_GROUP: 'AttributeGroup',
  MEMBERSHIP_LEVEL: 'MembershipLevel',
  MEMBERSHIP_TYPE: 'MembershipType',
  PROPERTY_FLOOR: 'Floor',
  ROOM_LOCATION: 'RoomLocation',
  WORKSTATION: 'Workstation',
  WORKSTATION_DEVICE: 'WorkstationDeviceLink',
  TRANSACTION_CODES: 'TransactionCode',
  CASHIERS: 'Cashier',
  RATE_PLANS: 'rate-plans',
  PURCHASE_ELEMENTS: 'purchase-elements',
  TAX_RULES: 'TaxRule',
  FOLIO_STYLE: 'FolioStyle',
  PROFILE_TYPE: 'ProfileType',
  AGE_BUCKETS: 'age-buckets',
};
