import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Box, Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './ChargesListSummary.style';

export interface PassedProps {
  folioBalance: number;
  className?: string;
}

interface ChargesListSummaryProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

const ChargesListSummary = ({
  t,
  classes,
  className,
  folioBalance = 100,
}: ChargesListSummaryProps) => (
  <Grid className={className}>
    <div className={classes.divider} />
    <div className={classes.backgroundCover}>
      <Box className={classes.rowContainer}>
        <Typography>{t('TOTAL_PAYMENT')}</Typography>
        <Typography className={classes.balance}>
          {folioBalance.toFixed(2)}
        </Typography>
      </Box>
    </div>
  </Grid>
);

export default compose(
  withTranslation(),
  withStyles(styles)
)(ChargesListSummary) as (props: PassedProps) => JSX.Element;
