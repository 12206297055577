import { combineReducers, Reducer } from 'redux';

import { Action } from '@ac/library-utils/dist/declarations';

import {
  legacyReducers,
  legacyRootReducerEnchanter,
} from '@LEGACY/store/reducers';

import { Store } from '../interfaces';

import { globalReducers } from './globalReducers';

export const createRootReducer = (): Reducer<Store, Action> => {
  return legacyRootReducerEnchanter(
    combineReducers<Store, Action>({
      ...legacyReducers,
      ...globalReducers,
    })
  );
};
