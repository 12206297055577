import { useEffect } from 'react';
import { History } from 'history';
import { Router } from 'utils';
import { Path } from 'utils/Router';

export function useCorectStep(
  history: History<unknown>,
  isLoading: boolean,
  hasError: boolean,
  configureSteps: () => void,
  isStepAvailable: () => boolean
) {
  useEffect(() => {
    if (isLoading || hasError) {
      return;
    }

    let nextURL = Router.nextStepURL;
    configureSteps();
    if (!Router.hasAllowedURL(Path.checkIn, nextURL)) {
      nextURL = Router.nextStepURL;
    }

    if (!isStepAvailable()) {
      history.replace(nextURL);
    }
  });
}
