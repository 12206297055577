import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Body, Footer, ImageHeader, View } from 'components';
import { compose } from 'redux';
import { isReservationDayUse } from 'store/reservation/selectors';
import {
  getRoomFloor,
  getRoomLocation,
  getRoomNumber,
} from 'store/room/selectors';
import Store from 'types/Store';
import { Configurator } from 'utils';
import { paths } from 'utils/Router';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CheckInComplete.style';
import DayUseDetails from './DayUseDetails';

const INITIAL_TIMEOUT = 20;
const { CHECK_IN_CONF_MESSAGE } = Configurator.getTranslationCodes();
const { name } = Configurator.propertySettings;

interface PassedProps {}

interface CheckInCompleteState {
  secondsRemaining: number;
}

interface CheckInCompleteProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  roomNumber: string | number;
  floor: string | number;
  wing: string;
  roomLocation: string;
  roomFloor: string;
  isReservationDayUse: boolean;
}

class CheckInComplete extends PureComponent<
  CheckInCompleteProps,
  CheckInCompleteState
> {
  public static defaultProps = {
    roomNumber: '',
    floor: '',
    wing: '',
  };

  public state = {
    secondsRemaining: INITIAL_TIMEOUT,
  };

  private timer: number | undefined;

  public componentDidMount = async () => {
    this.startTimer();
  };

  public render() {
    const { classes, t, roomNumber, roomFloor, isReservationDayUse } =
      this.props;
    const { secondsRemaining } = this.state;
    const timeRemaining = t('SECONDS', { count: secondsRemaining });

    return (
      <View hideCounter>
        <ImageHeader
          logo={Configurator.logo}
          title={
            `${Configurator.getTranslation(CHECK_IN_CONF_MESSAGE)}` ||
            `${t('PLEASANT_STAY')} ${name}`
          }
        />
        <Body className={classes.body}>
          <div className={classes.reservation}>
            <Typography className={classes.text}>
              {t('YOUR_ROOM_NUMBER')}
            </Typography>
            <Grid className={classes.additionalInfo}>
              <Typography className={classes.content}>{roomNumber}</Typography>
              {isReservationDayUse && <DayUseDetails />}
            </Grid>
            <div className={classes.subContent}>
              {!!roomFloor && (
                <Typography>{t('FLOOR', { floor: roomFloor })}</Typography>
              )}
              {/* <Typography>
                {roomLocation}
              </Typography> */}
            </div>
          </div>
          {/* <Typography className={classes.text}>
            { `${Configurator.getTranslation(CHECK_IN_CONF_MESSAGE)}` ||
              `${t('PLEASANT_STAY')} ${name}` }
          </Typography> */}
        </Body>
        <Footer className={classes.footer}>
          <div className={classes.footerContent}>
            <Typography className={classes.footerText}>
              {`${t('SCREEN_WILL_CLOSE')} ${timeRemaining}`}
            </Typography>
            <Button pattern={ButtonPattern.secondary} onClick={this.onClose}>
              {t('CLOSE')}
            </Button>
          </div>
        </Footer>
      </View>
    );
  }

  private startTimer = () => {
    this.timer = window.setInterval(this.decrementTime, 1000);
  };

  private decrementTime = () => {
    const { secondsRemaining } = this.state;
    if (secondsRemaining > 0) {
      this.setState({
        secondsRemaining: secondsRemaining - 1,
      });
    } else {
      this.handleTimerEnd();
    }
  };

  private handleTimerEnd = () => {
    clearInterval(this.timer);
    this.changeRoute(paths.WELCOME);
  };

  private changeRoute = (route: string) => {
    const { history } = this.props;
    history.push(route);
  };

  private onClose = () => {
    this.changeRoute(paths.WELCOME);
  };
}

const mapStateToProps = (state: Store) => ({
  roomNumber: getRoomNumber(state),
  roomLocation: getRoomLocation(state),
  roomFloor: getRoomFloor(state),
  isReservationDayUse: isReservationDayUse(state),
});

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(CheckInComplete) as (props: PassedProps) => JSX.Element;
