import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BUTTON_WIDTH = 44;
const BUTTON_HEIGHT = 44;
const ICON_WIDTH = 33;
const ICON_HEIGHT = 33;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    sliderButton: {
      width: BUTTON_WIDTH,
      height: BUTTON_HEIGHT,
      margin: spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 0,
    },
    buttonIcon: {
      width: ICON_WIDTH,
      height: ICON_HEIGHT,
    },
  });
