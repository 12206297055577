import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const FONT_SIZE = '0.875rem';
const title = {
  fontWeight: 300,
  color: 'inherit',
  fontSize: FONT_SIZE,
};

const MODAL_WIDTH = 474;
const MODAL_MIN_HEIGHT = 300;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginRight: spacing(2),
      marginBottom: spacing(4),
    },
    modalWrapper: {
      width: MODAL_WIDTH,
      minHeight: MODAL_MIN_HEIGHT,
    },
    emailTitle: {
      ...title,
      marginTop: spacing(1),
    },
    subtitle: {
      color: 'inherit',
      fontSize: FONT_SIZE,
    },
    link: {
      ...title,
      color: palette.primary.main,
      textDecoration: 'underline',
    },
    singleEmail: {
      textAlign: 'right',
      fontSize: '0.875rem',
    },
  });
