import React, {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  useContext,
} from 'react';
import classNames from 'classnames';
import { ListConfigContext } from 'components/List/List';
import { RowItemProps } from 'components/List/RowItem';

import { ListItem } from '@material-ui/core';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { WithStyles, withStyles } from '@material-ui/styles';

import { rowStyle } from './Row.style';

interface ListRowProps extends WithStyles<typeof rowStyle> {
  className?: string;
  spacing?: GridSpacing;
}

const ListRow = ({
  children,
  classes,
  className,
  spacing,
}: PropsWithChildren<ListRowProps>) => {
  const { cellSpacing, fontSize, columns = [] } = useContext(ListConfigContext);

  return (
    <ListItem className={classNames(classes.row, className)}>
      <Grid container spacing={spacing ?? cellSpacing}>
        {Children.map(children, (child, index) => {
          const gridProps = columns[index];

          return isValidElement(child) && (gridProps || fontSize)
            ? cloneElement(child, {
                gridProps: child?.props?.gridProps ?? gridProps,
                fontSize: child?.props?.fontSize ?? fontSize,
              } as RowItemProps)
            : child;
        })}
      </Grid>
    </ListItem>
  );
};

export default withStyles(rowStyle)(ListRow);
