import { RawCreditCardBindingItem } from '@ac/library-api';

import { SettingsCodeMap } from '../../utils';

export interface GeneralSettingsState {
  SEND_CHECK_IN_CONFIRMATION?: boolean;
  TRANSACTION_CODE_FOR_CREDIT_CARD?: string;
  KIOSK_IFC_CASHIER_NUMBER?: string;
  DEFAULT_EMAIL_TYPE?: string;
  DEFAULT_PHONE_TYPE?: string;
  DEFAULT_GUEST_ADDRESS_TYPE?: string;
  DEFAULT_COMPANY_ADDRESS_TYPE?: string;
  KIOSK_MINIBAR_ITEMS?: string[];
  CHECK_IN_BEFORE_ARRIVAL?: number;
  KIOSK_PRE_AUTH_RULE?: RawCreditCardBindingItem;
  SHOW_CHECK_IN?: boolean;
  SHOW_CHECK_OUT?: boolean;
  SHOW_GENERATE_KEY?: boolean;
  CHECK_IN_BY_SURNAME_ONLY?: boolean;
  SHOW_CONF_NUMBER?: boolean;
  SHOW_DUE_DATE?: boolean;
  SHOW_NO_OF_NIGHTS?: boolean;
  SHOW_EXPAND_FOR_RESERVATION_HEADER?: boolean;
  SHOW_RATE_PLAN?: boolean;
  ALLOW_KIOSK_PROFILE_MATCH?: boolean;
  ALLOW_KIOSK_PROFILE_DUPLICATES?: boolean;
  PRE_AUTH_DEDUCT_BI?: boolean;
  FAST_TRACK_CHECK_OUT?: boolean;
}

export const generalSettingsCodesMap: SettingsCodeMap<GeneralSettingsState> = {
  SEND_CHECK_IN_CONFIRMATION: {
    key: 'SENDCHECKINCONFIRMATION',
  },
  TRANSACTION_CODE_FOR_CREDIT_CARD: {
    key: 'TRANSACTIONCODEFORCREDITCARD',
  },
  KIOSK_IFC_CASHIER_NUMBER: {
    key: 'KIOSKUSEDCASHIERID',
  },
  DEFAULT_EMAIL_TYPE: {
    key: 'KIOSKDEFAULTEMAIL',
  },
  DEFAULT_PHONE_TYPE: {
    key: 'KIOSKDEFAULTPHONE',
  },
  DEFAULT_GUEST_ADDRESS_TYPE: {
    key: 'KIOSKDEFAULTGUESTADDRESS',
  },
  DEFAULT_COMPANY_ADDRESS_TYPE: {
    key: 'KIOSKDEFAULTCOMPANYADDRESS',
  },
  KIOSK_MINIBAR_ITEMS: {
    key: 'KIOSKMINIBARITEMS',
  },
  CHECK_IN_BEFORE_ARRIVAL: {
    key: 'CHECKINBEFOREARRIVAL',
  },
  KIOSK_PRE_AUTH_RULE: {
    key: 'PREAUTHORIZATIONRULE',
  },
  SHOW_CHECK_IN: {
    key: 'SHOWCHECKIN',
  },
  SHOW_CHECK_OUT: {
    key: 'SHOWCHECKOUT',
  },
  SHOW_GENERATE_KEY: {
    key: 'SHOWGENERATEKEY',
  },
  CHECK_IN_BY_SURNAME_ONLY: {
    key: 'CHECKINBYSURNAMEONLY',
  },
  SHOW_CONF_NUMBER: {
    key: 'SHOWCONFNUMBER',
  },
  SHOW_DUE_DATE: {
    key: 'SHOWDUEDATE',
  },
  SHOW_NO_OF_NIGHTS: {
    key: 'SHOWNOOFNIGHTS',
  },
  SHOW_EXPAND_FOR_RESERVATION_HEADER: {
    key: 'KIOSKRESERVATIONHEADEREXPAND',
  },
  SHOW_RATE_PLAN: {
    key: 'KIOSKRPONRSVNPRESENCE',
  },
  ALLOW_KIOSK_PROFILE_MATCH: {
    key: 'KIOSKPROFILEMATCH',
  },
  ALLOW_KIOSK_PROFILE_DUPLICATES: {
    key: 'KIOSKPROFILEDUPLICATES',
  },
  PRE_AUTH_DEDUCT_BI: {
    key: 'PREAUTHDEDUCTBI',
  },
  FAST_TRACK_CHECK_OUT: {
    key: 'ACTIVATEFASTTRACKCHECKOUT',
  },
};
