import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const {
  colorCodes: { COUNTER_FONT_COLOR },
} = Configurator;

const SUBTITLE_HEIGHT = 32;
const COUNTER_HEIGHT = 5;

export default ({ palette, spacing, transitions }: Theme) =>
  createStyles({
    fillWrapper: {
      width: '100%',
      height: COUNTER_HEIGHT,
      display: 'flex',
    },
    wrapper: {
      width: '100%',
      height: COUNTER_HEIGHT,
      bottom:
        'calc(var(--footer-height) + var(--console-simulator-nav-height))',
      position: 'fixed',
      zIndex: 999,
    },
    stepCount: {
      position: 'absolute',
      right: 0,
      bottom: COUNTER_HEIGHT,
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-end',
      marginRight: spacing(2),
    },
    title: {
      fontSize: '0.875rem',
      color: Configurator.getColor(COUNTER_FONT_COLOR, palette.common.black),
    },
    subtitle: {
      fontSize: '1.125rem',
      height: SUBTITLE_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(0.5),
      color: Configurator.getColor(COUNTER_FONT_COLOR, palette.common.black),
    },
  });
