import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const QR_BUTTON_WIDTH = 228;
const QR_BUTTON_HEIGHT = 106;
const ICON_SIZE = 44;

export default ({ spacing }: Theme) =>
  createStyles({
    qrCodeButton: {
      marginBottom: spacing(4),
      width: QR_BUTTON_WIDTH,
      height: QR_BUTTON_HEIGHT,
    },
    qrCodeIcon: {
      paddingRight: spacing(2),
      width: ICON_SIZE,
      height: ICON_SIZE,
    },
  });
