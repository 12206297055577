import { CallApiResponseAction } from 'store/middleware/api';

import CheckOutProcessStore from './interfaces';
import types from './types';

const initialState: CheckOutProcessStore = {
  dataFetching: {
    checkOut: false,
  },
  isFastTrackCheckOutProcess: false,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.START_FAST_CHECK_OUT_PROCESS:
      return {
        ...state,
        isFastTrackCheckOutProcess: true,
      };

    case types.EXECUTE_FAST_CHECK_OUT_PROCESS_REQUEST: {
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          checkOut: true,
        },
      };
    }

    case types.EXECUTE_FAST_CHECK_OUT_PROCESS_FINISHED:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          checkOut: false,
        },
      };

    default:
      return state;
  }
};
