import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const WRAPPER_HEIGHT = 24;
const FONT_SIZE = 11;
const FONT_WEIGHT = 600;
const ICON_WIDTH = 14;
const ICON_HEIGHT = 18;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      height: WRAPPER_HEIGHT,
      borderRadius: 2,
      width: 'fit-content',
      border: `solid 1px ${colors.grey[300]}`,
      backgroundColor: colors.white,
      fontWeight: FONT_WEIGHT,
      fontSize: FONT_SIZE,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `0 ${spacing(0.5)}px`,
      marginLeft: spacing(6.5),
    },
    icon: {
      width: ICON_WIDTH,
      height: ICON_HEIGHT,
      marginRight: spacing(0.5),
    },
    roleText: {
      fontSize: 'inherit',
      fontWeight: 600,
    },
  });
