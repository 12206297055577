import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 24;
const TITLE_WIDTH = 366;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    formWrapper: {
      width: '100%',
    },
    buttonWrapper: {
      flexDirection: 'row',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
    },
    title: {
      fontSize: '1.5rem',
      textAlign: 'center',
      margin: '0 auto',
      marginBottom: spacing(4),
      width: TITLE_WIDTH,
    },
    subtitle: {
      marginBottom: spacing(1.5),
      color: colors.black,
      position: 'relative',
    },
    emailInput: {
      width: '100%',
      marginBottom: '0 !important',
      paddingLeft: spacing(0.875),
    },
    labelRoot: {
      marginLeft: spacing(0.5),
    },
    labelRootNew: {
      margin: `0 0 ${spacing(0.5)}px ${spacing(0.5)}px`,
    },
    addEmailWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: spacing(4),
    },
    checkWrapper: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkboxIcon: {
      border: `1px solid ${colors.grey[500]}`,
      borderRadius: 4,
      width: ICON_SIZE,
      height: ICON_SIZE,
    },
  });
