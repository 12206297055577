import { Dispatch } from 'redux';

import { LogReporter } from '@ac/kiosk-components';
import { Action } from '@ac/library-utils/dist/declarations';

import { LOG_MESSAGES, LOG_TYPES } from '@gss/configs/constants';

export const reducerUncatchedErrorMiddleware = () => (next: Dispatch) => {
  return async (action: Action): Promise<Action> => {
    try {
      return next(action);
    } catch (error) {
      if (error instanceof Error) {
        LogReporter.log.error(
          LOG_TYPES.app,
          {
            message: LOG_MESSAGES.REDUCER_UNCATCHED_ERROR,
            details: {
              action: action?.type,
              message: error?.message,
            },
          },
          true
        );
      }
      throw error;
    }
  };
};
