import Store from 'types/Store/PaymentInterfaceStore';

export const getPaymentInterfaceErrors = (state: Store) =>
  state.paymentInterface.errors;

export const isFetching = (state: Store) => state.paymentInterface.isFetching;

export const getAdapterConfiguration = (state: Store) =>
  state.paymentInterface.adapterConfiguration;

export const getAvailablePaymentInterfaces = (state: Store) =>
  state.paymentInterface.availablePaymentInterfaces;
