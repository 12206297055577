const FETCH_AVAILABLE_PURCHASE_ITEMS_REQUEST =
  'FETCH_AVAILABLE_PURCHASE_ITEMS_REQUEST';
const FETCH_AVAILABLE_PURCHASE_ITEMS_SUCCESS =
  'FETCH_AVAILABLE_PURCHASE_ITEMS_SUCCESS';
const FETCH_AVAILABLE_PURCHASE_ITEMS_FAILURE =
  'FETCH_AVAILABLE_PURCHASE_ITEMS_FAILURE';

const FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_REQUEST =
  'FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_REQUEST';
const FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_SUCCESS =
  'FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_SUCCESS';
const FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_FAILURE =
  'FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_FAILURE';

export default {
  FETCH_AVAILABLE_PURCHASE_ITEMS_REQUEST,
  FETCH_AVAILABLE_PURCHASE_ITEMS_SUCCESS,
  FETCH_AVAILABLE_PURCHASE_ITEMS_FAILURE,
  FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_REQUEST,
  FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_SUCCESS,
  FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_FAILURE,
};
