import i18next from 'i18next';

import { AppInitializationError } from '@gss/utils/errors/customErrors';

import { PermissionData, PropertyPermissionsState } from '../interfaces';

export const validateConfiguration = (
  permissions: PropertyPermissionsState
): void => {
  const missingPermissionNames = Object.entries(permissions)
    .filter(([, permission]: [string, PermissionData]) => {
      return permission.isRequired && !permission.isGranted;
    })
    .map(
      ([key, permission]: [string, PermissionData]) => permission.name || key
    );

  if (missingPermissionNames.length) {
    throw new AppInitializationError(
      [
        `${i18next.t('ERROR.TITLE.MISSING_PROPERTY_PERMISSIONS')}:\n`,
        ...missingPermissionNames,
      ].join('\n')
    );
  }
};
