import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

import 'react-image-crop/dist/ReactCrop.css';

const MARK_WIDTH = 1;
const MARK_HEIGHT = 22;
const SLIDER_HEIGHT = 32;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      height: SLIDER_HEIGHT,
      position: 'relative',
      overflow: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    mark: {
      minWidth: MARK_WIDTH,
      height: MARK_HEIGHT,
      backgroundColor: palette.primary.main,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    slider: {
      height: '100%',
      minWidth: '150%',
      position: 'relative',
    },
  });
