import { colors } from 'config';
import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    body: {
      zIndex: 100,
    },
    primaryText: {
      marginTop: spacing(4),
      fontSize: '1.25rem',
      color: 'inherit',
    },
    secondaryText: {
      marginTop: spacing(2),
      fontSize: '2.5rem',
      color: 'inherit',
    },
    footer: {
      backgroundColor: 'inherit',
      boxShadow: 'none',
      border: 'none',
    },
    footerContent: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 0,
    },
    footerText: {
      whiteSpace: 'pre-wrap',
      textAlign: 'right',
      marginRight: spacing(2),
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.HINT_FONT_COLOR,
        colors.grey[500]
      ),
    },
  });
