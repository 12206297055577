import React, { createContext, PropsWithChildren } from 'react';

import MaterialList from '@material-ui/core/List';

import { ListConfig } from './types';

export const ListConfigContext: React.Context<ListConfig> = createContext({});

interface ListProps {
  className?: string;
  listConfig?: ListConfig;
}

const List = ({
  className,
  children,
  listConfig = {},
}: PropsWithChildren<ListProps>) => {
  return (
    <MaterialList className={className}>
      <ListConfigContext.Provider value={listConfig}>
        {children}
      </ListConfigContext.Provider>
    </MaterialList>
  );
};

export default List;
