import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 14;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      marginBottom: spacing(2),
      alignItems: 'center',
    },
    icon: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      color: palette.common.black,
    },
  });
