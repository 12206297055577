import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { ChargesFolio } from 'types/Api/Cashiering';
import { TransactionSummary } from 'types/Api/Shared';
import CheckOutSummary from 'views/CheckOutSummary';

import { Modal } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FolioSummaryModal.style';

interface PassedProps {
  isOpen: boolean;
  transactions: TransactionSummary[];
  folio: ChargesFolio;
  onCancel: () => void;
  onSubmit: () => void;
  folioBalance: number;
  hideCurrency: boolean;
}

interface FolioSummaryModalProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class FolioSummaryModal extends PureComponent<FolioSummaryModalProps> {
  public static defaultProps = {
    isOpen: false,
    folioBalance: 0,
  };

  public render() {
    const {
      isOpen,
      transactions,
      onCancel,
      folioBalance,
      onSubmit,
      folio,
      hideCurrency,
    } = this.props;

    return (
      <Modal className="with-default-kiosk-components-theme" open={isOpen}>
        <>
          <CheckOutSummary
            hideCurrency={hideCurrency}
            transactions={transactions}
            onCancel={onCancel}
            onSubmit={onSubmit}
            totalBalance={folioBalance}
            folioId={folio.id}
          />
        </>
      </Modal>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(FolioSummaryModal) as (props: PassedProps) => JSX.Element;
