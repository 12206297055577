import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const MAX_LOGO_HEIGHT = 125;
const MAX_LOGO_WIDTH = 400;
const MD_HEADER_HEIGHT = '38dvh';
const SM_HEADER_HEIGHT = '35dvh';
const SETTINGS_WRAPPER_TOP_POSITION = 20;
const SETTINGS_WRAPPER_RIGHT_POSITION = 32;
const FONT_WEIGHT_LIGHT = 300;

export const getBackground = (url: string) =>
  url
    ? {
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        opacity: 0.7,
      }
    : {};

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    header: {
      overflow: 'hidden',
      position: 'relative',
      padding: `${spacing(2)}px ${spacing(12)}px`,
      minHeight: MD_HEADER_HEIGHT,
      zIndex: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      [breakpoints.down('sm')]: {
        minHeight: SM_HEADER_HEIGHT,
        padding: `${spacing(2)}px ${spacing(10)}px`,
      },
      [breakpoints.down('xs')]: {
        alignItems: 'center',
      },
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.HEADER_FONT_COLOR
      ),
    },
    headerImage: {
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      opacity: 0.7,
      filter: 'blur(2px)',
      transform: 'scale(1.05)',
      zIndex: -1,
      left: 0,
      top: 0,
      ...Colors.getBackgroundColor(palette, {
        colorSwitch: Configurator.colorCodes.HEADER_BACKGROUND,
        separateSwitch: Configurator.switchCodes.SEPARATE_WELCOME_HEADER_BG,
      }),
    },
    title: {
      color: 'inherit',
      fontSize: '2.5rem',
      fontWeight: FONT_WEIGHT_LIGHT,
      lineHeight: 1.3,
      [breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      whiteSpace: 'pre-wrap',
    },
    logo: {
      display: 'block',
      maxHeight: MAX_LOGO_HEIGHT,
      maxWidth: MAX_LOGO_WIDTH,
    },
    help: {
      color: 'inherit',
      fontSize: '1rem',
      fontWeight: FONT_WEIGHT_LIGHT,
      textDecoration: 'underline',
    },
    settingsWrapper: {
      position: 'absolute',
      top: SETTINGS_WRAPPER_TOP_POSITION,
      right: SETTINGS_WRAPPER_RIGHT_POSITION,
      display: 'flex',
    },
  });
