import { cacheLoginErrorLog } from 'utils/loginErrorLogsCache';
import { LOGIN_REDIRECT_URL } from 'utils/Router';

import { LogReporter } from '@ac/kiosk-components';
import { Config, ConfigBuilder } from '@ac/library-api';
import { LogDetails } from '@ac/library-utils/dist/declarations/logger';
import { LoginService } from '@ac/library-utils/dist/services';

import {
  API_HEADERS,
  CLIENT_ID,
  DEFAULT_APP_LANGUAGE,
  LOG_TYPES,
} from '@gss/configs/constants';
import { BaseObject } from '@gss/types/shared';

export class Authorizer {
  public static init(onSuccessAuth?: () => void): boolean {
    LoginService.overrideIdentityClientId(CLIENT_ID);
    LoginService.setRedirectUri(LOGIN_REDIRECT_URL);
    LoginService.setOnSuccessHandler(onSuccessAuth);

    return LoginService.init((message: string | LogDetails) => {
      const logMessage =
        typeof message === 'string' ? message : message.message || '';
      const isAuthError = logMessage.includes('Error');
      const logMethod = isAuthError
        ? LogReporter.log.error
        : LogReporter.log.information;

      logMethod(LOG_TYPES.auth, { message: logMessage });

      if (isAuthError) {
        cacheLoginErrorLog(logMessage);
      }
    });
  }

  public static authorize(): void {
    LoginService.handleModuleCallBack({
      moduleLoginUrl: LOGIN_REDIRECT_URL,
      returnUrl: LOGIN_REDIRECT_URL,
    });
  }

  public static logout = (): void => {
    LoginService.logout();
    localStorage.clear();
  };

  public static getStaticConfiguration = (
    options?: {
      propertyId?: string;
      language?: string;
      headers?: { [key: string]: string };
    },
    skipCache: boolean = true
  ): Config<undefined> => {
    const { token: accessToken } = LoginService.authData() || { token: '' };

    const headers: BaseObject<string> = {
      Authorization: `Bearer ${accessToken}`,
      [API_HEADERS.acceptLanguage]: options?.language || DEFAULT_APP_LANGUAGE,
    };

    if (options?.headers) {
      Object.entries(options.headers).forEach(([headerKey, headerValue]) => {
        headers[headerKey] = headerValue;
      });
    }

    if (options?.propertyId) {
      headers[API_HEADERS.acPropertyId] = options.propertyId;
    }

    return new ConfigBuilder()
      .setHeaders(headers)
      .setSkipCache(skipCache)
      .getConfig();
  };
}
