import React, { PureComponent } from 'react';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './RoomGalleryElement.style';

interface PassedProps {
  img: string;
  onClick: () => void;
}

interface RoomGalleryElementProps
  extends PassedProps,
    WithStyles<typeof styles> {}

class RoomGalleryElement extends PureComponent<RoomGalleryElementProps> {
  public render() {
    const { classes, img, onClick } = this.props;

    return (
      <div
        className={classes.sliderElement}
        style={{ backgroundImage: `url('${img}')` }}
        onClick={onClick}
      />
    );
  }
}

export default withStyles(styles)(RoomGalleryElement) as (
  props: PassedProps
) => JSX.Element;
