import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ spacing }: Theme) =>
  createStyles({
    title: {
      fontSize: '1.25rem',
      marginBottom: spacing(1),
      color: 'inherit',
    },
    wrapper: {
      height: '100%',
    },
    searchWrapper: {
      width: '100%',
    },
    searchBox: {
      width: '100%',
    },
    searchBoxError: {
      width: '100%',
      marginBottom: 0,
    },
  });
