import { createSelector } from 'reselect';
import { getConfirmedScannedDocument } from 'store/checkInProcess/selectors';
import { getAddresses } from 'store/profile/selectors';
import { Address } from 'types/Api/Profile';
import Store from 'types/Store';

import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskCheckInUpdateProfile } from '@gss/api/KioskApi/entries';

import { CheckInCommunicationDetailsFormValues } from '../types';

import { ScannedAddressStatus } from './interfaces';

export const getCachedCommunicationDetailsFormData = (
  state: Store
): CheckInCommunicationDetailsFormValues | undefined =>
  state.checkInCommunicationDetails.cachedCommunicationDetailsFormData;

export const getCachedAddressId = (state: Store): string | undefined =>
  state.checkInCommunicationDetails.cachedAddressId;

export const getIdOfPreviouslyAddedAddress = createSelector(
  [getAddresses, getCachedAddressId],
  (addresses, cachedAddressId): string | undefined => {
    return addresses?.find((address) => address.id === cachedAddressId)?.id;
  }
);

export const getScannedAddress = createSelector(
  [getConfirmedScannedDocument],
  (confirmedScannedDocument): Partial<Address> | undefined => {
    const scannedAddress = {
      addressLine1: confirmedScannedDocument?.addressLine1,
      addressLine2: confirmedScannedDocument?.addressLine2,
      city: confirmedScannedDocument?.city,
      countryCode: confirmedScannedDocument?.countryOfIssue?.code,
      postCode: confirmedScannedDocument?.postalCode,
      stateCode: confirmedScannedDocument?.state?.code,
    };

    return Object.values(scannedAddress).some(isDefined)
      ? scannedAddress
      : undefined;
  }
);

export const getScannedAddressStatus = (
  state: Store
): ScannedAddressStatus | undefined =>
  state.checkInCommunicationDetails.scannedAddressStatus;

export const getCommunicationDetailsUpdatePayload = (
  state: Store
): Omit<KioskCheckInUpdateProfile, 'identityDocuments'> | undefined =>
  state.checkInCommunicationDetails.updateCommunicationDetailsPayload;
