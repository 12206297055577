import { useCallback, useEffect, useState } from 'react';

const LARGE_RESOLUTION_BREAKPOINT = '(min-width: 1024px)';

export const useLargeScreenMatchMedia = (): boolean => {
  const [largeDevice, setLargeDevice] = useState(false);

  const setLargeDeviceFlag = useCallback(
    (e: MediaQueryList | MediaQueryListEvent) => {
      setLargeDevice(e.matches);
    },
    []
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(LARGE_RESOLUTION_BREAKPOINT);
    setLargeDeviceFlag(mediaQuery);
    mediaQuery.addEventListener('change', setLargeDeviceFlag);

    return (): void => {
      mediaQuery.removeEventListener('change', setLargeDeviceFlag);
    };
  }, [setLargeDeviceFlag]);

  return largeDevice;
};
