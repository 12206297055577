import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ spacing, palette }: Theme) =>
  createStyles({
    list: {
      marginBottom: spacing(4),
      color: palette.common.black,
    },
  });
