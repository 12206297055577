import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const {
  colorCodes: { BODY_FONT_COLOR },
} = Configurator;

const WRAPPER_RADIUS = 6;

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    expansionPanelwrapper: {
      padding: spacing(2.25),
      border: `1px solid ${palette.primary.dark}`,
      borderRadius: WRAPPER_RADIUS,
      backgroundColor: 'inherit',
      color: Configurator.getColor(BODY_FONT_COLOR, palette.common.black),
    },
    summaryWrapper: {
      padding: 0,
      minHeight: 0,
      [breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    summaryWrapperContent: {
      margin: 0,
    },
    summarySubTitle: {
      marginTop: spacing(2),
      marginBottom: spacing(3),
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.HINT_FONT_COLOR,
        palette.text.disabled
      ),
    },
    detailsWrapper: {
      padding: 0,
      flexDirection: 'column',
    },
    labelRoot: {
      marginLeft: -spacing(0.5),
      marginTop: -spacing(0.5),
      marginRight: 0,
      color: 'inherit',
    },
    summaryWrapperExpanded: {
      margin: '0 !important',
      minHeight: '0 !important',
    },
  });
