/* eslint-disable operator-linebreak */
import { getApiActionTypes, getApiSagaActionTypes } from 'store/utils/actions';

export const FETCH_ACTIVE_AUTHORIZATIONS = getApiSagaActionTypes(
  'FETCH_ACTIVE_AUTHORIZATIONS'
);

export const FETCH_ACTIVE_GIVEN_AUTHORIZATIONS = getApiSagaActionTypes(
  'FETCH_ACTIVE_GIVEN_AUTHORIZATIONS'
);

export const FETCH_ACTIVE_AUTHORIZATION = getApiActionTypes(
  'FETCH_ACTIVE_AUTHORIZATION'
);
