import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Button } from '@ac/kiosk-components';

import { SvgIconProps } from '@material-ui/core/SvgIcon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CarouselButton.style';

interface PassedProps {
  onClick: () => void;
  direction?: string;
  Icon?: React.ComponentType<SvgIconProps>;
}

interface CarouselButtonProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class CarouselButton extends PureComponent<CarouselButtonProps> {
  public static defaultProps = {
    Icon: null,
  };

  public render() {
    const { classes, onClick, Icon } = this.props;

    return (
      <Button onClick={onClick} className={classes.sliderButton}>
        {Icon ? (
          <Icon color="primary" className={classes.buttonIcon} />
        ) : (
          this.renderArrow()
        )}
      </Button>
    );
  }

  private renderArrow() {
    const { classes, direction } = this.props;

    return direction === 'next' ? (
      <ChevronRightIcon color="primary" className={classes.buttonIcon} />
    ) : (
      <ChevronLeftIcon color="primary" className={classes.buttonIcon} />
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(CarouselButton) as (props: PassedProps) => JSX.Element;
