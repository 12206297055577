import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AllPurchaseItems } from 'types/Api/Reservation';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { Link, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './PreAddedButton.style';

interface PreAddedButtonProps
  extends WithTranslation,
    WithStyles<typeof styles> {
  isPerPerson: boolean;
  className?: string;
  onClick: (e: React.MouseEvent) => void;
  allPreAddedPurchases: AllPurchaseItems;
  purchaseItemId: string;
  isElementFromRate: boolean;
}

class PreAddedButton extends PureComponent<PreAddedButtonProps> {
  public static defaultProps = {
    className: '',
    allPreAddedPurchases: {
      preAddedPurchaseElements: [],
      purchasesElementsFromRate: [],
    },
  };

  public render() {
    const { t, classes, isPerPerson, onClick, className, isElementFromRate } =
      this.props;

    return (
      <Link
        className={classNames(classes.preAddedButton, className)}
        onClick={onClick}
      >
        <div className={classes.iconWrapper}>
          {isElementFromRate ? (
            <>
              <Icon type={IconTypes.lock} className={classes.icon} />
              <Typography className={classes.preAddedText}>
                {t('INCLUDED_IN_RATE')}
              </Typography>
            </>
          ) : (
            <>
              <Icon type={IconTypes.checkFilled} className={classes.icon} />
              <Typography className={classes.preAddedText}>
                {t('ALREADY_INCLUDED')}
              </Typography>
            </>
          )}
        </div>
        {!isPerPerson && (
          <Typography className={classes.preAddedText}>
            {this.getElementQuantity()}
          </Typography>
        )}
      </Link>
    );
  }

  public getElementQuantity = () => {
    const { allPreAddedPurchases, purchaseItemId, t, isElementFromRate } =
      this.props;
    const properPurchaseElements = isElementFromRate
      ? allPreAddedPurchases.purchasesElementsFromRate
      : allPreAddedPurchases.preAddedPurchaseElements;

    const foundItem = properPurchaseElements.find(
      (item) => item.purchaseElementId === purchaseItemId
    );

    const quantity = foundItem?.quantity;

    return quantity && quantity > 1
      ? `${quantity} ${t('ITEMS')}`
      : `${quantity} ${t('ITEM')}`;
  };
}

export default withStyles(styles)(withTranslation()(PreAddedButton));
