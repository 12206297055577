import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiError } from 'types/Api/Shared';

import { BaseApi } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';

const DEFAULT_INTERVAL = 500;
const DEFAULT_PAGE_SIZE = 200;

export interface ApiSettings extends AxiosRequestConfig {
  interval?: number;
  [index: string]: any;
  checkCondition?: (response: AxiosResponse) => boolean | Promise<boolean>;
  checkFailureCondition?: (
    response: AxiosResponse
  ) => boolean | Promise<boolean>;
}

export type FileFormat = 'base64' | 'blob';

const getPromiseDecision = (
  data: {
    currentRepeat: number;
    settings: ApiSettings;
    response: AxiosResponse<any>;
  },
  isResolvingDecision: boolean
) => {
  const {
    currentRepeat,
    settings: { checkFailureCondition, checkCondition, ...settings },
    response,
  } = data;
  const checkResponse = isResolvingDecision
    ? checkCondition
    : checkFailureCondition;
  if (!checkResponse) return true;
  const { repetitions } = settings;
  if (currentRepeat >= repetitions) return true;

  return checkResponse(response);
};

const isRequestResolved = (
  currentRepeat: number,
  settings: ApiSettings,
  response: AxiosResponse<any>
) =>
  getPromiseDecision(
    {
      currentRepeat,
      settings,
      response,
    },
    true
  );

const isRequestRejected = (
  currentRepeat: number,
  settings: ApiSettings,
  response: AxiosResponse<any>
) =>
  getPromiseDecision(
    {
      currentRepeat,
      settings,
      response,
    },
    false
  );

class Api {
  public static repetitions: number | undefined;

  public static async callApi(endpoint: string, settings: ApiSettings = {}) {
    const {
      paginate,
      pageSize = DEFAULT_PAGE_SIZE,
      params,
      ...props
    } = settings;
    try {
      const url = `${acConfig.apiUrl}/${endpoint}`;

      const defaultConfig =
        typeof BaseApi.defaultConfig === 'function'
          ? await BaseApi.defaultConfig()
          : BaseApi.defaultConfig;

      const options = {
        ...props,
        url,
        headers: {
          ...(defaultConfig?.headers || {}),
          ...settings.headers,
        },
        params: {
          ...params,
          ...(paginate && { pageSize, pageNumber: 1 }),
        },
        isLegacyApiMiddleware: true,
      };
      const response = await axios(options);

      const { paging } = response.data;
      const isAdditionalRequestNeeded = paging && paging.totalCount > pageSize;

      if (isAdditionalRequestNeeded) {
        const { totalCount } = paging;
        const pagesCount = Math.ceil(totalCount / pageSize);
        const additionalResults = await Promise.all(
          [...new Array(pagesCount - 1)].map(async (elem, index) => {
            const currentResult = await axios({
              ...options,
              params: {
                ...params,
                pageSize,
                pageNumber: index + 2,
              },
            });

            return currentResult.data.results;
          })
        );

        return Promise.resolve({
          ...response,
          data: {
            ...response.data,
            results: [...response.data.results, ...additionalResults.flat()],
          },
        });
      }

      return Promise.resolve(response);
    } catch (error) {
      const { response } = error;

      return Promise.reject(response || error);
    }
  }

  public static async callApiWithCondition(
    endpoint: string,
    settings: ApiSettings
  ): Promise<AxiosResponse<any>> {
    const {
      interval,
      repetitions,
      getOptions,
      checkCondition,
      checkFailureCondition,
      ...props
    } = settings;
    let currentRepeat = 0;

    return new Promise((resolve, reject) => {
      const makeRequestAndTriggerNextIfNeeded = async () => {
        try {
          currentRepeat += 1;
          const options = getOptions ? { ...props, ...getOptions() } : props;
          const response = await this.callApi(endpoint, options);
          const isResolved = await isRequestResolved(
            currentRepeat,
            settings,
            response
          );
          if (isResolved) {
            return resolve(response);
          }
          setTimeout(
            makeRequestAndTriggerNextIfNeeded,
            interval || DEFAULT_INTERVAL
          );
        } catch (error) {
          const { response } = error;
          const isRejected = await isRequestRejected(
            currentRepeat,
            settings,
            error
          );
          if (isRejected) return reject(response || error);
          setTimeout(
            makeRequestAndTriggerNextIfNeeded,
            interval || DEFAULT_INTERVAL
          );
        }
      };
      makeRequestAndTriggerNextIfNeeded();
    });
  }

  public static getFile(
    data: string,
    fileFormat: FileFormat = 'blob' as FileFormat,
    type?: string
  ) {
    return new Promise((resolve, reject) => {
      const file = new Blob([data], { type });
      if (fileFormat === 'blob') resolve(file);
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }

  public static isSuccessRequest(response: AxiosResponse) {
    return response.status === 200 || response.status === 201;
  }

  public static getErrors(
    response: AxiosResponse | ApiError,
    errors: ApiError[] = []
  ): ApiError[] {
    if (!response) return errors;
    const { data } = response as AxiosResponse;
    if (!data) {
      return errors.includes(response as ApiError)
        ? errors
        : [...errors, response as ApiError];
    }

    const { details } = data;
    if (!details || !details.length) {
      return errors.includes(data) ? errors : [...errors, data];
    }

    return errors.includes(details) ? errors : [...errors, ...details];
  }

  public static interruptInterval() {
    // eslint-disable-next-line no-throw-literal
    throw {
      response: { requestInterrupted: true },
    };
  }

  public static extractUrlFromLocationHeader(location: string) {
    return (location || '').replace('/api-gateway/', '') || '';
  }
}

export default Api;
