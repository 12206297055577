import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { TextField } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './QuantityFormField.style';

interface PassedProps {
  input?: any;
  className?: string;
  label?: string;
  readOnly?: boolean;
  type?: string;
  value?: number;
  minQuantity?: number;
  maxQuantity?: number;
}

interface QuantityFormFieldProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class QuantityFormField extends PureComponent<QuantityFormFieldProps> {
  public static defaultProps = {
    label: '',
    readOnly: false,
    minQuantity: 0,
  };

  public render() {
    const {
      input,
      label,
      classes,
      t,
      tReady,
      className,
      readOnly,
      minQuantity,
      maxQuantity,
      ...props
    } = this.props;
    const inputClasses = {
      root: classes.inputPropsRoot,
      input: classes.inputPropsInput,
      focused: classes.inputPropsFocused,
    };

    return (
      <div className={className}>
        <TextField
          className={classes.textField}
          InputProps={{
            readOnly,
            classes: inputClasses,
            disableUnderline: true,
          }}
          inputProps={{
            min: minQuantity,
            max: maxQuantity,
            className: classes.textFieldInput,
          }}
          {...input}
          {...props}
        />
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(QuantityFormField) as (props: PassedProps) => JSX.Element;
