import { getRequester } from '@ac/library-api/dist/api/requester';

import { KioskLoggedUserDto } from '../entries';

const path = {
  me: '/kiosk/v0/users/me',
};

export const ApiCacheKeyLoggedUser = 'UsersApiLoggedUser';

export const getLoggedUser = getRequester<undefined, KioskLoggedUserDto>({
  path: path.me,
  cacheKey: ApiCacheKeyLoggedUser,
});
