import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const MODAL_WIDTH = 624;
const MODAL_MAX_HEIGHT = 688;
const MODAL_MIN_HEIGHT = 217;
const ICONS_WIDTH = 50;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      '& > div': {
        width: '100%',
      },
    },
    title: {
      fontSize: '1.5rem',
      color: palette.common.black,
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    body: {
      maxHeight: 458,
      overflowX: 'hidden',
      '& div': {
        flexDirection: 'column',
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: spacing(2.5),
      width: '100%',
    },
    modal: {
      width: MODAL_WIDTH,
      maxHeight: MODAL_MAX_HEIGHT,
      minHeight: MODAL_MIN_HEIGHT,
      paddingTop: spacing(3),
    },
    searchBox: {
      width: '100%',
    },
    searchBoxError: {
      width: '100%',
      marginBottom: 0,
    },
    radioRoot: {
      padding: `0px ${spacing(1.5)}px`,
    },
    formControl: {
      width: '100%',
    },
    optionsWrapper: {
      display: 'flex',
    },
    label: {
      display: 'flex',
    },
  });
