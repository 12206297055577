import { applyMiddleware, StoreEnhancer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { legacyMiddleware } from '@LEGACY/store/middleware';

import { rootSagas } from '../sagas';

import { reducerUncatchedErrorMiddleware } from './reducerUncatchedErrorMiddleware';
import { sagaFailureActionMiddleware } from './sagaFailureActionMiddleware';
import { sagaUncatchedErrorMiddleware } from './sagaUncatchedErrorMiddleware';

const sagaMiddleware = createSagaMiddleware({
  onError: sagaUncatchedErrorMiddleware,
});

const reduxMiddleware = [
  sagaMiddleware,
  ...legacyMiddleware,
  reducerUncatchedErrorMiddleware,
  sagaFailureActionMiddleware,
];

export const applyReduxMiddleware = (): StoreEnhancer => {
  return composeWithDevTools(applyMiddleware(...reduxMiddleware));
};

export const runMiddleware = (): void => {
  sagaMiddleware.run(rootSagas);
};
