import moment, { Moment, MomentInput } from 'moment';
import { Configurator } from 'utils';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

export const API_MATCH_FULL_BIRTHDAY_FORMAT = 'DD-MM-YYYY';
export const API_MATCH_PARTIAL_BIRTHDAY_FORMAT = 'DD-MM';
export const TIME_12H_FORMAT = 'h:mm A';
export const TIME_24H_FORMAT = 'HH:mm';

/**
 * Formats a date into a string.
 * @param {string|Date} date Moment date object.
 * @param {string} format Date format string.
 * @returns {string} Formatted date string.
 */

function getFormattedDate(date: MomentInput, format?: string): string {
  if (format) {
    return moment(date).format(format);
  }
  const shortDateFormat = Configurator.dateFormat?.shortDateFormat;

  return moment(date).format(shortDateFormat || DEFAULT_DATE_FORMAT);
}

function getServerFormattedDateTime(date: MomentInput): string {
  return moment(date).format(SERVER_DATE_TIME_FORMAT);
}

function getServerFormattedDate(date: MomentInput): string {
  return moment(date).format(SERVER_DATE_FORMAT);
}

function getHour(date: string | Date, format = Configurator.timeFormat) {
  return getFormattedDate(date, format);
}

function parseDateFormat(
  date: string | Date | Moment,
  format?: string
): string {
  const shortDateFormat = Configurator.dateFormat.shortDateFormat;
  if (format) {
    return moment(date, shortDateFormat).format(format);
  }
  const parsedDate = moment(date, shortDateFormat).format(
    shortDateFormat || DEFAULT_DATE_FORMAT
  );

  return parsedDate;
}

function getDiffBetween(arrival: string | Date, departure: string | Date) {
  return moment(departure).diff(arrival, 'days');
}

function getSubtractedTime(
  time: string | Date | Moment,
  timeToSubstract: number = 0
) {
  return moment(time, 'HH:mm:ss').subtract(timeToSubstract, 'minutes');
}

function checkIfBefore(
  serverTime: MomentInput,
  date: MomentInput,
  inputDateFormat?: string
) {
  return moment(date, inputDateFormat).isBefore(moment(serverTime));
}

function checkIfAfter(
  serverTime: MomentInput,
  date: MomentInput,
  inputDateFormat?: string
) {
  return moment(date, inputDateFormat).isAfter(moment(serverTime));
}

function checkIfSameOrBefore(
  serverTime: MomentInput,
  date: MomentInput,
  inputDateFormat?: string
) {
  return moment(date, inputDateFormat).isSameOrBefore(moment(serverTime));
}

function checkIfSameOrAfter(
  serverTime: MomentInput,
  date: MomentInput,
  inputDateFormat?: string
) {
  return moment(date, inputDateFormat).isSameOrAfter(moment(serverTime));
}

function getEndOfTheDay(date: string | Date | Moment) {
  return moment(date).endOf('day').format(SERVER_DATE_TIME_FORMAT);
}

function getStartOfTheDay(date: string | Date | Moment) {
  return moment(date).startOf('day').format(SERVER_DATE_TIME_FORMAT);
}

function getPreviousDay(date: string | Date) {
  return moment(date).subtract(1, 'days').toISOString();
}

function getNextDay(
  date: string | Date,
  dateFormat: string = SERVER_DATE_FORMAT
) {
  return moment(date).add(1, 'days').format(dateFormat);
}

function getDateUpdatedByTime(date: string, timeToUpdateBy: string) {
  const time = moment(timeToUpdateBy).format(TIME_24H_FORMAT);

  return getDateWithProvidedTime(date, time);
}

function getDateWithProvidedTime(date: string, time: string) {
  const splitedDate = date.split('T')[0];

  return getServerFormattedDateTime(`${splitedDate}T${time}`);
}

function getDateFromString(stringDate: string) {
  return moment(getStartOfTheDay(stringDate)).toDate();
}

function addDays(
  date: string | Date,
  numberOfDays: number = 1,
  dateFormat: string = SERVER_DATE_FORMAT
) {
  return moment(date).add(numberOfDays, 'days').format(dateFormat);
}

function getDayOfTheWeek(t: any, date: Date, shortForm?: boolean) {
  const weekdays = [
    { full: t('SUNDAY'), short: t('SUN') },
    { full: t('MONDAY'), short: t('MON') },
    { full: t('TUESDAY'), short: t('TUE') },
    { full: t('WEDNESDAY'), short: t('WED') },
    { full: t('THURSDAY'), short: t('THU') },
    { full: t('FRIDAY'), short: t('FRI') },
    { full: t('SATURDAY'), short: t('SAT') },
  ];

  const days = weekdays.map((weekday) => {
    return shortForm ? weekday.short : weekday.full;
  });

  return days[date.getDay()];
}

function getObjectDate(date: MomentInput, format?: string) {
  const momentDate = moment(date, format);

  return {
    day: momentDate.date(),
    month: momentDate.month() + 1,
    year: momentDate.year(),
  };
}

function getTimestamp(date: string, format?: string): number {
  return moment(date, format).valueOf();
}

export default {
  getHour,
  getFormattedDate,
  getServerFormattedDateTime,
  getServerFormattedDate,
  getDiffBetween,
  getEndOfTheDay,
  checkIfBefore,
  checkIfAfter,
  checkIfSameOrBefore,
  checkIfSameOrAfter,
  getSubtractedTime,
  getPreviousDay,
  getNextDay,
  getStartOfTheDay,
  addDays,
  getDateFromString,
  parseDateFormat,
  getDateUpdatedByTime,
  getDayOfTheWeek,
  getDateWithProvidedTime,
  getObjectDate,
  getTimestamp,
};
