import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ breakpoints, palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      paddingTop: spacing(4),
      paddingBottom: spacing(13.75),
      minHeight: '100%',
      backgroundColor: 'inherit',
      [breakpoints.up('sm')]: {
        height: '100%',
        overflowY: 'auto',
        paddingLeft: spacing(10),
        paddingRight: spacing(10),
        paddingTop: spacing(4),
        paddingBottom: spacing(13.75),
      },
    },
    roomNumber: {
      color: 'inherit',
      fontSize: '1.5rem',
    },
    roomType: {
      color: 'inherit',
      fontSize: '1.25rem',
      marginBottom: spacing(2),
      fontWeight: 300,
    },
    description: {
      color: 'inherit',
      fontSize: '1rem',
      marginBottom: spacing(3),
    },
    equipmentTitle: {
      color: 'inherit',
      fontSize: '1rem',
      marginBottom: spacing(3),
    },
    equipment: {
      color: 'inherit',
      fontSize: '1rem',
    },
    attribute: {
      color: 'inherit',
    },
  });
