import { ApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

import { ReduxReducerMap } from '@gss/types/shared';

import { resetGlobalSetupData } from '../globalActions';
import { handleGlobalReducer } from '../storeConfiguration/utils';

import { Settings } from './interfaces/settings';
import * as actions from './actions';
import { SettingsState } from './interfaces';
import { revokeImageObjectUrls } from './utils';

export const initialState: SettingsState = {
  fetching: {
    settings: false,
  },
  errors: [],
  areSettingsInitialized: false,
};

const reducerMap: ReduxReducerMap<SettingsState> = {
  [actions.applySettings]: (state, action: Action<Settings>): SettingsState => {
    revokeImageObjectUrls(state.images);

    return {
      ...state,
      ...action.payload,
      areSettingsInitialized: true,
    };
  },

  [actions.prepareSettings.trigger]: (state): SettingsState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        settings: true,
      },
    };
  },

  [actions.prepareSettings.success]: (state): SettingsState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        settings: false,
      },
    };
  },

  [actions.prepareSettings.failure]: (
    state,
    action: Action<ApiError | ApiError[] | Error>
  ): SettingsState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        settings: false,
      },
      errors: [
        ...state.errors,
        ...(Array.isArray(action.payload) ? action.payload : [action.payload]), // TODO: Remove this condition after removal of `fetchLegacySetup`, payload will contain single error then
      ],
    };
  },

  [resetGlobalSetupData]: (state): SettingsState => {
    revokeImageObjectUrls(state.images);

    return {
      ...initialState,
    };
  },
};

export const settings = handleGlobalReducer<SettingsState>(
  reducerMap,
  initialState
);
