import {
  CookiesService,
  LoginService,
  SessionService,
} from '@ac/library-utils/dist/services';

import { SELF_SERVICE_DEVICE_COOKIE_EXPIRATION_TIME } from '@gss/configs/timers';

export class Storage {
  public static keys = {
    DEVICE: 'aboveCloud.device',
  };

  public static setCookie(
    key: string,
    value: string,
    expirationTime?: Date | number
  ): void {
    CookiesService.setCookie(key, value, expirationTime, this.domain);
  }

  public static getCookie(key: string): string | undefined {
    return CookiesService.getCookie(key);
  }

  public static deleteCookie(key: string): void {
    return CookiesService.deleteCookie(key, this.domain);
  }

  public static savePropertyId(propertyId: string): void {
    SessionService.setPropertyId(propertyId);
  }

  public static saveDeviceCookie(deviceId: string): void {
    Storage.setCookie(
      Storage.keys.DEVICE,
      deviceId,
      Date.now() + SELF_SERVICE_DEVICE_COOKIE_EXPIRATION_TIME
    );
  }

  public static deletePropertyId(): void {
    SessionService.setPropertyId(undefined);
  }

  public static deleteDeviceCookie(): void {
    Storage.deleteCookie(Storage.keys.DEVICE);
  }

  public static getPropertyId(): string | undefined {
    return SessionService.getPropertyId();
  }

  public static getDeviceCookie(): string | undefined {
    return Storage.getCookie(Storage.keys.DEVICE);
  }

  private static get domain(): string | undefined {
    return LoginService.domain();
  }
}
