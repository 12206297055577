export interface RawDocumentScanningErrorReport {
  code?: string;
  message?: string;
}

export class DocumentScanningErrorReport {
  public code?: string;
  public message?: string;

  constructor(raw: RawDocumentScanningErrorReport) {
    this.code = raw.code;
    this.message = raw.message;
  }
}
