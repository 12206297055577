import { CallApiResponseAction } from 'store/middleware/api';
import { failure, request, success } from 'store/utils/reducer';
import { State } from 'types/Store/RoomStore';

import types from './types';

const initialState: State = {
  dataFetching: {
    room: false,
    roomType: false,
    roomList: false,
  },
  roomType: {
    code: '',
    name: [],
    description: [],
    attributeIds: [],
  },
  details: {
    code: '',
    description: [],
  },
  roomList: [],
  errors: [],
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_ROOM_TYPE_REQUEST:
      return request(state, 'roomType');
    case types.FETCH_ROOM_TYPE_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'roomType'),
        roomType: data,
      };
    }
    case types.FETCH_ROOM_TYPE_FAILURE:
      return failure(state, action, 'roomType');

    case types.FETCH_ROOM_REQUEST:
      return request(state, 'room');
    case types.FETCH_ROOM_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'room'),
        details: data,
      };
    }
    case types.FETCH_ROOM_FAILURE:
      return failure(state, action, 'room');

    case types.CLEAR_ROOM_ERRORS:
      return {
        ...state,
        errors: [],
      };

    case types.FETCH_ROOM_LIST_REQUEST:
      return {
        ...state,
        ...request(state, 'roomList'),
      };

    case types.FETCH_ROOM_LIST_SUCCESS:
      return {
        ...state,
        ...success(state, 'roomList'),
        roomList: action.response.data.results,
      };

    case types.FETCH_ROOM_LIST_FAILURE:
      return {
        ...state,
        ...failure(state, action, 'roomList'),
      };

    default:
      return state;
  }
};
