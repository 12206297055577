import { ApiError } from '@ac/library-api';

const DOCUMENT_VERIFICATION_ERROR_CODE = 'InvalidForeignDocumentType';

export const isDocumentVerificationError = (error: Error | ApiError) => {
  return (
    error &&
    typeof error === 'object' &&
    'code' in error &&
    error.code === DOCUMENT_VERIFICATION_ERROR_CODE
  );
};
