import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { Api } from 'utils';
import { ApiSettings, FileFormat } from 'utils/Api';

export const CALL_API = 'CALL_API';

interface CallApiActionSettings extends ApiSettings {
  endpoint: string;
  types: string[];
  payload?: any;
  isFile?: boolean;
  fileFormat?: FileFormat;
  repetitions?: number;
}

export interface CallApiAction {
  [CALL_API]: CallApiActionSettings;
}

export enum ApiActionResult {
  request = 'request',
  success = 'success',
  failure = 'failure',
}

interface ApiAction<PayloadType = any> {
  type: string;
  payload?: PayloadType;
}

interface SuccessCallApiResponseAction<ResponseBodyType = any>
  extends ApiAction {
  response: {
    data: ResponseBodyType;
  };
  apiAction: ApiActionResult.success;
}

export const isInterruptedRequest = (
  apiCallResponse: FailueApiCallResponse
): apiCallResponse is InterruptedApiCallResponse =>
  !!(apiCallResponse as InterruptedApiCallResponse)?.requestInterrupted;

interface InterruptedApiCallResponse {
  requestInterrupted?: true;
}
type FailueApiCallResponse = InterruptedApiCallResponse | AxiosResponse;
export interface FailureCallApiResponseAction<
  ResponseBodyType extends FailueApiCallResponse = FailueApiCallResponse
> extends ApiAction {
  response: ResponseBodyType;
  apiAction: ApiActionResult.failure;
}

export interface RequestCallApiResponseAction extends ApiAction {
  apiAction: ApiActionResult.request;
}

export interface CallApiResponseAction {
  response?: any;
  payload?: any;
  type: string;
  apiAction?: ApiActionResult;
}

export type CallApiSpecificResponseAction<
  ResponseBodyType = any,
  FailureType = any
> =
  | SuccessCallApiResponseAction<ResponseBodyType>
  | FailureCallApiResponseAction<FailureType>
  | RequestCallApiResponseAction;

export default () => (next: Dispatch) => async (action: AnyAction) => {
  const settings: CallApiActionSettings = action[CALL_API];

  if (typeof settings === 'undefined') {
    return next(action);
  }

  const { endpoint, types, payload, fileFormat, ...data } = settings;
  const [requestType, successType, errorType] = types;
  const isFile = data.responseType === 'arraybuffer';
  const { checkCondition, checkFailureCondition } = data;
  try {
    next({
      payload,
      type: requestType,
      data,
      apiAction: ApiActionResult.request,
    });

    const response =
      checkCondition || checkFailureCondition
        ? await Api.callApiWithCondition(endpoint, data)
        : await Api.callApi(endpoint, data);

    if (!isFile) {
      return next({
        response,
        payload,
        type: successType,
        apiAction: ApiActionResult.success,
      });
    }
    const format: FileFormat = fileFormat || 'blob';
    const fileData = await Api.getFile(response.data, format);

    return next({
      payload,
      response: { ...response, data: fileData },
      type: successType,
      apiAction: ApiActionResult.success,
    });
  } catch (response) {
    next({
      response,
      payload,
      type: errorType,
      apiAction: ApiActionResult.failure,
    });
    const errorMessage = isFile ? response.statusText : Api.getErrors(response);
    // eslint-disable-next-line no-console
    console.error(errorMessage);

    return response;
  }
};
