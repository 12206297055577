import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './RowItem.style';

export interface RowItemProps
  extends Omit<GridProps, 'classes'>,
    WithStyles<typeof styles> {
  wrapperClassName?: string;
  textClassName?: string;
  gridProps?: GridProps;
  fontSize?: string;
}

const RowItem = ({
  children,
  classes,
  wrapperClassName,
  textClassName,
  gridProps,
  fontSize,
}: PropsWithChildren<RowItemProps>) => (
  <Grid
    className={classNames(classes.wrapper, wrapperClassName)}
    item
    {...gridProps}
  >
    <Typography
      className={classNames(classes.text, textClassName)}
      style={{ fontSize }}
    >
      {children}
    </Typography>
  </Grid>
);

export default withStyles(styles)(RowItem);
