import React from 'react';

import Grid from '@material-ui/core/Grid';

interface ImagesProps {
  image1: ImageProps;
  image2: ImageProps;
}

export const Images = ({ image1, image2 }: ImagesProps) => (
  <Grid container>
    <Image {...image1} />
    <Image {...image2} />
  </Grid>
);

interface ImageProps {
  className: string;
  imageSource: string;
}

const Image = ({ className, imageSource }: ImageProps) => (
  <Grid item xs={6}>
    <Grid container justify="center">
      <img src={imageSource} alt="" className={className} />
    </Grid>
  </Grid>
);
