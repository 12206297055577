var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p\n      class=\"pdf-table-header\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"justify") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":6},"end":{"line":36,"column":13}}})) != null ? stack1 : "")
    + "    >\n      <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"header") || (depth0 != null ? lookupProperty(depth0,"header") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"header","hash":{},"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":38,"column":22}}}) : helper)))
    + "</span>\n    </p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        style=\"justify-content: "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"justify") || (depth0 != null ? lookupProperty(depth0,"justify") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"justify","hash":{},"data":data,"loc":{"start":{"line":35,"column":32},"end":{"line":35,"column":43}}}) : helper)))
    + ";\"\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"cells") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":42,"column":4},"end":{"line":53,"column":13}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <p\n        class=\"pdf-table-cell\"\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,lookupProperty(helpers,"lookup").call(alias1,((stack1 = (depths[2] != null ? lookupProperty(depths[2],"tableConfig") : depths[2])) != null ? lookupProperty(stack1,"columns") : stack1),(data && lookupProperty(data,"index")),{"name":"lookup","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":45,"column":16},"end":{"line":45,"column":57}}}),{"name":"with","hash":{},"fn":container.program(6, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":45,"column":8},"end":{"line":49,"column":17}}})) != null ? stack1 : "")
    + "      >\n        <span>"
    + container.escapeExpression(container.lambda(blockParams[0][0], depth0))
    + "</span>\n      </p>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"justify") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":46,"column":10},"end":{"line":48,"column":17}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            style=\"justify-content: "
    + container.escapeExpression(container.lambda(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"justify") : stack1), depth0))
    + ";\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-table {\n    width: 100%;\n    text-align: left;\n    font-size: var(--font-size-sm);\n    display: grid;\n    gap: var(--spacing-sm) var(--spacing-xlg);\n    grid-auto-rows: auto;\n    grid-template-columns: "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"tableConfig") : depth0)) != null ? lookupProperty(stack1,"gridTemplateColumns") : stack1), depth0))
    + ";\n  }\n\n  .pdf-table-header, .pdf-table-cell {\n    display: flex;\n    margin: var(--spacing-xxs) var(--spacing-none);\n    align-items: center;\n    overflow: hidden;\n  }\n\n  .pdf-table-header span, .pdf-table-cell span {\n    white-space: pre-line;\n    word-break: break-all;\n  }\n\n  .pdf-table-header {\n    font-weight: var(--font-weight-bold);\n    font-size: var(--font-size-md);\n  }\n</style>\n\n<div class=\""
    + alias1(__default(require("../helpers/classNames.ts")).call(alias2,"pdf-table",(depth0 != null ? lookupProperty(depth0,"className") : depth0),{"name":"classNames","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":48}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"tableConfig") : depth0)) != null ? lookupProperty(stack1,"columns") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":31,"column":2},"end":{"line":40,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"tableData") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":41,"column":2},"end":{"line":54,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true,"useBlockParams":true});