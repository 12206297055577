import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BasicModal,
  BasicModalSize,
  Button,
  ButtonPattern,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import './CheckNameDetailsModal.scss';

interface CheckNameDetailsModalProps {
  onClose: () => void;
  onChange: () => void;
  onConfirm: () => void;
  firstName?: string;
  lastName?: string;
  secondSurname?: string | undefined;
}

const CheckNameDetailsModal = ({
  firstName,
  lastName,
  secondSurname,
  onClose,
  onChange,
  onConfirm,
}: CheckNameDetailsModalProps) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      onOverlayClick={onClose}
      size={BasicModalSize.md}
      className="with-default-kiosk-components-theme check-name-details-modal"
    >
      <Text size={TextSize.xlg} className="spacing-top-sm spacing-bottom-lg">
        {t('CHECK_NAME_HEADER')}
      </Text>
      <Text className="spacing-left-xlg spacing-right-xlg">
        {t('CHECK_NAME_SUBTITLE')}
      </Text>

      <div className="content-wrapper spacing-top-xxlg spacing-bottom-xxlg">
        <Text className="content-label">{`${t('FIRST_NAME')}: `}</Text>
        <Text size={TextSize.xlg} className="content-value">
          {firstName}
        </Text>

        <Text className="content-label">{`${t('LAST_NAME')}: `}</Text>
        <Text size={TextSize.xlg} className="content-value">
          {lastName}
        </Text>

        {secondSurname && (
          <>
            <Text className="content-label">{`${t('SECOND_SURNAME')}: `}</Text>
            <Text size={TextSize.xlg} className="content-value">
              {secondSurname}
            </Text>
          </>
        )}
      </div>

      <div className="buttons-wrapper">
        <Button pattern={ButtonPattern.secondary} onClick={onClose}>
          {t('CLOSE')}
        </Button>
        <Button
          pattern={ButtonPattern.secondary}
          onClick={onChange}
          className="spacing-left-auto"
        >
          {t('CHANGE')}
        </Button>
        <Button className="spacing-left-sm" onClick={onConfirm}>
          {t('CONFIRM')}
        </Button>
      </div>
    </BasicModal>
  );
};

export default CheckNameDetailsModal;
