const FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_REQUEST =
  'FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_REQUEST';
const FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_SUCCESS =
  'FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_SUCCESS';
const FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_FAILURE =
  'FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_FAILURE';
const CLEAR_CONFIRMED_NEW_PURCHASE_ORDERS =
  'CLEAR_CONFIRMED_NEW_PURCHASE_ORDERS';

export default {
  FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_REQUEST,
  FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_SUCCESS,
  FETCH_CONFIRMED_NEW_PURCHASE_ORDERS_FAILURE,
  CLEAR_CONFIRMED_NEW_PURCHASE_ORDERS,
};
