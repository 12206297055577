import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Address } from 'types/Api/Profile';
import { Configurator } from 'utils';

import {
  BasicModal,
  BasicModalSize,
  Button,
  ButtonPattern,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { getAllStateResults } from '@gss/store/lazyLoadedDictionary/selectors';

import AddressColumn, { ReviewAddressColumn } from './AddressColumn';

import './ReviewAddressModal.scss';

interface ReviewAddressModalProps {
  mainAddress: Partial<Address>;
  scannedAddress: Partial<Address>;
  onSkipClick: () => void;
  onAddAdditional: () => void;
  onReplace: () => void;
}

const ReviewAddressModal = ({
  mainAddress,
  scannedAddress,
  onSkipClick,
  onAddAdditional,
  onReplace,
}: ReviewAddressModalProps) => {
  const { t, i18n } = useTranslation();
  const states = useSelector(getAllStateResults);

  const prepareAddressData = useCallback(
    (address: Partial<Address>, languageCode: string): ReviewAddressColumn => {
      const {
        addressLine1,
        addressLine2,
        city,
        countryCode,
        postCode,
        stateCode,
      } = address;

      const countries = Configurator.getCountries(languageCode);
      const country = countries.find(({ code }) => code === countryCode);
      const lazyLoadedDictionary = countryCode
        ? states?.[countryCode]
        : undefined;
      const countryState = lazyLoadedDictionary?.find(
        (state) => state.code === stateCode
      )?.name;

      return {
        addressLine1,
        addressLine2,
        city,
        postCode,
        country: country?.name && `${countryCode} - ${country.name}`,
        state: countryState,
      };
    },
    [states]
  );

  const parsedMainAddress = useMemo(
    () => prepareAddressData(mainAddress, i18n.language),
    [mainAddress, i18n.language, prepareAddressData]
  );

  const parsedScannedAddress = useMemo(
    () => prepareAddressData(scannedAddress, i18n.language),
    [mainAddress, i18n.language, prepareAddressData]
  );

  return (
    <BasicModal
      size={BasicModalSize.lg}
      className="with-default-kiosk-components-theme review-address-modal"
    >
      <Text size={TextSize.xlg} className="spacing-sm">
        {t('CHECK_IN_COMMUNICATION_DETAILS.REVIEW_ADDRESS_MODAL.TITLE')}
      </Text>

      <Text className="spacing-top-sm review-address-subtitle">
        {t('CHECK_IN_COMMUNICATION_DETAILS.REVIEW_ADDRESS_MODAL.SUBTITLE')}
      </Text>

      <div className="spacing-xlg review-address-content-wrapper">
        <AddressColumn
          title={t('CHECK_IN_COMMUNICATION_DETAILS.MAIN_ADDRESS')}
          address={parsedMainAddress}
        />
        <AddressColumn
          title={t('CHECK_IN_COMMUNICATION_DETAILS.SCANNED_ADDRESS')}
          address={parsedScannedAddress}
        />
      </div>

      <div className="spacing-top-sm buttons-wrapper">
        <Button pattern={ButtonPattern.secondary} onClick={onSkipClick}>
          {t('CHECK_IN_COMMUNICATION_DETAILS.REVIEW_ADDRESS_MODAL.SKIP')}
        </Button>
        <Button
          pattern={ButtonPattern.secondary}
          onClick={onAddAdditional}
          className="spacing-left-auto spacing-right-sm"
        >
          {t(
            'CHECK_IN_COMMUNICATION_DETAILS.REVIEW_ADDRESS_MODAL.ADD_AS_ADDITIONAL'
          )}
        </Button>
        <Button onClick={onReplace}>
          {t('CHECK_IN_COMMUNICATION_DETAILS.REVIEW_ADDRESS_MODAL.REPLACE')}
        </Button>
      </div>
    </BasicModal>
  );
};

export default ReviewAddressModal;
