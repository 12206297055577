import React, { PureComponent } from 'react';
import { FormErrorMessage } from 'components';
import FormSelectOption from 'types/FormSelectOption';

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FormSelect.style';
import FormSelectOptions from './FormSelectOptions';

interface FormSelectProps extends WithStyles<typeof styles> {
  label: string;
  meta: {
    touched: boolean;
    error: string;
    active: boolean;
  };
  options: FormSelectOption[];
  id?: string;
  input?: any;
  native?: boolean;
  variant?: string;
  className?: string;
  empty?: string;
  onInputMouseDown?: (event: React.MouseEvent) => void;
}

class FormSelect extends PureComponent<FormSelectProps> {
  public static defaultProps = {
    native: true,
    variant: 'outlined',
    className: '',
    options: [{ value: '', title: '' }],
    empty: '',
  };

  public render() {
    const {
      classes,
      id,
      input,
      children,
      label,
      native,
      options,
      variant,
      className,
      onInputMouseDown,
      meta: { touched, error, active },
      empty,
      ...props
    } = this.props;
    const isError = touched && !!error && !active;
    const inputClasses = {
      root: classes.inputPropsRoot,
      input: classes.inputPropsInput,
      focused: classes.inputPropsFocused,
      error: classes.inputPropsError,
    };
    const labelClasses = {
      root: isError ? classes.inputLabelError : classes.inputLabelRoot,
      focused: classes.inputLabelFocused,
      shrink: classes.inputLabelShrink,
    };
    const elementId = id || input.name;

    return (
      <div className={className}>
        <FormControl
          id={elementId}
          classes={{ root: classes.selectWrapper }}
          variant="outlined"
          {...props}
        >
          <InputLabel
            id={`${elementId}-label`}
            classes={labelClasses}
            htmlFor={input.name}
            shrink
          >
            {label}
          </InputLabel>
          <Select
            {...input}
            id={`${elementId}-select`}
            native={native}
            error={isError}
            classes={{ root: classes.selectRoot }}
            IconComponent={this.SelectIcon}
            SelectDisplayProps={{ id: `${elementId}-select-open` }}
            inputProps={{ name: input.name, onMouseDown: onInputMouseDown }}
            input={<OutlinedInput labelWidth={0} classes={inputClasses} />}
          >
            <FormSelectOptions
              native={native}
              options={options}
              label={label}
              empty={empty}
            />
          </Select>
        </FormControl>
        {isError && <FormErrorMessage error={error} />}
      </div>
    );
  }

  private SelectIcon = () => {
    const { classes } = this.props;
    const iconClasses = {
      root: classes.iconRoot,
    };

    return <ArrowDropDownIcon classes={iconClasses} />;
  };
}

export default withStyles(styles)(FormSelect);
