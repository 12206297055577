import i18n from 'i18next';
import { Configurator } from 'utils';

export const validateEntitySetup = () => {
  const {
    SHOW_PURPOSE_OF_STAY,
    SHOW_DOCUMENT,
    SHOW_CONTACT,
    SHOW_ADDRESS,
    SHOW_PRE_AUTHORIZATION,
    SHOW_FISCAL_DOCUMENT_SELECTION,
  } = Configurator.switchCodes;
  const { KIOSK_PRE_AUTH_RULE } = Configurator.entityCodes;
  const errorAreas: string[] = [];
  const kioskInterface = Configurator.paymentInterface;
  const title = `${i18n.t('ENTITIES_MISCONFIGURED')}:\n`;

  if (
    Configurator.getSwitch(SHOW_PRE_AUTHORIZATION) &&
    !Configurator.getEntityTypeId(KIOSK_PRE_AUTH_RULE)
  ) {
    errorAreas.push('Pre-authorization rule');
  }
  if (
    !Configurator.purposesOfStay.length &&
    Configurator.getSwitch(SHOW_PURPOSE_OF_STAY)
  ) {
    errorAreas.push('Purpose of Stay');
  }
  if (
    !Configurator.documentTypes.length &&
    Configurator.getSwitch(SHOW_DOCUMENT)
  ) {
    errorAreas.push('Document Type');
  }
  if (!Configurator.emailTypes.length && Configurator.getSwitch(SHOW_CONTACT)) {
    errorAreas.push('Communication Type (email)');
  }
  if (
    !Configurator.mobileTypes.length &&
    Configurator.getSwitch(SHOW_CONTACT)
  ) {
    errorAreas.push('Communication Type (mobile)');
  }
  if (
    !Configurator.addressTypes.length &&
    Configurator.getSwitch(SHOW_ADDRESS)
  ) {
    errorAreas.push('Address Type');
  }
  if (!Configurator.taxRules.length) {
    errorAreas.push('Tax rule');
  }
  if (!Configurator.profileTypes.length) {
    errorAreas.push('Profile Type');
  }
  if (!kioskInterface.adaptersConfiguration.length) {
    errorAreas.push('Payment adapter configuration');
  }
  if (!kioskInterface.transactionCode) {
    errorAreas.push('Kiosk credit card transaction code');
  }
  if (
    Configurator.getSwitch(SHOW_FISCAL_DOCUMENT_SELECTION) &&
    !Configurator.folioStyles.length
  ) {
    errorAreas.push('Folio Styles (Guest Facing)');
  }

  if (errorAreas.length) {
    return `${title} ${errorAreas.join(', ')}`;
  }
};
