import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    bodyWrapper: {
      [breakpoints.up('sm')]: {
        padding: `${spacing(3)}px ${spacing(4)}px`,
        paddingBottom: spacing(9),
      },
      [breakpoints.up('md')]: {
        padding: `${spacing(4)}px ${spacing(11.5)}px`,
        paddingBottom: spacing(9),
      },
    },
    orderWrapper: {},
    backButton: {
      marginRight: spacing(1),
    },
    orderSummaryWrapper: {
      width: 'auto',
    },
    orderSubtitle: {
      fontSize: '1rem',
      marginTop: spacing(0.5),
      marginRight: spacing(1),
      color: 'inherit',
    },
    orderTitle: {
      fontSize: '1.5rem',
      color: 'inherit',
    },
    textWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing(2),
    },
    priceWrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      marginRight: spacing(2),
    },
    title: {
      fontSize: '1rem',
      lineHeight: 1.38,
      marginRight: spacing(1),
    },
    price: {
      fontSize: '1.5rem',
      lineHeight: 1.38,
    },
  });
