import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonPattern,
  Footer as BasicFooter,
} from '@ac/kiosk-components';

type BasicFooterProps = React.ComponentProps<typeof BasicFooter>;

interface FooterProps extends BasicFooterProps {
  hasCancelButton?: boolean;
}

export const Footer = ({
  children,
  hasCancelButton,
  ...rest
}: FooterProps): JSX.Element => {
  const { t } = useTranslation();

  const handleCancelClick = useCallback(() => {
    // TODO: Cancel for processes (check-in, check-out, make-keys)
    // Add after implementation of some processes will exists
  }, []);

  return (
    <BasicFooter {...rest}>
      {hasCancelButton && (
        <Button
          dataTestSelector="footer-cancel-button"
          onClick={handleCancelClick}
          pattern={ButtonPattern.secondary}
          className="spacing-right-lg"
        >
          {t('SHARED.CANCEL')}
        </Button>
      )}
      {children}
    </BasicFooter>
  );
};
