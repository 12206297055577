import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AlignItems,
  Body,
  Circular,
  CircularType,
  Color,
  Flex,
  Grid,
  Header,
  IconTypes,
  Section,
  SectionType,
  Table,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { ShijiWhiteLogo } from '@gss/assets/images';
import { Footer, View } from '@gss/components/layout';
import { useRouter } from '@gss/router';
import { getValidatedDevices } from '@gss/store/configuration/selectors';

import { ValidationDeviceInfoRow } from './components';
import { tableConfig } from './tableConfig';

import './MissingDevices.scss';

export const MissingDevices = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();

  const devices = useSelector(getValidatedDevices);

  const invalidDevices = useMemo(() => {
    return devices?.filter((device) => !device.isValid || device.isOnline);
  }, [devices]);

  const handleBackClick = (): void => {
    router?.goBack();
  };

  return (
    <View className="with-default-kiosk-components-theme">
      <Header
        logo={ShijiWhiteLogo}
        title={t('VIEWS.MISSING_ON_DEVICES.TITLE')}
      />
      <Body className="padding-vertical-lg missing-device-container">
        <Section
          type={SectionType.wide}
          className="padding-bottom-md missing-device-section"
        >
          <Grid gridTemplateRowsSm="auto 1fr">
            <Grid.Item>
              <Flex className="gap-sm" alignItems={AlignItems.center}>
                <Circular
                  type={CircularType.warning}
                  size={32}
                  icon={IconTypes.exclamationMark}
                  iconColor={Color.primary}
                />
                <Text size={TextSize.xlg}>
                  {t('VIEWS.MISSING_ON_DEVICES.HEADER')}
                </Text>
              </Flex>

              <Text className="spacing-top-lg spacing-bottom-sm">
                {t('VIEWS.MISSING_ON_DEVICES.DESCRIPTION')}
              </Text>
            </Grid.Item>
            <Grid.Item className="overflow-hidden">
              <Table config={tableConfig}>
                <Table.Header sticky>
                  <Table.Cell>
                    {t('VIEWS.MISSING_ON_DEVICES.TABLE_HEADERS.CODE')}
                  </Table.Cell>
                  <Table.Cell>
                    {t('VIEWS.MISSING_ON_DEVICES.TABLE_HEADERS.NAME')}
                  </Table.Cell>
                  <Table.Cell>
                    {t('VIEWS.MISSING_ON_DEVICES.TABLE_HEADERS.REASON')}
                  </Table.Cell>
                </Table.Header>
                <Table.Body>
                  {invalidDevices?.map(
                    (device, index): JSX.Element => (
                      <ValidationDeviceInfoRow key={index} device={device} />
                    )
                  )}
                </Table.Body>
              </Table>
            </Grid.Item>
          </Grid>
        </Section>
      </Body>
      <Footer
        hasPrimaryButton
        primaryLabel={t('SHARED.BACK')}
        onPrimaryClick={handleBackClick}
      />
    </View>
  );
};
