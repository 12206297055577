import { SettingsCodeMap } from '../../utils';

export interface CustomMessagesState {
  WELCOME_LINE_1?: string;
  WELCOME_LINE_2?: string;
  PHONE_FREE_TEXT?: string;
  EMAIL_FREE_TEXT?: string;
  SCREEN_SAVER_MESSAGE?: string;
  CHECK_IN_FAILED_MESSAGE?: string;
  GOODBYE_MESSAGE?: string;
  NEED_HELP_MESSAGE?: string;
  PREPAYMENT_MESSAGE?: string;
  PRE_AUTHORIZATION_MESSAGE?: string;
  CHECK_IN_CONF_MESSAGE?: string;
  KEY_MESSAGE?: string;
  CHECK_IN_BTN_DESC?: string;
  GENERATE_KEY_BTN_DESC?: string;
  CHECK_OUT_BTN_DESC?: string;
  ROOM_NOT_READY_MESSAGE?: string;
  CHECK_OUT_NOT_POSSIBLE?: string;
  KIOSK_DAY_USE_MESSAGE?: string;
  KIOSK_DUPLICATES_OFF_MESSAGE?: string;
  KIOSK_DUPLICATES_ON_MESSAGE?: string;
  KIOSK_CUSTOM_MESSAGE_PASSPORT_SCANNING?: string;
  SELF_SERVICE_DISCLAIMER?: string;
  CUSTOM_MESSAGE_FISCAL_DOCUMENT_TYPE?: string;
  KIOSK_CUSTOM_FAST_TRACK_CHECKOUT_MESSAGE?: string;
}

export const customMessagesCodesMap: SettingsCodeMap<CustomMessagesState> = {
  WELCOME_LINE_1: {
    key: 'WELCOMELINE1',
    switch: 'SHOWWELCOMELINE1',
  },
  WELCOME_LINE_2: {
    key: 'WELCOMELINE2',
    switch: 'SHOWWELCOMELINE2',
  },
  PHONE_FREE_TEXT: {
    key: 'PHONEFIELDMESSAGE',
    switch: 'SHOWPHONEFIELDMESSAGE',
  },
  EMAIL_FREE_TEXT: {
    key: 'EMAILFIELDMESSAGE',
    switch: 'SHOWEMAILFIELDMESSAGE',
  },
  SCREEN_SAVER_MESSAGE: {
    key: 'SCREENSAVERMESSAGE', // Old logic requires full array of translations for this message
    switch: 'SHOWSCREENSAVERMESSAGE',
  },
  CHECK_IN_FAILED_MESSAGE: {
    key: 'CHECKINFAILEDMESSAGE',
    switch: 'SHOWCHECKINFAILEDMESSAGE',
  },
  GOODBYE_MESSAGE: {
    key: 'GOODBYEMESSAGE',
    switch: 'SHOWGOODBYEMESSAGE',
  },
  NEED_HELP_MESSAGE: {
    key: 'NEEDHELPMESSAGE',
    switch: 'SHOWNEEDHELPMESSAGE',
  },
  PREPAYMENT_MESSAGE: {
    key: 'PREPAYMENTSTEPMESSAGE',
    switch: 'SHOWPREPAYMENTSTEPMESSAGE',
  },
  PRE_AUTHORIZATION_MESSAGE: {
    key: 'PREAUTHSTEPMESSAGE',
    switch: 'SHOWPREAUTHSTEPMESSAGE',
  },
  CHECK_IN_CONF_MESSAGE: {
    key: 'CHECKINCONFMESSAGE',
    switch: 'SHOWCHECKINCONFIRMATIONMESSAGE',
  },
  KEY_MESSAGE: {
    key: 'KEYMESSAGE',
    switch: 'SHOWGENERATEKEYMESSAGE',
  },
  CHECK_IN_BTN_DESC: {
    key: 'CHECKINBTNDESC',
    switch: 'SHOWCHECKINBUTTONDESCRIPTION',
  },
  GENERATE_KEY_BTN_DESC: {
    key: 'GENERATEKEYBTNDESC',
    switch: 'SHOWGENERATEKEYSBUTTONDESCRIPTION',
  },
  CHECK_OUT_BTN_DESC: {
    key: 'CHECKOUTBTNDESC',
    switch: 'SHOWCHECKOUTBUTTONDESCRIPTION',
  },
  ROOM_NOT_READY_MESSAGE: {
    key: 'ROOMNOTREADYMESSAGE',
    switch: 'SHOWROOMNOTREADYMESSAGE',
  },
  CHECK_OUT_NOT_POSSIBLE: {
    key: 'CHECKOUTNOTPOSSIBLE',
    switch: 'SHOWCHECKOUTNOTPOSSIBLEMESSAGE',
  },
  KIOSK_DAY_USE_MESSAGE: {
    key: 'KIOSKEXCPECTEDDEPARTURETIMEMESSAGE',
    switch: 'SHOWEXPECTEDDEPARTURETIMEMESSAGE',
  },
  KIOSK_DUPLICATES_OFF_MESSAGE: {
    key: 'KIOSKCUSTOMMESSAGEDUPLICATESOFF',
    switch: 'SHOWCUSTOMMESSAGEDUPLICATESOFF',
  },
  KIOSK_DUPLICATES_ON_MESSAGE: {
    key: 'KIOSKCUSTOMMESSAGEDUPLICATESON',
    switch: 'SHOWCUSTOMMESSAGEDUPLICATESON',
  },
  KIOSK_CUSTOM_MESSAGE_PASSPORT_SCANNING: {
    key: 'KIOSKCUSTOMMESSAGEPASSPORTSCANNING',
    switch: 'SHOWCUSTOMMESSAGEPASSPORTSCANNING',
  },
  SELF_SERVICE_DISCLAIMER: {
    key: 'SELFSERVICEDISCLAIMER',
    switch: 'SHOWDISCLAIMER',
  },
  CUSTOM_MESSAGE_FISCAL_DOCUMENT_TYPE: {
    key: 'CUSTOMMESSAGEFISCALDOCUMENTTYPE',
    switch: 'SHOWCUSTOMMESSAGEFISCALDOCUMENTTYPE',
  },
  KIOSK_CUSTOM_FAST_TRACK_CHECKOUT_MESSAGE: {
    key: 'KIOSKCUSTOMFASTTRACKCHECKOUTMESSAGE',
    switch: 'SHOWCUSTOMFASTTRACKCHECKOUTMESSAGE',
  },
};
