import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormErrorMessage, FormTextField } from 'components';
import { compose } from 'redux';

import { IconTypes } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './DatePickerField.style';
import MobileDatePicker from './MobileDatePicker';

interface PassedProps {
  value?: Date;
  input: {
    value?: string;
    onChange?: (date: string) => {};
  };
  meta: {
    active: boolean;
    error: string;
    touched: boolean;
  };
  label: string;
  disabled: boolean;
}

interface DatePickerFieldProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

interface DatePickerFieldState {
  isModalOpen: boolean;
}

class DatePickerField extends PureComponent<
  DatePickerFieldProps,
  DatePickerFieldState
> {
  public static defaultProps = {
    meta: {
      active: false,
      error: '',
      touched: false,
    },
    input: {},
    disabled: false,
  };

  public state = {
    isModalOpen: false,
  };

  public pickerId = 'calendar_id';

  public render() {
    const {
      input,
      classes,
      meta: { active, error, touched },
      label,
      disabled,
    } = this.props;
    const { isModalOpen } = this.state;
    const isError = (touched || input.value) && error && !active;

    return (
      <div className={classes.formWrapper}>
        <FormTextField
          readOnly
          icon={IconTypes.calendar}
          label={label}
          onClick={!disabled && this.openModal}
          onIconClick={!disabled && this.openModal}
          className={classes.textField}
          {...input}
          isError={isError}
          id={this.pickerId}
          disabled={disabled}
        />
        <MobileDatePicker
          isOpen={isModalOpen}
          closeModal={this.closeModal}
          input={input}
          label={label}
        />
        {isError && <FormErrorMessage error={error} />}
      </div>
    );
  }

  private openModal = () => {
    const el = document.getElementById(this.pickerId);
    if (el) {
      el.blur();
    }
    this.setState({ isModalOpen: true });
  };

  private closeModal = () => this.setState({ isModalOpen: false });
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(DatePickerField) as (props: PassedProps) => JSX.Element;
