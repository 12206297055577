import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { IconTypes, Loader } from '@ac/kiosk-components';

import { Button, Grid, MobileStepper, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './Pending.style';

interface PassedProps {
  keysNumber: number;
  currentKey?: number;
}

interface PendingProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class Pending extends PureComponent<PendingProps> {
  public render() {
    const { classes, t, keysNumber, currentKey = 0 } = this.props;

    return (
      <Grid className={classes.content}>
        <Grid className={classes.iconWrapper}>
          <Loader icon={IconTypes.key} />
        </Grid>
        <Typography className={classes.title}>
          {t('MAKING_WITH_COUNT', { current: currentKey, max: keysNumber })}
        </Typography>
        <Typography className={classes.subtitle}>
          {t('PLEASE_WAIT_KEYS')}
        </Typography>
        <MobileStepper
          variant="dots"
          steps={keysNumber}
          position="static"
          activeStep={currentKey - 1}
          nextButton={<Button className={classes.hidden} children="" />}
          backButton={<Button className={classes.hidden} children="" />}
          className={classes.stepper}
        />
      </Grid>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(Pending) as (
  props: PassedProps
) => JSX.Element;
