import { KioskCheckInUpdateProfile } from '@gss/api/KioskApi/entries';

import { CheckInCommunicationDetailsFormValues } from '../types';

import { ScannedAddressStatus } from './interfaces';
import types from './types';

export const cacheCommunicationDetailsFormData = (
  communicationDetailsFormData: CheckInCommunicationDetailsFormValues
) => ({
  type: types.CACHE_COMMUNICATION_DETAILS_FORM_DATA,
  payload: communicationDetailsFormData,
});

export const setCachedAddressId = (id: string) => ({
  type: types.SET_CACHED_ADDRESS_ID,
  payload: id,
});

export const setScannedAddressStatus = (
  scannedAddressStatus: ScannedAddressStatus
) => ({
  type: types.SET_SCANNED_ADDRESS_STATUS,
  payload: scannedAddressStatus,
});

export const setUpdateCommunicationDetailsPayload = (
  communicationDetailsPayload: Omit<
    KioskCheckInUpdateProfile,
    'identityDocuments'
  >
) => ({
  type: types.SET_UPDATE_COMMUNICATION_DETAILS_PAYLOAD,
  payload: communicationDetailsPayload,
});

export const clearUpdateCommunicationDetailsPayload = () => ({
  type: types.CLEAR_UPDATE_COMMUNICATION_DETAILS_PAYLOAD,
});

export const resetCommunicationChannelsUpdateProgress = () => ({
  type: types.RESET_COMMUNICATION_CHANNELS_UPDATE_PROGRESS,
});
