/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getDepartureHour } from 'store/reservation/selectors';
import Store from 'types/Store';
import { Configurator } from 'utils';

import { Circular, CircularType, IconTypes } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles, { ICON_WRAPPER_SIZE } from './DayUseDetails.style';

const { KIOSK_DAY_USE_MESSAGE } = Configurator.getTranslationCodes();

interface PassedProps {
  departureHour?: string;
}

interface DayUseDetailsProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class DayUseDetails extends PureComponent<DayUseDetailsProps> {
  public render() {
    const { classes, t, departureHour } = this.props;
    const description = Configurator.getTranslation(KIOSK_DAY_USE_MESSAGE);

    return (
      <Grid className={classes.wrapper}>
        <Grid className={classes.iconWrapper}>
          <Circular
            size={ICON_WRAPPER_SIZE}
            icon={IconTypes.exclamationMark}
            iconClassName={classes.icon}
            type={CircularType.warning}
          />
        </Grid>
        <Typography className={classes.title}>
          <span
            dangerouslySetInnerHTML={{
              __html: t('EXPECTED_DEPARTURE_DESCRIPTION', {
                time: departureHour,
              }),
            }}
          />
          {description}
        </Typography>
      </Grid>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  departureHour: getDepartureHour(state),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(DayUseDetails) as (props: PassedProps) => JSX.Element;
