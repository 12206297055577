import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 20;

const icon = (spacing: (unit: number) => number) => ({
  height: ICON_SIZE,
  width: ICON_SIZE,
  marginRight: spacing(1 / 2),
});

const getWrapperStyles = (spacing: (unit: number) => number) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:not(:first-child)': {
    marginLeft: spacing(2),
  },
  '&:not(:last-child)': {
    marginRight: spacing(2),
  },
});

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      ...getWrapperStyles(spacing),
    },
    title: {
      color: palette.common.black,
      lineHeight: '22px',
      marginLeft: spacing(1 / 2),
    },
    titleActive: {
      color: palette.primary.main,
      marginLeft: spacing(1 / 2),
      fontWeight: 600,
    },
    icon: {
      ...icon(spacing),
      fill: palette.primary.main,
    },
    iconSuccess: {
      ...icon(spacing),
      fill: colors.success[600],
    },
  });
