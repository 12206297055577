var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-text {\n    margin: var(--spacing-none);\n    word-break: break-word;\n  }\n\n  .pdf-text.xxs {\n    font-size: var(--font-size-xs);\n  }\n\n  .pdf-text.xs {\n    font-size: var(--font-size-xs);\n  }\n\n  .pdf-text.s {\n    font-size: var(--font-size-s);\n  }\n\n  .pdf-text.sm {\n    font-size: var(--font-size-sm);\n  }\n\n  .pdf-text.md {\n    font-size: var(--font-size-md);\n  }\n\n  .pdf-text.lg {\n    font-size: var(--font-size-lg);\n  }\n\n  .pdf-text.xlg {\n    font-size: var(--font-size-xlg);\n  }\n\n  .pdf-text.xxlg {\n    font-size: var(--font-size-xxlg);\n  }\n\n  .pdf-text.light {\n    font-weight: var(--font-weight-normal);\n    color: var(--color-dark-gray2);\n  }\n\n  .pdf-text.normal {\n    font-weight: var(--font-weight-normal);\n  }\n\n  .pdf-text.bold {\n    font-weight: var(--font-weight-bold);\n  }\n</style>\n\n<p class=\""
    + container.escapeExpression(__default(require("../helpers/classNames.ts")).call(alias1,"pdf-text",__default(require("../helpers/operators.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),"||","md",{"name":"operators","hash":{},"data":data,"loc":{"start":{"line":53,"column":34},"end":{"line":53,"column":60}}}),__default(require("../helpers/operators.ts")).call(alias1,(depth0 != null ? lookupProperty(depth0,"weight") : depth0),"||","normal",{"name":"operators","hash":{},"data":data,"loc":{"start":{"line":53,"column":61},"end":{"line":53,"column":93}}}),(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"classNames","hash":{},"data":data,"loc":{"start":{"line":53,"column":10},"end":{"line":53,"column":101}}}))
    + "\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</p>\n";
},"usePartial":true,"useData":true});