const FETCH_PURCHASE_ELEMENTS_REQUEST = 'FETCH_PURCHASE_ELEMENTS_REQUEST';
const FETCH_PURCHASE_ELEMENTS_SUCCESS = 'FETCH_PURCHASE_ELEMENTS_SUCCESS';
const FETCH_PURCHASE_ELEMENTS_FAILURE = 'FETCH_PURCHASE_ELEMENTS_FAILURE';
const FETCH_PURCHASE_ELEMENT_IMAGES_REQUEST =
  'FETCH_PURCHASE_ELEMENT_IMAGES_REQUEST';
const FETCH_PURCHASE_ELEMENT_IMAGES_SUCCESS =
  'FETCH_PURCHASE_ELEMENT_IMAGES_SUCCESS';
const FETCH_PURCHASE_ELEMENT_IMAGES_FAILURE =
  'FETCH_PURCHASE_ELEMENT_IMAGES_FAILURE';
const FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_REQUEST =
  'FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_REQUEST';
const FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_SUCCESS =
  'FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_SUCCESS';
const FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_FAILURE =
  'FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_FAILURE';
const FETCH_RATE_PLANS_REQUEST = 'FETCH_RATE_PLANS_REQUEST';
const FETCH_RATE_PLANS_SUCCESS = 'FETCH_RATE_PLANS_SUCCESS';
const FETCH_RATE_PLANS_FAILURE = 'FETCH_RATE_PLANS_FAILURE';
const FETCH_AGE_BUCKETS_REQUEST = 'FETCH_AGE_BUCKETS_REQUEST';
const FETCH_AGE_BUCKETS_SUCCESS = 'FETCH_AGE_BUCKETS_SUCCESS';
const FETCH_AGE_BUCKETS_FAILURE = 'FETCH_AGE_BUCKETS_FAILURE';

export default {
  FETCH_PURCHASE_ELEMENTS_REQUEST,
  FETCH_PURCHASE_ELEMENTS_SUCCESS,
  FETCH_PURCHASE_ELEMENTS_FAILURE,
  FETCH_PURCHASE_ELEMENT_IMAGES_REQUEST,
  FETCH_PURCHASE_ELEMENT_IMAGES_SUCCESS,
  FETCH_PURCHASE_ELEMENT_IMAGES_FAILURE,
  FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_REQUEST,
  FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_SUCCESS,
  FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_FAILURE,
  FETCH_RATE_PLANS_REQUEST,
  FETCH_RATE_PLANS_SUCCESS,
  FETCH_RATE_PLANS_FAILURE,
  FETCH_AGE_BUCKETS_REQUEST,
  FETCH_AGE_BUCKETS_SUCCESS,
  FETCH_AGE_BUCKETS_FAILURE,
};
