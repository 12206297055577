const CREATE_ATTACHMENT_REQUEST = 'CREATE_ATTACHMENT_REQUEST';
const CREATE_ATTACHMENT_SUCCESS = 'CREATE_ATTACHMENT_SUCCESS';
const CREATE_ATTACHMENT_FAILURE = 'CREATE_ATTACHMENT_FAILURE';
const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
const GENERATE_SUMMARY_PDF_REQUEST = 'GENERATE_SUMMARY_PDF_REQUEST';
const GENERATE_SUMMARY_PDF_SUCCESS = 'GENERATE_SUMMARY_PDF_SUCCESS';
const GENERATE_SUMMARY_PDF_FAILURE = 'GENERATE_SUMMARY_PDF_FAILURE';
const SAVE_FOLIO_DOCUMENT_REQUEST = 'SAVE_FOLIO_DOCUMENT_REQUEST';
const SAVE_FOLIO_DOCUMENT_SUCCESS = 'SAVE_FOLIO_DOCUMENT_SUCCESS';
const SAVE_FOLIO_DOCUMENT_FAILURE = 'SAVE_FOLIO_DOCUMENT_FAILURE';
const UPLOAD_PDF_REQUEST = 'UPLOAD_PDF_REQUEST';
const UPLOAD_PDF_SUCCESS = 'UPLOAD_PDF_SUCCESS';
const UPLOAD_PDF_FAILURE = 'UPLOAD_PDF_FAILURE';
const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';
const SAVE_PHOTOS_REQUEST = 'SAVE_PHOTOS_REQUEST';
const SAVE_PHOTOS_SUCCESS = 'SAVE_PHOTOS_SUCCESS';
const SAVE_PHOTOS_FAILURE = 'SAVE_PHOTOS_FAILURE';

const CLEAR_FILES_ERRORS = 'CLEAR_CASHIERING_ERRORS';
const RESET_FILES_STATE = 'RESET_FILES_STATE';

export default {
  CLEAR_FILES_ERRORS,
  CREATE_ATTACHMENT_REQUEST,
  CREATE_ATTACHMENT_SUCCESS,
  CREATE_ATTACHMENT_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  GENERATE_SUMMARY_PDF_REQUEST,
  GENERATE_SUMMARY_PDF_SUCCESS,
  GENERATE_SUMMARY_PDF_FAILURE,
  SAVE_FOLIO_DOCUMENT_REQUEST,
  SAVE_FOLIO_DOCUMENT_SUCCESS,
  SAVE_FOLIO_DOCUMENT_FAILURE,
  UPLOAD_PDF_REQUEST,
  UPLOAD_PDF_SUCCESS,
  UPLOAD_PDF_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  SAVE_PHOTOS_REQUEST,
  SAVE_PHOTOS_SUCCESS,
  SAVE_PHOTOS_FAILURE,
  RESET_FILES_STATE,
};
