import { useEffect, useState } from 'react';

export function useDepositBalance(fetchDepositBalance: () => Promise<any>) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchDepositBalance();
      setIsLoading(false);
    })();
  }, [fetchDepositBalance]);

  return isLoading;
}
