import differenceBy from 'lodash.differenceby';
import { fetchProfile } from 'store/profile/actions';
import {
  getActiveDocuments,
  getAddresses,
  getProfile,
} from 'store/profile/selectors';
import { Dispatch, GetState } from 'store/utils/actions';
import { Configurator, DateManager } from 'utils';
import {
  clearUpdateCommunicationDetailsPayload,
  resetCommunicationChannelsUpdateProgress,
  setCachedAddressId,
} from 'views/CheckInCommunicationDetails/store/actions';
import { getCommunicationDetailsUpdatePayload } from 'views/CheckInCommunicationDetails/store/selectors';
import {
  clearDocumentUpdatePayload,
  resetDocumentUpdateProgress,
  setCachedDocumentId,
} from 'views/CheckInID/store/actions';
import { getDocumentUpdatePayload } from 'views/CheckInID/store/selectors';

import { PDFCreator } from '@ac/kiosk-components';

import { SelfServiceCheckInProcessApi } from '@gss/api/KioskApi';
import {
  DocumentScanningResultDetails,
  KioskCheckInUpdateConsents,
  KioskLibraryApiResponse,
} from '@gss/api/KioskApi/entries';
import { API_HEADERS } from '@gss/configs/constants';

import {
  getCheckInProcessSessionId,
  getCheckInSummaryPdf,
  getCurrentProcessConsents,
} from './selectors';
import types from './types';
import { preparePdfContent } from './utils';

export const startCheckInProcess =
  (profileId: string) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: types.START_CHECK_IN_PROCESS_REQUEST });

      const response = (await SelfServiceCheckInProcessApi.stateCheckInProcess({
        data: {
          profileId,
        },
        fullResponse: true,
      })) as KioskLibraryApiResponse<undefined>;

      const kioskSessionId =
        response.headers[API_HEADERS.kioskSessionId.toLowerCase()];

      return dispatch({
        type: types.START_CHECK_IN_PROCESS_SUCCESS,
        payload: kioskSessionId,
      });
    } catch (error) {
      return dispatch({
        type: types.START_CHECK_IN_PROCESS_FAILURE,
        payload: error,
      });
    }
  };

export const saveScannedDocument = (
  document: DocumentScanningResultDetails
) => ({
  type: types.SAVE_SCANNED_DOCUMENT,
  payload: document,
});

export const sendUpdateProfileData =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.CHECK_IN_PROCESS_UPDATE_PROFILE_REQUEST });

      const state = getState();
      const kioskSessionId = getCheckInProcessSessionId(state);
      const profile = getProfile(state);
      const documents = getActiveDocuments(state);
      const addresses = getAddresses(state);

      const payload = {
        ...getCommunicationDetailsUpdatePayload(state),
        ...getDocumentUpdatePayload(state),
      };

      if (!Object.keys(payload).length) {
        return dispatch({
          type: types.CHECK_IN_PROCESS_UPDATE_PROFILE_SUCCESS,
        });
      }

      await SelfServiceCheckInProcessApi.updateProfile({
        data: payload,
        customConfig: {
          headers: {
            [API_HEADERS.kioskSessionId]: kioskSessionId,
          },
        },
      });

      await dispatch(fetchProfile(profile.id));
      const newState = getState();

      if (payload?.addresses?.add?.length) {
        const newAddresses = getAddresses(newState);
        const newAddressId = differenceBy(newAddresses, addresses, 'id')?.[0]
          ?.id;
        if (newAddressId) {
          dispatch(setCachedAddressId(newAddressId));
        }
      }

      if (payload?.identityDocuments?.add?.length) {
        const newDocuments = getActiveDocuments(newState);
        const newDocumentId = differenceBy(
          newDocuments,
          documents,
          'identificationDocumentId'
        )?.[0]?.identificationDocumentId;
        if (newDocumentId) {
          dispatch(setCachedDocumentId(newDocumentId));
        }
      }

      dispatch(clearProfileUpdatePayloads());

      return dispatch({ type: types.CHECK_IN_PROCESS_UPDATE_PROFILE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.CHECK_IN_PROCESS_UPDATE_PROFILE_FAILURE,
        payload: error,
      });
    }
  };

export const clearProfileUpdatePayloads = () => (dispatch: Dispatch) => {
  dispatch(clearDocumentUpdatePayload());
  dispatch(clearUpdateCommunicationDetailsPayload());
};

export const resetProfileUpdateProgress = () => (dispatch: Dispatch) => {
  dispatch(resetDocumentUpdateProgress());
  dispatch(resetCommunicationChannelsUpdateProgress());
};

export const updateChangesInProfileConsent =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.CHECK_IN_PROCESS_UPDATE_CONSENTS_REQUEST });

      const state = getState();
      const kioskSessionId = getCheckInProcessSessionId(state);
      const profile = getProfile(state);

      const profileConsents = profile?.consents || [];
      const currentProcessConsents = getCurrentProcessConsents(state);

      const payload = currentProcessConsents.reduce<KioskCheckInUpdateConsents>(
        (payloadObj, consent) => {
          const storedConsent = profileConsents.find(
            ({ consentId }) => consentId === consent.id
          );
          const consentChanged = storedConsent?.isGranted !== consent.isGranted;
          if (!consentChanged) return payloadObj;

          const consentDictionary = Configurator.consents.find(
            ({ id }) => id === consent.id
          );
          const defaultExpiryPeriod = consentDictionary?.defaultExpiryPeriod
            ? DateManager.addDays(
                Configurator.propertyBusinessDate,
                consentDictionary.defaultExpiryPeriod
              )
            : undefined;

          if (consent.isGranted) {
            payloadObj.grantConsents?.push({
              consentId: consent.id,
              expiryDate: defaultExpiryPeriod,
            });
          } else if (storedConsent && !consent.isGranted) {
            payloadObj.revokeConsents?.push(consent.id);
          }

          return payloadObj;
        },
        {
          grantConsents: [],
          revokeConsents: [],
        }
      );

      const isAnyUpdate = Boolean(
        payload.grantConsents?.length || payload.revokeConsents?.length
      );
      if (!isAnyUpdate) {
        return dispatch({
          type: types.CHECK_IN_PROCESS_UPDATE_CONSENTS_SUCCESS,
        });
      }

      await SelfServiceCheckInProcessApi.updateConsents({
        data: payload,
        customConfig: {
          headers: {
            [API_HEADERS.kioskSessionId]: kioskSessionId,
          },
        },
      });

      await dispatch(fetchProfile(profile.id));

      dispatch(clearProfileUpdatePayloads());

      return dispatch({ type: types.CHECK_IN_PROCESS_UPDATE_CONSENTS_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.CHECK_IN_PROCESS_UPDATE_CONSENTS_FAILURE,
        payload: error,
      });
    }
  };

export const generateCheckInSummaryPdf =
  (signature: Blob) => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.CHECK_IN_GENERATE_SUMMARY_PDF_REQUEST });

      const state = getState();
      const oldPdf = getCheckInSummaryPdf(state);

      if (oldPdf) {
        URL.revokeObjectURL(oldPdf);
      }

      const pdfContent = await preparePdfContent(state, signature);
      const pdf = await new PDFCreator().createPDF(pdfContent);

      return dispatch({
        type: types.CHECK_IN_GENERATE_SUMMARY_PDF_SUCCESS,
        payload: URL.createObjectURL(pdf),
      });
    } catch (error) {
      return dispatch({
        error,
        type: types.CHECK_IN_GENERATE_SUMMARY_PDF_FAILURE,
      });
    }
  };

export const resetCheckInState =
  () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const oldPdf = getCheckInSummaryPdf(state);

    if (oldPdf) {
      URL.revokeObjectURL(oldPdf);
    }

    dispatch({ type: types.RESET_CHECK_IN_STATE });
  };
