export interface RawKioskSupportedUiLanguageDetails {
  code?: string;
  name?: string;
  nativeLanguageName?: string;
}

export class KioskSupportedUiLanguageDetails {
  public code?: string;
  public name?: string;
  public nativeLanguageName?: string;

  constructor(data?: RawKioskSupportedUiLanguageDetails) {
    this.code = data?.code;
    this.name = data?.name;
    this.nativeLanguageName = data?.nativeLanguageName;
  }
}
