import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Configurator } from 'utils';

import { Field, SelectField, ValidationHeader } from '@ac/kiosk-components';
import {
  createRequiredValidator,
  formNestedFieldFactory,
} from '@ac/react-infrastructure';

import { mapSelectOptions } from '@gss/utils';
import { FormValidator, mapFieldRenderProps } from '@gss/utils/form';

import { AdditionalInfoFormValues } from '../types';

import './AdditionalInfoForm.scss';

const NestedFormField =
  formNestedFieldFactory<Record<string, AdditionalInfoFormValues>>();

interface AdditionalInfoFormProps {
  nestedPropertyName: string;
  className?: string;
  isNationalityEnabled: boolean;
  isTaxIDEnabled: boolean;
}

const AdditionalInfoForm = ({
  nestedPropertyName,
  className,
  isNationalityEnabled,
  isTaxIDEnabled,
}: AdditionalInfoFormProps) => {
  const { t, i18n } = useTranslation();

  const validator = useMemo(() => {
    if (!isNationalityEnabled) {
      return;
    }

    return new FormValidator<Record<string, AdditionalInfoFormValues>>({
      [nestedPropertyName]: {
        nationalityCode: createRequiredValidator(t('FIELD_IS_MANDATORY')),
      },
    });
  }, [i18n.language]);

  const nationalityOptions = useMemo(() => {
    const nationalities = Configurator.getNationalities(i18n.language);

    return mapSelectOptions(nationalities);
  }, [i18n.language]);

  return (
    <div className={classNames('additional-info-form', className)}>
      <ValidationHeader
        isValid={!isNationalityEnabled || validator?.isValid}
        title={t('ADDITIONAL_INFORMATION')}
        className="spacing-bottom-lg additional-info-form-header"
      />

      {isNationalityEnabled && (
        <NestedFormField
          valuePath={[nestedPropertyName, 'nationalityCode']}
          validateFields={[[nestedPropertyName, 'nationalityCode'].join('.')]}
          validate={validator?.validateSingleField}
        >
          {(fieldRenderProps) => (
            <SelectField
              {...mapFieldRenderProps(fieldRenderProps)}
              label={t('NATIONALITY')}
              placeholder={t('SELECT')}
              className="additional-info-form-nationality"
              modalClassName="with-default-kiosk-components-theme"
              options={nationalityOptions}
            />
          )}
        </NestedFormField>
      )}

      {isTaxIDEnabled && (
        <NestedFormField valuePath={[nestedPropertyName, 'taxId']}>
          {(fieldRenderProps) => (
            <Field
              {...mapFieldRenderProps(fieldRenderProps)}
              label={t('TAX_ID')}
              optional
              placeholder={t('FILL')}
              className="additional-info-form-nationality"
            />
          )}
        </NestedFormField>
      )}
    </div>
  );
};

export default AdditionalInfoForm;
