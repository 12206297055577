const CACHE_COMMUNICATION_DETAILS_FORM_DATA =
  'CACHE_COMMUNICATION_DETAILS_FORM_DATA';
const SET_CACHED_ADDRESS_ID = 'SET_CACHED_ADDRESS_ID';
const SET_SCANNED_ADDRESS_STATUS = 'SET_SCANNED_ADDRESS_STATUS';
const RESET_COMMUNICATION_CHANNELS_UPDATE_PROGRESS =
  'RESET_COMMUNICATION_CHANNELS_UPDATE_PROGRESS';
const SET_UPDATE_COMMUNICATION_DETAILS_PAYLOAD =
  'SET_UPDATE_COMMUNICATION_DETAILS_PAYLOAD';
const CLEAR_UPDATE_COMMUNICATION_DETAILS_PAYLOAD =
  'CLEAR_UPDATE_COMMUNICATION_DETAILS_PAYLOAD';

export default {
  CACHE_COMMUNICATION_DETAILS_FORM_DATA,
  SET_SCANNED_ADDRESS_STATUS,
  RESET_COMMUNICATION_CHANNELS_UPDATE_PROGRESS,
  SET_CACHED_ADDRESS_ID,
  SET_UPDATE_COMMUNICATION_DETAILS_PAYLOAD,
  CLEAR_UPDATE_COMMUNICATION_DETAILS_PAYLOAD,
};
