import { colors, fonts, getPalette } from 'config';
import { addHoverStyles, Colors, Configurator } from 'utils';

import { ThemeOptions } from '@material-ui/core/styles';

export const BUTTON_HEIGHT = 44;
export const FONT_FAMILY = 'Open Sans';

export default (): ThemeOptions => {
  const {
    colorCodes: {
      BUTTON_PRIMARY_TEXT_ACTIVE,
      BUTTON_SECONDARY_TEXT_ACTIVE,
      BUTTON_PRIMARY_BACKGROUND_CLICKED,
      BUTTON_PRIMARY_BORDER_CLICKED,
      BUTTON_PRIMARY_TEXT_CLICKED,
      BUTTON_SECONDARY_BACKGROUND_CLICKED,
      BUTTON_SECONDARY_BORDER_CLICKED,
      BUTTON_SECONDARY_TEXT_CLICKED,
    },
  } = Configurator;

  return {
    typography: fonts.getTypography(),
    palette: getPalette(),
    overrides: {
      MuiSelect: {
        select: {
          backgroundColor: 'transparent !important',
        },
      },
      MuiButton: {
        label: {
          zIndex: 999,
          wordBreak: 'keep-all',
        },
        contained: {
          fontWeight: 600,
          borderRadius: 6,
          fontSize: '1rem',
          boxShadow: 'none',
          height: BUTTON_HEIGHT,
          color: Configurator.getColor(BUTTON_PRIMARY_TEXT_ACTIVE),
        },
        containedPrimary: {
          ...addHoverStyles({
            ...Colors.getActiveButtonStyles(getPalette(), {
              background: BUTTON_PRIMARY_BACKGROUND_CLICKED,
              border: BUTTON_PRIMARY_BORDER_CLICKED,
            }),
            '& span': {
              color: Configurator.getColor(
                BUTTON_PRIMARY_TEXT_CLICKED,
                getPalette().primary.contrastText
              ),
            },
            '& svg': {
              fill: Configurator.getColor(
                BUTTON_PRIMARY_TEXT_CLICKED,
                getPalette().primary.contrastText
              ),
            },
          }),
        },
        outlined: {
          fontWeight: 600,
          backgroundColor: colors.white,
          fontSize: '1rem',
          height: BUTTON_HEIGHT,
          borderRadius: 6,
          transition: 'all 300ms',
          color: Configurator.getColor(BUTTON_SECONDARY_TEXT_ACTIVE),
        },
        outlinedPrimary: {
          ...addHoverStyles({
            ...Colors.getActiveButtonStyles(
              getPalette(),
              {
                background: BUTTON_SECONDARY_BACKGROUND_CLICKED,
                border: BUTTON_SECONDARY_BORDER_CLICKED,
              },
              'outlined'
            ),
            '& span': {
              color: Configurator.getColor(
                BUTTON_SECONDARY_TEXT_CLICKED,
                getPalette().primary.main
              ),
            },
            '& svg': {
              fill: Configurator.getColor(
                BUTTON_SECONDARY_TEXT_CLICKED,
                getPalette().secondary.contrastText
              ),
            },
          }),
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow: 'none',
        },
      },
      MuiTypography: {
        root: {
          color: 'inherit',
        },
      },
    },
  };
};
