export { default as api } from './api-endpoints';
export { default as colors } from './colors';
export { default as fonts } from './fonts';
export { default as ids } from './identifiers';
export { default as getPalette } from './palette';
export { default as getTheme } from './theme';

export default {
  API_URL: process.env.REACT_APP_API_URL,
  ENV: process.env.NODE_ENV || 'development',
};
