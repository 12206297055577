import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const fieldPadding = (spacing: any) => ({
  paddingLeft: spacing(1),
  paddingRight: spacing(1),
  '&:first-child': {
    paddingLeft: 0,
  },
  '&:last-child': {
    paddingRight: 0,
  },
});

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      border: `1px solid ${palette.primary.dark}`,
      backgroundColor: colors.grey[75],
    },
    formWrapper: {
      width: '100%',
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    formSummary: {
      display: 'flex',
      alignItems: 'center',
    },
    formTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    companyWrapper: {
      [breakpoints.up('md')]: {
        flexDirection: 'column',
      },
    },
    companyField: {
      [breakpoints.up('md')]: {
        width: '100%',
        maxWidth: '100%',
        flexBasis: 'auto',
      },
      [breakpoints.only('sm')]: {
        '&:first-child': {
          paddingRight: spacing(1),
        },
        '&:last-child': {
          paddingLeft: spacing(1),
        },
      },
    },
    field: {
      [breakpoints.up('sm')]: {
        ...fieldPadding(spacing),
        '&:last-child': {
          paddingRight: spacing(1),
        },
      },
      [breakpoints.up('md')]: {
        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    stateField: {
      [breakpoints.only('sm')]: {
        paddingLeft: spacing(1),
      },
      [breakpoints.up('md')]: {
        paddingRight: spacing(1),
      },
    },
    addressField: {
      [breakpoints.only('sm')]: {
        ...fieldPadding(spacing),
      },
    },
    countryField: {
      [breakpoints.up('md')]: {
        paddingLeft: spacing(1),
      },
    },
    form: {
      marginTop: spacing(2),
    },
    link: {
      fontSize: '1rem',
      textDecoration: 'underline',
      color: palette.primary.main,
    },
  });
