import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export const FOOTER_HEIGHT = 62;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    transparentFooter: {
      backgroundColor: 'transparent',
      border: 0,
    },
    footerChildrenWrapper: {
      display: 'flex',
    },
    backButton: {
      marginRight: spacing(1),
    },
    minibarButton: {
      marginRight: spacing(2.5),
    },
  });
