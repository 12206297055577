import { colors, fonts } from 'config';

import Configurator from './Configurator';

const disabledGrey = 'rgba(0, 0, 0, 0.26)';

class Colors {
  public static getBackgroundColor = (
    palette: any,
    color: {
      colorSwitch: string;
      separateSwitch: string;
    },
    defaultColor?: string
  ) => {
    const backgroundSettings = {
      borderBottom: 'none',
      borderTop: 'none',
      boxShadow: 'none',
    };
    const defaultBackground = defaultColor || palette.primary?.main;
    const configuredColor = Configurator.getColor(color.colorSwitch);
    const shouldSeparateColors = Configurator.getSwitch(color.separateSwitch);
    const backgroundColor = configuredColor || defaultBackground;
    if (!configuredColor && shouldSeparateColors) {
      return { backgroundColor: defaultBackground, opacity: 1 };
    }

    return shouldSeparateColors
      ? {
          backgroundColor,
          opacity: 1,
        }
      : {
          backgroundColor: 'transparent',
          ...backgroundSettings,
        };
  };

  public static getFontColor = (
    palette: any,
    colorSwitch: string,
    defaultColor?: string
  ) => {
    const defaultFontColor = defaultColor || palette.primary?.contrastText;
    const configuredColor = Configurator.getColor(colorSwitch);
    const color = configuredColor || defaultFontColor;

    return { color, fill: color };
  };

  public static getActiveButtonStyles = (
    palette: any,
    colorSwitch: {
      background: string;
      border: string;
    },
    variant?: string,
    defaultColor?: {
      background?: string;
      border?: string;
    }
  ) => {
    const outlinedVariant = variant === 'outlined';
    const defaultPrimaryBackgroundColor = defaultColor
      ? defaultColor.background
      : palette.primary.main;
    const defaultSecondaryBackgroundColor = defaultColor
      ? defaultColor.background
      : palette.common?.white;
    const configuredBackgroundColor = Configurator.getColor(
      colorSwitch.background
    );
    const backgroundColor =
      configuredBackgroundColor ||
      (outlinedVariant
        ? defaultSecondaryBackgroundColor
        : defaultPrimaryBackgroundColor);

    const defaultPrimaryBorderColor = defaultColor
      ? defaultColor.border
      : 'transparent';
    const defaultSecondaryBorderColor = defaultColor
      ? defaultColor.border
      : palette.primary.main;
    const configuredBorderColor = Configurator.getColor(colorSwitch.border);
    const borderColor =
      configuredBorderColor ||
      (outlinedVariant
        ? defaultSecondaryBorderColor
        : defaultPrimaryBorderColor);

    return {
      backgroundColor: `${backgroundColor} !important`,
      border: `1px solid ${borderColor} !important`,
    };
  };

  public static getDisabledButtonStyles = (
    palette: any,
    colorSwitch: {
      background: string;
      border: string;
    },
    variant?: string,
    defaultColor?: {
      background?: string;
      border?: string;
    }
  ) => {
    const outlinedVariant = variant === 'outlined';
    const defaultPrimaryBackgroundColor = defaultColor
      ? defaultColor.background
      : colors.grey[500];
    const defaultSecondaryBackgroundColor = defaultColor
      ? defaultColor.background
      : palette.common?.white;
    const configuredBackgroundColor = Configurator.getColor(
      colorSwitch.background
    );
    const defaultBackgroundColor = outlinedVariant
      ? defaultSecondaryBackgroundColor
      : defaultPrimaryBackgroundColor;
    const backgroundColor = configuredBackgroundColor || defaultBackgroundColor;
    const defaultPrimaryBorderColor = defaultColor
      ? defaultColor.border
      : 'transparent';
    const defaultSecondaryBorderColor = defaultColor
      ? defaultColor.border
      : disabledGrey;
    const configuredBorderColor = Configurator.getColor(colorSwitch.border);
    const defaultBorderColor = outlinedVariant
      ? defaultSecondaryBorderColor
      : defaultPrimaryBorderColor;
    const borderColor = configuredBorderColor || defaultBorderColor;

    return {
      backgroundColor: `${backgroundColor} !important`,
      border: `1px solid ${borderColor} !important`,
    };
  };

  public static getDatePickerColors = () => {
    const defaultColor = colors.primary[500];
    const transparent = 'transparent';
    const fontColor = colors.black;
    const backgroundColor = colors.white;
    const fontFamily = fonts.getfontFamily();
    const defaultPrimaryButton = {
      borderColor: transparent,
      textColor: colors.white,
      backgroundColor: defaultColor,
    };
    const defaultSecondaryButton = {
      borderColor: defaultColor,
      textColor: defaultColor,
      backgroundColor: colors.white,
    };
    const primaryButton = {
      active: defaultPrimaryButton,
      disabled: {
        borderColor: transparent,
        textColor: colors.white,
        backgroundColor: disabledGrey,
      },
      clicked: defaultPrimaryButton,
    };
    const secondaryButton = {
      active: defaultSecondaryButton,
      disabled: {
        borderColor: disabledGrey,
        textColor: disabledGrey,
        backgroundColor: colors.white,
      },
      clicked: defaultSecondaryButton,
    };

    return {
      backgroundColor,
      fontFamily,
      primaryButton,
      secondaryButton,
      fontColor,
    };
  };
}

export default Colors;
