import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './MinibarMenuItem.style';

export interface PassedProps {
  onClick: (title: string) => void;
  itemData: {
    title: string;
    id: string;
    price: number;
  };
  isOrdered: boolean;
}

interface MinibarMenuItemProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class MinibarMenuItem extends PureComponent<MinibarMenuItemProps> {
  public render() {
    const {
      classes,
      t,
      itemData: { title, price },
      isOrdered,
    } = this.props;
    const titleClass = isOrdered ? classes.titleActive : classes.title;
    const subtitleClass = isOrdered ? classes.subtitleActive : classes.subtitle;
    const priceClass = isOrdered ? classes.priceActive : classes.price;

    return (
      <Grid item xs={6} sm={3} md={6}>
        <Button
          onClick={this.handleClick}
          pattern={isOrdered ? ButtonPattern.primary : ButtonPattern.secondary}
          className={classes.button}
        >
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="flex-start"
            className={classes.labelWrapper}
          >
            <Typography className={titleClass}>{title}</Typography>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography className={subtitleClass}>
                {t('ITEM_PRICE')}
              </Typography>
              <Typography className={priceClass}>{price.toFixed(2)}</Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    );
  }
  private handleClick = () => {
    const {
      onClick,
      itemData: { id },
    } = this.props;
    onClick(id);
  };
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(MinibarMenuItem) as (props: PassedProps) => JSX.Element;
