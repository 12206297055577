import { getCheckInProcessSessionId } from 'store/checkInProcess/selectors';
import { Dispatch, GetState } from 'store/utils/actions';

import { repeatableCall } from '@ac/library-utils/dist/utils';

import { SelfServiceCheckInProcessApi } from '@gss/api/KioskApi';
import {
  DocumentScanningResult,
  DocumentScanningResultStatus,
} from '@gss/api/KioskApi/entries';
import { API_HEADERS } from '@gss/configs/constants';
import {
  getScanningDevice,
  getWorkstationId,
} from '@gss/store/configuration/selectors';

import { isDocumentVerificationError } from '../utils/isDocumentVerificationError';
import { isProcessingError } from '../utils/isProcessingError';

import types from './types';

const SCANNING_RESULT_INTERVAL = 1000;
const SCANNING_RESULT_CALL_AMOUNT = 60;

export const scanDocument =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.DOCUMENT_SCAN_REQUEST });

      const state = getState();
      const workstationId = getWorkstationId(state);
      const scanningDevice = getScanningDevice(state);
      const kioskSessionId = getCheckInProcessSessionId(state);

      if (!(workstationId && scanningDevice && kioskSessionId)) {
        throw new Error();
      }

      await SelfServiceCheckInProcessApi.documentScanningStart({
        data: {
          scanningDeviceId: scanningDevice.id,
          workstationId,
        },
        customConfig: {
          headers: {
            [API_HEADERS.kioskSessionId]: kioskSessionId,
          },
        },
      });

      const scanningResult: DocumentScanningResult | undefined =
        await repeatableCall(
          () => {
            return SelfServiceCheckInProcessApi.documentScanningResult({
              customConfig: {
                headers: { [API_HEADERS.kioskSessionId]: kioskSessionId },
              },
            }) as Promise<DocumentScanningResult>;
          },
          (result: DocumentScanningResult | undefined) => {
            return (
              result?.status === DocumentScanningResultStatus.Success ||
              result?.status === DocumentScanningResultStatus.Failure
            );
          },
          {
            intervalTime: SCANNING_RESULT_INTERVAL,
            repeatCount: SCANNING_RESULT_CALL_AMOUNT,
          }
        );

      if (
        !scanningResult ||
        scanningResult.status === DocumentScanningResultStatus.Pending
      ) {
        throw new Error();
      }

      if (
        scanningResult.status === DocumentScanningResultStatus.Failure &&
        scanningResult.error
      ) {
        throw scanningResult.error;
      }

      return dispatch({
        type: types.DOCUMENT_SCAN_SUCCESS,
        payload: scanningResult.result,
      });
    } catch (error) {
      if (isDocumentVerificationError(error) || isProcessingError(error)) {
        return dispatch({ type: types.DOCUMENT_SCAN_FAILURE, payload: error });
      }

      return dispatch({ type: types.DOCUMENT_SCAN_ERROR, payload: error });
    }
  };

export const clearCheckInIDScanningErrors = () => (dispatch: Dispatch) => {
  return dispatch({ type: types.CLEAR_CHECK_IN_ID_SCANNING_ERRORS });
};

export const clearCheckInIDScanningDocument = () => (dispatch: Dispatch) => {
  return dispatch({ type: types.CLEAR_CHECK_IN_ID_SCANNING_DOCUMENT });
};
