import React from 'react';
import { ProfileBox } from 'components';
import { ProfilesDataModel } from 'types/Api/Profile';

import { FormLabel, Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import EmailCheckbox from '../EmailCheckbox';

import styles from './EditEmailField.style';

interface PassedProps {
  profiles: ProfilesDataModel[];
  onChange?: (newValue: string[]) => void;
  input: {
    value: string[];
    onChange: (value: string[]) => {};
  };
}

interface EditEmailFieldProps extends PassedProps, WithStyles<typeof styles> {}

const EditEmailField = (props: EditEmailFieldProps) => {
  const { classes, profiles, onChange, input } = props;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    email: string
  ) => {
    const newValue: string[] = [...input.value];
    event.target.checked
      ? newValue.push(email)
      : newValue.splice(newValue.indexOf(email), 1);
    onChange && onChange(newValue);

    return input.onChange(newValue);
  };

  return (
    <div className={classes.formGroup}>
      {(profiles ?? []).map((profile: ProfilesDataModel, index: number) => {
        const { email, description, details } = profile;

        return (
          <React.Fragment key={`${details}${index}`}>
            <FormLabel classes={{ root: classes.formLabelRoot }}>
              <EmailCheckbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(event, email)
                }
                checked={input.value.indexOf(email) !== -1}
              />
              <Typography className={classes.checkboxText}>{email}</Typography>
            </FormLabel>
            <Grid className={classes.checkboxWrapper}>
              <ProfileBox
                role={description}
                name={details}
                icon={description.toLowerCase()}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(EditEmailField) as (
  props: PassedProps
) => JSX.Element;
