import {
  ApiActionResult,
  CallApiSpecificResponseAction,
} from 'store/middleware/api';
import { failure, request, success } from 'store/utils/reducer';
import { CreditCardAuthorizationDetails } from 'types/Api/Cashiering';
import { State } from 'types/Store/CashieringStore';

export const initialAuthorizations: State['activeAuthorizations'] = [];

export const fetchAuthorizations = (
  state: State,
  action: CallApiSpecificResponseAction<CreditCardAuthorizationDetails>
): State => {
  switch (action.apiAction) {
    case ApiActionResult.request:
      return request(state, 'cashiering');

    case ApiActionResult.failure:
      return failure(state, action, 'cashiering');

    case ApiActionResult.success: {
      const {
        response: { data: authorization },
      } = action;

      return {
        ...success(state, 'cashiering'),
        activeAuthorizations: [
          ...state.activeAuthorizations.filter(
            (stateAuthorization) => stateAuthorization.id !== authorization.id
          ),
          authorization,
        ],
      };
    }

    default:
      return state;
  }
};
