import { LocalizedStringDto, RawSettingFileRelation } from '@ac/library-api';

export interface LanguageSettings {
  languageCode: string;
  supportedLanguages: string[];
}

export type KioskLayoutSettingNestedValue =
  | string
  | string[]
  | boolean
  | LocalizedStringDto[]
  | LanguageSettings;

export type KioskLayoutSettingValue =
  | string
  | boolean
  | { value: KioskLayoutSettingNestedValue }
  | RawSettingFileRelation;

export interface RawKioskLayoutSetting {
  code?: string;
  value?: KioskLayoutSettingValue;
}

export class KioskLayoutSetting {
  public code?: string;
  public value?: KioskLayoutSettingValue;

  constructor(raw: RawKioskLayoutSetting) {
    this.code = raw.code;
    this.value = raw.value;
  }
}
