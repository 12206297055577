import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ breakpoints }: Theme) =>
  createStyles({
    addonsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  });
