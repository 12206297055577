import { createContext, PropsWithChildren } from 'react';

import {
  RouterContextValue,
  useRouterContextValue,
} from './useRouterContextValue';

export const RouterContext = createContext<RouterContextValue | undefined>(
  undefined
);

export const RouterProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const contextValue = useRouterContextValue();

  return (
    <RouterContext.Provider value={contextValue}>
      {children}
    </RouterContext.Provider>
  );
};
