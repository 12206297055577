import { CallApiResponseAction } from 'store/middleware/api';

import CheckInIdStore from './interfaces';
import types from './types';

const initialState: CheckInIdStore = {
  idPhotos: [],
  cachedDocumentFormData: undefined,
  cachedDocumentId: undefined,
  documentUpdatePayload: undefined,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.SAVE_ID_PHOTO: {
      const { idPhotos } = state;

      return {
        ...state,
        idPhotos: [...idPhotos, action.payload],
      };
    }

    case types.REMOVE_ID_PHOTO: {
      const { idPhotos } = state;
      const filteredPhotos = idPhotos.filter(
        (photo) => action.payload !== photo
      );

      return {
        ...state,
        idPhotos: filteredPhotos,
      };
    }

    case types.CACHE_DOCUMENT_FORM_DATA:
      return {
        ...state,
        cachedDocumentFormData: action.payload,
      };

    case types.SET_CACHED_DOCUMENT_ID:
      return {
        ...state,
        cachedDocumentId: action.payload,
      };

    case types.CLEAR_DOCUMENT_UPDATE_PAYLOAD:
      return {
        ...state,
        documentUpdatePayload: undefined,
      };

    case types.SET_DOCUMENT_UPDATE_PAYLOAD:
      return {
        ...state,
        documentUpdatePayload: action.payload,
      };

    case types.RESET_DOCUMENT_UPDATE_PROGRESS:
      return {
        ...state,
        cachedDocumentFormData: undefined,
        cachedDocumentId: undefined,
        documentUpdatePayload: undefined,
      };

    default:
      return state;
  }
};
