import { colors } from 'config';
import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BUTTON_BOTTOM_POSITION = 12;
const BUTTON_RIGHT_POSITION = 12;
const BUTTON_SIZE = 44;

const expandButtonRoot = {
  transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  minWidth: '0 !important',
  padding: '0 !important',
};

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      justifyContent: 'row',
      color: palette.common.black,
      fill: palette.common.black,
    },
    headerSegment: {
      width: '100%',
      flexDirection: 'column-reverse',
      flexWrap: 'nowrap',
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    expansionPanel: {
      zIndex: 999,
      padding: spacing(4),
      borderBottom: `1px solid ${colors.grey[500]}`,
      [breakpoints.up('sm')]: {
        padding: `${spacing(3)}px ${spacing(4)}px`,
      },
      [breakpoints.up('md')]: {
        padding: `${spacing(4)}px ${spacing(11.5)}px`,
      },
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.RESERVATION_HEADER_FONT_COLOR,
        palette.common.black
      ),
    },
    panelExpanded: {
      margin: 0,
    },
    content: {
      '& > :last-child': {
        paddingRight: 0,
      },
      margin: '0 !important',
    },
    price: {
      marginTop: spacing(2),
    },
    expandIcon: {
      width: BUTTON_SIZE,
      height: BUTTON_SIZE,
    },
    expansionSummary: {
      padding: 0,
    },
    guestDetailsTitle: {
      [breakpoints.down('xs')]: {
        marginBottom: spacing(1),
      },
    },
    expansionDetails: {
      padding: 0,
    },
    expandButton: {
      padding: 0,
      minWidth: 0,
      width: BUTTON_SIZE,
      height: BUTTON_SIZE,
      position: 'absolute',
      background: 'none',
      bottom: BUTTON_BOTTOM_POSITION,
      right: BUTTON_RIGHT_POSITION,
      outline: 'none',
    },
    sectionWrapper: {
      paddingTop: `${spacing(2)}px !important`,
    },
    expandButtonRoot: {
      ...expandButtonRoot,
      transform: 'rotate(0deg)',
    },
    expandButtonRootExpanded: {
      ...expandButtonRoot,
      transform: 'rotate(180deg)',
    },
    subtitle: {
      color: palette.common.black,
    },
    dayUseSubtitle: {
      fontSize: '1rem',
      fontWeight: 300,
    },
    priceBreakdownContainer: {
      display: 'flex',
    },
  });
