import { AnyAction } from 'redux';
import {
  singleLoadingFailure as failure,
  singleLoadingRequest as request,
  singleLoadingSuccess as success,
} from 'store/utils/reducer';
import { State } from 'types/Store/FilesStore';
import { Api } from 'utils';

import types from './types';

const initialState: State = {
  isFetching: false,
  errors: [],
  pdfGenerationError: null,
  uploadErrors: [],
  folioFile: undefined,
  attachmentId: '',
};

const getAttachmentId = (headerLocation: string) => {
  const result = headerLocation.split('/');

  return result[result.length - 1];
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.SAVE_FOLIO_DOCUMENT_REQUEST:
      return request(state);
    case types.SAVE_FOLIO_DOCUMENT_SUCCESS: {
      return {
        ...success(state),
        folioFile: undefined,
        isFetching: false,
      };
    }
    case types.SAVE_FOLIO_DOCUMENT_FAILURE:
      return failure(state, action);

    case types.GENERATE_SUMMARY_PDF_REQUEST:
      return request(state);
    case types.GENERATE_SUMMARY_PDF_SUCCESS: {
      const { pdf, folioId } = action.payload;

      return {
        ...success(state),
        folioFile: { id: folioId, file: pdf },
      };
    }
    case types.GENERATE_SUMMARY_PDF_FAILURE: {
      return {
        ...state,
        isFetching: false,
        pdfGenerationError: action.error,
      };
    }

    case types.CREATE_ATTACHMENT_REQUEST:
      return request(state);
    case types.CREATE_ATTACHMENT_SUCCESS: {
      const { location: headerLocation } = action.response.headers;
      const attachmentId = getAttachmentId(headerLocation);

      return {
        ...success(state),
        attachmentId,
      };
    }
    case types.CREATE_ATTACHMENT_FAILURE: {
      const { details } = action.response.data;
      const { uploadErrors } = state;

      return {
        ...state,
        isFetching: false,
        uploadErrors: [...uploadErrors, ...(details || [])],
      };
    }

    case types.UPLOAD_FILE_REQUEST:
      return request(state);
    case types.UPLOAD_FILE_SUCCESS:
      return success(state);
    case types.UPLOAD_FILE_FAILURE: {
      const { uploadErrors } = state;
      const newErrors = Api.getErrors(action.response, uploadErrors);

      return {
        ...state,
        isFetching: false,
        uploadErrors: newErrors,
      };
    }

    case types.CLEAR_FILES_ERRORS:
      return {
        ...state,
        pdfGenerationError: null,
        uploadErrors: [],
      };

    case types.RESET_FILES_STATE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
