import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormHeader, FormTextField } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { paths } from 'utils/Router';
import { confirmationNumber, required } from 'utils/Validator';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { Box, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './AuthorizationForm.style';

interface PassedProps {
  isSecondFactorVisible?: boolean;
  onSubmit: (values: {
    lastName: string;
    identificationNumber: string;
    roomNumber?: string;
  }) => void;
}

interface AuthorizationFormProps
  extends PassedProps,
    InjectedFormProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class AuthorizationForm extends PureComponent<AuthorizationFormProps> {
  public render() {
    const {
      classes,
      t,
      pristine,
      invalid,
      submitting,
      isSecondFactorVisible,
      handleSubmit,
    } = this.props;
    const isSubmitDisabled = pristine || invalid || submitting;

    return (
      <div>
        <FormHeader
          title={t('COMPLETE_THE_FORM')}
          subtitle={t('ALL_FIELDS_MANDATORY')}
        />
        <Form onSubmit={handleSubmit} autoComplete="off">
          <div className={classes.formWrapper}>
            <Field
              name="lastName"
              component={FormTextField}
              validate={[required]}
              label={t('LAST_NAME')}
              className={classes.formInput}
            />
            <Field
              name="identificationNumber"
              validate={[confirmationNumber, required]}
              component={FormTextField}
              label={t('CONFIRMATION_NUMBER')}
              className={classes.formInput}
            />
          </div>

          {isSecondFactorVisible && (
            <Box mb={2} mt={3}>
              <Typography className={classes.subtitle}>
                {t('PROVIDE_ADDITIONAL_INFORMATION')}
              </Typography>
              <Field
                name="roomNumber"
                validate={[required]}
                component={FormTextField}
                label={t('ROOM_NUMBER')}
                className={classes.formInput}
              />
            </Box>
          )}

          <div className={classes.buttonsWrapper}>
            <Button
              id={ids.AUTHORIZATION_FORM_CANCEL_BUTTON}
              onClick={this.cancel}
              pattern={ButtonPattern.secondary}
            >
              {t('CANCEL')}
            </Button>
            <Button
              id={ids.AUTHORIZATION_FORM_SUBMIT_BUTTON}
              type="submit"
              disabled={submitting || isSubmitDisabled}
            >
              {t('GENERATE_KEYS')}
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  private changeRoute = (route: string) => {
    const { history } = this.props;
    history.push(route);
  };

  private cancel = () => this.changeRoute(paths.WELCOME);
}

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  reduxForm({ form: ids.AUTHORIZATION_FORM })
)(AuthorizationForm) as (props: PassedProps) => JSX.Element;
