import Store from 'types/Store';

import { ApiError } from '@ac/library-api';

import { DocumentScanningResultDetails } from '@gss/api/KioskApi/entries';

export const getIsCheckInIDScanningLoading = (state: Store): boolean =>
  Object.values(state.checkInIDScanning.dataFetching).includes(true);

export const getCheckInIDScanningErrors = (
  state: Store
): Array<Error | ApiError> => state.checkInIDScanning.errors;

export const getScannedDocumentDetails = (
  state: Store
): DocumentScanningResultDetails | undefined =>
  state.checkInIDScanning.scannedDocument;

export const getProcessingErrorCounter = (state: Store): number =>
  state.checkInIDScanning.processingErrorCounter;
