import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: spacing(4),
      paddingRight: spacing(11.75),
      paddingBottom: spacing(20.5),
      paddingLeft: spacing(11.75),
      backgroundColor: colors.grey[50],
    },
    formWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    formInput: {
      width: '100%',
      boxSizing: 'border-box',
      '&:not(:last-child)': {
        marginRight: spacing(),
      },
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    helpDescription: {
      marginTop: spacing(0.25),
      marginBottom: spacing(1.5),
    },
    subtitle: {
      fontSize: '0.875rem',
      color: palette.text.secondary,
      marginBottom: spacing(2),
    },
  });
