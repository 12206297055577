import { createSelector } from 'reselect';
import { getRoomTypeAttributes } from 'store/room/selectors';
import Store from 'types/Store/CheckInRoomStore';
import { Configurator } from 'utils';

export const getRoomImages = (state: Store) => state.checkInRoom.images;

export const getRoomImageUrls = (state: Store) => state.checkInRoom.imageUrls;

export const getCheckInRoomErrors = (state: Store) => state.checkInRoom.errors;

export const getAttributes = createSelector(
  getRoomTypeAttributes,
  (attributeIds) =>
    attributeIds
      .map((item) =>
        Configurator.roomAttributes.find((attribute) => attribute.id === item)
      )
      .filter((item) => item)
);

export const getAttributeNames = createSelector(getAttributes, (attributes) =>
  attributes.map((item) => item!.description)
);

export const isFetchingImages = (state: Store) =>
  state.checkInRoom.isFetchingImages;
