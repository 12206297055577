import React, { PureComponent } from 'react';
import { firstLetterUppercase } from 'utils/Configurator';

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FormRadio.style';

interface FormRadioProps extends WithStyles<typeof styles> {
  data: Array<{
    value: string;
    title: string;
  }>;
  disabled?: boolean;
  input: { value: string; onChange: () => any };
}

class FormRadio extends PureComponent<FormRadioProps> {
  public static defaultProps = {
    data: [],
  };

  public render() {
    const { classes, data, input, disabled, ...props } = this.props;
    const tickClass = disabled ? classes.tickDisabled : classes.tick;

    return (
      <FormControl disabled={disabled} {...props}>
        <RadioGroup row name="purposeOfStay" {...input}>
          {data &&
            data.map((elem) => (
              <FormControlLabel
                key={elem.value}
                value={elem.value}
                classes={{
                  label: classes.controlLabel,
                }}
                control={
                  <Radio
                    color="primary"
                    icon={<div className={classes.radio} />}
                    checkedIcon={
                      <div className={classes.radio}>
                        <div className={tickClass} />
                      </div>
                    }
                  />
                }
                label={firstLetterUppercase(elem.title)}
                labelPlacement="end"
              />
            ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default withStyles(styles)(FormRadio);
