import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormTextField } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import {
  Field,
  Form,
  getFormValues,
  initialize,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { saveEmailToSendInvoice } from 'store/cashiering/actions';
import {
  getDetailedFolios,
  getEmailToSendInvoice,
  getFoliosWithCommunicationChannels,
} from 'store/cashiering/selectors';
import { checkEditEmailFormInitialization } from 'store/profile/actions';
import {
  getEmail,
  getProfile,
  getProfilesViews,
  getWasEditEmailFormInitialized,
} from 'store/profile/selectors';
import {
  ChargesFolio,
  FolioWithCommunicationChannels,
} from 'types/Api/Cashiering';
import {
  ContactProfile,
  LinkedProfileViews,
  Profile,
  ProfilesDataModel,
} from 'types/Api/Profile';
import Store from 'types/Store';
import { email as emailValidator } from 'utils/Validator';

import { Button, ButtonPattern } from '@ac/kiosk-components';
import { CommunicationMode } from '@ac/library-api';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import EditEmailField from './EditEmailField';
import styles from './EditEmailForm.style';
import EmailCheckbox from './EmailCheckbox';

interface PassedProps {
  displayedEmail: string;
  onCancel: () => void;
  currentFolioNumber: number;
}

interface EditEmailFormProps
  extends PassedProps,
    WithTranslation,
    InjectedFormProps,
    WithStyles<typeof styles> {
  formValues: {
    emails: string[];
    addEmail: boolean | number;
    newEmail: string;
  };
  onSubmit: (values: FormValues) => {};
  profilesLinkedToReservation: LinkedProfileViews[];
  onInitialize: (form: string, data: object) => void;
  saveEmailToSendInvoice: typeof saveEmailToSendInvoice;
  emailsToSendInvoice: string[];
  profile: Profile;
  wasEditEmailFormInitialized: boolean;
  checkEditEmailFormInitialization: typeof checkEditEmailFormInitialization;
  profileName: string;
  folios: ChargesFolio[];
  foliosWithCommunicationChannels: FolioWithCommunicationChannels[];
}

interface EditEmailFormState {
  isNewEmailInputDisabled: boolean;
  emails: string[];
  profiles: ProfilesDataModel[];
  emailsGroupDisabled: boolean;
  addEmailDisabled: boolean;
}

interface FormValues {
  addEmail: boolean | number;
  emails: string[];
  newEmail: string;
}

class EditEmailForm extends PureComponent<
  EditEmailFormProps,
  EditEmailFormState
> {
  public state = {
    isNewEmailInputDisabled: true,
    emails: [],
    profiles: [],
    emailsGroupDisabled: false,
    addEmailDisabled: true,
  };

  public async componentDidMount() {
    const {
      saveEmailToSendInvoice,
      displayedEmail,
      checkEditEmailFormInitialization,
      wasEditEmailFormInitialized,
    } = this.props;
    if (!wasEditEmailFormInitialized) {
      await saveEmailToSendInvoice([displayedEmail]);
      this.initializeForm();
      checkEditEmailFormInitialization(true);
    }
    const {
      formValues: { addEmail, emails },
    } = this.props;
    addEmail &&
      this.setState({
        addEmailDisabled: false,
        isNewEmailInputDisabled: false,
      });
    emails && !emails.length && this.setState({ emailsGroupDisabled: true });
  }

  public static defaultProps = {
    currentFolioNumber: 0,
    emailsToSendInvoice: [],
  };

  public render() {
    const { classes, handleSubmit, t } = this.props;
    const { isNewEmailInputDisabled } = this.state;

    return (
      <Form
        className={classes.formWrapper}
        onSubmit={handleSubmit(this.onSubmit)}
      >
        <Typography className={classes.title}>
          {t('PROVIDE_MISSING_EMAIL_ADDRESS')}
        </Typography>
        <Field name="emails" component={this.renderEditEmailField} />
        <Typography className={classes.subtitle}>
          {t('ADD_NEW_EMAIL')}
        </Typography>
        <div className={classes.addEmailWrapper}>
          <Field name="addEmail" component={this.renderAddEmail} />
          <Field
            name="newEmail"
            type="email"
            validate={[emailValidator]}
            component={FormTextField}
            disabled={isNewEmailInputDisabled}
            className={classes.emailInput}
            placeholder={t('FILL')}
            onChange={(event: any, input: any) => {
              !event.target.value
                ? this.setState({ addEmailDisabled: true })
                : this.setState({ addEmailDisabled: false });

              return input.onChange;
            }}
          />
        </div>
        <Grid className={classes.buttonWrapper}>
          <Button onClick={this.onCancel} pattern={ButtonPattern.secondary}>
            {t('CANCEL')}
          </Button>
          <Button disabled={this.shouldConfirmBeDisabled()} type="submit">
            {t('CONFIRM')}
          </Button>
        </Grid>
      </Form>
    );
  }

  private renderEditEmailField = ({
    input,
  }: {
    input: { value: string[]; onChange: (value: string[]) => {} };
  }) => {
    return (
      <EditEmailField
        input={input}
        profiles={this.getProfilesData()}
        onChange={(newValue: string[]) => {
          !newValue.length
            ? this.setState({ emailsGroupDisabled: true })
            : this.setState({ emailsGroupDisabled: false });
        }}
      />
    );
  };

  private renderAddEmail = ({
    input,
  }: {
    input: {
      value: string;
      onChange: (event: boolean) => {};
    };
  }) => {
    return (
      <EmailCheckbox
        onChange={(event) => {
          const { formValues } = this.props;
          !event.target.checked ||
          (event.target.checked && !formValues.newEmail)
            ? this.setState({
                addEmailDisabled: true,
                isNewEmailInputDisabled: false,
              })
            : this.setState({ addEmailDisabled: false });

          return input.onChange(event.target.checked);
        }}
        checked={!!input.value}
      />
    );
  };

  private onSubmit = async (values: any) => {
    const { saveEmailToSendInvoice, onCancel } = this.props;
    const valuesArray = [values];
    // eslint-disable-next-line array-callback-return
    await valuesArray.map((value) => {
      if (value.addEmail) {
        this.setState((prevState: EditEmailFormState) => {
          return prevState.emails.length
            ? { emails: [...prevState.emails, value.newEmail] }
            : { emails: [value.newEmail] };
        });
      }
      if (value.emails?.length) {
        this.setState((prevState: EditEmailFormState) => {
          return prevState.emails.length
            ? { emails: [...prevState.emails, ...value.emails] }
            : { emails: value.emails };
        });
      }
    });
    const { emails } = this.state;
    await Promise.resolve(saveEmailToSendInvoice(emails));

    return onCancel();
  };

  private onCancel = () => {
    const { onCancel, onInitialize, emailsToSendInvoice } = this.props;
    const profiles = this.getProfilesData();
    const emailsFromGroup = profiles.filter((profile: ProfilesDataModel) => {
      return emailsToSendInvoice.includes(profile.email);
    });
    const emailsAddEmail = emailsToSendInvoice.filter((email: string) => {
      const emailsFromGroupArray = emailsFromGroup.map((elem) => elem.email);

      return !emailsFromGroupArray.includes(email);
    });
    const data = {
      emails: emailsFromGroup.map((emailFromGroup) => emailFromGroup.email),
      newEmail: emailsAddEmail[0] || '',
      addEmail: emailsAddEmail.length,
    };
    onInitialize(ids.EDIT_EMAIL_FORM, data);

    if (onCancel) return onCancel();
  };

  getDescriptionByFolioType = () => {
    const { t, profile, currentFolioNumber, folios } = this.props;
    const currentFolio = folios[currentFolioNumber];
    if (currentFolio) {
      const {
        folioTypeCode: { code },
        profileId: folioProfileId,
      } = currentFolio;
      if (profile.id === folioProfileId) return t('GUEST');

      switch (code) {
        case 'COM':
          return t('COMPANY');
        case 'GUE':
          return t('INDIVIDUAL');
        case 'TRA':
          return t('TRAVEL_AGENT');
        default:
          return t('INDIVIDUAL');
      }
    }
  };

  private getGuestProfileData = () => {
    const { profile, t } = this.props;
    const {
      communicationDetails,
      details: { firstName, lastName },
    } = profile;
    const emailCommunicationChannels = communicationDetails.length
      ? communicationDetails.filter(
          (channel) => channel.mode === CommunicationMode.Email
        )
      : [];

    return emailCommunicationChannels.length
      ? emailCommunicationChannels.map((emailChannel) => {
          return {
            details: `${firstName} ${lastName}`,
            description: t('GUEST'),
            email: emailChannel.details,
          };
        })
      : [];
  };

  private getProfilesLinkedToReservationData = () => {
    const { profilesLinkedToReservation, t } = this.props;

    return profilesLinkedToReservation
      ? profilesLinkedToReservation.flatMap(
          (linkedProfile: LinkedProfileViews) => {
            const {
              communicationChannels,
              roleCode: { description },
            } = linkedProfile;
            const booker = this.getBookers(linkedProfile.id);
            const profileDescription = booker?.id.includes(linkedProfile.id)
              ? t('BOOKER')
              : description;
            const details = this.getDetails(linkedProfile);
            const emailChannel = communicationChannels?.filter(
              (channel) =>
                channel.mode === CommunicationMode.Email && channel.details
            );

            return emailChannel
              ? emailChannel.map((email) => {
                  return {
                    details,
                    description: profileDescription,
                    email: email.details,
                  };
                })
              : [];
          }
        )
      : [];
  };

  private getFolioProfilesEmails = () => {
    const { foliosWithCommunicationChannels, currentFolioNumber } = this.props;
    const description = this.getDescriptionByFolioType() || '';

    const folioWithCommunicationChannels =
      foliosWithCommunicationChannels[currentFolioNumber];
    const communicationChannels = folioWithCommunicationChannels
      ? folioWithCommunicationChannels.communicationChannels
      : [];
    const emails = communicationChannels.filter(
      (item) => item.mode === CommunicationMode.Email
    );

    return emails.map((email) => ({
      description,
      details: folioWithCommunicationChannels?.profileName,
      email: email.details,
    }));
  };

  private getProfilesData = () => {
    const profiles = [
      ...this.getGuestProfileData(),
      ...this.getProfilesLinkedToReservationData(),
      ...this.getFolioProfilesEmails(),
    ];
    const uniqueProfiles = profiles.filter(
      (elem, index, arr) =>
        arr.findIndex((item) => item.email === elem.email) === index
    );

    return uniqueProfiles.filter((profile) => profile.email);
  };

  private getBookers = (linkedProfileId: string) => {
    const { profilesLinkedToReservation } = this.props;
    const contactProfiles = profilesLinkedToReservation.filter(
      (profile: LinkedProfileViews) => {
        return (
          profile.contactProfiles?.length &&
          profile.contactProfiles.map((contactProfile: ContactProfile) => {
            return (
              contactProfile.contactProfileId === profile.id &&
              contactProfile.roles.includes('BOOKER')
            );
          })
        );
      }
    );

    return contactProfiles?.find(
      (contactProfile: LinkedProfileViews) =>
        contactProfile.id === linkedProfileId
    );
  };

  private getDetails = (linkedProfile: LinkedProfileViews) => {
    const { code } = linkedProfile.roleCode;
    switch (code) {
      case 'CMP':
        return linkedProfile.companyDetails!.fullName;
      case 'TA':
        return linkedProfile.travelAgentDetails!.fullName;
      case 'IND':
        return `${linkedProfile.individualDetails!.firstName} ${
          linkedProfile.individualDetails!.lastName
        }`;
      default:
        return '';
    }
  };

  private shouldConfirmBeDisabled = () => {
    const { emailsToSendInvoice, formValues } = this.props;
    if (formValues) {
      const { emails, addEmail, newEmail } = formValues;
      !emails?.length
        ? this.setState({ emailsGroupDisabled: true })
        : this.setState({ emailsGroupDisabled: false });
      !addEmail || (addEmail && !newEmail)
        ? this.setState({ addEmailDisabled: true })
        : this.setState({
            addEmailDisabled: false,
            isNewEmailInputDisabled: false,
          });
    }
    const { emailsGroupDisabled, addEmailDisabled } = this.state;

    return (
      !emailsToSendInvoice.length || (emailsGroupDisabled && addEmailDisabled)
    );
  };

  private initializeForm = () => {
    const { onInitialize, displayedEmail } = this.props;
    const data = { emails: [displayedEmail] };
    onInitialize(ids.EDIT_EMAIL_FORM, data);
  };
}

const mapStateToProps = (state: Store) => ({
  emailsToSendInvoice: getEmailToSendInvoice(state),
  profile: getProfile(state),
  guestEmail: getEmail(state),
  wasEditEmailFormInitialized: getWasEditEmailFormInitialized(state),
  formValues: getFormValues(ids.EDIT_EMAIL_FORM)(state),
  profilesLinkedToReservation: getProfilesViews(state),
  folios: getDetailedFolios(state),
  foliosWithCommunicationChannels: getFoliosWithCommunicationChannels(state),
});

const mapDispatchToProps = {
  saveEmailToSendInvoice,
  onInitialize: initialize,
  checkEditEmailFormInitialization,
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: ids.EDIT_EMAIL_FORM,
    destroyOnUnmount: false,
  })
)(EditEmailForm) as (props: PassedProps) => JSX.Element;
