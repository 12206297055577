import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FormHeader.style';

interface FormHeaderProps extends WithStyles<typeof styles> {
  title?: string;
  subtitle?: string;
  formName?: string | JSX.Element;
  isFormValid?: boolean;
  id?: string;
  className?: string;
  subtitleClass?: string;
}

class FormHeader extends PureComponent<FormHeaderProps> {
  public static defaultProps = {
    title: '',
    subtitle: '',
    formName: '',
    isFormValid: false,
    subtitleClass: '',
  };

  public render() {
    const {
      classes,
      title,
      subtitle,
      formName,
      isFormValid,
      id,
      className,
      subtitleClass,
    } = this.props;

    return (
      <div id={id} className={className}>
        {!!title && <Typography className={classes.title}>{title}</Typography>}
        {!!subtitle && (
          <Typography className={classNames(classes.subtitle, subtitleClass)}>
            {subtitle}
          </Typography>
        )}
        {!!formName && (
          <div className={classes.formNameWrapper}>
            {isFormValid ? (
              <Icon
                type={IconTypes.checkFilled}
                className={classNames(classes.icon, classes.valid)}
              />
            ) : (
              <Icon
                type={IconTypes.cancelFilled}
                className={classNames(classes.icon, classes.invalid)}
              />
            )}
            <Typography className={classes.formName}>{formName}</Typography>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(FormHeader);
