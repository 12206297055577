import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const WRAPPER_WIDTH = 474;
const WRAPPER_MIN_HEIGHT = 266;
const WRAPPER_RADIUS = 6;
const PRE_ADDED_BUTTON_HEIGHT = 38;
const PRE_ADDED_BUTTON_TOP = 232;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      position: 'absolute',
      outline: 'none',
      width: WRAPPER_WIDTH,
      minHeight: WRAPPER_MIN_HEIGHT,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: WRAPPER_RADIUS,
      overflow: 'hidden',
      background: palette.common.white,
    },
    cover: {
      height: WRAPPER_MIN_HEIGHT,
    },
    content: {
      boxSizing: 'border-box',
      padding: spacing(2),
      backgroundColor: palette.common.white,
    },
    body: {
      display: 'flex',
    },
    bottomPanel: {
      marginTop: spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    preAddedBottomPanel: {
      marginTop: spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
    },
    descriptionWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontSize: '1.25rem',
      marginBottom: spacing(1),
    },
    priceWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    description: {
      fontSize: '0.875rem',
      marginRight: spacing(6),
    },
    price: {
      fontSize: '1.25rem',
      textAlign: 'right',
    },
    pricing: {
      textAlign: 'right',
      fontSize: '0.8125rem',
      color: palette.text.disabled,
    },
    modal: {
      outline: 'none',
    },
    preAddedButton: {
      top: PRE_ADDED_BUTTON_TOP,
      minWidth: WRAPPER_WIDTH,
      borderTopRightRadius: WRAPPER_RADIUS,
      borderTopLeftRadius: WRAPPER_RADIUS,
      height: PRE_ADDED_BUTTON_HEIGHT,
    },
  });
