import React from 'react';
import classNames from 'classnames';
import { ListRow, RowItem } from 'components/List';

import { WithStyles, withStyles } from '@material-ui/styles';

import { listHeaderStyle } from './Header.style';

interface ListHeaderProps extends WithStyles<typeof listHeaderStyle> {
  headers: string[];
  rowClassName?: string;
  cellClassName?: string;
  textClassName?: string;
}

const ListHeader = ({
  headers,
  classes,
  rowClassName,
  cellClassName,
  textClassName,
}: ListHeaderProps) => (
  <ListRow className={classNames(classes.header, rowClassName)}>
    {headers.map((headerText, index) => (
      <RowItem
        key={`${headerText}-#${index}`}
        textClassName={classNames(classes.text, textClassName)}
        wrapperClassName={cellClassName}
      >
        {headerText}
      </RowItem>
    ))}
  </ListRow>
);

export default withStyles(listHeaderStyle)(ListHeader);
