import { PureComponent } from 'react';

interface Error {
  code: string;
  message: string;
}

interface KioskProPhotoCaptureProps {
  onNewPhotoAdd: (photo: string) => any;
  onError?: (error: Error) => any;
  onCancel?: () => any;
}

const fileName = 'taken-user-photo';

const kioskProGlobalCallbacks = {
  onTakingPhotoFinish: 'acKioskPro_onTakingPhotoFinish',
  onConvertingPhotoToBase64Finish: 'acKioskPro_onConvertingPhotoToBase64Finish',
};

export class KioskProPhotoCapture extends PureComponent<KioskProPhotoCaptureProps> {
  public componentDidMount() {
    this.assignCallbacks();
    this.takePhoto();
  }

  public componentWillUnmount() {
    this.cleanUpCallbacks();
  }

  public render() {
    return null;
  }

  private takePhoto = () => {
    (window as any).takePhotoToFile(
      fileName,
      kioskProGlobalCallbacks.onTakingPhotoFinish
    );
  };

  private cleanUpCallbacks = () => {
    const global = window as any;
    global[kioskProGlobalCallbacks.onTakingPhotoFinish] = undefined;
    global[kioskProGlobalCallbacks.onConvertingPhotoToBase64Finish] = undefined;
  };

  private assignCallbacks = () => {
    const global = window as any;
    global[kioskProGlobalCallbacks.onTakingPhotoFinish] =
      this.onTakingPictureFinish;
    global[kioskProGlobalCallbacks.onConvertingPhotoToBase64Finish] =
      this.onConertToBase64Finish;
  };

  private onTakingPictureFinish = (success: boolean) => {
    if (success) {
      this.convertToBase64();
    } else if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  private convertToBase64 = () => {
    const fullFileName = `${fileName}.png`;
    (window as any).kp_FileAPI_base64FromFile(
      fullFileName,
      kioskProGlobalCallbacks.onConvertingPhotoToBase64Finish
    );
  };

  private onConertToBase64Finish = (
    photo: string | null,
    error: null | KioskProConvertingToBase64Error
  ) => {
    const { onError, onNewPhotoAdd } = this.props;
    if (error && onError) {
      onError({ ...error, message: error.description });
    }

    if (photo) {
      const photoFullSrc = `data:image/png;base64,${photo}`;
      onNewPhotoAdd(photoFullSrc);
    }
  };
}

interface KioskProConvertingToBase64Error {
  domain: string;
  code: string;
  description: string;
}
