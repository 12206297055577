import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Icon, IconTypes, Loader } from '@ac/kiosk-components';

import { Button, Grid, MobileStepper, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './PendingDetailed.style';

interface PassedProps {
  keysNumber: number;
  currentKey?: number;
}

interface PendingDetailedProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class PendingDetailed extends PureComponent<PendingDetailedProps> {
  public render() {
    const { classes, t, keysNumber, currentKey = 0 } = this.props;

    return (
      <Grid className={classes.content}>
        <Grid className={classes.iconWrapper}>
          <Loader icon={IconTypes.key} />
        </Grid>
        <Typography className={classes.title}>
          {t('KEYS_BEING_PREPARED')}
        </Typography>
        <Grid className={classes.stepsWrapper}>{this.renderSteps()}</Grid>
        <MobileStepper
          variant="dots"
          steps={keysNumber}
          position="static"
          activeStep={currentKey - 1}
          nextButton={<Button className={classes.hidden}>{null}</Button>}
          backButton={<Button className={classes.hidden}>{null}</Button>}
          className={classes.stepper}
        />
      </Grid>
    );
  }

  private renderSteps = () => {
    const { currentKey = 0, keysNumber, t, classes } = this.props;

    return Array(keysNumber)
      .fill('')
      .map((item, index) => {
        let titleKey = 'DONE';
        if (currentKey === index + 1) titleKey = 'IN_PROGRESS';
        if (currentKey < index + 1) titleKey = 'WAITING';

        return (
          <Grid key={`cutting-key-step-${index + 1}`} container>
            <Grid item xs={5} className={classes.cell}>
              <Typography>{t('KEY', { count: index + 1 })}</Typography>
            </Grid>
            <Grid item xs={7} className={classes.cell}>
              {currentKey > index + 1 && (
                <Icon type={IconTypes.checkFilled} className={classes.icon} />
              )}
              <Typography>{t(titleKey)}</Typography>
            </Grid>
          </Grid>
        );
      });
  };
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(PendingDetailed) as (props: PassedProps) => JSX.Element;
