import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { compose } from 'redux';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CounterItem.style';

interface PassedProps {
  className?: string;
  width?: string;
  isActive?: boolean;
}

interface CounterItemProps extends PassedProps, WithStyles<typeof styles> {}

class CounterItem extends PureComponent<CounterItemProps> {
  public static defaultProps = {
    className: '',
    isActive: false,
    width: '10%',
  };

  public render() {
    const { classes, className, width, isActive, ...props } = this.props;
    const fillClass = isActive ? classes.fillActive : classes.fill;

    return (
      <div
        className={classNames(classes.wrapper, className)}
        style={{ width }}
        {...props}
      >
        <div className={fillClass} />
      </div>
    );
  }
}

export default compose(withStyles(styles))(CounterItem) as (
  props: PassedProps
) => JSX.Element;
