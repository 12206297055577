import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  Button,
  Circular,
  CircularType,
  IconTypes,
} from '@ac/kiosk-components';

import { Grid, Modal, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './PreAddedModal.style';

interface PreAddedModalProps
  extends WithTranslation,
    WithStyles<typeof styles> {
  isOpen: boolean;
  onModalClose: () => void;
  isElementFromRate: boolean;
}

class PreAddedModal extends PureComponent<PreAddedModalProps> {
  public static defaultProps = {
    isOpen: true,
  };

  public render() {
    const { classes, t, isOpen, onModalClose, isElementFromRate } = this.props;

    return (
      <Modal
        open={isOpen}
        onBackdropClick={onModalClose}
        className={classNames(
          classes.modal,
          'with-default-kiosk-components-theme'
        )}
        BackdropProps={{
          classes: { root: classes.backdrop },
        }}
      >
        <Grid className={classes.wrapper}>
          <Grid className={classes.content}>
            <Circular
              icon={IconTypes.exclamationMark}
              iconClassName={classes.icon}
              className={classes.circular}
              type={CircularType.warning}
            />
            <Typography className={classes.modalInfo}>
              {isElementFromRate
                ? t('PE_INCLUDED_IN_RATE_PLAN')
                : t('PE_INCLUDED_IN_RESERVATION')}
            </Typography>
            <Button onClick={onModalClose} className={classes.button}>
              {t('CLOSE')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

export default withStyles(styles)(withTranslation()(PreAddedModal));
