import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List, ListHeader, ListRow, RowItem } from 'components/List';
import { compose } from 'redux';
import { ReservationListDataModel } from 'types/Api/Reservation';

import { Button } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import { listConfig } from './listConfig';
import styles from './ReservationList.style';

interface PassedProps {
  data: ReservationListDataModel[];
  selectionDisabled?: boolean;
  onItemClick: (reservationId: string) => void;
}

export interface ReservationListProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class ReservationList extends Component<ReservationListProps> {
  public static defaultProps = {
    data: [],
    selectionDisabled: false,
  };

  private headers: string[];

  constructor(props: ReservationListProps) {
    super(props);

    const { t } = props;
    this.headers = [
      t('NAME'),
      t('LAST_NAME'),
      t('CONFIRMATION_NUMBER'),
      t('ARRIVAL'),
      t('DEPARTURE'),
      t('ROOM_NUMBER'),
      '',
    ];
  }

  public shouldComponentUpdate(nextProps: ReservationListProps) {
    const { selectionDisabled } = this.props;

    return !(
      selectionDisabled && selectionDisabled === nextProps.selectionDisabled
    );
  }

  public render() {
    const { t, classes, onItemClick, data, selectionDisabled } = this.props;

    return (
      <div>
        <List listConfig={listConfig}>
          <ListHeader headers={this.headers} />
          {data.map(
            ({
              firstName,
              lastName,
              confirmationNumber,
              arrivalDate,
              departureDate,
              roomNumber,
              id,
            }) => (
              <ListRow key={`reservations-${confirmationNumber}`}>
                <RowItem>{firstName}</RowItem>
                <RowItem>{lastName}</RowItem>
                <RowItem>{confirmationNumber}</RowItem>
                <RowItem>{arrivalDate}</RowItem>
                <RowItem>{departureDate}</RowItem>
                <RowItem>{roomNumber}</RowItem>
                <RowItem>
                  <Button
                    onClick={() => onItemClick(id!)}
                    className={classes.selectButton}
                    disabled={selectionDisabled}
                  >
                    {t('SELECT')}
                  </Button>
                </RowItem>
              </ListRow>
            )
          )}
        </List>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(ReservationList) as (props: PassedProps) => JSX.Element;
