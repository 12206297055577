import { createAction } from '@ac/library-utils/dist/redux-utils';

const ACTION_PREFIX = '@global/';

export const clearAllGlobalErrors = createAction(
  `${ACTION_PREFIX}clearAllGlobalErrors`
);

export const resetGlobalSetupData = createAction(
  `${ACTION_PREFIX}resetGlobalSetupData`
);
