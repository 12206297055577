import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export const listHeaderStyle = ({ spacing }: Theme) =>
  createStyles({
    header: {
      'li&': {
        backgroundColor: 'transparent',
        marginBottom: spacing(0),
      },
    },
    text: {
      fontWeight: 600,
    },
  });
