import { createSelector } from 'reselect';
import { isFetching as isFileFetching } from 'store/files/selectors';
import {
  getProfile,
  isFetching as isProfileFetching,
} from 'store/profile/selectors';
import Store from 'types/Store';

import { KioskCheckInUpdateProfile } from '@gss/api/KioskApi/entries';

import { DocumentsFormValues } from '../DocumentsForm';

export const getIdPhotos = (state: Store) => state.checkInId.idPhotos;

export const getIdPhotosNumber = createSelector(
  getIdPhotos,
  (photos) => photos.length
);

export const isSubmitting = createSelector(
  isProfileFetching,
  isFileFetching,
  (isSubmittingProfile, isUploadingFile) =>
    isSubmittingProfile || isUploadingFile
);

export const getCachedDocumentFormData = (
  state: Store
): DocumentsFormValues | undefined => state.checkInId.cachedDocumentFormData;

export const getCachedDocumentId = (state: Store): string | undefined =>
  state.checkInId.cachedDocumentId;

export const getIdOfPreviouslyAddedDocument = createSelector(
  [getProfile, getCachedDocumentId],
  (profile, cachedDocumentId): string | undefined => {
    return profile?.identificationDocuments?.find(
      (document) => document.identificationDocumentId === cachedDocumentId
    )?.identificationDocumentId;
  }
);

export const getDocumentUpdatePayload = (
  state: Store
):
  | Pick<KioskCheckInUpdateProfile, 'identityDocuments' | 'personalDetails'>
  | undefined => state.checkInId.documentUpdatePayload;
