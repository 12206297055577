import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal } from 'components';

import {
  Button,
  ButtonPattern,
  Circular,
  CircularType,
  IconTypes,
} from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './SkipModal.style';

interface SkipModalProps extends WithTranslation, WithStyles<typeof styles> {
  onContinue: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

class SkipModal extends PureComponent<SkipModalProps> {
  public render() {
    const { t, classes, isOpen, onContinue, onCancel } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onClick={onCancel}
        size="wide"
        className="with-default-kiosk-components-theme"
      >
        <Grid className={classes.wrapper}>
          <Grid className="spacing-bottom-md">
            <Circular
              icon={IconTypes.exclamationMark}
              iconClassName={classes.alertIcon}
              type={CircularType.warning}
            />
          </Grid>
          <Grid className={classes.content}>
            <Typography className={classes.title}>
              {t('SKIP_ADDONS_DESCRIPTION')}
            </Typography>
          </Grid>
          <Grid className={classes.footer}>
            <Button pattern={ButtonPattern.secondary} onClick={onCancel}>
              {t('CANCEL')}
            </Button>
            <Button onClick={onContinue}>
              {`${t('YES')}, ${t('CONTINUE')}`}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

export default withStyles(styles)(withTranslation()(SkipModal));
