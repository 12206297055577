import { getTheme } from 'config';
import { AnyAction } from 'redux';
import { State } from 'types/Store/UiStore';
import { Configurator } from 'utils';

import types from './types';

const { SCREEN_SAVER_MESSAGE } = Configurator.getTranslationCodes();

const initialState: State = {
  isReservationHeaderExtended: Configurator.getSwitch(
    Configurator.switchCodes.SHOW_EXPAND_FOR_RESERVATION_HEADER
  ),
  isIPad: false,
  isDayUseModalOpen: false,
  theme: getTheme(),
  screenSaverMessages: Configurator.getTranslations(SCREEN_SAVER_MESSAGE),
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.REFRESH_THEME:
      return {
        ...state,
        theme: getTheme(),
      };

    case types.TOGGLE_RESERVATION_HEADER:
      return {
        ...state,
        isReservationHeaderExtended: !state.isReservationHeaderExtended,
      };

    case types.REFRESH_UI:
      return {
        ...state,
        screenSaverMessages: Configurator.getTranslations(SCREEN_SAVER_MESSAGE),
        isReservationHeaderExtended: Configurator.getSwitch(
          Configurator.switchCodes.SHOW_EXPAND_FOR_RESERVATION_HEADER
        ),
      };

    case types.SET_IS_IPAD:
      return {
        ...state,
        isIPad: true,
      };

    case types.TOGGLE_DAY_USE_MODAL:
      return {
        ...state,
        isDayUseModalOpen: !state.isDayUseModalOpen,
      };

    default:
      return state;
  }
};
