import { api } from 'config';
import { fetchPaymentMethods } from 'store/cashiering/actions';
import { activeCardAuthorizations } from 'store/cashiering/authorization/selectors';
import { CALL_API } from 'store/middleware/api';
import { Dispatch, getApiCallActionTypes, GetState } from 'store/utils/actions';

import {
  FETCH_ACTIVE_AUTHORIZATION,
  FETCH_ACTIVE_AUTHORIZATIONS,
  FETCH_ACTIVE_GIVEN_AUTHORIZATIONS,
} from './types';

export const fetchActiveAuthorizations =
  () => async (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: FETCH_ACTIVE_AUTHORIZATIONS.REQUEST });

    await dispatch(fetchPaymentMethods());

    const authorizationIds = activeCardAuthorizations(getState()).map(
      (authorization) => authorization.id
    );

    const response = await dispatch(
      fetchActiveGivenAuthorizations(authorizationIds)
    );

    dispatch({ response, type: FETCH_ACTIVE_AUTHORIZATIONS.FINISHED });
  };

const fetchActiveGivenAuthorizations =
  (authorizationIds: string[]) => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ACTIVE_GIVEN_AUTHORIZATIONS.REQUEST });

    const result = await Promise.all(
      authorizationIds.map((authorizationId) =>
        dispatch(fetchActiveAuthorization(authorizationId))
      )
    );
    dispatch({
      type: FETCH_ACTIVE_GIVEN_AUTHORIZATIONS.FINISHED,
      response: result,
    });

    return result;
  };

const fetchActiveAuthorization =
  (authorizationId: string) => (dispatch: Dispatch) =>
    dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.AUTHORIZATION(authorizationId),
        types: getApiCallActionTypes(FETCH_ACTIVE_AUTHORIZATION),
      },
    });
