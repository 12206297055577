import { api } from 'config';
import { CALL_API } from 'store/middleware/api';
import { getReservation } from 'store/reservation/selectors';
import { Dispatch, getApiCallActionTypes, GetState } from 'store/utils/actions';

import { FETCH_RESERVATION_OUTSTANDING_DEPOSIT } from './types';

export const fetchReservationOutstandingDeposit =
  () => (dispatch: Dispatch, getState: GetState) => {
    const reservationId = getReservation(getState()).id;

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.RESERVATION_OUTSTANDING_DEPOSIT(reservationId),
        types: getApiCallActionTypes(FETCH_RESERVATION_OUTSTANDING_DEPOSIT),
      },
    });
  };
