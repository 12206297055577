import { ImagesSettingsState } from '../interfaces/settings/imagesSettings';

export const revokeImageObjectUrls = (
  images: ImagesSettingsState | undefined
): void => {
  if (!images) return;

  Object.values(images)
    .filter((item) => typeof item === 'string')
    .map((url) => URL.revokeObjectURL(url));
};
