import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_WIDTH = 16;
const ICON_HEIGHT = 16;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    errorContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    errorIcon: {
      width: ICON_WIDTH,
      height: ICON_HEIGHT,
      marginRight: spacing(0.75),
      fill: palette.error.main,
    },
    errorText: {
      color: palette.error.main,
    },
  });
