import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FormErrorMessage, FormTextField } from 'components';
import { compose } from 'redux';

import { IconTypes } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import MinibarMenuItem from '../MinibarMenuItem';

import styles from './MinibarMenu.style';

export interface PassedProps {
  onItemClick: (title: string) => void;
  items: Array<{
    title: string;
    price: number;
    id: string;
  }>;
  orderedItems: {
    [key: string]: number;
  };
}

interface MinibarMenuProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

interface MinibarMenuState {
  searchValue: string;
}

class MinibarMenu extends PureComponent<MinibarMenuProps, MinibarMenuState> {
  public static defaultProps = {
    orderedItems: {},
  };

  public state = {
    searchValue: '',
  };

  public render() {
    const { classes, onItemClick, t, items, orderedItems } = this.props;
    const { searchValue } = this.state;
    const filteredItems = items.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase().trim())
    );
    const isError = !filteredItems.length;

    return (
      <Grid container item xs={12} sm={12} md={6} className={classes.wrapper}>
        <Typography className={classes.title}>{t('MINIBAR')}</Typography>
        <Grid className={classes.searchWrapper}>
          <FormTextField
            className={isError ? classes.searchBoxError : classes.searchBox}
            onChange={this.onSearch}
            icon={IconTypes.search}
            placeholder={t('SEARCH_ITEMS')}
            isError={isError}
            value={searchValue}
          />
          {isError && <FormErrorMessage error={t('NO_RESULTS')} />}
        </Grid>
        <Grid spacing={2} container>
          {filteredItems.map((item) => (
            <MinibarMenuItem
              onClick={onItemClick}
              key={`minibar-item-${item.title}`}
              itemData={item}
              isOrdered={Boolean(orderedItems[item.id])}
            />
          ))}
        </Grid>
      </Grid>
    );
  }

  private onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ searchValue: value });
  };
}

export default compose(withTranslation(), withStyles(styles))(MinibarMenu) as (
  props: PassedProps
) => JSX.Element;
