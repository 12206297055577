import { SelectOption } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { BaseObject } from '@gss/types/shared';

export const mapSelectOptions = <
  InputType extends BaseObject,
  ValueKey extends keyof InputType
>(
  input: InputType[] | undefined,
  title: keyof InputType = 'name',
  value: ValueKey = 'code' as ValueKey
): Array<SelectOption<NonNullable<InputType[ValueKey]>>> => {
  return (input || [])
    .filter((data) => isDefined(data[title]) && isDefined(data[value]))
    .map((data) => ({
      title: data[title],
      value: data[value],
      hidden: 'isActive' in data ? !data.isActive : false,
    }));
};
