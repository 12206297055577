import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Router } from 'utils';
import { Path } from 'utils/Router';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './PriceSection.style';

interface PriceSectionProps extends WithStyles<typeof styles> {
  price: string;
  text: string;
  classNames?: {
    text?: string;
    price?: string;
    wrapper?: string;
  };
  isExpandable?: boolean;
}

class PriceSection extends PureComponent<PriceSectionProps> {
  public static defaultProps = {
    price: '',
    text: '',
    classNames: {},
    isExpandable: true,
  };

  public render() {
    const {
      classes,
      price,
      text,
      isExpandable,
      classNames: passedClassNames,
    } = this.props;
    const isCheckOut = Router.currentPath === Path.checkOut;
    const priceWrapperClass = isExpandable
      ? classes.priceWrapperExpandable
      : classes.priceWrapper;
    const wrapperBaseClass = isCheckOut
      ? classes.priceWrapperCheckOut
      : priceWrapperClass;

    return (
      <Grid className={classNames(wrapperBaseClass, passedClassNames?.wrapper)}>
        {text && (
          <Typography
            className={classNames(passedClassNames?.text, classes.priceText)}
          >
            {text}
          </Typography>
        )}
        {price && (
          <Typography
            className={classNames(passedClassNames?.price, classes.priceAmount)}
          >
            {price}
          </Typography>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PriceSection);
