import { ListConfig } from 'components/List/types';

export const listConfig: ListConfig = {
  cellSpacing: 3,
  fontSize: '0.9rem',
  columns: [
    { xs: 2, md: 3, lg: 4, xl: 6 },
    { container: true, justify: 'flex-end', xs: 2, lg: 2 },
    { xs: 3, xl: 2 },
    { container: true, justify: 'flex-end', xs: 2, lg: 1 },
    { container: true, justify: 'flex-end', xs: 3, md: 2, xl: 1 },
  ],
};
