import { ApiError } from '@ac/library-api';

import { Store } from '@gss/store';

export const getBackgroundTasksErrors = (
  state: Store
): Array<ApiError | Error> => state.backgroundTasks.errors;

export const getPendingRefreshConfigurationState = (state: Store): boolean =>
  state.backgroundTasks.fetching.pendingRefreshConfiguration;
