export default function getLocalStorageSize() {
  let total = 0;
  for (const key in localStorage) {
    // eslint-disable-next-line no-prototype-builtins
    if (window.localStorage.hasOwnProperty(key)) {
      const utf16EncodeSize = window.localStorage[key].length * 2;
      const megaByteSize = utf16EncodeSize / 1024 / 1024;
      if (!isNaN(megaByteSize)) {
        total += megaByteSize;
      }
    }
  }

  return `${total.toFixed(2)} MB`;
}
