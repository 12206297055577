import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 22;

export const ICON_WRAPPER_SIZE = 42;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    title: {
      fontSize: '0.875rem',
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    iconWrapper: {
      position: 'relative',
      width: ICON_WRAPPER_SIZE,
      height: ICON_WRAPPER_SIZE,
      marginRight: spacing(2),
    },
    icon: {
      color: 'var(--primary-color)',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: spacing(3),
    },
  });
