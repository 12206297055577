import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import Big from 'big.js';
import { QuantityFormField } from 'components';

import { isDefined } from '@ac/library-utils/dist/utils';

import { Grid, IconButton, Typography } from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons/';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './QuantityPanel.style';

interface QuantityPanelProps
  extends WithTranslation,
    WithStyles<typeof styles> {
  quantity: number;
  minQuantity?: number;
  maxQuantity?: number;
  price: number;
  input?: any;
  onAdd: (itemName: string) => void;
  onRemove: (itemName: string) => void;
}

interface QuantityPanelState {}

class QuantityPanel extends PureComponent<
  QuantityPanelProps,
  QuantityPanelState
> {
  public render() {
    const {
      classes,
      t,
      quantity,
      price,
      input,
      minQuantity = 0,
      maxQuantity,
    } = this.props;

    const isRemoveDisabled = !quantity || quantity <= minQuantity;
    const isIncreaseDisabled = isDefined(maxQuantity)
      ? quantity >= maxQuantity
      : false;
    const totalPrice = new Big(price).times(quantity || 0).toFixed(2);

    return (
      <Grid container className={classes.wrapper}>
        <Grid
          item
          container
          className={classes.contentWrapper}
          xs={6}
          justify="flex-start"
          alignItems="center"
        >
          <Typography className={classes.subtitle}>
            {t('QUANTITY_TITLE')}
          </Typography>
          <Grid className={classes.inputWrapper}>
            <IconButton
              disabled={isRemoveDisabled}
              className={classes.iconButton}
              onClick={this.handleRemoveClick}
            >
              <RemoveIcon
                className={
                  isRemoveDisabled ? classes.iconDisabled : classes.icon
                }
                color="primary"
              />
            </IconButton>
            <QuantityFormField
              readOnly
              type="number"
              input={input}
              value={quantity}
            />
            <IconButton
              disabled={isIncreaseDisabled}
              className={classes.iconButton}
              onClick={this.handleAddClick}
            >
              <AddIcon
                className={
                  isIncreaseDisabled ? classes.iconDisabled : classes.icon
                }
                color="primary"
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          container
          className={classes.contentWrapper}
          xs={6}
          justify="flex-end"
          alignItems="center"
        >
          <Typography className={classes.subtitle}>
            {t('TOTAL_PRICE')}
          </Typography>
          <Typography className={classes.title}>{totalPrice}</Typography>
        </Grid>
      </Grid>
    );
  }

  private handleRemoveClick = () => {
    const {
      onRemove,
      input: { name },
    } = this.props;
    onRemove(name);
  };

  private handleAddClick = () => {
    const {
      onAdd,
      input: { name },
    } = this.props;
    onAdd(name);
  };
}

export default withStyles(styles)(withTranslation()(QuantityPanel));
