import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FormHeader, Text, TextSize, TextWeight } from '@ac/kiosk-components';
import { DeviceType } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import { SelfServiceDeviceDto } from '@gss/api/KioskApi/entries';

export enum LinkedDevicesType {
  extended = 'extended',
  short = 'short',
}

interface LinkedDevicesProps {
  mainDevice: SelfServiceDeviceDto;
  type?: LinkedDevicesType;
}

export const LinkedDevices = ({
  mainDevice,
  type = LinkedDevicesType.extended,
}: LinkedDevicesProps): JSX.Element => {
  const { t } = useTranslation();

  const isShortVersion = type === LinkedDevicesType.short;

  const linkedDevicesNames = useMemo((): string[] | undefined => {
    if (!mainDevice.workstationDevices) return undefined;

    return mainDevice.workstationDevices
      .filter((device) => device.type?.code !== DeviceType.GuestSelfService)
      .map((device) =>
        [device.code, device.name].filter(isDefined).join(' - ')
      );
  }, [mainDevice.workstationDevices]);

  return (
    <>
      {isShortVersion ? (
        <Text dataTestSelector="short-version-header" size={TextSize.md}>
          {t('COMPONENTS.LINKED_DEVICES.TITLE')}
        </Text>
      ) : (
        <FormHeader
          dataTestSelector="extended-version-header"
          className="spacing-top-xlg"
          titleWeight={TextWeight.regular}
          title={t('COMPONENTS.LINKED_DEVICES.TITLE')}
          hint={t('COMPONENTS.LINKED_DEVICES.HINT')}
        />
      )}

      <ul className="spacing-none padding-lg padding-left-xlg">
        {linkedDevicesNames?.map((device, index) => (
          <li
            data-test-selector="linked-device-item"
            className="spacing-bottom-sm"
            key={index}
          >
            <Text size={TextSize.md}>{device}</Text>
          </li>
        ))}
      </ul>
    </>
  );
};
