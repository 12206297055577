import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { getDisplayedFolioCount } from 'store/cashiering/selectors';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { getCurrentFolioNumber } from 'views/CheckOutCharges/store/selectors';

import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './Counter.style';
import CounterItem from './CounterItem';

interface PassedProps {
  className?: string;
}

interface CounterProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  displayedFolioCount: number;
  currentFolioNumber: number;
}

class Counter extends PureComponent<CounterProps> {
  public static defaultProps = {
    className: '',
  };

  public render() {
    const { classes, className, t } = this.props;

    return (
      <div className={classes.wrapper}>
        {this.currentIndex ? (
          <div className={classes.stepCount}>
            <Typography className={classes.title}>{t('STEPS')}</Typography>
            <Typography className={classes.subtitle}>
              {`${this.currentIndex + 1}/${this.counterItemAmount}`}
            </Typography>
          </div>
        ) : null}
        <div className={classNames(classes.fillWrapper, className)}>
          {[...new Array(this.counterItemAmount)].map((item, index) => (
            <CounterItem
              key={`counter-${index}`}
              isActive={index <= this.currentIndex}
              width={this.counterItemWidth}
            />
          ))}
        </div>
      </div>
    );
  }

  private get counterItemAmount() {
    const { displayedFolioCount } = this.props;
    const { SHOW_PRE_AUTHORIZATION, SHOW_PREPAYMENT, SHOW_ADDONS } =
      Configurator.switchCodes;
    const shouldDecrementStepCount =
      Configurator.getSwitch(SHOW_PRE_AUTHORIZATION) &&
      Configurator.getSwitch(SHOW_PREPAYMENT) &&
      Configurator.getSwitch(SHOW_ADDONS);
    const routerStepCount = Router.counterSteps.length;
    const baseStepsAmount =
      shouldDecrementStepCount && routerStepCount > 0
        ? routerStepCount - 1
        : routerStepCount;

    return displayedFolioCount > 0
      ? baseStepsAmount + displayedFolioCount - 1
      : baseStepsAmount;
  }

  private get currentIndex() {
    const { currentFolioNumber } = this.props;
    const currentBaseIndex = Router.currentCounterStepIndex;

    return currentBaseIndex + currentFolioNumber;
  }

  private get counterItemWidth() {
    return `${100 / this.counterItemAmount}%`;
  }
}

const mapStateToProps = (state: Store) => ({
  displayedFolioCount: getDisplayedFolioCount(state),
  currentFolioNumber: getCurrentFolioNumber(state),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(Counter) as (props: PassedProps) => JSX.Element;
