import { createSelector } from 'reselect';
import Store from 'types/Store/RoomStore';
import { Configurator } from 'utils';

export const getRoomDetails = (state: Store) => state.room.details;

export const getRoomErrors = (state: Store) => state.room.errors;

export const getRoomId = (state: Store) => getRoomDetails(state).id;

export const getRoomType = (state: Store) => state.room.roomType;

export const getRoomNumber = (state: Store) => getRoomDetails(state).code;

export const getRoomTypeId = (state: Store) => getRoomType(state).id;

export const getRoomList = (state: Store) => state.room.roomList;

export const getIsDataLoading = (state: Store) =>
  Object.values(state.room.dataFetching).includes(true);

export const getRoomTypeName = createSelector(getRoomType, (roomType) =>
  Configurator.getDescription(roomType.name)
);

export const getRoomDescription = createSelector(getRoomType, (roomType) =>
  Configurator.getDescription(roomType.description)
);

export const getRoomTypeAttributes = (state: Store) =>
  getRoomType(state).attributeIds;

export const getRoomLocation = createSelector(getRoomDetails, (details) => {
  const roomLocation = Configurator.roomLocations.find(
    (item) => item.id === details.roomLocationId
  );

  return roomLocation
    ? Configurator.getDescription(roomLocation.description)
    : '';
});

export const getRoomFloor = createSelector(getRoomDetails, (details) => {
  const floor = Configurator.propertyFloors.find(
    (item) => item.id === details.floorId
  );

  return floor ? Configurator.getDescription(floor.description) : '';
});
