import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingView } from '@ac/kiosk-components';

import { logoutApplication } from '@gss/store/configuration/actions';
import { getLoggingOutState } from '@gss/store/configuration/selectors';

export const Logout = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoggingOutInProgress = useSelector(getLoggingOutState);

  useEffect(() => {
    if (!isLoggingOutInProgress) {
      dispatch(logoutApplication());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggingOutInProgress]);

  return (
    <LoadingView
      className="with-default-kiosk-components-theme"
      description={t('VIEWS.LOGOUT.LOGGING_OUT')}
    />
  );
};
