const FETCH_AVAILABLE_ROOMS_REQUEST = 'FETCH_AVAILABLE_ROOMS_REQUEST';
const FETCH_AVAILABLE_ROOMS_SUCCESS = 'FETCH_AVAILABLE_ROOMS_SUCCESS';
const FETCH_AVAILABLE_ROOMS_FAILURE = 'FETCH_AVAILABLE_ROOMS_FAILURE';
const CLEAR_HOUSEKEEPING_ERRORS = 'CLEAR_HOUSEKEEPING_ERRORS';

export default {
  FETCH_AVAILABLE_ROOMS_REQUEST,
  FETCH_AVAILABLE_ROOMS_SUCCESS,
  FETCH_AVAILABLE_ROOMS_FAILURE,
  CLEAR_HOUSEKEEPING_ERRORS,
};
