import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    body: {
      background: palette.common.white,
      paddingBottom: 0,
      color: palette.common.black,
    },
    footer: {
      justifyContent: 'flex-end',
    },
    additionalFooterSegment: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    costDescription: {
      fontSize: '1rem',
      marginRight: spacing(1),
    },
    cost: {
      fontSize: '1.5rem',
    },
    scrollRef: {
      paddingBottom: spacing(12),
    },
    checkOutSummarySignatureField: {
      height: '350px',
    },
  });
