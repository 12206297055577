import {
  createAction,
  createAsyncAction,
} from '@ac/library-utils/dist/redux-utils';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';

import {
  FailureDictionaryFetchStatesPayload,
  FetchDictionaryPayload,
  SuccessDictionaryFetchStatesPayload,
} from './types';

const ACTION_PREFIX = '@lazyLoadedDictionary/';

export const fetchStates = createAsyncAction<
  FetchDictionaryPayload,
  SuccessDictionaryFetchStatesPayload<KioskStateDetails[]>,
  FailureDictionaryFetchStatesPayload
>(`${ACTION_PREFIX}fetchStates`);

export const fetchDistricts = createAsyncAction<
  FetchDictionaryPayload,
  SuccessDictionaryFetchStatesPayload<BaseKioskConfigurationEntity[]>,
  FailureDictionaryFetchStatesPayload
>(`${ACTION_PREFIX}fetchDistricts`);

export const resetLazyLoadedDictionaryStore = createAction(
  `${ACTION_PREFIX}resetLazyLoadedDictionaryStore`
);
