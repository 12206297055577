import { CallApiResponseAction } from 'store/middleware/api';
import { State } from 'types/Store/CheckOutChargesStore';

import types from './types';

const initialState: State = {
  errors: [],
  currentFolioNumber: 0,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.SET_CURRENT_FOLIO_NUMBER:
      return {
        ...state,
        currentFolioNumber: action.payload,
      };

    default:
      return state;
  }
};
