import { useContext } from 'react';

import { RouterContext } from './Provider/RouterProvider';
import { RouterContextValue } from './Provider/useRouterContextValue';

export * from './Router';
export * from './routesConfigurations/checkInRoutes';
export * from './routesConfigurations/checkOutRoutes';
export * from './routesConfigurations/initialStepsRoutes';
export * from './routesConfigurations/makeKeysRoutes';
export * from './routesConfigurations/routes';
export * from './types';

export const useRouter = (): RouterContextValue => {
  return useContext(RouterContext) as RouterContextValue;
};
