import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ids } from 'config';
import { compose } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import { Grid, List, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import OrderSummaryListItem from '../OrderSummaryListItem';

import styles from './OrderSummaryList.style';

export interface PassedProps {
  items: Array<{
    title: string;
    quantity: number;
    price: number;
    id: string;
    max: number;
  }>;
  onItemAdd: (title: string) => void;
  onItemRemove: (title: string) => void;
  onDeleteClick: (title: string) => void;
  toggleActiveField: (title: string) => void;
  onFormSubmit: (values: any) => void;
}

interface OrderSummaryListProps
  extends PassedProps,
    InjectedFormProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class OrderSummaryList extends PureComponent<OrderSummaryListProps> {
  public render() {
    const {
      classes,
      items,
      onItemAdd,
      onItemRemove,
      onDeleteClick,
      toggleActiveField,
      handleSubmit,
      onFormSubmit,
      t,
    } = this.props;

    return (
      <Grid container item xs={12} sm={12} md={6} direction="column">
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <Typography className={classes.title}>
            {t('PURCHASED_ITEMS')}
          </Typography>
          <List className={classes.list}>
            {items.length > 0 ? (
              items.map((item) => (
                <Field
                  name={item.id}
                  type="number"
                  onFieldFocusToggle={toggleActiveField}
                  key={`order-summary-${item.title}`}
                  onAdd={onItemAdd}
                  onRemove={onItemRemove}
                  onDelete={onDeleteClick}
                  title={item.title}
                  quantity={item.quantity}
                  price={item.price}
                  normalize={this.parseToInt}
                  component={OrderSummaryListItem}
                  id={item.id}
                  max={item.max}
                />
              ))
            ) : (
              <OrderSummaryListItem isListEmpty />
            )}
          </List>
        </Form>
      </Grid>
    );
  }
  private parseToInt = (value: string) =>
    value.length > 0 && parseInt(value, 10);
}

export default compose(
  withTranslation(),
  withStyles(styles),
  reduxForm({ form: ids.MINIBAR_FORM })
)(OrderSummaryList) as (props: PassedProps) => JSX.Element;
