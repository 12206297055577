import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const SM_HEADER_HEIGHT = '35dvh';
const MD_HEADER_HEIGHT = '45dvh';

export const getBackground = (url?: string) =>
  url
    ? {
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundImage: `url('${url}')`,
        backgroundRepeat: 'no-repeat',
      }
    : {
        backgroundColor: colors.primary[400],
      };

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    body: {
      marginTop: spacing(4),
      marginBottom: spacing(7.75),
      paddingTop: 0,
      padding: spacing(11.5),
      [breakpoints.down('sm')]: {
        padding: spacing(9),
        paddingTop: 0,
      },
    },
    footerPanel: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 0,
    },
    headerWrapper: {
      maxHeight: SM_HEADER_HEIGHT,
      [breakpoints.up('md')]: {
        maxHeight: MD_HEADER_HEIGHT,
      },
    },
    formHeader: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& p': {
        marginBottom: 0,
      },
      marginBottom: spacing(3),
    },
    currencyWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      width: '100%',
      marginBottom: spacing(3),
    },
    title: {
      color: 'inherit',
      fontSize: '0.875rem',
    },
    currencyTitle: {
      fontWeight: 300,
      fontSize: '0.875rem',
      color: 'inherit',
      marginRight: spacing(0.25),
    },
    categoryTitle: {
      minWidth: '50%',
      paddingTop: spacing(1),
      paddingBottom: spacing(3),
      fontWeight: 300,
      fontSize: '1.5rem',
    },
    addonsWrapper: {
      display: 'flex',
    },
  });
