import {
  KioskComponentsConfigurator,
  PDFCreator,
  ThemeType,
} from '@ac/kiosk-components';
import { SessionDataHost } from '@ac/library-utils/dist/services';

import { KIOSK_COMPONENTS_DEFAULT_THEME_OVERRIDES } from '@gss/configs/defaultTheme';
import { PDF_SETUP } from '@gss/configs/pdfSetup';
import { initI18nTranslations } from '@gss/i18n';

export const initAppSetup = (): void => {
  SessionDataHost.setupSessionData();
  SessionDataHost.startSessionDataUpdater();

  initI18nTranslations();

  KioskComponentsConfigurator.createThemeVariables(
    ThemeType.default,
    KIOSK_COMPONENTS_DEFAULT_THEME_OVERRIDES
  );

  PDFCreator.configure(PDF_SETUP);
};
