import { AxiosResponse } from 'axios';
import { api } from 'config';
import { getFolios } from 'store/cashiering/selectors';
import {
  getAttachmentId,
  getFolioDocument,
  getUploadErrors,
} from 'store/files/selectors';
import { CALL_API } from 'store/middleware/api';
import { refetchProfile } from 'store/profile/actions';
import { getParentVersion } from 'store/profile/selectors';
import { getCurrentBusinessDate } from 'store/propertyManagement/selectors';
import { refetchReservation } from 'store/reservation/actions';
import {
  getReservation,
  getReservationVersion,
} from 'store/reservation/selectors';
import { getDocumentPhotos } from 'store/selectors';
import { Dispatch, GetState } from 'store/utils/actions';
import { DateManager, Files, Image } from 'utils';

import { PDFCreator } from '@ac/kiosk-components';

import { objectUrlToBlob } from '@gss/utils/files';

import types from './types';
import { prepareCheckOutFolioPdfContent } from './utils';

const getFileName = (data: string) => `Kiosk - Summary of Charges ${data}.pdf`;

const getSummaryFileName = (data: string) => `ReservationSummary_${data}.pdf`;

const defaultPhotoExtension = Image.ImageExtensions.jpeg;

const getDocumentFileName = (id: string, extension: string) =>
  `document-scan-${id}.${extension}`;

export const createAttachment =
  ({
    id,
    fileName,
    reservation,
  }: {
    id: string;
    fileName: string;
    reservation?: boolean;
  }) =>
  (dispatch: Dispatch, getState: GetState) =>
    dispatch({
      [CALL_API]: {
        method: 'POST',
        endpoint: reservation
          ? api.RESERVATIONS.ATTACHMENTS(id)
          : api.PROFILES.ATTACHMENTS(id),
        data: {
          name: fileName,
          files: [{ file: {}, id: '', settings: {} }],
        },
        interval: 500,
        checkFailureCondition: (response: AxiosResponse) =>
          reservation
            ? refetchReservation(response, id, dispatch)
            : refetchProfile(response, id, dispatch),
        getOptions: () => ({
          headers: {
            'If-Match': reservation
              ? getReservationVersion(getState())
              : getParentVersion(getState()),
          },
        }),
        types: [
          types.CREATE_ATTACHMENT_REQUEST,
          types.CREATE_ATTACHMENT_SUCCESS,
          types.CREATE_ATTACHMENT_FAILURE,
        ],
      },
    });

export const uploadFile =
  ({
    id,
    file,
    fileName,
    reservation,
  }: {
    id: string;
    file: Blob;
    fileName: string;
    reservation?: boolean;
  }) =>
  (dispatch: Dispatch, getState: GetState) => {
    const attachmentId = getAttachmentId(getState());
    const data = new FormData();
    data.append('file', file, fileName);

    return dispatch({
      [CALL_API]: {
        data,
        method: 'POST',
        endpoint: reservation
          ? api.RESERVATIONS.FILES(id, attachmentId)
          : api.PROFILES.FILES(id, attachmentId),
        checkFailureCondition: (response: AxiosResponse) =>
          reservation
            ? refetchReservation(response, id, dispatch)
            : refetchProfile(response, id, dispatch),
        getOptions: () => ({
          headers: {
            'If-Match': reservation
              ? getReservationVersion(getState())
              : getParentVersion(getState()),
          },
        }),
        types: [
          types.UPLOAD_FILE_REQUEST,
          types.UPLOAD_FILE_SUCCESS,
          types.UPLOAD_FILE_FAILURE,
        ],
      },
    });
  };

export const uploadPdf =
  (pdf: Blob) =>
  async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    try {
      dispatch({ type: types.UPLOAD_PDF_REQUEST });
      const state = getState();
      const reservation = getReservation(state);
      const { id } = reservation;
      const businessDate = getCurrentBusinessDate(state);

      await dispatch(
        createAttachment({
          id,
          fileName: getSummaryFileName(
            DateManager.getFormattedDate(businessDate, 'YYYYMMDD')
          ),
          reservation: true,
        })
      );

      const uploadErrors = getUploadErrors(getState());
      if (uploadErrors?.length)
        return dispatch({ type: types.UPLOAD_PDF_FAILURE });

      await dispatch(
        uploadFile({
          id,
          file: pdf,
          fileName: getSummaryFileName(
            DateManager.getFormattedDate(businessDate, 'YYYYMMDD')
          ),
          reservation: true,
        })
      );

      return dispatch({ type: types.UPLOAD_PDF_SUCCESS, payload: id });
    } catch (error) {
      return dispatch({ type: types.UPLOAD_PDF_FAILURE, payload: error });
    }
  };

export const uploadImage =
  (image: string) =>
  async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    try {
      dispatch({ type: types.UPLOAD_IMAGE_REQUEST });
      const state = getState();
      const reservation = getReservation(state);
      const { profileId } = reservation;
      const fileExtension =
        Image.getFileExtension(image) || defaultPhotoExtension;

      await dispatch(
        createAttachment({
          id: profileId,
          fileName: getDocumentFileName(profileId, fileExtension),
        })
      );

      const uploadErrors = getUploadErrors(getState());
      if (uploadErrors?.length)
        return dispatch({ type: types.UPLOAD_IMAGE_FAILURE });

      await dispatch(
        uploadFile({
          id: profileId,
          file: Files.base64ImageToBlob(image),
          fileName: getDocumentFileName(profileId, fileExtension),
        })
      );

      return dispatch({ type: types.UPLOAD_IMAGE_SUCCESS });
    } catch (error) {
      return dispatch({ type: types.UPLOAD_IMAGE_FAILURE, payload: error });
    }
  };

export const savePhotos =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    try {
      dispatch({ type: types.SAVE_PHOTOS_REQUEST });
      const state = getState();
      const photos = getDocumentPhotos(state);
      await photos!.reduce(async (prev, nextPhoto) => {
        await prev;

        return dispatch(uploadImage(nextPhoto));
      }, Promise.resolve());

      return dispatch({ type: types.SAVE_PHOTOS_SUCCESS });
    } catch (error) {
      return dispatch({ type: types.SAVE_PHOTOS_FAILURE, payload: error });
    }
  };

export const generateCheckOutFolioPdf =
  (folioId: string, signature: Blob) =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.GENERATE_SUMMARY_PDF_REQUEST });

      const state = getState();
      const oldFolioDocument = getFolioDocument(state);
      const pdfContent = await prepareCheckOutFolioPdfContent(state, signature);
      const pdf = await new PDFCreator().createPDF(pdfContent);

      if (oldFolioDocument?.file) {
        URL.revokeObjectURL(oldFolioDocument.file);
      }

      return dispatch({
        type: types.GENERATE_SUMMARY_PDF_SUCCESS,
        payload: { pdf: URL.createObjectURL(pdf), folioId },
      });
    } catch (error) {
      return dispatch({
        error,
        type: types.GENERATE_SUMMARY_PDF_FAILURE,
      });
    }
  };

export const clearFilesErrors = () => ({
  type: types.CLEAR_FILES_ERRORS,
});

export const saveFolioDocument =
  (pdf: string, folioId: string) =>
  async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    try {
      dispatch({ type: types.SAVE_FOLIO_DOCUMENT_REQUEST });
      const state = getState();
      const reservation = getReservation(state);
      const id = reservation.id;
      const businessDate = getCurrentBusinessDate(state);
      const fileNameData = DateManager.getFormattedDate(
        businessDate,
        'YYYYMMDD'
      );
      await dispatch(
        createAttachment({
          id,
          fileName: getFileName(fileNameData),
          reservation: true,
        })
      );

      const uploadErrors = getUploadErrors(getState());
      const fileBlob = await objectUrlToBlob(pdf);

      if (uploadErrors?.length || !fileBlob) {
        return dispatch({ type: types.SAVE_FOLIO_DOCUMENT_FAILURE });
      }

      await dispatch(
        uploadFile({
          id,
          file: fileBlob,
          fileName: getFileName(fileNameData),
          reservation: true,
        })
      );

      URL.revokeObjectURL(pdf);

      return dispatch({
        type: types.SAVE_FOLIO_DOCUMENT_SUCCESS,
        payload: folioId,
      });
    } catch (error) {
      return dispatch({
        type: types.SAVE_FOLIO_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };

export const resetFilesState =
  () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const folioDocument = getFolioDocument(state);

    if (folioDocument?.file) {
      URL.revokeObjectURL(folioDocument.file);
    }

    dispatch({ type: types.RESET_FILES_STATE });
  };
