import { mapLongDateFormat, mapShortDateFormat } from '@ac/library-api';

import {
  KioskConfigurationProperty,
  KioskLayoutSetting,
} from '@gss/api/KioskApi/entries';
import {
  DEFAULT_LONG_DATE_FORMAT,
  DEFAULT_SHORT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT_CODE,
} from '@gss/configs/constants';

import { mapLayoutSettings, SettingsCodeMap } from './mapLayoutSettings';

interface PropertyMapSettings {
  SHORT_DATE_FORMAT?: string;
  LONG_DATE_FORMAT?: string;
}

const propertyMapSettingsCodesMap: SettingsCodeMap<PropertyMapSettings> = {
  SHORT_DATE_FORMAT: {
    key: 'SHORTDATEFORMAT',
  },
  LONG_DATE_FORMAT: {
    key: 'LONGDATEFORMAT',
  },
};

export const mapProperty = async (
  property?: KioskConfigurationProperty,
  layoutSettings?: KioskLayoutSetting[]
): Promise<KioskConfigurationProperty | undefined> => {
  if (!property) {
    return undefined;
  }

  const settings = await mapLayoutSettings(
    propertyMapSettingsCodesMap,
    layoutSettings
  );

  return {
    ...property,

    dateTimeFormat: {
      timeFormat:
        property.dateTimeFormat?.timeFormat || DEFAULT_TIME_FORMAT_CODE,

      longDateFormat: mapLongDateFormat(
        settings?.LONG_DATE_FORMAT ||
          property.dateTimeFormat?.longDateFormat ||
          DEFAULT_LONG_DATE_FORMAT
      ),

      shortDateFormat: mapShortDateFormat(
        settings?.SHORT_DATE_FORMAT ||
          property.dateTimeFormat?.shortDateFormat ||
          DEFAULT_SHORT_DATE_FORMAT
      ),
    },
  };
};
