/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getDepartureHour } from 'store/reservation/selectors';
import { toggleDayUseModal } from 'store/ui/actions';
import Store from 'types/Store';
import { Configurator } from 'utils';

import {
  Button,
  Circular,
  CircularType,
  IconTypes,
} from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './DayUse.style';

const { KIOSK_DAY_USE_MESSAGE } = Configurator.getTranslationCodes();

interface PassedProps {}

interface DayUseProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  departureHour: string;
  toggleDayUseModal: typeof toggleDayUseModal;
}

class DayUse extends PureComponent<DayUseProps> {
  public render() {
    const { classes, t, departureHour } = this.props;
    const description = Configurator.getTranslation(KIOSK_DAY_USE_MESSAGE);

    return (
      <Grid className={classes.content}>
        <Grid className={classes.alertWrapper}>
          <Circular
            icon={IconTypes.exclamationMark}
            iconClassName={classes.alertIcon}
            type={CircularType.warning}
          />
        </Grid>
        <Grid className={classes.descriptionWrapper}>
          <Typography className={classes.title}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('EXPECTED_DEPARTURE_DESCRIPTION', {
                  time: departureHour,
                }),
              }}
            />
            {description}
          </Typography>
          <Button onClick={this.handleClick} className={classes.button}>
            {t('CLOSE')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private handleClick = () => {
    const { toggleDayUseModal } = this.props;
    toggleDayUseModal();
  };
}

const mapStateToProps = (state: Store) => ({
  departureHour: getDepartureHour(state),
});

const mapDispatchToProps = {
  toggleDayUseModal,
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DayUse) as (props: PassedProps) => JSX.Element;
