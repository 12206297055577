import {
  addBillingInstruction,
  addCompanyFolio,
  applyBillingInstruction,
  fetchAdditionalFolioInfo,
  fetchBillingInstructions,
  fetchFolios,
  getApplyBillingInstructionStatus,
  transferTransactions,
  updateFolioProfile,
} from 'store/cashiering/actions';
import {
  getActiveCompanyFolio,
  getBillingInstructions,
  getCashieringAccount,
  getFolios,
  getIndividualFolio,
} from 'store/cashiering/selectors';
import { fetchCommunicationChannels } from 'store/profile/actions';
import { getNewCompanyId } from 'store/profile/selectors';
import { getAccountId } from 'store/reservation/selectors';
import { Dispatch, GetState } from 'store/utils/actions';
import { Folio } from 'types/Api/Cashiering';
import { Transaction } from 'types/Api/Shared';
import { Configurator } from 'utils';

import { getSelectedCompanyId } from './selectors';
import types from './types';

export const fullCheckOutInvoiceSubmit =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.FULL_CHECKOUT_INVOICE_SUBMIT_REQUEST });
      const state = getState();
      const companyFolio = getActiveCompanyFolio(state);
      const cashieringAccount = getCashieringAccount(state);
      if (companyFolio) await dispatch(onFolioProfileUpdate());
      if (!companyFolio) await dispatch(addCompanyInvoiceFolio());
      await dispatch(onBillingInstructionAdd());
      await dispatch(fetchFolios());
      await dispatch(fetchAdditionalFoliosInfo());
      await dispatch(onTransactionsTransfer());
      await dispatch(fetchFolios());
      await dispatch(fetchCommunicationChannels());
      await dispatch(fetchAdditionalFoliosInfo());
      await dispatch(fetchBillingInstructions(cashieringAccount.id));

      return dispatch({ type: types.FULL_CHECKOUT_INVOICE_SUBMIT_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.FULL_CHECKOUT_INVOICE_SUBMIT_FAILURE,
        payload: error,
      });
    }
  };

export const addCompanyInvoiceFolio =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.COMPANY_INVOICE_FOLIO_ADD_REQUEST });
      const state = getState();
      const newCompanyId = getNewCompanyId(state);
      const accountId = getAccountId(state);
      const id = getSelectedCompanyId(state);
      const {
        cashieringDefaultSettings: { billViewAllowed, remoteCheckOutAllowed },
        folioCodes: { FOLIO_COMPANY_TYPE },
        defaultFolioStyle: { code: folioStyleCode },
      } = Configurator;
      const data = {
        remoteCheckOutAllowed,
        billViewAllowed,
        additionalText: '',
        ...(folioStyleCode && { folioStyleCode }),
        folioTypeCode: FOLIO_COMPANY_TYPE,
        paymentMethodId: null,
        profileId: id || newCompanyId,
      };
      await dispatch(addCompanyFolio(accountId, data));

      return dispatch({ type: types.COMPANY_INVOICE_FOLIO_ADD_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.COMPANY_INVOICE_FOLIO_ADD_FAILURE,
        payload: error,
      });
    }
  };

export const onFolioProfileUpdate =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.ON_FOLIO_PROFILE_UPDATE_REQUEST });
      const state = getState();
      const id = getSelectedCompanyId(state);
      const accountId = getAccountId(state);
      const companyFolio = getActiveCompanyFolio(state);
      await dispatch(updateFolioProfile(accountId, companyFolio!, id));

      return dispatch({ type: types.ON_FOLIO_PROFILE_UPDATE_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.ON_FOLIO_PROFILE_UPDATE_FAILURE,
        payload: error,
      });
    }
  };

export const onBillingInstructionAdd =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.ON_BILLING_INSTRUCTIONS_ADD_REQUEST });
      const state = getState();
      const accountId = getAccountId(state);
      const companyFolio = getActiveCompanyFolio(state);
      const billingInstructions = getBillingInstructions(state);
      const targetFolioId = companyFolio
        ? companyFolio.id
        : billingInstructions.targetFolioId;
      const applicableTransactionCodeIds =
        Configurator.billingTransactionCodes.map((elem) => elem.id);
      const data = {
        targetFolioId,
        newFolio: null,
        profileName: null,
        selector: {
          applicableTransactionCodeIds,
        },
        targetAccountId: null,
        version: '',
      };
      await dispatch(addBillingInstruction(accountId, data));
      await dispatch(applyBillingInstruction(accountId));
      await dispatch(getApplyBillingInstructionStatus());

      return dispatch({ type: types.ON_BILLING_INSTRUCTIONS_ADD_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.ON_BILLING_INSTRUCTIONS_ADD_FAILURE,
        payload: error,
      });
    }
  };

export const onTransactionsTransfer =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.ON_TRANSACTIONS_TRANSFER_ADD_REQUEST });
      const state = getState();
      const accountId = getAccountId(state);
      const companyFolio = getActiveCompanyFolio(state);
      const individualFolio = getIndividualFolio(state);
      const data = {
        targetAccountId: accountId,
        targetFolioWindowId: companyFolio ? companyFolio.id : '',
        transferToNewFolio: false,
        cashierNumber: Configurator.cashieringSettings.cashierNumber || '',
        transactions: individualFolio
          ? individualFolio.transactions.map((transaction: Transaction) => ({
              transactionId: transaction.id,
              version: transaction.version,
            }))
          : '',
      };
      if (individualFolio!.transactions.length)
        await dispatch(transferTransactions(data));

      return dispatch({ type: types.ON_TRANSACTIONS_TRANSFER_ADD_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.ON_TRANSACTIONS_TRANSFER_ADD_FAILURE,
        payload: error,
      });
    }
  };

export const fetchAdditionalFoliosInfo =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.FETCH_ADDITIONAL_FOLIOS_INFO_REQUEST });
      const state = getState();
      const folios = getFolios(state);
      await folios.reduce(async (prev, nextFolio) => {
        await prev;

        return dispatch(fetchAdditionalFolioInfo(nextFolio.id));
      }, Promise.resolve());

      return dispatch({ type: types.FETCH_ADDITIONAL_FOLIOS_INFO_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.FETCH_ADDITIONAL_FOLIOS_INFO_FAILURE,
        payload: error,
      });
    }
  };

export const selectCompany = (id: string) => ({
  type: types.SELECT_COMPANY,
  payload: id,
});

export const lockCompanySelect = () => ({
  type: types.LOCK_COMPANY_SELECT,
});

export const toggleCompanySelect = () => ({
  type: types.TOGGLE_COMPANY_SELECT,
});
