import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const wrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export default ({ palette, spacing }: Theme) =>
  createStyles({
    content: {
      ...wrapper,
      minHeight: '100%',
      flex: 1,
    },
  });
