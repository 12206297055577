import { api } from 'config';
import { CALL_API } from 'store/middleware/api';
import { Dispatch, GetState } from 'store/utils/actions';

import { getRoomImageUrls } from './selectors';
import types from './types';

export const fetchRoomAttributes = () => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.ROOM_ATTRIBUTES,
    types: [
      types.FETCH_ROOM_ATTRIBUTES_REQUEST,
      types.FETCH_ROOM_ATTRIBUTES_SUCCESS,
      types.FETCH_ROOM_ATTRIBUTES_FAILURE,
    ],
  },
});

export const fetchRoomImages =
  (roomId: string) => async (dispatch: Dispatch) => {
    await dispatch(revokeImageUrls());

    return dispatch({
      [CALL_API]: {
        endpoint: api.CONFIGURATION.ROOM_IMAGES(roomId),
        types: [
          types.FETCH_ROOM_IMAGES_REQUEST,
          types.FETCH_ROOM_IMAGES_SUCCESS,
          types.FETCH_ROOM_IMAGES_FAILURE,
        ],
      },
    });
  };

export const fetchRoomImageContent = (roomId: string, imageId: string) => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.ROOM_IMAGE_CONTENT(roomId, imageId),
    responseType: 'arraybuffer',
    payload: { imageId },
    types: [
      types.FETCH_ROOM_IMAGE_CONTENT_REQUEST,
      types.FETCH_ROOM_IMAGE_CONTENT_SUCCESS,
      types.FETCH_ROOM_IMAGE_CONTENT_FAILURE,
    ],
  },
});

export const fetchRoomTypeImages =
  (roomTypeId: string) => async (dispatch: Dispatch) => {
    await dispatch(revokeImageUrls());

    return dispatch({
      [CALL_API]: {
        endpoint: api.CONFIGURATION.ROOM_TYPE_IMAGES(roomTypeId),
        types: [
          types.FETCH_ROOM_TYPE_IMAGES_REQUEST,
          types.FETCH_ROOM_TYPE_IMAGES_SUCCESS,
          types.FETCH_ROOM_TYPE_IMAGES_FAILURE,
        ],
      },
    });
  };

export const fetchRoomTypeImageContent = (roomId: string, imageId: string) => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.ROOM_TYPE_IMAGE_CONTENT(roomId, imageId),
    responseType: 'arraybuffer',
    payload: { imageId },
    types: [
      types.FETCH_ROOM_TYPE_IMAGE_CONTENT_REQUEST,
      types.FETCH_ROOM_TYPE_IMAGE_CONTENT_SUCCESS,
      types.FETCH_ROOM_TYPE_IMAGE_CONTENT_FAILURE,
    ],
  },
});

export const clearRoomGalleryErrors = () => ({
  type: types.CLEAR_ROOM_GALLERY_ERRORS,
});

export const revokeImageUrls =
  () => (dispatch: Dispatch, getState: GetState) => {
    const roomImageUrls = getRoomImageUrls(getState());
    roomImageUrls.map(URL.revokeObjectURL);
  };
