import { colors } from 'config';
import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    body: {
      zIndex: 100,
    },
    reservation: {
      marginTop: spacing(4),
      marginBottom: spacing(8),
      '& > *': {
        marginBottom: spacing(2),
      },
    },
    subContent: {
      '& > p': {
        fontSize: '0.875rem',
        color: 'inherit',
      },
    },
    text: {
      fontSize: '1.25rem',
      color: 'inherit',
    },
    content: {
      fontSize: '2.5rem',
      color: 'inherit',
    },
    footer: {
      backgroundColor: 'inherit',
      boxShadow: 'none',
      border: 'none',
    },
    footerContent: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 0,
    },
    footerText: {
      whiteSpace: 'pre-wrap',
      textAlign: 'right',
      marginRight: spacing(2),
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.HINT_FONT_COLOR,
        colors.grey[500]
      ),
    },
    additionalInfo: {
      display: 'flex',
    },
  });
