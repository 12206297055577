import { CallApiResponseAction } from 'store/middleware/api';

import CheckInCommunicationDetailsStore from './interfaces';
import types from './types';

const initialState: CheckInCommunicationDetailsStore = {
  cachedCommunicationDetailsFormData: undefined,
  cachedAddressId: undefined,
  scannedAddressStatus: undefined,
  updateCommunicationDetailsPayload: undefined,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.CACHE_COMMUNICATION_DETAILS_FORM_DATA:
      return {
        ...state,
        cachedCommunicationDetailsFormData: action.payload,
      };

    case types.SET_CACHED_ADDRESS_ID:
      return {
        ...state,
        cachedAddressId: action.payload,
      };

    case types.SET_SCANNED_ADDRESS_STATUS:
      return {
        ...state,
        scannedAddressStatus: action.payload,
      };

    case types.SET_UPDATE_COMMUNICATION_DETAILS_PAYLOAD:
      return {
        ...state,
        updateCommunicationDetailsPayload: action.payload,
      };

    case types.CLEAR_UPDATE_COMMUNICATION_DETAILS_PAYLOAD:
      return {
        ...state,
        updateCommunicationDetailsPayload: undefined,
      };

    case types.RESET_COMMUNICATION_CHANNELS_UPDATE_PROGRESS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
