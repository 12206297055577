import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { Breakpoints } from '@material-ui/core/styles/createBreakpoints';
import { createStyles, CSSProperties } from '@material-ui/styles';

export const ZERO_KEYS_BUTTON_SIZE = 90;

const getKeysGenerateImage = (
  spacing: any,
  breakpoints: Breakpoints
): CSSProperties => {
  const verticalScreenHeight = `calc(50% - ${spacing(7.75 / 2)}px)`;

  return {
    backgroundImage: Configurator.keysGenerateImage
      ? `url('${Configurator.keysGenerateImage}')`
      : '',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    width: '100%',
    height: verticalScreenHeight,
    top: verticalScreenHeight,
    left: 0,
    [breakpoints.up('md')]: {
      left: '50%',
      top: 0,
      width: '50%',
      height: `calc(100% - ${spacing(7.75)}px)`,
    },
  };
};

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    backButton: {
      marginRight: spacing(1 / 2),
    },
    radioWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    keysGenerateImage: {
      ...getKeysGenerateImage(spacing, breakpoints),
    },
    body: {
      position: 'relative',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    zeroKeysButton: {
      width: ZERO_KEYS_BUTTON_SIZE,
      height: ZERO_KEYS_BUTTON_SIZE,
      minHeight: ZERO_KEYS_BUTTON_SIZE,
      minWidth: ZERO_KEYS_BUTTON_SIZE,
      background: 'transparent',
      zIndex: 1000,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  });
