import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './SegmentInfo.style';

interface SegmentInfoProps extends WithStyles<typeof styles> {
  title: string;
  subtitle?: string | React.ReactNode;
  subtitleList: string[];
  additionalInfo: Array<{
    title: string;
    value: string;
  }>;
  classNames?: {
    titleClass?: string;
    subtitleClass?: string;
    subtitleListClass?: string;
    segmentInfoWrapper?: string;
  };
}

class SegmentInfo extends PureComponent<SegmentInfoProps> {
  public static defaultProps = {
    title: '',
    subtitle: '',
    subtitleList: [],
    additionalInfo: [],
    classNames: {},
  };

  public render() {
    const {
      classes,
      title,
      subtitleList,
      additionalInfo,
      classNames: passedClassNames,
    } = this.props;

    return (
      <div className={passedClassNames?.segmentInfoWrapper}>
        <Typography
          className={classNames(classes.title, passedClassNames?.titleClass)}
        >
          {title}
        </Typography>
        {this.renderSubtitle()}
        {subtitleList.map((subtitlePart: string) => (
          <Typography
            key={`subtitlePart-${subtitlePart}`}
            className={classNames(
              passedClassNames?.subtitleListClass,
              classes.subtitle,
              classes.subtitlePart
            )}
            display="inline"
          >
            {subtitlePart}
          </Typography>
        ))}
        {additionalInfo.length ? (
          <Grid container>
            {additionalInfo.map((item) => (
              <Grid
                className={classes.additionalDetails}
                key={`segment-${item.title}`}
              >
                <Typography className={classes.additionalTitle}>
                  {item.title}
                </Typography>
                <Typography className={classes.additionalSubtitle}>
                  {item.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ) : null}
      </div>
    );
  }
  private renderSubtitle = () => {
    const { subtitle, classes, classNames: passedClassNames } = this.props;
    if (!subtitle) return null;
    if (typeof subtitle === 'string') {
      return (
        <Typography
          className={classNames(
            passedClassNames?.subtitleClass,
            classes.subtitle
          )}
        >
          {subtitle}
        </Typography>
      );
    }

    return subtitle;
  };
}

export default withStyles(styles)(SegmentInfo);
