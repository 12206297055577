import { CallApiResponseAction } from 'store/middleware/api';
import { failure, request, success } from 'store/utils/reducer';
import {
  AddressApiDataModel,
  CommunicationChannel,
  ProfileConsent,
  ProfileMatchingResponseModel,
  SeverityOfMatch,
} from 'types/Api/Profile';
import { State } from 'types/Store/ProfileStore';
import { Configurator } from 'utils';

import {
  PatchOperation,
  PatchProfileCommunicationChannelRequest,
  PatchProfileSectionRequest,
} from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import types from './types';

const initialState: State = {
  dataFetching: {
    profile: false,
    company: false,
    individual: false,
    communicationChannels: false,
    addresses: false,
    consent: false,
    travelAgent: false,
    profileDetailsUpdate: false,
  },
  profiles: [],
  linkedCompanies: [],
  errors: [],
  newCompanyId: '',
  newIndividualId: '',
  isCompanyCreated: false,
  isIndividualCreated: false,
  travelAgents: [],
  companyCommunicationChannels: [],
  travelAgentCommunicationChannels: [],
  individualCommunicationChannels: [],
  companies: [],
  profileFromFolio: [],
  profilesViews: [],
  wasEditEmailFormInitialized: false,
  profileDuplicatesDetected: false,
  guestAcceptedProfileDuplicateRisk: false,
};

const getIdentifierFromLocation = (location: string) => {
  const result = location.split('/');

  return result[result.length - 1];
};

const parseCommunicationChannels = (
  unparsedChannels: Array<
    PatchProfileSectionRequest<PatchProfileCommunicationChannelRequest>
  >
): CommunicationChannel[] => {
  const existingChannels = unparsedChannels.filter(
    (channel) => channel.operation !== PatchOperation.Remove
  );

  return existingChannels
    .map(({ value, id }) => {
      const { typeId, ...rest } = value || {};
      const typeCode = Configurator.emailTypes.find(
        (type) => type.id === typeId
      )?.code;

      return {
        ...rest,
        id: id as string | undefined,
        typeCode,
      };
    })
    .filter((channel) => isDefined(channel.typeCode)) as CommunicationChannel[];
};

const mergeOldCommunicationChannelsWithNew = (
  oldChannels: CommunicationChannel[],
  newData: Array<
    PatchProfileSectionRequest<PatchProfileCommunicationChannelRequest>
  >
) => {
  let communicationChannels = [...oldChannels];
  const parsedChannels = parseCommunicationChannels(newData);

  communicationChannels = communicationChannels.filter(
    (channel) =>
      !newData.find(
        (
          changedChannel: PatchProfileSectionRequest<PatchProfileCommunicationChannelRequest>
        ) =>
          changedChannel.id === channel.id &&
          (changedChannel.operation === PatchOperation.Remove ||
            changedChannel.operation === PatchOperation.Update)
      )
  );

  communicationChannels.push(...parsedChannels);

  return communicationChannels;
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_PROFILE_REQUEST:
      return request(state, 'profile');
    case types.FETCH_PROFILE_SUCCESS: {
      const { data } = action.response;
      const { profiles } = state;

      return {
        ...success(state, 'profile'),
        profiles: [...profiles.filter((elem) => elem.id !== data.id), data],
      };
    }
    case types.FETCH_PROFILE_FAILURE:
      return failure(state, action, 'profile');

    case types.FETCH_PROFILE_LIST_REQUEST:
      return {
        ...state,
        ...request(state, 'profile'),
      };

    case types.FETCH_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        ...success(state, 'profile'),
        profiles: action.response.data.results,
      };

    case types.FETCH_PROFILE_LIST_FAILURE:
      return {
        ...state,
        ...failure(state, action, 'profile'),
      };

    case types.FETCH_COMPANY_REQUEST:
      return request(state, 'company');
    case types.FETCH_COMPANY_SUCCESS: {
      const { data } = action.response;
      const { linkedCompanies } = state;

      return {
        ...success(state, 'company'),
        linkedCompanies: [
          ...linkedCompanies.filter((elem) => elem.id !== data.id),
          data,
        ],
      };
    }
    case types.FETCH_COMPANY_FAILURE:
      return failure(state, action, 'company');

    case types.FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST:
      return request(state, 'communicationChannels');
    case types.FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'communicationChannels'),
        individualCommunicationChannels: data.communicationDetails,
      };
    }
    case types.FETCH_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE:
      return failure(state, action, 'communicationChannels');

    case types.FETCH_COMPANY_COMMUNICATION_CHANNELS_REQUEST:
      return request(state, 'communicationChannels');
    case types.FETCH_COMPANY_COMMUNICATION_CHANNELS_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'communicationChannels'),
        companyCommunicationChannels: data.communicationDetails,
      };
    }
    case types.FETCH_COMPANY_COMMUNICATION_CHANNELS_FAILURE:
      return failure(state, action, 'communicationChannels');

    case types.FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST:
      return request(state, 'communicationChannels');
    case types.FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'communicationChannels'),
        travelAgentCommunicationChannels: data.communicationDetails,
      };
    }
    case types.FETCH_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE:
      return failure(state, action, 'communicationChannels');

    case types.UPDATE_GUEST_COMMUNICATION_CHANNELS_REQUEST:
      return request(state, 'communicationChannels');
    case types.UPDATE_GUEST_COMMUNICATION_CHANNELS_SUCCESS: {
      const {
        profiles: [profile],
      } = state;

      const communicationDetails = mergeOldCommunicationChannelsWithNew(
        profile.communicationDetails,
        action.payload
      );

      const updatedProfile = {
        ...profile,
        communicationDetails,
      };

      return {
        ...success(state, 'communicationChannels'),
        profiles: [updatedProfile],
        profileDuplicatesDetected: false,
      };
    }
    case types.UPDATE_GUEST_COMMUNICATION_CHANNELS_FAILURE:
      return failure(state, action, 'communicationChannels');

    case types.UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_REQUEST:
      return request(state, 'communicationChannels');
    case types.UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_SUCCESS: {
      const { individualCommunicationChannels } = state;

      const communicationChannels = mergeOldCommunicationChannelsWithNew(
        individualCommunicationChannels,
        action.payload
      );

      return {
        ...success(state, 'communicationChannels'),
        individualCommunicationChannels: communicationChannels,
      };
    }
    case types.UPDATE_INDIVIDUAL_COMMUNICATION_CHANNELS_FAILURE:
      return failure(state, action, 'communicationChannels');

    case types.UPDATE_PROFILE_DETAILS_REQUEST:
      return request(state, 'profileDetailsUpdate');
    case types.UPDATE_PROFILE_DETAILS_SUCCESS: {
      const { profileId, details } = action.payload;
      const { profiles } = state;
      const profile = profiles.find((profile) => profile.id === profileId);
      const updatedTitleCode =
        details.titleId &&
        Configurator.titles.find(({ id }) => id === details.titleId)?.code;

      return {
        ...success(state, 'profileDetailsUpdate'),
        profiles: [
          {
            ...profile,
            details: {
              ...profile?.details,
              ...details,
              ...(updatedTitleCode && { titleCode: updatedTitleCode }),
            },
          },
        ],
        profileDuplicatesDetected: false,
      };
    }
    case types.UPDATE_PROFILE_DETAILS_FAILURE:
      return failure(state, action, 'profileDetailsUpdate');

    case types.UPDATE_COMPANY_COMMUNICATION_CHANNELS_REQUEST:
      return request(state, 'communicationChannels');
    case types.UPDATE_COMPANY_COMMUNICATION_CHANNELS_SUCCESS: {
      const { companyCommunicationChannels } = state;

      const communicationChannels = mergeOldCommunicationChannelsWithNew(
        companyCommunicationChannels,
        action.payload
      );

      return {
        ...success(state, 'communicationChannels'),
        companyCommunicationChannels: communicationChannels,
      };
    }
    case types.UPDATE_COMPANY_COMMUNICATION_CHANNELS_FAILURE:
      return failure(state, action, 'communicationChannels');

    case types.UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_REQUEST:
      return request(state, 'communicationChannels');
    case types.UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_SUCCESS: {
      const { travelAgentCommunicationChannels } = state;

      const communicationChannels = mergeOldCommunicationChannelsWithNew(
        travelAgentCommunicationChannels,
        action.payload
      );

      return {
        ...success(state, 'communicationChannels'),
        travelAgentCommunicationChannels: communicationChannels,
      };
    }
    case types.UPDATE_TRAVEL_AGENT_COMMUNICATION_CHANNELS_FAILURE:
      return failure(state, action, 'communicationChannels');

    case types.FETCH_PROFILE_FROM_FOLIO_REQUEST:
      return request(state, 'profile');
    case types.FETCH_PROFILE_FROM_FOLIO_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state, 'profile'),
        profileFromFolio: [data],
      };
    }
    case types.FETCH_PROFILE_FROM_FOLIO_FAILURE:
      return failure(state, action, 'profile');

    case types.CLEAR_PROFILE_ERRORS:
      return {
        ...state,
        errors: [],
      };

    case types.ADD_CONSENTS_REQUEST:
      return request(state, 'consent');
    case types.ADD_CONSENTS_SUCCESS: {
      const { consents = [], profileId } = action.payload;

      return {
        ...success(state, 'consent'),
        profiles: state.profiles.map((profile) =>
          profile.id === profileId
            ? {
                ...profile,
                consents: [
                  ...(profile.consents || []),
                  ...consents.map((consent: ProfileConsent) => ({
                    ...consent,
                    isGranted: true,
                  })),
                ],
              }
            : profile
        ),
      };
    }
    case types.ADD_CONSENTS_FAILURE:
      return failure(state, action, 'consent');

    case types.REVOKE_CONSENTS_REQUEST:
      return request(state, 'consent');
    case types.REVOKE_CONSENTS_SUCCESS: {
      const { consents: revokedConsentIds = [], profileId } = action.payload;

      return {
        ...success(state, 'consent'),
        profiles: state.profiles.map((profile) =>
          profile.id === profileId
            ? {
                ...profile,
                consents: [
                  ...(profile.consents || []).filter(
                    (consent) => !revokedConsentIds.includes(consent.consentId)
                  ),
                ],
              }
            : profile
        ),
      };
    }
    case types.REVOKE_CONSENTS_FAILURE:
      return failure(state, action, 'consent');

    case types.UPDATE_COMPANY_ADDRESSES_REQUEST:
      return request(state, 'addresses');
    case types.UPDATE_COMPANY_ADDRESSES_SUCCESS:
      return success(state, 'addresses');
    case types.UPDATE_COMPANY_ADDRESSES_FAILURE:
      return failure(state, action, 'addresses');

    case types.UPDATE_COMPANY_DETAILS_REQUEST:
      return request(state, 'company');
    case types.UPDATE_COMPANY_DETAILS_SUCCESS:
      return success(state, 'company');
    case types.UPDATE_COMPANY_DETAILS_FAILURE:
      return failure(state, action, 'company');

    case types.CREATE_COMPANY_PROFILE_REQUEST:
      return request(state, 'company');
    case types.CREATE_COMPANY_PROFILE_SUCCESS: {
      const { location } = action.response.headers;

      return {
        ...success(state, 'company'),
        newCompanyId: getIdentifierFromLocation(location),
      };
    }
    case types.CREATE_COMPANY_PROFILE_FAILURE:
      return failure(state, action, 'company');

    case types.CREATE_INDIVIDUAL_PROFILE_REQUEST:
      return request(state, 'individual');
    case types.CREATE_INDIVIDUAL_PROFILE_SUCCESS: {
      const { location } = action.response.headers;

      return {
        ...success(state, 'individual'),
        newIndividualId: getIdentifierFromLocation(location),
      };
    }
    case types.CREATE_INDIVIDUAL_PROFILE_FAILURE:
      return failure(state, action, 'individual');

    case types.LINK_COMPANY_PROFILE_REQUEST:
      return request(state, 'company');
    case types.LINK_COMPANY_PROFILE_SUCCESS:
      return success(state, 'company');
    case types.LINK_COMPANY_PROFILE_FAILURE:
      return failure(state, action, 'company');

    case types.FETCH_CREATE_COMPANY_STATUS_REQUEST:
      return request(state, 'company');
    case types.FETCH_CREATE_COMPANY_STATUS_SUCCESS: {
      return {
        ...success(state, 'company'),
        isCompanyCreated: true,
      };
    }
    case types.FETCH_CREATE_COMPANY_STATUS_FAILURE:
      return failure(state, action, 'company');

    case types.FETCH_CREATE_INDIVIDUAL_STATUS_REQUEST:
      return request(state, 'individual');
    case types.FETCH_CREATE_INDIVIDUAL_STATUS_SUCCESS: {
      return {
        ...success(state, 'individual'),
        isIndividualCreated: true,
      };
    }
    case types.FETCH_CREATE_INDIVIDUAL_STATUS_FAILURE:
      return failure(state, action, 'individual');

    case types.CHOOSE_PROFILE: {
      const { payload: id } = action;
      const profiles = state.profiles.filter((item) => item.id === id);

      return {
        ...state,
        profiles,
      };
    }

    case types.FETCH_TRAVEL_AGENTS_REQUEST:
      return request(state, 'travelAgent');
    case types.FETCH_TRAVEL_AGENTS_SUCCESS: {
      const { results } = action.response.data;

      return {
        ...success(state, 'travelAgent'),
        travelAgents: results,
      };
    }
    case types.FETCH_TRAVEL_AGENTS_FAILURE:
      return failure(state, action, 'travelAgent');

    case types.FETCH_COMPANIES_REQUEST:
      return request(state, 'company');
    case types.FETCH_COMPANIES_SUCCESS: {
      const { results } = action.response.data;

      return {
        ...success(state, 'company'),
        companies: results,
      };
    }
    case types.FETCH_COMPANIES_FAILURE:
      return failure(state, action, 'company');

    case types.FETCH_PROFILES_VIEWS_REQUEST:
      return request(state, 'profile');
    case types.FETCH_PROFILES_VIEWS_SUCCESS: {
      const { results } = action.response.data;

      return {
        ...success(state, 'profile'),
        profilesViews: results,
      };
    }
    case types.FETCH_PROFILES_VIEWS_FAILURE:
      return failure(state, action, 'profile');

    case types.CHECK_PROFILE_DUPLICATES_REQUEST:
      return request(state, 'profile');
    case types.CHECK_PROFILE_DUPLICATES_SUCCESS: {
      const { results } = action.response.data;

      const duplicates = results.filter(
        (duplicates: ProfileMatchingResponseModel) =>
          duplicates.severity === SeverityOfMatch.AutoMergeLimitReached ||
          duplicates.severity === SeverityOfMatch.UpperThresholdReached
      );

      return {
        ...success(state, 'profile'),
        profileDuplicatesDetected: Boolean(duplicates.length),
        guestAcceptedProfileDuplicateRisk: false,
      };
    }
    case types.CHECK_PROFILE_DUPLICATES_FAILURE:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          profile: false,
        },
      };

    case types.CHECK_EDIT_EMAIL_FORM: {
      const wasEditEmailFormInitialized = action.payload;

      return {
        ...state,
        wasEditEmailFormInitialized,
      };
    }

    case types.FETCH_PROFILE_VERSION_REQUEST:
      return request(state, 'profile');
    case types.FETCH_PROFILE_VERSION_SUCCESS: {
      const { data: newProfile } = action.response;
      const { profiles } = state;

      return {
        ...success(state, 'profile'),
        profiles: profiles.map((profile) =>
          profile.id === newProfile.id
            ? { ...profile, version: newProfile.version }
            : profile
        ),
      };
    }
    case types.FETCH_PROFILE_VERSION_FAILURE:
      return failure(state, action, 'profile');

    case types.SET_GUEST_ACCEPTED_PROFILE_DUPLICATE_RISK:
      return {
        ...state,
        guestAcceptedProfileDuplicateRisk: action.payload,
      };

    default:
      return state;
  }
};
