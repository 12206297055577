import { Configurator } from 'utils';

export const DEFAULT_FONT_CODE = 'OPENSANS';

export const fontCodes: { [key: string]: string } = {
  ARVO: 'Arvo',
  CABIN: 'Cabin',
  CABINCONDENSED: 'Cabin Condensed',
  PTSANS: 'PT Sans',
  LORA: 'Lora',
  MERRIWEATHER: 'Merriweather',
  NOTOSANS: 'Noto Sans',
  OPENSANSCONDENSED: 'Open Sans Condensed',
  PLAYFAIRDISPLAY: 'Playfair Display',
  RALEWAY: 'Raleway',
  ROBOTO: 'Roboto',
  ROBOTOMONO: 'Roboto Mono',
  OPENSANS: 'Open Sans',
  MONSERRAT: 'Montserrat',
  BOING: 'Montserrat',
};

const getfontFamily = () => {
  const { KIOSK_USED_FONT } = Configurator.entityCodes;
  const fontCode = Configurator.getEntityTypeId(KIOSK_USED_FONT);

  return fontCodes[fontCode] || fontCodes[DEFAULT_FONT_CODE];
};

export const getTypography = () => ({
  useNextVariants: true,
  suppressDeprecationWarnings: true,
  fontFamily: [
    `"${getfontFamily()}"`,
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'sans-serif',
    'Arial',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
});

export default {
  fontCodes,
  getTypography,
  getfontFamily,
};
