export interface RawBaseKioskConfigurationEntity<CodeType = string> {
  id?: string;
  code?: CodeType;
  name?: string;
  description?: string;
}

export class BaseKioskConfigurationEntity<CodeType = string> {
  public id?: string;
  public code?: CodeType;
  public name?: string;
  public description?: string;

  constructor(raw: RawBaseKioskConfigurationEntity<CodeType>) {
    this.id = raw.id;
    this.code = raw.code;
    this.name = raw.name;
    this.description = raw.description;
  }
}
