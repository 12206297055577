import { createSelector } from 'reselect';
import Store from 'types/Store/EntitiesStore';

export const getEntitiesErrors = (state: Store) => state.entities.errors;

export const getInterfaceCashiers = (state: Store) =>
  state.entities.interfaceCashiers;

export const getDataFetching = (state: Store) => state.entities.dataFetching;

export const getAreEntitiesFetching = createSelector(
  getDataFetching,
  (dataFetching) => dataFetching.entity
);

export const isFetching = createSelector(getDataFetching, (dataFetching) =>
  Object.values(dataFetching).includes(true)
);
