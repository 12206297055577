import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Modal } from 'components';
import { compose } from 'redux';
import { Configurator } from 'utils';

import { Header as BasicHeader, Link } from '@ac/kiosk-components';

import { SupportedLanguagesSelector } from '@gss/components';

import './Header.scss';

interface PassedProps {
  title: string;
  className?: string;
  dataTestSelector?: string;
}

interface HeaderProps extends PassedProps, WithTranslation {}

interface HeaderState {
  isHelpModalOpen: boolean;
}

class Header extends PureComponent<HeaderProps, HeaderState> {
  public static defaultProps = {
    title: '',
  };

  public state = {
    isHelpModalOpen: false,
  };

  public render() {
    const { title, t, i18n, className, dataTestSelector } = this.props;
    const { isHelpModalOpen } = this.state;
    const SEPARATE_HEADER_BG = Configurator.switchCodes.SEPARATE_HEADER_BG;

    return (
      <BasicHeader
        className={classNames(
          'header-element',
          {
            'transparent-header': !Configurator.getSwitch(SEPARATE_HEADER_BG),
          },
          className
        )}
        dataTestSelector={dataTestSelector}
        logo={Configurator.logo}
        title={title}
      >
        <div className="settings-wrapper">
          <SupportedLanguagesSelector className="spacing-right-xlg" />
          <Link className="help-link" onClick={this.toggleHelpModal}>
            {t('NEED_HELP')}
          </Link>
        </div>
        <Modal
          isOpen={isHelpModalOpen}
          type="help"
          onClick={this.toggleHelpModal}
        />
      </BasicHeader>
    );
  }

  private toggleHelpModal = () => {
    const { isHelpModalOpen } = this.state;
    this.setState({ isHelpModalOpen: !isHelpModalOpen });
  };
}

export default compose(withTranslation())(Header) as (
  props: PassedProps
) => JSX.Element;
