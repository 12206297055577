var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"PDF.CHECK_OUT_FOLIO.CHARGES.TITLE",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":16,"column":48}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "      "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"TOTAL_BALANCE",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":6},"end":{"line":28,"column":30}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"total") || (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"total","hash":{},"data":data,"loc":{"start":{"line":35,"column":6},"end":{"line":35,"column":15}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-charges-wrapper .pdf-charges-balance {\n    display: flex;\n    align-items: center;\n    justify-content: end;\n    margin-top: var(--spacing-lg);\n  }\n</style>\n\n<div class=\"pdf-charges-wrapper\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-bottom-sm","weight":"bold","size":"lg"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/table.hbs"),depth0,{"name":"table","hash":{"tableData":(depth0 != null ? lookupProperty(depth0,"tableData") : depth0),"tableConfig":(depth0 != null ? lookupProperty(depth0,"tableConfig") : depth0),"className":"pdf-charges-table"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <div class=\"pdf-charges-balance\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"weight":"light","size":"sm"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    &nbsp;\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"weight":"bold","size":"sm"},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});