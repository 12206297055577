import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    formLabelRoot: {
      color: palette.text.primary,
      display: 'flex',
      alignItems: 'center',
    },
    formGroup: {
      maxHeight: 368,
      overflowY: 'scroll',
      paddingTop: spacing(0.5),
      paddingBottom: spacing(3),
      '&:after': {
        position: 'absolute',
        content: 'close-quote',
        boxShadow: '0 -15px 25px 15px white',
        height: 30,
        width: 'calc(100% - 38px)',
        bottom: 155,
      },
    },
    checkboxText: {
      paddingLeft: spacing(0.875),
    },
    checkboxWrapper: {
      marginBottom: spacing(2),
    },
  });
