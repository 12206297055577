import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { TypographyVariant } from 'types/MaterialUI';

import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './SegmentTitle.style';

interface SegmentTitleProps extends WithStyles<typeof styles> {
  title: string;
  [key: string]: any;
  className?: string;
}

class SegmentTitle extends PureComponent<SegmentTitleProps> {
  public static defaultProps = {
    title: '',
    className: '',
  };

  public render() {
    const { classes, title, className, ...props } = this.props;

    return (
      <div {...props}>
        <Typography
          variant={TypographyVariant.h6}
          className={classNames(classes.segmentTitle, className)}
        >
          {title}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(SegmentTitle);
