import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BUTTON_WIDTH = 44;
const BUTTON_HEIGHT = 44;
const ICON_WIDTH = 33;
const ICON_HEIGHT = 33;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    sliderButton: {
      width: BUTTON_WIDTH,
      height: BUTTON_HEIGHT,
      outline: 'none',
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: palette.common.white,
      borderRadius: 6,
      margin: spacing(2),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      minWidth: 0,
      boxShadow: 'none',
    },
    buttonIcon: {
      width: ICON_WIDTH,
      height: ICON_HEIGHT,
    },
    minibarPanelWrapper: {
      marginTop: spacing(6.25),
    },
  });
