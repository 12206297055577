import { AnyAction } from 'redux';
import { CallApiResponseAction } from 'store/middleware/api';
import Api from 'utils/Api';

import { LogReporter } from '@ac/kiosk-components';

import { LOG_MESSAGES, LOG_TYPES } from '@gss/configs/constants';

export const reportSelfServiceActionApiError = (
  action?: AnyAction,
  additionalData?: { [key: string]: unknown }
) => {
  if (!action) return;
  const { response = {} }: CallApiResponseAction = action;
  const { status, statusText, config, data } = response;

  LogReporter.log.error(
    LOG_TYPES.app,
    {
      message: LOG_MESSAGES.API_FAILURE,
      details: {
        action: action.type,
        status: status && statusText ? `${status} ${statusText}` : undefined,
        url: config?.url,
        errorMessages:
          data instanceof ArrayBuffer
            ? []
            : Api.getErrors(response)
                .map((error) => error.message && `\t${error.message}`)
                .filter(Boolean),
        ...additionalData,
      },
    },
    true
  );
};
