import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { TestEnvironmentBar, View as BasicView } from '@ac/kiosk-components';

import { getPropertyConfiguration } from '@gss/store/settings/selectors';

import './View.scss';

type ViewProps = React.ComponentProps<typeof BasicView>;

export const View = ({
  children,
  className,
  ...props
}: ViewProps): JSX.Element => {
  const propertyConfiguration = useSelector(getPropertyConfiguration);

  return (
    <BasicView
      {...props}
      className={classNames('gss-view-container', className)}
    >
      {propertyConfiguration?.nonProduction && (
        <TestEnvironmentBar dataTestSelector="view-test-environment-bar" />
      )}
      {children}
    </BasicView>
  );
};
