import {
  CodePathParam,
  getFactory,
  getFileFactory,
  listFactory,
} from '@ac/library-api';

import {
  BaseKioskConfigurationEntity,
  ImageSizeQueryParams,
  KioskConfiguration,
  KioskLocalizedTitlesEntryDetails,
  KioskStateDetails,
  RawBaseKioskConfigurationEntity,
  RawKioskConfiguration,
  RawKioskLocalizedTitlesEntryDetails,
  RawKioskStateDetails,
} from '../entries';

const path = {
  configuration: '/kiosk/v0/self-service/configuration',
  states: '/kiosk/v0/self-service/configuration/countries/{code}/states',
  districts: '/kiosk/v0/self-service/configuration/countries/{code}/districts',
  localizedTitles: '/kiosk/v0/self-service/configuration/titles',
  content: '/kiosk/v0/self-service/configuration/settings/{code}/content',
  contentImage:
    '/kiosk/v0/self-service/configuration/settings/{code}/content/image',
};

const ApiCacheConfigurationApi = 'KioskConfigurationApi';
export const ApiCacheKeyProperties = `${ApiCacheConfigurationApi}Properties`;
export const ApiCacheKeyDevices = `${ApiCacheConfigurationApi}Devices`;
export const ApiCacheKeyConfiguration = `${ApiCacheConfigurationApi}Configuration`;
export const ApiCacheKeyStates = `${ApiCacheConfigurationApi}States`;
export const ApiCacheKeyDistricts = `${ApiCacheConfigurationApi}Districts`;
export const ApiCacheKeyLocalizedTitles = `${ApiCacheConfigurationApi}LocalizedTitles`;
export const ApiCacheKeyRegistrationCard = `${ApiCacheConfigurationApi}RegistrationCard`;
export const ApiCacheKeyBusinessDate = `${ApiCacheConfigurationApi}BusinessDate`;

export const getConfiguration = getFactory<
  undefined,
  RawKioskConfiguration,
  KioskConfiguration
>({
  path: path.configuration,
  cacheKey: ApiCacheKeyConfiguration,
  ctor: KioskConfiguration,
});

export const getStates = listFactory<
  CodePathParam,
  RawKioskStateDetails,
  KioskStateDetails
>({
  path: path.states,
  cacheKey: ApiCacheKeyStates,
  ctor: KioskStateDetails,
});

export const getDistricts = listFactory<
  CodePathParam,
  RawBaseKioskConfigurationEntity,
  BaseKioskConfigurationEntity
>({
  path: path.districts,
  cacheKey: ApiCacheKeyDistricts,
  ctor: BaseKioskConfigurationEntity,
});

export const getLocalizedTitles = listFactory<
  undefined,
  RawKioskLocalizedTitlesEntryDetails,
  KioskLocalizedTitlesEntryDetails
>({
  path: path.localizedTitles,
  cacheKey: ApiCacheKeyLocalizedTitles,
  ctor: KioskLocalizedTitlesEntryDetails,
});

export const getContent = getFileFactory<CodePathParam>({
  path: path.content,
});

export const getContentImage = getFileFactory<
  CodePathParam,
  ImageSizeQueryParams
>({
  path: path.contentImage,
});
