import {
  DictionaryStatuses,
  LazyLoadedDictionaryState,
  StateChangeAction,
} from '../interfaces';

export const updateDictionaryStatusData = <
  StateType extends keyof LazyLoadedDictionaryState
>(
  state: LazyLoadedDictionaryState,
  action: StateChangeAction,
  stateType: StateType,
  status: DictionaryStatuses
): LazyLoadedDictionaryState => {
  const { key, data } = action;

  return {
    ...state,
    [stateType]: {
      ...state[stateType],
      [key]: {
        ...state[stateType]?.[key],
        status,
        data,
      },
    },
  };
};
