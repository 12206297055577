import i18next from 'i18next';

import template from './template.hbs';

const CONFIG = {
  tableConfig: {
    gridTemplateColumns:
      'auto 1fr minmax(80px, auto) minmax(30px, auto) minmax(80px, auto)',
    columns: [
      { header: 'DATE' },
      { header: 'DESCRIPTION' },
      { header: 'AMOUNT', justify: 'end' },
      {
        header: 'PDF.CHECK_OUT_FOLIO.CHARGES.HEADERS.QUANTITY',
        justify: 'end',
      },
      { header: 'TOTAL', justify: 'end' },
    ],
  },
};

interface TableDataProps {
  cells: [string, string, string, string, string];
}

interface ReservationDetails {
  guestName: string;
  company?: string;
  travelAgent?: string;
  individual?: string;
  address?: string;
  roomNumber: string;
  confirmationNumber: string;
}

export interface CheckOutFolioTemplateProps {
  logo?: string;
  period: string;
  tableData: TableDataProps[];
  reservationDetails: ReservationDetails;
  total: string;
  signature: string;
  dateTime: string;
}

export const checkOutFolioTemplate = (
  props: CheckOutFolioTemplateProps
): string => {
  return template({
    tableConfig: {
      ...CONFIG.tableConfig,
      columns: CONFIG.tableConfig.columns.map((columnSetting) => ({
        ...columnSetting,
        header: i18next.t(columnSetting.header),
      })),
    },
    ...props,
  });
};
