var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"TERMS_AND_CONDITIONS",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":13,"column":35}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"I_ACCEPT",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":23}}}))
    + ":\n";
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"pdf-consents-item spacing-bottom-xs\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"isGranted") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams),"inverse":container.program(8, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":24,"column":6},"end":{"line":28,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"sm","class":"spacing-left-sm"},"fn":container.program(10, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../../partials/icons/check.hbs"),depth0,{"name":"icons/check","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../../partials/icons/cancel.hbs"),depth0,{"name":"icons/cancel","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(container.lambda(((stack1 = blockParams[1][0]) != null ? lookupProperty(stack1,"description") : stack1), depth0))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-consents .pdf-consents-item {\n    display: flex;\n    align-items: center;\n  }\n</style>\n\n<div class=\""
    + container.escapeExpression(__default(require("../../../helpers/classNames.ts")).call(alias1,"pdf-consents",(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"classNames","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":47}}}))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"xlg","class":"spacing-bottom-xs"},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(require("../../../partials/hint.hbs"),depth0,{"name":"hint","hash":{"class":"spacing-bottom-sm"},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":22,"column":2},"end":{"line":37,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useBlockParams":true});