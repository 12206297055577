import { Component, PropsWithChildren } from 'react';

import {
  ErrorView,
  LogReporter,
  MagicButton,
  MagicButtonHorizontalPosition,
  MagicButtonVerticalPosition,
} from '@ac/kiosk-components';
import { Logger } from '@ac/library-api';

import { EnvironmentSettingsModal } from '@gss/components/modals';
import { LOG_MESSAGES, LOG_TYPES } from '@gss/configs/constants';

import './ErrorBoundary.scss';

interface ErrorBoundaryState {
  isUncatchedException: boolean;
  isServiceModalOpen: boolean;
}

export class ErrorBoundary extends Component<
  PropsWithChildren<unknown>,
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    isUncatchedException: false,
    isServiceModalOpen: false,
  };

  public componentDidMount(): void {
    window.addEventListener('unhandledrejection', this.reportReactError);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('unhandledrejection', this.reportReactError);
  }

  public componentDidCatch(error: Error): void {
    if (error) {
      this.setState({ isUncatchedException: true });
      this.reportReactError(error);
      Logger.forceSend();
    }
  }

  private reportReactError = (error: Error | PromiseRejectionEvent): void => {
    const isPromiseRejection = typeof error === 'object' && 'reason' in error;

    LogReporter.log.fatal(
      LOG_TYPES.app,
      {
        message: isPromiseRejection
          ? LOG_MESSAGES.REACT_UNHANDLED_REJECTION
          : LOG_MESSAGES.REACT_EXCEPTION,
        details: {
          message: isPromiseRejection
            ? (error.reason as Error)?.message
            : error?.message,
          location: window.location.pathname,
        },
      },
      true
    );
  };

  private toggleServiceModal = (): void => {
    // eslint-disable-next-line no-invalid-this
    const { isServiceModalOpen } = this.state;
    // eslint-disable-next-line no-invalid-this
    this.setState({ isServiceModalOpen: !isServiceModalOpen });
  };

  public render(): React.ReactNode {
    const { isUncatchedException, isServiceModalOpen } = this.state;

    return isUncatchedException ? (
      <>
        <MagicButton
          dataTestSelector="error-modal-magic-button"
          positionHorizontal={MagicButtonHorizontalPosition.left}
          positionVertical={MagicButtonVerticalPosition.top}
          width={90}
          height={90}
          pressTimeout={5000}
          onLongPress={this.toggleServiceModal}
          className="error-modal-magic-button"
        />

        {isServiceModalOpen && (
          <EnvironmentSettingsModal onCancelClick={this.toggleServiceModal} />
        )}

        <ErrorView dataTestSelector="error-view-placeholder" />
      </>
    ) : (
      this.props.children
    );
  }
}
