import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Configurator } from 'utils';

import { FormHeader, RadioButton } from '@ac/kiosk-components';
import {
  createRequiredValidator,
  Form,
  FormApi,
  formRadioButtonFieldFactory,
  FormSpy,
  SubmitResult,
} from '@ac/react-infrastructure';

import { getAvailableFolioStyles } from '@gss/_LEGACY_/store/cashiering/selectors';
import { FormValidator, mapRadioFieldRenderProps } from '@gss/utils/form';

import './FolioStyleTypeSelection.scss';

export interface FolioStyleTypeForm {
  folioType?: string | undefined;
}

const FormRadioButton = formRadioButtonFieldFactory<FolioStyleTypeForm>();

interface FolioStyleTypeSelectionProps {
  className?: string;
  formId?: string;
  currentFolioId?: string;
  initialFolioTypeCode?: string;
  onFolioStyleSelectSubmit: (
    values: FolioStyleTypeForm,
    formApi: FormApi<FolioStyleTypeForm>
  ) => SubmitResult<FolioStyleTypeForm>;
  onValidChange?: (state: { valid: boolean }) => void;
}

const FolioStyleTypeSelection = ({
  className,
  formId,
  currentFolioId,
  initialFolioTypeCode,
  onFolioStyleSelectSubmit,
  onValidChange,
}: FolioStyleTypeSelectionProps) => {
  const { t, i18n } = useTranslation();
  const availableFolioStyles = useSelector(getAvailableFolioStyles);

  const validator = useMemo(() => {
    return new FormValidator<FolioStyleTypeForm>({
      folioType: createRequiredValidator(t('')),
    });
  }, []);

  const folioStyleOptions = useMemo(() => {
    if (!currentFolioId) return [];

    const folioStylesForCurrentFolio = availableFolioStyles?.[currentFolioId];
    if (!folioStylesForCurrentFolio) return [];

    return folioStylesForCurrentFolio.map((folioStyle) => ({
      title: Configurator.getDescription(folioStyle.description),
      value: folioStyle.code,
    }));
  }, [currentFolioId]);

  const formInitialValues = useMemo(() => {
    const initialValueExists = folioStyleOptions.some(
      (style) => style.value === initialFolioTypeCode
    );

    return { folioType: initialValueExists ? initialFolioTypeCode : undefined };
  }, [initialFolioTypeCode, folioStyleOptions]);

  const fiscalDocumentHintMessage = useMemo(() => {
    const { CUSTOM_MESSAGE_FISCAL_DOCUMENT_TYPE } =
      Configurator.getTranslationCodes();

    return Configurator.getTranslation(CUSTOM_MESSAGE_FISCAL_DOCUMENT_TYPE);
  }, [i18n.language]);

  return (
    <div
      className={classNames('folio-style-type-selection-wrapper', className)}
    >
      <FormHeader
        className="folio-style-type-selection-header"
        title={t('CHECK_OUT_CHARGES.FOLIO_STYLE_SELECTION.TITLE')}
        hint={fiscalDocumentHintMessage}
      />

      <Form
        validate={validator.validate}
        initialValues={formInitialValues}
        onSubmit={onFolioStyleSelectSubmit}
      >
        {(formRenderProps) => (
          <>
            <FormSpy subscription={{ valid: true }} onChange={onValidChange} />
            <form
              id={formId}
              className={classNames('folio-style-type-selection-options', {
                'folio-style-type-selection-options-column':
                  folioStyleOptions?.length > 2,
              })}
              onSubmit={formRenderProps.handleSubmit}
            >
              {folioStyleOptions?.map((option) => (
                <FormRadioButton
                  key={option.value}
                  valuePath="folioType"
                  value={option.value}
                >
                  {(fieldRenderProps) => (
                    <RadioButton
                      {...mapRadioFieldRenderProps(fieldRenderProps)}
                      label={option.title}
                    />
                  )}
                </FormRadioButton>
              ))}
            </form>
          </>
        )}
      </Form>
    </div>
  );
};

export default FolioStyleTypeSelection;
