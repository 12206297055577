import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  AlignItems,
  Flex,
  FlexDirection,
  Text,
  TextWeight,
} from '@ac/kiosk-components';

import { LogoPlaceholder } from '@gss/assets/images';
import { getImages, getStyles } from '@gss/store/settings/selectors';

import './LargeHeader.scss';

interface LargeHeaderProps {
  dataTestSelector?: string;
  className?: string;
  firstLineMessage?: string;
  secondLineMessage?: string;
  backgroundImage?: string;
}

export const LargeHeader = ({
  dataTestSelector,
  className,
  firstLineMessage,
  secondLineMessage,
  backgroundImage,
}: LargeHeaderProps): JSX.Element => {
  const images = useSelector(getImages);
  const styles = useSelector(getStyles);

  const logo = images?.WELCOME_LOGO || LogoPlaceholder;
  const isTransparentBackground = !styles?.SEPARATE_HEADER_BG;

  return (
    <header
      className={classNames(
        'large-header-wrapper',
        {
          'large-header-wrapper-transparent':
            backgroundImage || isTransparentBackground,
        },
        className
      )}
      data-test-selector={dataTestSelector}
    >
      <div className="large-header-content spacing-vertical-auto">
        <Flex
          direction={FlexDirection.column}
          alignItems={AlignItems.flexStart}
        >
          <img
            alt="logo"
            src={logo}
            className="large-header-logo spacing-bottom-xlg"
          />
          {firstLineMessage && (
            <Text
              weight={TextWeight.light}
              className="large-header-description large-header-description-first-line"
            >
              {firstLineMessage}
            </Text>
          )}
          {secondLineMessage && (
            <Text
              weight={TextWeight.light}
              className="large-header-description large-header-description-second-line"
            >
              {secondLineMessage}
            </Text>
          )}
        </Flex>
      </div>
      {backgroundImage && (
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
          className="large-header-background-image"
        />
      )}
    </header>
  );
};
