import { Dispatch } from 'store/utils/actions';
import { ConfirmationFormValues } from 'views/CheckInConfirmation/types';

import types from './types';

export const cacheConfirmationFormData =
  (formData: ConfirmationFormValues) => (dispatch: Dispatch) => {
    return dispatch({
      type: types.CACHE_CONFIRMATION_FORM_DATA,
      payload: formData,
    });
  };

export const clearCacheConfirmationFormData = () => (dispatch: Dispatch) => {
  return dispatch({ type: types.CLEAR_CACHED_CONFIRMATION_FORM_DATA });
};
