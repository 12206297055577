import { createSelector } from 'reselect';
import { getCashieringErrors } from 'store/cashiering/selectors';
import {
  getFilesErrors,
  getPdfGenerationError,
  getUploadErrors,
} from 'store/files/selectors';

export const getErrors = createSelector(
  getPdfGenerationError,
  getUploadErrors,
  getCashieringErrors,
  getFilesErrors,
  (pdfGenerationError, uploadErrors, reservationErrors, filesErrors) =>
    [
      pdfGenerationError,
      ...uploadErrors,
      ...reservationErrors,
      ...filesErrors,
    ].filter((error) => !!error)
);
