import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      '& *': {
        outline: 'none',
      },
    },
  });
