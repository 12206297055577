import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { Button, ButtonPattern, IconTypes } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import styles from './EditorMenu.style';
import Slider from './Slider';

interface PassedProps {
  onChange: (value: number) => void;
  onReset: () => void;
  onFlipVertical: () => void;
  onFlipHorizontal: () => void;
  onRotateLeft: () => void;
  onRotateRight: () => void;
  value?: number;
}

interface EditorMenuProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  maxValue: number;
}

class EditorMenu extends PureComponent<EditorMenuProps> {
  public static defaultProps = {
    maxValue: 100,
  };

  public render() {
    const {
      classes,
      t,
      onChange,
      onReset,
      onFlipVertical,
      onFlipHorizontal,
      onRotateLeft,
      onRotateRight,
      value,
    } = this.props;

    return (
      <Grid className={classes.wrapper} container>
        <Grid item container className={classes.menuWrapper}>
          <Grid item className={classes.sidePanel}>
            <Grid>
              <Typography className={classes.title}>{t('ROTATE')}</Typography>
            </Grid>
            <Grid className={classes.buttonsWrapper}>
              <Button
                className={classes.iconButton}
                onClick={onRotateRight}
                pattern={ButtonPattern.secondary}
                icon={IconTypes.rotateRight}
              />
              <Button
                className={classes.iconButton}
                onClick={onRotateLeft}
                pattern={ButtonPattern.secondary}
                icon={IconTypes.rotateLeft}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.middlePanel}>
            <Typography className={classes.value}>
              {`${Math.round(value || 0)}°`}
            </Typography>
            <Grid item className={classes.sliderContainer}>
              <Slider onChange={onChange} value={value} />
            </Grid>
          </Grid>
          <Grid item className={classes.sidePanel}>
            <Grid className={classes.title}>
              <Typography>{t('FLIP')}</Typography>
            </Grid>
            <Grid className={classes.buttonsWrapper}>
              <Button
                className={classes.iconButton}
                pattern={ButtonPattern.secondary}
                onClick={onFlipHorizontal}
                icon={IconTypes.flipHorizontal}
              />
              <Button
                className={classes.iconButton}
                pattern={ButtonPattern.secondary}
                onClick={onFlipVertical}
                icon={IconTypes.flipVertical}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.resetText} onClick={onReset}>
          {t('RESET_TO_ZERO')}
        </Typography>
      </Grid>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles)
)(EditorMenu) as (props: PassedProps) => JSX.Element;
