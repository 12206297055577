export enum TypographyVariant {
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  body1 = 'body1',
  body2 = 'body2',
  caption = 'caption',
  button = 'button',
  overline = 'overline',
  srOnly = 'srOnly',
}

export enum ButtonVariant {
  contained = 'contained',
  outlined = 'outlined',
}
