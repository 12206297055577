import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Configurator } from 'utils';

import { Text, TextSize, TextWeight } from '@ac/kiosk-components';

import { sortDisplayElements } from '@gss/utils';

import ConsentCheckbox, {
  ConsentData,
} from './ConsentCheckbox/ConsentCheckbox';

import './TermsAndConditions.scss';

interface TermsAndConditionsProps {
  id?: string;
  dataTestSelector?: string;
  className?: string;
  disabledConsents: string[];
  onConsentLinkClick?: (consentId: string) => void;
}

const TermsAndConditions = ({
  id,
  dataTestSelector,
  className,
  disabledConsents,
  onConsentLinkClick,
}: TermsAndConditionsProps) => {
  const { t, i18n } = useTranslation();

  const consents = useMemo((): ConsentData[] => {
    return sortDisplayElements(Configurator.consents).map((consent) => {
      const isConsentCBP =
        consent.consentType?.code ===
        Configurator.consentTypeCodes.CROSS_BORDER;
      const isConsentDisabled = disabledConsents.includes(consent.id);

      return {
        id: consent.id,
        description: Configurator.getDescription(consent.description),
        disabled: isConsentDisabled,
        isConsentCBP,
      };
    });
  }, [i18n.language]);

  return (
    <div
      id={id}
      className={classNames('terms-and-conditions-component', className)}
      data-test-selector={dataTestSelector}
    >
      <Text size={TextSize.xlg} weight={TextWeight.light}>
        {t('TERMS_AND_CONDITIONS')}
      </Text>
      <Text className="spacing-top-sm spacing-bottom-md" hint>
        {t('BY_CLICKING_CONFIRM')}
      </Text>

      {consents && (
        <div className="terms-and-conditions-consents">
          {consents.map((consent) => (
            <ConsentCheckbox
              key={`checkbox-consent2-${consent.id}`}
              consent={consent}
              onConsentLinkClick={onConsentLinkClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TermsAndConditions;
