export default {
  testEnvironment: '#C30F98',
  white: '#fff',
  black: '#000',
  grey: {
    50: '#f2f2f2',
    75: '#eeeeee',
    100: '#dfdfdf',
    200: '#cacaca',
    300: '#b5b5b5',
    400: '#a5a5a5',
    500: '#959595',
    1000: '#333333',
  },
  secondary: {
    50: '#fff6e7',
    100: '#fee9c4',
    200: '#feda9d',
    300: '#fdcb76',
    400: '#fcc058',
    500: '#fcb53b',
  },
  primary: {
    50: '#e2e8ed',
    100: '#b6c5d3',
    200: '#869eb5',
    300: '#557797',
    400: '#126aba',
    500: '#0c3c6b',
  },
  error: {
    50: '#fae0e2',
    100: '#f2b3b6',
    200: '#e98086',
    300: '#e04d56',
    400: '#d92631',
    500: '#d2000d',
  },
  success: {
    50: '#f3faed',
    100: '#e1f2d1',
    200: '#d7f4bb',
    300: '#bae193',
    400: '#abda7c',
    500: '#9cd465',
    600: '#109128',
  },
  green: {
    50: '#e7f4e9',
    100: '#b7debe',
    200: '#9fd3a9',
    300: '#6fbd7e',
    400: '#279c3d',
    500: '#109128',
    600: '#095f1a',
  },
};
