import moment from 'moment';
import { Profile, ProfileMatchParamModel } from 'types/Api/Profile';
import DateManager, {
  API_MATCH_FULL_BIRTHDAY_FORMAT,
  API_MATCH_PARTIAL_BIRTHDAY_FORMAT,
} from 'utils/DateManager';

export function stringifyObjectData(
  data: ProfileMatchParamModel,
  parentPropertyName?: string
): string {
  return Object.entries(data).reduce((stringifiedData, [key, value]) => {
    if (!value) return stringifiedData;

    const propertyPath = parentPropertyName ? `${parentPropertyName}.` : '';
    let stringifiedEntry = '';

    if (value instanceof Object) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          stringifiedEntry += stringifyObjectData(
            item,
            `${propertyPath}${key}`
          );
        });
      } else {
        stringifiedEntry = stringifyObjectData(value, `${propertyPath}${key}`);
      }
    } else {
      stringifiedEntry = `${propertyPath}${key}:${value}`;
    }

    if (!stringifiedEntry) return stringifiedData;

    return stringifiedData
      ? `${stringifiedData}|${stringifiedEntry}`
      : stringifiedEntry;
  }, '');
}

function checkBitLength(value?: string, minLength: number = 2) {
  return new TextEncoder().encode(value).length >= minLength;
}

function deeplyFilterOutEmptyRecords<T>(data: T | T[keyof T]): Partial<T> {
  const dataEntries = Object.entries(data).map(([key, values]) => {
    if (values && typeof values === 'object' && !Array.isArray(values)) {
      return [key, deeplyFilterOutEmptyRecords(values)];
    }
    if (values && Array.isArray(values) && typeof values[0] === 'object') {
      return [key, values.map((value) => deeplyFilterOutEmptyRecords(value))];
    }

    return [key, values];
  });

  const filteredDataEntires = dataEntries.filter(([, value]) => {
    if (Array.isArray(value)) return value.filter(checkBitLength).length;
    if (value && typeof value === 'object') return Object.keys(value).length;

    return value && checkBitLength(value);
  });

  return Object.fromEntries(filteredDataEntires);
}

export function mapProfileDataForMatchRequest(
  profile: Profile
): ProfileMatchParamModel {
  const birthdayDate = profile.details.birthday;
  const formatedBirthdayDate =
    birthdayDate &&
    DateManager.getFormattedDate(
      moment({ ...birthdayDate, month: birthdayDate.month - 1 }),
      birthdayDate?.year
        ? API_MATCH_FULL_BIRTHDAY_FORMAT
        : API_MATCH_PARTIAL_BIRTHDAY_FORMAT
    );

  const matchingData = {
    details: {
      firstName: profile.details.firstName,
      lastName: profile.details.lastName,
      nationalityCode: profile.details.nationalityCode,
      companyName: profile.details.company,
      birthdayAnniversaryDate: formatedBirthdayDate,
      preferredLanguageProfileDetails: {
        localizedIndividualProfileDetails: {
          firstName: profile.details.preferredLanguageDetails?.firstName,
          lastName: profile.details.preferredLanguageDetails?.lastName,
        },
      },
    },
    addresses: profile.addresses.map((address) => ({
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      countryCode: address.countryCode,
      postalCode: address.postCode,
      stateCode: address.stateCode,
    })),
    communicationChannels: profile.communicationDetails.map((channel) => {
      const key = channel.mode.toLowerCase();

      return {
        [key]: channel.details,
      };
    }),
    externalSystemIdentifiers: [],
    documents: profile.identificationDocuments.map((document) => ({
      number: document.number,
    })),
    memberships: profile.memberships.map((membership) => ({
      number: membership.number,
    })),
  };

  return deeplyFilterOutEmptyRecords(matchingData);
}
