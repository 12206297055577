import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { LargeHeader } from '@gss/components/layout';
import {
  getCustomMessages,
  getImages,
  getStyles,
} from '@gss/store/settings/selectors';

import './WelcomeHeader.scss';

interface WelcomeHeaderProps {
  dataTestSelector?: string;
}

export const WelcomeHeader = ({
  dataTestSelector,
}: WelcomeHeaderProps): JSX.Element => {
  const customMessages = useSelector(getCustomMessages);
  const images = useSelector(getImages);
  const styles = useSelector(getStyles);

  const firstLineMessage = customMessages?.WELCOME_LINE_1;
  const secondLineMessage = customMessages?.WELCOME_LINE_2;
  const backgroundImage = images?.WELCOME_BACKGROUND_IMAGE;
  const isTransparentBackground =
    backgroundImage || !styles?.SEPARATE_WELCOME_HEADER_BG;

  return (
    <LargeHeader
      dataTestSelector={dataTestSelector}
      firstLineMessage={firstLineMessage}
      secondLineMessage={secondLineMessage}
      backgroundImage={backgroundImage}
      className={classNames(
        'welcome-header-element',
        'welcome-header-background',
        {
          'with-background': !isTransparentBackground,
          'transparent-background': isTransparentBackground,
        }
      )}
    />
  );
};
