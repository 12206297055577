import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const FONT_SIZE = '1rem';
const FONT_WEIGHT = 300;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    priceWrapper: {
      display: 'flex',
      marginTop: spacing(1),
      marginRight: spacing(2),
    },
    priceWrapperExpandable: {
      display: 'flex',
      marginRight: spacing(2),
      minWidth: 'fit-content',
    },
    priceWrapperCheckOut: {
      display: 'flex',
      marginTop: spacing(1 / 2),
      marginRight: spacing(1),
    },
    priceText: {
      fontWeight: FONT_WEIGHT,
      fontSize: FONT_SIZE,
      marginRight: spacing(1 / 2),
    },
    priceAmount: {
      fontSize: FONT_SIZE,
    },
  });
