import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BUTTON_WIDTH = 44;
const BUTTON_HEIGHT = 44;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    buttonContainer: {
      minWidth: BUTTON_WIDTH,
      height: BUTTON_HEIGHT,
      marginRight: spacing(1),
    },
  });
