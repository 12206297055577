import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { FolioTypeCode } from 'types/Api/Cashiering';
import { Configurator } from 'utils';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FolioStepperItem.style';

interface PassedProps {
  isDone?: boolean;
  isActive?: boolean;
  number?: number;
  type: FolioTypeCode;
}

interface FolioStepperItemProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class FolioStepperItem extends PureComponent<FolioStepperItemProps> {
  public render() {
    const { classes, isDone, isActive } = this.props;
    const iconClass = isDone ? classes.iconSuccess : classes.icon;
    const titleClass = isActive ? classes.titleActive : classes.title;

    return (
      <Grid className={classes.wrapper}>
        <Icon type={this.iconType} className={iconClass} />
        <Typography className={titleClass}>{this.title}</Typography>
      </Grid>
    );
  }
  private get iconType(): IconTypes {
    const { isDone } = this.props;
    if (isDone) return IconTypes.checkFilled;

    return this.stepData.icon;
  }

  private get stepData(): { icon: IconTypes; title: string } {
    const { type, t } = this.props;
    const { COMPANY, TRAVEL_AGENT } = Configurator.folioTypeCodes;
    switch (type) {
      case COMPANY:
        return {
          icon: IconTypes.company,
          title: t('COMPANY'),
        };
      case TRAVEL_AGENT:
        return {
          icon: IconTypes.travelAgent,
          title: t('TRAVEL_AGENT'),
        };
      default:
        return {
          icon: IconTypes.guest,
          title: t('PERSONAL'),
        };
    }
  }

  private get title() {
    const { number } = this.props;
    if (number) return `${this.stepData.title} ${number}`;

    return this.stepData.title;
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(FolioStepperItem) as (props: PassedProps) => JSX.Element;
