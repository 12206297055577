import { api } from 'config';
import { CALL_API } from 'store/middleware/api';

import types from './types';

export const fetchPropertyLocalDateTime = () => ({
  [CALL_API]: {
    endpoint: api.PROPERTY_MANAGEMENT.LOCAL_DATE_TIME,
    types: [
      types.FETCH_PROPERTY_LOCAL_DATE_TIME_REQUEST,
      types.FETCH_PROPERTY_LOCAL_DATE_TIME_SUCCESS,
      types.FETCH_PROPERTY_LOCAL_DATE_TIME_FAILURE,
    ],
  },
});

export const fetchCurrentDate = () => ({
  [CALL_API]: {
    endpoint: api.PROPERTY_MANAGEMENT.CURRENT_DATE,
    types: [
      types.FETCH_CURRENT_DATE_REQUEST,
      types.FETCH_CURRENT_DATE_SUCCESS,
      types.FETCH_CURRENT_DATE_FAILURE,
    ],
  },
});
