import { CallApiResponseAction } from 'store/middleware/api';

import { isDocumentVerificationError } from '../utils/isDocumentVerificationError';
import { isProcessingError } from '../utils/isProcessingError';

import CheckInIDScanningStore from './interfaces';
import types from './types';

const initialState: CheckInIDScanningStore = {
  dataFetching: {
    documentScanning: false,
  },
  errors: [],
  processingErrorCounter: 0,
  scannedDocument: undefined,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.DOCUMENT_SCAN_REQUEST:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          documentScanning: true,
        },
      };
    case types.DOCUMENT_SCAN_SUCCESS: {
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          documentScanning: false,
        },
        scannedDocument: action.payload,
      };
    }
    case types.DOCUMENT_SCAN_ERROR:
    case types.DOCUMENT_SCAN_FAILURE: {
      const isProcessError =
        !isDocumentVerificationError(action.payload) &&
        isProcessingError(action.payload);

      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          documentScanning: false,
        },
        errors: [...state.errors, action.payload],
        ...(isProcessError && {
          processingErrorCounter: state.processingErrorCounter + 1,
        }),
      };
    }

    case types.CLEAR_CHECK_IN_ID_SCANNING_ERRORS:
      return {
        ...state,
        errors: [],
      };

    case types.CLEAR_CHECK_IN_ID_SCANNING_DOCUMENT:
      return {
        ...state,
        scannedDocument: undefined,
      };

    default:
      return state;
  }
};
