import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormHeader } from 'components';
import { compose } from 'redux';
import {
  fetchCommunicationChannels,
  fetchCompanies,
} from 'store/profile/actions';
import { ChargesFolio } from 'types/Api/Cashiering';
import { CommunicationChannel } from 'types/Api/Profile';
import { Configurator } from 'utils';

import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import FolioStepper from '../FolioStepper';
import InvoiceDetailsPanel from '../InvoiceDetailsPanel';
import MinibarPanel from '../MinibarPanel';

import styles from './ChargesHeader.style';

interface PassedProps {
  onClick: () => void;
  folios: ChargesFolio[];
  currentFolioNumber: number;
  isProcessing: boolean;
  disableMinibar: boolean;
  communicationChannels: CommunicationChannel[];
  email: string;
}

interface ChargesHeaderProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  fetchCommunicationChannels: typeof fetchCommunicationChannels;
  fetchCompanies: typeof fetchCompanies;
}

class ChargesHeader extends PureComponent<ChargesHeaderProps> {
  public static defaultProps = {
    Icon: null,
    isProcessing: false,
    currentFolioNumber: 0,
    disableMinibar: false,
  };

  public async componentDidMount() {
    const { fetchCommunicationChannels, fetchCompanies } = this.props;
    await Promise.all([fetchCommunicationChannels(), fetchCompanies()]);
  }

  public render() {
    const {
      onClick,
      currentFolioNumber,
      t,
      folios,
      isProcessing,
      communicationChannels,
      email,
      classes,
    } = this.props;
    const currentFolio = folios[currentFolioNumber];
    const { profileName, address, profileId, remoteCheckOutAllowed } =
      currentFolio;
    const isPerformingPayment = isProcessing && remoteCheckOutAllowed;
    const title = isPerformingPayment
      ? t('COMPLETE_TERMINAL_PAYMENT')
      : this.title;
    const subtitle = isPerformingPayment ? t('FOLLOW_INSTRUCTIONS_SHORT') : '';

    return (
      <Grid container>
        <Grid item xs={isPerformingPayment ? 12 : 6}>
          <FolioStepper folios={folios} currentFolioId={currentFolio.id} />
          <FormHeader title={title} subtitle={subtitle} />
          {this.isMinibarVisible && (
            <Grid className={classes.minibarPanelWrapper}>
              <MinibarPanel
                onClick={onClick}
                disabled={currentFolio.isRecentlySettled}
              />
            </Grid>
          )}
        </Grid>

        {!isPerformingPayment && (
          <Grid item xs={6}>
            <InvoiceDetailsPanel
              name={profileName}
              profileAddress={address}
              communicationChannels={communicationChannels}
              email={email}
              folios={folios}
              currentFolioNumber={currentFolioNumber}
              profileId={profileId}
            />
          </Grid>
        )}
      </Grid>
    );
  }

  private get isMinibarVisible() {
    const { disableMinibar } = this.props;
    const { SHOW_MINIBAR } = Configurator.switchCodes;
    const isMinibarEnabled = Configurator.getSwitch(SHOW_MINIBAR);

    return (
      isMinibarEnabled &&
      Boolean(Configurator.minibarItems.length) &&
      !disableMinibar
    );
  }

  private get title() {
    const { t, folios, currentFolioNumber } = this.props;
    const currentFolio = folios[currentFolioNumber];
    const {
      folioTypeCode: { code },
    } = currentFolio;
    if (code === Configurator.folioTypeCodes.COMPANY)
      return t('REVIEW_COMPANY_CHARGES');
    if (code === Configurator.folioTypeCodes.TRAVEL_AGENT)
      return t('REVIEW_TRAVEL_AGENT_CHARGES');

    return t('REVIEW_PERSONAL_CHARGES');
  }
}

const mapDispatchToProps = {
  fetchCommunicationChannels,
  fetchCompanies,
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(ChargesHeader) as (props: PassedProps) => JSX.Element;
