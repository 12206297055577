import { api } from 'config';
import { CALL_API } from 'store/middleware/api';
import { getAccountId } from 'store/reservation/selectors';
import { Dispatch, getApiCallActionTypes, GetState } from 'store/utils/actions';

import { FETCH_FOLIO } from './types';

export const fetchFolio =
  (folioId: string) => (dispatch: Dispatch, getState: GetState) => {
    const accountId = getAccountId(getState());

    return dispatch({
      [CALL_API]: {
        endpoint: api.CASHIERING.FOLIO(accountId, folioId),
        payload: folioId,
        types: getApiCallActionTypes(FETCH_FOLIO),
      },
    });
  };
