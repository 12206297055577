import { CallApiResponseAction } from 'store/middleware/api';
import {
  singleLoadingFailure as failure,
  singleLoadingRequest as request,
  singleLoadingSuccess as success,
} from 'store/utils/reducer';
import { State } from 'types/Store/AvailabilityStore';

import types from './types';

const initialState: State = {
  purchaseItems: [],
  purchaseItemsAvailability: [],
  errors: [],
  isFetching: false,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_AVAILABLE_PURCHASE_ITEMS_REQUEST:
    case types.FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_REQUEST:
      return request(state);

    case types.FETCH_AVAILABLE_PURCHASE_ITEMS_SUCCESS: {
      const { results: purchaseItems } = action.response.data;

      return {
        ...success(state),
        purchaseItems,
      };
    }

    case types.FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_SUCCESS: {
      const purchaseItemsAvailability = action.response.data;

      return {
        ...success(state),
        purchaseItemsAvailability,
      };
    }

    case types.FETCH_AVAILABLE_PURCHASE_ITEMS_FAILURE:
    case types.FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_FAILURE:
      return failure(state, action);

    default:
      return state;
  }
};
