import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 16;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    title: {
      fontSize: '1.5rem',
      fontWeight: 300,
      width: 'auto',
      color: 'inherit',
      '&:not(:last-child)': {
        marginBottom: spacing(1),
      },
    },
    subtitle: {
      fontSize: '0.875rem',
      marginBottom: spacing(4),
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.HINT_FONT_COLOR,
        palette.text.secondary
      ),
    },
    formName: {
      fontSize: '1.5rem',
      color: 'inherit',
    },
    formNameWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: spacing(2),
    },
    icon: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      marginRight: spacing(1),
    },
    valid: {
      fill: 'var(--success-state-color)',
    },
    invalid: {
      fill: 'var(--error-state-color)',
    },
  });
