var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"GUEST_DETAILS",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":17,"column":28}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":25,"column":6},"end":{"line":25,"column":14}}}) : helper)))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"membership") || (depth0 != null ? lookupProperty(depth0,"membership") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"membership","hash":{},"data":data,"loc":{"start":{"line":30,"column":6},"end":{"line":30,"column":20}}}) : helper)))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../../../helpers/operators.ts")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"email") : stack1),"||",__default(require("../../../helpers/i18n.ts")).call(alias1,"NOT_PROVIDED",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":40},"end":{"line":41,"column":61}}}),{"name":"operators","hash":{},"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":63}}}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../../../helpers/operators.ts")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"mobile") : stack1),"||",__default(require("../../../helpers/i18n.ts")).call(alias1,"NOT_PROVIDED",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":50,"column":41},"end":{"line":50,"column":62}}}),{"name":"operators","hash":{},"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":50,"column":64}}}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"address") : stack1),{"name":"each","hash":{},"fn":container.program(12, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":57,"column":10},"end":{"line":64,"column":19}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-left-sm","size":"sm"},"fn":container.program(13, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data,blockParams) {
    return "              "
    + container.escapeExpression(container.lambda(blockParams[1][0], depth0))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-left-sm","size":"sm"},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "            "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"NOT_PROVIDED",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":70,"column":35}}}))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div class=\"pdf-guest-details-item\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/icons/taxCalculator.hbs"),depth0,{"name":"icons/taxCalculator","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-left-sm","size":"sm"},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(alias1,"TAX_ID_ADDITIONAL_INFO",__default(require("../../../helpers/object.ts")).call(alias1,{"name":"object","hash":{"taxId":((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"taxId") : stack1)},"data":data,"loc":{"start":{"line":82,"column":42},"end":{"line":82,"column":71}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":82,"column":10},"end":{"line":82,"column":73}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-guest-details-section-wrapper .pdf-guest-details-item {\n    display: flex;\n    align-items: center;\n    margin-top: var(--spacing-sm);\n  }\n\n  .pdf-guest-details-section-wrapper .pdf-reservation-details-subsection {\n    margin: var(--spacing-lg) var(--spacing-none);\n  }\n</style>\n\n<div class=\""
    + container.escapeExpression(__default(require("../../../helpers/classNames.ts")).call(alias1,"pdf-guest-details-section-wrapper",(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"classNames","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":68}}}))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"md"},"fn":container.program(1, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  <div class=\"pdf-reservation-details-subsection\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"weight":"bold","size":"xlg"},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"md"},"fn":container.program(5, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n\n  <div class=\"pdf-reservation-details-subsection\">\n    <div class=\"pdf-guest-details-item\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/icons/email.hbs"),depth0,{"name":"icons/email","data":data,"blockParams":blockParams,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-left-sm","size":"sm"},"fn":container.program(7, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"pdf-guest-details-item\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/icons/mobile.hbs"),depth0,{"name":"icons/mobile","data":data,"blockParams":blockParams,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-left-sm","size":"sm"},"fn":container.program(9, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"pdf-guest-details-item\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/icons/address.hbs"),depth0,{"name":"icons/address","data":data,"blockParams":blockParams,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../../helpers/operators.ts")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"address") : stack1),"&&",((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"address") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"operators","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":56,"column":14},"end":{"line":56,"column":71}}}),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams),"inverse":container.program(15, data, 0, blockParams),"data":data,"blockParams":blockParams,"loc":{"start":{"line":56,"column":8},"end":{"line":72,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"taxId") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":75,"column":4},"end":{"line":85,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true,"useBlockParams":true});