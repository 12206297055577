import { Suspense } from 'react';
import { Provider } from 'react-redux';

import { LoadingView } from '@ac/kiosk-components';

import { AppGlobalStateHandler, ErrorBoundary } from '@gss/components';
import { Router } from '@gss/router';
import { reduxStore } from '@gss/store';
import { App } from '@gss/views/App';
import { MuiProvider } from '@LEGACY/components';

export const AppContainer = (): JSX.Element => {
  return (
    <Provider store={reduxStore}>
      <Router>
        <Suspense fallback={<LoadingView description="Loading..." />}>
          <ErrorBoundary>
            <MuiProvider>
              <AppGlobalStateHandler>
                <App />
              </AppGlobalStateHandler>
            </MuiProvider>
          </ErrorBoundary>
        </Suspense>
      </Router>
    </Provider>
  );
};
