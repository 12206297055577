import { CallApiResponseAction } from 'store/middleware/api';
import {
  singleLoadingFailure as failure,
  singleLoadingRequest as request,
  singleLoadingSuccess as success,
} from 'store/utils/reducer';
import { State } from 'types/Store/HousekeepingStore';

import types from './types';

const initialState: State = {
  isFetching: false,
  errors: [],
  rooms: [],
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_AVAILABLE_ROOMS_REQUEST:
      return request(state);
    case types.FETCH_AVAILABLE_ROOMS_SUCCESS: {
      const { results } = action.response.data;
      const { rooms } = state;

      return {
        ...success(state),
        rooms: [...rooms, ...results],
      };
    }
    case types.FETCH_AVAILABLE_ROOMS_FAILURE:
      return failure(state, action);

    case types.CLEAR_HOUSEKEEPING_ERRORS:
      return {
        ...state,
        errors: [],
      };

    default:
      return state;
  }
};
