import { createSelector } from 'reselect';
import Store from 'types/Store';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';

import { selectDictionaryStatusData } from './utils/selectDictionaryStatusData';
import { StoredDictionaryStructure } from './interfaces';

export const getAllStateResults = (
  state: Store
): Record<string, KioskStateDetails[]> =>
  Object.fromEntries(
    Object.entries(state.lazyLoadedDictionary.states).map(([key, value]) => {
      return [key, value.data || []];
    })
  );

export const getAllDistrictResults = (
  state: Store
): Record<string, KioskStateDetails[]> =>
  Object.fromEntries(
    Object.entries(state.lazyLoadedDictionary.districts).map(([key, value]) => {
      return [key, value.data || []];
    })
  );

export const getSingleStateStore = (
  state: Store,
  countryCode: string | undefined
): StoredDictionaryStructure<KioskStateDetails[]> | undefined => {
  if (!countryCode) return undefined;

  return state.lazyLoadedDictionary.states?.[countryCode];
};

export const getStateStoreStatus = createSelector(
  [getSingleStateStore],
  selectDictionaryStatusData
);

export const getSingleDistrictStore = (
  state: Store,
  countryCode: string | undefined
): StoredDictionaryStructure<BaseKioskConfigurationEntity[]> | undefined => {
  if (!countryCode) return undefined;

  return state.lazyLoadedDictionary.districts?.[countryCode];
};

export const getDistrictStoreStatus = createSelector(
  [getSingleDistrictStore],
  selectDictionaryStatusData
);
