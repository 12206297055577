import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './PaymentResultDrawer.style';

interface PassedProps {
  isOpen: boolean;
  type?: 'success' | 'skip';
}

interface PaymentResultDrawerProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class PaymentResultDrawer extends PureComponent<PaymentResultDrawerProps> {
  public render() {
    const { classes, t, isOpen, type } = this.props;
    const isSuccess = type === 'success';
    const panelClass = isSuccess
      ? classes.detailsRootSuccess
      : classes.detailsRoot;
    const title = isSuccess
      ? t('PAYMENT_COMPLETE')
      : t('PAYMENT_WILL_NOT_BE_PROCESSED');

    return (
      <Accordion
        expanded={isOpen || !isSuccess}
        classes={{
          expanded: classes.panelExpanded,
        }}
      >
        <AccordionSummary
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expanded: classes.summaryExpanded,
          }}
        />
        <AccordionDetails classes={{ root: panelClass }}>
          {isSuccess && <CheckIcon classes={{ root: classes.iconRoot }} />}
          <Typography className={classes.title}>{title}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(PaymentResultDrawer) as (props: PassedProps) => JSX.Element;
