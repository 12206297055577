import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const wrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const TITLE_WIDTH = 366;

export default ({ spacing }: Theme) =>
  createStyles({
    bottomWrapper: {
      ...wrapper,
      flexDirection: 'row',
      width: '100%',
    },
    title: {
      fontSize: '1.5rem',
      textAlign: 'center',
      margin: '0 auto',
      marginBottom: spacing(2),
      width: TITLE_WIDTH,
    },
    content: {
      ...wrapper,
      minHeight: '100%',
      flex: 1,
    },
    emailInput: {
      width: '100%',
    },
    formWrapper: {
      marginBottom: spacing(1),
      width: '100%',
    },
  });
