import { api } from 'config';
import { CALL_API } from 'store/middleware/api';
import { Dispatch } from 'store/utils/actions';

import types from './types';

export const fetchRoomType = (roomTypeId: string) => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.ROOM_TYPE(roomTypeId),
    types: [
      types.FETCH_ROOM_TYPE_REQUEST,
      types.FETCH_ROOM_TYPE_SUCCESS,
      types.FETCH_ROOM_TYPE_FAILURE,
    ],
  },
});

export const fetchRoomDetails = (roomId: string) => ({
  [CALL_API]: {
    endpoint: api.CONFIGURATION.ROOM(roomId),
    types: [
      types.FETCH_ROOM_REQUEST,
      types.FETCH_ROOM_SUCCESS,
      types.FETCH_ROOM_FAILURE,
    ],
  },
});

export const clearRoomErrors = () => ({
  type: types.CLEAR_ROOM_ERRORS,
});

export const fetchRoomList = (roomIds: string[]) => (dispatch: Dispatch) => {
  const filterQuery = roomIds.map((id) => `id==${id}`).join(',');

  return dispatch({
    [CALL_API]: {
      endpoint: `${api.CONFIGURATION.ROOM_BASE}?filter=(${filterQuery})`,
      types: [
        types.FETCH_ROOM_LIST_REQUEST,
        types.FETCH_ROOM_LIST_SUCCESS,
        types.FETCH_ROOM_LIST_FAILURE,
      ],
    },
  });
};
