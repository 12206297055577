import { Action } from 'redux-actions';

import { ApiError } from '@ac/library-api';

import { ReduxReducerMap } from '@gss/types/shared';

import { handleGlobalReducer } from '../storeConfiguration/utils';

import * as actions from './actions';
import { MainProcessState } from './interfaces';

export const initialState: MainProcessState = {
  fetching: {
    initializeMainProcess: false,
  },
  errors: [],
  isMainProcessInitialized: false,
  isAppInactive: false,
  isOffline: false,
};

const reducerMap: ReduxReducerMap<MainProcessState> = {
  [actions.setIsOfflineStatus]: (
    state,
    action: Action<boolean>
  ): MainProcessState => {
    return {
      ...state,
      isOffline: action.payload,
    };
  },

  [actions.setIsAppInactive]: (
    state,
    action: Action<boolean>
  ): MainProcessState => {
    return {
      ...state,
      isAppInactive: action.payload,
    };
  },

  [actions.initializeMainProcess.trigger]: (state): MainProcessState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        initializeMainProcess: true,
      },
      isMainProcessInitialized: true,
    };
  },

  [actions.initializeMainProcess.success]: (state): MainProcessState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        initializeMainProcess: false,
      },
    };
  },

  [actions.initializeMainProcess.failure]: (
    state,
    action: Action<ApiError | Error>
  ): MainProcessState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        initializeMainProcess: false,
      },
      errors: [...state.errors, action.payload],
    };
  },
};

export const mainProcess = handleGlobalReducer<MainProcessState>(
  reducerMap,
  initialState
);
