import moment from 'moment';
import { Configurator } from 'utils';
/**
 * Checks if there was inserted any value.
 * @param {string} value Value to be validated.
 * @returns {string} Error text message.
 */
export function required(value: string) {
  return value ? undefined : 'FIELD_IS_MANDATORY';
}

/**
 * Checks if inserted value is in correct form
 * @param {string} value Value to be validated.
 * @returns {string} Error text message.
 */
export function number(value: string) {
  return value && !/^[0-9]*$/.test(value) ? 'CORRECT_NUMBER' : undefined;
}

/**
 * Checks if inserted value is in correct form
 * @param {string} value Value to be validated.
 * @returns {string} Error text message.
 */
export function confirmationNumber(value: string) {
  return value && !/^[a-zA-Z0-9_-]*$/.test(value)
    ? 'CORRECT_CONFIRMATION_NUMBER'
    : undefined;
}

/**
 * Checks if provided email is correct
 * @param {string} value Value to be validated.
 * @returns {string} Error text message.
 */
export function email(value: string) {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value)
    ? 'EMAIL_IS_INCORRECT'
    : undefined;
}

/**
 * Checks if inserted Date is valid
 * @param {Date} value Value to be validated.
 * @returns {string} Error text message.
 */
export function date(value: Date) {
  const actual = moment(Configurator.propertyBusinessDate);
  const chosen = moment(value, Configurator.dateFormat.shortDateFormat);
  const dayDiff = actual.diff(chosen, 'days');

  return dayDiff > 0 ? 'INVALID_DATE' : undefined;
}

/**
 * Checks if form is correctly filled
 * @param {boolean} pristine value - it says if form was touched
 * @param {boolean} invalid value - it says if form values are invalid
 * @param {boolean} initialized value - it says if form was initialized
 * @returns {boolean} Error text message.
 */
export function isFormValid({
  pristine,
  invalid,
  initialized,
}: {
  pristine: boolean;
  invalid: boolean;
  initialized: boolean;
}) {
  return (!pristine || initialized) && !invalid;
}

export default {
  required,
  email,
};
