import i18next from 'i18next';
import { put, select, takeLatest } from 'redux-saga/effects';

import { LogReporter } from '@ac/kiosk-components';
import { Action } from '@ac/library-utils/dist/declarations';
import { LoginService } from '@ac/library-utils/dist/services';

import { LOG_MESSAGES, LOG_TYPES } from '@gss/configs/constants';
import { refreshConfigurationTask } from '@gss/store/backgroundTasks/actions';
import { getExternalSettings } from '@gss/store/settings/selectors';
import { SagasGenerator } from '@gss/types/shared';

import { changeAppLanguage } from '../configuration/actions';
import { ExternalSettingsState } from '../settings/interfaces/settings/externalSettingsState';

import * as actions from './actions';

export function* handleConnectionStateChange(
  action: Action<boolean>
): SagasGenerator {
  const isOffline = action.payload;

  const connectionChangeMessage = isOffline
    ? LOG_MESSAGES.CONNECTION_LOST_LOG_MESSAGE
    : LOG_MESSAGES.CONNECTION_RECEIVED_LOG_MESSAGE;

  if (!isOffline) {
    if (!LoginService.isTokenRefreshScheduled()) {
      yield LoginService.refreshToken();
    }
  }

  LogReporter.log.warning(
    LOG_TYPES.app,
    {
      message: connectionChangeMessage,
    },
    true
  );
}

export function* handleInitializeMainProcess(): SagasGenerator {
  try {
    const externalSettings: ExternalSettingsState | undefined = yield select(
      getExternalSettings
    );

    const defaultLanguage =
      externalSettings?.LANGUAGE.languageCode.toLowerCase();

    if (defaultLanguage) {
      if (defaultLanguage !== i18next.language.toLowerCase()) {
        yield put(
          changeAppLanguage.trigger({
            language: defaultLanguage,
          })
        );
      }
    }

    yield put(refreshConfigurationTask.start());
    yield put(actions.initializeMainProcess.success());
  } catch (error) {
    yield put(actions.initializeMainProcess.failure(error));
  }
}

export function* mainProcessSagas(): SagasGenerator {
  yield takeLatest(actions.setIsOfflineStatus, handleConnectionStateChange);
  yield takeLatest(
    actions.initializeMainProcess.trigger,
    handleInitializeMainProcess
  );
}
