import { useTranslation } from 'react-i18next';

import { Flex, FlexDirection, Table } from '@ac/kiosk-components';

import { SelfServiceDeviceDto } from '@gss/api/KioskApi/entries';

import { ValidationInfo, ValidationInfoType } from '../ValidationInfo';

interface ValidationDeviceInfoRowProps {
  device: SelfServiceDeviceDto;
}

export const ValidationDeviceInfoRow = ({
  device,
}: ValidationDeviceInfoRowProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Table.Row>
      <Table.Cell>{device.deviceCode}</Table.Cell>
      <Table.Cell>{device.deviceName}</Table.Cell>
      <Table.Cell>
        <Flex direction={FlexDirection.column} className="gap-sm">
          <>
            {device.isOnline && (
              <ValidationInfo
                content={t('VIEWS.MISSING_ON_DEVICES.REASONS.DEVICE_IS_IN_USE')}
                type={ValidationInfoType.warn}
              />
            )}

            {device.issues?.map(({ message }, index) => (
              <ValidationInfo
                key={`${device.deviceId}-${index}`}
                content={message}
                type={ValidationInfoType.error}
              />
            ))}
          </>
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};
