import { CallApiResponseAction } from 'store/middleware/api';
import {
  singleLoadingFailure as failure,
  singleLoadingRequest as request,
  singleLoadingSuccess as success,
} from 'store/utils/reducer';
import { PurchaseElementImages } from 'types/Api/RateManager';
import { State } from 'types/Store/RateManagerStore';
import { Configurator } from 'utils';

import types from './types';

const initialState: State = {
  purchaseElements: [],
  errors: [],
  images: {},
  isFetching: false,
  ratePlans: [],
};

const addImage = (
  images: PurchaseElementImages,
  id: string,
  itemImages: Array<{ id: string }>
) => {
  const itemImagesIds = itemImages.map((image) => image.id);
  const objectIds = Object.keys(images);
  if (!objectIds.includes(id)) return { ...images, [id]: [...itemImagesIds] };
  const updatedImages: any = new Set([...images[id], ...itemImagesIds]);

  return {
    ...images,
    [id]: [...updatedImages],
  };
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_PURCHASE_ELEMENTS_REQUEST:
      return request(state);
    case types.FETCH_PURCHASE_ELEMENTS_SUCCESS: {
      const { results } = action.response.data;
      Configurator.savePurchaseElements(results);

      return {
        ...success(state),
        purchaseElements: results,
      };
    }
    case types.FETCH_PURCHASE_ELEMENTS_FAILURE:
      return failure(state, action);

    case types.FETCH_PURCHASE_ELEMENT_IMAGES_REQUEST:
      return request(state);
    case types.FETCH_PURCHASE_ELEMENT_IMAGES_SUCCESS: {
      const { data } = action.response;
      const { payload: id } = action;
      const { images } = state;

      return {
        ...success(state),
        images: addImage(images, id, data),
      };
    }
    case types.FETCH_PURCHASE_ELEMENT_IMAGES_FAILURE:
      return failure(state, action);

    case types.FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_REQUEST:
      return request(state);
    case types.FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_SUCCESS:
      return success(state);
    case types.FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_FAILURE:
      return failure(state, action);

    case types.FETCH_AGE_BUCKETS_REQUEST:
      return request(state);
    case types.FETCH_AGE_BUCKETS_SUCCESS: {
      const { results } = action.response.data;
      Configurator.saveAgeBuckets(results);

      return {
        ...success(state),
      };
    }
    case types.FETCH_AGE_BUCKETS_FAILURE:
      return failure(state, action);

    case types.FETCH_RATE_PLANS_REQUEST:
      return request(state);
    case types.FETCH_RATE_PLANS_SUCCESS: {
      const { results } = action.response.data;

      return {
        ...success(state),
        ratePlans: results,
      };
    }
    case types.FETCH_RATE_PLANS_FAILURE:
      return failure(state, action);

    default:
      return state;
  }
};
