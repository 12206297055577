import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Body, Loader } from '@ac/kiosk-components';

import OverlayView from '../OverlayView';

import './ExternalLinkOverlay.scss';

interface ExternalLinkOverlayProps {
  href: string;
  onClose?: () => void;
}

const ExternalLinkOverlay = ({ href, onClose }: ExternalLinkOverlayProps) => {
  const [t] = useTranslation();
  const [isLoading, setLoadingState] = useState(true);

  const handleIframeLoad = useCallback(() => {
    setLoadingState(false);
  }, [setLoadingState]);

  return (
    <OverlayView
      title={t('COMPONENTS.OVERLAYS.EXTERNAL_LINK.TITLE')}
      hasConfirmButton
      confirmButtonLabel={t('CLOSE')}
      onConfirmClick={onClose}
    >
      <Body className="external-link-overlay-body">
        {isLoading && (
          <div className="external-link-overlay-loader-wrapper">
            <Loader />
          </div>
        )}
        <iframe
          onLoad={handleIframeLoad}
          className="external-link-overlay-iframe"
          src={href}
          frameBorder="0"
          title={t('COMPONENTS.OVERLAYS.EXTERNAL_LINK.TITLE')}
        />
      </Body>
    </OverlayView>
  );
};

export default ExternalLinkOverlay;
