import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Footer } from 'components';
import { compose } from 'redux';
import {
  updateFolioProfileDetails,
  updateFolioStyleCode,
} from 'store/cashiering/actions';
import { ChargesFolio } from 'types/Api/Cashiering';
import { CommunicationChannel } from 'types/Api/Profile';
import { Configurator } from 'utils';

import {
  Body,
  Section,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { getAvailableFolioStyles } from '@gss/_LEGACY_/store/cashiering/selectors';
import Store from '@gss/_LEGACY_/types/Store';
import { submitFormById } from '@gss/utils/form';

import FolioStepper from '../FolioStepper';
import {
  FolioStyleTypeForm,
  FolioStyleTypeSelection,
} from '../FolioStyleTypeSelection';
import InvoiceDetailsPanel from '../InvoiceDetailsPanel';
import PaymentResultDrawer from '../PaymentResultDrawer';

import './PaymentConfirmationScreen.scss';

const FOLIO_STYLE_TYPE_SELECTION_ID = 'FOLIO_STYLE_TYPE_SELECTION_ID';

interface PassedProps {
  folios: ChargesFolio[];
  email: string;
  onConfirmationScreenContinue: () => void;
  currentFolioNumber: number;
  communicationChannels: CommunicationChannel[];
  withPaymentResult?: boolean;
}

interface PaymentConfirmationScreenState {
  isConfirmDisabled: boolean;
  isTaxIdChanged: boolean;
  newTaxId: string | undefined;
}

interface PaymentConfirmationScreenProps extends PassedProps, WithTranslation {
  updateFolioStyleCode: typeof updateFolioStyleCode;
  updateFolioProfileDetails: typeof updateFolioProfileDetails;
  availableFolioStyles: ReturnType<typeof getAvailableFolioStyles>;
}

class PaymentConfirmationScreen extends PureComponent<
  PaymentConfirmationScreenProps,
  PaymentConfirmationScreenState
> {
  public static defaultProps = {
    currentFolioNumber: 0,
    withPaymentResult: true,
  };

  public state = {
    isConfirmDisabled: this.isFolioTypeSelectionAvailable,
    isTaxIdChanged: false,
    newTaxId: undefined,
  };

  public render() {
    const {
      t,
      folios,
      email,
      currentFolioNumber,
      communicationChannels,
      withPaymentResult,
    } = this.props;

    const { profileName, address, id, folioStyleCode } =
      folios[currentFolioNumber];

    return (
      <>
        {withPaymentResult ? (
          <PaymentResultDrawer isOpen type="success" />
        ) : null}
        <Body>
          <Section className="payment-confirmation-screen-content-wrapper">
            <div className="payment-confirmation-screen-information spacing-top-sm">
              <FolioStepper folios={folios} currentFolioId={id} />
              {this.isFolioTypeSelectionAvailable ? (
                <FolioStyleTypeSelection
                  currentFolioId={id}
                  initialFolioTypeCode={folioStyleCode?.code}
                  formId={FOLIO_STYLE_TYPE_SELECTION_ID}
                  onValidChange={this.onFolioStyleTypeValidityChange}
                  onFolioStyleSelectSubmit={this.handleFormSubmit}
                />
              ) : (
                <Text size={TextSize.xlg} weight={TextWeight.light}>
                  {t('GET_INVOICE_AFTER_CHECKOUT')}
                </Text>
              )}
            </div>

            <InvoiceDetailsPanel
              name={profileName}
              profileAddress={address}
              communicationChannels={communicationChannels}
              email={email}
              folios={folios}
              currentFolioNumber={currentFolioNumber}
              isPaymentConfirmationScreen
              onTaxIdChange={this.handleTaxIdChange}
            />
          </Section>
        </Body>
        <Footer
          onContinue={
            this.isFolioTypeSelectionAvailable
              ? submitFormById(FOLIO_STYLE_TYPE_SELECTION_ID)
              : this.handleContinueClick
          }
          isOnContinuePrior
          hasCancelButton
          hasContinueButton
          routeName={t('CHECK_OUT')}
          continueButtonLabel={t('CONFIRM')}
          isContinueDisabled={this.state.isConfirmDisabled}
        />
      </>
    );
  }

  private get currentFolio() {
    const { currentFolioNumber, folios } = this.props;
    if (!folios || !isDefined(currentFolioNumber)) {
      return undefined;
    }

    return folios[currentFolioNumber];
  }

  private get folioStylesForCurrentFolio() {
    const { availableFolioStyles } = this.props;
    const currentFolioId = this.currentFolio?.id || '';

    return availableFolioStyles?.[currentFolioId];
  }

  private get isFolioTypeSelectionAvailable() {
    return (
      Configurator.getSwitch(
        Configurator.switchCodes.SHOW_FISCAL_DOCUMENT_SELECTION
      ) && this.folioStylesForCurrentFolio.length > 1
    );
  }

  private onFolioStyleTypeValidityChange = ({ valid }: { valid: boolean }) => {
    if (this.state.isConfirmDisabled === !valid) return;
    this.setState({ isConfirmDisabled: !valid });
  };

  private handleTaxIdChange = (taxId: string | undefined) => {
    this.setState({ newTaxId: taxId, isTaxIdChanged: true });
  };

  private updateTaxId = async () => {
    const { updateFolioProfileDetails } = this.props;
    const { newTaxId } = this.state;
    if (!this.currentFolio) return;
    const profile = this.currentFolio.profile;
    if (!profile?.id || profile.details?.taxId === newTaxId) return;

    await updateFolioProfileDetails(this.currentFolio.id, profile?.id, {
      taxId: newTaxId || null,
    });
  };

  private handleFormSubmit = async (values: FolioStyleTypeForm) => {
    const { onConfirmationScreenContinue } = this.props;
    const { isTaxIdChanged } = this.state;
    if (this.currentFolio && isTaxIdChanged) {
      await this.updateTaxId();
    }
    await this.updateFolioStyle(values.folioType);
    await onConfirmationScreenContinue();
  };

  private handleContinueClick = async () => {
    const { onConfirmationScreenContinue } = this.props;
    const { isTaxIdChanged } = this.state;
    const folioStyleCode = this.folioStylesForCurrentFolio?.[0]?.code;
    const isDocumentSelectionEnabled = Configurator.getSwitch(
      Configurator.switchCodes.SHOW_FISCAL_DOCUMENT_SELECTION
    );

    if (this.currentFolio && isTaxIdChanged) {
      await this.updateTaxId();
    }
    if (isDocumentSelectionEnabled) {
      await this.updateFolioStyle(folioStyleCode);
    }

    await onConfirmationScreenContinue();
  };

  private updateFolioStyle = async (folioStyleCode?: string) => {
    if (!folioStyleCode) {
      return;
    }
    const { folios, currentFolioNumber, updateFolioStyleCode } = this.props;
    const { id } = folios[currentFolioNumber];

    return updateFolioStyleCode(id, folioStyleCode);
  };
}

const mapStateToProps = (state: Store) => ({
  availableFolioStyles: getAvailableFolioStyles(state),
});

const mapDispatchToProps = {
  updateFolioStyleCode,
  updateFolioProfileDetails,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentConfirmationScreen) as (props: PassedProps) => JSX.Element;
