import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Body, Footer, Header, View } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { getFormValues, submit } from 'redux-form';
import {
  addBillingInstruction,
  addCompanyFolio,
  applyBillingInstruction,
  fetchFolios,
  fetchFolioTransactions,
  fetchPaymentMethods,
  fullCheckOut,
  getApplyBillingInstructionStatus,
  linkPaymentMethod,
  transferTransactions,
  updateFolioProfile,
} from 'store/cashiering/actions';
import {
  getActiveCompanyFolio,
  getActiveFolios,
  getBalancedFoliosToSettle,
  getBillingInstructions,
  getIndividualFolio,
  getNotBalancedFolios,
  getPaymentMethods,
} from 'store/cashiering/selectors';
import { getIsReviewChargesPossible } from 'store/checkOutProcess/selectors';
import {
  createCompany,
  fetchCreateCompanyStatus,
  linkCompanyToProfile,
} from 'store/profile/actions';
import { getNewCompanyId } from 'store/profile/selectors';
import { getReservation } from 'store/reservation/selectors';
import { getCompanyProfileIds, getErrors } from 'store/selectors';
import { ChargesFolio, Folio, PaymentMethod } from 'types/Api/Cashiering';
import { CompanyToCreate, LinkCompanyDataModel } from 'types/Api/Profile';
import { ReservationView } from 'types/Api/Reservation';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';

import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import {
  fullCheckOutInvoiceSubmit,
  lockCompanySelect,
  selectCompany,
  toggleCompanySelect,
} from './store/actions';
import { isCompanySelectLocked, isCompanySelectOpen } from './store/selectors';
import styles from './CheckOutPayment.style';
import CompanySelect from './CompanySelect';
import InvoiceForm from './InvoiceForm';

interface PassedProps {}

interface CheckOutPaymentProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  fullCheckOutInvoiceSubmit: typeof fullCheckOutInvoiceSubmit;
  selectCompany: typeof selectCompany;
  submit: typeof submit;
  createCompany: typeof createCompany;
  linkCompanyToProfile: typeof linkCompanyToProfile;
  addBillingInstruction: typeof addBillingInstruction;
  applyBillingInstruction: typeof applyBillingInstruction;
  updateFolioProfile: typeof updateFolioProfile;
  getApplyBillingInstructionStatus: typeof getApplyBillingInstructionStatus;
  addCompanyFolio: typeof addCompanyFolio;
  linkedCompanyIds: string[];
  billingInstructions: {
    targetFolioId: string;
    operationId: string;
  };
  companyFolio: Folio;
  individualFolio: Folio;
  folios: Folio[];
  reservation: ReservationView;
  newCompanyId: string;
  companyDetails: {
    fullName: string;
    taxId: string;
  };
  companyAddress: {
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    countryCode: string;
    stateCode: string;
    districtId?: string;
  };
  errors: ApiError[];
  fetchPaymentMethods: typeof fetchPaymentMethods;
  linkPaymentMethod: typeof linkPaymentMethod;
  fetchFolios: typeof fetchFolios;
  paymentMethods: PaymentMethod[];
  fetchFolioTransactions: typeof fetchFolioTransactions;
  transferTransactions: typeof transferTransactions;
  isCompanySelectOpen: boolean;
  isCompanySelectLocked: boolean;
  isReviewChargesPossible: boolean;
  toggleCompanySelect: typeof toggleCompanySelect;
  lockCompanySelect: typeof lockCompanySelect;
  fetchCreateCompanyStatus: typeof fetchCreateCompanyStatus;
  fullCheckOut: typeof fullCheckOut;
}

interface CheckOutPaymentState {
  isSubmitDisabled: boolean;
  isPanelExpanded: boolean;
  selectedCompanyId: string;
  isFormVisible: boolean;
  areMultipleFolios: boolean;
  isContinueLoading: boolean;
}

class CheckOutPayment extends PureComponent<
  CheckOutPaymentProps,
  CheckOutPaymentState
> {
  public state = {
    isSubmitDisabled: true,
    isPanelExpanded: false,
    selectedCompanyId: '',
    isFormVisible: false,
    areMultipleFolios: false,
    isContinueLoading: false,
  };

  public componentDidMount() {
    const { folios, lockCompanySelect } = this.props;
    if (folios.length > 1) lockCompanySelect();
  }

  public componentDidUpdate() {
    const { errors } = this.props;
    if (errors.length) {
      this.disableLoading();
    }
  }

  public render() {
    const { classes, t, errors, isCompanySelectOpen, isCompanySelectLocked } =
      this.props;
    const { isFormVisible, selectedCompanyId, isContinueLoading } = this.state;

    return (
      <View
        className={classes.view}
        idle={{ type: 'modal' }}
        modal={{ values: errors, isLoading: isContinueLoading }}
      >
        <Header title={`${t('CHECK_OUT')} - ${t('PAYMENT')}`} />
        <Body className={classes.body}>
          <Typography className={classes.title}>
            {t('GET_INVOICE', { routeName: t('CHECK_OUT') })}
          </Typography>
          {isFormVisible ? (
            <InvoiceForm
              setSubmitState={this.setSubmitState}
              onSubmit={this.onSubmit}
              selectedCompanyId={selectedCompanyId}
            />
          ) : (
            <CompanySelect
              onToggle={this.toggleSelectExpansionState}
              onSelect={this.onCompanySelect}
              isExpanded={isCompanySelectOpen}
              isLocked={isCompanySelectLocked}
            />
          )}
        </Body>
        <Footer
          hasContinueButton
          hasBackButton={isFormVisible}
          routeName={t('CHECK_OUT')}
          onGoBack={this.onGoBack}
          onContinue={this.submit}
          isContinueDisabled={this.isContinueDisabled()}
        />
      </View>
    );
  }

  private onGoBack = async () => {
    const { selectCompany } = this.props;
    await selectCompany('');

    return this.setState({
      selectedCompanyId: '',
      isFormVisible: false,
      isSubmitDisabled: true,
    });
  };

  private disableLoading = () => {
    this.setState({ isContinueLoading: false });
  };

  private enableLoading = () => {
    this.setState({ isContinueLoading: true });
  };

  private setSubmitState = (isFormValid: boolean) =>
    this.setState({ isSubmitDisabled: !isFormValid });

  private onCompanySelect = async (companyId?: string) => {
    const { history, fullCheckOutInvoiceSubmit, fullCheckOut } = this.props;
    this.setState({
      selectedCompanyId: companyId || '',
      isFormVisible: !companyId,
    });
    const { selectCompany } = this.props;
    selectCompany(companyId || '');
    if (companyId) {
      this.enableLoading();
      await fullCheckOutInvoiceSubmit();
    }
    if (!this.props.isReviewChargesPossible) await fullCheckOut();
    if (companyId) history.push(Router.nextStepURL);
  };

  private toggleSelectExpansionState = () => {
    const { toggleCompanySelect, isCompanySelectOpen } = this.props;
    const { isPanelExpanded } = this.state;
    toggleCompanySelect();
    this.setState({ isPanelExpanded: !isPanelExpanded });
    if (isCompanySelectOpen) this.setState({ selectedCompanyId: '' });
  };

  private submit = async () => {
    const { history, isCompanySelectOpen } = this.props;
    const { selectedCompanyId } = this.state;
    if (!isCompanySelectOpen) return history.push(Router.nextStepURL);
    const { submit } = this.props;
    this.enableLoading();
    if (selectedCompanyId) return submit(ids.DETAILS_FORM);
    await this.onCompanyAdd();
    this.onSubmit();
  };

  private onCompanyAdd = async () => {
    const {
      companyDetails: { fullName, taxId },
      companyAddress: {
        addressLine1,
        addressLine2,
        city,
        countryCode,
        postalCode,
        stateCode,
        districtId,
      },
      createCompany,
      i18n,
    } = this.props;

    const data: CompanyToCreate = {
      communicationChannels: [],
      externalSystemIdentifiers: [],
      customFieldContainerJson: '{}',
      addresses: [
        {
          addressTypeCode: Configurator.defaultCompanyAddressType?.code,
          addressLine1,
          addressLine2,
          city,
          countryCode,
          postalCode,
          stateCode,
          districtId,
          isArPrimary: false,
          isPrimary: true,
          languageCode: i18n.language.toUpperCase(),
        },
      ],
      details: {
        fullName,
        taxId,
        businessSegmentCodes: [],
        corporateId: '',
        industryCodes: [],
      },
      typeCode: Configurator.getProfileTypeCode(
        Configurator.profileRoles.COMPANY
      ),
    };

    await createCompany(data);
    const {
      newCompanyId,
      linkCompanyToProfile,
      fetchCreateCompanyStatus,
      fullCheckOutInvoiceSubmit,
      reservation: { profileId },
    } = this.props;
    await fetchCreateCompanyStatus(newCompanyId);
    const companyLinkData: LinkCompanyDataModel = {
      profileId,
      profileRoleCode: Configurator.profileRoles.INDIVIDUAL,
    };
    await linkCompanyToProfile(newCompanyId, companyLinkData);
    await fullCheckOutInvoiceSubmit();
  };

  private onSubmit = async () => {
    const { history } = this.props;
    history.push(Router.nextStepURL);
  };
  private isContinueDisabled = () => {
    const { isCompanySelectOpen, isCompanySelectLocked } = this.props;
    const {
      isSubmitDisabled,
      isFormVisible,
      selectedCompanyId,
      isContinueLoading,
    } = this.state;
    const isCommpanyIdRequired = isFormVisible
      ? !selectedCompanyId
      : selectedCompanyId;
    const isNotCompleted = isCompanySelectLocked
      ? !selectedCompanyId || isFormVisible
      : isCommpanyIdRequired || isFormVisible;
    const isContinueDisabled =
      (isSubmitDisabled && isNotCompleted) ||
      (isCompanySelectOpen && !isFormVisible);

    return !!(isContinueDisabled || isContinueLoading);
  };
}

const mapStateToProps = (state: Store) => ({
  companyFolio: getActiveCompanyFolio(state),
  individualFolio: getIndividualFolio(state),
  billingInstructions: getBillingInstructions(state),
  companyDetails: getFormValues(ids.DETAILS_FORM)(state),
  companyAddress: getFormValues(ids.ADDRESS_FORM)(state),
  folios: getActiveFolios(state),
  newCompanyId: getNewCompanyId(state),
  linkedCompanyIds: getCompanyProfileIds(state),
  reservation: getReservation(state),
  errors: getErrors(state),
  paymentMethods: getPaymentMethods(state),
  isCompanySelectOpen: isCompanySelectOpen(state),
  isCompanySelectLocked: isCompanySelectLocked(state),
  isReviewChargesPossible: getIsReviewChargesPossible(state),
});

const mapDispatchToProps = {
  updateFolioProfile,
  addBillingInstruction,
  addCompanyFolio,
  fullCheckOutInvoiceSubmit,
  applyBillingInstruction,
  getApplyBillingInstructionStatus,
  linkCompanyToProfile,
  createCompany,
  submit,
  fetchPaymentMethods,
  linkPaymentMethod,
  fetchFolios,
  fetchFolioTransactions,
  transferTransactions,
  selectCompany,
  toggleCompanySelect,
  lockCompanySelect,
  fetchCreateCompanyStatus,
  fullCheckOut,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckOutPayment) as (props: PassedProps) => JSX.Element;
