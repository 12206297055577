import colors from 'config/colors';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const IMAGE_HEIGHT = 365;
const IMAGE_WIDTH = 365;
const MOBILE_IMAGE_MARGIN = -80;
const APP_BAR_HEIGHT = 51;
const ICON_SIZE = 24;

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    appBar: {
      alignItems: 'center',
      backgroundColor: colors.success[600],
      display: 'flex',
      flexDirection: 'row',
      height: APP_BAR_HEIGHT,
      justifyContent: 'center',
      paddingBottom: spacing(0.75),
      paddingLeft: spacing(1.25),
      paddingRight: spacing(2),
      paddingTop: spacing(0.75),
      position: 'relative',
      boxShadow: 'none',
      zIndex: 0,
    },
    image1: {
      maxHeight: IMAGE_HEIGHT,
      maxWidth: IMAGE_WIDTH,
      [breakpoints.down('sm')]: {
        marginLeft: MOBILE_IMAGE_MARGIN,
      },
    },
    image2: {
      maxHeight: IMAGE_HEIGHT,
      maxWidth: IMAGE_WIDTH,
      [breakpoints.down('sm')]: {
        marginRight: MOBILE_IMAGE_MARGIN,
      },
    },
    successful: {
      fontSize: '1.5rem',
      textAlign: 'center',
      color: palette.common.white,
    },
    icon: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      marginRight: spacing(1),
      color: palette.common.white,
    },
  });
