import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { ButtonVariant } from 'types/MaterialUI';
import { paths } from 'utils/Router';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './PaymentError.style';

interface PassedProps {
  onClick?: () => void;
  error?: string;
  customErrorCode?: string;
}

interface PaymentErrorProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class PaymentError extends PureComponent<PaymentErrorProps> {
  public static defaultProps = {
    customErrorCode: '',
  };

  public render() {
    const { classes, t, onClick } = this.props;

    return (
      <Grid className={classes.content}>
        <Typography className={classes.title}>
          {t('PAYMENT_NOT_FINISHED')}
        </Typography>
        <Typography className={classes.subtitle}>
          {t('SOMETHING_WENT_WRONG_PAYMENT')}
        </Typography>
        <Grid className={classes.bottomWrapper}>
          <Button onClick={this.handleCancel} pattern={ButtonPattern.secondary}>
            {t('CANCEL')}
          </Button>
          <Button onClick={onClick}>{t('TRY_AGAIN')}</Button>
        </Grid>
      </Grid>
    );
  }
  private handleCancel = () => {
    const { history } = this.props;
    history.push(paths.WELCOME);
  };
}

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles)
)(PaymentError) as (props: PassedProps) => JSX.Element;
