const START_FAST_CHECK_OUT_PROCESS = 'START_FAST_CHECK_OUT_PROCESS';

const EXECUTE_FAST_CHECK_OUT_PROCESS_REQUEST =
  'EXECUTE_FAST_CHECK_OUT_PROCESS_REQUEST';
const EXECUTE_FAST_CHECK_OUT_PROCESS_FINISHED =
  'EXECUTE_FAST_CHECK_OUT_PROCESS_FINISHED';

export default {
  START_FAST_CHECK_OUT_PROCESS,
  EXECUTE_FAST_CHECK_OUT_PROCESS_REQUEST,
  EXECUTE_FAST_CHECK_OUT_PROCESS_FINISHED,
};
