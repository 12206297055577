import { CallApiResponseAction } from 'store/middleware/api';
import {
  singleLoadingFailure as failure,
  singleLoadingRequest as request,
  singleLoadingSuccess as success,
} from 'store/utils/reducer';
import { State } from 'types/Store/PropertyManagementStore';
import { Configurator } from 'utils';

import types from './types';

const initialState: State = {
  localPropertyDateTime: '',
  businessDate: '',
  errors: [],
  isFetching: false,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_PROPERTY_LOCAL_DATE_TIME_REQUEST:
      return request(state);
    case types.FETCH_PROPERTY_LOCAL_DATE_TIME_SUCCESS: {
      const { localPropertyDateTime } = action.response.data;

      return {
        ...success(state),
        localPropertyDateTime,
        isFetching: false,
      };
    }
    case types.FETCH_PROPERTY_LOCAL_DATE_TIME_FAILURE:
      return failure(state, action);

    case types.FETCH_CURRENT_DATE_REQUEST:
      return request(state);
    case types.FETCH_CURRENT_DATE_SUCCESS: {
      const { businessDate } = action.response.data;
      Configurator.savePropertyBusinessDate(businessDate);

      return {
        ...success(state),
        businessDate,
        isFetching: false,
      };
    }
    case types.FETCH_CURRENT_DATE_FAILURE:
      return failure(state, action);
    default:
      return state;
  }
};
