export const objectUrlToBlob = async (
  objectUrl: string
): Promise<Blob | undefined> => {
  try {
    const response = await fetch(objectUrl);

    return response.blob();
  } catch {
    return undefined;
  }
};
