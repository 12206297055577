import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { ChargesFolio } from 'types/Api/Cashiering';

import { Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { WithStyles, withStyles } from '@material-ui/styles';

import FolioStepperItem from '../FolioStepperItem';

import styles from './FolioStepper.style';

interface PassedProps {
  folios: ChargesFolio[];
  currentFolioId: string;
}

interface FolioStepperProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class FolioStepper extends PureComponent<FolioStepperProps> {
  public static defaultProps = {
    folios: [],
  };

  public render() {
    const { classes, folios, currentFolioId } = this.props;

    return (
      folios.length > 1 && (
        <Grid className={classes.wrapper}>
          {folios.map((folio, index) => {
            const sameTypeFolios = folios.filter(
              (item) => item.folioTypeCode.code === folio.folioTypeCode.code
            );
            const folioNumber =
              sameTypeFolios.findIndex((item) => item.id === folio.id) + 1;

            return (
              <React.Fragment key={folio.id}>
                <FolioStepperItem
                  number={sameTypeFolios.length > 1 ? folioNumber : undefined}
                  isActive={folio.id === currentFolioId}
                  isDone={folio.isRecentlySettled}
                  type={folio.folioTypeCode.code}
                />
                {index + 1 !== folios.length && (
                  <ArrowForwardIcon classes={{ root: classes.icon }} />
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      )
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(FolioStepper) as (
  props: PassedProps
) => JSX.Element;
