import { colors } from 'config';
import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const DEFAULT_FILL_ACTIVE_COLOR = colors.green[600];
const DEFAULT_FILL_COLOR = colors.grey[300];
const {
  colorCodes: {
    COUNTER_BACKGROUND_COLOR,
    COUNTER_FINISHED_BACKGROUND_COLOR,
    COUNTER_BORDER_COLOR,
  },
} = Configurator;

const fillProperties: CSSProperties = {
  height: '100%',
  width: '100%',
  borderRadius: 100,
  boxSizing: 'border-box',
  border: '1px solid',
};

export default ({ spacing }: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
      boxSizing: 'border-box',
      '&:not(:first-child)': {
        paddingLeft: spacing(0.25),
      },
      '&:not(:last-child)': {
        paddingRight: spacing(0.25),
      },
      '& div': {
        transition: 'all .3s',
      },
    },
    fill: {
      ...fillProperties,
      borderColor: Configurator.getColor(COUNTER_BORDER_COLOR, 'transparent'),
      backgroundColor: Configurator.getColor(
        COUNTER_BACKGROUND_COLOR,
        DEFAULT_FILL_COLOR
      ),
    },
    fillActive: {
      ...fillProperties,
      borderColor: Configurator.getColor(COUNTER_BORDER_COLOR, 'transparent'),
      backgroundColor: Configurator.getColor(
        COUNTER_FINISHED_BACKGROUND_COLOR,
        DEFAULT_FILL_ACTIVE_COLOR
      ),
    },
  });
