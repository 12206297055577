import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getUsedDeviceDetails } from '@gss/store/configuration/selectors';
import { paths } from '@LEGACY/utils/Router';

export const requireDeviceConfiguration = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): FC<Props> => {
  const DeviceConfigurationValidator = (props: Props): JSX.Element => {
    const deviceConfiguration = useSelector(getUsedDeviceDetails);

    if (!deviceConfiguration) {
      return <Redirect to={paths.DEVICE_SETUP} />;
    }

    return <WrappedComponent {...props} />;
  };

  return DeviceConfigurationValidator;
};
