import React from 'react';
import { email as emailValidator } from 'utils/Validator';

import { Checkbox } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './EmailCheckbox.style';

interface PassedProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {};
  checked: boolean;
}

interface EmailCheckboxProps extends PassedProps, WithStyles<typeof styles> {}

const EmailCheckbox = (props: EmailCheckboxProps) => {
  const { classes, onChange, checked } = props;

  return (
    <Checkbox
      color="primary"
      value={emailValidator}
      checked={checked}
      classes={{ checked: classes.checkedIcon }}
      onChange={onChange}
      icon={<div className={classes.checkboxIcon} />}
    />
  );
};

export default withStyles(styles)(EmailCheckbox) as (
  props: PassedProps
) => JSX.Element;
