import { api } from 'config';
import { CALL_API } from 'store/middleware/api';
import { Dispatch, GetState } from 'store/utils/actions';

import { getAvailablePaymentInterfaces } from './selectors';
import types from './types';

export const fetchPaymentInterface = (id: string) => ({
  [CALL_API]: {
    endpoint: api.PAYMENT_INTERFACE.INTERFACE(id),
    types: [
      types.FETCH_PAYMENT_INTERFACE_REQUEST,
      types.FETCH_PAYMENT_INTERFACE_SUCCESS,
      types.FETCH_PAYMENT_INTERFACE_FAILURE,
    ],
  },
});

export const fetchAdapterConfigurations = () => ({
  [CALL_API]: {
    endpoint: api.PAYMENT_INTERFACE.ADAPTER_CONFIGURATIONS,
    types: [
      types.FETCH_ADAPTER_CONFIGURATIONS_REQUEST,
      types.FETCH_ADAPTER_CONFIGURATIONS_SUCCESS,
      types.FETCH_ADAPTER_CONFIGURATIONS_FAILURE,
    ],
  },
});

export const fetchAdapterConfiguration = (type: string, id: string) => ({
  [CALL_API]: {
    endpoint: api.PAYMENT_INTERFACE.ADAPTER_CONFIGURATION(type, id),
    types: [
      types.FETCH_ADAPTER_CONFIGURATION_REQUEST,
      types.FETCH_ADAPTER_CONFIGURATION_SUCCESS,
      types.FETCH_ADAPTER_CONFIGURATION_FAILURE,
    ],
  },
});

export const clearPropertyInterfaceErrors = () => ({
  type: types.CLEAR_PROPERTY_INTERFACE_ERRORS,
});

export const fetchPaymentInterfaceAdapter =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.FETCH_PAYMENT_INTERFACE_ADAPTER_REQUEST });
      await dispatch(fetchAdapterConfigurations());
      const availableInterfaces = getAvailablePaymentInterfaces(getState());
      await Promise.all(
        availableInterfaces.map((item) =>
          dispatch(fetchAdapterConfiguration(item.adapterType, item.id))
        )
      );

      return dispatch({ type: types.FETCH_PAYMENT_INTERFACE_ADAPTER_SUCCESS });
    } catch (error) {
      return dispatch({
        type: types.FETCH_PAYMENT_INTERFACE_ADAPTER_FAILURE,
        payload: error,
      });
    }
  };
