import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const ICON_SIZE = 45;

const TIMER_SIZE = 88;
const wrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export default ({ palette, spacing }: Theme) =>
  createStyles({
    button: {
      marginBottom: spacing(),
    },
    descriptionWrapper: {
      ...wrapper,
      whiteSpace: 'pre-wrap',
      width: '100%',
    },
    title: {
      fontSize: '1rem',
      marginBottom: spacing(2),
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    alertIcon: {
      color: 'var(--primary-color)',
    },
    content: {
      ...wrapper,
      minHeight: '100%',
      flex: 1,
    },
    alertWrapper: {
      position: 'relative',
      width: TIMER_SIZE,
      height: TIMER_SIZE,
      marginBottom: spacing(3),
    },
  });
