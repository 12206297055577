import { CallApiResponseAction } from 'store/middleware/api';
import { State } from 'types/Store/CheckInRoomStore';
import { Api } from 'utils';

import types from './types';

const initialState: State = {
  isFetchingAttributes: false,
  isFetchingImages: false,
  attributes: [],
  images: [],
  imageUrls: [],
  errors: [],
};

function sortImages(state: State, imageId: string, url: string) {
  const imageUrls = [...state.imageUrls];
  const image = state.images.find((image) => image.id === imageId);

  if (image && Number.isInteger(image.displaySequence)) {
    imageUrls[image.displaySequence] = url;
  } else {
    imageUrls.push(url);
  }

  return imageUrls;
}

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_ROOM_ATTRIBUTES_REQUEST:
      return {
        ...state,
        isFetchingAttributes: true,
      };
    case types.FETCH_ROOM_ATTRIBUTES_SUCCESS: {
      const { results: attributes } = action.response.data;

      return {
        ...state,
        attributes,
        isFetchingAttributes: false,
      };
    }
    case types.FETCH_ROOM_ATTRIBUTES_FAILURE: {
      const { errors } = state;

      return {
        ...state,
        errors: Api.getErrors(action.response, errors),
        isFetching: false,
      };
    }

    case types.FETCH_ROOM_IMAGES_REQUEST:
      return {
        ...state,
        isFetchingImages: true,
      };
    case types.FETCH_ROOM_IMAGES_SUCCESS: {
      const { data } = action.response;

      return {
        ...state,
        isFetchingImages: false,
        images: data,
        imageUrls: [],
      };
    }
    case types.FETCH_ROOM_IMAGES_FAILURE: {
      const { errors } = state;

      return {
        ...state,
        errors: Api.getErrors(action.response, errors),
        isFetchingImages: false,
      };
    }

    case types.FETCH_ROOM_IMAGE_CONTENT_REQUEST:
      return {
        ...state,
        isFetchingImages: true,
      };
    case types.FETCH_ROOM_IMAGE_CONTENT_SUCCESS: {
      const { data } = action.response;
      const { imageId } = action.payload;
      const imageUrls = sortImages(state, imageId, URL.createObjectURL(data));

      return {
        ...state,
        imageUrls,
        isFetchingImages: false,
      };
    }
    case types.FETCH_ROOM_IMAGE_CONTENT_FAILURE: {
      const { errors } = state;

      return {
        ...state,
        errors: Api.getErrors(action.response, errors),
        isFetchingImages: false,
      };
    }

    case types.FETCH_ROOM_TYPE_IMAGES_REQUEST:
      return {
        ...state,
        isFetchingImages: true,
      };
    case types.FETCH_ROOM_TYPE_IMAGES_SUCCESS: {
      const { data } = action.response;

      return {
        ...state,
        isFetchingImages: false,
        images: data,
        imageUrls: [],
      };
    }
    case types.FETCH_ROOM_TYPE_IMAGES_FAILURE:
      return {
        ...state,
        isFetchingImages: false,
      };

    case types.FETCH_ROOM_TYPE_IMAGE_CONTENT_REQUEST:
      return {
        ...state,
        isFetchingImages: true,
      };
    case types.FETCH_ROOM_TYPE_IMAGE_CONTENT_SUCCESS: {
      const { data } = action.response;
      const { imageId } = action.payload;
      const imageUrls = sortImages(state, imageId, URL.createObjectURL(data));

      return {
        ...state,
        imageUrls,
        isFetchingImages: false,
      };
    }
    case types.FETCH_ROOM_TYPE_IMAGE_CONTENT_FAILURE:
      return {
        ...state,
        isFetchingImages: false,
      };

    case types.CLEAR_ROOM_GALLERY_ERRORS:
      return {
        ...state,
        errors: [],
      };

    default:
      return state;
  }
};
