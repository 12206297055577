import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Body,
  Footer,
  FormHeader,
  Header,
  Modal,
  QrScannerButton,
  View,
} from 'components';
import { CheckNameDetailsModal } from 'components/modals';
import { ids } from 'config';
import { compose } from 'redux';
import { change, getFormValues, reset, submit } from 'redux-form';
import { clearState, fetchCheckInData } from 'store/actions';
import { fetchCashieringAccount } from 'store/cashiering/actions';
import { activeAuthorizations } from 'store/cashiering/authorization/selectors';
import { getReservationOutstandingDeposit } from 'store/cashiering/reservationOutstandingDeposit/selectors';
import {
  getActiveBillingInstructions,
  getAddons,
  getFolios,
  getPreAuthorizationAmount,
} from 'store/cashiering/selectors';
import { fetchAvailableRooms } from 'store/housekeeping/actions';
import { shouldReassignRoom } from 'store/housekeeping/selectors';
import { fetchProfile } from 'store/profile/actions';
import { getProfileDetails } from 'store/profile/selectors';
import {
  fetchCurrentDate,
  fetchPropertyLocalDateTime,
} from 'store/propertyManagement/actions';
import { getLocalPropertyDateTime } from 'store/propertyManagement/selectors';
import {
  assignRoom,
  fetchMultiRoomSegmentDetails,
  fetchReservation,
  fetchReservationCheckIn,
  getAssignRoomOperationStatus,
} from 'store/reservation/actions';
import {
  getAssignRoomOperation,
  getIsReservationShared,
  getMultiRoomSegment,
  getMultiRoomSegmentId,
  getMultiRoomWithNoConfirmationProvided,
  getNumberOfTries,
  getReservation,
  getReservationVersion,
  getSuggestedRoom,
  getTooMuchReservations,
  isMultiRoomReservation,
  isReservationDayUse,
  isRoomFixed,
} from 'store/reservation/selectors';
import { fetchRoomDetails } from 'store/room/actions';
import { getRoomType } from 'store/room/selectors';
import { getErrors } from 'store/selectors';
import { setIsIPad } from 'store/ui/actions';
import { AdditionalData, AuthorizationData } from 'types/Api/Reservation';
import Store from 'types/Store';
import { Configurator, DateManager, Router } from 'utils';
import { Path } from 'utils/Router';

import { RoomError } from '@gss/utils/errors';
import { withStyles } from '@material-ui/styles';

import AuthorizationForm from './AuthorizationForm';
import styles from './CheckInAuth.style';
import { configureCheckInRouting } from './routing';
import { CheckInAuthProps, CheckInAuthState, PassedProps } from './types';

const DEFAULT_CHECK_IN_BEFORE_ARRIVAL_TIME = 60;
const { CHECK_IN_BEFORE_ARRIVAL } = Configurator.numberValueCodes;
const { CHECK_IN_FAILED_MESSAGE, ROOM_NOT_READY_MESSAGE } =
  Configurator.getTranslationCodes();

const { SHOW_CONF_NUMBER, SHOW_DUE_DATE, SHOW_NO_OF_NIGHTS } =
  Configurator.switchCodes;

class CheckInAuth extends PureComponent<CheckInAuthProps, CheckInAuthState> {
  public static defaultProps = {
    reservation: {},
    reservationVersion: '',
  };

  public state = {
    roomAssignmentError: {
      message: '',
    },
    checkInTimeMessage: '',
    isQrCodeModalVisible: false,
    isSubmitEnabled: false,
    isLoading: false,
    disableAdditionalData: false,
    securityConfirmationFieldEnabled: false,
    isCheckNameDetailsOpen: false,
  };

  private onlySurname = Configurator.getSwitch(
    Configurator.switchCodes.CHECK_IN_BY_SURNAME_ONLY,
    true
  );

  private willUnmount = false;

  public componentDidMount() {
    this.clearState();
    this.assignCallbacks();
    this.checkVersion();
  }

  public componentWillUnmount() {
    this.willUnmount = true;
  }

  public render() {
    const { t, profileDetails } = this.props;
    const {
      roomAssignmentError,
      checkInTimeMessage,
      isLoading,
      isSubmitEnabled,
      disableAdditionalData,
      securityConfirmationFieldEnabled,
      isCheckNameDetailsOpen,
    } = this.state;

    return (
      <View idle={{ type: 'modal' }} modal={{ isLoading }}>
        <Modal
          isOpen={Boolean(roomAssignmentError.message)}
          onClick={this.onModalClick}
          type="error"
          defaultError={this.getDefaultError()}
          error={checkInTimeMessage}
          customErrorCode={this.customErrorCode}
        />

        {isCheckNameDetailsOpen && (
          <CheckNameDetailsModal
            firstName={profileDetails?.firstName}
            lastName={profileDetails?.lastName}
            onClose={this.toggleChangeNameModal}
            onChange={this.continueCheckInWithNameChange}
            onConfirm={this.continueCheckInProcess}
          />
        )}

        <Header title={t('CHECK_IN')} />
        <Body>
          <FormHeader
            title={t('USE_QR_CODE_SENT')}
            subtitle={t('FOLLOW_THE_INSTRUCTION')}
          />
          <QrScannerButton onSubmit={this.onSubmit} />
          <AuthorizationForm
            onSubmit={this.onSubmit}
            isLoading={isLoading}
            setSubmitState={this.setSubmitState}
            disableAdditionalData={disableAdditionalData}
            securityConfirmationFieldEnabled={securityConfirmationFieldEnabled}
          />
        </Body>
        <Footer
          hasCancelButton
          hasContinueButton
          routeName={t('CHECK_IN')}
          onContinue={this.submit}
          isContinueDisabled={!isSubmitEnabled || isLoading}
          continueButtonLabel={t('START_CHECK_IN')}
          onCancel={this.cancel}
        />
      </View>
    );
  }

  public enableLoading = () => this.setState({ isLoading: true });

  public disableLoading = () => this.setState({ isLoading: false });

  public onModalClick = () => {
    this.clearState();
    this.setState({
      roomAssignmentError: {
        message: '',
      },
      disableAdditionalData: false,
      securityConfirmationFieldEnabled: false,
    });
  };

  private submit = () => {
    const { submit } = this.props;
    submit(ids.AUTHORIZATION_FORM);
  };

  private setSubmitState = (isFormValid: boolean) =>
    this.setState({ isSubmitEnabled: isFormValid });

  private cancel = () => {
    const { history } = this.props;
    history.replace(Router.prevStepURL);
  };

  /* eslint-disable camelcase */
  private assignCallbacks = () => {
    const global = window as any;
    // eslint-disable-next-line max-len
    global.kp_VersionAPI_requestProductName_callback =
      this.kp_VersionAPI_requestProductName_callback;
  };

  private kp_VersionAPI_requestProductName_callback = (productName: any) => {
    const { setIsIPad } = this.props;
    setIsIPad();
  };

  private checkVersion = () => {
    const global = window as any;
    global.kp_VersionAPI_requestProductName(
      'kp_VersionAPI_requestProductName_callback'
    );
  };
  /* eslint-enable camelcase */

  private getDefaultError = () => {
    const { t } = this.props;
    const roomNotReadyError =
      Configurator.getTranslation(ROOM_NOT_READY_MESSAGE) ||
      t('SOMETHING_WENT_WRONG');

    return this.areAllRoomsUnavailable
      ? roomNotReadyError
      : t('CHECK_IN_UNAVAILABLE');
  };

  private get customErrorCode() {
    const { t } = this.props;

    return this.areAllRoomsUnavailable
      ? ROOM_NOT_READY_MESSAGE || t('SOMETHING_WENT_WRONG')
      : CHECK_IN_FAILED_MESSAGE;
  }

  private get areAllRoomsUnavailable() {
    const { roomAssignmentError } = this.state;

    return (
      roomAssignmentError.message ===
      Configurator.roomErrorCodes.NO_AVAILABLE_ROOMS
    );
  }

  private disableSubmit = () => this.toggleSubmitState(false);

  private enableSubmit = () => this.toggleSubmitState(true);

  private toggleSubmitState = (isSubmitEnabled: boolean) => {
    this.setState({ isSubmitEnabled });
  };

  private clearState = () => {
    const { clearState, reset } = this.props;
    clearState();
    reset(ids.AUTHORIZATION_FORM);
  };

  private checkIfCheckInPossible = async () => {
    const { fetchPropertyLocalDateTime } = this.props;
    await fetchPropertyLocalDateTime();
    const { localPropertyDateTime, t } = this.props;
    const { checkInTime: defaultCheckInTime } = Configurator.propertySettings;
    const checkInDateTime = DateManager.getSubtractedTime(
      defaultCheckInTime,
      Configurator.getNumberValues(
        CHECK_IN_BEFORE_ARRIVAL,
        DEFAULT_CHECK_IN_BEFORE_ARRIVAL_TIME
      )
    );
    const isCheckInAvailable = DateManager.checkIfBefore(
      localPropertyDateTime,
      checkInDateTime
    );
    if (!isCheckInAvailable) {
      const message = t('SELF_CHECK_IN_AVAILABLE', {
        availabilityHour: DateManager.getFormattedDate(
          checkInDateTime,
          Configurator.timeFormat
        ),
      });
      this.setState({ checkInTimeMessage: message });
      // eslint-disable-next-line no-console
      console.error(message);
      throw new Error(message);
    }
  };

  private onSubmit = async (values: AuthorizationData) => {
    try {
      this.disableSubmit();
      this.enableLoading();

      const { lastName, identificationNumber, departureDate, numberOfNights } =
        values;
      const { fetchCurrentDate, authorizationForm, numberOfTries } = this.props;
      const { securityConfirmationFieldEnabled } = this.state;

      const isSelectedConfirmationNumber =
        authorizationForm?.additionalData ===
          AdditionalData.ConfirmationNumber ||
        !this.onlySurname ||
        securityConfirmationFieldEnabled;

      let departure = isSelectedConfirmationNumber ? undefined : departureDate;

      const confirmationNumber =
        isSelectedConfirmationNumber || numberOfTries
          ? identificationNumber
          : undefined;

      if (authorizationForm?.additionalData && !isSelectedConfirmationNumber) {
        const {
          authorizationForm: { additionalData },
        } = this.props;
        const businessDate = Configurator.propertyBusinessDate;
        if (additionalData === AdditionalData.NumberOfNights) {
          departure = DateManager.addDays(businessDate, numberOfNights);
        }
        if (additionalData === AdditionalData.DepartureDate) {
          departure =
            departure &&
            DateManager.parseDateFormat(
              departure,
              Configurator.dateFormat.shortDateFormat
            );
        }
      }
      await this.fetchReservationCheckIn(
        lastName!,
        confirmationNumber,
        departure
      );

      const {
        isReservationDayUse,
        reservation: { isEtaEtdGuaranteed },
      } = this.props;

      await fetchCurrentDate();

      if (!(isReservationDayUse || isEtaEtdGuaranteed))
        await this.checkIfCheckInPossible();
      await this.loadFullData(confirmationNumber);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.setState({ roomAssignmentError: error });
      this.enableSubmit();
    }
    if (!this.willUnmount) this.disableLoading();
  };

  private get showSecondFactor() {
    const switches = [SHOW_CONF_NUMBER, SHOW_DUE_DATE, SHOW_NO_OF_NIGHTS];

    return switches.some((s) => Configurator.getSwitch(s));
  }

  private loadFullData = async (confirmationNumber?: string | number) => {
    try {
      const {
        tooMuchReservations,
        isMultiRoomReservation,
        multiRoomWithNoConfirmationProvided,
        history,
      } = this.props;

      if (
        multiRoomWithNoConfirmationProvided ||
        (tooMuchReservations && this.showSecondFactor)
      ) {
        this.setupAdditionalDataField();
      }
      if (tooMuchReservations || multiRoomWithNoConfirmationProvided)
        return this.enableSubmit();
      if (isMultiRoomReservation) {
        const isMultiRoomPath = await this.checkIsMultiRoomPath(
          confirmationNumber
        );
        if (isMultiRoomPath) return history.push(Router.nextStepURL);
      }

      const {
        assignRoom,
        fetchAvailableRooms,
        fetchProfile,
        fetchReservation,
        fetchCheckInData,
        fetchRoomDetails,
        reservation: { id, profileId, roomId, roomTypeId },
      } = this.props;

      await Promise.all([fetchProfile(profileId), fetchReservation(id)]);
      await fetchCheckInData();

      const { errors } = this.props;
      if (errors.length) throw new Error(errors[0].message);

      if (roomId) await fetchAvailableRooms(roomTypeId);

      const { shouldReassignRoom, isRoomFixed } = this.props;
      const { NO_AVAILABLE_ROOMS } = Configurator.roomErrorCodes;

      if (shouldReassignRoom) {
        if (isRoomFixed) {
          throw new RoomError(NO_AVAILABLE_ROOMS);
        }

        await assignRoom();
      }

      const { suggestedRoom } = this.props;
      await fetchRoomDetails((suggestedRoom.roomId || roomId) as string);

      if (isMultiRoomReservation) {
        this.toggleChangeNameModal();
      } else {
        this.continueCheckInProcess();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.setState({ roomAssignmentError: error });
      this.enableSubmit();
    }
  };

  private setupAdditionalDataField = () => {
    const { onChange, authorizationForm, multiRoomWithNoConfirmationProvided } =
      this.props;

    if (multiRoomWithNoConfirmationProvided) {
      this.setState({ securityConfirmationFieldEnabled: true });
    } else if (!authorizationForm.additionalData) {
      onChange(
        ids.AUTHORIZATION_FORM,
        'additionalData',
        this.getFirstAdditionalField()
      );
    }
  };

  private getFirstAdditionalField = () => {
    const { SHOW_CONF_NUMBER, SHOW_DUE_DATE, SHOW_NO_OF_NIGHTS } =
      Configurator.switchCodes;
    if (Configurator.getSwitch(SHOW_CONF_NUMBER))
      return AdditionalData.ConfirmationNumber;
    if (Configurator.getSwitch(SHOW_DUE_DATE))
      return AdditionalData.DepartureDate;
    if (Configurator.getSwitch(SHOW_NO_OF_NIGHTS))
      return AdditionalData.NumberOfNights;
  };

  private checkIsMultiRoomPath = async (
    confirmationNumber?: string | number
  ) => {
    const {
      fetchMultiRoomSegmentDetails,
      isMultiRoomReservation,
      multiRoomSegmentId,
    } = this.props;

    await fetchMultiRoomSegmentDetails(multiRoomSegmentId);

    const {
      multiRoomSegment: { confirmationNumber: multiRoomConfirmationNumber },
    } = this.props;

    return Boolean(
      isMultiRoomReservation &&
        multiRoomConfirmationNumber === String(confirmationNumber)
    );
  };

  private fetchReservationCheckIn = async (
    lastName: string,
    identificationNumber?: string | number,
    departureDate?: string
  ) => {
    const { fetchReservationCheckIn } = this.props;
    await fetchReservationCheckIn(
      lastName.trim(),
      identificationNumber,
      departureDate
    );
    const { errors } = this.props;
    if (errors.length) {
      const { message } = errors[0];
      throw new Error(message);
    }
  };

  private toggleChangeNameModal = () => {
    const { isCheckNameDetailsOpen } = this.state;
    this.setState({ isCheckNameDetailsOpen: !isCheckNameDetailsOpen });
  };

  private continueCheckInWithNameChange = () => {
    const { history } = this.props;
    const { roomAssignmentError } = this.state;

    if (!roomAssignmentError.message) {
      history.push(Router.steps[Path.checkIn].NAME_DETAILS.url);
    }
  };

  private continueCheckInProcess = () => {
    const {
      addons,
      activeAuthorizations,
      outstandingDeposit,
      localPropertyDateTime,
      preAuthorizationAmount,
      billingInstructions,
      folios,
      history,
    } = this.props;

    const { roomAssignmentError } = this.state;

    configureCheckInRouting(
      addons,
      activeAuthorizations,
      outstandingDeposit,
      localPropertyDateTime,
      preAuthorizationAmount,
      billingInstructions,
      folios,
      false
    );

    if (!roomAssignmentError.message) {
      history.push(Router.nextStepURL);
    }
  };
}

const mapStateToProps = (state: Store, props: CheckInAuthProps) => ({
  tooMuchReservations: getTooMuchReservations(state),
  multiRoomWithNoConfirmationProvided:
    getMultiRoomWithNoConfirmationProvided(state),
  reservation: getReservation(state),
  profileDetails: getProfileDetails(state),
  reservationVersion: getReservationVersion(state),
  roomType: getRoomType(state),
  localPropertyDateTime: getLocalPropertyDateTime(state),
  folios: getFolios(state),
  billingInstructions: getActiveBillingInstructions(state),
  errors: getErrors(state),
  isRoomFixed: isRoomFixed(state),
  shouldReassignRoom: shouldReassignRoom(state),
  assignRoomOperation: getAssignRoomOperation(state),
  suggestedRoom: getSuggestedRoom(state),
  authorizationForm: getFormValues(ids.AUTHORIZATION_FORM)(state),
  addons: getAddons(state, props),
  outstandingDeposit: getReservationOutstandingDeposit(state),
  activeAuthorizations: activeAuthorizations(state),
  preAuthorizationAmount: getPreAuthorizationAmount(state),
  isReservationDayUse: isReservationDayUse(state),
  isMultiRoomReservation: isMultiRoomReservation(state),
  multiRoomSegmentId: getMultiRoomSegmentId(state),
  isReservationShared: getIsReservationShared(state),
  numberOfTries: getNumberOfTries(state),
  multiRoomSegment: getMultiRoomSegment(state),
});

const mapDispatchToProps = {
  fetchCashieringAccount,
  fetchReservationCheckIn,
  fetchProfile,
  fetchRoomDetails,
  fetchAvailableRooms,
  assignRoom,
  getAssignRoomOperationStatus,
  clearState,
  setIsIPad,
  fetchReservation,
  submit,
  onChange: change,
  fetchCurrentDate,
  fetchPropertyLocalDateTime,
  fetchCheckInData,
  fetchMultiRoomSegmentDetails,
  reset,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInAuth) as (props: PassedProps) => JSX.Element;
