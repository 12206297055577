import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export const rowStyle = ({ spacing }: Theme) =>
  createStyles({
    row: {
      backgroundColor: colors.white,
      borderRadius: '0.375rem',
      marginBottom: spacing(1),
    },
  });
