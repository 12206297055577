import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { isIPad } from 'store/ui/selectors';
import Store from 'types/Store';
import { Image } from 'utils';

import { Grid, Modal } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { saveIdPhoto } from '../store/actions';

import { KioskProPhotoCapture } from './KioskProPhotoCapture/KioskProPhotoCapture';
import styles from './IDCapture.style';
import WebcamPhotoCapture from './WebcamPhotoCapture';

interface PassedProps {
  isOpen: boolean;
  toggleModal: () => void;
  shouldCrop?: boolean;
}

interface IDCaptureProps extends PassedProps, WithStyles<typeof styles> {
  saveIdPhoto: typeof saveIdPhoto;
  isIPad: boolean;
}

interface IDCaptureState {
  currentPhoto: string;
  isKioskProEnabled: boolean;
}

class IDCapture extends PureComponent<IDCaptureProps, IDCaptureState> {
  public static defaultProps = {
    isOpen: false,
    shouldCrop: false,
  };

  public state = {
    currentPhoto: '',
    isKioskProEnabled: true,
  };

  public componentDidUpdate(prevProps: IDCaptureProps) {
    const { isOpen } = prevProps;
    const { isOpen: willOpen } = this.props;
    if (!isOpen && willOpen) {
      this.setState({ isKioskProEnabled: true });
    }
  }

  public render() {
    const { isOpen, shouldCrop, toggleModal, isIPad, classes } = this.props;

    const { currentPhoto, isKioskProEnabled } = this.state;

    return (
      <Modal
        open={isOpen}
        className={classNames(
          classes.wrapper,
          'with-default-kiosk-components-theme'
        )}
      >
        {isIPad && !currentPhoto && isKioskProEnabled ? (
          <KioskProPhotoCapture
            onNewPhotoAdd={this.addNewPhoto}
            onCancel={toggleModal}
            onError={toggleModal}
          />
        ) : (
          <Grid>
            <WebcamPhotoCapture
              shouldCrop={shouldCrop}
              toggleModal={toggleModal}
              kioskProPhoto={currentPhoto}
              onCaptureReset={this.resetCapture}
              onCaptureRetry={this.retryCapture}
            />
          </Grid>
        )}
      </Modal>
    );
  }

  private resetCapture = () => {
    const { toggleModal } = this.props;
    this.setState({ isKioskProEnabled: false, currentPhoto: '' });
    toggleModal();
  };

  private retryCapture = () => this.setState({ currentPhoto: '' });

  private addNewPhoto = async (photo: string) => {
    const photoToAdd =
      Image.getFileExtension(photo) === Image.ImageExtensions.png
        ? await Image.convertPNGToJPEG(photo)
        : photo;
    this.setState({ currentPhoto: photoToAdd });
  };
}

const mapStateToProps = (state: Store) => ({
  isIPad: isIPad(state),
});

const mapDispatchToProps = {
  saveIdPhoto,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(IDCapture) as (props: PassedProps) => JSX.Element;
