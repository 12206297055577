import types from './types';

export const refreshTheme = () => ({
  type: types.REFRESH_THEME,
});

export const toggleReservationHeader = () => ({
  type: types.TOGGLE_RESERVATION_HEADER,
});

export const refreshUi = () => ({
  type: types.REFRESH_UI,
});

export const setIsIPad = () => ({
  type: types.SET_IS_IPAD,
});

export const toggleDayUseModal = () => ({
  type: types.TOGGLE_DAY_USE_MODAL,
});
