import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

import 'react-image-crop/dist/ReactCrop.css';

const RADIUS = 6;
export const WEBCAM_WIDTH = 592;
export const WEBCAM_HEIGHT = WEBCAM_WIDTH * 0.75;

export const getBackground = (image: string) =>
  image
    ? {
        backgroundImage: `url('${image}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }
    : {};

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: palette.common.white,
      padding: spacing(2),
      outline: 'none',
      border: `2px solid ${palette.primary.main}`,
      borderRadius: RADIUS,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    webcam: {
      height: '100%',
      width: '100%',
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cameraWrapper: {
      border: `2px solid ${palette.primary.main}`,
      marginBottom: spacing(1),
      borderRadius: RADIUS,
      height: WEBCAM_HEIGHT,
      width: WEBCAM_WIDTH,
      overflow: 'hidden',
    },
    confirmTitle: {
      width: WEBCAM_WIDTH,
      fontSize: '1rem',
      marginTop: spacing(1 / 2),
      marginBottom: spacing(1),
      textAlign: 'center',
    },
    webcamCapture: {
      width: '100%',
      height: '100%',
    },
    retryButton: {
      marginRight: spacing(1),
    },
    editButton: {
      marginRight: spacing(1),
    },
    crop: {
      '& div': {
        '& div:nth-child(n+5)': {
          backgroundColor: palette.common.white,
        },
      },
    },
    title: {
      fontSize: '1.5rem',
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: spacing(1.5),
    },
  });
