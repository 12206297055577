import { ApiError } from '@ac/library-api';

import { Store } from '@gss/store';

export const getMainProcessFetchState = (state: Store): boolean =>
  Object.values(state.mainProcess.fetching).includes(true);

export const getMainProcessErrors = (state: Store): Array<ApiError | Error> =>
  state.mainProcess.errors;

export const getIsMainProcessInitialized = (state: Store): boolean =>
  state.mainProcess.isMainProcessInitialized;

export const getIsInactiveStatus = (state: Store): boolean =>
  state.mainProcess.isAppInactive;

export const getIsOfflineStatus = (state: Store): boolean =>
  state.mainProcess.isOffline;
