export interface PermissionData {
  id: string;
  isGranted: boolean;
  isRequired: boolean;
  name?: string;
}

export interface PropertyPermissionsState {
  USE_SELF_SERVICE_APPLICATION?: PermissionData;
  MANAGE_INDIVIDUAL_PROFILE?: PermissionData;
  VIEW_INDIVIDUAL_PROFILE?: PermissionData;
  MANAGE_COMPANY_PROFILE?: PermissionData;
  MANAGE_CONSENTS_AND_PRIVACY?: PermissionData;
  POST_A_PAYMENT?: PermissionData;
  VIEW_ROOMS?: PermissionData;
  VIEW_BILLING_SCREEN?: PermissionData;
  VIEW_RESERVATIONS_FOR_SELF_SERVICE?: PermissionData;
  VIEW_ADAPTER?: PermissionData;
  MANAGE_BILLING_INSTRUCTION?: PermissionData;
  MANAGE_RESERVATION?: PermissionData;
  VIEW_ACCOUNT_SETUP?: PermissionData;
  VIEW_ORGANIZATION_UNIT?: PermissionData;
  ACCESS_TO_CONFIGURATION_MODULE?: PermissionData;
  MANAGE_PAYMENT_METHODS?: PermissionData;
  TRANSFER_TRANSACTION?: PermissionData;
  VIEW_COMPANY_PROFILE?: PermissionData;
  VIEW_RESERVATION?: PermissionData;
  LINK_PROFILES?: PermissionData;
  CHECK_OUT?: PermissionData;
  CHECK_IN_RESERVATION?: PermissionData;
  CASHIER_NUMBER_OPEN?: PermissionData;
  MANAGE_FOLIO_WINDOW?: PermissionData;
  REVEAL_PROFILE_PERSONAL_DATA?: PermissionData;
}

export const propertyPermissionsMap: Record<
  keyof PropertyPermissionsState,
  { id: string; isOptional?: boolean }
> = {
  USE_SELF_SERVICE_APPLICATION: {
    id: '0f01fd2a-567a-4c34-8750-d26c0fa5941c',
  },
  MANAGE_INDIVIDUAL_PROFILE: {
    id: '02aaa2b1-58a4-4aab-9c0b-2a68b0b62f86',
  },
  VIEW_INDIVIDUAL_PROFILE: {
    id: '17e270d2-9dd2-4eea-9892-a91e3cf9659e',
  },
  MANAGE_COMPANY_PROFILE: {
    id: '27cde860-a058-4cd1-8c17-945ff79db766',
  },
  MANAGE_CONSENTS_AND_PRIVACY: {
    id: 'a5049113-0132-4e44-a1c1-67eae851ee01',
  },
  POST_A_PAYMENT: {
    id: '4af51a21-bd9f-47f9-99b5-1b9ae55503b7',
  },
  VIEW_ROOMS: {
    id: '4c10481b-ac98-42de-a89e-b723035da6e2',
  },
  VIEW_BILLING_SCREEN: {
    id: '4e3284ae-5a2a-4d6b-86a1-10a1af0341bd',
  },
  VIEW_RESERVATIONS_FOR_SELF_SERVICE: {
    id: '4effe570-3f36-41ed-9978-f4acf1960fb9',
  },
  VIEW_ADAPTER: {
    id: '77762a33-c9ed-48ed-80dc-7052aeebe2c4',
  },
  MANAGE_BILLING_INSTRUCTION: {
    id: '79378c4a-2277-4dc2-bced-e04c155a22b6',
  },
  MANAGE_RESERVATION: {
    id: '7be833c3-df42-4835-8469-7c0ba10bc071',
  },
  VIEW_ACCOUNT_SETUP: {
    id: '8600b909-e4bc-4fd3-b81f-8d2ecbc4c648',
  },
  VIEW_ORGANIZATION_UNIT: {
    id: 'a02f8999-313f-4518-a003-6642c1d5b933',
  },
  ACCESS_TO_CONFIGURATION_MODULE: {
    id: 'a080a0fb-98f6-4f1b-8c9b-470a7c9e782e',
  },
  MANAGE_PAYMENT_METHODS: {
    id: 'a99f248f-4da6-4903-b664-7b4353104e05',
    isOptional: true,
  },
  TRANSFER_TRANSACTION: {
    id: 'ae6ac522-12c9-4c7e-9aa5-5d7ca0b0b905',
  },
  VIEW_COMPANY_PROFILE: {
    id: 'b0eb8413-3488-4275-84cb-a61ff5893de7',
  },
  VIEW_RESERVATION: {
    id: 'b6796503-59f4-4658-a0f1-b81473e40abe',
  },
  LINK_PROFILES: {
    id: 'c3f40dd2-a7a8-4d94-8bc2-890d4a7d5925',
  },
  CHECK_OUT: {
    id: 'c7753077-38fc-46dd-99bf-ab4dc3b293c3',
  },
  CHECK_IN_RESERVATION: {
    id: 'e105fbde-c8b0-4626-bf5b-676313dbc814',
  },
  CASHIER_NUMBER_OPEN: {
    id: 'f39b3fb5-7880-483a-b583-16b7c11b5828',
  },
  MANAGE_FOLIO_WINDOW: {
    id: 'fe032f01-b768-453a-98bb-55466de2c355',
  },
  REVEAL_PROFILE_PERSONAL_DATA: {
    id: '0beddeb8-3243-49b5-8dc3-caa9ffb5bfdc',
  },
};
