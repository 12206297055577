import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      '& input': {
        zIndex: 9999,
      },
      width: 'auto',
    },
    label: {
      fontSize: '1rem',
      marginLeft: spacing(1),
      color: 'inherit',
      zIndex: 2,
    },
    formControlLabel: {
      color: 'inherit',
    },
  });
