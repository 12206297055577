import { CallApiResponseAction } from 'store/middleware/api';

import CheckInConfirmationStore from './interfaces';
import types from './types';

const initialState: CheckInConfirmationStore = {
  cachedConfirmationFormData: undefined,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.CACHE_CONFIRMATION_FORM_DATA:
      return {
        ...state,
        cachedConfirmationFormData: action.payload,
      };

    case types.CLEAR_CACHED_CONFIRMATION_FORM_DATA:
      return {
        ...state,
        cachedConfirmationFormData: undefined,
      };

    default:
      return state;
  }
};
