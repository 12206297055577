import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AllPurchaseItems } from 'types/Api/Reservation';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { Grid, Modal, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { getBackground } from '../CheckInAddons.style';
import PreAddedButton from '../PreAddedButton';
import QuantityPanel from '../QuantityPanel';

import styles from './AddonModal.style';

interface AddonModalProps extends WithTranslation, WithStyles<typeof styles> {
  backgroundUrl?: string;
  isOpen?: boolean;
  onClose: (itenNumber?: number) => void;
  onBackdropClick: () => void;
  onItemAdd: (itemName: string) => void;
  onItemRemove: (itemName: string) => void;
  onItemDelete: (itemName: string) => void;
  input?: any;
  data: {
    id: string;
    title: string;
    price: number;
    description: string;
    quantity: number;
    calculationRule: {
      code: string;
      description: string;
    };
    isPerPerson: boolean;
    maxQuantity?: number;
  };
  isPreAddedElement: boolean;
  openPreAddeModal: (e: React.MouseEvent) => void;
  allPreAddedPurchases: AllPurchaseItems;
  addedElements: number;
  isElementFromRate: boolean;
}

interface AddonModalState {
  quantity: number;
  quantityFieldValue: string;
}

class AddonModal extends PureComponent<AddonModalProps, AddonModalState> {
  public static defaultProps = {
    backgroundUrl: '',
    isOpen: true,
  };

  public state = {
    quantity: 0,
    quantityFieldValue: '',
  };

  public componentDidMount() {
    const { addedElements } = this.props;
    this.setState({ quantity: addedElements });
  }

  public render() {
    const {
      classes,
      backgroundUrl,
      isOpen = false,
      input,
      onBackdropClick,
      openPreAddeModal,
      data: {
        id,
        title,
        description,
        price,
        calculationRule: { description: calculationRule },
        isPerPerson,
        maxQuantity,
      },
      isPreAddedElement,
      allPreAddedPurchases,
      isElementFromRate,
    } = this.props;
    const { quantity } = this.state;

    return (
      <Modal
        open={isOpen}
        onBackdropClick={onBackdropClick}
        className={classNames(
          classes.modal,
          'with-default-kiosk-components-theme'
        )}
      >
        <Grid className={classes.wrapper}>
          <Grid
            className={classes.cover}
            style={{ ...getBackground(backgroundUrl) }}
          />
          {isPreAddedElement && (
            <PreAddedButton
              isPerPerson={isPerPerson}
              onClick={openPreAddeModal}
              className={classes.preAddedButton}
              allPreAddedPurchases={allPreAddedPurchases}
              purchaseItemId={id}
              isElementFromRate={isElementFromRate}
            />
          )}
          <Grid className={classes.content}>
            <Grid container className={classes.body}>
              <Grid item xs={10} className={classes.descriptionWrapper}>
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </Grid>
              {!isElementFromRate && (
                <Grid item xs={2} className={classes.priceWrapper}>
                  <Typography className={classes.price}>
                    {price.toFixed(2)}
                  </Typography>
                  <Typography className={classes.pricing}>
                    {calculationRule}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {!isPerPerson && !isPreAddedElement ? (
              <QuantityPanel
                input={{
                  ...input,
                  onChange: this.onFieldChange,
                  onBlur: () => null,
                }}
                quantity={quantity}
                price={price}
                onAdd={this.onItemAdd}
                onRemove={this.onItemRemove}
                maxQuantity={maxQuantity}
              />
            ) : null}
            <Grid
              className={
                isPreAddedElement
                  ? classes.preAddedBottomPanel
                  : classes.bottomPanel
              }
            >
              {this.renderButtons()}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    );
  }

  public handleDeleteClick = () => {
    const {
      onItemDelete,
      input: { name },
      onClose,
    } = this.props;
    onItemDelete(name);
    this.setState({ quantity: 0 });
    onClose();
  };

  public handleClose = () => {
    const {
      onClose,
      data: { quantity: addedItemsQuantity },
    } = this.props;
    const { quantity } = this.state;

    return onClose(quantity - addedItemsQuantity);
  };

  private onItemAdd = () => {
    const { quantity } = this.state;
    this.setState({ quantity: quantity ? quantity + 1 : 1 });
  };

  private onItemRemove = () => {
    const { quantity } = this.state;
    this.setState({ quantity: quantity - 1 });
  };

  private onFieldChange = (e: any) => {
    const isZero = e.target.value === '0';
    const value = isZero ? 1 : parseInt(e.target.value, 10);

    return this.setState({ quantity: value });
  };

  private onCancelClick = () => {
    const { onBackdropClick, addedElements } = this.props;
    this.setState({ quantity: addedElements });
    onBackdropClick();
  };

  private renderButtons = () => {
    const {
      t,
      data: { isPerPerson },
      onBackdropClick,
      isPreAddedElement,
      addedElements,
    } = this.props;
    const { quantity } = this.state;

    const closeButton = <Button onClick={onBackdropClick}>{t('CLOSE')}</Button>;

    const removeConfirmButton = (
      <>
        <Button
          pattern={ButtonPattern.secondary}
          onClick={this.handleDeleteClick}
        >
          {t('REMOVE')}
        </Button>
        <Button onClick={this.handleClose}>{t('CONFIRM')}</Button>
      </>
    );

    const cancelAddButton = (
      <>
        <Button pattern={ButtonPattern.secondary} onClick={this.onCancelClick}>
          {t('CANCEL')}
        </Button>
        <Button onClick={this.handleClose} disabled={!isPerPerson && !quantity}>
          {t('ADD')}
        </Button>
      </>
    );

    if (isPreAddedElement) return closeButton;
    if (addedElements > 0) return removeConfirmButton;

    return cancelAddButton;
  };
}

export default withStyles(styles)(withTranslation()(AddonModal));
