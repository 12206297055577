import i18next from 'i18next';
import { Configurator } from 'utils';

export const validateSettingsSetup = () => {
  const errorAreas: string[] = [];
  const title = `${i18next.t('SETTINGS_MISCONFIGURED')}:\n`;

  if (!Configurator.defaultCommunicationEmailTypeForKiosk?.code) {
    errorAreas.push('Default Email type for Kiosk');
  }
  if (!Configurator.defaultCommunicationMobileTypeForKiosk?.code) {
    errorAreas.push('Default Mobile type for Kiosk');
  }
  if (!Configurator.defaultGuestAddressTypeForKiosk?.code) {
    errorAreas.push('Default Guest Address type for Kiosk');
  }
  if (!Configurator.defaultCompanyAddressTypeForKiosk?.code) {
    errorAreas.push('Default Company Address type for Kiosk');
  }

  if (errorAreas.length) {
    return `${title} ${errorAreas.join(', ')}`;
  }
};
