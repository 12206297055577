import {
  ApiActionResult,
  CallApiSpecificResponseAction,
} from 'store/middleware/api';
import { failure, request, success } from 'store/utils/reducer';
import { Folio } from 'types/Api/Cashiering';
import { State } from 'types/Store/CashieringStore';

export const fetchFolio = (
  state: State,
  action: CallApiSpecificResponseAction<Folio>
): State => {
  switch (action.apiAction) {
    case ApiActionResult.request:
      return request(state, 'cashiering');

    case ApiActionResult.failure:
      return failure(state, action, 'cashiering');

    case ApiActionResult.success: {
      const { data: newFolio } = action.response;
      const { payload: folioId } = action;
      const { folios } = state;
      const isPreviousFolio = folios.some((folio) => folio.id === folioId);
      const updatedFolios = isPreviousFolio
        ? folios.map((folio, index) =>
            folio.id === folioId
              ? {
                  ...folio,
                  ...newFolio,
                }
              : folio
          )
        : [...folios, newFolio];

      return {
        ...success(state, 'cashiering'),
        newFolioId: undefined,
        folios: updatedFolios,
      };
    }

    default:
      return state;
  }
};
