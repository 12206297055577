import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Configurator } from 'utils';

import { Button } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './Help.style';

const { NEED_HELP_MESSAGE } = Configurator.getTranslationCodes();

interface PassedProps {
  onClick?: () => void;
}

interface HelpProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class Help extends PureComponent<HelpProps> {
  public render() {
    const { classes, t, onClick } = this.props;

    return (
      <Grid className={classes.content}>
        <Grid className={classes.descriptionWrapper}>
          <Typography className={classes.title}>
            {t('DO_YOU_NEED_HELP')}
          </Typography>
          <Typography className={classes.description}>
            {Configurator.getTranslation(NEED_HELP_MESSAGE)}
          </Typography>
        </Grid>
        <Button onClick={onClick} className={classes.button}>
          {t('CLOSE')}
        </Button>
      </Grid>
    );
  }
}

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles)
)(Help) as (props: PassedProps) => JSX.Element;
