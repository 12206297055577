import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Button } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './MinibarPanel.style';

interface PassedProps {
  onClick: () => void;
  disabled?: boolean;
}

interface MinibarPanelProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class MinibarPanel extends PureComponent<MinibarPanelProps> {
  public static defaultProps = {
    isOpen: false,
  };

  public render() {
    const { classes, t, onClick, disabled } = this.props;

    return (
      <Grid className={classes.wrapper}>
        <Button
          onClick={onClick}
          className="spacing-bottom-sm"
          disabled={disabled}
        >
          {t('ADD_MINIBAR_ITEMS')}
        </Button>
        <Typography className={classes.title}>
          {t('ANY_MINIBAR_ITEMS_MISSING')}
        </Typography>
      </Grid>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(MinibarPanel) as (
  props: PassedProps
) => JSX.Element;
