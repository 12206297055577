import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { Breakpoints } from '@material-ui/core/styles/createBreakpoints';
import { Palette } from '@material-ui/core/styles/createPalette';
import {
  CreateCSSProperties,
  createStyles,
  CSSProperties,
} from '@material-ui/styles';

const {
  colorCodes: { ADDONS_BACKGROUND_COLOR },
} = Configurator;

const WRAPPER_RADIUS = 6;
const WRAPPER_HEIGHT = 219;
const PRE_ADDED_WRAPPER_HEIGHT = 102;
const TITLE_BAR_HEIGHT = 68;
const ICON_BUTTON_SIZE = 44;
const ICON_SIZE = 22;
const BORDER_WIDTH = 2;
const PRICE_MAX_WIDTH = '30%';
const SUBTITLE_FONT_WEIGHT = 600;

const getWrapperStyles = (
  palette: Palette,
  spacing: (unit: number) => number,
  breakpoints: Breakpoints
): CreateCSSProperties<any> => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: WRAPPER_RADIUS,
  backgroundColor: Configurator.getColor(
    ADDONS_BACKGROUND_COLOR,
    palette.common.white
  ),
  textTransform: 'none',
  marginBottom: spacing(2),
});

const subtitle: CSSProperties = {
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
};

const title: CSSProperties = {
  textAlign: 'left',
  color: 'inherit',
};

const price: CSSProperties = {
  fontWeight: SUBTITLE_FONT_WEIGHT,
  textAlign: 'right',
  color: 'inherit',
};

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      ...getWrapperStyles(palette, spacing, breakpoints),
      height: WRAPPER_HEIGHT,
    },
    preAddedWrapper: {
      ...getWrapperStyles(palette, spacing, breakpoints),
      height: PRE_ADDED_WRAPPER_HEIGHT,
    },
    titleBar: {
      width: '100%',
      minHeight: TITLE_BAR_HEIGHT,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: spacing(1.5),
      boxSizing: 'border-box',
      color: 'inherit',
    },
    leftRow: {
      width: '75%',
    },
    title: {
      ...title,
    },
    longTitle: {
      ...title,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    buttonLabel: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      color: 'inherit',
    },
    buttonRoot: {
      padding: 0,
      justifyContent: 'flex-end',
      overflow: 'hidden',
      alignItems: 'flex-end',
      color: 'inherit',
      zIndex: 400,
    },
    priceWrapper: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: PRICE_MAX_WIDTH,
      minWidth: PRICE_MAX_WIDTH,
      alignItems: 'flex-end',
    },
    priceTitle: {
      ...price,
    },
    priceTitleDisabled: {
      ...price,
      color: Configurator.getColor(
        Configurator.colorCodes.HINT_FONT_COLOR,
        palette.text.disabled
      ),
    },
    subtitle: {
      ...subtitle,
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.HINT_FONT_COLOR,
        palette.text.disabled
      ),
    },
    subtitleBold: {
      ...subtitle,
      textAlign: 'left',
      color: 'inherit',
      fontWeight: SUBTITLE_FONT_WEIGHT,
    },
    onlySubtitle: {
      ...subtitle,
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.HINT_FONT_COLOR,
        palette.text.disabled
      ),
      width: '100%',
    },
    wrapperActive: {
      ...getWrapperStyles(palette, spacing, breakpoints),
      boxShadow: `0 0 0 ${BORDER_WIDTH}px ${palette.primary.main}`,
      height: WRAPPER_HEIGHT,
    },
    iconButton: {
      borderRadius: WRAPPER_RADIUS,
      position: 'absolute',
      top: 0,
      right: 0,
      padding: '0px !important',
      paddingTop: spacing(1 / 2),
      minWidth: 0,
      width: ICON_BUTTON_SIZE,
      height: ICON_BUTTON_SIZE,
      backgroundColor: palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
    },
    layout: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'flex-end',
    },
    contentWrapper: {
      flexDirection: 'column',
      height: '100%',
    },
    editPenIcon: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      color: 'var(--primary-button-active-text-color)',
    },
    preAddedButton: {
      minWidth: '100%',
    },
  });
