import { useSelector } from 'react-redux';

import { getPendingRefreshConfigurationState } from '@gss/store/backgroundTasks/selectors';
import {
  getChangeAppLanguageState,
  getRestartApplicationState,
} from '@gss/store/configuration/selectors';
import { getMainProcessFetchState } from '@gss/store/mainProcess/selectors';
import { getSettingsFetchState } from '@gss/store/settings/selectors';

export const useSubscribedLoadingState = (): boolean => {
  const settingsFetchState = useSelector(getSettingsFetchState);
  const restartApplicationState = useSelector(getRestartApplicationState);
  const pendingRefreshConfigurationState = useSelector(
    getPendingRefreshConfigurationState
  );
  const mainProcessFetchState = useSelector(getMainProcessFetchState);
  const changeAppLanguageState = useSelector(getChangeAppLanguageState);

  return (
    settingsFetchState ||
    restartApplicationState ||
    pendingRefreshConfigurationState ||
    changeAppLanguageState ||
    mainProcessFetchState
  );
};
