import { CallApiResponseAction } from 'store/middleware/api';

import CheckInProcessStore from './interfaces';
import types from './types';

const initialState: CheckInProcessStore = {
  dataFetching: {
    startProcess: false,
    updateProfile: false,
    updateConsents: false,
    generatingPDF: false,
  },
  errors: [],
  sessionId: undefined,
  confirmedScannedDocument: undefined,
  summaryPdf: undefined,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.START_CHECK_IN_PROCESS_REQUEST:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          startProcess: true,
        },
      };
    case types.START_CHECK_IN_PROCESS_SUCCESS: {
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          startProcess: false,
        },
        sessionId: action.payload,
      };
    }
    case types.START_CHECK_IN_PROCESS_FAILURE:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          startProcess: false,
        },
        errors: [...state.errors, action.payload],
      };

    case types.CHECK_IN_PROCESS_UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          updateProfile: true,
        },
      };
    case types.CHECK_IN_PROCESS_UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          updateProfile: false,
        },
      };
    }
    case types.CHECK_IN_PROCESS_UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          updateProfile: false,
        },
        errors: [...state.errors, action.payload],
      };

    case types.SAVE_SCANNED_DOCUMENT:
      return {
        ...state,
        confirmedScannedDocument: action.payload,
      };

    case types.CHECK_IN_PROCESS_UPDATE_CONSENTS_REQUEST:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          updateConsents: true,
        },
      };
    case types.CHECK_IN_PROCESS_UPDATE_CONSENTS_SUCCESS: {
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          updateConsents: false,
        },
      };
    }
    case types.CHECK_IN_PROCESS_UPDATE_CONSENTS_FAILURE:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          updateConsents: false,
        },
        errors: [...state.errors, action.payload],
      };

    case types.CHECK_IN_GENERATE_SUMMARY_PDF_REQUEST: {
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          generatingPDF: true,
        },
        summaryPdf: undefined,
      };
    }

    case types.CHECK_IN_GENERATE_SUMMARY_PDF_SUCCESS: {
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          generatingPDF: false,
        },
        summaryPdf: action.payload,
      };
    }

    case types.CHECK_IN_GENERATE_SUMMARY_PDF_FAILURE:
      return {
        ...state,
        dataFetching: {
          ...state.dataFetching,
          generatingPDF: false,
        },
        errors: [...state.errors, action.payload],
      };

    case types.RESET_CHECK_IN_STATE: {
      const oldSignature = state.summaryPdf;
      if (oldSignature) {
        URL.revokeObjectURL(oldSignature);
      }

      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};
