const FETCH_ADAPTER_CONFIGURATIONS_REQUEST =
  'FETCH_ADAPTER_CONFIGURATIONS_REQUEST';
const FETCH_ADAPTER_CONFIGURATIONS_SUCCESS =
  'FETCH_ADAPTER_CONFIGURATIONS_SUCCESS';
const FETCH_ADAPTER_CONFIGURATIONS_FAILURE =
  'FETCH_ADAPTER_CONFIGURATIONS_FAILURE';

const FETCH_ADAPTER_CONFIGURATION_REQUEST =
  'FETCH_ADAPTER_CONFIGURATION_REQUEST';
const FETCH_ADAPTER_CONFIGURATION_SUCCESS =
  'FETCH_ADAPTER_CONFIGURATION_SUCCESS';
const FETCH_ADAPTER_CONFIGURATION_FAILURE =
  'FETCH_ADAPTER_CONFIGURATION_FAILURE';

const FETCH_PAYMENT_INTERFACE_REQUEST = 'FETCH_PAYMENT_INTERFACE_REQUEST';
const FETCH_PAYMENT_INTERFACE_SUCCESS = 'FETCH_PAYMENT_INTERFACE_SUCCESS';
const FETCH_PAYMENT_INTERFACE_FAILURE = 'FETCH_PAYMENT_INTERFACE_FAILURE';

const FETCH_PAYMENT_INTERFACE_ADAPTER_REQUEST =
  'FETCH_PAYMENT_INTERFACE_ADAPTER_REQUEST';
const FETCH_PAYMENT_INTERFACE_ADAPTER_SUCCESS =
  'FETCH_PAYMENT_INTERFACE_ADAPTER_SUCCESS';
const FETCH_PAYMENT_INTERFACE_ADAPTER_FAILURE =
  'FETCH_PAYMENT_INTERFACE_ADAPTER_FAILURE';

const CLEAR_PROPERTY_INTERFACE_ERRORS = 'CLEAR_PROPERTY_INTERFACE_ERRORS';

export default {
  CLEAR_PROPERTY_INTERFACE_ERRORS,
  FETCH_ADAPTER_CONFIGURATIONS_REQUEST,
  FETCH_ADAPTER_CONFIGURATIONS_SUCCESS,
  FETCH_ADAPTER_CONFIGURATIONS_FAILURE,
  FETCH_PAYMENT_INTERFACE_REQUEST,
  FETCH_PAYMENT_INTERFACE_SUCCESS,
  FETCH_PAYMENT_INTERFACE_FAILURE,
  FETCH_ADAPTER_CONFIGURATION_REQUEST,
  FETCH_ADAPTER_CONFIGURATION_SUCCESS,
  FETCH_ADAPTER_CONFIGURATION_FAILURE,
  FETCH_PAYMENT_INTERFACE_ADAPTER_REQUEST,
  FETCH_PAYMENT_INTERFACE_ADAPTER_SUCCESS,
  FETCH_PAYMENT_INTERFACE_ADAPTER_FAILURE,
};
