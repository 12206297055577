import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
    },
  });
