/* eslint-disable max-len */
import { api } from 'config';
import { CALL_API } from 'store/middleware/api';

import types from './types';

export const fetchAvailableRooms = (roomCode: string) => ({
  [CALL_API]: {
    endpoint: `${api.HOUSEKEEPING.AVAILABLE_ROOMS}?filter=roomType.id==${roomCode}&includeAssignedRooms=true`,
    paginate: true,
    types: [
      types.FETCH_AVAILABLE_ROOMS_REQUEST,
      types.FETCH_AVAILABLE_ROOMS_SUCCESS,
      types.FETCH_AVAILABLE_ROOMS_FAILURE,
    ],
  },
});

export const clearHousekeepingErrors = () => ({
  type: types.CLEAR_HOUSEKEEPING_ERRORS,
});
