import mergeWith from 'lodash.mergewith';
import {
  ParsedBaseKioskConfigurationEntity,
  ParsedKioskCommunicationType,
  ParsedKioskConfiguration,
  ParsedKioskConfigurationConsent,
  ParsedKioskDictionaryEntryDetails,
} from 'types/Api/ParsedKioskConfiguration';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  BaseKioskConfigurationEntity,
  KioskCommunicationType,
  KioskConfiguration,
  KioskConfigurationConsent,
  KioskDictionaryEntryDetails,
  KioskLayoutSetting,
} from '@gss/api/KioskApi/entries';

import Configurator from '../../../utils/Configurator';

type KioskConfigurationEntities = Omit<
  KioskConfiguration,
  'property' | 'layoutSettings'
>;
type ExtractedKioskConfigurationCollectionItem =
  KioskConfigurationEntities[keyof KioskConfigurationEntities] extends Array<
    infer ExtractedArrayType
  >
    ? ExtractedArrayType
    : never;

type ParsedKioskConfigurationEntities = Omit<
  ParsedKioskConfiguration,
  'property' | 'layoutSettings'
>;
type ParsedExtractedKioskConfigurationCollectionItem =
  ParsedKioskConfigurationEntities[keyof ParsedKioskConfigurationEntities] extends Array<
    infer ExtractedArrayType
  >
    ? ExtractedArrayType
    : never;

export const mapConfigurationsData = (
  configurations: Array<[string, KioskConfiguration]>
): ParsedKioskConfiguration => {
  const localizedConfigurations = configurations.map(
    ([languageCode, singleConfiguration]) => {
      const { property, layoutSettings, ...entities } = singleConfiguration;

      const mappedLayoutSettings = mapLayoutSettings(
        layoutSettings,
        languageCode
      );
      const mappedEntities = Object.entries(entities).reduce(
        (acc, [key, value]) => {
          return {
            ...acc,
            [key]: localizeConfigurationEntry(value, languageCode),
          };
        },
        {}
      );

      return {
        property,
        layoutSettings: mappedLayoutSettings,
        ...mappedEntities,
      };
    }
  );

  return mergeWith(
    {},
    ...localizedConfigurations,
    configurationMergeModification
  );
};

const configurationMergeModification = (objValue: any, srcValue: any) => {
  const isTranslationArray =
    Array.isArray(objValue) &&
    Array.isArray(srcValue) &&
    typeof objValue[0] === 'object' &&
    typeof srcValue[0] === 'object' &&
    'languageCode' in objValue[0] &&
    'content' in objValue[0] &&
    'languageCode' in srcValue[0] &&
    'content' in srcValue[0];

  if (isTranslationArray) {
    if (
      objValue.some(
        (localization) => localization.content === srcValue[0].content
      )
    ) {
      return objValue;
    }

    return objValue.concat(srcValue);
  }
};

const createLocalizationArray = (
  languageCode: string,
  content: string | undefined
) => {
  return content && !Array.isArray(content)
    ? [{ languageCode, content }]
    : undefined;
};

const localizeConfigurationEntry = (
  data:
    | ExtractedKioskConfigurationCollectionItem
    | ExtractedKioskConfigurationCollectionItem[],
  languageCode: string
):
  | ParsedExtractedKioskConfigurationCollectionItem
  | ParsedExtractedKioskConfigurationCollectionItem[] => {
  if (Array.isArray(data)) {
    return data.map((collectionItem) =>
      localizeConfigurationEntry(collectionItem, languageCode)
    ) as ParsedExtractedKioskConfigurationCollectionItem[];
  }

  if (data instanceof KioskCommunicationType) {
    return {
      ...data,
      description: createLocalizationArray(languageCode, data.description),
      name: createLocalizationArray(languageCode, data.name),
      ...(data.communicationMode
        ? {
            communicationMode: {
              ...data.communicationMode,
              description: createLocalizationArray(
                languageCode,
                data.communicationMode.description
              ),
              name: createLocalizationArray(
                languageCode,
                data.communicationMode.name
              ),
            },
          }
        : {}),
    } as ParsedKioskCommunicationType;
  }

  if (data instanceof KioskConfigurationConsent) {
    return {
      ...data,
      description: createLocalizationArray(languageCode, data.description),
      name: createLocalizationArray(languageCode, data.name),
      consentBody: createLocalizationArray(languageCode, data.consentBody),
      ...(data.consentType
        ? {
            consentType: {
              ...data.consentType,
              description: createLocalizationArray(
                languageCode,
                data.consentType.description
              ),
            },
          }
        : {}),
    } as ParsedKioskConfigurationConsent;
  }

  if (
    data instanceof KioskDictionaryEntryDetails ||
    data instanceof BaseKioskConfigurationEntity
  ) {
    return {
      ...data,
      description: createLocalizationArray(languageCode, data.description),
      name: createLocalizationArray(languageCode, data.name),
    } as ParsedKioskDictionaryEntryDetails | ParsedBaseKioskConfigurationEntity;
  }

  return data;
};

function mapLayoutSettings(
  settings: KioskLayoutSetting[],
  languageCode: string
) {
  const translationCodes = Object.values(Configurator.getTranslationCodes());
  const mappedSettings = settings.map((setting) => {
    const newValue =
      (
        setting.value as {
          value?: any;
        }
      )?.value ?? setting.value;

    return [setting.code, newValue];
  });

  return Object.fromEntries(
    mappedSettings
      .filter(isDefined)
      .filter(([key, value]) => key && (value || value === false))
      .map(([key, value]) => {
        if (!translationCodes.includes(key)) {
          return [key, value];
        }

        if (Array.isArray(value)) {
          return;
        }

        return [key, createLocalizationArray(languageCode, value)];
      })
      .filter(isDefined)
  );
}
