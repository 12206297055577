import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const {
  colorCodes: { BUTTON_PRIMARY_TEXT_ACTIVE, BODY_FONT_COLOR },
} = Configurator;
const ICON_SIZE = 28;
const icon = {
  width: ICON_SIZE,
  height: ICON_SIZE,
};

const OVERLAY_SIZE = ICON_SIZE * 0.75;

const RIPPLE_OPACITY = 1;
const RIPPLE_SCALE = 1.35;
const RIPPLE_PULSATE_SCALE = 0.92;
const RIPPLE_GROW_DURATION = 400;
const RIPPLE_PULSATE_DURATION = 2000;
const RIPPLE_LEAVING_DURATION = 300;
const RIPPLE_SIZE = 126;
const RIPPLE_MARGIN = (RIPPLE_SIZE - 100) / -2;

export default ({ palette, spacing, transitions }: Theme) =>
  createStyles({
    checkboxRoot: {
      padding: 0,
    },
    check: {
      ...icon,
    },
    checkedIcon: {
      ...icon,
      width: 20,
      height: 20,
      color: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_ACTIVE,
        palette.primary.main
      ),
    },
    checkedIconDisabled: {
      color: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_ACTIVE,
        palette.primary.contrastText
      ),
    },
    checkboxOverlay: {
      position: 'absolute',
      width: OVERLAY_SIZE,
      height: OVERLAY_SIZE,
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      zIndex: 99,
      borderRadius: 4,
      boxSizing: 'border-box',
      backgroundColor: palette.common.white,
      border: `1px solid ${Configurator.getColor(
        BODY_FONT_COLOR,
        palette.common.black
      )}`,
    },
    checkboxOverlayDisabled: {
      backgroundColor: palette.text.disabled,
      borderColor: palette.text.disabled,
    },
    checkboxWrapper: {
      position: 'relative',
    },
    checkWrapper: {
      width: ICON_SIZE,
      height: ICON_SIZE,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    /* ripple settings */
    rippleRoot: {
      color: palette.primary.light,
      width: `${RIPPLE_SIZE}%`,
      height: `${RIPPLE_SIZE}%`,
      marginLeft: `${RIPPLE_MARGIN}%`,
      marginTop: `${RIPPLE_MARGIN}%`,
    },
    iconRoot: {
      zIndex: 999,
    },
    rippleVisible: {
      opacity: RIPPLE_OPACITY,
      transform: `scale(${RIPPLE_SCALE})`,
      animation: `$ripple-frame ${RIPPLE_GROW_DURATION}ms ${transitions.easing.easeInOut}`,
    },
    ripple: {
      opacity: RIPPLE_OPACITY,
    },
    childPulsate: {
      animation: `$ripple-pulsate ${RIPPLE_PULSATE_DURATION}ms
      ${transitions.easing.easeInOut} 200ms infinite`,
    },
    childLeaving: {
      animation: `$ripple-exit ${RIPPLE_LEAVING_DURATION}ms ${transitions.easing.easeInOut}`,
    },
    '@keyframes ripple-frame': {
      '0%': {
        transform: 'scale(0)',
        opacity: 0.1,
      },
      '100%': {
        transform: `scale(${RIPPLE_SCALE})`,
        opacity: RIPPLE_OPACITY,
      },
    },
    '@keyframes ripple-pulsate': {
      '0%': {
        transform: 'scale(1)',
      },
      '50%': {
        transform: `scale(${RIPPLE_PULSATE_SCALE})`,
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
    '@keyframes ripple-exit': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
  });
