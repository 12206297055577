import { PurchaseItem } from 'types/Api/Availability';
import { Breakdown, PurchaseElementData } from 'types/Api/Reservation';
import { Configurator, DateManager } from 'utils';

import { getPriceForGuest } from '../store/selectors';
import { FormValues } from '../types';

interface ParsedPurchaseElementData {
  id?: string;
  purchaseElementId: string;
  dateAnchor: string;
  quantity: number;
}

export const getBreakdownDataPerPerson = (
  item: any,
  quantity: number,
  isReservationDayUse: boolean,
  breakdown: Breakdown
) => {
  const { fromDate, toDate, guests } = breakdown;

  const {
    isPerPerson,
    dailyPrices,
    basePriceType,
    currency: { code: currency },
  } = item;

  return guests.map((guest) => ({
    guestCount: guest.count,
    ageBucketId: guest.ageBucketId,
    price: {
      currency,
      basePriceType,
      ...getPriceForGuest(guest, dailyPrices),
    },
    quantity,
    from: fromDate,
    to:
      isPerPerson || isReservationDayUse
        ? toDate
        : DateManager.getNextDay(fromDate),
  }));
};

export const getBreakdownDataPerReservation = (
  item: any,
  quantity: number,
  isReservationDayUse: boolean,
  breakdown: Breakdown
) => {
  const { fromDate, toDate } = breakdown;

  const {
    isPerPerson,
    basePriceType,
    currency: { code: currency },
    price: { gross, net },
  } = item;

  return [
    {
      guestCount: 1,
      price: {
        gross,
        net,
        currency,
        basePriceType,
      },
      quantity,
      from: fromDate,
      to:
        isPerPerson || isReservationDayUse
          ? toDate
          : DateManager.getNextDay(fromDate),
    },
  ];
};

export const prepareDataForNewAddedItems = (
  formValues: FormValues,
  purchaseItems: PurchaseItem[],
  previousFormValue: FormValues
): ParsedPurchaseElementData[] => {
  const itemIds = Object.keys(formValues);

  return itemIds
    .filter((id) => previousFormValue[id]?.quantity !== formValues[id].quantity)
    .map((purchaseElementId: string) => {
      const item = purchaseItems.find((item) => item.id === purchaseElementId)!;
      const { isPerPerson } = item;

      const { PER_PERSON, PER_RESERVATION } = Configurator.dateAnchorCodes;
      const dateAnchor = isPerPerson ? PER_PERSON : PER_RESERVATION;
      const quantity = formValues[purchaseElementId].quantity;
      const transactionId = formValues[purchaseElementId].transactionId;

      return {
        ...(transactionId && { id: transactionId }),
        purchaseElementId,
        dateAnchor,
        quantity,
      };
    });
};

export const mergeDataWithPreAddedItems = (
  newAddedItems: ParsedPurchaseElementData[],
  preAddedPurchaseElements: PurchaseElementData[]
): ParsedPurchaseElementData[] => [
  ...preAddedPurchaseElements.reduce(
    (prev: ParsedPurchaseElementData[], preAddedItem: PurchaseElementData) => {
      const { purchaseElementId, id, dateAnchor, quantity } = preAddedItem;

      const itemIndex = newAddedItems.findIndex(
        (element) =>
          element.purchaseElementId === purchaseElementId &&
          element.dateAnchor === dateAnchor
      );
      const foundedItem =
        itemIndex > -1 && newAddedItems.splice(itemIndex, 1)[0];

      if (foundedItem) {
        const additionalItems = foundedItem.quantity;

        prev.push({
          purchaseElementId,
          dateAnchor: dateAnchor as string,
          id,
          quantity: quantity + additionalItems,
        });
      }

      return prev;
    },
    []
  ),
  ...newAddedItems,
];
