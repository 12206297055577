import {
  fullCheckOut,
  sendInvoiceEmail,
  updateFolioStyleCode,
} from 'store/cashiering/actions';
import {
  getActiveFolios,
  getAvailableFolioStyles,
  getFoliosToCheckoutRemotely,
  getIsEveryFolioHasAvailableFolioStyle,
} from 'store/cashiering/selectors';
import { getEmailAddressIfExist } from 'store/profile/selectors';
import { Dispatch, GetState } from 'store/utils/actions';
import { Folio } from 'types/Api/Cashiering';
import { Configurator } from 'utils';
import Router, { Path } from 'utils/Router';

import types from './types';

const sendInvoiceEmailsForCheckOutedFolios =
  (folios: Folio[]) =>
  async (dispatch: Dispatch): Promise<void> => {
    const {
      entityCodes: { BILLING_EMAIL },
      emailTypes,
    } = Configurator;

    await Promise.all(
      folios.map(async (folio) => {
        const billingEmailType = Configurator.getEntityTypeCode(
          emailTypes,
          BILLING_EMAIL
        );

        const email = getEmailAddressIfExist(
          folio?.profile?.communicationDetails,
          billingEmailType
        );

        if (!email) return;

        await dispatch(sendInvoiceEmail(folio.id, [email]));
      })
    );
  };

export const continueCheckOutAsFastTrackProcess =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({ type: types.START_FAST_CHECK_OUT_PROCESS });

    const state = getState();
    const isEveryFolioHasAvailableFolioStyle =
      getIsEveryFolioHasAvailableFolioStyle(state);

    const isDocumentSelectionEnabled = Configurator.getSwitch(
      Configurator.switchCodes.SHOW_FISCAL_DOCUMENT_SELECTION
    );

    Router.removeStep(Path.checkOut, 'PAYMENT');

    if (
      !isDocumentSelectionEnabled ||
      (isDocumentSelectionEnabled && isEveryFolioHasAvailableFolioStyle)
    ) {
      Router.removeStep(Path.checkOut, 'CHARGES');
      await dispatch(executeFastTrackCheckOut());
    }
  };

export const executeFastTrackCheckOut =
  () =>
  async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    dispatch({ type: types.EXECUTE_FAST_CHECK_OUT_PROCESS_REQUEST });

    try {
      const state = getState();
      const foliosToCheckoutRemotely = getFoliosToCheckoutRemotely(state);
      const availableFolioStyles = getAvailableFolioStyles(state);

      const isDocumentSelectionEnabled = Configurator.getSwitch(
        Configurator.switchCodes.SHOW_FISCAL_DOCUMENT_SELECTION
      );

      if (isDocumentSelectionEnabled) {
        await Promise.all(
          foliosToCheckoutRemotely.map((folio) => {
            const folioStyleCode = availableFolioStyles?.[folio.id]?.[0]?.code;
            if (!folioStyleCode) return;

            return dispatch(updateFolioStyleCode(folio.id, folioStyleCode));
          })
        );

        await dispatch(fullCheckOut());
        await dispatch(
          sendInvoiceEmailsForCheckOutedFolios(foliosToCheckoutRemotely)
        );
      } else if (!isDocumentSelectionEnabled) {
        await dispatch(fullCheckOut());
        await dispatch(
          sendInvoiceEmailsForCheckOutedFolios(foliosToCheckoutRemotely)
        );
      }
    } finally {
      dispatch({ type: types.EXECUTE_FAST_CHECK_OUT_PROCESS_FINISHED });
    }
  };
