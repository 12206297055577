import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { List, ListHeader, ListRow, RowItem } from 'components/List';
import { compose } from 'redux';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import { MultiRoomReservationsListDataModel } from '../types';

import { listConfig } from './listConfig';
import styles from './MultiRoomReservationList.style';

interface PassedProps {
  data: MultiRoomReservationsListDataModel[];
  onItemClick: (reservationId: string) => void;
}

export interface MultiRoomReservationListProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class MultiRoomReservationList extends Component<MultiRoomReservationListProps> {
  public static defaultProps = {
    data: [],
  };

  private headers: string[];

  constructor(props: MultiRoomReservationListProps) {
    super(props);

    const { t } = props;
    this.headers = [
      t('GUEST'),
      t('CONFIRMATION_NO'),
      t('STAY'),
      t('ROOM_NO'),
      '',
    ];
  }

  public render() {
    const { t, onItemClick, data, classes } = this.props;

    return (
      <div>
        <List listConfig={listConfig}>
          <ListHeader headers={this.headers} />
          {data.map(
            ({ fullName, confirmationNumber, stay, roomNumber, id }) => (
              <ListRow key={`multiroom-${id}`}>
                <RowItem>{fullName}</RowItem>
                <RowItem>{confirmationNumber}</RowItem>
                <RowItem>{stay}</RowItem>
                <RowItem>{roomNumber}</RowItem>
                <RowItem>
                  <Button
                    pattern={ButtonPattern.secondary}
                    onClick={() => onItemClick(confirmationNumber)}
                    className={classes.selectButton}
                  >
                    {t('CHECK_IN').toUpperCase()}
                  </Button>
                </RowItem>
              </ListRow>
            )
          )}
        </List>
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(MultiRoomReservationList) as (props: PassedProps) => JSX.Element;
