import { MomentInput } from 'moment';
import { DateManager } from 'utils';

import { ValidationResult, ValidationSeverity } from '@ac/react-infrastructure';

export enum CompareDateType {
  isBefore = 'isBefore',
  isAfter = 'isAfter',
  isSameOrAfter = 'isSameOrAfter',
  isSameOrBefore = 'isSameOrBefore',
}

const dateComparerMap = {
  [CompareDateType.isBefore]: DateManager.checkIfBefore,
  [CompareDateType.isAfter]: DateManager.checkIfAfter,
  [CompareDateType.isSameOrAfter]: DateManager.checkIfSameOrAfter,
  [CompareDateType.isSameOrBefore]: DateManager.checkIfSameOrBefore,
};

export const createDateCompareValidator =
  (
    description: string,
    compareDate: MomentInput,
    compareType: CompareDateType,
    inputDateFormat?: string
  ) =>
  (value: string): ValidationResult => {
    const year = DateManager.getObjectDate(value, inputDateFormat).year;
    const compareFunction = dateComparerMap[compareType];

    if (
      !value ||
      !year ||
      compareFunction?.(compareDate, value, inputDateFormat)
    ) {
      return [];
    }

    return [
      {
        description,
        severity: ValidationSeverity.error,
      },
    ];
  };
