import {
  DictionaryStatusData,
  DictionaryStatuses,
  StoredDictionaryStructure,
} from '../interfaces';

export const selectDictionaryStatusData = <T extends unknown[]>(
  districtStoredData: StoredDictionaryStructure<T> | undefined
): DictionaryStatusData<T> => {
  if (!districtStoredData) return {};

  return {
    isFetching: districtStoredData.status === DictionaryStatuses.fetching,
    isFailed: districtStoredData.status === DictionaryStatuses.failed,
    result: districtStoredData.data || ([] as unknown as T),
  };
};
