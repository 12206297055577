import { ApiError } from 'types/Api/Shared';
import { Api } from 'utils';

import {
  CallApiResponseAction,
  FailureCallApiResponseAction,
  isInterruptedRequest,
} from '../middleware/api';

interface DataFetchingModel {
  [key: string]: boolean;
}

interface LoadingState<T> {
  dataFetching: T;
}

interface SingleLoadingState {
  isFetching: boolean;
}

interface FailureState {
  errors: ApiError[];
}

export const getFetchingData = <T, K extends keyof T>(
  data: T,
  key: K,
  value: boolean
) => ({
  ...data,
  [key]: value,
});

export const enableFetching = <T, K extends keyof T>(data: T, key: K) =>
  getFetchingData(data, key, true);
export const disableFetching = <T, K extends keyof T>(data: T, key: K) =>
  getFetchingData(data, key, false);

export const getStateErrors = <State extends FailureState>(
  state: State,
  action: FailureCallApiResponseAction
): ApiError[] =>
  isInterruptedRequest(action.response)
    ? state.errors
    : Api.getErrors(action.response, state.errors);

export const failure = <T, State>(
  state: State & FailureState & LoadingState<T>,
  action: FailureCallApiResponseAction | CallApiResponseAction,
  key: keyof T
): State & FailureState & LoadingState<T> => ({
  ...state,
  dataFetching: disableFetching(state.dataFetching, key),
  errors: getStateErrors(state, action as FailureCallApiResponseAction),
});

export const request = <T, State>(
  state: State & LoadingState<T>,
  key: keyof T
): State & LoadingState<T> => ({
  ...state,
  dataFetching: enableFetching(state.dataFetching, key),
});

export const success = <T, State>(
  state: State & LoadingState<T>,
  key: keyof T
): State & LoadingState<T> => ({
  ...state,
  dataFetching: disableFetching(state.dataFetching, key),
});

export const singleLoadingFailure = <
  State extends FailureState & SingleLoadingState
>(
  state: State,
  action: FailureCallApiResponseAction | CallApiResponseAction
): State => ({
  ...state,
  isFetching: false,
  errors: getStateErrors(state, action as FailureCallApiResponseAction),
});

export const singleLoadingRequest = <State extends SingleLoadingState>(
  state: State
): State => ({
  ...state,
  isFetching: true,
});

export const singleLoadingSuccess = <State extends SingleLoadingState>(
  state: State
): State => ({
  ...state,
  isFetching: false,
});
