import { createSelector } from 'reselect';
import { getProfileConsents } from 'store/profile/selectors';
import Store from 'types/Store';
import { Configurator } from 'utils';
import { getCachedConfirmationFormData } from 'views/CheckInConfirmation/store/selectors';

import { ApiError } from '@ac/library-api';

import { DocumentScanningResultDetails } from '@gss/api/KioskApi/entries';

import { CurrentProcessConsent } from './interfaces';

export const getIsCheckInProcessFetching = (state: Store): boolean =>
  Object.values(state.checkInProcess.dataFetching).includes(true);

export const checkInProcessErrors = (state: Store): Array<Error | ApiError> =>
  state.checkInProcess.errors;

export const getCheckInProcessSessionId = (state: Store): string | undefined =>
  state.checkInProcess.sessionId;

export const getConfirmedScannedDocument = (
  state: Store
): DocumentScanningResultDetails | undefined =>
  state.checkInProcess.confirmedScannedDocument;

export const getCurrentProcessConsents = createSelector(
  [getProfileConsents, getCachedConfirmationFormData],
  (profileConsents, confirmationData): CurrentProcessConsent[] => {
    const newConsents = confirmationData?.termsAndCondition;
    const consentsToMap: Array<[string, boolean]> = newConsents
      ? Object.entries(newConsents)
      : profileConsents.map((consent) => [
          consent.consentId,
          consent.isGranted,
        ]);

    return consentsToMap.map(([id, isGranted]) => {
      const storedConsent = profileConsents.find(
        (consent) => consent.consentId === id
      );
      const isCBPConsentStored =
        storedConsent?.type === Configurator.consentTypeCodes.CROSS_BORDER;

      return {
        id,
        isGranted,
        isOptional:
          storedConsent && isCBPConsentStored ? !storedConsent.isGranted : true,
      };
    });
  }
);

export const getCheckInSummaryPdf = (state: Store): string | undefined =>
  state.checkInProcess.summaryPdf;
