import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  getIsAppInitialized,
  getUsedPropertyId,
} from '@gss/store/configuration/selectors';
import { paths } from '@LEGACY/utils/Router';

export const requirePropertyInitialized = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): React.ComponentType<Props> => {
  const PropertyInitializedValidation = (props: Props): JSX.Element => {
    const currentPropertyId = useSelector(getUsedPropertyId);
    const isAppInitialized = useSelector(getIsAppInitialized);

    if (!isAppInitialized || !currentPropertyId) {
      return <Redirect to={paths.LOGIN} />;
    }

    return <WrappedComponent {...props} />;
  };

  return PropertyInitializedValidation;
};
