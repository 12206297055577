import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { ChargesFolio } from 'types/Api/Cashiering';
import CheckOutMinibar from 'views/CheckOutMinibar';

import { Grid, Modal } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './MinibarModal.style';

interface PassedProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  folio: ChargesFolio;
}

interface MinibarModalProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class MinibarModal extends PureComponent<MinibarModalProps> {
  public static defaultProps = {
    isOpen: false,
  };

  public render() {
    const { classes, isOpen, onClose, onSubmit, folio } = this.props;

    return (
      <Modal className="with-default-kiosk-components-theme" open={isOpen}>
        <Grid className={classes.wrapper}>
          <CheckOutMinibar
            onClose={onClose}
            onSubmit={onSubmit}
            folio={folio}
          />
        </Grid>
      </Modal>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(MinibarModal) as (
  props: PassedProps
) => JSX.Element;
