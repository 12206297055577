import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Configurator } from 'utils';

import { Body, Section, Text } from '@ac/kiosk-components';

import OverlayView from '../OverlayView';

export interface ConsentDetails {
  id: string;
  isGranted: boolean;
  isRevokePossible: boolean;
}

interface ConsentDetailsOverlayProps {
  consentDetails: ConsentDetails;
  onCancel?: (consentId: string, isAccepted: boolean) => void;
  onConfirm?: (consentId: string, isAccepted: boolean) => void;
}

const ConsentDetailsOverlay = ({
  consentDetails,
  onCancel,
  onConfirm,
}: ConsentDetailsOverlayProps) => {
  const { t, i18n } = useTranslation();

  const handleCancelClick = useCallback(() => {
    onCancel?.(consentDetails.id, false);
  }, [consentDetails, onCancel]);

  const handleConfirmClick = useCallback(() => {
    onConfirm?.(consentDetails.id, true);
  }, [consentDetails, onConfirm]);

  const consentDictionary = useMemo(() => {
    return Configurator.consents.find(
      (consent) => consent.id === consentDetails.id
    );
  }, [consentDetails.id]);

  const title = useMemo(() => {
    return consentDictionary
      ? Configurator.getDescription(consentDictionary.description)
      : '';
  }, [consentDictionary, i18n.language]);

  const body = useMemo(() => {
    return consentDictionary
      ? Configurator.getDescription(consentDictionary.consentBody)
      : undefined;
  }, [consentDictionary, i18n.language]);

  const declineButtonTitle = useMemo(() => {
    return consentDetails.isGranted ? t('REVOKE') : t('DECLINE');
  }, [consentDetails.isGranted, i18n.language]);

  return (
    <OverlayView
      title={title}
      hasCancelButton={consentDetails.isRevokePossible}
      cancelButtonLabel={declineButtonTitle}
      onCancelClick={handleCancelClick}
      hasConfirmButton
      confirmButtonLabel={t('ACCEPT')}
      onConfirmClick={handleConfirmClick}
    >
      <Body>
        <Section>
          <Text>{body || t('NOT_PROVIDED')}</Text>
        </Section>
      </Body>
    </OverlayView>
  );
};

export default ConsentDetailsOverlay;
