import {
  buildFIQLFilter,
  FIQLOperators,
  PageResponseDto,
  ReservationDashboardItem,
} from '@ac/library-api';
import { ViewsApi } from '@ac/library-api/dist/api/v0/booking/reservations';
import { repeatableCall } from '@ac/library-utils/dist/utils';

import { ONE_SECOND } from '@gss/configs/timers';
import { Configurator } from '@LEGACY/utils';

const checkedInStatuses = [
  Configurator.reservationStatuses.IN_HOUSE,
  Configurator.reservationStatuses.DUE_OUT,
];

const isReservationInCheckedInStatus = (
  reservation?: ReservationDashboardItem
) => {
  const statusCode = reservation?.statusCode?.code;

  return !!statusCode && checkedInStatuses.includes(statusCode);
};

export const verifyIfReservationViewIsCheckedInStatus = async (
  id: string
): Promise<boolean> => {
  const reservationResponse = await repeatableCall(
    async () => {
      try {
        return ViewsApi.getDashboard({
          queryParams: {
            filter: buildFIQLFilter('id', FIQLOperators.equal, id),
          },
        });
      } catch (error) {
        return error;
      }
    },
    (response: PageResponseDto<ReservationDashboardItem> | undefined) =>
      isReservationInCheckedInStatus(response?.results[0]),
    { repeatCount: 5, intervalTime: ONE_SECOND }
  );

  return isReservationInCheckedInStatus(reservationResponse?.results[0]);
};
