export enum CommunicationDetailsFormSection {
  contact = 'contact',
  mainAddress = 'mainAddress',
  scannedAddress = 'scannedAddress',
  additionalData = 'additionalData',
}

export interface CheckInCommunicationDetailsFormValues {
  [CommunicationDetailsFormSection.contact]: ContactFormValues;
  [CommunicationDetailsFormSection.mainAddress]: AddressSubFormValues;
  [CommunicationDetailsFormSection.scannedAddress]?:
    | AddressSubFormValues
    | undefined;
  [CommunicationDetailsFormSection.additionalData]?:
    | AdditionalInfoFormValues
    | undefined;
}

export interface ContactFormValues {
  prefix?: string | undefined;
  mobile?: string | undefined;
  email?: string | undefined;
}

export interface AdditionalInfoFormValues {
  nationalityCode?: string | undefined;
  taxId?: string | undefined;
}

export interface AddressSubFormValues {
  addressLine1?: string | undefined;
  addressLine2?: string | undefined;
  postCode?: string | undefined;
  city?: string | undefined;
  countryCode?: string | undefined;
  stateCode?: string | undefined;
  districtId?: string | undefined;
}
