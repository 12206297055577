import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Configurator } from 'utils';

import { RadioButton, Text, TextSize, TextWeight } from '@ac/kiosk-components';
import { formRadioButtonFieldFactory } from '@ac/react-infrastructure';

import { sortDisplayElements } from '@gss/utils';
import { mapRadioFieldRenderProps } from '@gss/utils/form';

import { ConfirmationFormProperties, ConfirmationFormValues } from '../types';

import './PurposeOfStaySubForm.scss';

interface PurposeOfStaySubFormProps {
  dataTestSelector?: string;
  className?: string;
}

const FormRadioField =
  formRadioButtonFieldFactory<
    Pick<ConfirmationFormValues, ConfirmationFormProperties.purposeOfStay>
  >();

const PurposeOfStaySubForm = ({
  dataTestSelector,
  className,
}: PurposeOfStaySubFormProps) => {
  const [t] = useTranslation();

  const purposeOfStayOptions = useMemo(() => {
    return sortDisplayElements(Configurator.purposesOfStay).map((purpose) => ({
      value: purpose.id,
      title: Configurator.getDescription(purpose.description),
    }));
  }, []);

  return (
    <div
      className={classNames('check-in-purpose-of-stay-wrapper', className)}
      data-test-selector={dataTestSelector}
    >
      <Text size={TextSize.xlg} weight={TextWeight.light}>
        {t('PURPOSE_OF_STAY')}
      </Text>
      <Text className="spacing-top-sm spacing-bottom-md" hint>
        {t('CHOOSE_MAIN_PURPOSE')}
      </Text>
      <div className="check-in-purpose-of-stay-options-wrapper">
        {purposeOfStayOptions.map((option) => (
          <FormRadioField
            key={option.value}
            valuePath={ConfirmationFormProperties.purposeOfStay}
            value={option.value}
          >
            {(fieldRenderProps) => (
              <RadioButton
                {...mapRadioFieldRenderProps(fieldRenderProps)}
                label={option.title}
              />
            )}
          </FormRadioField>
        ))}
      </div>
    </div>
  );
};

export default PurposeOfStaySubForm;
