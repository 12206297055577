import { SettingsCodeMap } from '../../utils';

export interface StylesSettingsState {
  THEME_COLOR?: string;
  THEME_FONT?: string;
  HEADER_BACKGROUND?: string;
  HEADER_FONT_COLOR?: string;
  BODY_BACKGROUND?: string;
  BODY_FONT_COLOR?: string;
  FOOTER_BACKGROUND?: string;
  FOOTER_FONT_COLOR?: string;
  RESERVATION_HEADER_BACKGROUND?: string;
  RESERVATION_HEADER_FONT_COLOR?: string;
  HINT_FONT_COLOR?: string;
  BUTTON_PRIMARY_BACKGROUND_ACTIVE?: string;
  BUTTON_PRIMARY_BORDER_ACTIVE?: string;
  BUTTON_PRIMARY_TEXT_ACTIVE?: string;
  BUTTON_PRIMARY_BACKGROUND_DISABLED?: string;
  BUTTON_PRIMARY_BORDER_DISABLED?: string;
  BUTTON_PRIMARY_TEXT_DISABLED?: string;
  BUTTON_PRIMARY_BACKGROUND_CLICKED?: string;
  BUTTON_PRIMARY_BORDER_CLICKED?: string;
  BUTTON_PRIMARY_TEXT_CLICKED?: string;
  BUTTON_SECONDARY_BACKGROUND_ACTIVE?: string;
  BUTTON_SECONDARY_BORDER_ACTIVE?: string;
  BUTTON_SECONDARY_TEXT_ACTIVE?: string;
  BUTTON_SECONDARY_BACKGROUND_DISABLED?: string;
  BUTTON_SECONDARY_BORDER_DISABLED?: string;
  BUTTON_SECONDARY_TEXT_DISABLED?: string;
  BUTTON_SECONDARY_BACKGROUND_CLICKED?: string;
  BUTTON_SECONDARY_BORDER_CLICKED?: string;
  BUTTON_SECONDARY_TEXT_CLICKED?: string;
  ADDONS_BACKGROUND_COLOR?: string;
  COUNTER_FINISHED_BACKGROUND_COLOR?: string;
  COUNTER_BACKGROUND_COLOR?: string;
  COUNTER_BORDER_COLOR?: string;
  COUNTER_FONT_COLOR?: string;
  SEPARATE_HEADER_BG?: boolean;
  SEPARATE_FOOTER_BG?: boolean;
  SEPARATE_RESERVATION_HEADER_BG?: boolean;
  SEPARATE_WELCOME_HEADER_BG?: boolean;
}

export const stylesCodesMap: SettingsCodeMap<StylesSettingsState> = {
  THEME_COLOR: {
    key: 'THEMECOLOR',
  },
  THEME_FONT: {
    key: 'KIOSKUSEDFONT',
  },
  HEADER_BACKGROUND: {
    key: 'KIOSKHEADERBGCOLOR',
  },
  HEADER_FONT_COLOR: {
    key: 'KIOSKHEADERFONTCOLOR',
  },
  BODY_BACKGROUND: {
    key: 'KIOSKBODYBGCOLOR',
  },
  BODY_FONT_COLOR: {
    key: 'KIOSKBODYFONTCOLOR',
  },
  FOOTER_BACKGROUND: {
    key: 'KIOSKFOOTERBGCOLOR',
  },
  FOOTER_FONT_COLOR: {
    key: 'KIOSKFOOTERFONTCOLOR',
  },
  RESERVATION_HEADER_BACKGROUND: {
    key: 'KIOSKRESERVATIONHEADERBGCOLOR',
  },
  RESERVATION_HEADER_FONT_COLOR: {
    key: 'KIOSKRESERVATIONHEADERFONTCOLOR',
  },
  HINT_FONT_COLOR: {
    key: 'KIOSKHINTFONTCOLOR',
  },
  BUTTON_PRIMARY_BACKGROUND_ACTIVE: {
    key: 'MAINACTIVEBACKGROUNDCOLOR',
  },
  BUTTON_PRIMARY_BORDER_ACTIVE: {
    key: 'MAINACTIVEBORDERCOLOR',
  },
  BUTTON_PRIMARY_TEXT_ACTIVE: {
    key: 'MAINACTIVETEXTCOLOR',
  },
  BUTTON_PRIMARY_BACKGROUND_DISABLED: {
    key: 'MAINDISABLEDBACKGROUNDCOLOR',
  },
  BUTTON_PRIMARY_BORDER_DISABLED: {
    key: 'MAINDISABLEDBORDERCOLOR',
  },
  BUTTON_PRIMARY_TEXT_DISABLED: {
    key: 'MAINDISABLEDTEXTCOLOR',
  },
  BUTTON_PRIMARY_BACKGROUND_CLICKED: {
    key: 'MAINCLICKEDBACKGROUNDCOLOR',
  },
  BUTTON_PRIMARY_BORDER_CLICKED: {
    key: 'MAINCLICKEDBORDERCOLOR',
  },
  BUTTON_PRIMARY_TEXT_CLICKED: {
    key: 'MAINCLICKEDTEXTCOLOR',
  },
  BUTTON_SECONDARY_BACKGROUND_ACTIVE: {
    key: 'SECONDARYACTIVEBACKGROUNDCOLOR',
  },
  BUTTON_SECONDARY_BORDER_ACTIVE: {
    key: 'SECONDARYACTIVEBORDERCOLOR',
  },
  BUTTON_SECONDARY_TEXT_ACTIVE: {
    key: 'SECONDARYACTIVETEXTCOLOR',
  },
  BUTTON_SECONDARY_BACKGROUND_DISABLED: {
    key: 'SECONDARYDISABLEDBACKGROUNDCOLOR',
  },
  BUTTON_SECONDARY_BORDER_DISABLED: {
    key: 'SECONDARYDISABLEDBORDERCOLOR',
  },
  BUTTON_SECONDARY_TEXT_DISABLED: {
    key: 'SECONDARYDISABLEDTEXTCOLOR',
  },
  BUTTON_SECONDARY_BACKGROUND_CLICKED: {
    key: 'SECONDARYCLICKEDBACKGROUNDCOLOR',
  },
  BUTTON_SECONDARY_BORDER_CLICKED: {
    key: 'SECONDARYCLICKEDBORDERCOLOR',
  },
  BUTTON_SECONDARY_TEXT_CLICKED: {
    key: 'SECONDARYCLICKEDTEXTCOLOR',
  },
  ADDONS_BACKGROUND_COLOR: {
    key: 'ADDONBACKGROUNDCOLOR',
  },
  COUNTER_FINISHED_BACKGROUND_COLOR: {
    key: 'KIOSKPROGRESSFINISHEDBG',
  },
  COUNTER_BACKGROUND_COLOR: {
    key: 'KIOSKPROGRESSTODOBG',
  },
  COUNTER_BORDER_COLOR: {
    key: 'KIOSKPROGRESSSTEPBORDER',
  },
  COUNTER_FONT_COLOR: {
    key: 'KIOSKPROGRESSFONT',
  },
  SEPARATE_HEADER_BG: {
    key: 'SEPARATEHEADERBG',
  },
  SEPARATE_FOOTER_BG: {
    key: 'SEPARATEFOOTERBG',
  },
  SEPARATE_RESERVATION_HEADER_BG: {
    key: 'KIOSKSEPARATERESERVATIONHEADER',
  },
  SEPARATE_WELCOME_HEADER_BG: {
    key: 'SEPARATEMAINSCRHEADERBG',
  },
};
