import i18next from 'i18next';
import { getCurrentBusinessDate } from 'store/propertyManagement/selectors';
import {
  getAddonsPriceSum,
  getCurrency,
  getDepartureHour,
  getRoomPrice,
  isRatePlanConfidential,
  isReservationDayUse,
} from 'store/reservation/selectors';
import { getGuestDetails, getReservationDetails } from 'store/selectors';
import { getFeatureToggles } from 'store/setup/selectors';
import Store from 'types/Store';
import { Configurator, DateManager } from 'utils';

import { checkInReservationTemplate } from '@gss/pdfTemplates';
import { sortDisplayElements } from '@gss/utils';
import { blobToBase64 } from '@gss/utils/files';

import { getCurrentProcessConsents } from '../selectors';

export async function preparePdfContent(state: Store, signature: Blob) {
  const featureToggles = getFeatureToggles(state);
  const { SHOW_TERMS_AND_CONDITIONS, SHOW_TAX_ID } = Configurator.switchCodes;
  const isTermsAndConditionsEnabled = Configurator.getSwitch(
    SHOW_TERMS_AND_CONDITIONS
  );
  const sortedConsentEntities = sortDisplayElements(Configurator.consents);
  const showTaxId = Configurator.getSwitch(SHOW_TAX_ID);
  const disclaimerContent = Configurator.getTranslation(
    Configurator.getTranslationCodes()?.SELF_SERVICE_DISCLAIMER,
    i18next.language
  );

  const guestData = getGuestDetails(state);
  const reservationData = getReservationDetails(state);
  const currency = getCurrency(state);
  const businessDate = getCurrentBusinessDate(state);
  const consents = getCurrentProcessConsents(state);
  const isPriceHidden = isRatePlanConfidential(state);
  const isDayUse = isReservationDayUse(state);
  const departureHour = getDepartureHour(state);
  const roomPrice = getRoomPrice(state);
  const addonsPrice = getAddonsPriceSum(state);

  const signatureBase64 = await blobToBase64(signature);

  const date = DateManager.getFormattedDate(businessDate);
  const time = DateManager.getHour(new Date());

  const mappedConsents = sortedConsentEntities.map(({ id, description }) => {
    const changedConsents = consents?.find((item) => item.id === id);

    return {
      description: Configurator.getDescription(description),
      isGranted: changedConsents?.isGranted || false,
      isOptional: changedConsents?.isOptional,
    };
  });

  return checkInReservationTemplate({
    name: guestData.name,
    membership: guestData.membership,
    contacts: {
      address: guestData.address?.split('\n')?.filter(Boolean),
      email: guestData.email,
      mobile: guestData.mobile,
      taxId: showTaxId && guestData.taxId ? guestData.taxId : undefined,
    },
    period: reservationData.bookingPeriod,
    room: {
      name: reservationData.roomName,
      number: reservationData.roomNumber,
    },
    bookingInfo: {
      isDayUse,
      departureHour,
      adults: reservationData.adults,
      children: reservationData.children,
      nights: reservationData.nights,
    },
    priceInfo: {
      totalPrice: isPriceHidden
        ? Configurator.pricePlaceholder
        : `${reservationData.totalPrice.toFixed(2)} ${currency}`,
      roomPrice: isPriceHidden
        ? Configurator.pricePlaceholder
        : `${roomPrice.toFixed(2)} ${currency}`,
      addonsPrice: addonsPrice
        ? `${addonsPrice.toFixed(2)} ${currency}`
        : undefined,
    },
    ...(isTermsAndConditionsEnabled ? { consents: mappedConsents } : {}),
    disclaimer: disclaimerContent,
    dateTime: `${date} ${time}`,
    signature: signatureBase64,
  });
}
