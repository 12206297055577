import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const wrapper: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

const BUTTON_MAX_WIDTH = '40%';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    buttonsWrapper: {
      ...wrapper,
    },
    descriptionTitle: {
      fontSize: '1.5rem',
      marginBottom: spacing(2),
      textAlign: 'center',
    },
    alertIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      color: palette.primary.main,
      fontSize: '3.25rem',
    },
    content: {
      flex: 1,
      flexDirection: 'column',
      ...wrapper,
    },
    title: {
      fontSize: '1.5rem',
      marginBottom: spacing(1.375),
    },
    description: {
      fontSize: '1rem',
      padding: `0px ${spacing(3.5)}px`,
      boxSizing: 'border-box',
      textAlign: 'center',
    },
    descriptionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      maxWidth: BUTTON_MAX_WIDTH,
    },
    buttonLabel: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
