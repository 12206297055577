const FETCH_ROOM_TYPE_REQUEST = 'FETCH_ROOM_TYPE_REQUEST';
const FETCH_ROOM_TYPE_SUCCESS = 'FETCH_ROOM_TYPE_SUCCESS';
const FETCH_ROOM_TYPE_FAILURE = 'FETCH_ROOM_TYPE_FAILURE';

const FETCH_ROOM_REQUEST = 'FETCH_ROOM_REQUEST';
const FETCH_ROOM_SUCCESS = 'FETCH_ROOM_SUCCESS';
const FETCH_ROOM_FAILURE = 'FETCH_ROOM_FAILURE';

const FETCH_ROOM_LIST_REQUEST = 'FETCH_ROOM_LIST_REQUEST';
const FETCH_ROOM_LIST_SUCCESS = 'FETCH_ROOM_LIST_SUCCESS';
const FETCH_ROOM_LIST_FAILURE = 'FETCH_ROOM_LIST_FAILURE';

const CLEAR_ROOM_ERRORS = 'CLEAR_ROOM_ERRORS';

export default {
  FETCH_ROOM_TYPE_REQUEST,
  FETCH_ROOM_TYPE_SUCCESS,
  FETCH_ROOM_TYPE_FAILURE,
  FETCH_ROOM_REQUEST,
  FETCH_ROOM_SUCCESS,
  FETCH_ROOM_FAILURE,
  FETCH_ROOM_LIST_REQUEST,
  FETCH_ROOM_LIST_SUCCESS,
  FETCH_ROOM_LIST_FAILURE,
  CLEAR_ROOM_ERRORS,
};
