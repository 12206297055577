/* eslint-disable no-fallthrough */
import {
  fetchAuthorizations,
  initialAuthorizations,
} from 'store/cashiering/authorization/reducer';
import { fetchFolio } from 'store/cashiering/folio/reducer';
import {
  initialOutstandingDeposit,
  reservationOutstandingDeposit,
} from 'store/cashiering/reservationOutstandingDeposit/reducer';
import {
  CallApiResponseAction,
  CallApiSpecificResponseAction,
} from 'store/middleware/api';
import { failure, request, success } from 'store/utils/reducer';
import { Folio } from 'types/Api/Cashiering';
import { Transaction } from 'types/Api/Shared';
import { State } from 'types/Store/CashieringStore';
import { Api, Configurator } from 'utils';
import { reportSelfServiceActionApiError } from 'utils/logReportPatterns';

import types from './types';

const API_ACCEPTED_CODE = 202;

const initialState: State = {
  dataFetching: {
    checkOut: false,
    cashiering: false,
    availableFolioStyles: false,
  },
  availableFolioStyleIds: {},
  folios: [],
  errors: [],
  paymentMethods: [],
  authorizationDetails: {
    authorizationRule: '',
    accountId: '',
    folioWindowAssignments: [],
  },
  account: {
    id: '',
    linkedProfiles: {
      companyProfileId: '',
      individualProfileId: '',
      travelAgentProfileId: '',
    },
    profileRoleCode: '',
    version: 0,
  },
  checkOut: {
    id: '',
    status: '',
  },
  voidAuthorization: {
    id: '',
    status: '',
  },
  updateAuthorization: {
    id: '',
    status: '',
  },
  checkOutFolio: {
    ids: [],
    wrongVersionedFolios: [],
    status: '',
  },
  transaction: {
    id: '',
    status: '',
  },
  chargeReservation: {
    id: '',
    status: '',
  },
  billingInstructions: {
    targetFolioId: '',
    operationId: '',
    status: '',
    instructions: [],
  },
  finalInvoice: {
    id: '',
  },
  preAuthorization: {
    isPreAuthorized: false,
    operationStatusUrl: '',
    operationId: '',
    isLongRunningProcess: false,
    terminalUrl: '',
    terminalRequestPayload: '',
    terminalStatus: '',
    terminalError: false,
  },
  reservationOutstandingDeposit: initialOutstandingDeposit,
  activeAuthorizations: initialAuthorizations,
  minibarPendingCharges: 0,
  emailToSendInvoice: [],
  preAuthorizationAmount: 0,
  notPostedCharges: [],
  emailsSent: [],
};

const getUpdatedFolios = (
  payload: { folioId: string; pendingTransaction: Transaction },
  folios: Folio[]
) => {
  const { pendingTransaction, folioId } = payload;
  const updatedFolios: Folio[] = folios.map((folio) =>
    folio.id === folioId
      ? {
          ...folio,
          transactions: [...folio.transactions, pendingTransaction],
        }
      : folio
  );

  return updatedFolios;
};

const getOperationId = (headerLocation: string) => {
  const result = headerLocation.split('/');

  return result[result.length - 1];
};

export default (state = initialState, action: CallApiResponseAction): State => {
  switch (action.type) {
    case types.CHECK_OUT_REQUEST:
      return request(state, 'cashiering');
    case types.CHECK_OUT_SUCCESS: {
      const { location: headerLocation } = action.response.headers;
      const { checkOut } = state;

      return {
        ...success(state, 'cashiering'),
        checkOut: {
          ...checkOut,
          id: getOperationId(headerLocation),
        },
      };
    }
    case types.CHECK_OUT_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_PAYMENT_METHODS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_PAYMENT_METHODS_SUCCESS: {
      const { results: paymentMethods } = action.response.data;

      return {
        ...success(state, 'cashiering'),
        paymentMethods,
      };
    }
    case types.FETCH_PAYMENT_METHODS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.VOID_AUTHORIZATION_REQUEST:
      return request(state, 'cashiering');
    case types.VOID_AUTHORIZATION_SUCCESS: {
      const { location: headerLocation } = action.response.headers;
      const { voidAuthorization } = state;

      return {
        ...success(state, 'cashiering'),
        voidAuthorization: {
          ...voidAuthorization,
          id: getOperationId(headerLocation),
        },
      };
    }
    case types.VOID_AUTHORIZATION_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_VOID_AUTHORIZATION_STATUS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_VOID_AUTHORIZATION_STATUS_SUCCESS: {
      const { status } = action.response.data;
      const { voidAuthorization } = state;

      return {
        ...success(state, 'cashiering'),
        voidAuthorization: {
          ...voidAuthorization,
          status,
        },
      };
    }
    case types.FETCH_VOID_AUTHORIZATION_STATUS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.ADD_TRANSACTION_REQUEST: {
      const { transaction } = state;

      return {
        ...request(state, 'cashiering'),
        transaction: {
          ...transaction,
          status: Configurator.operationStatuses.PAYMENT_INITIALIZE,
        },
      };
    }
    case types.ADD_TRANSACTION_SUCCESS: {
      const {
        response: {
          headers: { location: headerLocation },
        },
        payload: { pendingTransaction },
      } = action;
      const { transaction, folios } = state;

      return {
        ...success(state, 'cashiering'),
        folios: pendingTransaction
          ? getUpdatedFolios(pendingTransaction, folios)
          : folios,
        transaction: {
          ...transaction,
          id: getOperationId(headerLocation),
        },
      };
    }
    case types.ADD_TRANSACTION_FAILURE: {
      reportSelfServiceActionApiError(action, { payload: action?.payload });

      return failure(state, action, 'cashiering');
    }

    case types.SET_MINIBAR_PENDING_CHARGES: {
      const charges = action.payload;

      return {
        ...state,
        minibarPendingCharges: charges,
      };
    }

    case types.FETCH_ADD_TRANSACTION_STATUS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_ADD_TRANSACTION_STATUS_SUCCESS: {
      const {
        response: {
          data: { status },
        },
        payload,
      } = action;
      const { transaction, folios } = state;
      if (!payload) {
        return {
          ...success(state, 'cashiering'),
          transaction: {
            ...transaction,
            status,
          },
        };
      }
      const { pendingTransaction, folioId } = payload;
      const updatedFolios = folios.map((folio, index) =>
        folio.id === folioId
          ? {
              ...folio,
              transactions: [...folio.transactions, pendingTransaction],
            }
          : folio
      );

      return {
        ...success(state, 'cashiering'),
        folios: payload ? updatedFolios : folios,
        transaction: {
          ...transaction,
          status,
        },
      };
    }
    case types.FETCH_ADD_TRANSACTION_STATUS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_FOLIO_TRANSACTIONS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_FOLIO_TRANSACTIONS_SUCCESS: {
      const { payload: folioId, response } = action;
      const {
        data: { results: transactions },
      } = response;
      const { folios } = state;
      const transactionsFolio = folios.find((folio) => folio.id === folioId);
      const updatedFolio = transactionsFolio
        ? [
            {
              ...transactionsFolio,
              transactions,
            },
          ]
        : [];
      const updatedFolios = [
        ...folios.filter((folio) => folio.id !== folioId),
        ...updatedFolio,
      ];

      return {
        ...success(state, 'cashiering'),
        folios: updatedFolios,
      };
    }
    case types.FETCH_FOLIO_TRANSACTIONS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_FOLIO_PROFILE_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_FOLIO_PROFILE_SUCCESS: {
      const {
        response: { data },
        payload: folioId,
      } = action;
      const { folios } = state;

      const folio = folios.find((folio) => folio.id === folioId)!;
      const updatedFolio: Folio = { ...folio, profile: data };

      return {
        ...success(state, 'cashiering'),
        folios: [
          ...folios.filter((folio) => folio.id !== folioId),
          updatedFolio,
        ],
      };
    }
    case types.FETCH_FOLIO_PROFILE_FAILURE:
      return failure(state, action, 'cashiering');

    case types.CHECK_OUT_FOLIO_REQUEST:
      return request(state, 'cashiering');
    case types.CHECK_OUT_FOLIO_SUCCESS: {
      const { location } = action.response.headers;
      const { checkOutFolio } = state;
      const { payload: folioId } = action;
      const { ids } = checkOutFolio;
      const folio = {
        folioId,
        operationId: getOperationId(location),
      };
      const updatedIds = [...ids.filter((id) => id.folioId !== folioId), folio];

      return {
        ...success(state, 'cashiering'),
        checkOutFolio: {
          ...checkOutFolio,
          ids: updatedIds,
        },
      };
    }
    case types.CHECK_OUT_FOLIO_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_FOLIOS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_FOLIOS_SUCCESS: {
      const { results: folios } = action.response.data;

      return {
        ...success(state, 'cashiering'),
        folios,
      };
    }
    case types.FETCH_FOLIOS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_FOLIO.REQUEST:
    case types.FETCH_FOLIO.SUCCESS:
    case types.FETCH_FOLIO.FAILURE:
      return fetchFolio(state, action as CallApiSpecificResponseAction);

    case types.FETCH_CASHIERING_ACCOUNT_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_CASHIERING_ACCOUNT_SUCCESS: {
      const account = action.response.data;

      return {
        ...success(state, 'cashiering'),
        account,
      };
    }
    case types.FETCH_CASHIERING_ACCOUNT_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_CHECK_OUT_FOLIO_STATUS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_CHECK_OUT_FOLIO_STATUS_SUCCESS: {
      const { data, status: statusData } = action.response.data;
      const { payload: operationId } = action;
      const {
        checkOutFolio,
        checkOutFolio: { wrongVersionedFolios, ids },
      } = state;
      const currentFolio = ids.find((id) => id.operationId === operationId);
      const folioId = currentFolio ? currentFolio.folioId : '';
      const folioErrorMessage = data ? data.message : '';
      const isFolioWrongVersioned = folioErrorMessage.includes(
        'modified by someone else'
      );
      const updatedWrongVersionedFolios = isFolioWrongVersioned
        ? [...wrongVersionedFolios, folioId]
        : wrongVersionedFolios;
      const { operationStatus: status } = statusData;

      return {
        ...success(state, 'cashiering'),
        checkOutFolio: {
          ...checkOutFolio,
          status,
          wrongVersionedFolios: updatedWrongVersionedFolios,
        },
      };
    }
    case types.FETCH_CHECK_OUT_FOLIO_STATUS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_CHECK_OUT_STATUS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_CHECK_OUT_STATUS_SUCCESS: {
      const { operationStatus: status } = action.response.data.status;
      const { checkOut } = state;

      return {
        ...success(state, 'cashiering'),
        checkOut: {
          ...checkOut,
          status,
        },
      };
    }
    case types.FETCH_CHECK_OUT_STATUS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.ADD_COMPANY_FOLIO_REQUEST:
      return request(state, 'cashiering');
    case types.ADD_COMPANY_FOLIO_SUCCESS: {
      const { location } = action.response.headers;
      const { billingInstructions } = state;
      const newFolioId = getOperationId(location);

      return {
        ...success(state, 'cashiering'),
        newFolioId,
        billingInstructions: {
          ...billingInstructions,
          targetFolioId: newFolioId,
        },
      };
    }
    case types.ADD_COMPANY_FOLIO_FAILURE:
      return failure(state, action, 'cashiering');

    case types.ADD_PAYMENT_METHOD_REQUEST:
      return request(state, 'cashiering');
    case types.ADD_PAYMENT_METHOD_SUCCESS: {
      const {
        headers: { location },
      } = action.response;
      const { preAuthorization } = state;
      const apiAccepted = action.response.status === API_ACCEPTED_CODE;
      const apiOK =
        action.response.status >= 200 && action.response.status < 400;
      const apiError = action.response.status >= 400;

      return {
        ...success(state, 'cashiering'),
        preAuthorization: {
          ...preAuthorization,
          isPreAuthorized: !apiAccepted && apiOK,
          operationStatusUrl: Api.extractUrlFromLocationHeader(location),
          operationId: getOperationId(location),
          isLongRunningProcess: apiAccepted,
          terminalError: apiError,
        },
      };
    }
    case types.ADD_PAYMENT_METHOD_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_PAYMENT_METHOD_STATUS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_PAYMENT_METHOD_STATUS_SUCCESS: {
      const { status } = action.response.data;

      return {
        ...success(state, 'cashiering'),
        preAuthorization: {
          ...state.preAuthorization,
          isPreAuthorized:
            status ===
            Configurator.paymentMethodsOperationStatuses.PaymentCompleted,
          operationStatusUrl: '',
          isLongRunningProcess: false,
          terminalUrl: '',
          terminalRequestPayload: '',
          terminalStatus: '',
          terminalError:
            status !==
            Configurator.paymentMethodsOperationStatuses.PaymentCompleted,
        },
      };
    }
    case types.FETCH_PAYMENT_METHOD_STATUS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.ADD_BILLING_INSTRUCTION_REQUEST:
      return request(state, 'cashiering');
    case types.ADD_BILLING_INSTRUCTION_SUCCESS:
      return success(state, 'cashiering');
    case types.ADD_BILLING_INSTRUCTION_FAILURE:
      return failure(state, action, 'cashiering');

    case types.APPLY_BILLING_INSTRUCTIONS_REQUEST:
      return request(state, 'cashiering');
    case types.APPLY_BILLING_INSTRUCTIONS_SUCCESS: {
      const { location } = action.response.headers;
      const { billingInstructions } = state;

      return {
        ...success(state, 'cashiering'),
        billingInstructions: {
          ...billingInstructions,
          operationId: getOperationId(location),
        },
      };
    }
    case types.APPLY_BILLING_INSTRUCTIONS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_SUCCESS: {
      const { operationStatus: status } = action.response.data.status;
      const { billingInstructions } = state;

      return {
        ...success(state, 'cashiering'),
        billingInstructions: {
          ...billingInstructions,
          status,
        },
      };
    }
    case types.FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_BILLING_INSTRUCTIONS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_BILLING_INSTRUCTIONS_SUCCESS: {
      const { results: instructions } = action.response.data;
      const { billingInstructions } = state;

      return {
        ...success(state, 'cashiering'),
        billingInstructions: {
          ...billingInstructions,
          instructions,
        },
      };
    }
    case types.FETCH_BILLING_INSTRUCTIONS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.UPDATE_FOLIO_PROFILE_REQUEST:
      return request(state, 'cashiering');
    case types.UPDATE_FOLIO_PROFILE_SUCCESS:
      return success(state, 'cashiering');
    case types.UPDATE_FOLIO_PROFILE_FAILURE:
      return failure(state, action, 'cashiering');

    case types.SEND_INVOICE_EMAIL_REQUEST:
      return request(state, 'cashiering');
    case types.SEND_INVOICE_EMAIL_SUCCESS: {
      return {
        ...success(state, 'cashiering'),
        emailsSent: [...new Set([...state.emailsSent, ...action.payload])],
      };
    }
    case types.SEND_INVOICE_EMAIL_FAILURE:
      return failure(state, action, 'cashiering');

    case types.SEND_FINAL_INVOICE_MAIL_REQUEST:
      return request(state, 'cashiering');
    case types.SEND_FINAL_INVOICE_MAIL_SUCCESS: {
      const { data: finalInvoice } = action.response;

      return {
        ...success(state, 'cashiering'),
        finalInvoice,
      };
    }
    case types.SEND_FINAL_INVOICE_MAIL_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FULL_CHECK_OUT_REQUEST:
      return request(state, 'checkOut');
    case types.FULL_CHECK_OUT_SUCCESS:
      return success(state, 'checkOut');
    case types.FULL_CHECK_OUT_FAILURE:
      return failure(state, action, 'checkOut');

    case types.CLEAR_CASHIERING_ERRORS:
      return {
        ...state,
        errors: [],
      };

    case types.CLEAR_PRE_AUTHORIZATION:
      return {
        ...state,
        preAuthorization: {
          isPreAuthorized: false,
          operationStatusUrl: '',
          operationId: '',
          isLongRunningProcess: false,
          terminalUrl: '',
          terminalRequestPayload: '',
          terminalStatus: '',
          terminalError: false,
        },
      };

    case types.UPDATE_AUTHORIZATION_REQUEST:
      return request(state, 'cashiering');
    case types.UPDATE_AUTHORIZATION_SUCCESS: {
      const { location } = action.response.headers;
      const { updateAuthorization } = state;

      return {
        ...success(state, 'cashiering'),
        updateAuthorization: {
          ...updateAuthorization,
          id: getOperationId(location),
        },
      };
    }
    case types.UPDATE_AUTHORIZATION_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_UPDATE_AUTHORIZATION_STATUS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_UPDATE_AUTHORIZATION_STATUS_SUCCESS: {
      const { status } = action.response.data;
      const { updateAuthorization } = state;

      return {
        ...success(state, 'cashiering'),
        updateAuthorization: {
          ...updateAuthorization,
          status,
        },
      };
    }
    case types.FETCH_UPDATE_AUTHORIZATION_STATUS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.DELETE_PAYMENT_METHOD_REQUEST:
      return request(state, 'cashiering');
    case types.DELETE_PAYMENT_METHOD_SUCCESS:
      return success(state, 'cashiering');
    case types.DELETE_PAYMENT_METHOD_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_AUTHORIZATION_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_AUTHORIZATION_SUCCESS: {
      const {
        response: { data: authorizationDetails },
      } = action;

      return {
        ...success(state, 'cashiering'),
        authorizationDetails,
      };
    }
    case types.FETCH_AUTHORIZATION_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_RESERVATION_OUTSTANDING_DEPOSIT.REQUEST:
    case types.FETCH_RESERVATION_OUTSTANDING_DEPOSIT.SUCCESS:
    case types.FETCH_RESERVATION_OUTSTANDING_DEPOSIT.FAILURE:
      return reservationOutstandingDeposit(
        state,
        action as CallApiSpecificResponseAction
      );

    case types.FETCH_ACTIVE_AUTHORIZATIONS.REQUEST:
    case types.FETCH_ACTIVE_AUTHORIZATIONS.FINISHED:

    case types.FETCH_ACTIVE_GIVEN_AUTHORIZATIONS.REQUEST:
    case types.FETCH_ACTIVE_GIVEN_AUTHORIZATIONS.FINISHED:

    case types.FETCH_ACTIVE_AUTHORIZATION.REQUEST:
    case types.FETCH_ACTIVE_AUTHORIZATION.SUCCESS:
    case types.FETCH_ACTIVE_AUTHORIZATION.FAILURE:
      return fetchAuthorizations(
        state,
        action as CallApiSpecificResponseAction
      );

    case types.LINK_PAYMENT_METHOD_REQUEST:
      return request(state, 'cashiering');
    case types.LINK_PAYMENT_METHOD_SUCCESS:
      return success(state, 'cashiering');
    case types.LINK_PAYMENT_METHOD_FAILURE:
      return failure(state, action, 'cashiering');

    case types.UNLINK_PAYMENT_METHOD_REQUEST:
      return request(state, 'cashiering');
    case types.UNLINK_PAYMENT_METHOD_SUCCESS:
      return success(state, 'cashiering');
    case types.UNLINK_PAYMENT_METHOD_FAILURE:
      return failure(state, action, 'cashiering');

    case types.TRANSFER_TRANSACTIONS_REQUEST:
      return request(state, 'cashiering');
    case types.TRANSFER_TRANSACTIONS_SUCCESS:
      return success(state, 'cashiering');
    case types.TRANSFER_TRANSACTIONS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.UPDATE_FOLIO_STYLE_CODE_REQUEST:
      return request(state, 'cashiering');
    case types.UPDATE_FOLIO_STYLE_CODE_SUCCESS:
      return success(state, 'cashiering');
    case types.UPDATE_FOLIO_STYLE_CODE_FAILURE:
      return failure(state, action, 'cashiering');

    case types.UPDATE_FOLIO_PROFILE_DETAILS_REQUEST:
      return request(state, 'cashiering');
    case types.UPDATE_FOLIO_PROFILE_DETAILS_SUCCESS:
      return success(state, 'cashiering');
    case types.UPDATE_FOLIO_PROFILE_DETAILS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.SAVE_EMAIL_TO_SEND_INVOICE: {
      const emailToSendInvoice = action.payload;

      return {
        ...state,
        emailToSendInvoice,
      };
    }

    case types.SET_FOLIO_SETTLEMENT_STATE: {
      const { payload: folioId } = action;
      const { folios } = state;

      return {
        ...state,
        folios: folios.map((item) =>
          item.id === folioId ? { ...item, isRecentlySettled: true } : item
        ),
      };
    }

    case types.CHECK_OUT_FOLIO_SEPARATELY_REQUEST: {
      return {
        ...request(state, 'cashiering'),
        checkOutFolio: {
          ids: [],
          wrongVersionedFolios: [],
          status: '',
        },
      };
    }
    case types.CHECK_OUT_FOLIO_SEPARATELY_SUCCESS:
      return success(state, 'cashiering');
    case types.CHECK_OUT_FOLIO_SEPARATELY_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_KIOSK_AUTHORIZATION_AMOUNT_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_KIOSK_AUTHORIZATION_AMOUNT_SUCCESS: {
      const {
        authorization: { amount },
      } = action.response.data;

      return {
        ...success(state, 'cashiering'),
        preAuthorizationAmount: amount,
      };
    }
    case types.FETCH_KIOSK_AUTHORIZATION_AMOUNT_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_NOT_POSTED_CHARGES_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_NOT_POSTED_CHARGES_SUCCESS: {
      const { results: notPostedCharges } = action.response.data;

      return {
        ...success(state, 'cashiering'),
        notPostedCharges,
      };
    }
    case types.FETCH_NOT_POSTED_CHARGES_FAILURE:
      return failure(state, action, 'cashiering');

    case types.CHARGE_RESERVATION_REQUEST:
      return request(state, 'cashiering');
    case types.CHARGE_RESERVATION_SUCCESS: {
      const { location } = action.response.headers;
      const { chargeReservation } = state;

      return {
        ...success(state, 'cashiering'),
        chargeReservation: {
          ...chargeReservation,
          id: getOperationId(location),
        },
      };
    }
    case types.CHARGE_RESERVATION_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_CHARGE_RESERVATION_STATUS_REQUEST:
      return request(state, 'cashiering');
    case types.FETCH_CHARGE_RESERVATION_STATUS_SUCCESS: {
      const { operationStatus: status } = action.response.data.status;
      const { chargeReservation } = state;

      return {
        ...success(state, 'cashiering'),
        chargeReservation: {
          ...chargeReservation,
          status,
        },
      };
    }
    case types.FETCH_CHARGE_RESERVATION_STATUS_FAILURE:
      return failure(state, action, 'cashiering');

    case types.FETCH_AVAILABLE_FOLIO_STYLES_REQUEST: {
      return request(state, 'availableFolioStyles');
    }

    case types.FETCH_AVAILABLE_FOLIO_STYLES_SUCCESS: {
      return {
        ...success(state, 'availableFolioStyles'),
        availableFolioStyleIds: {
          ...state.availableFolioStyleIds,
          [action.payload]: action.response.data.availableFolioStyleIds,
        },
      };
    }

    case types.FETCH_AVAILABLE_FOLIO_STYLES_FAILURE: {
      return failure(state, action, 'availableFolioStyles');
    }

    default:
      return state;
  }
};
