import { AnyAction } from 'redux';
import {
  singleLoadingFailure as failure,
  singleLoadingRequest as request,
  singleLoadingSuccess as success,
} from 'store/utils/reducer';
import { State } from 'types/Store/PaymentInterfaceStore';
import { Configurator } from 'utils';

import types from './types';

const initialState: State = {
  isFetching: false,
  interface: {
    adapterType: '',
    id: '',
    isActive: false,
    supportedPaymentCommands: [],
    transactionCode: '',
    transactionCodes: [],
  },
  availablePaymentInterfaces: [],
  adapterConfigurations: [],
  adapterConfiguration: {
    id: '',
    adapterType: '',
    isActive: false,
    transactionCodes: [],
  },
  errors: [],
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.FETCH_PAYMENT_INTERFACE_REQUEST:
      return request(state);
    case types.FETCH_PAYMENT_INTERFACE_SUCCESS: {
      const { data } = action.response;

      return {
        ...success(state),
        interface: data,
      };
    }
    case types.FETCH_PAYMENT_INTERFACE_FAILURE:
      return failure(state, action);

    case types.FETCH_ADAPTER_CONFIGURATIONS_REQUEST:
      return request(state);
    case types.FETCH_ADAPTER_CONFIGURATIONS_SUCCESS: {
      const { data: adapterConfigurations } = action.response;
      const availablePaymentInterfaces = adapterConfigurations.filter(
        (item: { adapterType: string; isActive: boolean }) =>
          item.adapterType === Configurator.adapterInterfaceCode &&
          item.isActive
      );

      return {
        ...success(state),
        availablePaymentInterfaces,
      };
    }
    case types.FETCH_ADAPTER_CONFIGURATIONS_FAILURE:
      return failure(state, action);

    case types.FETCH_ADAPTER_CONFIGURATION_REQUEST:
      return request(state);
    case types.FETCH_ADAPTER_CONFIGURATION_SUCCESS: {
      const { data: adapterConfiguration } = action.response;
      const { adapterConfigurations } = state;

      return {
        ...success(state),
        adapterConfiguration,
        adapterConfigurations: [...adapterConfigurations, adapterConfiguration],
      };
    }
    case types.FETCH_ADAPTER_CONFIGURATION_FAILURE:
      return failure(state, action);

    case types.FETCH_PAYMENT_INTERFACE_ADAPTER_REQUEST:
      return {
        ...request(state),
        adapterConfigurations: [],
      };
    case types.FETCH_PAYMENT_INTERFACE_ADAPTER_SUCCESS: {
      const { adapterConfigurations } = state;
      Configurator.saveAdaptersConfiguration(adapterConfigurations);

      return success(state);
    }
    case types.FETCH_PAYMENT_INTERFACE_ADAPTER_FAILURE:
      return failure(state, action);

    case types.CLEAR_PROPERTY_INTERFACE_ERRORS:
      return {
        ...state,
        errors: [],
      };
    default:
      return state;
  }
};
