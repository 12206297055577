import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    priceContainerExpandable: {
      display: 'flex',
    },
    priceContainer: {
      display: 'block',
    },
    priceWrapper: {
      marginTop: spacing(1 / 2),
    },
    totalPriceText: {
      fontSize: '1.5rem',
    },
  });
