import { colors } from 'config';
import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      paddingTop: spacing(4),
      paddingRight: spacing(11.75),
      paddingBottom: spacing(20.5),
      paddingLeft: spacing(11.75),
      backgroundColor: colors.grey[50],
    },
    formWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    formInput: {
      width: '50%',
      boxSizing: 'border-box',
      '&:not(:last-child)': {
        marginRight: 8,
      },
      '&:last-child:not(:only-of-type)': {
        marginLeft: 8,
      },
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    helpDescription: {
      marginTop: spacing(0.25),
      marginBottom: spacing(1.5),
    },
    subtitle: {
      fontSize: '0.875rem',
      marginBottom: spacing(2),
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.HINT_FONT_COLOR,
        palette.text.secondary
      ),
    },
  });
