import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Configurator } from 'utils';

import {
  DatePickerField,
  Field,
  FieldMode,
  SelectField,
  SelectOption,
  ValidationHeader,
} from '@ac/kiosk-components';
import {
  createRequiredValidator,
  Form,
  FormApi,
  formFieldFactory,
  FormSpy,
} from '@ac/react-infrastructure';

import { mapSelectOptions } from '@gss/utils';
import {
  CompareDateType,
  createDateCompareValidator,
  FormValidator,
  mapFieldRenderProps,
} from '@gss/utils/form';

import './DocumentsForm.scss';

export interface DocumentsFormValues {
  documentType?: string | undefined;
  number?: string | undefined;
  nationality?: string | undefined;
  expiryDate?: string | undefined;
  countryOfIssue?: string | undefined;
  placeOfIssue?: string | undefined;
  issuedBy?: string | undefined;
  issueDate?: string | undefined;
  dateOfBirth?: string | undefined;
  countryOfBirth?: string | undefined;
}

export type DocumentsFormFieldsVisibilityConfig = Partial<
  Record<keyof DocumentsFormValues, boolean>
>;

const PASSPORT_TYPE_CODE = 'PASSPORT';

const FormField = formFieldFactory<DocumentsFormValues>();

interface DocumentsFormProps {
  id?: string;
  isScannedDocument?: boolean;
  fieldsVisibility: DocumentsFormFieldsVisibilityConfig;
  initialValues: DocumentsFormValues;
  onDocumentTypeChange: (code: string) => void;
  onSubmit: (
    values: DocumentsFormValues,
    formApi: FormApi<DocumentsFormValues>
  ) => void;
  onFormValidChange?: (isValid: boolean) => void;
}

const DocumentsForm = ({
  id,
  isScannedDocument,
  fieldsVisibility,
  initialValues,
  onDocumentTypeChange,
  onSubmit,
  onFormValidChange,
}: DocumentsFormProps) => {
  const { t, i18n } = useTranslation();

  const validator = useMemo(() => {
    return new FormValidator<DocumentsFormValues>({
      documentType: createRequiredValidator(t('FIELD_IS_MANDATORY')),
      number: createRequiredValidator(t('FIELD_IS_MANDATORY')),
      dateOfBirth: createDateCompareValidator(
        t('INVALID_DATE'),
        Configurator.propertyBusinessDate,
        CompareDateType.isSameOrBefore
      ),
      expiryDate: createDateCompareValidator(
        t('INVALID_DATE'),
        Configurator.propertyBusinessDate,
        CompareDateType.isSameOrAfter
      ),
      issueDate: createDateCompareValidator(
        t('INVALID_DATE'),
        Configurator.propertyBusinessDate,
        CompareDateType.isSameOrBefore
      ),
    });
  }, [i18n.language]);

  const nationalityOptions = useMemo(() => {
    const nationalities = Configurator.getNationalities(i18n.language);

    return mapSelectOptions(nationalities);
  }, [i18n.language]);

  const countryOptions = useMemo(() => {
    const countries = Configurator.getCountries(i18n.language);

    return mapSelectOptions(countries);
  }, [i18n.language]);

  const documentTypeOptions = useMemo(() => {
    return (Configurator.documentTypes || [])
      .map((documentType) => ({
        title: Configurator.getDescription(documentType.description),
        value: documentType.code,
      }))
      .filter((option) => {
        return option.title && option.value;
      }) as SelectOption[];
  }, [i18n.language]);

  return (
    <Form
      initialValues={initialValues}
      validate={validator.validate}
      onSubmit={onSubmit}
      destroyOnUnregister
    >
      {(formRenderProps) => (
        <>
          {onFormValidChange && (
            <FormSpy
              subscription={{ valid: true }}
              onChange={({ valid }) => onFormValidChange(valid)}
            />
          )}

          <form
            id={id}
            className="document-form-wrapper"
            onSubmit={formRenderProps.handleSubmit}
          >
            <ValidationHeader
              title={t('ID_DOCUMENT')}
              isValid={formRenderProps.valid}
              className="spacing-bottom-lg"
            />

            <div className="document-form-fields-wrapper">
              {fieldsVisibility.documentType && (
                <FormField valuePath="documentType">
                  {(fieldRenderProps) => {
                    const mappedFieldProps =
                      mapFieldRenderProps(fieldRenderProps);

                    return (
                      <SelectField
                        {...mappedFieldProps}
                        onChange={(value: string) => {
                          mappedFieldProps.onChange(value);
                          onDocumentTypeChange?.(value);
                        }}
                        modalClassName="with-default-kiosk-components-theme"
                        options={documentTypeOptions}
                        placeholder={t('SELECT')}
                        label={t('DOCUMENT_TYPE')}
                        disabled={isScannedDocument}
                      />
                    );
                  }}
                </FormField>
              )}

              {fieldsVisibility.number && (
                <FormField valuePath="number">
                  {(fieldRenderProps) => (
                    <Field
                      {...mapFieldRenderProps(fieldRenderProps)}
                      placeholder={t('FILL')}
                      label={t('NUMBER')}
                      mode={FieldMode.numeric}
                    />
                  )}
                </FormField>
              )}

              {fieldsVisibility.nationality && (
                <FormField valuePath="nationality">
                  {(fieldRenderProps) => (
                    <SelectField
                      {...mapFieldRenderProps(fieldRenderProps)}
                      modalClassName="with-default-kiosk-components-theme"
                      options={nationalityOptions}
                      placeholder={t('SELECT')}
                      label={t('NATIONALITY')}
                      allowClear
                      optional
                    />
                  )}
                </FormField>
              )}

              {fieldsVisibility.expiryDate && (
                <FormField valuePath="expiryDate">
                  {(fieldRenderProps) => (
                    <DatePickerField
                      {...mapFieldRenderProps(fieldRenderProps)}
                      modalClassName="with-default-kiosk-components-theme"
                      placeholder={Configurator.dateFormat.shortDateFormat}
                      fieldDisplayFormat={
                        Configurator.dateFormat.shortDateFormat
                      }
                      label={t('EXPIRY_DATE')}
                      allowClear
                      optional
                    />
                  )}
                </FormField>
              )}

              {fieldsVisibility.countryOfIssue && (
                <FormField valuePath="countryOfIssue">
                  {(fieldRenderProps) => (
                    <SelectField
                      {...mapFieldRenderProps(fieldRenderProps)}
                      modalClassName="with-default-kiosk-components-theme"
                      options={countryOptions}
                      placeholder={t('SELECT')}
                      label={t('SHARED.COUNTRY_OF_ISSUE')}
                      disabled={
                        initialValues.documentType !== PASSPORT_TYPE_CODE
                      }
                      allowClear
                      optional
                    />
                  )}
                </FormField>
              )}

              {fieldsVisibility.placeOfIssue && (
                <FormField valuePath="placeOfIssue">
                  {(fieldRenderProps) => (
                    <Field
                      {...mapFieldRenderProps(fieldRenderProps)}
                      placeholder={t('FILL')}
                      label={t('SHARED.PLACE_OF_ISSUE')}
                      optional
                    />
                  )}
                </FormField>
              )}

              {fieldsVisibility.issuedBy && (
                <FormField valuePath="issuedBy">
                  {(fieldRenderProps) => (
                    <Field
                      {...mapFieldRenderProps(fieldRenderProps)}
                      placeholder={t('FILL')}
                      label={t('SHARED.ISSUED_BY')}
                      optional
                    />
                  )}
                </FormField>
              )}

              {fieldsVisibility.issueDate && (
                <FormField valuePath="issueDate">
                  {(fieldRenderProps) => (
                    <DatePickerField
                      {...mapFieldRenderProps(fieldRenderProps)}
                      modalClassName="with-default-kiosk-components-theme"
                      placeholder={Configurator.dateFormat.shortDateFormat}
                      fieldDisplayFormat={
                        Configurator.dateFormat.shortDateFormat
                      }
                      label={t('SHARED.ISSUE_DATE')}
                      allowClear
                      optional
                    />
                  )}
                </FormField>
              )}

              {fieldsVisibility.dateOfBirth && (
                <FormField valuePath="dateOfBirth">
                  {(fieldRenderProps) => (
                    <DatePickerField
                      {...mapFieldRenderProps(fieldRenderProps)}
                      modalClassName="with-default-kiosk-components-theme"
                      placeholder={Configurator.dateFormat.shortDateFormat}
                      fieldDisplayFormat={
                        Configurator.dateFormat.shortDateFormat
                      }
                      label={t('SHARED.DATE_OF_BIRTH')}
                      allowClear
                      optional
                    />
                  )}
                </FormField>
              )}

              {fieldsVisibility.countryOfBirth && (
                <FormField valuePath="countryOfBirth">
                  {(fieldRenderProps) => (
                    <SelectField
                      {...mapFieldRenderProps(fieldRenderProps)}
                      modalClassName="with-default-kiosk-components-theme"
                      options={countryOptions}
                      placeholder={t('SELECT')}
                      label={t('SHARED.COUNTRY_OF_BIRTH')}
                      allowClear
                      optional
                    />
                  )}
                </FormField>
              )}
            </div>
          </form>
        </>
      )}
    </Form>
  );
};

export default DocumentsForm;
