import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './DayUseInfo.style';

interface PassedProps {
  onClick: () => void;
  hour: string;
}

interface DayUseInfoProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class DayUseInfo extends PureComponent<DayUseInfoProps> {
  public render() {
    const { classes, hour, onClick, t } = this.props;

    return (
      <Grid className={classes.wrapper}>
        <Typography className={classes.title}>
          {`${t('EXPECTED_DEPARTURE')}:`}
        </Typography>
        <button
          className={classes.clickableLabel}
          type="button"
          onClick={() => onClick()}
        >
          <Typography className={classes.buttonTitle}>{hour}</Typography>
          <Grid className={classes.iconWrapper}>
            <Icon type={IconTypes.exclamationMark} className={classes.icon} />
          </Grid>
        </button>
      </Grid>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(DayUseInfo) as (
  props: PassedProps
) => JSX.Element;
