import { AnyAction, Dispatch } from 'redux';
import {
  reportReduxActionError,
  reportSelfServiceActionApiError,
} from 'utils/logReportPatterns';

import { ApiActionResult } from './api';
import { isActionOnLoggingBlacklist } from './reduxErrorsLogBlacklist';

const reduxActionErrorReporter = () => (next: Dispatch) => {
  return async (action: AnyAction) => {
    const actionResult = await next(action);

    const isNewTypeOfAction = actionResult?.type?.startsWith('@');
    if (isNewTypeOfAction) return actionResult;

    if (!isActionOnLoggingBlacklist(action)) {
      if (actionResult?.apiAction === ApiActionResult.failure) {
        reportSelfServiceActionApiError(actionResult);
      } else if (action.type?.includes('_FAILURE')) {
        const error = actionResult?.payload?.error || actionResult?.payload;
        reportReduxActionError(error, actionResult);
      }
    }

    return actionResult;
  };
};

export default reduxActionErrorReporter;
