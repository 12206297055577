import { initialStepsRoutes, RoutesViews } from '@gss/router';
import {
  requirePropertyInitialized,
  requireSettingsInitialized,
} from '@gss/utils/hoc';

import { Device } from './Device';
import { Login } from './Login';
import { Logout } from './Logout';
import { MissingDevices } from './MissingDevices';
import { Setup } from './Setup';

export const initialStepsBinds: RoutesViews<typeof initialStepsRoutes> = {
  LOGIN: Login,
  LOGOUT: Logout,
  SETUP: requirePropertyInitialized(Setup),
  DEVICE_SETUP: requireSettingsInitialized(Device),
  MISSING_DEVICE: requireSettingsInitialized(MissingDevices),
};
