import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './AdditionalInfo.style';

interface PasssedProps {
  info?: any;
  icon?: IconTypes;
  classNames?: {
    wrapper?: string;
    info?: string;
    icon?: string;
  };
}

interface AdditionalInfoProps extends PasssedProps, WithStyles<typeof styles> {}

class AdditionalInfo extends PureComponent<AdditionalInfoProps> {
  public static defaultProps = {
    info: '',
    classNames: {},
  };

  public render() {
    const { classes, info, icon, classNames: passedClassNames } = this.props;

    return (
      <div
        className={classNames(
          passedClassNames?.wrapper,
          classes.additionalInfo
        )}
      >
        {icon && (
          <Icon
            type={icon}
            className={classNames(passedClassNames?.icon, classes.icon)}
          />
        )}
        <Typography
          display="inline"
          className={classNames(passedClassNames?.info, classes.text)}
        >
          {info}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(AdditionalInfo);
