import { useCallback } from 'react';
import { addTransaction } from 'store/cashiering/actions';
import { AsyncApiCallThroughActionCreator } from 'store/utils/actions';
import { Folio } from 'types/Api/Cashiering';
import { Money } from 'types/Api/Shared';

export const useAddingPaymentHandler = (
  addTransactionInApi: AsyncApiCallThroughActionCreator<typeof addTransaction>,
  transactionCode?: string
) =>
  useCallback(
    async (folio: Folio, paymentValue: Required<Money>) => {
      if (!transactionCode) {
        return;
      }

      await addTransactionInApi(
        { folioId: folio.id, version: folio.version },
        {
          transactionCode,
          unitPrice: paymentValue,
          quantity: 1,
          isMainTransaction: true,
          foreignUnitPrice: null,
        }
      );
    },
    [addTransactionInApi, transactionCode]
  );
