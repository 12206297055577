import template from './template.hbs';

export interface CheckOutFolioTemplateProps {
  name: string;
  membership?: string;
  contacts: {
    email: string;
    mobile: string;
    address: string[];
    taxId?: string;
  };
  bookingInfo: {
    isDayUse: boolean;
    departureHour: string;
    nights: number;
    adults: number;
    children: number;
  };
  priceInfo: {
    totalPrice: string;
    roomPrice: string;
    addonsPrice?: string;
  };
  period: string;
  room: {
    number: string;
    name: string;
  };
  consents?: Array<{
    description: string;
    isGranted: boolean;
  }>;
  disclaimer?: string;
  signature: string;
  dateTime: string;
}

export const checkInReservationTemplate = (
  props: CheckOutFolioTemplateProps
): string => {
  return template({
    ...props,
  });
};
