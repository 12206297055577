const START_CHECK_IN_PROCESS_REQUEST = 'START_CHECK_IN_PROCESS_REQUEST';
const START_CHECK_IN_PROCESS_SUCCESS = 'START_CHECK_IN_PROCESS_SUCCESS';
const START_CHECK_IN_PROCESS_FAILURE = 'START_CHECK_IN_PROCESS_FAILURE';

const CHECK_IN_PROCESS_UPDATE_PROFILE_REQUEST =
  'CHECK_IN_PROCESS_UPDATE_PROFILE_REQUEST';
const CHECK_IN_PROCESS_UPDATE_PROFILE_SUCCESS =
  'CHECK_IN_PROCESS_UPDATE_PROFILE_SUCCESS';
const CHECK_IN_PROCESS_UPDATE_PROFILE_FAILURE =
  'CHECK_IN_PROCESS_UPDATE_PROFILE_FAILURE';

const CHECK_IN_PROCESS_UPDATE_CONSENTS_REQUEST =
  'CHECK_IN_PROCESS_UPDATE_CONSENTS_REQUEST';
const CHECK_IN_PROCESS_UPDATE_CONSENTS_SUCCESS =
  'CHECK_IN_PROCESS_UPDATE_CONSENTS_SUCCESS';
const CHECK_IN_PROCESS_UPDATE_CONSENTS_FAILURE =
  'CHECK_IN_PROCESS_UPDATE_CONSENTS_FAILURE';

const SAVE_SCANNED_DOCUMENT = 'SAVE_SCANNED_DOCUMENT';

const CHECK_IN_GENERATE_SUMMARY_PDF_REQUEST =
  'CHECK_IN_GENERATE_SUMMARY_PDF_REQUEST';
const CHECK_IN_GENERATE_SUMMARY_PDF_SUCCESS =
  'CHECK_IN_GENERATE_SUMMARY_PDF_SUCCESS';
const CHECK_IN_GENERATE_SUMMARY_PDF_FAILURE =
  'CHECK_IN_GENERATE_SUMMARY_PDF_FAILURE';

const RESET_CHECK_IN_STATE = 'RESET_CHECK_IN_STATE';

export default {
  START_CHECK_IN_PROCESS_REQUEST,
  START_CHECK_IN_PROCESS_SUCCESS,
  START_CHECK_IN_PROCESS_FAILURE,
  CHECK_IN_PROCESS_UPDATE_PROFILE_REQUEST,
  CHECK_IN_PROCESS_UPDATE_PROFILE_SUCCESS,
  CHECK_IN_PROCESS_UPDATE_PROFILE_FAILURE,
  SAVE_SCANNED_DOCUMENT,
  CHECK_IN_PROCESS_UPDATE_CONSENTS_REQUEST,
  CHECK_IN_PROCESS_UPDATE_CONSENTS_SUCCESS,
  CHECK_IN_PROCESS_UPDATE_CONSENTS_FAILURE,
  CHECK_IN_GENERATE_SUMMARY_PDF_REQUEST,
  CHECK_IN_GENERATE_SUMMARY_PDF_SUCCESS,
  CHECK_IN_GENERATE_SUMMARY_PDF_FAILURE,
  RESET_CHECK_IN_STATE,
};
