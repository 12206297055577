import { LogReporter } from '@ac/kiosk-components';

import { LOG_MESSAGES, LOG_TYPES } from '@gss/configs/constants';

export const sagaUncatchedErrorMiddleware = (
  error: Error,
  errorInfo: { sagaStack?: string }
): void => {
  const sagaActionType = errorInfo?.sagaStack?.match(/@.+(?=,)/)?.[0];

  LogReporter.log.error(
    LOG_TYPES.app,
    {
      message: LOG_MESSAGES.SAGA_UNCATCHED_ERROR,
      details: {
        action: sagaActionType,
        message: error?.message,
      },
    },
    true
  );
};
