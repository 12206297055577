import { createSelector } from 'reselect';
import {
  getAllFolios,
  getBalancedFoliosToSettle,
  getNotBalancedFolios,
} from 'store/cashiering/selectors';
import { getEmailAddressIfExist } from 'store/profile/selectors';
import { Folio } from 'types/Api/Cashiering';
import Store from 'types/Store';
import { Configurator } from 'utils';

export const getIsFastTrackCheckOutProcess = (state: Store): boolean =>
  state.checkOutProcess.isFastTrackCheckOutProcess;

export const getIsFastTrackCheckOutProcessIsLoading = (state: Store): boolean =>
  state.checkOutProcess.dataFetching.checkOut;

export const getIsReviewChargesPossible = createSelector(
  [getNotBalancedFolios, getBalancedFoliosToSettle],
  (notBalancedFolios, balancedFoliosToSettle): boolean => {
    return Boolean(notBalancedFolios.length || balancedFoliosToSettle.length);
  }
);

export const getIsFastTrackCheckOutPossible = createSelector(
  [getAllFolios],
  (folios: Folio[]): boolean => {
    const {
      entityCodes: { BILLING_EMAIL },
      emailTypes,
      folioStatusCodes: { ACTIVE },
      switchCodes: { FAST_TRACK_CHECK_OUT },
    } = Configurator;
    const isFastTrackSettingEnabled =
      Configurator.getSwitch(FAST_TRACK_CHECK_OUT);
    if (!isFastTrackSettingEnabled) {
      return false;
    }

    const isThereAnyBillViewFolio = folios.some(
      ({ billViewAllowed }) => billViewAllowed
    );
    const billingEmailType =
      Configurator.getEntityTypeCode(emailTypes, BILLING_EMAIL) || '';

    const remoteCheckoutAllowedFolios = folios.filter(
      (folio) =>
        folio.remoteCheckOutAllowed && folio?.folioStatusCode?.code === ACTIVE
    );

    const areAllFoliosHaveBalanceZero = remoteCheckoutAllowedFolios.every(
      (folio) => !folio?.grossBalance?.amount
    );

    const areAllFoliosHasEmailForInvoice = remoteCheckoutAllowedFolios.every(
      (folio) =>
        getEmailAddressIfExist(
          folio?.profile?.communicationDetails,
          billingEmailType
        )
    );

    return (
      isThereAnyBillViewFolio &&
      areAllFoliosHaveBalanceZero &&
      areAllFoliosHasEmailForInvoice
    );
  }
);
