import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 24;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    checkboxIcon: {
      border: `1px solid ${colors.grey[500]}`,
      borderRadius: 4,
      width: ICON_SIZE,
      height: ICON_SIZE,
    },
    checkedIcon: {
      '& svg': {
        borderRadius: 4,
        width: ICON_SIZE * 1.2,
        height: ICON_SIZE * 1.2,
      },
    },
  });
