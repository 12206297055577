import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import { stepsSettingsCodesMap } from '@gss/store/settings/interfaces/settings/stepsSettingsState';
import { getStepsSettings } from '@gss/store/settings/selectors';

import { checkInRoutes } from '../routesConfigurations/checkInRoutes';
import { checkOutRoutes } from '../routesConfigurations/checkOutRoutes';
import { initialStepsRoutes } from '../routesConfigurations/initialStepsRoutes';
import { makeKeysRoutes } from '../routesConfigurations/makeKeysRoutes';
import { FlowType, RouteName, RoutesMap, RouteType } from '../types';

export const useResolveFlowSteps = (): ((
  flowType: FlowType | undefined
) => Partial<RoutesMap>) => {
  const stepSettings = useSelector(getStepsSettings);

  const getRoutesForFlowType = useCallback(
    (flowType: FlowType | undefined): Partial<Record<string, RouteType>> => {
      switch (flowType) {
        case FlowType.checkIn:
          return checkInRoutes;
        case FlowType.checkOut:
          return checkOutRoutes;
        case FlowType.makeKeys:
          return makeKeysRoutes;
        case FlowType.initial:
          return initialStepsRoutes;
        default:
          return {};
      }
    },
    []
  );

  const areSwitchesEnabled = useCallback(
    (settingKeys: string[]): boolean => {
      if (!settingKeys.length) return true;

      const switchSettingKeys = settingKeys
        .map((key) => {
          const entries = Object.entries(stepsSettingsCodesMap).find(
            ([, settingMap]) => settingMap.key === key
          );

          return entries?.[0];
        })
        .filter(isDefined) as Array<keyof typeof stepsSettingsCodesMap>;

      return switchSettingKeys.every((key) => !!stepSettings?.[key]);
    },
    [stepSettings]
  );

  return useCallback(
    (flowType: FlowType | undefined): Partial<RoutesMap> => {
      const routes = getRoutesForFlowType(flowType);

      return Object.entries(routes).reduce<Partial<RoutesMap>>(
        (steps, [key, value]): Partial<RoutesMap> => {
          if (!value) return steps;

          const switches = value.switch || [];
          const isDisabled = !areSwitchesEnabled(switches);

          steps[key as RouteName] = {
            path: value.path,
            isDisabled,
            optionalStep: value.optionalStep,
            ...('excludedFromCounter' in value
              ? { excludedFromCounter: value.excludedFromCounter }
              : {}),
          };

          return steps;
        },
        {}
      );
    },
    [areSwitchesEnabled, getRoutesForFlowType]
  );
};
