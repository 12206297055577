import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { Checkbox } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FormCheckboxControl.style';

interface FormCheckboxControlProps extends WithStyles<typeof styles> {
  input?: { value: string };
  required?: boolean;
  disabled?: boolean;
}

class FormCheckboxControl extends PureComponent<FormCheckboxControlProps> {
  public static defaultProps = {
    required: false,
    disabled: false,
    input: { value: '' },
  };

  public render() {
    const { classes, input, required, disabled } = this.props;
    if (required) return <CheckIcon className={classes.check} />;

    return (
      <div className={classes.checkboxWrapper}>
        <Checkbox
          {...input}
          disabled={disabled}
          icon={<div className={classes.checkWrapper} />}
          classes={{ root: classes.checkboxRoot }}
          checked={input && !!input.value}
          color="primary"
          checkedIcon={
            <div className={classes.checkWrapper}>
              <CheckIcon
                color={disabled ? 'disabled' : 'inherit'}
                classes={{
                  root: classes.iconRoot,
                  colorDisabled: classes.checkedIconDisabled,
                }}
                className={classes.checkedIcon}
              />
            </div>
          }
          value={input && input.value.toString()}
          TouchRippleProps={{
            center: true,
            classes: {
              root: classes.rippleRoot,
              rippleVisible: classes.rippleVisible,
              ripple: classes.ripple,
              childPulsate: classes.childPulsate,
              childLeaving: classes.childLeaving,
            },
          }}
        />
        <div
          className={classNames(
            classes.checkboxOverlay,
            disabled ? classes.checkboxOverlayDisabled : ''
          )}
        />
      </div>
    );
  }
}

export default withStyles(styles)(FormCheckboxControl);
