const SAVE_ID_PHOTO = 'SAVE_ID_PHOTO';
const REMOVE_ID_PHOTO = 'REMOVE_ID_PHOTO';
const SET_CACHED_DOCUMENT_ID = 'SET_CACHED_DOCUMENT_ID';
const SET_DOCUMENT_UPDATE_PAYLOAD = 'SET_DOCUMENT_UPDATE_PAYLOAD';
const CLEAR_DOCUMENT_UPDATE_PAYLOAD = 'CLEAR_DOCUMENT_UPDATE_PAYLOAD';
const CACHE_DOCUMENT_FORM_DATA = 'CACHE_DOCUMENT_FORM_DATA';
const RESET_DOCUMENT_UPDATE_PROGRESS = 'RESET_DOCUMENT_UPDATE_PROGRESS';

export default {
  SAVE_ID_PHOTO,
  REMOVE_ID_PHOTO,
  CACHE_DOCUMENT_FORM_DATA,
  RESET_DOCUMENT_UPDATE_PROGRESS,
  SET_CACHED_DOCUMENT_ID,
  SET_DOCUMENT_UPDATE_PAYLOAD,
  CLEAR_DOCUMENT_UPDATE_PAYLOAD,
};
