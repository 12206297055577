import { createSelector } from 'reselect';
import { getCompanies } from 'store/profile/selectors';
import Store from 'types/Store';

export const getLinkedCompany = (state: Store, selectedCompanyId?: string) =>
  createSelector(getCompanies, (companies) => {
    const identifier = selectedCompanyId;

    return Array.isArray(companies)
      ? companies.find((elem) => elem.id === identifier) || companies[0]
      : undefined;
  })(state);

export const getSelectedCompanyId = (state: Store) =>
  state.checkOutPayment.selectedCompanyId;

export const isCompanySelectLocked = (state: Store) =>
  state.checkOutPayment.isCompanySelectLocked;

export const isCompanySelectOpen = (state: Store) =>
  state.checkOutPayment.isCompanySelectOpen;
