import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const position: CSSProperties = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
};

const MODAL_MIN_WIDTH = 474;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      borderRadius: 6,
      backgroundColor: palette.common.white,
      padding: spacing(2),
      paddingTop: spacing(4.5),
      boxSizing: 'border-box',
      outline: 'none',
      maxHeight: '100dvh',
      overflowY: 'auto',
      display: 'flex',
      minWidth: MODAL_MIN_WIDTH,
      ...position,
    },
  });
