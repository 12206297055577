import {
  NewReservationInventoryItemDto,
  ReservationInventoryItemDto,
  UpdateReservationInventoryItems,
} from '@ac/library-api';
import { isDefined, uuidv4 } from '@ac/library-utils/dist/utils';

import {
  UpdatePurchaseElementData,
  UpdatePurchaseFormData,
} from '@LEGACY/types/Api/Reservation';
import { Configurator } from '@LEGACY/utils';

const prepareInventoryItemsData = (
  inventoryItems: ReservationInventoryItemDto[],
  purchaseElements: UpdatePurchaseFormData,
  defaultCheckOutTime: string,
  defaultCheckInTime: string
): UpdateReservationInventoryItems => {
  const purchaseElementsDictionary = Configurator.getPurchaseElements();

  const removedInventoryItems = purchaseElements.removed
    .map(
      (id) =>
        inventoryItems.find((element) => element.boundedItemId === id)
          ?.boundedItemId
    )
    .filter(isDefined);

  const existingItems = inventoryItems.filter(
    (item) => !removedInventoryItems.includes(item.boundedItemId)
  );

  const newItems = purchaseElements.modified
    .map((element) => {
      const breakdown = element.breakdown[0];
      const purchaseElementData = purchaseElementsDictionary.find(
        (dictionary) => dictionary.id === element.purchaseElementId
      );
      const ifInventoryItemWasAlreadyAdded = !!existingItems.find(
        (item) =>
          isDefined(item.inventoryItemId) &&
          item.inventoryItemId === purchaseElementData?.inventoryItemId
      );

      if (
        !purchaseElementData?.inventoryItemId ||
        ifInventoryItemWasAlreadyAdded
      ) {
        return undefined;
      }

      return {
        allocationPeriods: [
          {
            start: `${breakdown.from}T${defaultCheckInTime}`,
            end: `${breakdown.to}T${defaultCheckOutTime}`,
          },
        ],
        inventoryItemId: purchaseElementData.inventoryItemId,
        _refId: uuidv4(),
      };
    })
    .filter(isDefined);

  return {
    existingItems,
    newItems,
  };
};

const boundNewInventoryItemsWithPurchaseElements = (
  newInventoryItems: NewReservationInventoryItemDto[],
  modifiedPurchaseElements: UpdatePurchaseElementData[]
): UpdatePurchaseElementData[] => {
  const purchaseElementsDictionary = Configurator.getPurchaseElements();

  return modifiedPurchaseElements.map((element) => {
    const purchaseElementData = purchaseElementsDictionary.find(
      (item) => item.id === element.purchaseElementId
    );

    const linkedInventoryItem = newInventoryItems.find(
      (item) => item.inventoryItemId === purchaseElementData?.inventoryItemId
    );

    if (linkedInventoryItem) {
      const { id, ...elementData } = element;

      return {
        ...elementData,
        _refId: linkedInventoryItem._refId,
      };
    }

    return element;
  });
};

export type PreparedPurchaseElementsUpdatePayload = {
  inventoryItems: UpdateReservationInventoryItems;
  purchaseElements: {
    modified: UpdatePurchaseElementData[];
    removed: string[];
  };
};

export const prepareUpdatePurchaseElementsPayload = (
  inventoryItems: ReservationInventoryItemDto[],
  purchaseElements: UpdatePurchaseFormData,
  defaultCheckOutTime: string,
  defaultCheckInTime: string
) => {
  const inventoryItemsPayload = prepareInventoryItemsData(
    inventoryItems,
    purchaseElements,
    defaultCheckOutTime,
    defaultCheckInTime
  );

  const modifiedPurchaseElements = boundNewInventoryItemsWithPurchaseElements(
    inventoryItemsPayload.newItems,
    purchaseElements.modified
  );

  return {
    inventoryItems: inventoryItemsPayload,
    purchaseElements: {
      modified: modifiedPurchaseElements,
      removed: purchaseElements.removed,
    },
  };
};
