import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { TranslatedDescription } from 'types/Api/Shared';
import { Configurator } from 'utils';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './RoomDescription.style';

export interface PassedProps {
  roomNumber: any;
  attributes: TranslatedDescription[][];
  roomType: string;
  description: string;
  equipment?: string;
  isGalleryModalOpen?: boolean;
}

interface RoomDescriptionProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class RoomDescription extends PureComponent<RoomDescriptionProps> {
  public static defaultProps = {
    isGalleryModalOpen: false,
    equipment: '',
  };

  public render() {
    const { classes, t, roomNumber, description, roomType } = this.props;

    return (
      <Grid item xs={12} sm={12} md={6} className={classes.wrapper}>
        <Typography className={classes.roomNumber}>
          {t('ROOM', { room: roomNumber })}
        </Typography>
        <Typography className={classes.roomType}>
          {t('TYPE', { type: roomType })}
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
        <Typography className={classes.equipmentTitle}>
          {t('ROOM_ATTRIBUTES')}
        </Typography>
        {this.renderAttributes()}
      </Grid>
    );
  }

  private renderAttributes = () => {
    const { attributes, classes } = this.props;

    return attributes.map((attribute, index) => {
      const attributeName = Configurator.getDescription(attribute);

      return attributeName ? (
        <Typography
          className={classes.attribute}
          key={`room-attribute-${attributeName}-${index}`}
        >
          {attributeName}
        </Typography>
      ) : null;
    });
  };
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(RoomDescription) as (props: PassedProps) => JSX.Element;
