import { isDefined } from '@ac/library-utils/dist/utils';

type WithSequenceItem<T> = {
  displaySequence: number;
} & T;

interface CategorizedItems<T> {
  withSequence: Array<WithSequenceItem<T>>;
  restOfItems: T[];
}

export const sortDisplayElements = <
  T extends {
    displaySequence?: number;
  }
>(
  items: T[]
): T[] => {
  const { restOfItems, withSequence } = items.reduce<CategorizedItems<T>>(
    (acc, item) => {
      if ('displaySequence' in item && isDefined(item.displaySequence)) {
        acc.withSequence.push(item as WithSequenceItem<T>);
      } else {
        acc.restOfItems.push(item);
      }

      return acc;
    },
    {
      withSequence: [],
      restOfItems: [],
    }
  );

  withSequence.sort((a, b) => {
    const aSequence = a.displaySequence;
    const bSequence = b.displaySequence;

    return aSequence === bSequence ? 0 : aSequence > bSequence ? 1 : -1;
  });

  return [...withSequence, ...restOfItems];
};
