var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"PDF.SHARED.SIGNATURE",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":35}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"dateTime") || (depth0 != null ? lookupProperty(depth0,"dateTime") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dateTime","hash":{},"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":25,"column":16}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-signature-wrapper .pdf-signature-image {\n    max-width: 100%;\n  }\n</style>\n\n<div\n  data-avoid-break-page-inside=\"true\"\n  class=\""
    + alias2(__default(require("../helpers/classNames.ts")).call(alias1,"pdf-signature-wrapper",(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"classNames","hash":{},"data":data,"loc":{"start":{"line":9,"column":9},"end":{"line":9,"column":53}}}))
    + "\"\n>\n"
    + ((stack1 = container.invokePartial(require("./text.hbs"),depth0,{"name":"text","hash":{"size":"xs","class":"spacing-bottom-sm"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  <img src="
    + alias2(((helper = (helper = lookupProperty(helpers,"signature") || (depth0 != null ? lookupProperty(depth0,"signature") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"signature","hash":{},"data":data,"loc":{"start":{"line":18,"column":11},"end":{"line":18,"column":24}}}) : helper)))
    + " class=\"pdf-signature-image\" alt=\"signature image\" />\n\n"
    + ((stack1 = container.invokePartial(require("./text.hbs"),depth0,{"name":"text","hash":{"weight":"light","size":"xxs","class":"spacing-top-sm"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n\n";
},"usePartial":true,"useData":true});