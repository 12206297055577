import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    expandButton: {
      '& .button-component-icon': {
        transition: 'transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        transform: 'rotate(0deg)',
      },
    },
    expandButtonExpanded: {
      '& .button-component-icon': {
        transform: 'rotate(180deg)',
      },
    },
  });
