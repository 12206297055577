import React, { PureComponent } from 'react';
import FormSelectOption from 'types/FormSelectOption';

import { MenuItem } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FormSelectOptions.style';

interface FormSelectOptionsProps extends WithStyles<typeof styles> {
  label: string;
  options: FormSelectOption[];
  native?: boolean;
  empty?: string;
}

class FormSelectOptions extends PureComponent<FormSelectOptionsProps> {
  public static defaultProps = {
    native: true,
    options: [{ value: '', title: '' }],
    empty: '',
  };

  public render() {
    const { native, options, label, empty, classes } = this.props;

    if (!native) {
      return options.map((option) => (
        <MenuItem key={`${label}-select-${option.value}`} value={option.value}>
          {option.title}
        </MenuItem>
      ));
    }
    if (!empty) {
      return options.map((option) => (
        <option key={`${label}-select-${option.value}`} value={option.value}>
          {option.title}
        </option>
      ));
    }

    return (
      <>
        <option value="" className={classes.emptyOption} />
        {options.map((option) => (
          <option value={option.value} key={`${label}-select-${option.value}`}>
            {option.title}
          </option>
        ))}
      </>
    );
  }
}

export default withStyles(styles)(FormSelectOptions);
