export enum ScrollPosition {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}

export const scrollElement = (
  containerSelector: string,
  elementSelector: string,
  elementPosition: ScrollPosition = ScrollPosition.center
): void => {
  const container = document.querySelector(containerSelector);
  const element = document.querySelector(elementSelector);
  if (!element || !container) return;

  const {
    height: containerHeight,
    top: containerTop,
    bottom: containerBottom,
  } = container.getBoundingClientRect();

  const {
    height: elementHeight,
    top: elementTop,
    bottom: elementBottom,
  } = element.getBoundingClientRect();

  let positionToScroll;

  switch (elementPosition) {
    case ScrollPosition.top: {
      positionToScroll = elementTop - containerTop;
      break;
    }

    case ScrollPosition.bottom: {
      positionToScroll = elementBottom - containerBottom;
      break;
    }

    default: {
      const centerOfContainer = containerHeight / 2;
      const centerOfElement = elementHeight / 2;
      positionToScroll =
        elementTop + centerOfElement - containerTop - centerOfContainer;
      break;
    }
  }

  container.scrollBy({ top: positionToScroll, behavior: 'smooth' });
};
