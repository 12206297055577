import { CallApiResponseAction } from 'store/middleware/api';
import { failure, request, success } from 'store/utils/reducer';
import { RawTransactionCode, TransactionCode } from 'types/Api/Entities';
import { State } from 'types/Store/EntitiesStore';
import { Configurator } from 'utils';

import types from './types';

const initialState: State = {
  dataFetching: {
    entity: false,
  },
  membershipLevels: [],
  membershipTypes: [],
  errors: [],
  interfaceCashiers: [],
  selectedIfcCashierNumber: '',
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_MEMBERSHIP_LEVELS_REQUEST:
      return request(state, 'entity');
    case types.FETCH_MEMBERSHIP_LEVELS_SUCCESS: {
      const { results } = action.response.data;
      Configurator.saveMembershipLevels(results);

      return success(state, 'entity');
    }
    case types.FETCH_MEMBERSHIP_LEVELS_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_MEMBERSHIP_TYPES_REQUEST:
      return request(state, 'entity');
    case types.FETCH_MEMBERSHIP_TYPES_SUCCESS: {
      const { results } = action.response.data;
      Configurator.saveMembershipTypes(results);

      return success(state, 'entity');
    }
    case types.FETCH_MEMBERSHIP_TYPES_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_ATTRIBUTE_TYPES_REQUEST:
      return request(state, 'entity');
    case types.FETCH_ATTRIBUTE_TYPES_SUCCESS: {
      const { results } = action.response.data;
      Configurator.saveRoomAttributes(results);

      return success(state, 'entity');
    }
    case types.FETCH_ATTRIBUTE_TYPES_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_PROFILE_TYPES_REQUEST:
      return request(state, 'entity');
    case types.FETCH_PROFILE_TYPES_SUCCESS: {
      const { results } = action.response.data;
      Configurator.saveProfileTypes(results);

      return success(state, 'entity');
    }
    case types.FETCH_PROFILE_TYPES_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_PROPERTY_FLOORS_REQUEST:
      return request(state, 'entity');
    case types.FETCH_PROPERTY_FLOORS_SUCCESS: {
      const { results: propertyFloors } = action.response.data;
      Configurator.savePropertyFloors(propertyFloors);

      return success(state, 'entity');
    }
    case types.FETCH_PROPERTY_FLOORS_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_ROOM_LOCATIONS_REQUEST:
      return request(state, 'entity');
    case types.FETCH_ROOM_LOCATIONS_SUCCESS: {
      const { results: roomLocations } = action.response.data;
      Configurator.saveRoomLocations(roomLocations);

      return success(state, 'entity');
    }
    case types.FETCH_ROOM_LOCATIONS_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_TRANSACTION_CODES_REQUEST:
      return request(state, 'entity');
    case types.FETCH_TRANSACTION_CODES_SUCCESS: {
      const { results: rawCodes } = action.response.data;
      const codes = rawCodes.map(
        (rawTransactionCode: RawTransactionCode) =>
          new TransactionCode(rawTransactionCode)
      );

      Configurator.saveTransactionCodes(codes);
      Configurator.saveBillingTransactionCodes(codes);

      return success(state, 'entity');
    }
    case types.FETCH_TRANSACTION_CODES_FAILURE:
      return failure(state, action, 'entity');

    case types.CLEAR_ENTITIES_ERRORS:
      return {
        ...state,
        errors: [],
      };

    case types.FETCH_KIOSK_TRANSACTION_CODE_REQUEST:
      return request(state, 'entity');
    case types.FETCH_KIOSK_TRANSACTION_CODE_SUCCESS: {
      const { data } = action.response;
      const { results = [] } = data;
      const transactionCode = results.pop();

      if (transactionCode) {
        Configurator.saveKioskTransactionCode(transactionCode);

        return success(state, 'entity');
      }

      return failure(state, action, 'entity');
    }
    case types.FETCH_KIOSK_TRANSACTION_CODE_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_INTERFACE_CASHIERS_REQUEST:
      return request(state, 'entity');
    case types.FETCH_INTERFACE_CASHIERS_SUCCESS: {
      const { results: interfaceCashiers } = action.response.data;

      return {
        ...success(state, 'entity'),
        interfaceCashiers,
      };
    }
    case types.FETCH_INTERFACE_CASHIERS_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_CASHIER_NUMBER_REQUEST:
      return request(state, 'entity');
    case types.FETCH_CASHIER_NUMBER_SUCCESS: {
      const { payload } = action;
      const { CASHIER_NUMBER } = Configurator.cashieringSettingsCodes;
      const id = payload ? payload.cashierId : '';
      if (id) Configurator.saveCashieringSettings(CASHIER_NUMBER, id);
      if (!id) Configurator.clearCashieringSettings();

      return {
        ...success(state, 'entity'),
        selectedIfcCashierNumber: id,
      };
    }
    case types.FETCH_CASHIER_NUMBER_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_TAX_RULES_REQUEST:
      return request(state, 'entity');
    case types.FETCH_TAX_RULES_SUCCESS: {
      const { results } = action.response.data;
      Configurator.saveTaxRules(results);

      return success(state, 'entity');
    }
    case types.FETCH_TAX_RULES_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_FOLIO_STYLES_REQUEST:
      return request(state, 'entity');
    case types.FETCH_FOLIO_STYLES_SUCCESS: {
      const { results } = action.response.data;
      Configurator.saveFolioStyles(results);

      return success(state, 'entity');
    }
    case types.FETCH_FOLIO_STYLES_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_DEFAULT_FOLIO_STYLE_REQUEST:
      return request(state, 'entity');
    case types.FETCH_DEFAULT_FOLIO_STYLE_SUCCESS: {
      const { results } = action.response.data;
      Configurator.saveDefaultFolioStyle(results);

      return success(state, 'entity');
    }
    case types.FETCH_DEFAULT_FOLIO_STYLE_FAILURE:
      return failure(state, action, 'entity');

    case types.FETCH_CREDIT_CARD_AUTHORIZATION_RULE_REQUEST:
      return request(state, 'entity');
    case types.FETCH_CREDIT_CARD_AUTHORIZATION_RULE_SUCCESS: {
      const creditCardAuthorizationRules = action.response.data;
      const { languageCode } = action.payload;
      Configurator.saveCreditCardAuthorizationRules(
        languageCode,
        creditCardAuthorizationRules
      );

      return success(state, 'entity');
    }
    case types.FETCH_CREDIT_CARD_AUTHORIZATION_RULE_FAILURE:
      return failure(state, action, 'entity');

    default:
      return state;
  }
};
