import {
  BaseKioskConfigurationEntity,
  RawBaseKioskConfigurationEntity,
} from '../../shared';

export interface RawKioskLocalizedTitlesEntryDetails
  extends RawBaseKioskConfigurationEntity {
  descriptionLanguageCode: string;
  isActive: boolean;
  displaySequence: number;
}

export class KioskLocalizedTitlesEntryDetails extends BaseKioskConfigurationEntity {
  public descriptionLanguageCode: string;
  public isActive: boolean;
  public displaySequence: number;

  constructor(raw: RawKioskLocalizedTitlesEntryDetails) {
    super(raw);
    this.descriptionLanguageCode = raw.descriptionLanguageCode;
    this.isActive = raw.isActive;
    this.displaySequence = raw.displaySequence;
  }
}
