import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal } from 'components';
import { compose } from 'redux';
import { TypographyVariant } from 'types/MaterialUI';
import { Router } from 'utils';
import { Path } from 'utils/Router';

import { SupportedLanguagesSelector } from '@gss/components';
import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles, { getBackground } from './ImageHeader.style';

interface PassedProps {
  title?: string;
  subtitle?: string;
  image?: string;
  logo?: string;
}

interface ImageHeaderProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

interface ImageHeaderState {
  isHelpModalOpen: boolean;
}

class ImageHeader extends PureComponent<ImageHeaderProps, ImageHeaderState> {
  public static defaultProps = {
    image: '',
    title: '',
    subtitle: '',
    logo: '',
  };

  public state = {
    isHelpModalOpen: false,
  };

  public render() {
    const { classes, title, subtitle, image, logo, t } = this.props;
    const { isHelpModalOpen } = this.state;
    const isWelcomePage = Router.currentPath === Path.welcome;

    return (
      <div className={classes.header}>
        {!isWelcomePage && (
          <div className={classes.settingsWrapper}>
            <SupportedLanguagesSelector className="spacing-right-xlg" />
            <Typography className={classes.help} onClick={this.toggleHelpModal}>
              {t('NEED_HELP')}
            </Typography>
          </div>
        )}
        <img src={logo} alt="" className={classes.logo} />
        {title && (
          <Typography variant={TypographyVariant.h2} className={classes.title}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant={TypographyVariant.h2} className={classes.title}>
            {subtitle}
          </Typography>
        )}
        <div
          className={classes.headerImage}
          style={image ? getBackground(image) : undefined}
        />
        <Modal
          isOpen={isHelpModalOpen}
          type="help"
          onClick={this.toggleHelpModal}
        />
      </div>
    );
  }

  private toggleHelpModal = () => {
    const { isHelpModalOpen } = this.state;
    this.setState({ isHelpModalOpen: !isHelpModalOpen });
  };
}

export default compose(withTranslation(), withStyles(styles))(ImageHeader) as (
  props: PassedProps
) => JSX.Element;
