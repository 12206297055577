import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { InjectedFormProps } from 'redux-form';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './RadioButtons.style';

interface PassedProps {
  input: { value: number; onChange: (value: number) => any };
  maxValue: number;
}

interface RadioButtonsProps
  extends PassedProps,
    InjectedFormProps,
    WithStyles<typeof styles> {}

class RadioButtons extends PureComponent<RadioButtonsProps> {
  public render() {
    const { classes, maxValue, input } = this.props;

    return (
      <>
        {Array.from({ length: maxValue }, (e, i) => {
          const isChecked = i + 1 === input.value;

          return (
            <Button
              pattern={
                isChecked ? ButtonPattern.primary : ButtonPattern.secondary
              }
              className={classes.buttonContainer}
              onClick={() => input.onChange(i + 1)}
              key={`inputRadio-${i + 1}`}
            >
              {i + 1}
            </Button>
          );
        })}
      </>
    );
  }
}

export default compose(withStyles(styles))(RadioButtons) as (
  props: PassedProps
) => JSX.Element;
