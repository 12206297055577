import React from 'react';
import ReactDOM from 'react-dom';

import { ConsoleSimulator } from '@ac/kiosk-components';

import {
  APP_ROOT_CONTAINER_ID,
  CONSOLE_SIMULATOR_ROOT_CONTAINER_ID,
} from '@gss/configs/constants';
import * as serviceWorkerRegistration from '@gss/serviceWorker/serviceWorkerRegistration';

import { AppContainer } from './AppContainer';
import { initAppSetup } from './initAppSetup';

initAppSetup();

ReactDOM.render(
  React.createElement(ConsoleSimulator),
  document.getElementById(CONSOLE_SIMULATOR_ROOT_CONTAINER_ID)
);

ReactDOM.render(
  React.createElement(AppContainer),
  document.getElementById(APP_ROOT_CONTAINER_ID)
);

serviceWorkerRegistration.register();
