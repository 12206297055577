import { CallApiResponseAction } from 'store/middleware/api';
import {
  disableFetching,
  failure,
  request,
  success,
} from 'store/utils/reducer';
import { State } from 'types/Store/SetupStore';

import types from './types';

const initialState: State = {
  dataFetching: {
    setting: false,
  },
  errors: [],
  featureToggles: undefined,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_FEATURE_TOGGLES_REQUEST:
      return request(state, 'setting');
    case types.FETCH_FEATURE_TOGGLES_SUCCESS:
      return {
        ...success(state, 'setting'),
        featureToggles: action.payload,
      };
    case types.FETCH_FEATURE_TOGGLES_FAILURE:
      return {
        ...state,
        dataFetching: disableFetching(state.dataFetching, 'setting'),
        errors: [...state.errors, action.payload],
      };

    case types.RESET_SETUP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
