import { api } from 'config';
import { CALL_API } from 'store/middleware/api';

import { CalculationRule } from '@ac/library-api';
import { PurchaseElementsApi } from '@ac/library-api/dist/api/v0/availability';

import { getPropertyConfiguration } from '@gss/store/settings/selectors';
import { DateManager } from '@LEGACY/utils';

import { getReservation, isReservationDayUse } from '../reservation/selectors';
import { Dispatch, GetState } from '../utils/actions';

import { getAllPurchaseItems } from './selectors';
import types from './types';

export const fetchAvailablePurchaseItems = (
  startDate: string,
  endDate: string
) => ({
  [CALL_API]: {
    endpoint: api.AVAILABILITY.PURCHASE_ELEMENTS,
    params: {
      stayFromInclusive: startDate,
      stayToExclusive: endDate,
    },
    paginate: true,
    types: [
      types.FETCH_AVAILABLE_PURCHASE_ITEMS_REQUEST,
      types.FETCH_AVAILABLE_PURCHASE_ITEMS_SUCCESS,
      types.FETCH_AVAILABLE_PURCHASE_ITEMS_FAILURE,
    ],
  },
});

export const fetchInventoryItemsAvailability =
  () => async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({
        type: types.FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_REQUEST,
      });

      const state = getState();
      const availablePurchaseElements = getAllPurchaseItems(state);
      const reservationDetails = getReservation(state);
      const isDayUse = isReservationDayUse(state);
      const propertyConfiguration = getPropertyConfiguration(state);
      const defaultCheckOutTime = propertyConfiguration?.checkOutTime;
      const defaultCheckInTime = propertyConfiguration?.checkInTime;

      const purchaseElementInRanges = availablePurchaseElements.map((item) => {
        const isPerPerson = item.calculationRule === CalculationRule.PerPerson;

        return {
          purchaseElementId: item.id,
          ranges: [
            {
              fromDate: reservationDetails.arrivalDate,
              fromTime: defaultCheckInTime,
              toDate:
                isPerPerson || isDayUse
                  ? reservationDetails.departureDate
                  : DateManager.getNextDay(reservationDetails.arrivalDate),
              toTime: defaultCheckOutTime,
            },
          ],
        };
      });

      const response =
        await PurchaseElementsApi.postPurchaseElementsAvailabilities({
          data: {
            purchaseElementInRanges,
          },
        });

      dispatch({
        type: types.FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_SUCCESS,
        response,
      });
    } catch (error) {
      dispatch({
        type: types.FETCH_PURCHASE_ELEMENTS_AVAILABLE_TO_ORDER_FAILURE,
        response: {
          data: error,
        },
      });
    }
  };
