import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { toggleReservationHeader } from 'store/ui/actions';

import { Button, ButtonPattern, IconTypes } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './ExpandIcon.style';

interface PassedProps {
  isExpanded: boolean;
}

interface ExpandIconProps extends PassedProps, WithStyles<typeof styles> {
  toggleReservationHeader: () => void;
}

class ExpandIcon extends PureComponent<ExpandIconProps> {
  render() {
    const { toggleReservationHeader, isExpanded, classes } = this.props;

    return (
      <Button
        pattern={ButtonPattern.secondary}
        onClick={toggleReservationHeader}
        icon={IconTypes.expand}
        className={classNames(classes.expandButton, {
          [classes.expandButtonExpanded]: isExpanded,
        })}
      />
    );
  }
}

const mapDispatchToProps = {
  toggleReservationHeader,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(ExpandIcon) as (props: PassedProps) => JSX.Element;
