import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Configurator } from 'utils';

import {
  BasicModal,
  BasicModalSize,
  Button,
  ButtonPattern,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import './ProfileDuplicatesModal.scss';

export enum ProfileDuplicateVariants {
  duplicatesWarning = 'duplicates_warning',
  duplicatesError = 'duplicates_error',
}

interface ProfileDuplicatesModalProps {
  variant?: ProfileDuplicateVariants;
  onCancel?: () => void;
  onSubmit?: () => void;
}

const ProfileDuplicatesModal = ({
  variant,
  onCancel,
  onSubmit,
}: ProfileDuplicatesModalProps) => {
  const { t } = useTranslation();

  const titleContent = useMemo(() => {
    return variant === ProfileDuplicateVariants.duplicatesWarning
      ? t('DUPLICATES_FOUND_TITLE')
      : t('CHECK_IN_NOT_POSSIBLE');
  }, [variant]);

  const descriptionContent = useMemo(() => {
    const { KIOSK_DUPLICATES_ON_MESSAGE, KIOSK_DUPLICATES_OFF_MESSAGE } =
      Configurator.getTranslationCodes();

    return variant === ProfileDuplicateVariants.duplicatesWarning
      ? Configurator.getTranslation(KIOSK_DUPLICATES_ON_MESSAGE)
      : Configurator.getTranslation(KIOSK_DUPLICATES_OFF_MESSAGE);
  }, [variant]);

  return (
    <BasicModal
      size={BasicModalSize.md}
      className="with-default-kiosk-components-theme profile-duplicates-modal"
    >
      <Text size={TextSize.xlg} className="spacing-top-sm">
        {titleContent}
      </Text>
      <Text className="spacing-top-lg spacing-bottom-lg">
        {descriptionContent}
      </Text>

      {variant === ProfileDuplicateVariants.duplicatesWarning ? (
        <div className="buttons-wrapper">
          <Button pattern={ButtonPattern.secondary} onClick={onSubmit}>
            {t('CONTINUE_CHECK_IN')}
          </Button>
          <Button onClick={onCancel}>{t('CANCEL_CHECK_IN')}</Button>
        </div>
      ) : (
        <Button onClick={onCancel} className="spacing-auto">
          {t('CLOSE')}
        </Button>
      )}
    </BasicModal>
  );
};

export default ProfileDuplicatesModal;
