import { createStyles } from '@material-ui/styles';

const CODE_READER_SIZE = 240;
const CODE_READER_RADIUS = 6;

export default () =>
  createStyles({
    codeReader: {
      margin: '0 auto',
      width: CODE_READER_SIZE,
      height: CODE_READER_SIZE,
      '& > section': {
        borderRadius: CODE_READER_RADIUS,
      },
    },
    hidden: {
      display: 'none',
    },
  });
