import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    headerElement: {
      marginBottom: spacing(2),
    },
    title: {
      fontSize: '1.2rem',
      [breakpoints.up('sm')]: {
        fontSize: '1.5rem',
      },
      color: 'inherit',
      lineHeight: '33px',
      wordBreak: 'break-word',
      marginRight: spacing(1),
    },
    subtitle: {
      fontSize: '0.85rem',
      fontWeight: 300,
      [breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
      color: 'inherit',
      lineHeight: '33px',
      '& em': {
        fontStyle: 'normal',
        fontWeight: 'bold',
      },
    },
    subtitlePart: {
      [breakpoints.down('sm')]: {
        '&:nth-child(2)': {
          display: 'inline-block',
          width: '100%',
          fontSize: '1.25rem',
          lineHeight: '33px',
        },
        fontSize: '0.875rem',
        lineHeight: 1.5,
      },
      '&:not(:last-child)': {
        marginRight: spacing(2),
      },
    },
    additionalDetails: {
      display: 'flex',
      '&:not(:last-child)': {
        marginRight: spacing(1),
      },
    },
    additionalTitle: {
      fontSize: '0.875rem',
      fontWeight: 300,
      marginRight: spacing(0.25),
      color: 'inherit',
    },
    additionalSubtitle: {
      fontSize: '0.875rem',
      fontWeight: 'normal',
      color: 'inherit',
    },
  });
