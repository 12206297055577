import React, { useMemo } from 'react';
import { List, ListHeader, ListRow, RowItem } from 'components/List';
import { Money } from 'types/Api/Shared';

import { WithStyles, withStyles } from '@material-ui/styles';

import { listConfig } from './listConfig';
import styles from './PaymentDescription.style';

interface RequiredProps {
  paymentValue: Money;
  paymentDescription: string;
}

interface PaymentDescriptionProps
  extends RequiredProps,
    WithStyles<typeof styles> {}

const PaymentDescription = ({
  paymentValue,
  paymentDescription,
  classes,
}: PaymentDescriptionProps) => {
  const headers = useMemo(
    () => ['', paymentValue.currency || ''],
    [paymentValue.currency]
  );

  return (
    <List listConfig={listConfig} className={classes.list}>
      <ListHeader headers={headers} />
      <ListRow>
        <RowItem>{paymentDescription}</RowItem>
        <RowItem>{paymentValue.amount}</RowItem>
      </ListRow>
    </List>
  );
};

export default withStyles(styles)(PaymentDescription) as (
  props: RequiredProps
) => JSX.Element;
