import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './ResultDrawer.style';

interface PassedProps {
  isOpen: boolean;
  title?: string;
}

interface ResultDrawerProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class ResultDrawer extends PureComponent<ResultDrawerProps> {
  public render() {
    const { classes, isOpen, title } = this.props;

    return (
      <Accordion
        expanded={isOpen}
        classes={{
          expanded: classes.panelExpanded,
        }}
      >
        <AccordionSummary
          classes={{
            root: classes.summaryRoot,
            content: classes.summaryContent,
            expanded: classes.summaryExpanded,
          }}
        />
        <AccordionDetails classes={{ root: classes.detailsRoot }}>
          <CheckIcon classes={{ root: classes.iconRoot }} />
          <Typography className={classes.title}>{title || ''}</Typography>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(ResultDrawer) as (
  props: PassedProps
) => JSX.Element;
