import { ApiError, isApiError, LibraryApiResponse } from '@ac/library-api';

export const isNetworkConnectionError = (error: unknown): boolean => {
  if (!error || typeof error !== 'object') return false;

  const apiError = isApiError((error as LibraryApiResponse<ApiError>).data);
  const genericError = (error as Error).stack && (error as Error).message;

  return !(genericError || apiError);
};
