import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import {
  Button,
  ButtonPattern,
  Circular,
  CircularType,
  IconTypes,
} from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './Success.style';

const COUNTDOWN_CIRCLE_SIZE = 88;

interface PassedProps {
  onContinue?: () => void;
  onCancel?: () => void;
  currentKey?: number;
  keysNumber: number;
}

interface SuccessProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class Success extends PureComponent<SuccessProps> {
  public static defaultProps = {
    currentKey: 0,
  };

  public render() {
    const { classes, t, onContinue, currentKey, keysNumber, onCancel } =
      this.props;

    return (
      <Grid className={classes.content}>
        <Grid className={classes.successWrapper}>
          <Circular
            size={COUNTDOWN_CIRCLE_SIZE}
            icon={IconTypes.check}
            type={CircularType.success}
            iconClassName={classes.icon}
          />
        </Grid>
        <Grid className={classes.descriptionWrapper}>
          <Typography className={classes.descriptionTitle}>
            {t('YOUR_KEY_IS_READY', { current: currentKey, max: keysNumber })}
          </Typography>
          <Grid className={classes.buttonWrapper}>
            <Button onClick={onCancel} pattern={ButtonPattern.secondary}>
              {t('CANCEL')}
            </Button>
            <Button onClick={onContinue}>{t('ENCODE_NEXT_KEY')}</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(Success) as (
  props: PassedProps
) => JSX.Element;
