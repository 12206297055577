import {
  BaseKioskConfigurationEntity,
  KioskDictionaryEntryDetails,
  RawBaseKioskConfigurationEntity,
  RawKioskDictionaryEntryDetails,
} from '../../shared';

export interface RawKioskCommunicationType
  extends RawKioskDictionaryEntryDetails {
  communicationMode?: RawBaseKioskConfigurationEntity;
}

export class KioskCommunicationType extends KioskDictionaryEntryDetails {
  public communicationMode?: BaseKioskConfigurationEntity;

  constructor(raw: RawKioskCommunicationType) {
    super(raw);
    this.communicationMode =
      raw.communicationMode &&
      new BaseKioskConfigurationEntity(raw.communicationMode);
  }
}
