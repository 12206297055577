import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const INPUT_SIZE = 44;

export default ({ spacing, palette }: Theme) =>
  createStyles({
    wrapper: {
      marginTop: spacing(1),
    },
    title: {
      marginLeft: spacing(2),
      fontSize: '1.5rem',
    },
    subtitle: {
      fontSize: '0.875rem',
      color: palette.common.black,
      marginBottom: spacing(1 / 2),
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '&:not(:first-child)': {
        alignItems: 'flex-end',
      },
    },
    iconButton: {
      padding: `0 ${spacing(1)}px`,
    },
    icon: {
      fontSize: '2rem',
      color: palette.primary.main,
    },
    iconDisabled: {
      fontSize: '2rem',
      color: palette.text.disabled,
    },
    input: {
      width: INPUT_SIZE,
      height: INPUT_SIZE,
    },
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  });
