const FETCH_ROOM_ATTRIBUTES_REQUEST = 'FETCH_ROOM_ATTRIBUTES_REQUEST';
const FETCH_ROOM_ATTRIBUTES_SUCCESS = 'FETCH_ROOM_ATTRIBUTES_SUCCESS';
const FETCH_ROOM_ATTRIBUTES_FAILURE = 'FETCH_ROOM_ATTRIBUTES_FAILURE';

const FETCH_ROOM_IMAGES_REQUEST = 'FETCH_ROOM_IMAGES_REQUEST';
const FETCH_ROOM_IMAGES_SUCCESS = 'FETCH_ROOM_IMAGES_SUCCESS';
const FETCH_ROOM_IMAGES_FAILURE = 'FETCH_ROOM_IMAGES_FAILURE';

const FETCH_ROOM_IMAGE_CONTENT_REQUEST = 'FETCH_ROOM_IMAGE_CONTENT_REQUEST';
const FETCH_ROOM_IMAGE_CONTENT_SUCCESS = 'FETCH_ROOM_IMAGE_CONTENT_SUCCESS';
const FETCH_ROOM_IMAGE_CONTENT_FAILURE = 'FETCH_ROOM_IMAGE_CONTENT_FAILURE';

const FETCH_ROOM_TYPE_IMAGES_REQUEST = 'FETCH_ROOM_TYPE_IMAGES_REQUEST';
const FETCH_ROOM_TYPE_IMAGES_SUCCESS = 'FETCH_ROOM_TYPE_IMAGES_SUCCESS';
const FETCH_ROOM_TYPE_IMAGES_FAILURE = 'FETCH_ROOM_TYPE_IMAGES_FAILURE';

const FETCH_ROOM_TYPE_IMAGE_CONTENT_REQUEST =
  'FETCH_ROOM_TYPE_IMAGE_CONTENT_REQUEST';
const FETCH_ROOM_TYPE_IMAGE_CONTENT_SUCCESS =
  'FETCH_ROOM_TYPE_IMAGE_CONTENT_SUCCESS';
const FETCH_ROOM_TYPE_IMAGE_CONTENT_FAILURE =
  'FETCH_ROOM_TYPE_IMAGE_CONTENT_FAILURE';

const CLEAR_ROOM_GALLERY_ERRORS = 'CLEAR_ROOM_GALLERY_ERRORS';

export default {
  FETCH_ROOM_ATTRIBUTES_REQUEST,
  FETCH_ROOM_ATTRIBUTES_SUCCESS,
  FETCH_ROOM_ATTRIBUTES_FAILURE,
  FETCH_ROOM_IMAGES_REQUEST,
  FETCH_ROOM_IMAGES_SUCCESS,
  FETCH_ROOM_IMAGES_FAILURE,
  FETCH_ROOM_IMAGE_CONTENT_REQUEST,
  FETCH_ROOM_IMAGE_CONTENT_SUCCESS,
  FETCH_ROOM_IMAGE_CONTENT_FAILURE,
  FETCH_ROOM_TYPE_IMAGES_REQUEST,
  FETCH_ROOM_TYPE_IMAGES_SUCCESS,
  FETCH_ROOM_TYPE_IMAGES_FAILURE,
  FETCH_ROOM_TYPE_IMAGE_CONTENT_REQUEST,
  FETCH_ROOM_TYPE_IMAGE_CONTENT_SUCCESS,
  FETCH_ROOM_TYPE_IMAGE_CONTENT_FAILURE,
  CLEAR_ROOM_GALLERY_ERRORS,
};
