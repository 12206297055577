import { AlignItems } from '@ac/kiosk-components';
import { TableConfig } from '@ac/kiosk-components/dist/components/layoutComponents/Table/types';

export const tableConfig: TableConfig = {
  align: AlignItems.center,
  columns: [
    { sizeSm: 'minmax(80px, auto)' },
    { sizeSm: 'minmax(250px, auto)' },
    { sizeSm: '1fr' },
  ],
};
