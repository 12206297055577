import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { List, ListHeader, ListRow, RowItem } from 'components/List';
import { compose } from 'redux';
import { TransactionSummary } from 'types/Api/Shared';
import { Configurator } from 'utils';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import { chargeListStyles, chargeRowItemStyles } from './ChargeList.style';
import DetailsChip from './DetailsChip';
import { listConfig } from './listConfig';

interface PassedProps {
  transactions: TransactionSummary[];
  hideCurrency: boolean;
}

interface ChargeListProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof chargeListStyles> {}

class ChargeList extends PureComponent<ChargeListProps> {
  public static defaultProps = {
    transactions: [],
    hideCurrency: false,
  };

  private headers: string[];

  constructor(props: ChargeListProps) {
    super(props);

    const { t } = props;
    this.headers = [
      t('DATE'),
      t('DESCRIPTION'),
      t('AMOUNT'),
      t('QUANTITY'),
      t('TOTAL'),
    ];
  }

  public render() {
    const { t, transactions, classes, hideCurrency } = this.props;

    return (
      <>
        {!hideCurrency && (
          <Grid className={classes.currencyWrapper}>
            <Typography className={classes.currencyTitle}>{`${t(
              'CURRENCY'
            )}: `}</Typography>
            <Typography className={classes.subtitle}>
              {Configurator.propertySettings.currencyCode}
            </Typography>
          </Grid>
        )}

        <List listConfig={listConfig} className={classes.list}>
          <ListHeader headers={this.headers} rowClassName={classes.header} />
          {transactions.map(
            (
              {
                date,
                description,
                quantity,
                gross,
                totalGross,
                transactionType,
                id,
              },
              index
            ) => {
              const isTransactionPayment =
                transactionType === Configurator.transactionCodes.PAYMENT;

              return (
                <ListRow
                  key={`transactions-${id || index}`}
                  className={classNames({
                    [classes.payment]: isTransactionPayment,
                  })}
                >
                  <ChargeRowItem>{date}</ChargeRowItem>
                  <ChargeRowItem>{description}</ChargeRowItem>
                  <ChargeRowItem>
                    {isTransactionPayment ? '' : gross}
                  </ChargeRowItem>
                  <ChargeRowItem>{quantity || ''}</ChargeRowItem>
                  <ChargeRowItem>
                    {isTransactionPayment ? `-${totalGross}` : totalGross}
                  </ChargeRowItem>
                </ListRow>
              );
            }
          )}
        </List>
      </>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(chargeListStyles)
)(ChargeList) as (props: PassedProps) => JSX.Element;

interface ChargeRowItemProps extends WithStyles<typeof chargeRowItemStyles> {
  children: string | number | Array<{ quantity: number; title: string }>;
}

const ChargeRowItem = withStyles(chargeRowItemStyles)(
  ({ children, classes, ...rest }: ChargeRowItemProps) => {
    const isDataArray = Array.isArray(children);
    const dataToDisplay = isDataArray
      ? (children as Array<{ quantity: number; title: string }>).map((item) => (
          <DetailsChip
            key={`chip-${item.title}`}
            data={`${item.quantity} ${item.title}`}
          />
        ))
      : children;

    return (
      <RowItem
        wrapperClassName={classNames({ [classes.chipText]: isDataArray })}
        {...rest}
      >
        {dataToDisplay}
      </RowItem>
    );
  }
);
