import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_BUTTON_SIZE = 36;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    textField: {
      width: '100%',
      marginBottom: spacing(0.25),
    },
    inputPropsRoot: {
      height: 44,
      backgroundColor: palette.common.white,
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: `inset 0 0 0 1px ${colors.grey[500]}`,
      borderRadius: 4,
    },
    inputPropsInput: {
      border: 'none',
      padding: 0,
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      height: '100%',
    },
    inputPropsFocused: {
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: `inset 0 0 0 2px ${palette.primary.light}`,
    },
    inputPropsError: {
      transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: `inset 0 0 0 1px ${palette.error.main}`,
    },
    inputLabelRoot: {
      color: 'inherit',
      fontSize: '1rem',
    },
    inputLabelShrink: {
      color: 'inherit',
    },
    inputLabelError: {
      color: palette.error.main,
    },
    inputLabelFocused: {
      color: `${palette.primary.light} !important`,
    },
    fieldWrapper: {
      marginBottom: spacing(2),
    },
    icon: {
      height: '100%',
      width: '100%',
      fill: 'var(--primary-color)',
    },
    iconButton: {
      width: ICON_BUTTON_SIZE,
      height: ICON_BUTTON_SIZE,
      padding: spacing(0.75),
      marginRight: -spacing(0.5),
    },
    adornment: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
