import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BUTTON_WIDTH = 228;
const BUTTON_HEIGHT = 118;
const ICON_HEIGHT = 44;

export default ({ spacing }: Theme) =>
  createStyles({
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
    },
    button: {
      padding: `${spacing(2)}px 0`,
      width: BUTTON_WIDTH,
      height: BUTTON_HEIGHT,
      boxSizing: 'border-box',
      flexDirection: 'column',
    },
    icon: {
      '& svg': {
        height: ICON_HEIGHT,
        fill: 'currentColor',
      },
    },
  });
