import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BUTTON_HEIGHT = 44;
const ITEM_WIDTH = 228;
const ITEM_HEIGHT = 118;
const ITEM_BORDER_RADIUS = 6;
const ICON_SIZE = 22;

export const getBackground = (url: string) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundImage: `url('${url}')`,
});

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      width: ITEM_WIDTH,
      height: ITEM_HEIGHT,
      boxSizing: 'border-box',
      flexDirection: 'column',
      border: `2px solid ${palette.primary.main}`,
      borderRadius: ITEM_BORDER_RADIUS,
      marginRight: spacing(2),
    },
    icon: {
      '& .button-component-icon': {
        height: ICON_SIZE,
        width: ICON_SIZE,
      },
    },
    iconButton: {
      height: BUTTON_HEIGHT,
      minWidth: 0,
      padding: 0,
      position: 'absolute',
      borderRadius: 'inherit',
      top: 0,
      right: 0,
      borderTopRightRadius: 0,
    },
    buttonLabel: {
      height: '100%',
    },
    modalRoot: {
      zIndex: 9999,
    },
    backdropRoot: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    fullSizeImage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '&:focus': {
        outline: 'none',
      },
    },
  });
