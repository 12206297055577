import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CurrentProcessConsent } from 'store/checkInProcess/interfaces';
import { Configurator } from 'utils';

import {
  Icon,
  IconTypes,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

import { sortDisplayElements } from '@gss/utils';

import './Consents.scss';

interface ConsentsProps {
  dataTestSelector?: string;
  className?: string;
  consents?: CurrentProcessConsent[];
}

const Consents = ({ dataTestSelector, className, consents }: ConsentsProps) => {
  const { t, i18n } = useTranslation();

  const mappedConsents = useMemo(() => {
    const sortedConsents = sortDisplayElements(Configurator.consents);

    return sortedConsents.map((consent) => {
      const changedConsents = consents?.find(
        (item: CurrentProcessConsent) => item.id === consent.id
      );

      return {
        id: consent.id,
        description: Configurator.getDescription(consent.description),
        isGranted: changedConsents?.isGranted,
        isOptional: changedConsents?.isOptional,
      };
    });
  }, [i18n.language, consents]);

  return (
    <div
      className={classNames('consent-list-wrapper', className)}
      data-test-selector={dataTestSelector}
    >
      <Text
        size={TextSize.xlg}
        weight={TextWeight.light}
        className="spacing-bottom-sm"
      >
        {t('TERMS_AND_CONDITIONS')}
      </Text>
      <Text hint className="spacing-bottom-md">
        {`${t('I_ACCEPT')}:`}
      </Text>

      <ul className="padding-none spacing-none">
        {mappedConsents.map((item) => (
          <li className="consent-item" key={item.id}>
            <Icon
              className="consent-icon"
              type={item.isGranted ? IconTypes.check : IconTypes.cancel}
            />
            <Text>{item.description}</Text>
            {item.isOptional && (
              <Text className="spacing-left-s">{`(${t('OPTIONAL')})`}</Text>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Consents;
