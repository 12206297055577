import { KioskCheckInUpdateProfile } from '@gss/api/KioskApi/entries';

import { DocumentsFormValues } from '../DocumentsForm';

import types from './types';

export const saveIdPhoto = (photo: string) => ({
  type: types.SAVE_ID_PHOTO,
  payload: photo,
});

export const removeIdPhoto = (photo: string) => ({
  type: types.REMOVE_ID_PHOTO,
  payload: photo,
});

export const setCachedDocumentId = (id: string) => ({
  type: types.SET_CACHED_DOCUMENT_ID,
  payload: id,
});

export const cacheDocumentFormData = (formData: DocumentsFormValues) => ({
  type: types.CACHE_DOCUMENT_FORM_DATA,
  payload: formData,
});

export const setDocumentUpdatePayload = (
  communicationDetailsPayload: Pick<
    KioskCheckInUpdateProfile,
    'identityDocuments' | 'personalDetails'
  >
) => ({
  type: types.SET_DOCUMENT_UPDATE_PAYLOAD,
  payload: communicationDetailsPayload,
});

export const clearDocumentUpdatePayload = () => ({
  type: types.CLEAR_DOCUMENT_UPDATE_PAYLOAD,
});

export const resetDocumentUpdateProgress = () => ({
  type: types.RESET_DOCUMENT_UPDATE_PROGRESS,
});
