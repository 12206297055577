import { Selector } from 'react-redux';
import { createSelector } from 'reselect';

import { ApiError } from '@ac/library-api';

import { KioskConfigurationProperty } from '@gss/api/KioskApi/entries';
import { Store } from '@gss/store';

import { CustomMessagesState } from './interfaces/settings/customMessagesState';
import { EntitiesState } from './interfaces/settings/entitiesState';
import { ExternalSettingsState } from './interfaces/settings/externalSettingsState';
import { GeneralSettingsState } from './interfaces/settings/generalSettings';
import { ImagesSettingsState } from './interfaces/settings/imagesSettings';
import { StepsSettingsState } from './interfaces/settings/stepsSettingsState';
import { StylesSettingsState } from './interfaces/settings/stylesSate';

export const getSettingsErrors = (state: Store): Array<ApiError | Error> =>
  state.settings.errors;

export const getSettingsFetchState = (state: Store): boolean =>
  Object.values(state.settings.fetching).includes(true);

export const getAreSettingsInitialized = (state: Store): boolean =>
  state.settings.areSettingsInitialized;

export const getPropertyConfiguration = (
  state: Store
): KioskConfigurationProperty | undefined => state.settings.property;

export const getCustomMessages = (
  state: Store
): CustomMessagesState | undefined => state.settings.customMessages;

export const getStyles = (state: Store): StylesSettingsState | undefined =>
  state.settings.styles;

export const getImages = (state: Store): ImagesSettingsState | undefined =>
  state.settings.images;

export const getExternalSettings = (
  state: Store
): ExternalSettingsState | undefined => state.settings.externalSettings;

export const getGeneralSettings = (
  state: Store
): GeneralSettingsState | undefined => state.settings.generalSettings;

export const getStepsSettings = (
  state: Store
): StepsSettingsState | undefined => state.settings.stepsSettings;

export const getEntities = (state: Store): EntitiesState | undefined =>
  state.settings.entities;

const getEntityFactorySelector = <EntityName extends keyof EntitiesState>(
  type: EntityName
): Selector<Store, EntitiesState[EntityName] | undefined> =>
  createSelector(
    [getEntities],
    (
      entities: EntitiesState | undefined
    ): EntitiesState[EntityName] | undefined => {
      if (!entities) return undefined;

      return entities[type];
    }
  );

const getActiveEntityFactorySelector = <EntityName extends keyof EntitiesState>(
  type: EntityName
): Selector<Store, EntitiesState[EntityName] | undefined> =>
  createSelector(
    [getEntities],
    (
      entities: EntitiesState | undefined
    ): EntitiesState[EntityName] | undefined => {
      const entity = entities?.[type];
      if (!entity) return undefined;

      const filteredEntity = Array.isArray(entity)
        ? (entity as Array<{ isActive?: boolean; active?: boolean }>).filter(
            (item): boolean => {
              return item.isActive ?? item.active ?? true;
            }
          )
        : entity;

      return filteredEntity as EntitiesState[EntityName];
    }
  );

export const getConsents = getEntityFactorySelector('consents');
export const getActiveConsents = getActiveEntityFactorySelector('consents');

export const getAddressTypes = getEntityFactorySelector('addressTypes');
export const getActiveAddressTypes =
  getActiveEntityFactorySelector('addressTypes');

export const getCommunicationTypes =
  getEntityFactorySelector('communicationTypes');
export const getActiveCommunicationTypes =
  getActiveEntityFactorySelector('communicationTypes');

export const getCountries = getEntityFactorySelector('countries');

export const getDocumentTypes = getEntityFactorySelector('documentTypes');
export const getActiveDocumentTypes =
  getActiveEntityFactorySelector('documentTypes');

export const getGenders = getEntityFactorySelector('genders');

export const getLanguages = getEntityFactorySelector('languages');

export const getNationalities = getEntityFactorySelector('nationalities');

export const getProfileSuffixes = getEntityFactorySelector('profileSuffixes');

export const getPurposesOfStay = getEntityFactorySelector('purposesOfStay');
export const getActivePurposesOfStay =
  getActiveEntityFactorySelector('purposesOfStay');

export const getTelephoneRegionPrefixes = getEntityFactorySelector(
  'telephoneRegionPrefixes'
);

export const getTitles = getEntityFactorySelector('titles');
export const getActiveTitles = getActiveEntityFactorySelector('titles');

export const getSupportedUiLanguages = getEntityFactorySelector(
  'supportedUiLanguages'
);
