import {
  ApiActionResult,
  CallApiResponseAction,
  CallApiSpecificResponseAction,
  FailureCallApiResponseAction,
} from 'store/middleware/api';
import {
  disableFetching,
  failure,
  request,
  success,
} from 'store/utils/reducer';
import { BalanceOfAllOutstandingDepositRequests } from 'types/Api/Cashiering';
import { State } from 'types/Store/CashieringStore';
import { Api } from 'utils';

export const initialOutstandingDeposit: BalanceOfAllOutstandingDepositRequests =
  {
    reservationId: '',
    balance: {
      amount: 0,
    },
  };

export const reservationOutstandingDeposit = (
  state: State,
  action: CallApiSpecificResponseAction<BalanceOfAllOutstandingDepositRequests>
): State => {
  switch (action.apiAction) {
    case ApiActionResult.request:
      return request(state, 'cashiering');

    case ApiActionResult.failure:
      return depositBalanceFailureRequest(state, action);

    case ApiActionResult.success: {
      const {
        response: { data: reservationOutstandingDeposit },
      } = action;

      return {
        ...success(state, 'cashiering'),
        reservationOutstandingDeposit,
      };
    }

    default:
      return state;
  }
};

function depositBalanceFailureRequest(
  state: State,
  action: FailureCallApiResponseAction
): State {
  if (depositWasNotFound(action)) {
    return {
      ...state,
      dataFetching: disableFetching(state.dataFetching, 'cashiering'),
      reservationOutstandingDeposit: initialOutstandingDeposit,
    };
  }

  return failure(state, action, 'cashiering');
}

function depositWasNotFound(action: CallApiResponseAction): boolean {
  const errors = Api.getErrors(action.response, []);

  return errors.length === 1 && errors[0].code === 'DepositRequestsNotFound';
}
