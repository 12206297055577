import { TranslatedDescription } from 'types/Api/Shared';

export interface PurchaseElement {
  id: string;
  code: string;
  description: TranslatedDescription[];
  active: boolean;
  forceToUse: boolean;
  isLocked: boolean;
  longDescription: TranslatedDescription[];
  sellFromDate: string;
  sellToDate: string;
  transactionCodeId: string;
  calculationRuleCode: string;
  pricingIds: string[];
  kioskAddon: boolean;
  inventoryItemId?: string;
}

export interface PurchaseElementImages {
  [key: string]: string[];
}

export interface RatePlan {
  id: string;
  code: string;
  description: TranslatedDescription[];
  purchaseElements: Array<{
    purchaseElementId: string;
    isPriceIncluded: boolean;
    postSeparately: boolean;
  }>;
}

export enum AgeQualifyingCode {
  Adult = 'Adult',
  Child = 'Child',
}

export interface AgeBucket {
  id: string;
  version: number;
  zoneId: string;
  code?: string;
  description?: TranslatedDescription[];
  isActive?: boolean;
  forceToUse?: boolean;
  displaySequence?: number;
  ageFrom?: number;
  ageTo?: number;
  ageQualifyingCode: AgeQualifyingCode;
}
