import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormHeader, FormTextField } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';
import { isLoading } from 'store/selectors';
import Store from 'types/Store';
import { paths } from 'utils/Router';
import { required } from 'utils/Validator';

import { Button, ButtonPattern } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './CheckOutAuthForm.style';

interface PassedProps {
  onSubmit: (values: { lastName: string; roomNumber: string }) => void;
}

interface CheckOutAuthFormProps
  extends PassedProps,
    InjectedFormProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  isLoading: boolean;
}

class CheckOutAuthForm extends PureComponent<CheckOutAuthFormProps> {
  public render() {
    const {
      classes,
      t,
      pristine,
      invalid,
      handleSubmit,
      submitting,
      isLoading,
    } = this.props;
    const isSubmitDisabled = pristine || invalid;
    const isButtonLoading = submitting || isLoading;

    return (
      <div>
        <FormHeader
          title={t('COMPLETE_THE_FORM')}
          subtitle={t('ALL_FIELDS_MANDATORY')}
        />
        <Form onSubmit={handleSubmit} autoComplete="off">
          <div className={classes.formWrapper}>
            <Field
              name="lastName"
              component={FormTextField}
              validate={[required]}
              label={t('LAST_NAME')}
              type="text"
              className={classes.formInput}
            />
            <Field
              name="roomNumber"
              validate={[required]}
              component={FormTextField}
              label={t('ROOM_NUMBER')}
              type="text"
              className={classes.formInput}
            />
          </div>
          <div className={classes.buttonsWrapper}>
            <Button
              id={ids.CHECKOUT_AUTH_FORM_CANCEL_BUTTON}
              onClick={this.cancel}
              pattern={ButtonPattern.secondary}
            >
              {t('CANCEL')}
            </Button>
            <Button
              id={ids.CHECKOUT_AUTH_FORM_SUBMIT_BUTTON}
              type="submit"
              disabled={isButtonLoading || isSubmitDisabled}
            >
              {t('START_CHECK_OUT')}
            </Button>
          </div>
        </Form>
      </div>
    );
  }

  private changeRoute = (route: string) => {
    const { history } = this.props;
    history.push(route);
  };

  private cancel = () => this.changeRoute(paths.WELCOME);
}

const mapStateToProps = (state: Store) => ({
  isLoading: isLoading(state),
});

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  reduxForm({ form: ids.CHECKOUT_AUTH_FORM }),
  connect(mapStateToProps)
)(CheckOutAuthForm) as (props: PassedProps) => JSX.Element;
