import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ spacing }: Theme) =>
  createStyles({
    title: {
      fontSize: '1,5rem',
      marginBottom: spacing(1),
    },
    bodyText: {
      fontSize: '1rem',
      marginBottom: spacing(1),
    },
  });
