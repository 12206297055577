import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BUTTON_HEIGHT = 36;

export default ({ spacing }: Theme) =>
  createStyles({
    selectButton: {
      height: BUTTON_HEIGHT,
      padding: spacing(0.5, 2),
      fontSize: '0.9rem',
    },
  });
