import {
  DocumentScanningErrorReport,
  RawDocumentScanningErrorReport,
} from './documentScanningErrorReport';
import {
  DocumentScanningResultDetails,
  RawDocumentScanningResultDetails,
} from './documentScanningResultDetails';
import { DocumentScanningResultStatus } from './documentScanningResultStatus';

export interface RawDocumentScanningResult {
  status: DocumentScanningResultStatus;
  result?: RawDocumentScanningResultDetails;
  error?: RawDocumentScanningErrorReport;
}

export class DocumentScanningResult {
  public status: DocumentScanningResultStatus;
  public result?: RawDocumentScanningResultDetails;
  public error?: RawDocumentScanningErrorReport;

  constructor(raw: RawDocumentScanningResult) {
    this.status = raw.status;
    this.result = raw.result && new DocumentScanningResultDetails(raw.result);
    this.error = raw.error && new DocumentScanningErrorReport(raw.error);
  }
}
