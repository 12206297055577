import { History } from 'history';
import { Router } from 'utils';
import { Path } from 'utils/Router';
import { useCorectStep } from 'views/CheckInCardPayment/routing';

export function useCorectStepInPrepayment(
  history: History<unknown>,
  isLoading: boolean,
  hasError: boolean,
  isPrepaymentStepAvailable: boolean
) {
  useCorectStep(
    history,
    isLoading,
    hasError,
    () => configurePrepaymentSteps(isPrepaymentStepAvailable),
    () => isPrepaymentStepAvailable
  );
}

export function configurePrepaymentSteps(isPrepaymentStepAvailable: boolean) {
  const stepToRemove = isPrepaymentStepAvailable
    ? 'PRE_AUTHORIZATION'
    : 'PREPAYMENT';
  if (!isPrepaymentStepAvailable)
    console.warn('Prepayment step is going to be skipped'); // eslint-disable-line no-console
  Router.removeStep(Path.checkIn, stepToRemove);
}

export function isPrepaymentAvailable(outstandingDepositAmount: number) {
  return outstandingDepositAmount > 0;
}
