import { FilterPageQueryParams } from '@ac/library-api';
import { pagedListRequester } from '@ac/library-api/dist/api/requester';

import { SelfServiceDeviceDto, SelfServicePropertyDto } from '../entries';

const path = {
  properties: '/kiosk/v0/self-service/initialization/properties',
  devices: '/kiosk/v0/self-service/initialization/devices',
};

export const SelfServiceInitializationApiCacheKey =
  'SelfServiceInitializationApi';
export const SelfServiceInitializationPropertiesApiCacheKey = `${SelfServiceInitializationApiCacheKey}Properties`;
export const SelfServiceInitializationDevicesApiCacheKey = `${SelfServiceInitializationApiCacheKey}Devices`;

export const getProperties = pagedListRequester<
  undefined,
  FilterPageQueryParams,
  SelfServicePropertyDto
>({
  path: path.properties,
  cacheKey: SelfServiceInitializationPropertiesApiCacheKey,
});

export const getDevices = pagedListRequester<
  undefined,
  FilterPageQueryParams,
  SelfServiceDeviceDto
>({
  path: path.devices,
  cacheKey: SelfServiceInitializationDevicesApiCacheKey,
});
