import { asKeyedRoutes } from '../utils/asKeyedRoutes';

export const initialStepsRoutes = asKeyedRoutes({
  LOGIN: {
    path: '/login',
  },
  LOGOUT: {
    path: '/logout',
    optionalStep: true,
  },
  SETUP: {
    path: '/setup',
  },
  DEVICE_SETUP: {
    path: '/device-setup',
  },
  MISSING_DEVICE: {
    path: '/missing-devices',
    optionalStep: true,
  },
});
