import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const TOP_POSITION = -100;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    errorContainer: {
      marginTop: -spacing(2),
    },
    hiddenInput: {
      visibility: 'hidden',
      position: 'relative',
      top: TOP_POSITION,
    },
    textField: {
      marginBottom: 0,
    },
    formWrapper: {
      marginBottom: spacing(2),
    },
  });
