import { Action } from 'redux-actions';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { getLanguageCode } from 'utils/Configurator';

import { SelfServiceConfigurationApi } from '@gss/api/KioskApi';
import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';
import { API_HEADERS } from '@gss/configs/constants';
import { changeAppLanguage } from '@gss/store/configuration/actions';
import { SagasGenerator } from '@gss/types/shared';

import { ChangeLanguagePayload } from '../configuration/interfaces';

import * as actions from './actions';
import { getAllDistrictResults, getAllStateResults } from './selectors';
import { FetchDictionaryPayload } from './types';

function* handleFetchStates(
  action: Action<FetchDictionaryPayload>
): SagasGenerator {
  const { countryCode, languageCode, skipCache = false } = action.payload;

  try {
    const states: KioskStateDetails[] = yield call(
      SelfServiceConfigurationApi.getStates,
      {
        pathParams: { code: countryCode },
        customConfig: {
          headers: {
            [API_HEADERS.acceptLanguage]: languageCode,
          },
          skipCache,
        },
      }
    );

    yield put(
      actions.fetchStates.success({
        ...action.payload,
        data: states,
      })
    );
  } catch (error) {
    yield put(
      actions.fetchStates.failure({
        ...action.payload,
        error,
      })
    );
  }
}

function* handleFetchDistricts(
  action: Action<FetchDictionaryPayload>
): SagasGenerator {
  const {
    countryCode,
    languageCode = getLanguageCode(),
    skipCache = false,
  } = action.payload;

  try {
    const districts: BaseKioskConfigurationEntity[] = yield call(
      SelfServiceConfigurationApi.getDistricts,
      {
        pathParams: { code: countryCode },
        customConfig: {
          headers: { [API_HEADERS.acceptLanguage]: languageCode },
          skipCache,
        },
      }
    );

    yield put(
      actions.fetchDistricts.success({
        ...action.payload,
        data: districts,
      })
    );
  } catch (error) {
    yield put(
      actions.fetchDistricts.failure({
        ...action.payload,
        error,
      })
    );
  }
}

function* refreshLazyDictionaryAfterLanguageChange(
  languageCode: string
): SagasGenerator {
  const allStates: Record<string, KioskStateDetails[]> = yield select(
    getAllStateResults
  );
  const allDistricts: Record<string, KioskStateDetails[]> = yield select(
    getAllDistrictResults
  );

  const allCountryCodesForStates = Object.keys(allStates);
  const allCountryCodesForDistricts = Object.keys(allDistricts);

  for (const countryCodeForStates of allCountryCodesForStates) {
    yield put(
      actions.fetchStates.trigger({
        countryCode: countryCodeForStates,
        languageCode,
        skipCache: true,
      })
    );
  }

  for (const countryCodeForDistricts of allCountryCodesForDistricts) {
    yield put(
      actions.fetchDistricts.trigger({
        countryCode: countryCodeForDistricts,
        languageCode,
        skipCache: true,
      })
    );
  }
}

function* changeAppLanguageHandler(
  action: Action<ChangeLanguagePayload>
): SagasGenerator {
  yield fork(refreshLazyDictionaryAfterLanguageChange, action.payload.language);
}

export function* lazyLoadedDictionarySagas(): SagasGenerator {
  yield all([
    takeEvery(actions.fetchStates.trigger, handleFetchStates),
    takeEvery(actions.fetchDistricts.trigger, handleFetchDistricts),
    takeLatest(changeAppLanguage.success, changeAppLanguageHandler),
  ]);
}
