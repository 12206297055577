import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const MODAL_WIDTH = 474;
const MODAL_HEIGHT = 310;
const ICON_SIZE = 88;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    modal: {
      outline: 'none',
    },
    wrapper: {
      width: MODAL_WIDTH,
      height: MODAL_HEIGHT,
      borderRadius: 6,
      outline: 'none',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      background: palette.common.white,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      width: '100%',
    },
    backdrop: {
      background: 'rgba(0, 0, 0, 0.8)',
    },
    modalInfo: {
      padding: `${spacing(4)}px ${spacing(2)}px`,
    },
    circular: {
      margin: 'auto',
    },
    icon: {
      color: 'var(--primary-color)',
    },
    button: {
      margin: 'auto',
    },
  });
