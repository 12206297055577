const FETCH_MEMBERSHIP_LEVELS_REQUEST = 'FETCH_MEMBERSHIP_LEVELS_REQUEST';
const FETCH_MEMBERSHIP_LEVELS_SUCCESS = 'FETCH_MEMBERSHIP_LEVELS_SUCCESS';
const FETCH_MEMBERSHIP_LEVELS_FAILURE = 'FETCH_MEMBERSHIP_LEVELS_FAILURE';

const FETCH_MEMBERSHIP_TYPES_REQUEST = 'FETCH_MEMBERSHIP_TYPES_REQUEST';
const FETCH_MEMBERSHIP_TYPES_SUCCESS = 'FETCH_MEMBERSHIP_TYPES_SUCCESS';
const FETCH_MEMBERSHIP_TYPES_FAILURE = 'FETCH_MEMBERSHIP_TYPES_FAILURE';

const FETCH_ATTRIBUTE_TYPES_REQUEST = 'FETCH_ATTRIBUTE_TYPES_REQUEST';
const FETCH_ATTRIBUTE_TYPES_SUCCESS = 'FETCH_ATTRIBUTE_TYPES_SUCCESS';
const FETCH_ATTRIBUTE_TYPES_FAILURE = 'FETCH_ATTRIBUTE_TYPES_FAILURE';

const FETCH_PROFILE_TYPES_REQUEST = 'FETCH_PROFILE_TYPES_REQUEST';
const FETCH_PROFILE_TYPES_SUCCESS = 'FETCH_PROFILE_TYPES_SUCCESS';
const FETCH_PROFILE_TYPES_FAILURE = 'FETCH_PROFILE_TYPES_FAILURE';

const FETCH_PROPERTY_FLOORS_REQUEST = 'FETCH_PROPERTY_FLOORS_REQUEST';
const FETCH_PROPERTY_FLOORS_SUCCESS = 'FETCH_PROPERTY_FLOORS_SUCCESS';
const FETCH_PROPERTY_FLOORS_FAILURE = 'FETCH_PROPERTY_FLOORS_FAILURE';

const FETCH_ROOM_LOCATIONS_REQUEST = 'FETCH_ROOM_LOCATIONS_REQUEST';
const FETCH_ROOM_LOCATIONS_SUCCESS = 'FETCH_ROOM_LOCATIONS_SUCCESS';
const FETCH_ROOM_LOCATIONS_FAILURE = 'FETCH_ROOM_LOCATIONS_FAILURE';

const CLEAR_ENTITIES_ERRORS = 'CLEAR_ENTITIES_ERRORS';

const FETCH_TRANSACTION_CODES_REQUEST = 'FETCH_TRANSACTION_CODES_REQUEST';
const FETCH_TRANSACTION_CODES_SUCCESS = 'FETCH_TRANSACTION_CODES_SUCCESS';
const FETCH_TRANSACTION_CODES_FAILURE = 'FETCH_TRANSACTION_CODES_FAILURE';

const FETCH_KIOSK_TRANSACTION_CODE_REQUEST =
  'FETCH_KIOSK_TRANSACTION_CODE_REQUEST';
const FETCH_KIOSK_TRANSACTION_CODE_SUCCESS =
  'FETCH_KIOSK_TRANSACTION_CODE_SUCCESS';
const FETCH_KIOSK_TRANSACTION_CODE_FAILURE =
  'FETCH_KIOSK_TRANSACTION_CODE_FAILURE';

const FETCH_INTERFACE_CASHIERS_REQUEST = 'FETCH_INTERFACE_CASHIERS_REQUEST';
const FETCH_INTERFACE_CASHIERS_SUCCESS = 'FETCH_INTERFACE_CASHIERS_SUCCESS';
const FETCH_INTERFACE_CASHIERS_FAILURE = 'FETCH_INTERFACE_CASHIERS_FAILURE';

const FETCH_CASHIER_NUMBER_REQUEST = 'FETCH_CASHIER_NUMBER_REQUEST';
const FETCH_CASHIER_NUMBER_SUCCESS = 'FETCH_CASHIER_NUMBER_SUCCESS';
const FETCH_CASHIER_NUMBER_FAILURE = 'FETCH_CASHIER_NUMBER_FAILURE';

const FETCH_TAX_RULES_REQUEST = 'FETCH_TAX_RULES_REQUEST';
const FETCH_TAX_RULES_SUCCESS = 'FETCH_TAX_RULES_SUCCESS';
const FETCH_TAX_RULES_FAILURE = 'FETCH_TAX_RULES_FAILURE';

const FETCH_FOLIO_STYLES_REQUEST = 'FETCH_FOLIO_STYLES_REQUEST';
const FETCH_FOLIO_STYLES_SUCCESS = 'FETCH_FOLIO_STYLES_SUCCESS';
const FETCH_FOLIO_STYLES_FAILURE = 'FETCH_FOLIO_STYLES_FAILURE';

const FETCH_DEFAULT_FOLIO_STYLE_REQUEST = 'FETCH_DEFAULT_FOLIO_STYLE_REQUEST';
const FETCH_DEFAULT_FOLIO_STYLE_SUCCESS = 'FETCH_DEFAULT_FOLIO_STYLE_SUCCESS';
const FETCH_DEFAULT_FOLIO_STYLE_FAILURE = 'FETCH_DEFAULT_FOLIO_STYLE_FAILURE';

const FETCH_CREDIT_CARD_AUTHORIZATION_RULE_REQUEST =
  'FETCH_CREDIT_CARD_AUTHORIZATION_RULE_REQUEST';
const FETCH_CREDIT_CARD_AUTHORIZATION_RULE_SUCCESS =
  'FETCH_CREDIT_CARD_AUTHORIZATION_RULE_SUCCESS';
const FETCH_CREDIT_CARD_AUTHORIZATION_RULE_FAILURE =
  'FETCH_CREDIT_CARD_AUTHORIZATION_RULE_FAILURE';

export default {
  FETCH_MEMBERSHIP_LEVELS_REQUEST,
  FETCH_MEMBERSHIP_LEVELS_SUCCESS,
  FETCH_MEMBERSHIP_LEVELS_FAILURE,
  FETCH_MEMBERSHIP_TYPES_REQUEST,
  FETCH_MEMBERSHIP_TYPES_SUCCESS,
  FETCH_MEMBERSHIP_TYPES_FAILURE,
  FETCH_ATTRIBUTE_TYPES_REQUEST,
  FETCH_ATTRIBUTE_TYPES_SUCCESS,
  FETCH_ATTRIBUTE_TYPES_FAILURE,
  FETCH_PROFILE_TYPES_REQUEST,
  FETCH_PROFILE_TYPES_SUCCESS,
  FETCH_PROFILE_TYPES_FAILURE,
  FETCH_ROOM_LOCATIONS_REQUEST,
  FETCH_ROOM_LOCATIONS_SUCCESS,
  FETCH_ROOM_LOCATIONS_FAILURE,
  FETCH_PROPERTY_FLOORS_REQUEST,
  FETCH_PROPERTY_FLOORS_SUCCESS,
  FETCH_PROPERTY_FLOORS_FAILURE,
  CLEAR_ENTITIES_ERRORS,
  FETCH_TRANSACTION_CODES_REQUEST,
  FETCH_TRANSACTION_CODES_SUCCESS,
  FETCH_TRANSACTION_CODES_FAILURE,
  FETCH_KIOSK_TRANSACTION_CODE_REQUEST,
  FETCH_KIOSK_TRANSACTION_CODE_SUCCESS,
  FETCH_KIOSK_TRANSACTION_CODE_FAILURE,
  FETCH_CASHIER_NUMBER_REQUEST,
  FETCH_CASHIER_NUMBER_SUCCESS,
  FETCH_CASHIER_NUMBER_FAILURE,
  FETCH_INTERFACE_CASHIERS_REQUEST,
  FETCH_INTERFACE_CASHIERS_SUCCESS,
  FETCH_INTERFACE_CASHIERS_FAILURE,
  FETCH_TAX_RULES_REQUEST,
  FETCH_TAX_RULES_SUCCESS,
  FETCH_TAX_RULES_FAILURE,
  FETCH_FOLIO_STYLES_REQUEST,
  FETCH_FOLIO_STYLES_SUCCESS,
  FETCH_FOLIO_STYLES_FAILURE,
  FETCH_DEFAULT_FOLIO_STYLE_REQUEST,
  FETCH_DEFAULT_FOLIO_STYLE_SUCCESS,
  FETCH_DEFAULT_FOLIO_STYLE_FAILURE,
  FETCH_CREDIT_CARD_AUTHORIZATION_RULE_REQUEST,
  FETCH_CREDIT_CARD_AUTHORIZATION_RULE_SUCCESS,
  FETCH_CREDIT_CARD_AUTHORIZATION_RULE_FAILURE,
};
