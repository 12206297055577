/* eslint-disable max-len */
import {
  FETCH_ACTIVE_AUTHORIZATION,
  FETCH_ACTIVE_AUTHORIZATIONS,
  FETCH_ACTIVE_GIVEN_AUTHORIZATIONS,
} from 'store/cashiering/authorization/types';
import { FETCH_FOLIO } from 'store/cashiering/folio/types';
import { FETCH_RESERVATION_OUTSTANDING_DEPOSIT } from 'store/cashiering/reservationOutstandingDeposit/types';

const CHECK_OUT_REQUEST = 'CHECK_OUT_REQUEST';
const CHECK_OUT_SUCCESS = 'CHECK_OUT_SUCCESS';
const CHECK_OUT_FAILURE = 'CHECK_OUT_FAILURE';

const CHECK_OUT_FOLIO_REQUEST = 'CHECK_OUT_FOLIO_REQUEST';
const CHECK_OUT_FOLIO_SUCCESS = 'CHECK_OUT_FOLIO_SUCCESS';
const CHECK_OUT_FOLIO_FAILURE = 'CHECK_OUT_FOLIO_FAILURE';

const FETCH_FOLIOS_REQUEST = 'FETCH_FOLIOS_REQUEST';
const FETCH_FOLIOS_SUCCESS = 'FETCH_FOLIOS_SUCCESS';
const FETCH_FOLIOS_FAILURE = 'FETCH_FOLIOS_FAILURE';

const ADD_COMPANY_FOLIO_REQUEST = 'ADD_COMPANY_FOLIO_REQUEST';
const ADD_COMPANY_FOLIO_SUCCESS = 'ADD_COMPANY_FOLIO_SUCCESS';
const ADD_COMPANY_FOLIO_FAILURE = 'ADD_COMPANY_FOLIO_FAILURE';

const ADD_PAYMENT_METHOD_REQUEST = 'ADD_PAYMENT_METHOD_REQUEST';
const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
const ADD_PAYMENT_METHOD_FAILURE = 'ADD_PAYMENT_METHOD_FAILURE';

const FETCH_PAYMENT_METHOD_STATUS_REQUEST =
  'FETCH_PAYMENT_METHOD_STATUS_REQUEST';
const FETCH_PAYMENT_METHOD_STATUS_SUCCESS =
  'FETCH_PAYMENT_METHOD_STATUS_SUCCESS';
const FETCH_PAYMENT_METHOD_STATUS_FAILURE =
  'FETCH_PAYMENT_METHOD_STATUS_FAILURE';

const ADD_BILLING_INSTRUCTION_REQUEST = 'ADD_BILLING_INSTRUCTION_REQUEST';
const ADD_BILLING_INSTRUCTION_SUCCESS = 'ADD_BILLING_INSTRUCTION_SUCCESS';
const ADD_BILLING_INSTRUCTION_FAILURE = 'ADD_BILLING_INSTRUCTION_FAILURE';

const FETCH_CHECK_OUT_STATUS_REQUEST = 'FETCH_CHECK_OUT_STATUS_REQUEST';
const FETCH_CHECK_OUT_STATUS_SUCCESS = 'FETCH_CHECK_OUT_STATUS_SUCCESS';
const FETCH_CHECK_OUT_STATUS_FAILURE = 'FETCH_CHECK_OUT_STATUS_FAILURE';

const FETCH_CHECK_OUT_FOLIO_STATUS_REQUEST =
  'FETCH_CHECK_OUT_FOLIO_STATUS_REQUEST';
const FETCH_CHECK_OUT_FOLIO_STATUS_SUCCESS =
  'FETCH_CHECK_OUT_FOLIO_STATUS_SUCCESS';
const FETCH_CHECK_OUT_FOLIO_STATUS_FAILURE =
  'FETCH_CHECK_OUT_FOLIO_STATUS_FAILURE';

const APPLY_BILLING_INSTRUCTIONS_REQUEST = 'APPLY_BILLING_INSTRUCTIONS_REQUEST';
const APPLY_BILLING_INSTRUCTIONS_SUCCESS = 'APPLY_BILLING_INSTRUCTIONS_SUCCESS';
const APPLY_BILLING_INSTRUCTIONS_FAILURE = 'APPLY_BILLING_INSTRUCTIONS_FAILURE';

const FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_REQUEST =
  'FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_REQUEST';
const FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_SUCCESS =
  'FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_SUCCESS';
const FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_FAILURE =
  'FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_FAILURE';

const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';

const FETCH_CASHIERING_ACCOUNT_REQUEST = 'FETCH_CASHIERING_ACCOUNT_REQUEST';
const FETCH_CASHIERING_ACCOUNT_SUCCESS = 'FETCH_CASHIERING_ACCOUNT_SUCCESS';
const FETCH_CASHIERING_ACCOUNT_FAILURE = 'FETCH_CASHIERING_ACCOUNT_FAILURE';

const FETCH_BILLING_INSTRUCTIONS_REQUEST = 'FETCH_BILLING_INSTRUCTIONS_REQUEST';
const FETCH_BILLING_INSTRUCTIONS_SUCCESS = 'FETCH_BILLING_INSTRUCTIONS_SUCCESS';
const FETCH_BILLING_INSTRUCTIONS_FAILURE = 'FETCH_BILLING_INSTRUCTIONS_FAILURE';

const UPDATE_FOLIO_PROFILE_REQUEST = 'UPDATE_FOLIO_PROFILE_REQUEST';
const UPDATE_FOLIO_PROFILE_SUCCESS = 'UPDATE_FOLIO_PROFILE_SUCCESS';
const UPDATE_FOLIO_PROFILE_FAILURE = 'UPDATE_FOLIO_PROFILE_FAILURE';

const SEND_INVOICE_EMAIL_REQUEST = 'SEND_INVOICE_EMAIL_REQUEST';
const SEND_INVOICE_EMAIL_SUCCESS = 'SEND_INVOICE_EMAIL_SUCCESS';
const SEND_INVOICE_EMAIL_FAILURE = 'SEND_INVOICE_EMAIL_FAILURE';

const SEND_FINAL_INVOICE_MAIL_REQUEST = 'SEND_FINAL_INVOICE_MAIL_REQUEST';
const SEND_FINAL_INVOICE_MAIL_SUCCESS = 'SEND_FINAL_INVOICE_MAIL_SUCCESS';
const SEND_FINAL_INVOICE_MAIL_FAILURE = 'SEND_FINAL_INVOICE_MAIL_FAILURE';

const FETCH_FOLIO_TRANSACTIONS_REQUEST = 'FETCH_FOLIO_TRANSACTIONS_REQUEST';
const FETCH_FOLIO_TRANSACTIONS_SUCCESS = 'FETCH_FOLIO_TRANSACTIONS_SUCCESS';
const FETCH_FOLIO_TRANSACTIONS_FAILURE = 'FETCH_FOLIO_TRANSACTIONS_FAILURE';

const FETCH_FOLIO_PROFILE_REQUEST = 'FETCH_FOLIO_PROFILE_REQUEST';
const FETCH_FOLIO_PROFILE_SUCCESS = 'FETCH_FOLIO_PROFILE_SUCCESS';
const FETCH_FOLIO_PROFILE_FAILURE = 'FETCH_FOLIO_PROFILE_FAILURE';

const ADD_TRANSACTION_REQUEST = 'ADD_TRANSACTION_REQUEST';
const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS';
const ADD_TRANSACTION_FAILURE = 'ADD_TRANSACTION_FAILURE';

const FETCH_ADD_TRANSACTION_STATUS_REQUEST =
  'FETCH_ADD_TRANSACTION_STATUS_REQUEST';
const FETCH_ADD_TRANSACTION_STATUS_SUCCESS =
  'FETCH_ADD_TRANSACTION_STATUS_SUCCESS';
const FETCH_ADD_TRANSACTION_STATUS_FAILURE =
  'FETCH_ADD_TRANSACTION_STATUS_FAILURE';

const FETCH_PAYMENT_METHODS_REQUEST = 'FETCH_PAYMENT_METHODS_REQUEST';
const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS';
const FETCH_PAYMENT_METHODS_FAILURE = 'FETCH_PAYMENT_METHODS_FAILURE';

const VOID_AUTHORIZATION_REQUEST = 'VOID_AUTHORIZATION_REQUEST';
const VOID_AUTHORIZATION_SUCCESS = 'VOID_AUTHORIZATION_SUCCESS';
const VOID_AUTHORIZATION_FAILURE = 'VOID_AUTHORIZATION_FAILURE';

const FETCH_VOID_AUTHORIZATION_STATUS_REQUEST =
  'FETCH_VOID_AUTHORIZATION_STATUS_REQUEST';
const FETCH_VOID_AUTHORIZATION_STATUS_SUCCESS =
  'FETCH_VOID_AUTHORIZATION_STATUS_SUCCESS';
const FETCH_VOID_AUTHORIZATION_STATUS_FAILURE =
  'FETCH_VOID_AUTHORIZATION_STATUS_FAILURE';

const FULL_CHECK_OUT_REQUEST = 'FULL_CHECK_OUT_REQUEST';
const FULL_CHECK_OUT_SUCCESS = 'FULL_CHECK_OUT_SUCCESS';
const FULL_CHECK_OUT_FAILURE = 'FULL_CHECK_OUT_FAILURE';

const UPDATE_AUTHORIZATION_REQUEST = 'UPDATE_AUTHORIZATION_REQUEST';
const UPDATE_AUTHORIZATION_SUCCESS = 'UPDATE_AUTHORIZATION_SUCCESS';
const UPDATE_AUTHORIZATION_FAILURE = 'UPDATE_AUTHORIZATION_FAILURE';

const FETCH_UPDATE_AUTHORIZATION_STATUS_REQUEST =
  'FETCH_UPDATE_AUTHORIZATION_STATUS_REQUEST';
const FETCH_UPDATE_AUTHORIZATION_STATUS_SUCCESS =
  'FETCH_UPDATE_AUTHORIZATION_STATUS_SUCCESS';
const FETCH_UPDATE_AUTHORIZATION_STATUS_FAILURE =
  'FETCH_UPDATE_AUTHORIZATION_STATUS_FAILURE';

const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST';
const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';

const FETCH_AUTHORIZATION_REQUEST = 'FETCH_AUTHORIZATION_REQUEST';
const FETCH_AUTHORIZATION_SUCCESS = 'FETCH_AUTHORIZATION_SUCCESS';
const FETCH_AUTHORIZATION_FAILURE = 'FETCH_AUTHORIZATION_FAILURE';

const CLEAR_CASHIERING_ERRORS = 'CLEAR_CASHIERING_ERRORS';
const CLEAR_PRE_AUTHORIZATION = 'CLEAR_PRE_AUTHORIZATION';

const LINK_PAYMENT_METHOD_REQUEST = 'LINK_PAYMENT_METHOD_REQUEST';
const LINK_PAYMENT_METHOD_SUCCESS = 'LINK_PAYMENT_METHOD_SUCCESS';
const LINK_PAYMENT_METHOD_FAILURE = 'LINK_PAYMENT_METHOD_FAILURE';

const UNLINK_PAYMENT_METHOD_REQUEST = 'UNLINK_PAYMENT_METHOD_REQUEST';
const UNLINK_PAYMENT_METHOD_SUCCESS = 'UNLINK_PAYMENT_METHOD_SUCCESS';
const UNLINK_PAYMENT_METHOD_FAILURE = 'UNLINK_PAYMENT_METHOD_FAILURE';

const TRANSFER_TRANSACTIONS_REQUEST = 'TRANSFER_TRANSACTIONS_REQUEST';
const TRANSFER_TRANSACTIONS_SUCCESS = 'TRANSFER_TRANSACTIONS_SUCCESS';
const TRANSFER_TRANSACTIONS_FAILURE = 'TRANSFER_TRANSACTIONS_FAILURE';

const CHECK_OUT_FOLIO_SEPARATELY_REQUEST = 'CHECK_OUT_FOLIO_SEPARATELY_REQUEST';
const CHECK_OUT_FOLIO_SEPARATELY_SUCCESS = 'CHECK_OUT_FOLIO_SEPARATELY_SUCCESS';
const CHECK_OUT_FOLIO_SEPARATELY_FAILURE = 'CHECK_OUT_FOLIO_SEPARATELY_FAILURE';

const SET_MINIBAR_PENDING_CHARGES = 'SET_MINIBAR_PENDING_CHARGES';

const SAVE_EMAIL_TO_SEND_INVOICE = 'SAVE_EMAIL_TO_SEND_INVOICE';

const SET_FOLIO_SETTLEMENT_STATE = 'SET_FOLIO_SETTLEMENT_STATE';

const FETCH_KIOSK_AUTHORIZATION_AMOUNT_REQUEST =
  'FETCH_KIOSK_AUTHORIZATION_AMOUNT_REQUEST';
const FETCH_KIOSK_AUTHORIZATION_AMOUNT_SUCCESS =
  'FETCH_KIOSK_AUTHORIZATION_AMOUNT_SUCCESS';
const FETCH_KIOSK_AUTHORIZATION_AMOUNT_FAILURE =
  'FETCH_KIOSK_AUTHORIZATION_AMOUNT_FAILURE';

const FETCH_NOT_POSTED_CHARGES_REQUEST = 'FETCH_NOT_POSTED_CHARGES_REQUEST';
const FETCH_NOT_POSTED_CHARGES_SUCCESS = 'FETCH_NOT_POSTED_CHARGES_SUCCESS';
const FETCH_NOT_POSTED_CHARGES_FAILURE = 'FETCH_NOT_POSTED_CHARGES_FAILURE';

const CHARGE_RESERVATION_REQUEST = 'CHARGE_RESERVATION_REQUEST';
const CHARGE_RESERVATION_SUCCESS = 'CHARGE_RESERVATION_SUCCESS';
const CHARGE_RESERVATION_FAILURE = 'CHARGE_RESERVATION_FAILURE';

const FETCH_CHARGE_RESERVATION_STATUS_REQUEST =
  'FETCH_CHARGE_RESERVATION_STATUS_REQUEST';
const FETCH_CHARGE_RESERVATION_STATUS_SUCCESS =
  'FETCH_CHARGE_RESERVATION_STATUS_SUCCESS';
const FETCH_CHARGE_RESERVATION_STATUS_FAILURE =
  'FETCH_CHARGE_RESERVATION_STATUS_FAILURE';

const UPDATE_FOLIO_STYLE_CODE_REQUEST = 'UPDATE_FOLIO_STYLE_CODE_REQUEST';
const UPDATE_FOLIO_STYLE_CODE_SUCCESS = 'UPDATE_FOLIO_STYLE_CODE_SUCCESS';
const UPDATE_FOLIO_STYLE_CODE_FAILURE = 'UPDATE_FOLIO_STYLE_CODE_FAILURE';

const UPDATE_FOLIO_PROFILE_DETAILS_REQUEST =
  'UPDATE_FOLIO_PROFILE_DETAILS_REQUEST';
const UPDATE_FOLIO_PROFILE_DETAILS_SUCCESS =
  'UPDATE_FOLIO_PROFILE_DETAILS_SUCCESS';
const UPDATE_FOLIO_PROFILE_DETAILS_FAILURE =
  'UPDATE_FOLIO_PROFILE_DETAILS_FAILURE';

const FETCH_AVAILABLE_FOLIO_STYLES_REQUEST =
  'FETCH_AVAILABLE_FOLIO_STYLES_REQUEST';
const FETCH_AVAILABLE_FOLIO_STYLES_SUCCESS =
  'FETCH_AVAILABLE_FOLIO_STYLES_SUCCESS';
const FETCH_AVAILABLE_FOLIO_STYLES_FAILURE =
  'FETCH_AVAILABLE_FOLIO_STYLES_FAILURE';

export default {
  CLEAR_CASHIERING_ERRORS,
  CHECK_OUT_FOLIO_REQUEST,
  CHECK_OUT_FOLIO_SUCCESS,
  CHECK_OUT_FOLIO_FAILURE,
  CHECK_OUT_REQUEST,
  CHECK_OUT_SUCCESS,
  CHECK_OUT_FAILURE,
  FETCH_FOLIOS_REQUEST,
  FETCH_FOLIOS_SUCCESS,
  FETCH_FOLIOS_FAILURE,
  FETCH_CHECK_OUT_FOLIO_STATUS_REQUEST,
  FETCH_CHECK_OUT_FOLIO_STATUS_SUCCESS,
  FETCH_CHECK_OUT_FOLIO_STATUS_FAILURE,
  FETCH_CHECK_OUT_STATUS_REQUEST,
  FETCH_CHECK_OUT_STATUS_SUCCESS,
  FETCH_CHECK_OUT_STATUS_FAILURE,
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_CASHIERING_ACCOUNT_REQUEST,
  FETCH_CASHIERING_ACCOUNT_SUCCESS,
  FETCH_CASHIERING_ACCOUNT_FAILURE,
  ADD_COMPANY_FOLIO_REQUEST,
  ADD_COMPANY_FOLIO_SUCCESS,
  ADD_COMPANY_FOLIO_FAILURE,
  ADD_BILLING_INSTRUCTION_REQUEST,
  ADD_BILLING_INSTRUCTION_SUCCESS,
  ADD_BILLING_INSTRUCTION_FAILURE,
  FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_REQUEST,
  FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_SUCCESS,
  FETCH_APPLY_BILLING_INSTRUCTIONS_STATUS_FAILURE,
  APPLY_BILLING_INSTRUCTIONS_REQUEST,
  APPLY_BILLING_INSTRUCTIONS_SUCCESS,
  APPLY_BILLING_INSTRUCTIONS_FAILURE,
  FETCH_BILLING_INSTRUCTIONS_REQUEST,
  FETCH_BILLING_INSTRUCTIONS_SUCCESS,
  FETCH_BILLING_INSTRUCTIONS_FAILURE,
  UPDATE_FOLIO_PROFILE_REQUEST,
  UPDATE_FOLIO_PROFILE_SUCCESS,
  UPDATE_FOLIO_PROFILE_FAILURE,
  SEND_INVOICE_EMAIL_REQUEST,
  SEND_INVOICE_EMAIL_SUCCESS,
  SEND_INVOICE_EMAIL_FAILURE,
  SEND_FINAL_INVOICE_MAIL_REQUEST,
  SEND_FINAL_INVOICE_MAIL_SUCCESS,
  SEND_FINAL_INVOICE_MAIL_FAILURE,
  FETCH_FOLIO_TRANSACTIONS_REQUEST,
  FETCH_FOLIO_TRANSACTIONS_SUCCESS,
  FETCH_FOLIO_TRANSACTIONS_FAILURE,
  FETCH_FOLIO_PROFILE_REQUEST,
  FETCH_FOLIO_PROFILE_SUCCESS,
  FETCH_FOLIO_PROFILE_FAILURE,
  ADD_TRANSACTION_REQUEST,
  ADD_TRANSACTION_SUCCESS,
  ADD_TRANSACTION_FAILURE,
  FETCH_ADD_TRANSACTION_STATUS_REQUEST,
  FETCH_ADD_TRANSACTION_STATUS_SUCCESS,
  FETCH_ADD_TRANSACTION_STATUS_FAILURE,
  ADD_PAYMENT_METHOD_REQUEST,
  ADD_PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_FAILURE,
  FETCH_PAYMENT_METHOD_STATUS_REQUEST,
  FETCH_PAYMENT_METHOD_STATUS_SUCCESS,
  FETCH_PAYMENT_METHOD_STATUS_FAILURE,
  CLEAR_PRE_AUTHORIZATION,
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_SUCCESS,
  FETCH_PAYMENT_METHODS_FAILURE,
  VOID_AUTHORIZATION_REQUEST,
  VOID_AUTHORIZATION_SUCCESS,
  VOID_AUTHORIZATION_FAILURE,
  FETCH_RESERVATION_OUTSTANDING_DEPOSIT,
  FETCH_FOLIO,
  FETCH_ACTIVE_AUTHORIZATIONS,
  FETCH_ACTIVE_GIVEN_AUTHORIZATIONS,
  FETCH_ACTIVE_AUTHORIZATION,
  FULL_CHECK_OUT_REQUEST,
  FULL_CHECK_OUT_SUCCESS,
  FULL_CHECK_OUT_FAILURE,
  FETCH_VOID_AUTHORIZATION_STATUS_REQUEST,
  FETCH_VOID_AUTHORIZATION_STATUS_SUCCESS,
  FETCH_VOID_AUTHORIZATION_STATUS_FAILURE,
  UPDATE_AUTHORIZATION_REQUEST,
  UPDATE_AUTHORIZATION_SUCCESS,
  UPDATE_AUTHORIZATION_FAILURE,
  FETCH_UPDATE_AUTHORIZATION_STATUS_REQUEST,
  FETCH_UPDATE_AUTHORIZATION_STATUS_SUCCESS,
  FETCH_UPDATE_AUTHORIZATION_STATUS_FAILURE,
  DELETE_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_FAILURE,
  FETCH_AUTHORIZATION_REQUEST,
  FETCH_AUTHORIZATION_SUCCESS,
  FETCH_AUTHORIZATION_FAILURE,
  LINK_PAYMENT_METHOD_REQUEST,
  LINK_PAYMENT_METHOD_SUCCESS,
  LINK_PAYMENT_METHOD_FAILURE,
  UNLINK_PAYMENT_METHOD_REQUEST,
  UNLINK_PAYMENT_METHOD_SUCCESS,
  UNLINK_PAYMENT_METHOD_FAILURE,
  TRANSFER_TRANSACTIONS_REQUEST,
  TRANSFER_TRANSACTIONS_SUCCESS,
  TRANSFER_TRANSACTIONS_FAILURE,
  SET_MINIBAR_PENDING_CHARGES,
  SAVE_EMAIL_TO_SEND_INVOICE,
  SET_FOLIO_SETTLEMENT_STATE,
  CHECK_OUT_FOLIO_SEPARATELY_REQUEST,
  CHECK_OUT_FOLIO_SEPARATELY_SUCCESS,
  CHECK_OUT_FOLIO_SEPARATELY_FAILURE,
  FETCH_KIOSK_AUTHORIZATION_AMOUNT_REQUEST,
  FETCH_KIOSK_AUTHORIZATION_AMOUNT_SUCCESS,
  FETCH_KIOSK_AUTHORIZATION_AMOUNT_FAILURE,
  CHARGE_RESERVATION_REQUEST,
  CHARGE_RESERVATION_SUCCESS,
  CHARGE_RESERVATION_FAILURE,
  FETCH_CHARGE_RESERVATION_STATUS_REQUEST,
  FETCH_CHARGE_RESERVATION_STATUS_SUCCESS,
  FETCH_CHARGE_RESERVATION_STATUS_FAILURE,
  FETCH_NOT_POSTED_CHARGES_REQUEST,
  FETCH_NOT_POSTED_CHARGES_SUCCESS,
  FETCH_NOT_POSTED_CHARGES_FAILURE,
  UPDATE_FOLIO_STYLE_CODE_REQUEST,
  UPDATE_FOLIO_STYLE_CODE_SUCCESS,
  UPDATE_FOLIO_STYLE_CODE_FAILURE,
  UPDATE_FOLIO_PROFILE_DETAILS_REQUEST,
  UPDATE_FOLIO_PROFILE_DETAILS_SUCCESS,
  UPDATE_FOLIO_PROFILE_DETAILS_FAILURE,
  FETCH_AVAILABLE_FOLIO_STYLES_REQUEST,
  FETCH_AVAILABLE_FOLIO_STYLES_SUCCESS,
  FETCH_AVAILABLE_FOLIO_STYLES_FAILURE,
};
