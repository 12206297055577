import { LogMessage, LogReporter } from '@ac/kiosk-components';

import { LOG_TYPES } from '@gss/configs/constants';

type LogDetails = Pick<LogMessage, 'message' | 'details'>;

const AUTH_ERRORS_MAX_LOG_SIZE = 5;

export const cacheLoginErrorLog = (message: string): void => {
  const cachedAuthErrors = localStorage.getItem(LOG_TYPES.authErrorCache);
  const errors: LogDetails[] = cachedAuthErrors
    ? JSON.parse(cachedAuthErrors)
    : [];

  if (errors.length >= AUTH_ERRORS_MAX_LOG_SIZE) {
    errors.shift();
  }

  errors.push({
    message,
    details: prepareLoginErrorLogDetails(),
  });

  localStorage.setItem(LOG_TYPES.authErrorCache, JSON.stringify(errors));
};

export const getCachedLoginErrorLogs = (): string[] => {
  const cachedAuthErrors = localStorage.getItem(LOG_TYPES.authErrorCache);
  const parsedAuthErrors = cachedAuthErrors ? JSON.parse(cachedAuthErrors) : [];

  return parsedAuthErrors.map((log: LogDetails) => JSON.stringify(log));
};

export const clearCachedLoginErrorLogs = (): void => {
  localStorage.removeItem(LOG_TYPES.authErrorCache);
};

const prepareLoginErrorLogDetails = () => {
  const loginErrorTimestamp = Date.now();
  const authLogs = LogReporter.getLogs(LOG_TYPES.auth).reverse();

  const lastAuthToken = authLogs.find(
    ({ message, date }) =>
      message.startsWith('AccessToken exp') &&
      date &&
      new Date(date).getTime() < loginErrorTimestamp
  );

  const lastAuthTokenExp = lastAuthToken?.message.split(' ')?.[2];

  return {
    loginErrorDate: new Date(loginErrorTimestamp).toUTCString(),
    previousSuccessfulAuth: {
      date: lastAuthToken?.date,
      tokenExpiry:
        lastAuthTokenExp &&
        new Date(Number(lastAuthTokenExp) * 1000).toUTCString(),
    },
  };
};
