import { createSelector } from 'reselect';
import { Transaction } from 'types/Api/Aggregator';
import Store from 'types/Store/AggregatorStore';
import { Configurator, DateManager } from 'utils';

const { REVENUE } = Configurator.transactionCodes;

export const getAllTransactions = (state: Store) =>
  state.aggregator.transactions || [];

export const getTotalBalance = (state: Store) => state.aggregator.totalBalance;

export const getTransactions = createSelector(
  getAllTransactions,
  (transactions) => transactions.map(mapTransactions)
);

// const calculateTax = (transaction: Transaction) => {
//   if (transaction.internalTransactions && transaction.internalTransactions.length > 0) {
//     return transaction.internalTransactions[0].taxes
//       .reduce((a, b) => a + Number(b.amount.amount), 0)
//       .toFixed(2);
//   }
//   return 0;
// };

export const mapTransactions = (transaction: Transaction) => {
  const { transactionGroupTypeCode, transactionType } = transaction;
  const type = transactionType || transactionGroupTypeCode;
  const isRevenue = type === REVENUE;

  return {
    id: transaction.id,
    transactionType: type,
    date: DateManager.getFormattedDate(
      transaction.businessDate,
      Configurator.dateFormat.shortDateFormat
    ),
    description: transaction.transactionCode.description,
    totalGross: Number(transaction.grossAmount.amount).toFixed(2),
    gross: Number(transaction.grossUnitPrice.amount).toFixed(2),
    quantity: isRevenue ? transaction.quantity : 0,
    // tax: isRevenue ? calculateTax(transaction) : '',
    net: isRevenue ? Number(transaction.netUnitPrice.amount).toFixed(2) : '',
    totalNet: isRevenue ? Number(transaction.netAmount.amount).toFixed(2) : '',
    transactionCode: transaction.transactionCode.code,
  };
};
