import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import classNames from 'classnames';
import Carousel from 'nuka-carousel';
import { compose } from 'redux';
import { TypographyVariant } from 'types/MaterialUI';

import { Loader } from '@ac/kiosk-components';

import { Grid, Modal, Typography } from '@material-ui/core';
import { Close as CloseIcon, ZoomIn as ZoomInIcon } from '@material-ui/icons';
import { WithStyles, withStyles } from '@material-ui/styles';

import CarouselButton from '../CarouselButton';
import RoomGalleryElement from '../RoomGalleryElement';

import styles from './RoomGallery.style';

interface PassedProps {
  toggleModal: () => void;
  images: string[];
  isOpen: boolean;
  isLoading: boolean;
}

interface RoomGalleryProps
  extends PassedProps,
    WithStyles<typeof styles>,
    WithTranslation {}

interface RoomGalleryState {
  slideIndex: number;
}

class RoomGallery extends PureComponent<RoomGalleryProps, RoomGalleryState> {
  public static defaultProps = {
    images: [],
    isOpen: false,
    isLoading: false,
  };

  public state = {
    slideIndex: 0,
  };

  public render() {
    const { classes, toggleModal, isOpen, isLoading, images } = this.props;
    const { slideIndex } = this.state;

    const mdGalleryWidth = isOpen ? 12 : 6;
    const isEmpty = images.length === 0;
    const shouldWrapAround = images.length > 1;

    if (isLoading || isEmpty) return this.renderPlaceholder();

    return (
      <this.GalleryWrapper>
        <Grid
          item
          xs={12}
          sm={12}
          md={mdGalleryWidth}
          className={classNames(classes.wrapper, {
            [classes.modalGallery]: isOpen,
          })}
        >
          <Carousel
            heightMode="max"
            className={classes.slider}
            renderBottomCenterControls={() => null}
            renderTopRightControls={this.renderTopRightControls}
            renderCenterLeftControls={this.renderCenterLeftControls}
            renderCenterRightControls={this.renderCenterRightControls}
            slideIndex={slideIndex}
            afterSlide={this.setSlideIndex}
            wrapAround={shouldWrapAround}
          >
            {images.map((img) => (
              <RoomGalleryElement
                key={`gallery-item-${img}`}
                img={img}
                onClick={toggleModal}
              />
            ))}
          </Carousel>
        </Grid>
      </this.GalleryWrapper>
    );
  }

  private setSlideIndex = (slideIndex: number) => this.setState({ slideIndex });

  private renderTopRightControls = () => {
    const { isOpen, toggleModal } = this.props;

    return (
      <CarouselButton
        Icon={isOpen ? CloseIcon : ZoomInIcon}
        onClick={toggleModal}
      />
    );
  };

  private renderCenterLeftControls = ({
    previousSlide,
  }: {
    previousSlide: () => void;
  }) => {
    const { images } = this.props;

    return images.length > 1 ? (
      <CarouselButton direction="prev" onClick={previousSlide} />
    ) : null;
  };

  private renderCenterRightControls = ({
    nextSlide,
  }: {
    nextSlide: () => void;
  }) => {
    const { images } = this.props;

    return images.length > 1 ? (
      <CarouselButton direction="next" onClick={nextSlide} />
    ) : null;
  };

  private renderPlaceholder() {
    const { t, classes, isLoading } = this.props;

    return (
      <div className={classes.placeholder}>
        {isLoading ? (
          <Loader title={t('LOADING')} description={t('PLEASE_WAIT')} />
        ) : (
          <Typography variant={TypographyVariant.h6} align="center">
            {t('NO_ROOM_IMAGES')}
          </Typography>
        )}
      </div>
    );
  }

  private GalleryWrapper = ({ children }: { children: any }) => {
    const { isOpen, classes, toggleModal } = this.props;

    return isOpen ? (
      <>
        <Modal
          open={isOpen}
          onBackdropClick={toggleModal}
          classes={{ root: classes.modalRoot }}
          className="with-default-kiosk-components-theme"
          BackdropProps={{
            classes: { root: classes.backdropRoot },
          }}
        >
          {children}
        </Modal>
        <div className={classes.gutter} />
      </>
    ) : (
      <>{children}</>
    );
  };
}

export default compose(withStyles(styles), withTranslation())(RoomGallery) as (
  props: PassedProps
) => JSX.Element;
