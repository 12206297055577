enum ImageExtensions {
  png = 'png',
  jpeg = 'jpeg',
  jpg = 'jpg',
}

// tested also https://www.npmjs.com/package/png-to-jpeg
// but it was slower about 4 to 5x
async function convertPNGToJPEG(png: string): Promise<string> {
  const pngImage = new Image();
  const imageLoading = new Promise((resolve) => (pngImage.onload = resolve));
  pngImage.src = png;
  await imageLoading;

  const canvas = document.createElement('canvas');
  canvas.width = pngImage.width;
  canvas.height = pngImage.height;
  const canvasContext = canvas.getContext('2d');
  if (!canvasContext) {
    return png;
  }
  canvasContext.drawImage(pngImage, 0, 0);

  return canvas.toDataURL(`image/${ImageExtensions.jpeg}`);
}

function getFileExtension(photo: string): string | null | undefined {
  const photoExtensionRegex = /^data:image\/(.*);base64,/;

  const extensionGroups = photo.match(photoExtensionRegex);

  return extensionGroups && extensionGroups[1];
}

export default {
  getFileExtension,
  ImageExtensions,
  convertPNGToJPEG,
};
