import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const {
  colorCodes: {
    BUTTON_PRIMARY_TEXT_ACTIVE,
    BUTTON_PRIMARY_TEXT_DISABLED,
    BODY_FONT_COLOR,
  },
} = Configurator;

const RADIO_SIZE = 24;
const TICK_SIZE = 16;

const tick = {
  width: TICK_SIZE,
  height: TICK_SIZE,
  borderRadius: 100,
};

export default ({ palette, spacing }: Theme) =>
  createStyles({
    controlLabel: {
      fontSize: 16,
      color: 'inherit',
    },
    radio: {
      width: RADIO_SIZE,
      height: RADIO_SIZE,
      backgroundColor: 'white',
      boxSizing: 'border-box',
      border: `1px solid ${Configurator.getColor(
        BODY_FONT_COLOR,
        palette.common.black
      )}`,
      borderRadius: 100,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tick: {
      ...tick,
      backgroundColor: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_ACTIVE,
        palette.primary.main
      ),
    },
    tickDisabled: {
      ...tick,
      backgroundColor: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_DISABLED,
        palette.text.disabled
      ),
    },
  });
