import { createSelector } from 'reselect';
import { getPurchaseItems } from 'store/cashiering/selectors';
import {
  getPreAddedPurchaseElements,
  getPurchaseElementsIdsFirstNightRate,
  getPurchaseElementsIdsLastNightRate,
} from 'store/reservation/selectors';
import { ApiPurchaseItem, DailyPrice } from 'types/Api/Availability';
import { GuestCount, PurchaseElementData } from 'types/Api/Reservation';
import Store from 'types/Store/CheckInAddonStore';
import { Configurator, DateManager } from 'utils';

const DATE_FORMAT = Configurator.dateFormat?.shortDateFormat;

export const getConfigurationAddons = (language: string) =>
  Configurator.getPurchaseElements(language)
    .filter((elem) => elem.kioskAddon)
    .reduce(
      (prev, current) => ({
        ...prev,
        [current.code]: current,
      }),
      {}
    );

export const getDailyPrices = (dailyPrices: any, date: string) => {
  const formattedDate = DateManager.getFormattedDate(date, DATE_FORMAT);

  return dailyPrices.filter((elem: any) => elem.day === formattedDate);
};

const isPerPerson = (code: string): boolean =>
  code === Configurator.calculationRuleCodes.PER_PERSON;

export const getCalculationRule = (code: string) =>
  isPerPerson(code) ? 'PER_STAY' : 'PER_ITEM';

export const getPriceForGuest = (guest: GuestCount, prices: DailyPrice[]) => {
  const ageBucketPrice = prices.find(
    (item) => item.ageBucketId === guest.ageBucketId
  );

  return ageBucketPrice
    ? ageBucketPrice.price
    : {
        gross: 0,
        net: 0,
      };
};

const getPurchaseElements = (
  allPurchaseItems: Array<{ id: string }>,
  ratePurchaseItems: PurchaseElementData[],
  isIncludedInRatePlan: boolean
) => {
  const ratePurchaseItemsIds = ratePurchaseItems?.map(
    (item) => item.purchaseElementId
  );
  const allPurchaseItemsFiltered = allPurchaseItems.filter((item) =>
    ratePurchaseItemsIds?.includes(item.id)
  );

  return allPurchaseItemsFiltered.map((item) => ({
    ...item,
    fromRate: isIncludedInRatePlan,
  }));
};

export const getOnlyPreAdded = createSelector(
  getPurchaseItems,
  getPreAddedPurchaseElements,
  (allPurchaseItems, preAddedPurchaseElements) => {
    return preAddedPurchaseElements
      ? getPurchaseElements(allPurchaseItems, preAddedPurchaseElements, false)
      : [];
  }
);

export const getPurchaseElementsFromFirstNightRate = createSelector(
  getPurchaseItems,
  getPurchaseElementsIdsFirstNightRate,
  (allPurchaseItems, ratePurchaseItems) =>
    ratePurchaseItems
      ? getPurchaseElements(allPurchaseItems, ratePurchaseItems, true)
      : []
);

export const getPurchaseElementsFromLastNightRate = createSelector(
  getPurchaseItems,
  getPurchaseElementsIdsLastNightRate,
  (allPurchaseItems, ratePurchaseItems) =>
    ratePurchaseItems
      ? getPurchaseElements(allPurchaseItems, ratePurchaseItems, true)
      : []
);

export const getConfirmedNewPurchaseOrders = (state: Store) =>
  state.checkInAddon.confirmedNewPurchaseOrders;

export const getConfirmedNewInventoryItems = (state: Store) =>
  state.checkInAddon.confirmedNewInventoryItems;
