import {
  AmountLockBehaviour,
  BreakdownUpdatePayload,
  ReservationExtendedBreakdown,
} from 'types/Api/Reservation';

export function mapReservationExtendedBreakdownToUpdateBreakdownPayload(
  breakdown: ReservationExtendedBreakdown[]
): BreakdownUpdatePayload[] {
  return breakdown.map((item) => {
    const { accommodationPrice, totalDailyPrice, ratePricingDetails } = item;

    const amountLock = {
      behaviour: item.amountLock?.lockedReason
        ? AmountLockBehaviour.Locked
        : AmountLockBehaviour.Undefined,
      lockedReason: item.amountLock?.lockedReason,
    };

    return {
      fromDate: item.fromDate,
      toDate: item.toDate,
      guests: item.guests,
      ratePricingDetails: {
        ratePlanId: ratePricingDetails.ratePlanId,
        roomTypeToChargeId: ratePricingDetails.roomTypeToChargeId,
        promotionId: ratePricingDetails.promotionId,
        memberRateId: ratePricingDetails.memberRateId,
      },
      marketSegmentId: item.marketSegmentId,
      ratePricePerDay: accommodationPrice?.amount && {
        basePriceType: accommodationPrice.basePriceType,
        currency: accommodationPrice.currency,
        ...accommodationPrice.amount,
      },
      pricePerDay: totalDailyPrice?.amount && {
        basePriceType: totalDailyPrice.basePriceType,
        currency: totalDailyPrice.currency,
        ...totalDailyPrice.amount,
      },
      discount: item.discount,
      type: item.type,
      amountLock,
    };
  });
}

export default {
  mapReservationExtendedBreakdownToUpdateBreakdownPayload,
};
