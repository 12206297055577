import Big from 'big.js';
import {
  PurchaseElementData,
  PurchaseItemBreakdown,
  PurchasePrice,
  Reservation,
  ReservationExtendedBreakdown,
  ReservationView,
  UpdatePurchaseElementBreakdown,
  UpdatePurchaseElementData,
} from 'types/Api/Reservation';
import { DateManager } from 'utils';

export const getOperationId = (location: string) => {
  const result = location.split('/');

  return result[result.length - 1];
};

export const parsePreAddedElements = (
  preAddedElements: PurchaseElementData[]
): PurchaseElementData[] => {
  return preAddedElements.map((element) => {
    const quantity =
      element.breakdown[0].flat?.quantity ??
      element.breakdown[0].occupancy?.[0].quantity ??
      0;

    return {
      ...element,
      quantity,
    };
  });
};

export const parseLinkedPurchase = (
  breakdownElement: ReservationExtendedBreakdown
): PurchaseElementData[] => {
  return (breakdownElement.linkedPurchaseElements || []).map(
    ({ id, quantity, flatAmount, guestAmounts }) => {
      const breakdownElem: PurchaseItemBreakdown = {
        fromDate: breakdownElement.fromDate,
        toDate: breakdownElement.toDate,
      };

      if (flatAmount) {
        breakdownElem.flat = {
          quantity,
          unitPrice: flatAmount,
        };
      }

      if (guestAmounts) {
        breakdownElem.occupancy = guestAmounts.map((guest) => ({
          guestCount: guest.ageBucketCount,
          unitPrice: guest.amount,
          quantity,
        }));
      }

      return {
        quantity,
        purchaseElementId: id,
        breakdown: [breakdownElem],
      };
    }
  );
};

export const parseElementsFromRate = (
  elementsFromRate: ReservationExtendedBreakdown[]
): PurchaseElementData[] => {
  return elementsFromRate.reduce((prev: PurchaseElementData[], curr) => {
    const linkedPurchaseElem = parseLinkedPurchase(curr);

    linkedPurchaseElem.forEach((newElement) => {
      const equalPrevElement = prev.find(
        (previosElement) =>
          previosElement.purchaseElementId === newElement.purchaseElementId
      );

      if (equalPrevElement) {
        equalPrevElement.breakdown.push(...newElement.breakdown);
      } else {
        prev.push(newElement);
      }
    });

    return prev;
  }, []);
};

export const mergePreAddedPurchasesWithChanges = (
  preAddedPurchases: PurchaseElementData[],
  confirmedNewPurchaseOrders: PurchaseElementData[],
  newPurchases: UpdatePurchaseElementData[],
  removed: string[]
): Array<PurchaseElementData | UpdatePurchaseElementData> => {
  const unchangedPurchaseElements = [
    ...preAddedPurchases,
    ...confirmedNewPurchaseOrders,
  ]
    .filter(({ id }) => !newPurchases.find((newElem) => newElem.id === id))
    .filter(({ id }) => !removed.includes(id as string));

  return [...unchangedPurchaseElements, ...newPurchases];
};

export const sumItem = (
  previous: number,
  current: PurchaseItemBreakdown | UpdatePurchaseElementBreakdown
): number => {
  if ('flat' in current) {
    const { unitPrice, quantity } = current.flat as PurchasePrice;

    return new Big(unitPrice.gross).times(quantity).plus(previous).toNumber();
  }

  if ('occupancy' in current) {
    const currentOccupancyPrice = (current.occupancy as PurchasePrice[])
      .reduce((prev, curr) => {
        const {
          unitPrice: { gross },
          guestCount,
          quantity,
        } = curr;
        const currentPrice = new Big(gross)
          .times(guestCount!.count)
          .times(quantity)
          .toNumber();

        return prev.plus(currentPrice);
      }, new Big(0))
      .toNumber();

    return new Big(previous).plus(currentOccupancyPrice).toNumber();
  }

  const {
    price: { gross },
    guestCount,
    quantity,
    from,
    to,
  } = current as UpdatePurchaseElementBreakdown;

  const period = DateManager.getDiffBetween(from, to);

  return new Big(gross)
    .times(guestCount)
    .times(quantity)
    .times(period)
    .plus(previous)
    .toNumber();
};

export const sumPrices = (
  items: Array<PurchaseElementData | UpdatePurchaseElementData>
) => {
  return items
    .reduce((prev, { breakdown }) => {
      const purchases = breakdown as Array<
        UpdatePurchaseElementBreakdown | PurchaseItemBreakdown
      >;
      const breakdownSum = purchases.reduce(sumItem, 0);

      return prev.plus(breakdownSum);
    }, new Big(0))
    .toNumber();
};

export const parseReservationData = (data: Reservation | ReservationView) => {
  const {
    projectedRevenueAmount,
    accountId,
    linkedTravelAgent,
    linkedCompany,
    guestContactProfiles,
    relations,
  } = data;

  return {
    projectedRevenueAmount,
    accountId,
    reservations: [
      {
        ...data,
        ...(relations &&
          !Array.isArray(relations) && {
            sharingId: relations.shareId,
            segment: relations.segment,
          }),
      },
    ],
    linkedProfiles: {
      linkedTravelAgent,
      linkedCompany,
      guestContactProfiles,
    },
  };
};
