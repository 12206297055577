import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EditEmailForm } from 'components';
import { compose } from 'redux';

import { Grid } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './EditEmail.style';

interface PassedProps {
  onCancel?: () => void;
  displayedEmail?: string;
  currentFolioNumber?: number;
}

interface EditEmailProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  displayedEmail: string;
  currentFolioNumber: number;
  onCancel: () => void;
}

class EditEmail extends PureComponent<EditEmailProps> {
  public render() {
    const { classes, onCancel, displayedEmail, currentFolioNumber } =
      this.props;

    return (
      <Grid className={classes.content}>
        <EditEmailForm
          onCancel={onCancel}
          displayedEmail={displayedEmail}
          currentFolioNumber={currentFolioNumber}
        />
      </Grid>
    );
  }
}

export default compose(withTranslation(), withStyles(styles))(EditEmail) as (
  props: PassedProps
) => JSX.Element;
