import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const WRAPPER_HEIGHT = 44;

const getWrapperStyles = (palette: any, spacing: any) => ({
  height: WRAPPER_HEIGHT,
  backgroundColor: palette.common.white,
  color: palette.common.black,
  borderRadius: 6,
  display: 'flex',
  padding: 0,
  paddingLeft: spacing(1.25),
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:not(:last-child)': {
    marginBottom: spacing(0.5),
  },
});

export default ({ breakpoints, palette, spacing }: Theme) =>
  createStyles({
    contentWrapper: {
      ...getWrapperStyles(palette, spacing),
    },
    wrapper: {
      marginBottom: spacing(1),
    },
    panel: {
      display: 'flex',
      height: '100%',
    },
    panelInput: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    panelElement: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    inputField: {
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    removeIcon: {
      fontSize: '2rem',
      color: palette.primary.main,
    },
    iconButton: {
      padding: 2,
    },
    icon: {
      fontSize: '2rem',
    },
    removeIconDisabled: {
      fontSize: '2rem',
      color: palette.text.disabled,
    },
    title: {
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      boxSizing: 'border-box',
      paddingRight: spacing(1),
      fontSize: '1rem',
      color: 'inherit',
    },
    emptyItemWrapper: {
      ...getWrapperStyles(palette, spacing),
      justifyContent: 'center',
    },
  });
