import React, { PureComponent } from 'react';

import { Chip } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './DetailsChip.style';

interface DetailsChipProps extends WithStyles<typeof styles> {
  data: string;
}

class DetailsChip extends PureComponent<DetailsChipProps> {
  public render() {
    const { data, classes } = this.props;

    return (
      <Chip
        component="span"
        variant="outlined"
        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
        label={data}
        className={classes.chip}
      />
    );
  }
}

export default withStyles(styles)(DetailsChip);
