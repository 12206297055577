import { routes } from './routesConfigurations/routes';

export interface RouterStepCounter {
  stepIndex: number;
  currentStep: number;
  totalSteps: number;
}

export enum FlowType {
  checkIn = 'checkIn',
  makeKeys = 'makeKeys',
  checkOut = 'checkOut',
  initial = 'initial',
}

export interface RouteType {
  path: string;
  switch?: string[];
  isDisabled?: boolean;
  optionalStep?: boolean;
  excludedFromCounter?: boolean;
}

export type RouteName = keyof typeof routes;

export type RoutesMap = Record<RouteName, RouteType>;

export type RoutesViews<TRoutes> = Record<keyof TRoutes, React.ComponentType>;
