import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { ApiError } from 'types/Api/Shared';
import { Configurator } from 'utils';

import {
  Button,
  Circular,
  CircularType,
  IconTypes,
} from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './Alert.style';

interface PassedProps {
  onClick?: () => void;
  defaultError?: string;
  error?: string;
  customErrorCode?: string;
  customDescription?:
    | Array<ApiError | JSX.Element>
    | string
    | HTMLParagraphElement;
}

interface AlertProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class Alert extends PureComponent<AlertProps> {
  public static defaultProps = {
    defaultError: '',
    customErrorCode: '',
  };

  public render() {
    const { classes, t, onClick } = this.props;

    return (
      <Grid className={classes.content}>
        <Grid className={classes.alertWrapper}>
          <Circular
            icon={IconTypes.exclamationMark}
            iconClassName={classes.alertIcon}
            type={CircularType.warning}
          />
        </Grid>
        <Grid className={classes.descriptionWrapper}>
          <Typography className={classes.descriptionTitle}>
            {this.getDescription()}
          </Typography>
          <Button onClick={onClick} className={classes.button}>
            {t('TRY_AGAIN')}
          </Button>
        </Grid>
      </Grid>
    );
  }

  private getDescription = () => {
    const { customDescription } = this.props;
    if (customDescription) return customDescription;

    const { error } = this.props;
    if (error) return error;

    const { customErrorCode } = this.props;
    const customError = Configurator.getTranslation(customErrorCode!);
    if (customError) return customError;

    const { defaultError, t } = this.props;

    return defaultError || t('SOMETHING_WENT_WRONG');
  };
}

export default compose(withTranslation(), withStyles(styles))(Alert) as (
  props: PassedProps
) => JSX.Element;
