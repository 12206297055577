import { AxiosResponse } from 'axios';
import { api } from 'config';
import {
  CALL_API,
  CallApiAction,
  CallApiResponseAction,
} from 'store/middleware/api';
import { Dispatch, GetState } from 'store/utils/actions';
import { PurchaseElement } from 'types/Api/RateManager';
import Configurator from 'utils/Configurator';

import types from './types';

export const fetchPurchaseElements =
  (wasStoreInitializedBefore: boolean) =>
  async (dispatch: Dispatch, getState: GetState) => {
    await dispatch({
      [CALL_API]: {
        endpoint: api.RATE_MANAGER.PURCHASE_ELEMENTS,
        paginate: true,
        types: [
          types.FETCH_PURCHASE_ELEMENTS_REQUEST,
          types.FETCH_PURCHASE_ELEMENTS_SUCCESS,
          types.FETCH_PURCHASE_ELEMENTS_FAILURE,
        ],
      },
    });
    const {
      rateManager: { purchaseElements },
    } = getState();
    await Promise.all(
      purchaseElements.map(async (elem: { id: string }) => {
        await dispatch(fetchPurchaseElementImages(elem.id));
      })
    );
    const {
      rateManager: { images },
    } = getState();
    const elementsIds = Object.keys(images);

    await Promise.all(
      elementsIds
        .filter((id) => images[id].length > 0)
        .map(async (id) => {
          const imageId = images[id][0];
          const elementCode = purchaseElements.find(
            (elem: PurchaseElement) => elem.id === id
          )?.code;
          const purchaseElementKey = `${Configurator.fileCodes.PURCHASE_ELEMENTS}.${elementCode}`;

          const savedPurchaseElementForCode =
            Configurator.getFile(purchaseElementKey);
          const wasThisImageAlreadySaved =
            wasStoreInitializedBefore &&
            savedPurchaseElementForCode?.id === imageId;

          if (wasThisImageAlreadySaved) return Promise.resolve();

          try {
            const apiResponse = await dispatch(
              fetchPurchaseElementImageContent(id, imageId, elementCode!)
            );

            const imageContent = (
              apiResponse as unknown as CallApiResponseAction
            ).response.data;

            URL.revokeObjectURL(savedPurchaseElementForCode?.content);

            return Configurator.saveFile(purchaseElementKey, {
              id: imageId,
              content: URL.createObjectURL(imageContent),
            });
          } catch {
            // Prevents whole action failure in case fetch image content failed, they are optional
            return Promise.resolve();
          }
        })
    );
  };

export const fetchPurchaseElementImages = (id: string) => ({
  [CALL_API]: {
    endpoint: api.RATE_MANAGER.IMAGES(id),
    payload: id,
    types: [
      types.FETCH_PURCHASE_ELEMENT_IMAGES_REQUEST,
      types.FETCH_PURCHASE_ELEMENT_IMAGES_SUCCESS,
      types.FETCH_PURCHASE_ELEMENT_IMAGES_FAILURE,
    ],
  },
});

export const fetchPurchaseElementImageContent = (
  elementId: string,
  imageId: string,
  code: string
): CallApiAction => ({
  [CALL_API]: {
    endpoint: api.RATE_MANAGER.IMAGE_CONTENT(elementId, imageId),
    responseType: 'arraybuffer',
    payload: {
      imageId,
      id: code,
    },
    checkFailureCondition: (response: AxiosResponse) =>
      response?.status === 200,
    interval: 500,
    repetitions: 3,
    types: [
      types.FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_REQUEST,
      types.FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_SUCCESS,
      types.FETCH_PURCHASE_ELEMENT_IMAGE_CONTENT_FAILURE,
    ],
  },
});

export const fetchRatePlans = () => ({
  [CALL_API]: {
    endpoint: api.RATE_MANAGER.RATE_PLANS,
    paginate: true,
    types: [
      types.FETCH_RATE_PLANS_REQUEST,
      types.FETCH_RATE_PLANS_SUCCESS,
      types.FETCH_RATE_PLANS_FAILURE,
    ],
  },
});

export const fetchAgeBuckets = () => ({
  [CALL_API]: {
    endpoint: api.RATE_MANAGER.AGE_BUCKETS,
    paginate: true,
    types: [
      types.FETCH_AGE_BUCKETS_REQUEST,
      types.FETCH_AGE_BUCKETS_SUCCESS,
      types.FETCH_AGE_BUCKETS_FAILURE,
    ],
  },
});
