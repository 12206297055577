import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Store from 'types/Store';
import { Configurator } from 'utils';
import Router, { paths } from 'utils/Router';

import {
  getIsAppInitialized,
  getPaymentDevice,
  getScanningDevice,
  getUsedPropertyId,
  getWorkstationId,
} from '@gss/store/configuration/selectors';
import { getAreSettingsInitialized } from '@gss/store/settings/selectors';

class PrivateRoute extends PureComponent<any> {
  public render() {
    const { component, ...props } = this.props;

    return <Route {...props} render={this.renderRoute} />;
  }

  private renderRoute = (props: any) => {
    const { component: Component, isAppInitialized } = this.props;

    if (this.shouldRedirectToLogin) return <Redirect to={paths.LOGIN} />;

    return !isAppInitialized && Router.isCurrentStepPrivate ? (
      <Redirect to={paths.WELCOME} />
    ) : (
      <Component {...props} />
    );
  };

  private get shouldRedirectToLogin() {
    const {
      propertyId,
      isSetupInitialized,
      workstationId,
      paymentDevice,
      scanningDevice,
    } = this.props;

    const { KIOSKS_PASSPORT_SCANNING } = Configurator.switchCodes;
    const isScanningDeviceEnabled = Configurator.getSwitch(
      KIOSKS_PASSPORT_SCANNING
    );

    return (
      !propertyId ||
      !isSetupInitialized ||
      !workstationId ||
      !paymentDevice ||
      (!scanningDevice && isScanningDeviceEnabled)
    );
  }
}

const mapStateToProps = (state: Store) => ({
  isAppInitialized: getIsAppInitialized(state),
  isSetupInitialized: getAreSettingsInitialized(state),
  propertyId: getUsedPropertyId(state),
  workstationId: getWorkstationId(state),
  paymentDevice: getPaymentDevice(state),
  scanningDevice: getScanningDevice(state),
});

export default connect(mapStateToProps)(PrivateRoute);
