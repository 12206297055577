import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const FONT_SIZE = 24;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    headerTitle: {
      fontFamily: 'Open Sans',
      fontSize: FONT_SIZE,
      display: 'flex',
      alignItems: 'center',
      marginTop: spacing(3),
      justifyContent: 'center',
    },
  });
