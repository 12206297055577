import { createSelector } from 'reselect';
import { getEntitiesErrors } from 'store/entities/selectors';
import { ApiError } from 'types/Api/Shared';
import Store, { StoredFeatureToggles } from 'types/Store/SetupStore';
import { Configurator } from 'utils';

export const isFetchingSetting = (state: Store): boolean =>
  state.setup.dataFetching.setting;

export const getSetupErrors = (state: Store): ApiError[] => state.setup.errors;

export const getFeatureToggles = (
  state: Store
): StoredFeatureToggles | undefined => state.setup.featureToggles;

export const getIsDistrictEnabled = createSelector(
  getFeatureToggles,
  (featureToggles) => {
    const { DISTRICT } = Configurator.switchCodes;

    return Boolean(Configurator.getSwitch(DISTRICT));
  }
);

export const getErrors = createSelector(
  getSetupErrors,
  getEntitiesErrors,
  (setupErrors, entitiesErrors) =>
    [...setupErrors, ...entitiesErrors].filter(
      (item) => Object.values(item).length
    )
);
