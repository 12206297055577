import convert from 'color-convert';
import { colors } from 'config';
import { Configurator } from 'utils';

function getContrastColor(hex: string, darkColor: string, lightColor: string) {
  const rgb = convert.hex.rgb(hex);
  const o = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);

  return o <= 180 ? lightColor : darkColor;
}

export default () => {
  const primaryColor = Configurator.primaryColor || colors.primary;

  return {
    primary: {
      light: primaryColor[400],
      main: primaryColor[500],
      contrastText: getContrastColor(
        primaryColor[500],
        colors.black,
        colors.white
      ),
    },
    secondary: {
      light: colors.secondary[400],
      main: colors.secondary[500],
      contrastText: getContrastColor(
        colors.secondary[500],
        colors.black,
        colors.white
      ),
    },
    error: {
      main: colors.error[500],
    },
    text: {
      primary: colors.black,
      secondary: colors.grey[500],
      disabled: colors.grey[500],
    },
  };
};
