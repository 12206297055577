import { SettingsCodeMap } from '../../utils';

export interface StepsSettingsState {
  SHOW_CONTACT?: boolean;
  SHOW_DOCUMENT?: boolean;
  SHOW_NATIONALITY_FIELD?: boolean;
  SHOW_ADDRESS?: boolean;
  SHOW_SCAN_DOCUMENT?: boolean;
  KIOSKS_PASSPORT_SCANNING?: boolean;
  SHOW_SCREENSAVER?: boolean;
  SHOW_TAX_ID?: boolean;
  SHOW_NUMBER_OF_KEYS?: boolean;
  SHOW_MINIBAR?: boolean;
  SHOW_COMPANY_INVOICE?: boolean;
  COMPANY_INVOICE_ON_CHECK_OUT?: boolean;
  SHOW_CHECK_IN_SUMMARY?: boolean;
  SHOW_CHECK_OUT_SUMMARY?: boolean;
  SHOW_ADDONS?: boolean;
  SHOW_PREPAYMENT?: boolean;
  SHOW_PRE_AUTHORIZATION?: boolean;
  SHOW_PURPOSE_OF_STAY?: boolean;
  SHOW_ALLOCATION_STEP?: boolean;
  SHOW_TERMS_AND_CONDITIONS?: boolean;
  SHOW_FISCAL_DOCUMENT_SELECTION?: boolean;
}

export const stepsSettingsCodesMap: SettingsCodeMap<StepsSettingsState> = {
  SHOW_CONTACT: {
    key: 'SHOWCONTACT',
  },
  SHOW_DOCUMENT: {
    key: 'SHOWDOCUMENT',
  },
  SHOW_NATIONALITY_FIELD: {
    key: 'KIOSKSHOWNATIONALITY',
  },
  SHOW_ADDRESS: {
    key: 'SHOWADDRESS',
  },
  SHOW_SCAN_DOCUMENT: {
    key: 'SHOWSCANDOCUMENT',
  },
  KIOSKS_PASSPORT_SCANNING: {
    key: 'KIOSKSPASSPORTSCANNING',
  },
  SHOW_SCREENSAVER: {
    key: 'SHOWSCREENSAVER',
    default: true,
  },
  SHOW_TAX_ID: {
    key: 'SHOWTAXIDDURINGCHECKINCHECKOUT',
  },
  SHOW_NUMBER_OF_KEYS: {
    key: 'SHOWNUMBEROFKEYS',
  },
  SHOW_MINIBAR: {
    key: 'SHOWMINIBAR',
  },
  SHOW_COMPANY_INVOICE: {
    key: 'COMPANYINVOICE',
  },
  COMPANY_INVOICE_ON_CHECK_OUT: {
    key: 'COMPANYINVOICEONCHECKOUT',
  },
  SHOW_CHECK_IN_SUMMARY: {
    key: 'CHECKINSUMMARYSIGN',
  },
  SHOW_CHECK_OUT_SUMMARY: {
    key: 'CHECKOUTSUMMARYSIGN',
  },
  SHOW_ADDONS: {
    key: 'SHOWADDONS',
  },
  SHOW_PREPAYMENT: {
    key: 'SHOWPREPAYMENTSTEP',
  },
  SHOW_PRE_AUTHORIZATION: {
    key: 'SHOWPREAUTHSTEP',
  },
  SHOW_PURPOSE_OF_STAY: {
    key: 'SHOWPURPOSEOFSTAY',
  },
  SHOW_ALLOCATION_STEP: {
    key: 'SHOWROOMALLOCSTEP',
  },
  SHOW_TERMS_AND_CONDITIONS: {
    key: 'KIOSKSHOWTERMSNCOND',
  },
  SHOW_FISCAL_DOCUMENT_SELECTION: {
    key: 'SHOWFISCALDOCUMENTTYPESELECTION',
  },
};
