import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 16;

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    additionalInfo: {
      marginBottom: spacing(1),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(2),
      },
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      width: ICON_SIZE,
      minWidth: ICON_SIZE,
      height: ICON_SIZE,
      color: 'inherit',
      marginRight: spacing(2),
    },
    text: {
      fontSize: '1rem',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      color: 'inherit',
    },
  });
