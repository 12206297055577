import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { connect } from 'react-redux';
import { Modal } from 'components';
import { compose } from 'redux';
import { isIPad } from 'store/ui/selectors';
import { ScannerData } from 'types/Api/Reservation';
import Store from 'types/Store';

import { Loader } from '@ac/kiosk-components';

import { WithStyles, withStyles } from '@material-ui/styles';

import KioskProQrReader from './KioskProQrReader/KioskProQrReader';
import styles from './Scanner.style';

interface PassedProps {
  onScan: (data: ScannerData) => any;
}

interface ScannerProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  isIPad: boolean;
}

interface ScannerState {
  scanError: string | null;
  isLoading: boolean;
}

class Scanner extends PureComponent<ScannerProps, ScannerState> {
  public state = {
    scanError: null,
    isLoading: true,
  };

  public render() {
    const { classes, t, isIPad } = this.props;
    const { scanError, isLoading } = this.state;

    return (
      <div>
        <Modal
          isOpen={Boolean(scanError)}
          type="error"
          defaultError={scanError || undefined}
          onClick={this.onErrorDismissed}
        />
        {isLoading && <Loader title={t('LOADING_SCANNER')} />}
        <div className={isLoading ? classes.hidden : ''}>
          {isIPad ? (
            <KioskProQrReader onLoad={this.onLoad} onScan={this.onScan} />
          ) : (
            <QrReader
              className={classes.codeReader}
              facingMode="user"
              showViewFinder={false}
              onLoad={this.onLoad}
              onScan={this.onScan}
              onError={this.onError}
            />
          )}
        </div>
      </div>
    );
  }

  private onError = (error: Error) => {
    this.setState({ scanError: this.getErrorMessage(error.name) });
  };

  private getErrorMessage = (errorName: string): string => {
    const { t } = this.props;
    const cameraErrorCodes: {
      [key: string]: any;
    } = {
      NotFoundError: t('NO_VIDEO_DEVICES'),
      NoVideoInputDevicesError: t('NO_VIDEO_DEVICES'),
      NotAllowedError: t('PERMISSION_DENIED'),
    };
    const error = cameraErrorCodes[errorName];

    return error || t('SOMETHING_WENT_WRONG');
  };

  private onErrorDismissed = () => this.setState({ scanError: null });

  private onLoad = () => this.setState({ isLoading: false });

  private onScan = (data: string | null) => {
    try {
      if (!data) return;

      const { t, onScan } = this.props;
      const values: ScannerData = JSON.parse(data);

      if (!values.lastName || !values.identificationNumber) {
        throw new Error(t('QR_CODE_IS_INVALID'));
      }

      return onScan(values);
    } catch (error) {
      this.onError(error);
    }
  };
}

const mapStateToProps = (state: Store) => ({
  isIPad: isIPad(state),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(Scanner) as (props: PassedProps) => JSX.Element;
