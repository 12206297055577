import { routes, RoutesViews } from '@gss/router';
import { requireDeviceConfiguration } from '@gss/utils/hoc';

import { checkInStepsBinds } from './checkIn/checkInStepsBinds';
import { checkOutStepsBinds } from './checkOut/checkOutStepsBinds';
import { initialStepsBinds } from './initialSteps/initialStepsBinds';
import { makeKeysStepsBinds } from './makeKeys/makeKeysStepsBinds';
import { Welcome } from './Welcome';

export const stepsBinds: RoutesViews<typeof routes> = {
  ...initialStepsBinds,
  ...checkInStepsBinds,
  ...checkOutStepsBinds,
  ...makeKeysStepsBinds,
  WELCOME: requireDeviceConfiguration(Welcome),
};
