var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-icon {\n    --icon-size: 10px;\n    width: var(--icon-size);\n    max-width: var(--icon-size);\n    height: var(--icon-size);\n    max-height: var(--icon-size);\n    margin-bottom: -4px;\n  }\n</style>\n\n<img\n  class=\""
    + alias2(__default(require("../../helpers/classNames.ts")).call(alias1,"pdf-icon",(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"classNames","hash":{},"data":data,"loc":{"start":{"line":13,"column":9},"end":{"line":13,"column":40}}}))
    + "\"\n  alt=\"pdf-icon\"\n  src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"src") || (depth0 != null ? lookupProperty(depth0,"src") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"src","hash":{},"data":data,"loc":{"start":{"line":15,"column":7},"end":{"line":15,"column":14}}}) : helper)))
    + "\"\n/>\n";
},"useData":true});