import { createSelector } from 'reselect';
import { getReservation } from 'store/reservation/selectors';
import {
  HousekeepingReservation,
  HousekeepingRoomMaintenance,
  Room,
} from 'types/Api/Housekeeping';
import { ReservationView } from 'types/Api/Reservation';
import Store from 'types/Store/HousekeepingStore';
import { Configurator, DateManager } from 'utils';

const { USE_INSPECTED_STATUS } = Configurator.switchCodes;

export const getRooms = (state: Store) => state.housekeeping.rooms;

export const getHousekeepingErrors = (state: Store) =>
  state.housekeeping.errors;

const reservationWithOccupiedStatus = (
  room: Room
): HousekeepingReservation[] => {
  const occupiedReservationStatuses = [
    Configurator.reservationStatuses.RESERVED,
    Configurator.reservationStatuses.DUE_IN,
    Configurator.reservationStatuses.IN_HOUSE,
  ];
  const getStatusCode = (reservation: HousekeepingReservation) =>
    (reservation.status || reservation.statusCode || { code: '' }).code || '';

  return (room.reservations || []).filter((reservation) =>
    occupiedReservationStatuses.includes(getStatusCode(reservation))
  );
};

interface DateRange {
  fromDate: string;
  toDate: string;
}
const datesOverlaps = (date1: DateRange, date2: DateRange) => {
  const firstFromDateBeforeSecondToDate = DateManager.checkIfBefore(
    date2.toDate,
    date1.fromDate
  );
  const firstToDateAfterSecondFromDate = DateManager.checkIfAfter(
    date2.fromDate,
    date1.toDate
  );

  return firstFromDateBeforeSecondToDate && firstToDateAfterSecondFromDate;
};

const reservationsOverlaps = (
  mainReservation: ReservationView,
  roomReservation: HousekeepingReservation
): boolean => {
  return datesOverlaps(
    {
      fromDate: mainReservation.arrivalDate,
      toDate: mainReservation.departureDate,
    },
    {
      fromDate: roomReservation.arrivalDate || '',
      toDate: roomReservation.departureDate || '',
    }
  );
};

const isRoomReservationNotSharedWithReservation = (
  mainReservation: ReservationView,
  roomReservation: HousekeepingReservation
): boolean =>
  !roomReservation.shareId ||
  roomReservation.shareId !== mainReservation.sharingId;

const isRoomOccupied = (reservation: ReservationView, room: Room): boolean => {
  return reservationWithOccupiedStatus(room)
    .filter((roomReservation) =>
      isRoomReservationNotSharedWithReservation(reservation, roomReservation)
    )
    .some(
      (roomReservation) =>
        reservation.id !== roomReservation.id &&
        reservationsOverlaps(reservation, roomReservation)
    );
};

const isMaintenanceStatusCode = (maintenanceCode: string) =>
  maintenanceCode === Configurator.roomMaintenanceStatuses.OUT_OF_ORDER ||
  maintenanceCode === Configurator.roomMaintenanceStatuses.OUT_OF_SERVICE;

const isMaintenanceStatus = (maintenance: HousekeepingRoomMaintenance) =>
  isMaintenanceStatusCode((maintenance.status || {}).code || '') ||
  isMaintenanceStatusCode((maintenance.statusCode || {}).code || '');

const isRoomDuringMaintenance = (reservation: ReservationView, room: Room) => {
  const { checkInTime: defaultCheckInTime, checkOutTime: defaultCheckOutTime } =
    Configurator.propertySettings;

  return (room.maintenances || []).some((maintenance) => {
    const fromDate =
      reservation.eta ||
      (reservation.isEtaEtdGuaranteed &&
        Configurator.etGuaranteed?.options.defaultCheckInTime) ||
      DateManager.getServerFormattedDateTime(
        `${reservation.arrivalDate}T${defaultCheckInTime}`
      );

    const toDate =
      reservation.etd ||
      (reservation.isEtaEtdGuaranteed &&
        Configurator.etGuaranteed?.options.defaultCheckOutTime) ||
      DateManager.getServerFormattedDateTime(
        `${reservation.departureDate}T${defaultCheckOutTime}`
      );

    return (
      isMaintenanceStatus(maintenance) &&
      datesOverlaps(
        { fromDate, toDate },
        { fromDate: maintenance.fromTime, toDate: maintenance.toTime || '' }
      )
    );
  });
};

const isRoomAvailable = (room: Room, reservation: ReservationView) => {
  const filterCode = Configurator.getSwitch(USE_INSPECTED_STATUS)
    ? Configurator.roomStatuses.INSPECTED
    : Configurator.roomStatuses.CLEAN;

  return (
    room.roomStatus.code === filterCode &&
    room.frontdeskStatus.code === Configurator.frontdeskStatuses.VACANT &&
    room.housekeepingStatus.code === Configurator.housekeepingStatuses.VACANT &&
    !isRoomOccupied(reservation, room) &&
    !isRoomDuringMaintenance(reservation, room)
  );
};

export const shouldReassignRoom = createSelector(
  getRooms,
  getReservation,
  (rooms, reservation) => {
    const { roomId } = reservation;
    const room = rooms.find(
      (room) => room.id === roomId && isRoomAvailable(room, reservation)
    );

    return !room;
  }
);
