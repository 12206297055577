import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 22;
const PRE_ADDED_BUTTON_HEIGHT = 34;
const {
  colorCodes: { BUTTON_PRIMARY_TEXT_ACTIVE, BUTTON_PRIMARY_BACKGROUND_ACTIVE },
} = Configurator;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    preAddedButton: {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 0,
      zIndex: 500,
      position: 'absolute',
      boxSizing: 'border-box',
      margin: 0,
      padding: `0 ${spacing(1)}px`,
      height: PRE_ADDED_BUTTON_HEIGHT,
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      backgroundColor: Configurator.getColor(
        BUTTON_PRIMARY_BACKGROUND_ACTIVE,
        palette.primary.main
      ),
    },
    preAddedText: {
      color: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_ACTIVE,
        palette.primary.contrastText
      ),
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      fontSize: '0.875rem',
    },
    icon: {
      marginRight: spacing(1),
      width: ICON_SIZE,
      height: ICON_SIZE,
      fill: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_ACTIVE,
        palette.primary.contrastText
      ),
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  });
