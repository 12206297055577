import i18next from 'i18next';
import { Configurator } from 'utils';

import {
  propertyPermissionsMap,
  PropertyPermissionsState,
} from '@gss/store/configuration/interfaces';

const STEP_DEPENDENT_PERMISSIONS = [
  {
    permission: propertyPermissionsMap.MANAGE_PAYMENT_METHODS.id,
    step: Configurator.switchCodes.SHOW_PRE_AUTHORIZATION,
  },
];

export const validatePropertyPermissions = (
  propertyPermissions?: PropertyPermissionsState
) => {
  const title = `${i18next.t('PERMISSIONS_MISSING')}:`;
  const missingPermissionNames =
    propertyPermissions &&
    Object.values(propertyPermissions)
      .filter(({ id }) => {
        const stepWithPermission = STEP_DEPENDENT_PERMISSIONS.find(
          (dependant) => dependant.permission === id
        )?.step;

        return (
          !stepWithPermission || Configurator.getSwitch(stepWithPermission)
        );
      })
      .filter(({ isGranted }) => !isGranted)
      .map(({ name }) => name);

  if (missingPermissionNames?.length) {
    return `${title} ${missingPermissionNames.join(', ')}`;
  }
};
