import { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { ScanDataType } from './types';

interface PassedProps {
  onLoad?: () => any;
  onScan: (data: string | null) => any;
}

interface KioskProQrReaderProps extends PassedProps, WithTranslation {}

class KioskProQrReader extends PureComponent<KioskProQrReaderProps> {
  public componentDidMount() {
    if (this.props.onLoad) this.props.onLoad();

    this.assignCallbacks();
    this.startScan();
  }

  public componentWillUnmount() {
    this.cleanUpCallbacks();
  }

  public render() {
    return null;
  }

  private startScan = () => {
    (window as any).kp_ZBarScanner_startScan();
  };

  private cleanUpCallbacks = () => {
    const global = window as any;
    /* eslint-disable camelcase */
    global.kp_ZBarScanner_scanDidFinishWithDataEx = undefined;
    global.kp_ZBarScanner_scanDidCancel = undefined;
    global.kp_ZBarScanner_scanDidFinishWithTimeout = undefined;
    global.kp_ZBarScanner_scanDidFailWithError = undefined;
    /* eslint-enable camelcase */
  };

  private assignCallbacks = () => {
    const global = window as any;
    /* eslint-disable camelcase */
    global.kp_ZBarScanner_scanDidFinishWithDataEx = this.onScanFinish;
    global.kp_ZBarScanner_scanDidCancel = this.onScanCancel;
    global.kp_ZBarScanner_scanDidFinishWithTimeout = this.onScanCancel;
    global.kp_ZBarScanner_scanDidFailWithError = this.onError;
    /* eslint-enable camelcase */
  };

  private onScanFinish = (data: string, codeType: ScanDataType) => {
    const { t } = this.props;
    if (codeType !== ScanDataType.QRCode) {
      throw new Error(t('QR_CODE_IS_INVALID'));
    }
    this.props.onScan(data);
  };

  private onScanCancel = () => null;

  private onError = () => null;
}

export default withTranslation()(KioskProQrReader) as (
  props: PassedProps
) => JSX.Element;
