import React, { PureComponent } from 'react';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './ProfileBox.style';

interface ProfileBoxProps extends WithStyles<typeof styles> {
  role: string;
  name: string;
  icon: string;
}

class ProfileBox extends PureComponent<ProfileBoxProps> {
  public static defaultProps = {
    icon: 'guest',
    name: '',
    role: '',
  };

  public render() {
    const { classes, role, name, icon } = this.props;
    const iconType = this.getIconType(icon);

    return (
      <div className={classes.wrapper}>
        <Icon type={iconType} className={classes.icon} />
        <Typography className={classes.roleText}>
          {role}: {name}
        </Typography>
      </div>
    );
  }

  private getIconType = (icon: string) => {
    switch (icon) {
      case 'company':
        return IconTypes.company;
      case 'travel agent':
        return IconTypes.travelAgent;
      case 'booker':
        return IconTypes.booker;
      default:
        return IconTypes.guest;
    }
  };
}

export default withStyles(styles)(ProfileBox);
