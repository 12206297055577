import { colors } from 'config';
import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const FONT_SIZE = '0.875rem';
const {
  colorCodes: { BODY_FONT_COLOR },
} = Configurator;

export const chargeListStyles = ({ palette, spacing }: Theme) =>
  createStyles({
    list: {
      padding: 0,
      marginBottom: spacing(2),
    },
    header: {
      paddingTop: 0,
      color: Configurator.getColor(BODY_FONT_COLOR, palette.common.black),
    },
    currencyWrapper: {
      display: 'flex',
      marginBottom: spacing(),
      justifyContent: 'flex-end',
      paddingRight: spacing(2),
    },
    currencyTitle: {
      fontWeight: 300,
      color: 'inherit',
      fontSize: FONT_SIZE,
      marginRight: spacing(0.25),
    },
    subtitle: {
      color: 'inherit',
      fontSize: FONT_SIZE,
    },
    payment: {
      background: colors.success[200],
    },
  });

export const chargeRowItemStyles = ({ palette, spacing }: Theme) =>
  createStyles({
    chipText: {
      marginTop: spacing(),
    },
  });
