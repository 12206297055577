import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const WRAPPER_WIDTH = 108;
const WRAPPER_HEIGHT = 32;
const WRAPPER_RADIUS = 6;

const getWrapperStyles = (spacing: (unit: number) => number) => ({
  width: WRAPPER_WIDTH,
  height: WRAPPER_HEIGHT,
  borderRadius: WRAPPER_RADIUS,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:not(:first-child)': {
    marginLeft: spacing(1),
  },
  '&:not(:last-child)': {
    marginRight: spacing(1),
  },
});

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      ...getWrapperStyles(spacing),
      backgroundColor: colors.grey[500],
    },
    wrapperActive: {
      ...getWrapperStyles(spacing),
      backgroundColor: colors.grey[1000],
    },
    title: {
      color: palette.common.white,
      marginLeft: spacing(1 / 2),
    },
    icon: {
      color: palette.common.white,
      marginRight: spacing(1 / 2),
    },
  });
