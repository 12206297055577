const RESET_SETUP = 'RESET_SETUP';

const FETCH_FEATURE_TOGGLES_REQUEST = 'FETCH_FEATURE_TOGGLES_REQUEST';
const FETCH_FEATURE_TOGGLES_SUCCESS = 'FETCH_FEATURE_TOGGLES_SUCCESS';
const FETCH_FEATURE_TOGGLES_FAILURE = 'FETCH_FEATURE_TOGGLES_FAILURE';

export default {
  RESET_SETUP,
  FETCH_FEATURE_TOGGLES_REQUEST,
  FETCH_FEATURE_TOGGLES_SUCCESS,
  FETCH_FEATURE_TOGGLES_FAILURE,
};
