import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AdditionalInfo, SegmentInfo, SegmentTitle } from 'components';
import DayUseInfo from 'components/ReservationHeader/DayUseInfo';
import PriceSection from 'components/ReservationHeader/PriceSection';
import { compose } from 'redux';
import {
  getAddonsPriceSum,
  getCurrency,
  getDepartureHour,
  getProjectedRevenueAmount,
  getRoomPrice,
  isRatePlanConfidential,
  isReservationDayUse,
} from 'store/reservation/selectors';
import { getGuestDetails, getReservationDetails } from 'store/selectors';
import { getFeatureToggles } from 'store/setup/selectors';
import { toggleDayUseModal } from 'store/ui/actions';
import { ProjectedRevenueAmount } from 'types/Api/Reservation';
import { TypographyVariant } from 'types/MaterialUI';
import Store from 'types/Store';
import { StoredFeatureToggles } from 'types/Store/SetupStore';
import { Configurator } from 'utils';

import { IconTypes } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './ReservationDetails.style';

const SPACING = 1;

interface PassedProps {}

interface ReservationDetailsProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  profile: {
    name: string;
    vipCode: string;
    email: string;
    mobile: string;
    address: string;
    membership: string;
    taxId: string;
  };
  reservation: {
    bookingPeriod: string;
    nights: number;
    adults: number;
    children: number;
    totalPrice: number;
    roomName: string;
    roomNumber: string;
    arrivalDate?: string;
    departureDate?: string;
  };
  currency: string;
  projectedRevenueAmount: ProjectedRevenueAmount;
  isRatePlanConfidential: boolean;
  toggleDayUseModal: typeof toggleDayUseModal;
  departureHour: string;
  isReservationDayUse: boolean;
  addonsPrice: number;
  roomPrice: number;
  featureToggles: StoredFeatureToggles | undefined;
}

class ReservationDetails extends PureComponent<ReservationDetailsProps> {
  public static defaultProps = {
    profile: {
      name: '',
      vipCode: '',
      email: '',
      mobile: '',
      address: '',
      membership: '',
      taxId: '',
    },
    reservation: {
      bookingPeriod: '',
      nights: 0,
      adults: 0,
      children: 0,
      totalPrice: 0,
      roomName: '',
      roomNumber: '',
    },
    currency: '',
  };

  public render() {
    const {
      t,
      classes,
      addonsPrice,
      roomPrice,
      profile: { name, email, mobile, address, membership, taxId },
      reservation: { bookingPeriod, roomName, roomNumber },
      projectedRevenueAmount: { gross: totalPrice },
      currency,
      isRatePlanConfidential,
      isReservationDayUse,
      departureHour,
      toggleDayUseModal,
      featureToggles,
    } = this.props;

    const SHOW_TAX_ID = Configurator.switchCodes.SHOW_TAX_ID;
    const showTaxId = Configurator.getSwitch(SHOW_TAX_ID);

    return (
      <Grid container className={classes.wrapper} spacing={SPACING}>
        <Grid item xs={12} sm={6}>
          <SegmentTitle
            title={t('GUEST_DETAILS')}
            className={classes.guestDetailsTitle}
          />
          <SegmentInfo
            title={name || t('NOT_PROVIDED')}
            subtitle={membership}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SegmentTitle title={t('RESERVATION_DETAILS')} />
          <SegmentInfo title={bookingPeriod} />
          {isReservationDayUse && (
            <>
              <SegmentInfo
                subtitle={
                  <DayUseInfo
                    hour={departureHour}
                    onClick={toggleDayUseModal}
                  />
                }
              />
              <SegmentInfo
                title={t('DEPART_LATER')}
                classNames={{ titleClass: classes.dayUseSubtitle }}
              />
            </>
          )}
          <SegmentInfo subtitleList={this.getBookingInfo()} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AdditionalInfo
            info={email || t('NOT_PROVIDED')}
            icon={IconTypes.email}
          />
          <AdditionalInfo
            info={mobile || t('NOT_PROVIDED')}
            icon={IconTypes.mobile}
          />
          <AdditionalInfo
            info={address || t('NOT_PROVIDED')}
            icon={IconTypes.address}
          />
          {showTaxId && taxId && (
            <AdditionalInfo
              info={t('TAX_ID_ADDITIONAL_INFO', { taxId })}
              icon={IconTypes.taxCalculator}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <SegmentInfo
            title={t('ROOM', { room: roomNumber })}
            subtitle={roomName || t('NOT_PROVIDED')}
          />
          <div className={classes.price}>
            <Typography className={classes.subtitle}>
              {t('TOTAL_WITH_TAX')}
            </Typography>
            <Typography
              variant={TypographyVariant.h5}
              className={classes.subtitle}
            >
              {isRatePlanConfidential
                ? Configurator.pricePlaceholder
                : `${totalPrice.toFixed(2)} ${currency}`}
            </Typography>
            {!!addonsPrice && (
              <Grid className={classes.priceBreakdownContainer}>
                <PriceSection
                  text={`${t('ACCOMODATION')}: `}
                  price={
                    isRatePlanConfidential
                      ? Configurator.pricePlaceholder
                      : `${roomPrice.toFixed(2)} ${currency}`
                  }
                />
                <PriceSection
                  text={`${t('STAY_ENHANCEMENTS')}: `}
                  price={`${addonsPrice.toFixed(2)} ${currency}`}
                />
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }

  private getBookingInfo = (): string[] => {
    const {
      t,
      reservation: { nights, adults, children },
    } = this.props;

    return [
      t('NIGHT', { count: nights }),
      t('ADULT', { count: adults }),
      t('CHILD', { count: children }),
    ];
  };
}

const mapStateToProps = (state: Store) => ({
  profile: getGuestDetails(state),
  currency: getCurrency(state),
  reservation: getReservationDetails(state),
  projectedRevenueAmount: getProjectedRevenueAmount(state),
  addonsPrice: getAddonsPriceSum(state),
  roomPrice: getRoomPrice(state),
  isRatePlanConfidential: isRatePlanConfidential(state),
  isReservationDayUse: isReservationDayUse(state),
  departureHour: getDepartureHour(state),
  featureToggles: getFeatureToggles(state),
});

const mapDispatchToProps = {
  toggleDayUseModal,
};

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ReservationDetails) as (props: PassedProps) => JSX.Element;
