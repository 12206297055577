class Files {
  public static base64ImageToBlob = (image: string) => {
    const byteChars = atob(
      image.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
    );
    const byteArray = new Uint8Array(
      byteChars.split('').map((elem, index) => byteChars.charCodeAt(index))
    );

    return new Blob([byteArray]);
  };
}

export default Files;
