import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Body, Footer, Header, ReservationHeader, View } from 'components';
import { compose } from 'redux';
import { clearHousekeepingErrors } from 'store/housekeeping/actions';
import { clearProfileErrors } from 'store/profile/actions';
import { clearReservationErrors } from 'store/reservation/actions';
import { clearRoomErrors } from 'store/room/actions';
import {
  getRoomDescription,
  getRoomId,
  getRoomNumber,
  getRoomTypeId,
  getRoomTypeName,
} from 'store/room/selectors';
import { RoomImage } from 'types/Api/CheckInRoom';
import { ApiError, TranslatedDescription } from 'types/Api/Shared';
import Store from 'types/Store';

import { WithStyles, withStyles } from '@material-ui/styles';

import {
  fetchRoomImageContent,
  fetchRoomImages,
  fetchRoomTypeImageContent,
  fetchRoomTypeImages,
} from './store/actions';
import {
  getAttributeNames,
  getCheckInRoomErrors,
  getRoomImages,
  getRoomImageUrls,
  isFetchingImages,
} from './store/selectors';
import styles from './CheckInRoom.style';
import RoomDescription from './RoomDescription';
import RoomGallery from './RoomGallery';

interface PassedProps {}

interface CheckInRoomProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  isFetchingImages: boolean;
  roomId: string;
  roomTypeId: string;
  roomTypeName: string;
  roomNumber: string;
  roomDescription: string;
  roomImages: RoomImage[];
  roomImageUrls: string[];
  roomAttributes: TranslatedDescription[][];
  checkInRoomErrors: ApiError[];
  fetchRoomImages: (roomId: string) => any;
  fetchRoomImageContent: (roomId: string, imageId: string) => any;
  fetchRoomTypeImages: (roomTypeId: string) => any;
  fetchRoomTypeImageContent: (roomTypeId: string, imageId: string) => any;
  clearProfileErrors: () => void;
  clearReservationErrors: () => void;
  clearRoomErrors: () => void;
  clearHousekeepingErrors: () => void;
}

interface CheckInRoomState {
  isGalleryModalOpen: boolean;
  isLoading: boolean;
}

class CheckInRoom extends PureComponent<CheckInRoomProps, CheckInRoomState> {
  public static defaultProps = {
    roomImages: [],
    roomImageUrls: [],
  };

  public state = {
    isGalleryModalOpen: false,
    isLoading: false,
  };

  public componentDidMount() {
    this.fetchRoomImages();
  }

  public render() {
    const {
      classes,
      t,
      roomNumber,
      roomTypeName,
      roomDescription,
      roomAttributes,
      roomImageUrls,
      isFetchingImages,
      checkInRoomErrors,
    } = this.props;
    const { isGalleryModalOpen, isLoading } = this.state;

    return (
      <View
        className={classes.wrapper}
        modal={{ values: checkInRoomErrors }}
        idle={{ type: 'modal' }}
      >
        <Header title={`${t('CHECK_IN')} - ${t('ROOM_DETAILS')}`} />
        <ReservationHeader />
        <Body className={classes.bodyWrapper}>
          <RoomGallery
            isOpen={isGalleryModalOpen}
            isLoading={isLoading || isFetchingImages}
            toggleModal={this.toggleModal}
            images={roomImageUrls}
          />
          <RoomDescription
            roomNumber={roomNumber}
            roomType={roomTypeName}
            description={roomDescription}
            attributes={roomAttributes}
          />
        </Body>
        <Footer
          hasCancelButton
          hasBackButton
          hasContinueButton
          routeName={t('CHECK_IN')}
        />
      </View>
    );
  }

  private async fetchRoomImages() {
    const { roomTypeId, roomId, fetchRoomImages, fetchRoomImageContent } =
      this.props;
    if (!roomTypeId) return;
    this.setState({ isLoading: true });
    await fetchRoomImages(roomId);
    const { roomImages } = this.props;
    if (roomImages.length !== 0) {
      await Promise.all(
        roomImages.map((image) => fetchRoomImageContent(roomId, image.id))
      );

      return this.setState({ isLoading: false });
    }

    return this.fetchRoomTypeImages();
  }

  private async fetchRoomTypeImages() {
    const { roomTypeId, fetchRoomTypeImages, fetchRoomTypeImageContent } =
      this.props;
    await fetchRoomTypeImages(roomTypeId);

    const { roomImages } = this.props;
    await Promise.all(
      roomImages.map((image) => fetchRoomTypeImageContent(roomTypeId, image.id))
    );

    return this.setState({ isLoading: false });
  }

  private toggleModal = () => {
    this.setState({ isGalleryModalOpen: !this.state.isGalleryModalOpen });
  };
}

const mapStateToProps = (state: Store) => ({
  isFetchingImages: isFetchingImages(state),
  roomId: getRoomId(state),
  roomNumber: getRoomNumber(state),
  roomTypeId: getRoomTypeId(state),
  roomTypeName: getRoomTypeName(state),
  roomDescription: getRoomDescription(state),
  roomImages: getRoomImages(state),
  roomImageUrls: getRoomImageUrls(state),
  roomAttributes: getAttributeNames(state),
  checkInRoomErrors: getCheckInRoomErrors(state),
});

const mapDispatchToProps = {
  fetchRoomImages,
  fetchRoomImageContent,
  fetchRoomTypeImages,
  fetchRoomTypeImageContent,
  clearRoomErrors,
  clearProfileErrors,
  clearReservationErrors,
  clearHousekeepingErrors,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInRoom) as (props: PassedProps) => JSX.Element;
