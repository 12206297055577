import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  BasicModal,
  BasicModalSize,
  Button,
  ButtonPattern,
  Circular,
  CircularType,
  IconTypes,
  Text,
} from '@ac/kiosk-components';
import { ApiError } from '@ac/library-api';

import { getProcessingErrorCounter } from '../store/selectors';
import { isDocumentVerificationError } from '../utils/isDocumentVerificationError';
import { isProcessingError } from '../utils/isProcessingError';

import './ScanningErrorModal.scss';

interface ScanningErrorModalProps {
  error: Error | ApiError;
  onContinueCheckIn: () => void;
  onCancelCheckIn: () => void;
  onBackToScan: () => void;
}

const ScanningErrorModal = ({
  error,
  onContinueCheckIn,
  onCancelCheckIn,
  onBackToScan,
}: ScanningErrorModalProps) => {
  const { t } = useTranslation();
  const processingErrorCounter = useSelector(getProcessingErrorCounter);

  const modalConfig: any = useMemo(() => {
    if (isDocumentVerificationError(error)) {
      return {
        description: t(
          'CHECK_IN_ID_SCANNING.SCANNING_ERROR_MODAL.WRONG_DOCUMENT'
        ),
        primaryButton: {
          description: t('CHECK_IN_ID_SCANNING.BACK_TO_SCAN'),
          onClick: onBackToScan,
        },
      };
    }

    if (isProcessingError(error) && processingErrorCounter <= 1) {
      return {
        description: t(
          'CHECK_IN_ID_SCANNING.SCANNING_ERROR_MODAL.FIRST_ERROR_DESCRIPTION'
        ),
        primaryButton: {
          description: t('CHECK_IN_ID_SCANNING.BACK_TO_SCAN'),
          onClick: onBackToScan,
        },
      };
    }

    if (isProcessingError(error) && processingErrorCounter > 1) {
      return {
        description: t(
          'CHECK_IN_ID_SCANNING.SCANNING_ERROR_MODAL.SECOND_ERROR_DESCRIPTION'
        ),
        secondaryButton: {
          description: t('CHECK_IN_ID_SCANNING.BACK_TO_SCAN'),
          onClick: onBackToScan,
        },
        primaryButton: {
          description: t('CONTINUE_CHECK_IN'),
          onClick: onContinueCheckIn,
        },
      };
    }

    return {
      description: t('CHECK_IN_ID_SCANNING.SCANNING_ERROR_MODAL.CONNECT_ERROR'),
      secondaryButton: {
        description: t('CANCEL_CHECK_IN'),
        onClick: onCancelCheckIn,
      },
      primaryButton: {
        description: t('CONTINUE_CHECK_IN'),
        onClick: onContinueCheckIn,
      },
    };
  }, [t, error]);

  return (
    <BasicModal
      size={BasicModalSize.md}
      className="with-default-kiosk-components-theme scanning-document-error-modal"
    >
      <Circular
        icon={IconTypes.exclamationMark}
        type={CircularType.warning}
        iconClassName="error-circular-icon"
        className="spacing-auto"
      />
      <Text className="spacing-top-lg spacing-bottom-lg">
        {modalConfig.description}
      </Text>
      <div className="buttons-wrapper">
        {modalConfig?.secondaryButton && (
          <Button
            pattern={ButtonPattern.secondary}
            onClick={modalConfig.secondaryButton.onClick}
          >
            {modalConfig.secondaryButton.description}
          </Button>
        )}
        <Button onClick={modalConfig.primaryButton.onClick}>
          {modalConfig.primaryButton.description}
        </Button>
      </div>
    </BasicModal>
  );
};

export default ScanningErrorModal;
