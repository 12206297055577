import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

import 'react-image-crop/dist/ReactCrop.css';

const BOTTOM_HEIGHT = 44;

export default ({ palette, spacing }: Theme) =>
  createStyles({
    wrapper: {
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: '1rem',
      height: 22,
    },
    iconButton: {
      padding: 'var(--spacing-sm)',
      '&:not(:last-child)': {
        marginRight: spacing(1.375),
      },
    },
    sidePanel: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonsWrapper: {
      minHeight: BOTTOM_HEIGHT,
      maxHeight: BOTTOM_HEIGHT,
      display: 'flex',
      marginTop: spacing(1),
    },
    sliderContainer: {
      boxSizing: 'border-box',
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      minHeight: BOTTOM_HEIGHT,
      maxHeight: BOTTOM_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    middlePanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'space-between',
      bottom: 0,
      top: 0,
      position: 'relative',
      alignSelf: 'stretch',
    },
    menuWrapper: {
      alignItems: 'flex-end',
    },
    resetText: {
      fontSize: '1rem',
      fontWeight: 600,
      color: palette.common.black,
      textTransform: 'uppercase',
    },
    value: {
      fontSize: '1rem',
      color: palette.common.black,
    },
  });
