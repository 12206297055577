import { ListConfig } from 'components/List/types';

export const listConfig: ListConfig = {
  cellSpacing: 1,
  columns: [
    { xs: 3 },
    { xs: 2, md: 3 },
    {
      container: true,
      justify: 'flex-start',
      xs: 3,
      md: 3,
    },
    {
      container: true,
      justify: 'flex-end',
      xs: 4,
      md: 3,
    },
  ],
};
