import { createStore, Store as ReduxStore } from 'redux';

import { Action } from '@ac/library-utils/dist/declarations';

import { Store } from './interfaces';
import { applyReduxMiddleware, runMiddleware } from './middleware';
import { createRootReducer } from './reducers';

const configureStore = (): ReduxStore<Store, Action> => {
  const store = createStore<Store, Action, unknown, unknown>(
    createRootReducer(),
    applyReduxMiddleware()
  );

  runMiddleware();

  return store;
};

export const reduxStore = configureStore();
