import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BAR_HEIGHT = 62;
const BODY_HEIGHT = `calc(100% - ${BAR_HEIGHT * 2}px)`;

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    backButton: {
      marginRight: spacing(1),
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 300,
      marginBottom: spacing(2),
      color: 'inherit',
    },
    view: {
      height: '100%',
    },
    body: {
      height: BODY_HEIGHT,
      paddingBottom: spacing(7.75),
      overflowY: 'auto',
      boxSizing: 'border-box',
      [breakpoints.down('xs')]: {
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
      },
    },

    expansionPanelwrapper: {
      border: `1px solid ${palette.primary.dark}`,
      backgroundColor: colors.grey[75],
    },
    summaryWrapper: {
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    summaryTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    detailsWrapper: {
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
  });
