import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const ICON_SIZE = 45;
const TIMER_SIZE = 88;

export default ({ spacing, palette }: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    alertIcon: {
      color: 'var(--primary-color)',
    },
    alertCircle: {
      color: palette.secondary.main,
    },
    content: {
      textAlign: 'center',
    },
    title: {
      fontSize: '1rem',
      marginBottom: spacing(2),
    },
  });
