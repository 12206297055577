import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Body as BasicBody } from '@ac/kiosk-components';

import { BackgroundImagePosition } from '@gss/store/settings/interfaces/settings/imagesSettings';
import { getImages } from '@gss/store/settings/selectors';

import './Body.scss';

interface BodyProps extends React.ComponentProps<typeof BasicBody> {
  hasBackgroundImage?: boolean;
}

export const Body = ({
  children,
  dataTestSelector,
  hasBackgroundImage = true,
  ...props
}: BodyProps): JSX.Element => {
  const images = useSelector(getImages);

  const backgroundImageStyles = useMemo(() => {
    if (!hasBackgroundImage || !images?.VIEW_BACKGROUND_IMAGE) return;

    return {
      backgroundImage: `url(${images?.VIEW_BACKGROUND_IMAGE})`,
    };
  }, [images?.VIEW_BACKGROUND_IMAGE, hasBackgroundImage]);

  const imagePositionClass = useMemo(() => {
    if (!hasBackgroundImage || !images?.VIEW_BACKGROUND_IMAGE) return;
    const imagePosition =
      images?.BACKGROUND_IMAGE_POSITION || BackgroundImagePosition.Tile;

    return `body-wrapper-image-position-${imagePosition.toLocaleLowerCase()}`;
  }, [
    images?.BACKGROUND_IMAGE_POSITION,
    images?.VIEW_BACKGROUND_IMAGE,
    hasBackgroundImage,
  ]);

  return (
    <div
      data-test-selector={dataTestSelector}
      style={backgroundImageStyles}
      className={classNames('body-wrapper', imagePositionClass)}
    >
      <BasicBody {...props}>{children}</BasicBody>
    </div>
  );
};
