import { FOOTER_HEIGHT } from 'components/Footer';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    body: {
      flex: 1,
      overflow: 'auto',
      padding: `${spacing(4)}px ${spacing(12)}px`,
      paddingBottom: spacing(4) + FOOTER_HEIGHT,
      '& > *': {
        flex: 1,
      },
      [breakpoints.down('sm')]: {
        paddingLeft: spacing(10),
        paddingRight: spacing(10),
        paddingTop: spacing(4),
        paddingBottom: spacing(4) + FOOTER_HEIGHT,
      },
    },
  });
