/**
 * Temporary validation for device
 * Validation will be done on the backend side:
 * https://jira.shijidev.com/browse/ACPR-80960
 */
import { DeviceType } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import {
  KioskApiErrorDetailsDto,
  SelfServiceDeviceDto,
} from '@gss/api/KioskApi/entries';
import { GeneralSettingsState } from '@gss/store/settings/interfaces/settings/generalSettings';
import { StepsSettingsState } from '@gss/store/settings/interfaces/settings/stepsSettingsState';

class MissingDeviceIssue implements KioskApiErrorDetailsDto {
  code = 'MissingDevice';
  message?: string;

  constructor(type: DeviceType) {
    /**
     * According to previous comment translations were skipped
     * Also, initialization steps will always be in English
     */
    if (type === DeviceType.KeyEncoder) {
      this.message = 'Key encoder is not linked';
    } else if (type === DeviceType.PassportScanner) {
      this.message = 'Passport scanner is not linked';
    } else if (type === DeviceType.PaymentDevice) {
      this.message = 'Payment device is not linked';
    }
  }
}

export const validateDevice = (
  device: SelfServiceDeviceDto,
  stepsSettings: StepsSettingsState | undefined,
  generalSettings: GeneralSettingsState | undefined
): SelfServiceDeviceDto => {
  if (!device.isValid) return device;

  const isPassportScannerMissing = stepsSettings?.KIOSKS_PASSPORT_SCANNING
    ? !device?.workstationDevices?.some(
        (linkedDevice) => linkedDevice.type?.code === DeviceType.PassportScanner
      )
    : false;

  const isKeyEncoderMissing =
    stepsSettings?.SHOW_NUMBER_OF_KEYS || generalSettings?.SHOW_GENERATE_KEY
      ? !device?.workstationDevices?.some(
          (linkedDevice) => linkedDevice.type?.code === DeviceType.KeyEncoder
        )
      : false;

  const isPaymentDeviceMissing = !device?.workstationDevices?.some(
    (linkedDevice) => linkedDevice.type?.code === DeviceType.PaymentDevice
  );

  const anyDeviceMissing =
    isPassportScannerMissing || isKeyEncoderMissing || isPaymentDeviceMissing;

  return {
    ...device,
    isValid: !anyDeviceMissing,
    issues: [
      ...(device.issues ? device.issues : []),
      isPaymentDeviceMissing
        ? new MissingDeviceIssue(DeviceType.PaymentDevice)
        : undefined,
      isKeyEncoderMissing
        ? new MissingDeviceIssue(DeviceType.KeyEncoder)
        : undefined,
      isPassportScannerMissing
        ? new MissingDeviceIssue(DeviceType.PassportScanner)
        : undefined,
    ].filter(isDefined),
  };
};
