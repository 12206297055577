import React, { PureComponent } from 'react';
import classNames from 'classnames';

import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './Body.style';

interface BodyProps extends WithStyles<typeof styles> {
  className?: string;
  [prop: string]: any;
}

class Body extends PureComponent<BodyProps> {
  public render() {
    const { classes, children, className, ...props } = this.props;

    return (
      <div className={classNames(classes.body, className)} {...props}>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(Body);
