import { Address, CommunicationChannel, Profile } from './Profile';
import {
  Code,
  Money,
  Transaction,
  TransactionSummary,
  TranslatedDescription,
} from './Shared';

export type FolioTypeCode = 'COM' | 'GUE' | 'TRA';

export interface FolioBase<TransactionType> {
  id: string;
  version: number;
  number: string;
  profileId: string;
  profileName: string;
  profileRoleCode: Code;
  transactions: TransactionType[];
  profile: Profile;
  folioTypeCode: Code<FolioTypeCode>;
  folioStyleCode?: Code;
  folioStatusCode: Code;
  grossBalance: Money;
  billViewAllowed: boolean;
  remoteCheckOutAllowed: boolean;
  isRecentlySettled?: boolean;
}

export type Folio = FolioBase<Transaction>;

export interface ChargesFolio extends FolioBase<TransactionSummary> {
  address: Address;
}

export interface FolioWithCommunicationChannels extends FolioBase<Transaction> {
  communicationChannels: CommunicationChannel[];
}

export interface CheckOutFolioOperation {
  ids: Array<{
    folioId: string;
    operationId: string;
  }>;
  wrongVersionedFolios: string[];
  status: string;
}

export interface Cashier {
  cashierId: number;
  description: TranslatedDescription[];
  isOnlyExclusive: boolean;
}

export interface CurrentCashier {
  id: string;
}

export interface CashieringAccount {
  id: string;
  version: number;
  profileRoleCode: string;
  linkedProfiles: {
    individualProfileId: string;
    companyProfileId: string;
    travelAgentProfileId: string;
  };
}

export interface BillingInstruction {
  accountId: string;
  applicableTransactionCodeIds: string[];
  id: string;
  targetAccountId: null | string;
  targetFolioId: null | string;
  version: number;
}

export interface BillingInstructions {
  targetFolioId: string;
  operationId: string;
  status: string;
  instructions: BillingInstruction[];
}

export interface CreateFolioDataModel {
  additionalText: string;
  billViewAllowed: boolean;
  folioStyleCode?: string;
  folioTypeCode: string;
  paymentMethodId: string | null;
  profileId: string;
  remoteCheckOutAllowed: boolean;
}

export interface CreateBillingInstructionDataModel {
  newFolio: null;
  profileName: string | null;
  selector: {
    applicableTransactionCodeIds: string[];
  };
  targetAccountId: string | null;
  targetFolioId: string;
  version: string;
}

export interface PreAuthorizationData {
  isPreAuthorized: boolean;
  operationStatusUrl: string;
  operationId: string;
  isLongRunningProcess: boolean;
  terminalUrl: string;
  terminalRequestPayload: string;
  terminalStatus: string;
  terminalError: boolean;
}

export interface CreditCardDetails {
  approvalAmount: number;
  authorizationRule: string;
  folioWindowIds: string[];
  terminalId?: string;
  workstationId: string;
}

export interface CreatePaymentMethodDataModel {
  creditCardDetails: CreditCardDetails;
  transactionCodeId: string;
}

export interface FinalInvoice {
  id: string;
}

export interface CreateTransactionModel {
  foreignUnitPrice: null;
  isMainTransaction: boolean;
  quantity: number;
  transactionCode: string | null;
  unitPrice: Money;
}

export interface CreateTransactionOptions {
  applyBillingInstructions: boolean;
  cashierNumber: number;
  checkNumber: string;
  comment: string;
  folioText: string;
  isCommentInternal: boolean;
  remarks: string;
  terminalId: string;
  workstationId: string;
  creditCardAuthorizationId?: string;
}

export interface PaymentMethod {
  assignedFolioWindowIds: string[];
  creditCardDetails: {
    authorizationAmount: number;
    cardHolderName: string;
    creditCardType: string;
    creditCardAuthorizations: CreditCardAuthorization[];
  };
  id: string;
  version: number;
}

export interface CreditCardAuthorization {
  id: string;
  folioWindowAssignmentIds: string[];
  amount: Money;
  version: number;
  isActive: boolean;
}

export interface BalanceOfAllOutstandingDepositRequests {
  reservationId: string;
  balance: Money;
}

type CashieringMoney = Required<Money>;
type AuthorizationRule =
  | 'LOSRate'
  | 'LOSRateAmount'
  | 'LOSRatePersonAmount'
  | 'LOSRatePercent'
  | 'LOSAmount';

export interface CreditCardAuthorizationDetails {
  id: string;
  version: number;
  creditCardMaskedNumber?: string;
  creditCardToken?: string;
  transactionCodeId: string;
  amount?: CashieringMoney;
  isActive: boolean;
  authorizationRule: AuthorizationRule;
  transactionId?: string;
  referenceNumber?: string;
  terminalId?: string;
  completedAt?: string;
  accountId: string;
  folioWindowAssignments: string[];
}

export interface AuthorizationDetails {
  authorizationRule: string;
  accountId: string;
  folioWindowAssignments: string[];
}

export enum PaymentStatus {
  ToBeInitialized = 'ToBeInitialized',
  ProcessingPayment = 'ProcessingPayment',
  PaymentRejected = 'PaymentRejected',
  PaymentCompleted = 'PaymentCompleted',
  ProcessingFailed = 'ProcessingFailed',
}

export interface CreditCardOperationStatusResponse {
  id: string;
  paymentOperationId?: string;
  version: number;
  status: PaymentStatus;
  errors?: string[];
  warnings?: string[];
}

export interface MinibarItem {
  title: string;
  id: string;
  price: number;
  transactionCode: string;
  maxQuantity: number;
}

export interface Price {
  amount: number;
  currency: string;
}
export interface ReservationCharge {
  charges: [
    {
      comment: string;
      id: string;
      price: Money;
      quantity: number;
      transactionCodeId: string;
    }
  ];
  dateOfServing: string;
  isPosted: boolean;
  postAfterBusinessDateChange: boolean;
  purchaseElementId: string | null;
  ratePlanId: string | null;
}

export interface DataFetchingModel {
  checkOut: boolean;
  cashiering: boolean;
  availableFolioStyles: boolean;
}
