import { ApiError } from '@ac/library-api';
import { createAction } from '@ac/library-utils/dist/redux-utils';

const ACTION_PREFIX = '@backgroundTasks/';

export const keepDeviceLiveTask = {
  start: createAction(`${ACTION_PREFIX}keepDeviceLive/start`),
  stop: createAction(`${ACTION_PREFIX}keepDeviceLive/stop`),
  failed: createAction<ApiError | Error>(
    `${ACTION_PREFIX}keepDeviceLive/failed`
  ),
};

export const refreshConfigurationTask = {
  start: createAction(`${ACTION_PREFIX}refetchConfigurationTask/start`),
  stop: createAction(`${ACTION_PREFIX}refetchConfigurationTask/stop`),
};

export const pendingRefreshConfiguration = {
  trigger: createAction(
    `${ACTION_PREFIX}pendingRefreshingConfiguration/trigger`
  ),
  done: createAction(`${ACTION_PREFIX}pendingRefreshingConfiguration/done`),
};
