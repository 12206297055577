import { FOOTER_HEIGHT } from 'components/Footer';
import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const SLIDER_MIN_HEIGHT_XS = 300;
const SLIDER_MIN_HEIGHT_SM = 374;
const MODAL_SLIDER_MIN_HEIGHT_XS = 480;
const MODAL_SLIDER_MIN_HEIGHT_MD = `calc(100dvh - ${FOOTER_HEIGHT * 2}px)`;

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    wrapper: {
      background: palette.common.white,
      position: 'relative',
      [breakpoints.up('md')]: {
        paddingBottom: FOOTER_HEIGHT,
      },
    },
    placeholder: {
      background: Configurator.getColor(
        Configurator.colorCodes.BODY_BACKGROUND,
        palette.common.white
      ),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [breakpoints.up('md')]: {
        paddingBottom: FOOTER_HEIGHT,
      },
      minHeight: SLIDER_MIN_HEIGHT_XS,
      [breakpoints.up('sm')]: {
        minHeight: SLIDER_MIN_HEIGHT_SM,
      },
    },
    slider: {
      minHeight: `${SLIDER_MIN_HEIGHT_XS}px !important`,
      [breakpoints.up('sm')]: {
        minHeight: `${SLIDER_MIN_HEIGHT_SM}px !important`,
      },
      height: '100% !important',
      width: '100% !important',
      '& ul, li': {
        height: '100% !important',
        width: '100% !important',
      },
    },
    modalGallery: {
      background: 'transparent',
      outline: 'none',
      paddingBottom: 0,
      zIndex: 9999,
      top: '50%',
      transform: 'translate(0, -50%)',
      '& > div.slider > div.slider-frame': {
        minHeight: MODAL_SLIDER_MIN_HEIGHT_XS,
        [breakpoints.up('md')]: {
          minHeight: MODAL_SLIDER_MIN_HEIGHT_MD,
        },
        '& > ul.slider-list': {
          minHeight: MODAL_SLIDER_MIN_HEIGHT_XS,
          [breakpoints.up('md')]: {
            minHeight: MODAL_SLIDER_MIN_HEIGHT_MD,
          },
        },
      },
    },
    modalRoot: {
      zIndex: 9999,
    },
    backdropRoot: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    gutter: {
      minHeight: SLIDER_MIN_HEIGHT_SM,
      width: '100%',
    },
  });
