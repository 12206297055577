import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { TextField } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './QuantityFormField.style';

interface PassedProps {
  input?: any;
  className?: string;
  label?: string;
  error?: boolean;
  type?: string;
  value?: number;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
}

interface QuantityFormFieldProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {}

class QuantityFormField extends PureComponent<QuantityFormFieldProps> {
  public static defaultProps = {
    label: '',
  };

  public render() {
    const { input, label, classes, t, tReady, className, error, ...props } =
      this.props;
    const inputClasses = {
      root: error ? classes.inputPropsError : classes.inputPropsRoot,
      input: classes.inputPropsInput,
      focused: classes.inputPropsFocused,
    };

    return (
      <div className={className}>
        <TextField
          className={classes.textField}
          InputProps={{
            classes: inputClasses,
            disableUnderline: true,
          }}
          inputProps={{
            min: 1,
            className: classes.textFieldInput,
          }}
          {...input}
          {...props}
        />
      </div>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles)
)(QuantityFormField) as (props: PassedProps) => JSX.Element;
