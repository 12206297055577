import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getAreSettingsInitialized } from '@gss/store/settings/selectors';
import { paths } from '@LEGACY/utils/Router';

export const requireSettingsInitialized = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): FC<Props> => {
  const SettingsInitializationValidator = (props: Props): JSX.Element => {
    const areSettingsInitialized = useSelector(getAreSettingsInitialized);

    if (!areSettingsInitialized) {
      return <Redirect to={paths.SETUP} />;
    }

    return <WrappedComponent {...props} />;
  };

  return SettingsInitializationValidator;
};
