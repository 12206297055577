import { ApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

import { ReduxReducerMap } from '@gss/types/shared';

import { handleGlobalReducer } from '../storeConfiguration/utils';

import * as actions from './actions';
import { BackgroundTasksState } from './interfaces';

export const initialState: BackgroundTasksState = {
  fetching: {
    pendingRefreshConfiguration: false,
  },
  errors: [],
  isKeepDeviceLiveTaskInitialized: false,
  isRefreshConfigurationTaskInitialized: false,
};

const reducerMap: ReduxReducerMap<BackgroundTasksState> = {
  [actions.keepDeviceLiveTask.start]: (state): BackgroundTasksState => {
    return {
      ...state,
      isKeepDeviceLiveTaskInitialized: true,
    };
  },

  [actions.keepDeviceLiveTask.stop]: (state): BackgroundTasksState => {
    return {
      ...state,
      isKeepDeviceLiveTaskInitialized: false,
    };
  },

  [actions.refreshConfigurationTask.start]: (state): BackgroundTasksState => {
    return {
      ...state,
      isRefreshConfigurationTaskInitialized: true,
    };
  },

  [actions.refreshConfigurationTask.stop]: (state): BackgroundTasksState => {
    return {
      ...state,
      isRefreshConfigurationTaskInitialized: false,
    };
  },

  [actions.keepDeviceLiveTask.failed]: (
    state,
    action: Action<ApiError | Error>
  ): BackgroundTasksState => {
    return {
      ...state,
      errors: [action.payload],
    };
  },

  [actions.pendingRefreshConfiguration.trigger]: (
    state
  ): BackgroundTasksState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        pendingRefreshConfiguration: true,
      },
    };
  },
  [actions.pendingRefreshConfiguration.done]: (state): BackgroundTasksState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        pendingRefreshConfiguration: false,
      },
    };
  },
};

export const backgroundTasks = handleGlobalReducer<BackgroundTasksState>(
  reducerMap,
  initialState
);
