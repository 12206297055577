const DOCUMENT_SCAN_REQUEST = 'DOCUMENT_SCAN_REQUEST';
const DOCUMENT_SCAN_SUCCESS = 'DOCUMENT_SCAN_SUCCESS';
const DOCUMENT_SCAN_FAILURE = 'DOCUMENT_SCAN_FAILURE';
const DOCUMENT_SCAN_ERROR = 'DOCUMENT_SCAN_ERROR';

const CLEAR_CHECK_IN_ID_SCANNING_ERRORS = 'CLEAR_CHECK_IN_ID_SCANNING_ERRORS';

const CLEAR_CHECK_IN_ID_SCANNING_DOCUMENT =
  'CLEAR_CHECK_IN_ID_SCANNING_DOCUMENT';

export default {
  DOCUMENT_SCAN_REQUEST,
  DOCUMENT_SCAN_SUCCESS,
  DOCUMENT_SCAN_FAILURE,
  DOCUMENT_SCAN_ERROR,
  CLEAR_CHECK_IN_ID_SCANNING_ERRORS,
  CLEAR_CHECK_IN_ID_SCANNING_DOCUMENT,
};
