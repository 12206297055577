import { useEffect, useState } from 'react';
import { fetchActiveAuthorizations as fetchApiActiveAuthorizations } from 'store/cashiering/authorization/actions';
import { AsyncApiCallThroughActionCreator } from 'store/utils/actions';

export function useActiveAuthorizations(
  fetchActiveAuthorizations: AsyncApiCallThroughActionCreator<
    typeof fetchApiActiveAuthorizations
  >
) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await fetchActiveAuthorizations();

      setIsLoading(false);
    })();
  }, [fetchActiveAuthorizations]);

  return isLoading;
}
