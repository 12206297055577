import { Address } from 'types/Api/Profile';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from '@gss/api/KioskApi/entries';

import Configurator, { getLanguageCode } from './Configurator';

export const stringifyAddressData = (
  address: Partial<Address>,
  states: Record<string, KioskStateDetails[]>,
  districts: Record<string, BaseKioskConfigurationEntity[]>,
  languageCode: string = getLanguageCode(),
  isDistrictEnabled: boolean = false
): string => {
  const {
    addressLine1,
    addressLine2,
    city,
    countryCode,
    postCode,
    stateCode,
    districtId,
  } = address;

  const countries = Configurator.getCountries(languageCode);
  const country = countries.find(({ code }) => code === countryCode);
  const countryStatesDictionary = countryCode
    ? states?.[countryCode]
    : undefined;
  const countryDistrictsDictionary = countryCode
    ? districts?.[countryCode]
    : undefined;
  const countryState = countryStatesDictionary?.find(
    (state) => state.code === stateCode
  )?.name;
  const district = countryDistrictsDictionary?.find(
    (district) => district.id === districtId
  )?.description;

  return [
    addressLine1,
    addressLine2,
    [postCode, city].filter(isDefined).join(' '),
    countryState,
    isDistrictEnabled ? district : undefined,
    country?.name,
  ]
    .filter(isDefined)
    .join(', ');
};
