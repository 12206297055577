var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"RESERVATION_DETAILS",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":26,"column":34}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"period") || (depth0 != null ? lookupProperty(depth0,"period") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"period","hash":{},"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":35,"column":18}}}) : helper)))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"md"},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"class":"spacing-bottom-xs","size":"sm"},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + alias1(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"EXPECTED_DEPARTURE",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":10},"end":{"line":41,"column":39}}}))
    + ":\n          <span class=\"pdf-reservation-details-departure-hour\">\n            "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingInfo") : depth0)) != null ? lookupProperty(stack1,"departureHour") : stack1), depth0))
    + "\n          </span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "          "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"DEPART_LATER",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":50,"column":10},"end":{"line":50,"column":33}}}))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(alias1,"NIGHT",__default(require("../../../helpers/object.ts")).call(alias1,{"name":"object","hash":{"count":((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingInfo") : depth0)) != null ? lookupProperty(stack1,"nights") : stack1)},"data":data,"loc":{"start":{"line":60,"column":23},"end":{"line":60,"column":56}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":60,"column":8},"end":{"line":60,"column":58}}}))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(alias1,"ADULT",__default(require("../../../helpers/object.ts")).call(alias1,{"name":"object","hash":{"count":((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingInfo") : depth0)) != null ? lookupProperty(stack1,"adults") : stack1)},"data":data,"loc":{"start":{"line":66,"column":23},"end":{"line":66,"column":56}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":66,"column":8},"end":{"line":66,"column":58}}}))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(alias1,"CHILD",__default(require("../../../helpers/object.ts")).call(alias1,{"name":"object","hash":{"count":((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingInfo") : depth0)) != null ? lookupProperty(stack1,"children") : stack1)},"data":data,"loc":{"start":{"line":72,"column":23},"end":{"line":72,"column":58}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":72,"column":8},"end":{"line":72,"column":60}}}))
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(alias1,"ROOM",__default(require("../../../helpers/object.ts")).call(alias1,{"name":"object","hash":{"room":((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"number") : stack1)},"data":data,"loc":{"start":{"line":82,"column":20},"end":{"line":82,"column":45}}}),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":82,"column":6},"end":{"line":82,"column":47}}}))
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"room") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "      "
    + container.escapeExpression(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"TOTAL_WITH_TAX",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":96,"column":6},"end":{"line":96,"column":31}}}))
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"priceInfo") : depth0)) != null ? lookupProperty(stack1,"totalPrice") : stack1), depth0))
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div class=\"pdf-reservation-details-subsection-inline-wrapper\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"sm","class":"pdf-reservation-details-subsection-inline-element"},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"sm","class":"pdf-reservation-details-subsection-inline-element"},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + alias1(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"ACCOMODATION",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":111,"column":10},"end":{"line":111,"column":33}}}))
    + ":\n          <span class=\"pdf-reservation-details-departure-hour\">\n            "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"priceInfo") : depth0)) != null ? lookupProperty(stack1,"roomPrice") : stack1), depth0))
    + "\n          </span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + alias1(__default(require("../../../helpers/i18n.ts")).call(depth0 != null ? depth0 : (container.nullContext || {}),"STAY_ENHANCEMENTS",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":120,"column":10},"end":{"line":120,"column":38}}}))
    + ":\n          <span class=\"pdf-reservation-details-departure-hour\">\n            "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"priceInfo") : depth0)) != null ? lookupProperty(stack1,"addonsPrice") : stack1), depth0))
    + "\n          </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-reservation-details-section .pdf-reservation-details-subsection {\n    margin: var(--spacing-lg) var(--spacing-none);\n  }\n\n  .pdf-reservation-details-section .pdf-reservation-details-subsection-inline-wrapper {\n    display: flex;\n    flex-wrap: wrap;\n  }\n\n  .pdf-reservation-details-subsection-inline-wrapper .pdf-reservation-details-subsection-inline-element {\n    flex: unset;\n    margin-right: var(--spacing-sm);\n  }\n\n  .pdf-reservation-details-section .pdf-reservation-details-departure-hour {\n    font-weight: var(--font-weight-bold);\n  }\n</style>\n\n<div class=\""
    + container.escapeExpression(__default(require("../../../helpers/classNames.ts")).call(alias1,"pdf-reservation-details-section",(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"classNames","hash":{},"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":66}}}))
    + "\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"md","class":"pdf-signature-title"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n  <div class=\"pdf-reservation-details-subsection\">\n    <div>\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"weight":"bold","size":"xlg"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingInfo") : depth0)) != null ? lookupProperty(stack1,"isDayUse") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":6},"end":{"line":52,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"pdf-reservation-details-subsection-inline-wrapper\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"md","class":"pdf-reservation-details-subsection-inline-element"},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"md","class":"pdf-reservation-details-subsection-inline-element"},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"md","class":"pdf-reservation-details-subsection-inline-element"},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n  </div>\n\n  <div class=\"pdf-reservation-details-subsection\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"weight":"bold","size":"xlg"},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"size":"md"},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n\n  <div class=\"pdf-reservation-details-subsection\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"weight":"bold","size":"sm"},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("../../../partials/text.hbs"),depth0,{"name":"text","hash":{"weight":"bold","size":"xlg"},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"priceInfo") : depth0)) != null ? lookupProperty(stack1,"addonsPrice") : stack1),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":4},"end":{"line":126,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"usePartial":true,"useData":true});