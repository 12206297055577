import { colors } from 'config';
import { Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const BUTTON_HEIGHT = 70;

const {
  colorCodes: {
    BUTTON_PRIMARY_TEXT_ACTIVE,
    BUTTON_SECONDARY_TEXT_ACTIVE,
    BUTTON_SECONDARY_TEXT_DISABLED,
  },
} = Configurator;

const title: CSSProperties = {
  fontSize: '1rem',
  textTransform: 'none',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
  textAlign: 'left',
};
const subtitle: CSSProperties = {
  fontSize: '0.875rem',
  textTransform: 'none',
};
const price: CSSProperties = {
  fontSize: '1rem',
  fontWeight: 600,
};

export default ({ breakpoints, palette, spacing }: Theme) =>
  createStyles({
    button: {
      height: BUTTON_HEIGHT,
      width: '100%',
      paddingLeft: spacing(1.25),
      paddingRight: spacing(1.25),
      color: 'inherit',
    },
    title: {
      ...title,
      color: Configurator.getColor(
        BUTTON_SECONDARY_TEXT_ACTIVE,
        palette.common.black
      ),
    },
    titleActive: {
      ...title,
      color: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_ACTIVE,
        palette.common.white
      ),
    },
    subtitle: {
      ...subtitle,
      color: Configurator.getColor(
        BUTTON_SECONDARY_TEXT_DISABLED,
        colors.grey[500]
      ),
    },
    subtitleActive: {
      ...subtitle,
      color: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_ACTIVE,
        palette.common.white
      ),
    },
    price: {
      ...price,
      color: Configurator.getColor(
        BUTTON_SECONDARY_TEXT_ACTIVE,
        palette.common.black
      ),
    },
    priceActive: {
      ...price,
      color: Configurator.getColor(
        BUTTON_PRIMARY_TEXT_ACTIVE,
        palette.common.white
      ),
    },
    labelWrapper: {
      width: '100%',
    },
  });
