var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./iconWrapper.hbs"),depth0,{"name":"icons/iconWrapper","hash":{"src":"data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gAfQ29tcHJlc3NlZCBieSBqcGVnLXJlY29tcHJlc3P/2wCEAAQEBAQEBAQEBAQGBgUGBggHBwcHCAwJCQkJCQwTDA4MDA4MExEUEA8QFBEeFxUVFx4iHRsdIiolJSo0MjRERFwBBAQEBAQEBAQEBAYGBQYGCAcHBwcIDAkJCQkJDBMMDgwMDgwTERQQDxAUER4XFRUXHiIdGx0iKiUlKjQyNEREXP/CABEIABkAGQMBIgACEQEDEQH/xAAZAAACAwEAAAAAAAAAAAAAAAAGCAABBQn/2gAIAQEAAAAAf6keeJVwY/aJJhXoHKzdOf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQIQAAAAD//EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMQAAAAD//EADQQAAAFAgQBBg8AAAAAAAAAAAECAwQFBgcACBBCERMxMjNDRBUWISIlNlRiY3JzdbGzwv/aAAgBAQABPwDAiBQExhAAAOIiOJ/NDPy16qdpq27AZan0HYsF26QAJ5Q6nkOqQ+0iWw2lG5pYufu1N0HMMPBkUdyLGHcuAMksLpEwkMRyU/QFUehjNZfQ0Yi5tVRzvjIOSASaco9imfuhPfPvxllsUS3MIFXVO1DxqlEOrPzsGp+y+qffpmtsSMug5ulR7T0o0IB5lqjzrok7yT4hN+Mq1j15l6nd2uUDrE5Yy8Oi6887hf21X+NV+oW+Q34xR3qjS32hj+kuv//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8AH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8AH//Z","class":(depth0 != null ? lookupProperty(depth0,"class") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});