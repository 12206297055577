import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { RouterProvider } from './Provider/RouterProvider';

export const Router = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <BrowserRouter>
      <RouterProvider>{children}</RouterProvider>
    </BrowserRouter>
  );
};
