import { ListConfig } from 'components/List/types';

export const listConfig: ListConfig = {
  cellSpacing: 1,
  columns: [
    { xs: 2, lg: 1 },
    { xs: 5, lg: 7 },
    { container: true, justify: 'flex-end', xs: 2, lg: 1 },
    { container: true, justify: 'flex-end', xs: 1 },
    { container: true, justify: 'flex-end', xs: 2 },
  ],
};
