import { Money } from 'types/Api/Shared';

import { AgeQualifyingCode } from './RateManager';

interface DateRange {
  fromDate: string;
  toDate: string;
}

export interface GuestCount {
  ageBucketId: string;
  count: number;
}

export interface PriceDiscount {
  amount: number;
  type: string;
  reason: {
    id: string;
    comment?: string;
  };
}

export interface PriceAmount {
  gross: number;
  net: number;
}

export interface UnitPrice extends PriceAmount {
  currency?: string;
  basePriceType: string;
}

export interface UnitPriceWithConfidential extends UnitPrice {
  isConfidential: boolean;
}

export interface PriceOverrideItem {
  type?: string;
  reasonId?: string;
  userId: string;
  timestamp: string;
}

export interface RatePricingDetails {
  roomTypeToChargeId: string;
  ratePlanId: string;
  promotionId?: string;
  isConfidential: boolean;
  memberRateId?: string;
  isOfflineOffer: boolean;
}

export interface Breakdown extends DateRange {
  guests: GuestCount[];
  marketSegmentId: string;
  amountPerDay: UnitPrice;
  discount?: PriceDiscount;
  ratePricingDetails?: RatePricingDetails;
  pricePerDay?: UnitPrice;
  ratePricePerDay?: UnitPrice;
}

export enum AmountLockBehaviour {
  Undefined = 'Undefined',
  Locked = 'Locked',
  Forbidden = 'Forbidden',
}

export interface BreakdownUpdatePayload extends DateRange {
  type: string;
  guests: GuestCount[];
  marketSegmentId: string;

  ratePricingDetails: {
    ratePlanId: string;
    roomTypeToChargeId: string;
    promotionId?: string;
    memberRateId?: string;
  };

  ratePricePerDay?: UnitPrice;
  pricePerDay?: UnitPrice;
  discount?: PriceDiscount;
  amountLock?: {
    behaviour: AmountLockBehaviour;
    lockedReason?: string;
  };
}

export interface PreferenceItem {
  isRequired: boolean;
  weight?: number;
  id: string;
  code?: string;
  description?: string;
}

export interface AccompanyingGuestItem {
  profileId: string;
  arrivalDate: string;
  departureDate: string;
  status: string;
  type: AgeQualifyingCode;
}

export interface TransportationItem {
  typeId?: string;
  locationId?: string;
  start?: string;
  duration?: {
    days?: number;
    hours?: number;
    minutes?: number;
  };
  passengerCount?: number;
  luggageItemCount?: number;
  comments?: string;
  flightNumber?: string;
}

export interface GuestLocationItem {
  id: string;
  startDateTime: string;
  endDateTime: string;
  guestId: string;
  locationId: string;
  comment?: string;
  communications?: {
    target: string;
    channelId?: string;
  };
}

export interface ReservationBase extends LinkedProfiles {
  createdAtUtc: string;
  updatedAtUtc?: string;
  bookingTimestamp: string;
  id: string;
  profileId: string;
  statusCode?: {
    code?: string;
    description?: string;
  };
  confirmationNumber: string;
  eta?: string;
  etd?: string;
  isEtaEtdGuaranteed: boolean;
  checkInTime?: string;
  checkOutTime?: string;
  arrivalDate: string;
  departureDate: string;
  roomTypeId: string;
  roomTypeToChargeId: string;
  roomId?: string;
  guaranteeTypeId: string;
  channelId: string;
  preferences?: PreferenceItem[];
  sourceId: string;
  accompanyingGuests?: AccompanyingGuestItem[];
  applicableMembershipIds?: string[];
  isRoomFixed: boolean;
  cancellationComment?: string;
  cancellingUserId?: string;
  cancellationTime?: string;
  cancellationReasonId?: string;
  cancellationNumber?: string;
  projectedRevenue: Money;
  externalIds?: Array<{
    systemId: string;
    number: string;
  }>;
  transportations?: {
    arrivalTransportation?: TransportationItem;
    departureTransportation?: TransportationItem;
  };
  bookingDate: string;
  accountId: string;
  version: number;
  purposeOfStayId?: string;
  projectedRevenueAmount?: UnitPriceWithConfidential;
  guestLocations?: GuestLocationItem[];
}

export interface ReservationView extends ReservationBase {
  segment?: {
    id: string;
    ownership: string;
  };
  relations?: Array<{
    kind: string;
    id: string;
  }>;
  sharingId?: string;
  groupId?: string;
}

export interface Reservation extends ReservationBase {
  walkIn?: boolean;
  walkInIntention?: boolean;
  stayExtensionRestricted: boolean;
  relations?: {
    reinstateDetails?: {
      reinstatedFrom?: string;
      reinstatedTo?: string;
    };
    groupId?: string;
    shareId?: string;
    segment?: {
      id: string;
      ownership: string;
    };
  };
}

export interface SuggestedRoom {
  roomId: string;
  roomCode: string;
  roomDescription: string;
}
export interface DataFetchingModel {
  reservation: boolean;
  postCheckInLetter: boolean;
  sharingSummary: boolean;
  purposeOfStay: boolean;
  purchases: boolean;
  room: boolean;
  keys: boolean;
  operationStatus: boolean;
  checkIn: boolean;
  profileUpdate: boolean;
  mooltiRoomSegment: boolean;
}

export interface ReservationListDataModel {
  firstName: string;
  lastName: string;
  confirmationNumber: string | number;
  arrivalDate: string | Date;
  departureDate: string | Date;
  roomNumber: string | number;
  id?: string;
}

export interface CutKeyDataModel {
  encoder: string;
  workstation: string;
  from: string;
  to: string;
  numberOfKeys: number;
}

export enum AdditionalData {
  ConfirmationNumber = 'identificationNumber',
  DepartureDate = 'departureDate',
  NumberOfNights = 'numberOfNights',
}

export interface AuthorizationData {
  lastName?: string;
  identificationNumber?: string | number;
  additionalData?: AdditionalData;
  departureDate?: string;
  numberOfNights?: number;
}

export interface ScannerData {
  lastName: string;
  identificationNumber: string;
}

export interface ReservationDetails {
  purposeOfStayId: string;
}

export interface ProjectedRevenueAmount {
  gross: number;
  net: number;
  currency: string;
  basePriceType: string;
  isConfidential: boolean;
}

export interface PersonPrices extends DateRange {
  guestAmounts: GuestsAmounts[];
  flatAmount?: {
    gross: number;
  };
}

export interface PurchasePrice {
  guestCount?: {
    ageBucketId: string;
    count: number;
  };
  unitPrice: UnitPrice;
  quantity: number;
}

export interface GuestsAmounts {
  ageBucketCount: {
    ageBucketId: string;
    count: number;
  };
  amount: UnitPrice;
}

export interface BaseSingleContact {
  id: string;
  contactProfileRole: string[];
}

export interface ContactProfiles {
  primaryContact?: BaseSingleContact;
  otherContacts?: BaseSingleContact[];
}

export interface LinkedContactProfile {
  id: string;
  contactProfiles?: ContactProfiles;
}

export interface LinkedProfiles {
  linkedCompany?: LinkedContactProfile;
  linkedTravelAgent?: LinkedContactProfile;
  guestContactProfiles?: ContactProfiles;
}

export interface CorrespondenceDefinitionByRole {
  definitionId: string;
  role: string;
}

export interface CorrespondenceDefaultDefinitions {
  definitionsPerRoles: CorrespondenceDefinitionByRole[];
}

export interface HourlyInventoryManagement {
  feature: boolean;
  options: {
    defaultCheckInTime: string;
    defaultCheckOutTime: string;
  };
}

export interface BreakdownPurchaseElement {
  id: string;
  quantity: number;
  guestAmounts: GuestsAmounts[];
  flatAmount: UnitPrice;
  allowanceLimit?: {
    amount: number;
    currency?: string;
  };
}

export interface ReservationExtendedBreakdown extends DateRange {
  guests: GuestCount[];
  marketSegmentId: string;
  discount: PriceDiscount;
  ratePricingDetails: RatePricingDetails;
  accommodationPrice?: {
    amount?: PriceAmount;
    amountBeforeDiscount?: PriceAmount;
    currency?: string;
    basePriceType: string;
    isConfidential: boolean;
  };
  totalDailyPrice?: {
    discount?: PriceDiscount;
    amount?: PriceAmount;
    amountBeforeDiscount?: PriceAmount;
    currency?: string;
    basePriceType: string;
    isConfidential: boolean;
  };
  linkedPurchaseElements?: BreakdownPurchaseElement[];
  promotionalPurchaseElements?: {
    promotionId: string;
    purchaseElements?: BreakdownPurchaseElement[];
  };
  type: string;
  amountLock?: {
    canBeLocked: boolean;
    lockedReason?: string;
  };
  priceOverride?: {
    items?: PriceOverrideItem[];
  };
}

export interface PurchaseItemBreakdown {
  date?: string;
  fromDate?: string;
  toDate?: string;
  transactionCodeId?: string;
  chargeId?: string;
  flat?: PurchasePrice;
  occupancy?: PurchasePrice[];
}

export interface PurchaseElementData {
  id?: string;
  dateAnchor?: string;
  purchaseElementId: string;
  quantity: number;
  breakdown: PurchaseItemBreakdown[];
}

export interface SharingSummary {
  id: string;
  version: number;
  roomTypeId: string;
  roomId: string;
  reservationIds: string[];
}

export interface AllPurchaseItems {
  preAddedPurchaseElements: PurchaseElementData[];
  purchasesElementsFromRate: PurchaseElementData[];
}

export interface UpdatePurchaseElementBreakdown {
  guestCount: number;
  price: {
    gross: any;
    net: any;
    currency: any;
    basePriceType: any;
  };
  quantity: number;
  from: string;
  to: string;
}

export interface UpdatePurchaseElementData {
  id?: string;
  purchaseElementId: string;
  dateAnchor: string;
  breakdown: UpdatePurchaseElementBreakdown[];
}

export interface UpdatePurchaseFormData {
  modified: UpdatePurchaseElementData[];
  removed: string[];
}

export interface MultiRoomSegment {
  confirmationNumber: string;
  externalIds: string[];
  id: string;
  reservationIds: string[];
  version: number;
}
