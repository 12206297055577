import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Icon, IconTypes } from '@ac/kiosk-components';

import { Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import styles from './FormErrorMessage.style';

interface FormErrorMessageProps
  extends WithTranslation,
    WithStyles<typeof styles> {
  error: string;
}

class FormErrorMessage extends PureComponent<FormErrorMessageProps> {
  public render() {
    const { classes, error, t } = this.props;

    return (
      <Grid container className={classes.errorContainer}>
        <Icon type={IconTypes.warning} className={classes.errorIcon} />
        <Typography className={classes.errorText}>{t(error)}</Typography>
      </Grid>
    );
  }
}

const ComposedFormErrorMessage = compose(
  withStyles(styles),
  withTranslation()
)(FormErrorMessage);

export default ComposedFormErrorMessage as any;
