/**
 * Known error codes that might be returned by KIOSK API.
 * In case of which we should notify user with specific message (for example process session ended)
 *
 * Also this kind of errors are not something unexpected and won't be logged by Logger
 */
const knownErrorCodesMap = {
  PLACEHOLDER: {
    code: 'PLACEHOLDER',
  },
};

export const knownErrorCodes = knownErrorCodesMap as {
  [key in keyof typeof knownErrorCodesMap]: {
    code: string;
    message?: string;
  };
};
