import { createSelector } from 'reselect';
import { getReservationErrors } from 'store/reservation/selectors';
import Store from 'types/Store/FilesStore';

export const getFilesErrors = (state: Store) => state.files.errors;

export const isFetching = (state: Store) => state.files.isFetching;

export const getPdfGenerationError = (state: Store) =>
  state.files.pdfGenerationError;

export const getUploadErrors = (state: Store) => state.files.uploadErrors;

export const getAttachmentId = (state: Store) => state.files.attachmentId;

export const getFolioDocument = (state: Store) => state.files.folioFile;

export const getErrors = createSelector(
  getPdfGenerationError,
  getUploadErrors,
  getReservationErrors,
  getFilesErrors,
  (pdfGenerationError, uploadErrors, reservationErrors, filesErrors) =>
    [
      pdfGenerationError,
      ...uploadErrors,
      ...reservationErrors,
      ...filesErrors,
    ].filter((error) => Boolean(error))
);
