import { CallApiResponseAction } from 'store/middleware/api';
import {
  singleLoadingFailure as failure,
  singleLoadingRequest as request,
  singleLoadingSuccess as success,
} from 'store/utils/reducer';
import { State } from 'types/Store/AggregatorStore';

import types from './types';

const initialState: State = {
  transactions: [],
  errors: [],
  isFetching: false,
  totalBalance: 0,
};

export default (state = initialState, action: CallApiResponseAction) => {
  switch (action.type) {
    case types.FETCH_ALL_TRANSACTIONS_REQUEST:
      return request(state);
    case types.FETCH_ALL_TRANSACTIONS_SUCCESS: {
      const {
        _embedded: {
          transactions: { results: transactions },
        },
        projectedRevenue: { amount },
      } = action.response.data;

      return {
        ...success(state),
        transactions,
        totalBalance: amount,
      };
    }
    case types.FETCH_ALL_TRANSACTIONS_FAILURE:
      return failure(state, action);
    default:
      return state;
  }
};
