import { colors } from 'config';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

export default ({ palette, spacing }: Theme) =>
  createStyles({
    rowContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: colors.white,
      borderRadius: '0.375rem',
      marginBottom: spacing(1),
      padding: spacing(1, 2),
    },
    balance: {
      fontWeight: 700,
    },
    divider: {
      height: 12,
      borderBottom: `2px solid ${colors.grey[500]}`,
      // eslint-disable-next-line max-len
      backgroundImage:
        'linear-gradient(to bottom, rgba(238, 238, 238, 0), rgba(238, 238, 238, 0.25) 17%, rgba(238, 238, 238, 0.75) 38%, rgba(238, 238, 238, 0.9) 100%)',
    },
    backgroundCover: {
      paddingBottom: spacing(2),
      background: colors.grey[75],
      width: '100%',
      height: '100%',
      paddingTop: spacing(),
    },
  });
