import { Theme } from '@material-ui/core/styles';
import { createStyles, CSSProperties } from '@material-ui/styles';

const ICON_SIZE = 45;
const ICON_WRAPPER_SIZE = 88;
const VALID_ICON_SIZE = 16;

const wrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export default ({ palette, spacing }: Theme) =>
  createStyles({
    title: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      marginBottom: spacing(2),
      marginTop: spacing(3),
      textAlign: 'center',
    },
    hidden: {
      display: 'none',
    },
    content: {
      ...wrapper,
      minHeight: '100%',
      flex: 1,
    },
    iconWrapper: {
      position: 'relative',
      width: ICON_WRAPPER_SIZE,
      height: ICON_WRAPPER_SIZE,
      marginBottom: spacing(1.5),
    },
    stepsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
    },
    cell: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingLeft: spacing(1.5),
      '&:first-child': {
        paddingRight: spacing(1.5),
        justifyContent: 'flex-end',
        paddingLeft: 0,
      },
    },
    stepper: {
      marginTop: spacing(2),
      background: 'none',
      backgroundColor: 'transparent',
    },
    icon: {
      width: VALID_ICON_SIZE,
      height: VALID_ICON_SIZE,
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      marginRight: spacing(1),
    },
  });
