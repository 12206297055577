import { colors } from 'config';
import { Colors, Configurator } from 'utils';

import { Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const BUTTON_BOTTOM_POSITION = 12;
const BUTTON_RIGHT_POSITION = 32;
const BUTTON_SIZE = 44;
const TITLE_FONT_SIZE = '1.5rem';
const SUBTITLE_FONT_SIZE = '1.25rem';
const SMALL_SUBTITLE_FONT_SIZE = '1rem';
const FONT_WEIGHT_LIGHT = 300;

export default ({ palette, spacing, breakpoints }: Theme) =>
  createStyles({
    headerSegment: {
      width: '100%',
      flexDirection: 'column-reverse',
      flexWrap: 'nowrap',
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    expansionPanel: {
      zIndex: 1100,
      padding: spacing(4),
      borderBottom: `1px solid ${colors.grey[500]}`,
      [breakpoints.up('sm')]: {
        padding: `${spacing(2.5)}px ${spacing(4)}px`,
      },
      [breakpoints.up('md')]: {
        padding: `${spacing(2.5)}px ${spacing(11.5)}px`,
      },
      ...Colors.getBackgroundColor(
        palette,
        {
          colorSwitch: Configurator.colorCodes.RESERVATION_HEADER_BACKGROUND,
          separateSwitch:
            Configurator.switchCodes.SEPARATE_RESERVATION_HEADER_BG,
        },
        palette.common.white
      ),
      ...Colors.getFontColor(
        palette,
        Configurator.colorCodes.RESERVATION_HEADER_FONT_COLOR,
        palette.common.black
      ),
      '&:before': {
        opacity: 0,
      },
    },
    panelExpanded: {
      margin: '0 !important',
    },
    content: {
      '& > :last-child': {
        paddingRight: 0,
      },
      margin: '0 !important',
      cursor: 'default',
    },
    expansionSummary: {
      padding: 0,
    },
    expandedColumnTitle: {
      fontSize: SUBTITLE_FONT_SIZE,
      fontWeight: FONT_WEIGHT_LIGHT,
      marginBottom: spacing(1),
    },
    expansionDetails: {
      padding: 0,
    },
    expandButton: {
      padding: 0,
      minWidth: 0,
      width: BUTTON_SIZE,
      height: BUTTON_SIZE,
      position: 'absolute',
      background: 'none',
      bottom: BUTTON_BOTTOM_POSITION,
      right: BUTTON_RIGHT_POSITION,
      outline: 'none',
    },
    contactInfo: {
      paddingTop: `${spacing(1)}px !important`,
    },
    contactInfoWithoutMembership: {
      marginTop: -spacing(3),
    },
    emailAddressInfo: {
      marginTop: spacing(1),
    },
    segmentInfoTitle: {
      fontSize: TITLE_FONT_SIZE,
    },
    segmentInfoSubtitleExpandable: {
      fontSize: SUBTITLE_FONT_SIZE,
    },
    segmentInfoSubtitle: {
      fontSize: SMALL_SUBTITLE_FONT_SIZE,
      marginTop: spacing(1 / 2),
    },
    bookingInfo: {
      fontSize: SMALL_SUBTITLE_FONT_SIZE,
      fontWeight: FONT_WEIGHT_LIGHT,
    },
    ratePlan: {
      marginTop: -spacing(0.5),
      fontSize: SMALL_SUBTITLE_FONT_SIZE,
      fontWeight: FONT_WEIGHT_LIGHT,
    },
    segmentInfoWrapper: {
      marginBottom: spacing(2),
    },
    titleClass: {
      marginBottom: spacing(0.5),
    },
  });
