import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormCheckbox } from 'components';
import { compose } from 'redux';
import { getCompanyListData } from 'store/profile/selectors';
import Store from 'types/Store';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';

import CompanyList from '../CompanyList';

import styles from './CompanySelect.style';

interface PassedProps {
  onSelect: (companyId?: string) => void;
  onToggle?: () => void;
  isExpanded?: boolean;
  isLocked: boolean;
}

interface CompanySelectProps
  extends PassedProps,
    WithTranslation,
    WithStyles<typeof styles> {
  companies: any;
}

interface CompanySelectState {
  isPanelExpanded: boolean;
}

class CompanySelect extends PureComponent<
  CompanySelectProps,
  CompanySelectState
> {
  public static defaultProps = {
    isChecked: false,
  };

  public state = {
    isPanelExpanded: false,
  };

  public render() {
    const { companies, classes, onSelect, isExpanded, isLocked, t } =
      this.props;
    const { isPanelExpanded } = this.state;

    return (
      <Accordion
        expanded={isLocked || isExpanded || isPanelExpanded}
        classes={{ root: classes.expansionPanelwrapper }}
      >
        <AccordionSummary
          classes={{
            root: classes.summaryWrapper,
            expanded: classes.summaryWrapperExpanded,
            content: classes.summaryWrapperContent,
          }}
        >
          <FormCheckbox
            input={{
              value: isLocked || isExpanded || isPanelExpanded,
              onChange: this.onToggle,
              disabled: isLocked,
            }}
            labelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            label={t('COMPANY_INVOICE')}
          />
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.detailsWrapper }}>
          <Typography className={classes.summarySubTitle}>
            {t('SELECT_COMPANY')}
          </Typography>
          <CompanyList onItemClick={onSelect} data={companies} />
        </AccordionDetails>
      </Accordion>
    );
  }

  private onToggle = () => {
    const { isPanelExpanded } = this.state;
    const { onToggle } = this.props;

    return onToggle
      ? onToggle()
      : this.setState({ isPanelExpanded: !isPanelExpanded });
  };
}

const mapStateToProps = (state: Store) => ({
  companies: getCompanyListData(state),
});

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps)
)(CompanySelect) as (props: PassedProps) => JSX.Element;
